///////////////////////////////
// Imports
///////////////////////////////

import { Box, Divider, FormControl, FormControlLabel, MenuItem, Select, Stack, Switch, Typography } from '@mui/material'
import { roofTypeOptions } from 'app/pages/projects/forms/project_home_details'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_TaskWorkflowProd_Document, DatabaseRef_TaskWorkflow_Document } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import { DatabaseRef_SalesPartner_InvoiceRates_Task_Document } from 'rfbp_aux/services/database_endpoints/finances/invoice_rates'
import { Json } from 'rfbp_core/components/code_display'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseGetDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { dynamicSort, formatCurrency, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { TsInterface_ProjectData } from '../services/invoice_progress_functions'
import { invoiceBillToOptions } from './task_invoice_mapping'

///////////////////////////////
// Variables
///////////////////////////////

export const invoiceApprovalTypeOptions: TsInterface_UnspecifiedObject = {
  auto_approved: {
    key: 'auto_approved',
    value: rLIB('Auto Approved', false),
  },
  internal_optional_evidence: {
    key: 'internal_optional_evidence',
    value: rLIB('Internal (Optional Evidence)', false),
  },
  internal_required_evidence: {
    key: 'internal_required_evidence',
    value: rLIB('Internal (Required Evidence)', false),
  },
  customer_approved: {
    key: 'customer_approved',
    value: rLIB('Customer', false),
    disabled: true,
  },
  sales_partner_approved: {
    key: 'sales_partner_approved',
    value: rLIB('Sales Partner', false),
    disabled: true,
  },
}

export const projectInvoicePriceTypeOptions: TsInterface_UnspecifiedObject = {
  flat: {
    key: 'flat',
    value: rLIB('Flat Rate', false),
    calculate: (lineItem: TsInterface_UnspecifiedObject, projectData: TsInterface_ProjectData) => {
      return lineItem.price
    },
  },
  per_dc_watt: {
    key: 'per_dc_watt',
    value: rLIB('Per DC Watt', false),
    calculate: (lineItem: TsInterface_UnspecifiedObject, projectData: TsInterface_ProjectData) => {
      if (projectData.system_size_dc != null) {
        // Remove non numeric characters from system_size_dc
        let parsedSystemSizeDC = projectData.system_size_dc.toString().replace(/[^0-9.]/g, '')
        return lineItem.price * parseFloat(parsedSystemSizeDC) * 1000
      } else {
        return 0
      }
    },
  },
  per_panel: {
    key: 'per_panel',
    value: rLIB('Per Panel', false),
    calculate: (lineItem: TsInterface_UnspecifiedObject, projectData: TsInterface_ProjectData) => {
      if (projectData.system_panel_quantity != null) {
        return lineItem.price * projectData.system_panel_quantity
      } else {
        return 0
      }
    },
  },
}

export const invoiceBillingTypeOptions: TsInterface_UnspecifiedObject = {
  percent: {
    key: 'percent',
    value: rLIB('Staggered Percentages', false),
  },
  deposit: {
    key: 'deposit',
    value: rLIB('Deposit before Full', false),
  },
}

const depositInvoiceAmountTypeOptions: TsInterface_UnspecifiedObject = {
  deposit: {
    key: 'deposit',
    value: rLIB('Deposit', false),
  },
  remainder: {
    key: 'remainder',
    value: rLIB('Remainder', false),
  },
}

///////////////////////////////
// Functions
///////////////////////////////

// Billing
const rJSX_BillToDropdown = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  rateKey: string,
): JSX.Element => {
  let dropdownJSX = <></>
  if (rowData != null && rowData.key != null && tableAdditionalData != null && tableAdditionalData.us_selectedSalesPartnerKey != null) {
    let dropdownValue = ''
    if (
      rowData != null &&
      rowData.key != null &&
      tableAdditionalData != null &&
      tableAdditionalData.us_invoiceRates != null &&
      tableAdditionalData.us_invoiceRates[rateKey] != null &&
      tableAdditionalData.us_invoiceRates[rateKey]['bill_to'] != null
    ) {
      dropdownValue = tableAdditionalData.us_invoiceRates[rateKey]['bill_to']
    }
    dropdownJSX = (
      <Box>
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={dropdownValue}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                let updateObject: TsInterface_UnspecifiedObject = {
                  bill_to: event.target.value,
                }
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(
                      DatabaseRef_SalesPartner_InvoiceRates_Task_Document(res_GCK.clientKey, tableAdditionalData.us_selectedSalesPartnerKey, rateKey),
                      updateObject,
                    )
                      .then((res_DSMD) => {
                        // Nothing
                      })
                      .catch((rej_DSMD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }
            }}
            variant="outlined"
          >
            {objectToArray(invoiceBillToOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={option['key']}
                disabled={getProp(option, 'disabled', false)}
              >
                {option['name']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    )
  }
  return dropdownJSX
}

const rJSX_BillingTypeDropdown = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  rateKey: string,
): JSX.Element => {
  let dropdownJSX = <></>
  if (rowData != null && rowData.key != null && tableAdditionalData != null && tableAdditionalData.us_selectedSalesPartnerKey != null) {
    let dropdownValue = ''
    if (
      rowData != null &&
      rowData.key != null &&
      tableAdditionalData != null &&
      tableAdditionalData.us_invoiceRates != null &&
      tableAdditionalData.us_invoiceRates[rateKey] != null &&
      tableAdditionalData.us_invoiceRates[rateKey]['billing_type'] != null
    ) {
      dropdownValue = tableAdditionalData.us_invoiceRates[rateKey]['billing_type']
    }
    dropdownJSX = (
      <Box>
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={dropdownValue}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                let updateObject: TsInterface_UnspecifiedObject = {
                  billing_type: event.target.value,
                  billing_times: {},
                }
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(
                      DatabaseRef_SalesPartner_InvoiceRates_Task_Document(res_GCK.clientKey, tableAdditionalData.us_selectedSalesPartnerKey, rateKey),
                      updateObject,
                    )
                      .then((res_DSMD) => {
                        // Nothing
                      })
                      .catch((rej_DSMD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }
            }}
            variant="outlined"
          >
            {objectToArray(invoiceBillingTypeOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={option['key']}
              >
                {option['value']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    )
  }
  return dropdownJSX
}

const rJSX_ApprovalTypeDropdown = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  rateKey: string,
): JSX.Element => {
  let dropdownJSX = <></>
  if (rowData != null && rowData.key != null && tableAdditionalData != null && tableAdditionalData.us_selectedSalesPartnerKey != null) {
    let dropdownValue = ''
    if (
      rowData != null &&
      rowData.key != null &&
      tableAdditionalData != null &&
      tableAdditionalData.us_invoiceRates != null &&
      tableAdditionalData.us_invoiceRates[rateKey] != null &&
      tableAdditionalData.us_invoiceRates[rateKey]['approval_type'] != null
    ) {
      dropdownValue = tableAdditionalData.us_invoiceRates[rateKey]['approval_type']
    }
    dropdownJSX = (
      <Box>
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={dropdownValue}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                let updateObject: TsInterface_UnspecifiedObject = {
                  approval_type: event.target.value,
                }
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(
                      DatabaseRef_SalesPartner_InvoiceRates_Task_Document(res_GCK.clientKey, tableAdditionalData.us_selectedSalesPartnerKey, rateKey),
                      updateObject,
                    )
                      .then((res_DSMD) => {
                        // Nothing
                      })
                      .catch((rej_DSMD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }
            }}
            variant="outlined"
          >
            {objectToArray(invoiceApprovalTypeOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={option['key']}
                disabled={getProp(option, 'disabled', false)}
              >
                {option['value']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    )
  }
  return dropdownJSX
}

const rJSX_AutomaticBillingCheckbox = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  rateKey: string,
): JSX.Element => {
  const returnCheckboxBoolean = () => {
    if (
      rowData != null &&
      rowData.key != null &&
      tableAdditionalData != null &&
      tableAdditionalData.us_invoiceRates != null &&
      tableAdditionalData.us_invoiceRates[rateKey] != null &&
      tableAdditionalData.us_invoiceRates[rateKey]['automatic_billing'] != null
    ) {
      return tableAdditionalData.us_invoiceRates[rateKey]['automatic_billing']
    }
    return false
  }
  let checkboxJSX = (
    <Box>
      <FormControlLabel
        control={
          <Switch
            disabled={true}
            checked={returnCheckboxBoolean()}
            onChange={(event) => {
              if (event != null && event.target != null && event.target.checked != null) {
                let updateObject: TsInterface_UnspecifiedObject = {
                  automatic_billing: event.target.checked,
                }
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(
                      DatabaseRef_SalesPartner_InvoiceRates_Task_Document(res_GCK.clientKey, tableAdditionalData.us_selectedSalesPartnerKey, rateKey),
                      updateObject,
                    )
                      .then((res_DSMD) => {
                        // Nothing
                      })
                      .catch((rej_DSMD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }
            }}
          />
        }
        label={<Box className="tw-inline-block">{returnCheckboxBoolean() ? rLIB('Automatic Billing') : rLIB('Manual Billing')}</Box>}
      />
    </Box>
  )
  return checkboxJSX
}

const rJSX_BillingSchedule = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  rateKey: string,
): JSX.Element => {
  let cellJSX = <></>
  const rJSX_DeleteBillingTimeIcon = (billingTimeKey: string) => {
    let iconJSX = (
      <Icon
        icon="trash"
        className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-error_main tw-ml-1"
        tooltip={rLIB('Delete')}
        tooltipPlacement="right"
        onClick={() => {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete invoice time'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure you want to delete this invoice time?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  if (
                    rowData != null &&
                    rowData.key != null &&
                    tableAdditionalData != null &&
                    tableAdditionalData.us_invoiceRates != null &&
                    tableAdditionalData.us_invoiceRates[rateKey] != null
                  ) {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      billing_times: {
                        [billingTimeKey]: null,
                      },
                    }
                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(
                          DatabaseRef_SalesPartner_InvoiceRates_Task_Document(res_GCK.clientKey, tableAdditionalData.us_selectedSalesPartnerKey, rateKey),
                          updateObject,
                        )
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                        reject(rej_GCK)
                      })
                  }
                })
              },
            },
          })
        }}
      />
    )
    return iconJSX
  }
  const rJSX_BillingTimesLineItem = (billingTime: TsInterface_UnspecifiedObject) => {
    let lineItemJSX = <></>
    if (
      // If billing_type is percent and the percent doesn't add up to 100
      rowData != null &&
      rowData.key != null &&
      tableAdditionalData != null &&
      tableAdditionalData.us_invoiceRates != null &&
      tableAdditionalData.us_invoiceRates[rateKey] != null &&
      tableAdditionalData.us_invoiceRates[rateKey]['billing_type'] == 'percent'
    ) {
      lineItemJSX = (
        <Box>
          <Box
            className="tw-inline-block tw-mr-2"
            sx={{ background: themeVariables.info_main, borderRadius: '4px', padding: '1px 4px', marginBottom: '4px' }}
          >
            {billingTime.invoice_suffix}
          </Box>
          <Typography
            className="tw-inline-block tw-mr-2"
            sx={{ fontSize: '14px' }}
          >
            {billingTime.associated_task_name}:
          </Typography>
          <Typography
            className="tw-inline-block"
            sx={{ fontSize: '14px' }}
          >
            {billingTime.invoice_amount_percent}%
          </Typography>
          {rJSX_DeleteBillingTimeIcon(billingTime.associated_task_key)}
        </Box>
      )
    } else if (
      rowData != null &&
      rowData.key != null &&
      tableAdditionalData != null &&
      tableAdditionalData.us_invoiceRates != null &&
      tableAdditionalData.us_invoiceRates[rateKey] != null &&
      tableAdditionalData.us_invoiceRates[rateKey]['billing_type'] == 'deposit'
    ) {
      if (billingTime != null && billingTime.invoice_amount_type === 'deposit') {
        lineItemJSX = (
          <Box>
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{ background: themeVariables.info_main, borderRadius: '4px', padding: '1px 4px', marginBottom: '4px' }}
            >
              {billingTime.invoice_suffix}
            </Box>
            <Typography
              className="tw-inline-block tw-mr-2"
              sx={{ fontSize: '' }}
            >
              {billingTime.associated_task_name}:
            </Typography>
            <Typography
              className="tw-inline-block"
              sx={{ fontSize: '' }}
            >
              {formatCurrency(billingTime.invoice_deposit_amount)}
            </Typography>
            {rJSX_DeleteBillingTimeIcon(billingTime.associated_task_key)}
          </Box>
        )
      } else if (billingTime != null && billingTime.invoice_amount_type === 'remainder') {
        lineItemJSX = (
          <Box>
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{ background: themeVariables.info_main, borderRadius: '4px', padding: '1px 4px', marginBottom: '4px' }}
            >
              {billingTime.invoice_suffix}
            </Box>
            <Typography
              className="tw-inline-block tw-mr-2"
              sx={{ fontSize: '' }}
            >
              {billingTime.associated_task_name}:
            </Typography>
            <Typography
              className="tw-inline-block"
              sx={{ fontSize: '' }}
            >
              {rLIB('Remainder')}
            </Typography>
            {rJSX_DeleteBillingTimeIcon(billingTime.associated_task_key)}
          </Box>
        )
      } else {
        lineItemJSX = <Json data={billingTime} />
      }
    }
    return lineItemJSX
  }
  const rJSX_BillingTimeErrors = () => {
    let errorsJSX = <></>
    // No Billing Times
    if (
      rowData != null &&
      rowData.key != null &&
      tableAdditionalData != null &&
      tableAdditionalData.us_invoiceRates != null &&
      tableAdditionalData.us_invoiceRates[rateKey] != null &&
      (tableAdditionalData.us_invoiceRates[rateKey]['billing_times'] == null ||
        objectToArray(tableAdditionalData.us_invoiceRates[rateKey]['billing_times']).length == 0)
    ) {
      errorsJSX = <Box className="tw-rounded tw-bg-error_main tw-p-1 tw-inline-block">{rLIB('No times set')}</Box>
    } else if (
      // If billing_type is percent and the percent doesn't add up to 100
      rowData != null &&
      rowData.key != null &&
      tableAdditionalData != null &&
      tableAdditionalData.us_invoiceRates != null &&
      tableAdditionalData.us_invoiceRates[rateKey] != null &&
      tableAdditionalData.us_invoiceRates[rateKey]['billing_type'] == 'percent'
    ) {
      let percentSum = 0
      for (let billingTimeKey in tableAdditionalData.us_invoiceRates[rateKey]['billing_times']) {
        if (tableAdditionalData.us_invoiceRates[rateKey]['billing_times'][billingTimeKey] != null) {
          percentSum += tableAdditionalData.us_invoiceRates[rateKey]['billing_times'][billingTimeKey]['invoice_amount_percent']
        }
      }
      if (percentSum != 100) {
        errorsJSX = <Box className="tw-rounded tw-bg-error_main tw-p-1 tw-inline-block">{rLIB("Percents don't add up to 100%")}</Box>
      }
    } else if (
      rowData != null &&
      rowData.key != null &&
      tableAdditionalData != null &&
      tableAdditionalData.us_invoiceRates != null &&
      tableAdditionalData.us_invoiceRates[rateKey] != null &&
      tableAdditionalData.us_invoiceRates[rateKey]['billing_type'] == 'deposit'
    ) {
      let remainderCount = 0
      for (let billingTimeKey in tableAdditionalData.us_invoiceRates[rateKey]['billing_times']) {
        if (tableAdditionalData.us_invoiceRates[rateKey]['billing_times'][billingTimeKey] != null) {
          if (tableAdditionalData.us_invoiceRates[rateKey]['billing_times'][billingTimeKey]['invoice_amount_type'] == 'remainder') {
            remainderCount++
          }
        }
      }
      // If billing_type is deposit and there is not a remainder
      if (remainderCount == 0) {
        errorsJSX = <Box className="tw-rounded tw-bg-error_main tw-p-1 tw-inline-block">{rLIB('There must be one remainder')}</Box>
        // If billing_type is deposit and there are multiple remainders
      } else if (remainderCount > 1) {
        errorsJSX = <Box className="tw-rounded tw-bg-error_main tw-p-1 tw-inline-block">{rLIB('There must be only one remainder')}</Box>
      }
    }
    //
    return <Box className="tw-my-1">{errorsJSX}</Box>
  }
  const rJSX_addBillingTimeIconJSX = () => {
    let iconJSX = <></>
    if (
      rowData != null &&
      rowData.key != null &&
      tableAdditionalData != null &&
      tableAdditionalData.us_invoiceRates != null &&
      tableAdditionalData.us_invoiceRates[rateKey] != null &&
      tableAdditionalData.us_invoiceRates[rateKey]['billing_type'] != null &&
      (tableAdditionalData.us_invoiceRates[rateKey]['billing_type'] == 'percent' || tableAdditionalData.us_invoiceRates[rateKey]['billing_type'] == 'deposit')
    ) {
      iconJSX = (
        <Icon
          icon="circle-plus"
          className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-success_main"
          tooltip={rLIB('Add billing time')}
          tooltipPlacement="right"
          onClick={() => {
            // Get Tasks
            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseGetDocument(DatabaseRef_TaskWorkflowProd_Document(res_GCK.clientKey, rowData.key as string))
                  .then((res_GD) => {
                    let listOfTasks: TsInterface_UnspecifiedObject = {}
                    if (res_GD != null && res_GD.data != null && res_GD.data.tasks != null) {
                      for (let taskKey in res_GD.data.tasks) {
                        if (res_GD.data.tasks[taskKey] != null) {
                          listOfTasks[taskKey] = {
                            key: taskKey,
                            value: res_GD.data.tasks[taskKey].name,
                          }
                        }
                      }
                    }
                    // Percent Form Inputs
                    let percentFormInputs: TsInterface_FormInputs = {
                      associated_task_key: {
                        data_type: 'string',
                        input_type: 'multiple_choice_select',
                        key: 'associated_task_key',
                        label: rLIB('Task to bill after'),
                        required: true,
                        options: Object.values(listOfTasks).sort(dynamicSort('value', 'asc')),
                      },
                      invoice_suffix: {
                        data_type: 'string',
                        input_type: 'text_basic',
                        key: 'invoice_suffix',
                        label: rLIB('Invoice Suffix'),
                        required: true,
                      },
                      invoice_amount_percent: {
                        data_type: 'number',
                        input_type: 'text_number',
                        key: 'invoice_amount_percent',
                        label: rLIB('Invoice Amount Percent'),
                        required: true,
                      },

                      // TODO: Percents don't add up to 100%
                    }
                    // Deposit Form Inputs
                    let depositFormInputs: TsInterface_FormInputs = {
                      associated_task_key: {
                        data_type: 'string',
                        input_type: 'multiple_choice_select',
                        key: 'associated_task_key',
                        label: rLIB('Task to bill after'),
                        required: true,
                        options: Object.values(listOfTasks).sort(dynamicSort('value', 'asc')),
                      },
                      invoice_suffix: {
                        data_type: 'string',
                        input_type: 'text_basic',
                        key: 'invoice_suffix',
                        label: rLIB('Invoice Suffix'),
                        required: true,
                      },
                      invoice_amount_type: {
                        data_type: 'string',
                        input_type: 'multiple_choice_select',
                        key: 'invoice_amount_type',
                        label: rLIB('Invoice Amount Type'),
                        required: true,
                        options: Object.values(depositInvoiceAmountTypeOptions),
                      },
                      invoice_deposit_amount: {
                        data_type: 'number',
                        input_type: 'text_number',
                        key: 'invoice_deposit_amount',
                        label: rLIB('Deposit Amount'),
                        required: true,
                        conditional_display: {
                          active: true,
                          logic: {
                            active: true,
                            logic_type: 'comparison',
                            source: 'formData',
                            prop: 'invoice_amount_type',
                            comparator: '==',
                            value: 'deposit',
                            conditions: [],
                          },
                        },
                        conditional_require: {
                          active: true,
                          logic: {
                            active: true,
                            logic_type: 'comparison',
                            source: 'formData',
                            prop: 'invoice_amount_type',
                            comparator: '==',
                            value: 'deposit',
                            conditions: [],
                          },
                        },
                      },

                      // TODO: Error if no remainder / multiple remainders
                    }
                    // Billing Type
                    let billingType = ''
                    if (
                      rowData != null &&
                      rowData.key != null &&
                      tableAdditionalData != null &&
                      tableAdditionalData.us_invoiceRates != null &&
                      tableAdditionalData.us_invoiceRates[rateKey] != null &&
                      tableAdditionalData.us_invoiceRates[rateKey]['billing_type'] != null
                    ) {
                      billingType = tableAdditionalData.us_invoiceRates[rateKey]['billing_type']
                    }
                    // Select Form Inputs
                    let selectedFormInputs: TsInterface_FormInputs = {}
                    if (billingType == 'percent') {
                      selectedFormInputs = percentFormInputs
                    } else if (billingType == 'deposit') {
                      selectedFormInputs = depositFormInputs
                    } else {
                      selectedFormInputs = {}
                    }
                    // Open form dialog
                    tableHooks.uc_setUserInterface_FormDialogDisplay({
                      display: true,
                      form: {
                        form: {
                          formAdditionalData: {},
                          formData: {},
                          formInputs: selectedFormInputs,
                          formOnChange: (
                            formAdditionalData: TsInterface_FormAdditionalData,
                            formData: TsInterface_FormData,
                            formInputs: TsInterface_FormInputs,
                            formSettings: TsInterface_FormSettings,
                          ) => {},
                          formSettings: {},
                          formSubmission: (
                            formSubmittedData: TsInterface_FormSubmittedData,
                            formAdditionalData: TsInterface_FormAdditionalData,
                            formHooks: TsInterface_FormHooksObject,
                          ) => {
                            return new Promise((resolve, reject) => {
                              let updateObject: TsInterface_UnspecifiedObject = {}
                              if (formSubmittedData != null && formSubmittedData['associated_task_key'] != null) {
                                updateObject['billing_times'] = {
                                  [formSubmittedData['associated_task_key']]: {
                                    associated_task_key: formSubmittedData['associated_task_key'],
                                    associated_task_name: listOfTasks[formSubmittedData['associated_task_key']].value,
                                    invoice_amount_percent: getProp(formSubmittedData, 'invoice_amount_percent', null),
                                    invoice_amount_type: getProp(formSubmittedData, 'invoice_amount_type', null),
                                    invoice_deposit_amount: getProp(formSubmittedData, 'invoice_deposit_amount', null),
                                    invoice_suffix: getProp(formSubmittedData, 'invoice_suffix', null),
                                  },
                                }
                              }
                              // Save to database
                              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                                .then((res_GCK) => {
                                  DatabaseSetMergeDocument(
                                    DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                                      res_GCK.clientKey,
                                      tableAdditionalData.us_selectedSalesPartnerKey,
                                      rateKey,
                                    ),
                                    updateObject,
                                  )
                                    .then((res_DSMD) => {
                                      resolve(res_DSMD)
                                    })
                                    .catch((rej_DSMD) => {
                                      reject(rej_DSMD)
                                    })
                                })
                                .catch((rej_GCK) => {
                                  console.error(rej_GCK)
                                  reject(rej_GCK)
                                })
                            })
                          },
                        },
                        dialog: {
                          formDialogHeaderColor: 'success',
                          formDialogHeaderText: rLIB('Add billing time'),
                          formDialogIcon: (
                            <Icon
                              type="solid"
                              icon="circle-plus"
                            />
                          ),
                        },
                      },
                    })
                  })
                  .catch((rej_GC) => {
                    console.error(rej_GC)
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GC.error })
                  })
              })
              .catch((rej_GCK) => {
                console.error(rej_GCK)
              })
          }}
        />
      )
    }
    return iconJSX
  }
  const rJSX_BillingTimeLineItems = () => {
    let lineItemsJSX = <></>
    if (
      rowData != null &&
      rowData.key != null &&
      tableAdditionalData != null &&
      tableAdditionalData.us_invoiceRates != null &&
      tableAdditionalData.us_invoiceRates[rateKey] != null &&
      tableAdditionalData.us_invoiceRates[rateKey]['billing_times'] != null
    ) {
      lineItemsJSX = (
        <Box>
          {objectToArray(tableAdditionalData.us_invoiceRates[rateKey]['billing_times'])
            .sort(dynamicSort('invoice_suffix', 'asc'))
            .map((billingTime: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>{rJSX_BillingTimesLineItem(billingTime)}</Box>
            ))}
        </Box>
      )
    }
    return lineItemsJSX
  }
  // Full JSX
  cellJSX = (
    <Box>
      {rJSX_BillingTimeLineItems()}
      {rJSX_addBillingTimeIconJSX()}
      {rJSX_BillingTimeErrors()}
    </Box>
  )
  return cellJSX
}

// Shared Rates Content
const rJSX_BaseRateOrAdderRowItemPrice = (item: TsInterface_UnspecifiedObject): JSX.Element => {
  if (item.price_type === 'flat') {
    return <Box sx={{ background: themeVariables.success_main, borderRadius: '5px', padding: '2px' }}>{formatCurrency(item.price)}</Box>
  } else if (item.price_type === 'per_dc_watt') {
    return (
      <Stack
        direction="row"
        spacing={1}
        sx={{ background: themeVariables.warning_main, borderRadius: '5px', padding: '2px' }}
      >
        {formatCurrency(item.price)}
        <Box className="tw-opacity-60 tw-ml-1">({rLIB('Per Watt')})</Box>
      </Stack>
    )
  } else if (item.price_type === 'per_panel') {
    return (
      <Stack
        direction="row"
        spacing={1}
        sx={{ background: themeVariables.info_main, borderRadius: '5px', padding: '2px' }}
      >
        {formatCurrency(item.price)}
        <Box className="tw-opacity-60 tw-ml-1">({rLIB('Per Panel')})</Box>
      </Stack>
    )
  } else {
    return <Json data={item} />
  }
}

// Base Rate
const rJSX_BaseRateNewIcon = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  rateKey: string,
  regionKey: string,
): JSX.Element => {
  let iconJSX = <></>
  let formInputs_BaseRate: TsInterface_FormInputs = {
    name: {
      key: 'name',
      label: rLIB('Name'),
      input_type: 'text_basic',
      required: true,
      data_type: 'string',
    },
    price: {
      key: 'price',
      label: rLIB('Price'),
      input_type: 'text_number',
      required: true,
      data_type: 'number',
    },
    price_type: {
      data_type: 'string',
      input_type: 'multiple_choice_radio',
      key: 'price_type',
      label: rLIB('Pricing type'),
      required: true,
      options: Object.values(projectInvoicePriceTypeOptions),
    },
  }
  iconJSX = (
    <Icon
      icon="circle-plus"
      tooltip={rLIB('Add Base Rate')}
      tooltipPlacement="right"
      className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-success_main"
      onClick={() => {
        tableHooks.uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: {
            form: {
              formAdditionalData: {},
              formData: {},
              formInputs: formInputs_BaseRate,
              formOnChange: (
                formAdditionalData: TsInterface_FormAdditionalData,
                formData: TsInterface_FormData,
                formInputs: TsInterface_FormInputs,
                formSettings: TsInterface_FormSettings,
              ) => {},
              formSettings: {},
              formSubmission: (
                formSubmittedData: TsInterface_FormSubmittedData,
                formAdditionalData: TsInterface_FormAdditionalData,
                formHooks: TsInterface_FormHooksObject,
              ) => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        ['base_rates_' + regionKey]: {
                          [formSubmittedData.name]: {
                            name: formSubmittedData.name,
                            price: formSubmittedData.price,
                            price_type: formSubmittedData.price_type,
                          },
                        },
                      }
                      DatabaseSetMergeDocument(
                        DatabaseRef_SalesPartner_InvoiceRates_Task_Document(res_GCK.clientKey, tableAdditionalData.us_selectedSalesPartnerKey, rateKey),
                        updateObject,
                      )
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
            dialog: {
              formDialogHeaderColor: 'success',
              formDialogHeaderText: <>{rLIB('New Base Rate Line Item')}</>,
              formDialogIcon: (
                <Icon
                  type="solid"
                  icon="pen-to-square"
                />
              ),
            },
          },
        })
      }}
    />
  )
  return iconJSX
}

const rJSX_BaseRateRows = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  rateKey: string,
  regionKey: string,
): JSX.Element => {
  let rowsJSX = <></>
  let itemCount = 0
  if (
    rateKey != null &&
    tableAdditionalData != null &&
    tableAdditionalData.us_invoiceRates != null &&
    tableAdditionalData.us_invoiceRates[rateKey] != null &&
    tableAdditionalData.us_invoiceRates[rateKey]['base_rates_' + regionKey] != null
  ) {
    let baseRates = tableAdditionalData.us_invoiceRates[rateKey]['base_rates_' + regionKey]
    itemCount = objectToArray(baseRates).length
    rowsJSX = (
      <Box>
        {objectToArray(baseRates)
          .sort(dynamicSort('name', null))
          .map((baseRateData: TsInterface_UnspecifiedObject, baseRateIndex: number) => (
            <Box key={baseRateIndex}>
              <Stack
                direction="row"
                spacing={1}
                className="tw-mb-1"
              >
                <Box>{baseRateData['name']}</Box>
                {rJSX_BaseRateOrAdderRowItemPrice(baseRateData)}
                <Icon
                  icon="trash"
                  className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-error_main"
                  tooltip={rLIB('Delete')}
                  tooltipPlacement="right"
                  onClick={() => {
                    tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
                      display: true,
                      confirm: {
                        color: 'error',
                        icon: <Icon icon="trash-xmark" />,
                        header: rLIB('Delete Base Rate'),
                        text: <>{rLIB('Are you sure that you want to delete this base rate line item?')}</>,
                        submit_text: rLIB('Delete'),
                        submit_callback: () => {
                          return new Promise((resolve, reject) => {
                            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                let updateObject = {
                                  ['base_rates_' + regionKey]: {
                                    [baseRateData['name']]: null,
                                  },
                                }
                                DatabaseSetMergeDocument(
                                  DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                                    res_GCK.clientKey,
                                    tableAdditionalData.us_selectedSalesPartnerKey,
                                    rateKey,
                                  ),
                                  updateObject,
                                )
                                  .then((res_DSMD) => {
                                    resolve({ success: true })
                                  })
                                  .catch((rej_DSMD) => {
                                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  })
                              })
                              .catch((rej_GCK) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              })
                          })
                        },
                      },
                    })
                  }}
                />
              </Stack>
            </Box>
          ))}
      </Box>
    )
  }
  if (itemCount === 0) {
    rowsJSX = (
      <Box
        className="tw-inline-block tw-rounded tw-px-2 tw-py-1"
        sx={{ background: themeVariables.gray_700 }}
      >
        <Typography
          sx={{ fontSize: '14px' }}
          className="tw-opacity-50"
        >
          <Icon
            icon="circle-exclamation"
            className="tw-mr-1"
          />
          {rLIB('No base rates')}
        </Typography>
      </Box>
    )
  }
  return rowsJSX
}

// Adders
const rJSX_DistanceAdderNewIcon = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  rateKey: string,
  regionKey: string,
): JSX.Element => {
  let iconJSX = <></>
  let formInputs_DistanceCutoff: TsInterface_FormInputs = {
    cutoff_miles: {
      key: 'cutoff_miles',
      label: rLIB('Mile Cutoff'),
      input_type: 'text_number',
      required: true,
      data_type: 'number',
    },
    price: {
      key: 'price',
      label: rLIB('Price'),
      input_type: 'text_number',
      required: true,
      data_type: 'number',
    },
    price_type: {
      data_type: 'string',
      input_type: 'multiple_choice_radio',
      key: 'price_type',
      label: rLIB('Pricing type'),
      required: true,
      options: Object.values(projectInvoicePriceTypeOptions),
    },
  }
  iconJSX = (
    <Icon
      icon="circle-plus"
      tooltip={rLIB('Add distance cutoff')}
      tooltipPlacement="right"
      className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-success_main"
      onClick={() => {
        tableHooks.uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: {
            form: {
              formAdditionalData: {},
              formData: {},
              formInputs: formInputs_DistanceCutoff,
              formOnChange: (
                formAdditionalData: TsInterface_FormAdditionalData,
                formData: TsInterface_FormData,
                formInputs: TsInterface_FormInputs,
                formSettings: TsInterface_FormSettings,
              ) => {},
              formSettings: {},
              formSubmission: (
                formSubmittedData: TsInterface_FormSubmittedData,
                formAdditionalData: TsInterface_FormAdditionalData,
                formHooks: TsInterface_FormHooksObject,
              ) => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        ['adders_distance_' + regionKey]: {
                          [formSubmittedData.cutoff_miles]: {
                            cutoff_miles: formSubmittedData.cutoff_miles,
                            price: formSubmittedData.price,
                            price_type: formSubmittedData.price_type,
                          },
                        },
                      }
                      DatabaseSetMergeDocument(
                        DatabaseRef_SalesPartner_InvoiceRates_Task_Document(res_GCK.clientKey, tableAdditionalData.us_selectedSalesPartnerKey, rateKey),
                        updateObject,
                      )
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
            dialog: {
              formDialogHeaderColor: 'success',
              formDialogHeaderText: <>{rLIB('New Distance Adder Cutoff')}</>,
              formDialogIcon: (
                <Icon
                  type="solid"
                  icon="pen-to-square"
                />
              ),
            },
          },
        })
      }}
    />
  )
  return iconJSX
}

const rJSX_DistanceAdderRows = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  rateKey: string,
  regionKey: string,
): JSX.Element => {
  let rowsJSX = <></>
  let itemCount = 0
  if (
    rateKey != null &&
    tableAdditionalData != null &&
    tableAdditionalData.us_invoiceRates != null &&
    tableAdditionalData.us_invoiceRates[rateKey] != null &&
    tableAdditionalData.us_invoiceRates[rateKey]['adders_distance_' + regionKey] != null
  ) {
    let adders = tableAdditionalData.us_invoiceRates[rateKey]['adders_distance_' + regionKey]
    itemCount = objectToArray(adders).length
    rowsJSX = (
      <Box>
        {objectToArray(adders)
          .sort(dynamicSort('cutoff_miles', null))
          .map((adderData: TsInterface_UnspecifiedObject, adderIndex: number) => (
            <Box key={adderIndex}>
              <Stack
                direction="row"
                spacing={1}
                className="tw-mb-1"
              >
                <Box>
                  {adderData['cutoff_miles']} {rLIB('Miles')}
                </Box>
                {rJSX_BaseRateOrAdderRowItemPrice(adderData)}
                <Icon
                  icon="trash"
                  className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-error_main"
                  tooltip={rLIB('Delete')}
                  tooltipPlacement="right"
                  onClick={() => {
                    tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
                      display: true,
                      confirm: {
                        color: 'error',
                        icon: <Icon icon="trash-xmark" />,
                        header: rLIB('Delete Cutoff'),
                        text: <>{rLIB('Are you sure that you want to delete this mileage cutoff?')}</>,
                        submit_text: rLIB('Delete'),
                        submit_callback: () => {
                          return new Promise((resolve, reject) => {
                            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                let updateObject = {
                                  ['adders_distance_' + regionKey]: {
                                    [adderData['cutoff_miles']]: null,
                                  },
                                }
                                DatabaseSetMergeDocument(
                                  DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                                    res_GCK.clientKey,
                                    tableAdditionalData.us_selectedSalesPartnerKey,
                                    rateKey,
                                  ),
                                  updateObject,
                                )
                                  .then((res_DSMD) => {
                                    resolve({ success: true })
                                  })
                                  .catch((rej_DSMD) => {
                                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  })
                              })
                              .catch((rej_GCK) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              })
                          })
                        },
                      },
                    })
                  }}
                />
              </Stack>
            </Box>
          ))}
      </Box>
    )
  }
  if (itemCount === 0) {
    rowsJSX = (
      <Box
        className="tw-inline-block tw-rounded tw-px-2 tw-py-1"
        sx={{ background: themeVariables.gray_700 }}
      >
        <Typography
          sx={{ fontSize: '14px' }}
          className="tw-opacity-50"
        >
          <Icon
            icon="circle-exclamation"
            className="tw-mr-1"
          />
          {rLIB('No distance adders')}
        </Typography>
      </Box>
    )
  }
  return rowsJSX
}

const rJSX_PitchAdderNewIcon = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  rateKey: string,
  regionKey: string,
): JSX.Element => {
  let iconJSX = <></>
  let formInputs_PitchCutoff: TsInterface_FormInputs = {
    cutoff_pitch: {
      key: 'cutoff_pitch',
      label: rLIB('Cutoff Pitch'),
      input_type: 'text_number',
      required: true,
      data_type: 'number',
    },
    price: {
      key: 'price',
      label: rLIB('Price'),
      input_type: 'text_number',
      required: true,
      data_type: 'number',
    },
    price_type: {
      data_type: 'string',
      input_type: 'multiple_choice_radio',
      key: 'price_type',
      label: rLIB('Pricing type'),
      required: true,
      options: Object.values(projectInvoicePriceTypeOptions),
    },
  }
  iconJSX = (
    <Icon
      icon="circle-plus"
      tooltip={rLIB('Add pitch cutoff')}
      tooltipPlacement="right"
      className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-success_main"
      onClick={() => {
        tableHooks.uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: {
            form: {
              formAdditionalData: {},
              formData: {},
              formInputs: formInputs_PitchCutoff,
              formOnChange: (
                formAdditionalData: TsInterface_FormAdditionalData,
                formData: TsInterface_FormData,
                formInputs: TsInterface_FormInputs,
                formSettings: TsInterface_FormSettings,
              ) => {},
              formSettings: {},
              formSubmission: (
                formSubmittedData: TsInterface_FormSubmittedData,
                formAdditionalData: TsInterface_FormAdditionalData,
                formHooks: TsInterface_FormHooksObject,
              ) => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        ['adders_pitch_' + regionKey]: {
                          [formSubmittedData.cutoff_pitch]: {
                            cutoff_pitch: formSubmittedData.cutoff_pitch,
                            price: formSubmittedData.price,
                            price_type: formSubmittedData.price_type,
                          },
                        },
                      }
                      DatabaseSetMergeDocument(
                        DatabaseRef_SalesPartner_InvoiceRates_Task_Document(res_GCK.clientKey, tableAdditionalData.us_selectedSalesPartnerKey, rateKey),
                        updateObject,
                      )
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
            dialog: {
              formDialogHeaderColor: 'success',
              formDialogHeaderText: <>{rLIB('New Pitch Adder Cutoff')}</>,
              formDialogIcon: (
                <Icon
                  type="solid"
                  icon="pen-to-square"
                />
              ),
            },
          },
        })
      }}
    />
  )
  return iconJSX
}

const rJSX_PitchAdderRows = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  rateKey: string,
  regionKey: string,
): JSX.Element => {
  let rowsJSX = <></>
  let itemCount = 0
  if (
    rateKey != null &&
    tableAdditionalData != null &&
    tableAdditionalData.us_invoiceRates != null &&
    tableAdditionalData.us_invoiceRates[rateKey] != null &&
    tableAdditionalData.us_invoiceRates[rateKey]['adders_pitch_' + regionKey] != null
  ) {
    let adders = tableAdditionalData.us_invoiceRates[rateKey]['adders_pitch_' + regionKey]
    itemCount = objectToArray(adders).length
    rowsJSX = (
      <Box>
        {objectToArray(adders)
          .sort(dynamicSort('cutoff_pitch', null))
          .map((adderData: TsInterface_UnspecifiedObject, adderIndex: number) => (
            <Box key={adderIndex}>
              <Stack
                direction="row"
                spacing={1}
                className="tw-mb-1"
              >
                <Box>{adderData['cutoff_pitch']}°</Box>
                {rJSX_BaseRateOrAdderRowItemPrice(adderData)}
                <Icon
                  icon="trash"
                  className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-error_main"
                  tooltip={rLIB('Delete')}
                  tooltipPlacement="right"
                  onClick={() => {
                    tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
                      display: true,
                      confirm: {
                        color: 'error',
                        icon: <Icon icon="trash-xmark" />,
                        header: rLIB('Delete Cutoff'),
                        text: <>{rLIB('Are you sure that you want to delete this pitch cutoff?')}</>,
                        submit_text: rLIB('Delete'),
                        submit_callback: () => {
                          return new Promise((resolve, reject) => {
                            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                let updateObject = {
                                  ['adders_pitch_' + regionKey]: {
                                    [adderData['cutoff_pitch']]: null,
                                  },
                                }
                                DatabaseSetMergeDocument(
                                  DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                                    res_GCK.clientKey,
                                    tableAdditionalData.us_selectedSalesPartnerKey,
                                    rateKey,
                                  ),
                                  updateObject,
                                )
                                  .then((res_DSMD) => {
                                    resolve({ success: true })
                                  })
                                  .catch((rej_DSMD) => {
                                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  })
                              })
                              .catch((rej_GCK) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              })
                          })
                        },
                      },
                    })
                  }}
                />
              </Stack>
            </Box>
          ))}
      </Box>
    )
  }
  if (itemCount === 0) {
    rowsJSX = (
      <Box
        className="tw-inline-block tw-rounded tw-px-2 tw-py-1"
        sx={{ background: themeVariables.gray_700 }}
      >
        <Typography
          sx={{ fontSize: '14px' }}
          className="tw-opacity-50"
        >
          <Icon
            icon="circle-exclamation"
            className="tw-mr-1"
          />
          {rLIB('No pitch adders')}
        </Typography>
      </Box>
    )
  }
  return rowsJSX
}

const rJSX_BatteryAdderNewIcon = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  rateKey: string,
  regionKey: string,
): JSX.Element => {
  let iconJSX = <></>
  let formInputs_BatteryCutoff: TsInterface_FormInputs = {
    cutoff_battery: {
      key: 'cutoff_battery',
      label: rLIB('Battery Cutoff'),
      input_type: 'text_number',
      required: true,
      data_type: 'number',
    },
    price: {
      key: 'price',
      label: rLIB('Price'),
      input_type: 'text_number',
      required: true,
      data_type: 'number',
    },
    price_type: {
      data_type: 'string',
      input_type: 'multiple_choice_radio',
      key: 'price_type',
      label: rLIB('Pricing type'),
      required: true,
      options: Object.values(projectInvoicePriceTypeOptions),
    },
  }
  iconJSX = (
    <Icon
      icon="circle-plus"
      tooltip={rLIB('Add battery cutoff')}
      tooltipPlacement="right"
      className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-success_main"
      onClick={() => {
        tableHooks.uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: {
            form: {
              formAdditionalData: {},
              formData: {},
              formInputs: formInputs_BatteryCutoff,
              formOnChange: (
                formAdditionalData: TsInterface_FormAdditionalData,
                formData: TsInterface_FormData,
                formInputs: TsInterface_FormInputs,
                formSettings: TsInterface_FormSettings,
              ) => {},
              formSettings: {},
              formSubmission: (
                formSubmittedData: TsInterface_FormSubmittedData,
                formAdditionalData: TsInterface_FormAdditionalData,
                formHooks: TsInterface_FormHooksObject,
              ) => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        ['adders_battery_' + regionKey]: {
                          [formSubmittedData.cutoff_battery]: {
                            cutoff_battery: formSubmittedData.cutoff_battery,
                            price: formSubmittedData.price,
                            price_type: formSubmittedData.price_type,
                          },
                        },
                      }
                      DatabaseSetMergeDocument(
                        DatabaseRef_SalesPartner_InvoiceRates_Task_Document(res_GCK.clientKey, tableAdditionalData.us_selectedSalesPartnerKey, rateKey),
                        updateObject,
                      )
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
            dialog: {
              formDialogHeaderColor: 'success',
              formDialogHeaderText: <>{rLIB('New Battery Adder Cutoff')}</>,
              formDialogIcon: (
                <Icon
                  type="solid"
                  icon="pen-to-square"
                />
              ),
            },
          },
        })
      }}
    />
  )
  return iconJSX
}

const rJSX_BatteryAdderRows = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  rateKey: string,
  regionKey: string,
): JSX.Element => {
  let rowsJSX = <></>
  let itemCount = 0
  const rJSX_BatteryInstallText = (number: number): JSX.Element => {
    let textJSX = rLIB('Battery') as JSX.Element
    if (number > 1) {
      textJSX = rLIB('Batteries') as JSX.Element
    }
    return textJSX
  }
  if (
    rateKey != null &&
    tableAdditionalData != null &&
    tableAdditionalData.us_invoiceRates != null &&
    tableAdditionalData.us_invoiceRates[rateKey] != null &&
    tableAdditionalData.us_invoiceRates[rateKey]['adders_battery_' + regionKey] != null
  ) {
    let adders = tableAdditionalData.us_invoiceRates[rateKey]['adders_battery_' + regionKey]
    itemCount = objectToArray(adders).length
    rowsJSX = (
      <Box>
        {objectToArray(adders)
          .sort(dynamicSort('cutoff_battery', null))
          .map((adderData: TsInterface_UnspecifiedObject, adderIndex: number) => (
            <Box key={adderIndex}>
              <Stack
                direction="row"
                spacing={1}
                className="tw-mb-1"
              >
                <Box>
                  {adderData['cutoff_battery']} {rJSX_BatteryInstallText(adderData['cutoff_battery'])}
                </Box>
                {rJSX_BaseRateOrAdderRowItemPrice(adderData)}
                <Icon
                  icon="trash"
                  className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-error_main"
                  tooltip={rLIB('Delete')}
                  tooltipPlacement="right"
                  onClick={() => {
                    tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
                      display: true,
                      confirm: {
                        color: 'error',
                        icon: <Icon icon="trash-xmark" />,
                        header: rLIB('Delete Cutoff'),
                        text: <>{rLIB('Are you sure that you want to delete this battery cutoff?')}</>,
                        submit_text: rLIB('Delete'),
                        submit_callback: () => {
                          return new Promise((resolve, reject) => {
                            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                let updateObject = {
                                  ['adders_battery_' + regionKey]: {
                                    [adderData['cutoff_battery']]: null,
                                  },
                                }
                                DatabaseSetMergeDocument(
                                  DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                                    res_GCK.clientKey,
                                    tableAdditionalData.us_selectedSalesPartnerKey,
                                    rateKey,
                                  ),
                                  updateObject,
                                )
                                  .then((res_DSMD) => {
                                    resolve({ success: true })
                                  })
                                  .catch((rej_DSMD) => {
                                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  })
                              })
                              .catch((rej_GCK) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              })
                          })
                        },
                      },
                    })
                  }}
                />
              </Stack>
            </Box>
          ))}
      </Box>
    )
  }
  if (itemCount === 0) {
    rowsJSX = (
      <Box
        className="tw-inline-block tw-rounded tw-px-2 tw-py-1"
        sx={{ background: themeVariables.gray_700 }}
      >
        <Typography
          sx={{ fontSize: '14px' }}
          className="tw-opacity-50"
        >
          <Icon
            icon="circle-exclamation"
            className="tw-mr-1"
          />
          {rLIB('No battery adders')}
        </Typography>
      </Box>
    )
  }
  return rowsJSX
}

const rJSX_RoofTypeAdderNewIcon = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  rateKey: string,
  regionKey: string,
): JSX.Element => {
  let iconJSX = <></>
  let formInputs_RoofTypeAdder: TsInterface_FormInputs = {
    roof_type: {
      key: 'roof_type',
      label: rLIB('Roof Type'),
      input_type: 'multiple_choice_radio',
      required: true,
      data_type: 'string',
      options: objectToArray(roofTypeOptions),
    },
    price: {
      key: 'price',
      label: rLIB('Price'),
      input_type: 'text_number',
      required: true,
      data_type: 'number',
    },
    price_type: {
      data_type: 'string',
      input_type: 'multiple_choice_radio',
      key: 'price_type',
      label: rLIB('Pricing type'),
      required: true,
      options: Object.values(projectInvoicePriceTypeOptions),
    },
  }
  iconJSX = (
    <Icon
      icon="circle-plus"
      tooltip={rLIB('Add roof type adder')}
      tooltipPlacement="right"
      className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-success_main"
      onClick={() => {
        tableHooks.uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: {
            form: {
              formAdditionalData: {},
              formData: {},
              formInputs: formInputs_RoofTypeAdder,
              formOnChange: (
                formAdditionalData: TsInterface_FormAdditionalData,
                formData: TsInterface_FormData,
                formInputs: TsInterface_FormInputs,
                formSettings: TsInterface_FormSettings,
              ) => {},
              formSettings: {},
              formSubmission: (
                formSubmittedData: TsInterface_FormSubmittedData,
                formAdditionalData: TsInterface_FormAdditionalData,
                formHooks: TsInterface_FormHooksObject,
              ) => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        ['adders_roof_type_' + regionKey]: {
                          [formSubmittedData.roof_type]: {
                            roof_type: formSubmittedData.roof_type,
                            price: formSubmittedData.price,
                            price_type: formSubmittedData.price_type,
                          },
                        },
                      }
                      DatabaseSetMergeDocument(
                        DatabaseRef_SalesPartner_InvoiceRates_Task_Document(res_GCK.clientKey, tableAdditionalData.us_selectedSalesPartnerKey, rateKey),
                        updateObject,
                      )
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
            dialog: {
              formDialogHeaderColor: 'success',
              formDialogHeaderText: <>{rLIB('New Roof Type Adder')}</>,
              formDialogIcon: (
                <Icon
                  type="solid"
                  icon="pen-to-square"
                />
              ),
            },
          },
        })
      }}
    />
  )
  return iconJSX
}

const rJSX_RoofTypeAdderRows = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  rateKey: string,
  regionKey: string,
): JSX.Element => {
  let rowsJSX = <></>
  let itemCount = 0
  if (
    rateKey != null &&
    tableAdditionalData != null &&
    tableAdditionalData.us_invoiceRates != null &&
    tableAdditionalData.us_invoiceRates[rateKey] != null &&
    tableAdditionalData.us_invoiceRates[rateKey]['adders_roof_type_' + regionKey] != null
  ) {
    let adders = tableAdditionalData.us_invoiceRates[rateKey]['adders_roof_type_' + regionKey]
    itemCount = objectToArray(adders).length
    rowsJSX = (
      <Box>
        {objectToArray(adders)
          .sort(dynamicSort('roof_type', null))
          .map((adderData: TsInterface_UnspecifiedObject, adderIndex: number) => (
            <Box key={adderIndex}>
              <Stack
                direction="row"
                spacing={1}
                className="tw-mb-1"
              >
                <Box>{adderData['roof_type']}</Box>
                {rJSX_BaseRateOrAdderRowItemPrice(adderData)}
                <Icon
                  icon="trash"
                  className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-error_main"
                  tooltip={rLIB('Delete')}
                  tooltipPlacement="right"
                  onClick={() => {
                    tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
                      display: true,
                      confirm: {
                        color: 'error',
                        icon: <Icon icon="trash-xmark" />,
                        header: rLIB('Delete Roof Type Adder'),
                        text: <>{rLIB('Are you sure that you want to delete this roof type adder?')}</>,
                        submit_text: rLIB('Delete'),
                        submit_callback: () => {
                          return new Promise((resolve, reject) => {
                            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                let updateObject = {
                                  ['adders_roof_type_' + regionKey]: {
                                    [adderData['roof_type']]: null,
                                  },
                                }
                                DatabaseSetMergeDocument(
                                  DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                                    res_GCK.clientKey,
                                    tableAdditionalData.us_selectedSalesPartnerKey,
                                    rateKey,
                                  ),
                                  updateObject,
                                )
                                  .then((res_DSMD) => {
                                    resolve({ success: true })
                                  })
                                  .catch((rej_DSMD) => {
                                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  })
                              })
                              .catch((rej_GCK) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              })
                          })
                        },
                      },
                    })
                  }}
                />
              </Stack>
            </Box>
          ))}
      </Box>
    )
  }
  if (itemCount === 0) {
    rowsJSX = (
      <Box
        className="tw-inline-block tw-rounded tw-px-2 tw-py-1"
        sx={{ background: themeVariables.gray_700 }}
      >
        <Typography
          sx={{ fontSize: '14px' }}
          className="tw-opacity-50"
        >
          <Icon
            icon="circle-exclamation"
            className="tw-mr-1"
          />
          {rLIB('No roof type adders')}
        </Typography>
      </Box>
    )
  }
  return rowsJSX
}

const rJSX_RegionRatesCellContent = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  rateKey: string,
  regionKey: string,
): JSX.Element => {
  let cellJSX = (
    <Box>
      <Stack
        direction="row"
        spacing={2}
      >
        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{rLIB('Base Rate')}:</Typography>
        <Box
          className="tw-ml-1"
          sx={{ marginTop: '3px' }}
        >
          {rJSX_BaseRateNewIcon(rowData, tableAdditionalData, tableHooks, rateKey, regionKey)}
        </Box>
      </Stack>
      <Box className="tw-pl-6">{rJSX_BaseRateRows(rowData, tableAdditionalData, tableHooks, rateKey, regionKey)}</Box>
      <Divider className="tw-my-2" />
      <Stack
        direction="row"
        spacing={2}
      >
        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{rLIB('Distance Adder')}:</Typography>
        <Box
          className="tw-ml-1"
          sx={{ marginTop: '3px' }}
        >
          {rJSX_DistanceAdderNewIcon(rowData, tableAdditionalData, tableHooks, rateKey, regionKey)}
        </Box>
      </Stack>
      <Box className="tw-pl-6">{rJSX_DistanceAdderRows(rowData, tableAdditionalData, tableHooks, rateKey, regionKey)}</Box>
      <Divider className="tw-my-2" />
      <Stack
        direction="row"
        spacing={2}
      >
        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{rLIB('Pitch Adder')}:</Typography>
        <Box
          className="tw-ml-1"
          sx={{ marginTop: '3px' }}
        >
          {rJSX_PitchAdderNewIcon(rowData, tableAdditionalData, tableHooks, rateKey, regionKey)}
        </Box>
      </Stack>
      <Box className="tw-pl-6">{rJSX_PitchAdderRows(rowData, tableAdditionalData, tableHooks, rateKey, regionKey)}</Box>
      <Divider className="tw-my-2" />
      <Stack
        direction="row"
        spacing={2}
      >
        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{rLIB('Battery Adder')}:</Typography>
        <Box
          className="tw-ml-1"
          sx={{ marginTop: '3px' }}
        >
          {rJSX_BatteryAdderNewIcon(rowData, tableAdditionalData, tableHooks, rateKey, regionKey)}
        </Box>
      </Stack>
      <Box className="tw-pl-6">{rJSX_BatteryAdderRows(rowData, tableAdditionalData, tableHooks, rateKey, regionKey)}</Box>
      <Divider className="tw-my-2" />
      <Stack
        direction="row"
        spacing={2}
      >
        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{rLIB('Roof Type Adder')}:</Typography>
        <Box
          className="tw-ml-1"
          sx={{ marginTop: '3px' }}
        >
          {rJSX_RoofTypeAdderNewIcon(rowData, tableAdditionalData, tableHooks, rateKey, regionKey)}
        </Box>
      </Stack>
      <Box className="tw-pl-6">{rJSX_RoofTypeAdderRows(rowData, tableAdditionalData, tableHooks, rateKey, regionKey)}</Box>
    </Box>
  )
  return cellJSX
}

// Region Override
const rJSX_RegionOverrideToggle = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  rateKey: string,
  regionKey: string,
): JSX.Element => {
  const returnCheckboxBoolean = () => {
    if (
      rowData != null &&
      rowData.key != null &&
      tableAdditionalData != null &&
      tableAdditionalData.us_invoiceRates != null &&
      tableAdditionalData.us_invoiceRates[rateKey] != null &&
      tableAdditionalData.us_invoiceRates[rateKey]['use_region_override'] != null &&
      tableAdditionalData.us_invoiceRates[rateKey]['use_region_override'][regionKey] != null
    ) {
      return tableAdditionalData.us_invoiceRates[rateKey]['use_region_override'][regionKey]
    }
    return false
  }
  let checkboxJSX = (
    <Box>
      <FormControlLabel
        control={
          <Switch
            checked={returnCheckboxBoolean()}
            onChange={(event) => {
              if (event != null && event.target != null && event.target.checked != null) {
                let updateObject: TsInterface_UnspecifiedObject = {
                  use_region_override: {
                    [regionKey]: event.target.checked,
                  },
                }
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(
                      DatabaseRef_SalesPartner_InvoiceRates_Task_Document(res_GCK.clientKey, tableAdditionalData.us_selectedSalesPartnerKey, rateKey),
                      updateObject,
                    )
                      .then((res_DSMD) => {
                        // Nothing
                      })
                      .catch((rej_DSMD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }
            }}
          />
        }
        label={<Box className="tw-inline-block">{returnCheckboxBoolean() ? rLIB('Use Region Override') : rLIB('No Region Override')}</Box>}
      />
    </Box>
  )
  return checkboxJSX
}

///////////////////////////////
// Exports
///////////////////////////////

export const tableSettings_InvoiceBasePricing: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 180px)',
  alternate_row_colors: true,
  alternate_row_color_hex: themeVariables.background_hover,
}

export const tableColumns_InvoiceBasePricing: TsInterface_TableColumns = {
  name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = <Box>{rLIB('Workflow Name')}</Box>
        return headerJSX
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <Box>
            <Typography sx={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '4px' }}>{rowData.name}</Typography>
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = 'tw-align-top'
        return cellCSS
      },
    },
  },
  CUSTOM_billing: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = <Box>{rLIB('Billing')}</Box>
        return headerJSX
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const rJSX_WorkflowSetting = () => {
          let workflowSettingJSX = <></>
          if (
            tableAdditionalData != null &&
            tableAdditionalData.us_taskWorkflows != null &&
            rowData != null &&
            rowData.key != null &&
            tableAdditionalData.us_taskWorkflows[rowData.key as string] != null
          ) {
            let taskWorkflowData = tableAdditionalData.us_taskWorkflows[rowData.key as string]
            if (taskWorkflowData.projects_using_invoicing === true) {
              workflowSettingJSX = (
                <Stack
                  className="tw-pl-4"
                  direction="row"
                >
                  <Switch
                    checked={true}
                    onChange={(event) => {
                      tableHooks.uc_setUserInterface_PromptDialogDisplay({
                        display: true,
                        prompt: {
                          color: 'error',
                          confirm_text: rLIB('Deactivate Invoicing'),
                          default_value: '',
                          header: rLIB('Deactivate Invoicing for this workflow'),
                          icon: (
                            <Icon
                              icon="siren-on"
                              type="solid"
                            />
                          ),
                          input_label: rLIB('Type DANGER to proceed'),
                          input_type: 'text',
                          text: rLIB('Are you sure that you want to deactivate invoicing for this workflow?'),
                          submit_callback: (promptValue: string) => {
                            return new Promise((resolve, reject) => {
                              if (promptValue === 'DANGER') {
                                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                                  .then((res_GCK) => {
                                    let taskWorkflowUpdateObject = {
                                      projects_using_invoicing: false,
                                    }
                                    DatabaseSetMergeDocument(
                                      DatabaseRef_TaskWorkflow_Document(res_GCK.clientKey, rowData.key as string),
                                      taskWorkflowUpdateObject,
                                    )
                                      .then((res_DSMD) => {
                                        resolve(res_DSMD)
                                      })
                                      .catch((rej_DSMD) => {
                                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                        resolve({ close_dialog: false })
                                      })
                                  })
                                  .catch((rej_GCK) => {
                                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                    resolve({ close_dialog: false })
                                  })
                              } else {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                                  display: true,
                                  error: {
                                    message: rLIB('Failed to deactivate invoicing'),
                                    details: (
                                      <>
                                        {rLIB('You must enter DANGER in order to proceed.')} {rLIB('Otherwise click dismiss')}
                                      </>
                                    ),
                                    code: 'ER-D-PBP-DI-01',
                                  },
                                })
                                resolve({ close_dialog: false })
                              }
                            })
                          },
                        },
                      })
                    }}
                  />
                  <Box
                    sx={{ backgroundColor: themeVariables.success_light }}
                    className="tw-px-2 tw-pt-2 tw-pb-1 tw-rounded-md tw-inline-block"
                  >
                    <Icon
                      icon="circle-play"
                      className="tw-mr-2"
                      sx={{ fontSize: '14px' }}
                    />
                    {rLIB('Invoicing turned on for this workflow')}
                  </Box>
                </Stack>
              )
            } else {
              workflowSettingJSX = (
                <Stack
                  className="tw-pl-4"
                  direction="row"
                >
                  <Switch
                    checked={false}
                    onChange={(event) => {
                      tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
                        display: true,
                        confirm: {
                          color: 'success',
                          header: rLIB('Activate Invoicing for this workflow'),
                          icon: (
                            <Icon
                              icon="circle-play"
                              type="solid"
                            />
                          ),
                          submit_text: rLIB('Activate'),
                          text: rLIB('Are you sure you want to activate invoicing for this workflow?'),
                          submit_callback: () => {
                            return new Promise((resolve, reject) => {
                              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                                .then((res_GCK) => {
                                  let taskWorkflowUpdateObject = {
                                    projects_using_invoicing: true,
                                  }
                                  DatabaseSetMergeDocument(
                                    DatabaseRef_TaskWorkflow_Document(res_GCK.clientKey, rowData.key as string),
                                    taskWorkflowUpdateObject,
                                  )
                                    .then((res_DSMD) => {
                                      resolve(res_DSMD)
                                    })
                                    .catch((rej_DSMD) => {
                                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                      resolve({ close_dialog: false })
                                    })
                                })
                                .catch((rej_GCK) => {
                                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                  resolve({ close_dialog: false })
                                })
                            })
                          },
                        },
                      })
                    }}
                  />
                  <Box
                    sx={{ backgroundColor: themeVariables.error_main }}
                    className="tw-px-2 tw-pt-2 tw-pb-1 tw-rounded-md tw-inline-block"
                  >
                    <Icon
                      icon="circle-pause"
                      className="tw-mr-2"
                      sx={{ fontSize: '14px' }}
                    />
                    {rLIB('Invoicing turned off for this workflow')}
                  </Box>
                </Stack>
              )
            }
          }
          return workflowSettingJSX
        }
        // Full JSX
        let rateKey = ('BASE_RATES_' + rowData.key) as string
        let cellJSX = (
          <Box>
            <Typography sx={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '4px' }}>{rLIB('Bill To')}:</Typography>
            <Box className="tw-pl-6">{rJSX_BillToDropdown(rowData, tableAdditionalData, tableHooks, rateKey)}</Box>
            <Divider className="tw-my-2" />
            <Typography sx={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '4px' }}>{rLIB('Invoice Type')}:</Typography>
            <Box className="tw-pl-6">{rJSX_BillingTypeDropdown(rowData, tableAdditionalData, tableHooks, rateKey)}</Box>
            <Divider className="tw-my-2" />
            <Typography sx={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '4px' }}>{rLIB('Approval Type')}:</Typography>
            <Box className="tw-pl-6">{rJSX_ApprovalTypeDropdown(rowData, tableAdditionalData, tableHooks, rateKey)}</Box>
            <Divider className="tw-my-2" />
            <Typography sx={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '4px' }}>{rLIB('Manual or Automatic Billing')}:</Typography>
            <Box className="tw-pl-6">{rJSX_AutomaticBillingCheckbox(rowData, tableAdditionalData, tableHooks, rateKey)}</Box>
            <Divider className="tw-my-2" />
            <Typography sx={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '4px' }}>{rLIB('Billing Schedule')}:</Typography>
            <Box className="tw-pl-6">{rJSX_BillingSchedule(rowData, tableAdditionalData, tableHooks, rateKey)}</Box>
            <Divider className="tw-my-2" />
            <Typography sx={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '4px' }}>{rLIB('Activation Status')}:</Typography>
            {rJSX_WorkflowSetting()}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = 'tw-align-top'
        return cellCSS
      },
    },
  },
  CUSTOM_default_line_items: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = <></>
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_selectedSalesPartnerKey != null &&
          tableAdditionalData.us_salesPartners != null &&
          tableAdditionalData.us_salesPartners[tableAdditionalData.us_selectedSalesPartnerKey] != null &&
          tableAdditionalData.us_salesPartners[tableAdditionalData.us_selectedSalesPartnerKey]['name'] != null
        ) {
          headerJSX = (
            <Box>
              {rLIB('Default rates for ')} {tableAdditionalData.us_salesPartners[tableAdditionalData.us_selectedSalesPartnerKey]['name']}
            </Box>
          )
        } else {
          headerJSX = (
            <Box>
              {rLIB('Default rates for ')} {rLIB('sales partner')}
            </Box>
          )
        }
        return headerJSX
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let rateKey = ('BASE_RATES_' + rowData.key) as string
        let regionKey = 'default'
        let cellJSX = <Box>{rJSX_RegionRatesCellContent(rowData, tableAdditionalData, tableHooks, rateKey, regionKey)}</Box>
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = 'tw-align-top'
        return cellCSS
      },
    },
  },
  CUSTOM_region_line_items: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = <></>
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_regionKey != null &&
          tableAdditionalData.us_setRegionKey != null &&
          tableAdditionalData.us_regionsList != null
        ) {
          headerJSX = (
            <Box>
              <FormControl className="bp_thin_select_input">
                <Select
                  color="primary"
                  value={tableAdditionalData.us_regionKey || ''}
                  onChange={(event: any) => {
                    if (event != null && event.target != null && event.target.value != null) {
                      tableAdditionalData.us_setRegionKey(event.target.value)
                    }
                  }}
                  variant="outlined"
                >
                  {objectToArray(tableAdditionalData.us_regionsList)
                    .sort(dynamicSort('name', null))
                    .map((option: TsInterface_UnspecifiedObject, index: number) => (
                      <MenuItem
                        key={index}
                        value={option['key']}
                      >
                        {option['name']}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          )
        } else {
          headerJSX = <Box>{rLIB('Region rates ')}</Box>
        }
        return headerJSX
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let rateKey = ('BASE_RATES_' + rowData.key) as string
        let regionKey = tableAdditionalData.us_regionKey
        let cellJSX = <></>
        if (regionKey != null && regionKey !== '') {
          if (
            rowData != null &&
            rowData.key != null &&
            tableAdditionalData != null &&
            tableAdditionalData.us_invoiceRates != null &&
            tableAdditionalData.us_invoiceRates[rateKey] != null &&
            tableAdditionalData.us_invoiceRates[rateKey]['use_region_override'] != null &&
            tableAdditionalData.us_invoiceRates[rateKey]['use_region_override'][regionKey] === true
          ) {
            cellJSX = (
              <Box>
                {rJSX_RegionRatesCellContent(rowData, tableAdditionalData, tableHooks, rateKey, regionKey)}
                <Divider className="tw-my-2" />
                {rJSX_RegionOverrideToggle(rowData, tableAdditionalData, tableHooks, rateKey, regionKey)}
              </Box>
            )
          } else {
            cellJSX = <Box>{rJSX_RegionOverrideToggle(rowData, tableAdditionalData, tableHooks, rateKey, regionKey)}</Box>
          }
        } else {
          cellJSX = <Box className="tw-opacity-30">{rLIB('No region selected')}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = 'tw-align-top'
        return cellCSS
      },
    },
  },
}
