///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Masonry } from '@mui/lab'
import { Box, Button, Card, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material/'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveJurisdictions_Query } from 'rfbp_aux/services/database_endpoints/directory/jurisdictions'
import { DatabaseRef_ActiveLeadSources_Query } from 'rfbp_aux/services/database_endpoints/directory/lead_sources'
import { DatabaseRef_ActiveSalesPartners_Query } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_ActiveUtilities_Query } from 'rfbp_aux/services/database_endpoints/directory/utilities'
import {
  DatabaseRef_SaleOpportunityFiles_Collection,
  DatabaseRef_SaleOpportunityFile_Document,
  DatabaseRef_SaleOpportunityPhotos_Collection,
  DatabaseRef_SaleOpportunityPhoto_Document,
  DatabaseRef_SalesOpportunity_v2_Document,
} from 'rfbp_aux/services/database_endpoints/sales/opportunities_v2'
import {
  DatabaseRef_ProposalsForOpportunity_Query,
  DatabaseRef_Proposals_Collection,
  DatabaseRef_Proposal_Document,
} from 'rfbp_aux/services/database_endpoints/sales/proposals'
import { StorageRef_SalesOpportunityFile, StorageRef_SalesOpportunityPhoto } from 'rfbp_aux/services/storage_endpoints/sales_opportunities'
import { FileSystemBasic, TsInterface_FileSystemSettings } from 'rfbp_core/components/file_system/file_system_basic'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellManage,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_RootData_ClientUser, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
} from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getCoordinatesFromAddress } from 'rfbp_core/services/helper_functions/get_coordinates_from_address'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { formInputs_ProjectHomeDetails_v2 } from '../projects/forms/project_home_details'
import { OpportunityProposalDialog } from './proposal_dialog'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SalesOpportunityPage']['key']

const formInputs_OpportunityDetails: TsInterface_FormInputs = {
  associated_customer_name: {
    key: 'associated_customer_name',
    label: rLIB('Customer Name'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  associated_customer_phone: {
    key: 'associated_customer_phone',
    label: rLIB('Customer Phone Number'),
    input_type: 'phone_number_usa',
    required: true,
    data_type: 'string',
    background_color: '#4A4D53',
  },
  associated_customer_email: {
    key: 'associated_customer_email',
    label: rLIB('Customer Email Address'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  name_on_title: {
    key: 'name_on_title',
    label: rLIB('Name on Title'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  name_on_utility_bill: {
    key: 'name_on_utility_bill',
    label: rLIB('Name on Utility Bill'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  location_address: {
    key: 'location_address',
    label: rLIB('Address'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  location_city: {
    key: 'location_city',
    label: rLIB('City'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  location_state: {
    key: 'location_state',
    label: rLIB('State'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  location_zip: {
    key: 'location_zip',
    label: rLIB('Zip Code'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  associated_hoa_name: {
    key: 'associated_hoa_name',
    label: rLIB('HOA Name'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  associated_hoa_phone: {
    key: 'associated_hoa_phone',
    label: rLIB('HOA Phone Number'),
    input_type: 'phone_number_usa',
    required: true,
    data_type: 'string',
  },
  contact_preferences: {
    key: 'contact_preferences',
    label: rLIB('Contact Preferences'),
    input_type: 'text_multiline',
    required: true,
    data_type: 'string',
  },
  contact_preferred_method: {
    key: 'contact_preferred_method',
    label: rLIB('Preferred Contact Method'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'Phone', value: 'Phone' },
      { key: 'Email', value: 'Email' },
      { key: 'Text', value: 'Text' },
    ],
    required: true,
    data_type: 'string',
  },
  contact_preferred_contact_time: {
    key: 'contact_preferred_contact_time',
    label: rLIB('Preferred Contact Time'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'Morning', value: 'Morning' },
      { key: 'Afternoon', value: 'Afternoon' },
      { key: 'Evening', value: 'Evening' },
      { key: 'Weekends', value: 'Weekends' },
    ],
    required: true,
    data_type: 'string',
  },
  associated_lead_status: {
    key: 'associated_lead_status',
    label: rLIB('Lead Status'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'Active', value: 'Active' },
      { key: 'Closed Won', value: 'Closed Won' },
      { key: 'Arc', value: 'ARC' },
    ],
    required: true,
    data_type: 'string',
  },
  associated_sales_rep_name: {
    key: 'associated_sales_rep_name',
    label: rLIB('Sales Rep Name'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  associated_sales_rep_phone: {
    key: 'associated_sales_rep_phone',
    label: rLIB('Sales Rep Phone'),
    input_type: 'phone_number_usa',
    required: true,
    data_type: 'string',
    background_color: '#4A4D53',
  },
  associated_sales_rep_email: {
    key: 'associated_sales_rep_email',
    label: rLIB('Sales Rep Email'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  home_roof_age: {
    key: 'home_roof_age',
    label: rLIB('Roof Age'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'Original roof with the home', value: 'Original roof with the home' },
      { key: '20 years or more', value: '20 years or more' },
      { key: '15-20 years', value: '15-20 years' },
      { key: '10-15 years', value: '10-15 years' },
      { key: 'Less than 10 years', value: 'Less than 10 years' },
    ],
    required: true,
    data_type: 'string',
  },
  home_has_attic: {
    key: 'home_has_attic',
    label: rLIB('Has Attic'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'true', value: 'true' },
      { key: 'false', value: 'false' },
    ],
    required: true,
    data_type: 'string',
  },
  home_attic_access_location: {
    key: 'home_attic_access_location',
    label: rLIB('Attic Access Location'),
    input_type: 'text_multiline',
    required: true,
    data_type: 'string',
  },
  home_outside_wall_access_description: {
    key: 'home_outside_wall_access_description',
    label: rLIB('Outside Wall Access Description'),
    input_type: 'text_multiline',
    required: true,
    data_type: 'string',
  },
  home_house_age: {
    key: 'home_house_age',
    label: rLIB('House Age'),
    input_type: 'multiple_choice_select',
    options: [
      { key: '2016 or newer', value: '2016 or newer' },
      { key: '2000 to 2015', value: '2000 to 2015' },
      { key: '1985 to 1999', value: '1985 to 1999' },
      { key: '1970 to 1985', value: '1970 to 1985' },
      { key: "1960's or older", value: "1960's or older" },
    ],
    required: true,
    data_type: 'string',
  },
  home_roof_quality: {
    key: 'home_roof_quality',
    label: rLIB('Roof Quality'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'Needs Repair', value: 'Needs Repair' },
      { key: 'Good', value: 'Good' },
    ],
    required: true,
    data_type: 'string',
  },
  home_house_type: {
    key: 'home_house_type',
    label: rLIB('House Type'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'Single Family Home', value: 'Single Family Home' },
      { key: 'Condo', value: 'Condo' },
      { key: 'Duplex', value: 'Duplex' },
      { key: 'Mobile Home', value: 'Mobile Home' },
    ],
    required: true,
    data_type: 'string',
  },
  home_has_pets: {
    key: 'home_has_pets',
    label: rLIB('Pets on Property'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'true', value: 'true' },
      { key: 'false', value: 'false' },
    ],
    required: true,
    data_type: 'boolean', // Changed from 'string' to 'boolean'
  },
  home_gate_code: {
    key: 'home_gate_code',
    label: rLIB('Gate Code'),
    input_type: 'text_number',
    options: [],
    required: true,
    data_type: 'number',
  },
  home_roof_type: formInputs_ProjectHomeDetails_v2.home_roof_type,
  system_annual_electrical_usage: {
    key: 'system_annual_electrical_usage',
    label: rLIB('Annual Electrical Usage'),
    input_type: 'text_number',
    required: true,
    data_type: 'number',
  },
  home_existing_solar_system: {
    key: 'home_existing_solar_system',
    label: rLIB('Existing Solar System'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'true', value: 'true' },
      { key: 'false', value: 'false' },
    ],
    required: true,
    data_type: 'string',
  },
  home_battery_storage: {
    key: 'home_battery_storage',
    label: rLIB('Battery Storage'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'true', value: 'true' },
      { key: 'false', value: 'false' },
    ],
    required: true,
    data_type: 'string',
  },
  home_has_generator: {
    key: 'home_has_generator',
    label: rLIB('Generator'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'true', value: 'true' },
      { key: 'false', value: 'false' },
    ],
    required: true,
    data_type: 'string',
  },
  home_ev_charger: {
    key: 'home_ev_charger',
    label: rLIB('EV Charger'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'true', value: 'true' },
      { key: 'false', value: 'false' },
    ],
    required: true,
    data_type: 'string',
  },
  home_has_pool: {
    key: 'home_has_pool',
    label: rLIB('Pool'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'true', value: 'true' },
      { key: 'false', value: 'false' },
    ],
    required: true,
    data_type: 'string',
  },
  home_has_hot_tub: {
    key: 'home_has_hot_tub',
    label: rLIB('Hot Tub'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'true', value: 'true' },
      { key: 'false', value: 'false' },
    ],
    required: true,
    data_type: 'string',
  },
  electric_water_heater: {
    key: 'electric_water_heater',
    label: rLIB('Electric Water Heater'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'true', value: 'true' },
      { key: 'false', value: 'false' },
    ],
    required: true,
    data_type: 'string',
  },
  electric_dryer: {
    key: 'electric_dryer',
    label: rLIB('Electric Dryer'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'true', value: 'true' },
      { key: 'false', value: 'false' },
    ],
    required: true,
    data_type: 'string',
  },
  electric_heating: {
    key: 'electric_heating',
    label: rLIB('Electric Heating'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'true', value: 'true' },
      { key: 'false', value: 'false' },
    ],
    required: true,
    data_type: 'string',
  },
  electric_specialized_equipment: {
    key: 'electric_specialized_equipment',
    label: rLIB('Specialized Equipment'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'true', value: 'true' },
      { key: 'false', value: 'false' },
    ],
    required: true,
    data_type: 'string',
  },
  home_electric_other: {
    key: 'home_electric_other',
    label: rLIB('Other'),
    input_type: 'text_multiline',
    required: true,
    data_type: 'string',
  },
  electric_main_breaker_rating: {
    key: 'electric_main_breaker_rating',
    label: rLIB('Main Breaker Rating'),
    input_type: 'text_basic',
    required: true,
    data_type: 'number',
  },
  electric_main_panel_num_breakers: {
    key: 'electric_main_panel_num_breakers',
    label: rLIB('Number of Breakers on Main Panel'),
    input_type: 'text_basic',
    required: true,
    data_type: 'number',
  },
  electric_main_panel_sum_breakers: {
    key: 'electric_main_panel_sum_breakers',
    label: rLIB('Sum of Breakers on Main Panel'),
    input_type: 'text_basic',
    required: true,
    data_type: 'number',
  },
  electric_sub_panel_number: {
    key: 'electric_sub_panel_number',
    label: rLIB('Sub Panel Number'),
    input_type: 'text_basic',
    required: true,
    data_type: 'number',
  },
  electric_sub_panel_locations: {
    key: 'electric_sub_panel_locations',
    label: rLIB('Sub Panel Locations'),
    input_type: 'text_multiline',
    required: true,
    data_type: 'string',
  },
  system_usage_offset: {
    key: 'system_usage_offset',
    label: rLIB('Usage Offset'),
    input_type: 'text_number',
    required: true,
    data_type: 'number',
    min: 30,
    max: 200,
    min_error_message: rLIB('Minimum: 30%'),
    max_error_message: rLIB('Maximum: 200%'),
  },
  system_annual_electrical_bill: {
    key: 'system_annual_electrical_bill',
    label: rLIB('Annual Electrical Bill'),
    input_type: 'text_number',
    required: true,
    data_type: 'number',
  },
  system_placement_preferences: {
    key: 'system_placement_preferences',
    label: rLIB('Placement Preferences'),
    input_type: 'text_multiline',
    options: [],
    required: true,
    data_type: 'string',
  },
  person_purchase_timeframe: {
    key: 'person_purchase_timeframe',
    label: rLIB('Purchase Timeframe'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'Immediately', value: 'Immediately' },
      { key: '0-30 Days', value: '0-30 Days' },
      { key: '30-60 Days', value: '30-60 Days' },
      { key: '60+ Days', value: '60+ Days' },
    ],
    required: true,
    data_type: 'string',
  },
  product_type: {
    key: 'product_type',
    label: rLIB('Product Type'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'solar', value: rLIB('Solar') },
      { key: 'lunar_battery', value: rLIB('Lunar Battery') },
    ],
    required: true,
    data_type: 'string',
  },
}

const formInputs_OpportunityAssociations: TsInterface_FormInputs = {
  associated_sales_partner_key: {
    key: 'associated_sales_partner_key',
    label: rLIB('Sales Partner'),
    input_type: 'multiple_choice_select',
    options: [],
    required: true,
    data_type: 'string',
  },
  associated_utility_company_key: {
    key: 'associated_utility_company_key',
    label: rLIB('Utility Company Name'),
    input_type: 'multiple_choice_select',
    options: [],
    required: true,
    data_type: 'string',
  },
  associated_jurisdiction_key: {
    key: 'associated_jurisdiction_key',
    label: rLIB('Jurisdiction'),
    input_type: 'multiple_choice_select',
    options: [],
    required: true,
    data_type: 'string',
  },
  associated_lead_source_key: {
    key: 'associated_lead_source_key',
    label: rLIB('Lead Source'),
    input_type: 'multiple_choice_select',
    options: [],
    required: true,
    data_type: 'string',
  },
  system_inverter_preferences: {
    key: 'system_inverter_preferences',
    label: rLIB('Inverter Placement Preferences'),
    input_type: 'text_multiline',
    required: true,
    data_type: 'string',
  },
}

const formInputs_NewProposal: TsInterface_FormInputs = {
  name: {
    key: 'name',
    label: rLIB('Proposal Name'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
}

const tableColumns_Proposals: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: rLIB('View'),
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_CustomDialogDisplay({
            display: true,
            dialog: {
              dialog_jsx: (
                <OpportunityProposalDialog
                  opportunity={tableAdditionalData.us_opportunityData}
                  proposalKey={rowData.key as string}
                />
              ),
            },
          })
        }
      },
    },
    set_proposal_active: {
      icon: (
        <Icon
          type="solid"
          icon="star"
        />
      ),
      label: rLIB('Set Active'),
      onClick: function (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks): void {
        setProposalActive(
          rowData.key as string,
          tableAdditionalData.us_opportunityData.key as string,
          tableHooks.uc_RootData_ClientKey,
          tableAdditionalData.us_proposals,
        )
      },
    },
    set_proposal_signed: {
      icon: (
        <Icon
          type="solid"
          icon="signature"
        />
      ),
      label: rLIB('Set Signed'),
      onClick: function (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks): void {
        setProposalSigned(
          rowData.key as string,
          tableAdditionalData.us_opportunityData.key as string,
          tableHooks.uc_RootData_ClientKey,
          tableAdditionalData.us_proposals,
        )
      },
    },
  }),

  active_proposal_icon: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.active) {
          return (
            <Box className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block">
              <Icon
                type="solid"
                icon="star"
              />
            </Box>
          )
        }
        if (rowData.signed) {
          return (
            <Box className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block">
              <Icon
                type="solid"
                icon="signature"
              />
            </Box>
          )
        }
        return <></>
      },
    },
    header: {
      header_css: undefined,
      header_jsx: function (tableAdditionalData: TsInterface_TableAdditionalData): JSX.Element | string {
        return rLIB('Status')
      },
      header_sort_by: undefined,
    },
  },
  name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return (
          <Box
            className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
            sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
            onClick={() => {
              if (rowData.key != null) {
                tableHooks.uc_setUserInterface_CustomDialogDisplay({
                  display: true,
                  dialog: {
                    dialog_jsx: (
                      <OpportunityProposalDialog
                        opportunity={tableAdditionalData.us_opportunityData}
                        proposalKey={rowData.key as string}
                      />
                    ),
                  },
                })
              }
            }}
          >
            {rowData.name}
          </Box>
        )
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Proposal Name')
      },
      header_sort_by: 'name',
    },
  },
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Timestamp Created'), 'timestamp_created', 'YYYY-MM-DD HH:mm', true),
}

const tableSettings_Proposals: TsInterface_TableSettings = {
  paginated: false,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sortable: true,
  sort_direction: 'desc',
  sort_property_default: 'timestamp_created',
}

///////////////////////////////
// Functions
///////////////////////////////

const setProposalActive = (proposalKey: string, opportunityKey: string, clientKey: string, proposals: TsInterface_UnspecifiedObject) => {
  // First, set all proposals to inactive
  const updatePromises = Object.keys(proposals).map((key) => {
    return DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(clientKey, key), { active: false })
  })

  // Then, set the selected proposal to active
  updatePromises.push(DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(clientKey, proposalKey), { active: true }))

  // Execute all updates
  Promise.all(updatePromises)
    .then(() => {
      console.log('Proposal set as active successfully')
    })
    .catch((error) => {
      console.error('Error setting proposal as active:', error)
    })
}

const setProposalSigned = (proposalKey: string, opportunityKey: string, clientKey: string, proposals: TsInterface_UnspecifiedObject) => {
  const updatePromises = Object.keys(proposals).map((key) => {
    if (key === proposalKey) {
      // For the selected proposal, set signed to true and active to false
      return DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(clientKey, key), { signed: true, active: false })
    } else {
      // For all other proposals, set signed and active to false
      return DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(clientKey, key), { active: false })
    }
  })

  // Execute all updates
  Promise.all(updatePromises)
    .then(() => {
      console.log('Proposal set as signed successfully')
    })
    .catch((error) => {
      console.error('Error setting proposal as signed:', error)
    })
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const un_routerNavigation = useNavigate()
  // { sort-start } - hooks
  const [us_opportunityData, us_setOpportunityData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_proposals, us_setProposals] = useState<TsInterface_UnspecifiedObject>({})
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  // { sort-end } - hooks

  const [us_isUtilityBillEditMode, us_setIsUtilityBillEditMode] = useState(false)
  const [us_utilityData, us_setUtilityData] = useState<{ [key: string]: { kw_usage: string; rate: string; bill_amount: string } }>({})

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Sales Opportunity', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setOpportunityData(newData)
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.log(rej_GCK)
      })

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, itemKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProposals(newData)
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProposalsForOpportunity_Query(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.log(rej_GCK)
      })

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    // Load data from us_opportunityData if available
    if (us_opportunityData.utility_bill_data) {
      us_setUtilityData(us_opportunityData.utility_bill_data)
    }
  }, [us_opportunityData])

  // Functions

  const createNewProposal = () => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_NewProposal,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = formSubmittedData
                  updateObject['timestamp_created'] = new Date()
                  updateObject['status'] = 'active'
                  updateObject['associated_opportunity_key'] = itemKey
                  DatabaseAddDocument(DatabaseRef_Proposals_Collection(res_GCK.clientKey), updateObject, true)
                    .then((res_DAD) => {
                      resolve(res_DAD)
                    })
                    .catch((rej_DAD) => {
                      reject(rej_DAD)
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Create New Opportunity'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const rJSX_UtilityBillCalendar = (): JSX.Element => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    const handleInputChange = (month: string, field: 'kw_usage' | 'rate', value: string) => {
      us_setUtilityData((prevData) => {
        const updatedMonth = { ...prevData[month], [field]: value }
        const kw_usage = parseFloat(updatedMonth.kw_usage)
        const rate = parseFloat(updatedMonth.rate)

        if (!isNaN(kw_usage) && !isNaN(rate)) {
          updatedMonth.bill_amount = (kw_usage * rate).toFixed(2)
        } else {
          updatedMonth.bill_amount = ''
        }

        return {
          ...prevData,
          [month]: updatedMonth,
        }
      })
    }

    const saveUtilityData = () => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, itemKey), {
            utility_bill_data: us_utilityData,
          })
            .then(() => {})
            .catch((error) => {
              console.error('Error saving utility bill data:', error)
            })
        })
        .catch((error) => {
          console.error('Error getting client key:', error)
        })
    }

    const rJSX_ViewMode = (): JSX.Element => {
      return (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{rLIB('Month')}</TableCell>
                <TableCell>{rLIB('kW Usage')}</TableCell>
                <TableCell>{rLIB('Rate ($/kWh)')}</TableCell>
                <TableCell>{rLIB('Bill Amount')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {months.map((month) => (
                <TableRow key={month}>
                  <TableCell>{month}</TableCell>
                  <TableCell>{us_utilityData[month]?.kw_usage || '-'}</TableCell>
                  <TableCell>{us_utilityData[month]?.rate || '-'}</TableCell>
                  <TableCell>${us_utilityData[month]?.bill_amount || '-'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }

    return us_isUtilityBillEditMode ? (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{rLIB('Month')}</TableCell>
              <TableCell>{rLIB('kW Usage')}</TableCell>
              <TableCell>{rLIB('Rate ($/kWh)')}</TableCell>
              <TableCell>{rLIB('Bill Amount')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {months.map((month) => (
              <TableRow key={month}>
                <TableCell>{month}</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={us_utilityData[month]?.kw_usage || ''}
                    onChange={(e) => handleInputChange(month, 'kw_usage', e.target.value)}
                    onBlur={saveUtilityData}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={us_utilityData[month]?.rate || ''}
                    onChange={(e) => handleInputChange(month, 'rate', e.target.value)}
                    onBlur={saveUtilityData}
                  />
                </TableCell>
                <TableCell>${us_utilityData[month]?.bill_amount || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      rJSX_ViewMode()
    )
  }

  // JSX Generation
  const rJSX_BackButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink) {
      buttonJSX = (
        <Tooltip title={rLIB('Back to all Opportunities')}>
          <Button
            variant="outlined"
            color="inherit"
            className="tw-mr-2 bp_icon_only_button"
            startIcon={
              <Icon
                type="solid"
                icon="chevron-left"
              />
            }
            onClick={() => {
              directAppNavigation(un_routerNavigation, ApplicationPages.SalesOpportunitiesIndexPage.url())
            }}
          ></Button>
        </Tooltip>
      )
    } else {
      buttonJSX = (
        <Button
          variant="outlined"
          color="inherit"
          className="tw-mr-2"
          startIcon={
            <Icon
              type="solid"
              icon="chevron-left"
            />
          }
          onClick={() => {
            directAppNavigation(un_routerNavigation, ApplicationPages.SalesOpportunitiesIndexPage.url())
          }}
        >
          {rLIB('Back to all Opportunities')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_newProposalButton = (): JSX.Element => {
    return (
      <Button
        variant="contained"
        color="success"
        startIcon={<Icon icon="circle-plus" />}
        onClick={() => {
          createNewProposal()
        }}
      >
        {rLIB('New Proposal')}
      </Button>
    )
  }

  const rJSX_DetailLineItem = (
    icon: string,
    label: JSX.Element | string,
    displayKey: string,
    formKey: string,
    editIcon: (propKey: string) => JSX.Element,
  ): JSX.Element => {
    const value = getProp(us_opportunityData, displayKey, null)
    const editIconJSX = editIcon(formKey)

    // Format the value based on its type
    let displayValue = value
    if (typeof value === 'boolean') {
      displayValue = value ? 'Yes' : 'No'
    } else if (value === 'true') {
      displayValue = 'Yes'
    } else if (value === 'false') {
      displayValue = 'No'
    }

    return (
      <Box>
        <Icon
          className="tw-mr-2"
          icon={icon}
          sx={{ color: themeVariables.primary_main }}
        />
        <Typography
          className="tw-inline-block tw-mr-2"
          sx={{ color: themeVariables.primary_main }}
        >
          {label}:
        </Typography>
        {value !== null ? (
          <Typography className="tw-inline-block">{displayValue}</Typography>
        ) : (
          <Typography
            className="tw-inline-block"
            sx={{ opacity: 0.5 }}
          >
            {rLIB('Missing')}
          </Typography>
        )}
        {editIconJSX}
      </Box>
    )
  }

  const rJSX_NotEditableLineItemIcon = (propKey: string): JSX.Element => {
    return <></>
  }

  const rJSX_SimpleEditLineItemIcon = (propKey: string): JSX.Element => {
    return (
      <Tooltip
        placement="right"
        title={rLIB('Edit')}
      >
        <Box className="tw-inline-block">
          <Icon
            className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
            icon={'pen-to-square'}
            onClick={() => {
              let formInputs: TsInterface_FormInputs = {}

              if (formInputs_OpportunityDetails[propKey] != null) {
                formInputs[propKey] = formInputs_OpportunityDetails[propKey]
              }

              // If editing address-related fields, include all address inputs
              if (['location_address', 'location_city', 'location_state', 'location_zip'].includes(propKey)) {
                formInputs = {
                  location_address: formInputs_OpportunityDetails.location_address,
                  location_city: formInputs_OpportunityDetails.location_city,
                  location_state: formInputs_OpportunityDetails.location_state,
                  location_zip: formInputs_OpportunityDetails.location_zip,
                }
              }

              uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: { ...us_opportunityData },
                    formInputs: formInputs,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = formSubmittedData

                            const isAddressEdit =
                              formSubmittedData.location_address ||
                              formSubmittedData.location_city ||
                              formSubmittedData.location_state ||
                              formSubmittedData.location_zip

                            // Check if all address fields are filled
                            if (isAddressEdit) {
                              const allAddressFieldsFilled =
                                formSubmittedData.location_address &&
                                formSubmittedData.location_city &&
                                formSubmittedData.location_state &&
                                formSubmittedData.location_zip

                              if (allAddressFieldsFilled) {
                                const fullAddress = `${formSubmittedData.location_address}, ${formSubmittedData.location_city}, ${formSubmittedData.location_state} ${formSubmittedData.location_zip}`
                                getCoordinatesFromAddress(fullAddress)
                                  .then((result: any) => {
                                    if (result.success) {
                                      updateObject.location_latitude = result.coordinates.latitude
                                      updateObject.location_longitude = result.coordinates.longitude
                                    }
                                    DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, itemKey), updateObject)
                                      .then((res_DAD) => {
                                        resolve(res_DAD)
                                      })
                                      .catch((rej_DAD) => {
                                        reject(rej_DAD)
                                      })
                                  })
                                  .catch((error) => {
                                    console.error('Error getting coordinates:', error)
                                    // Still update other fields even if coordinate calculation fails
                                    DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, itemKey), updateObject)
                                      .then((res_DAD) => {
                                        resolve(res_DAD)
                                      })
                                      .catch((rej_DAD) => {
                                        reject(rej_DAD)
                                      })
                                  })
                              } else {
                                // If not all address fields are filled, just update without calculating coordinates
                                // Also, clear existing latitude and longitude if address is incomplete
                                updateObject.location_latitude = null
                                updateObject.location_longitude = null
                              }
                            }

                            DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, itemKey), updateObject)
                              .then((res_DAD) => {
                                resolve(res_DAD)
                              })
                              .catch((rej_DAD) => {
                                reject(rej_DAD)
                              })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: rLIB('Edit Opportunity'),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          ></Icon>
        </Box>
      </Tooltip>
    )
  }

  const rJSX_AssociationLineItemEditIcon = (propKey: string): JSX.Element => {
    return (
      <Tooltip
        placement="right"
        title={rLIB('Edit')}
      >
        <Box className="tw-inline-block">
          <Icon
            className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
            icon={'pen-to-square'}
            onClick={() => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                //get association list
                let associationOptions: TsInterface_UnspecifiedObject = {}
                let promiseArray: Promise<unknown>[] = []

                switch (propKey) {
                  case 'associated_sales_partner_key':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveSalesPartners_Query(res_GCK.clientKey, 'name', true, null, {})).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'associated_utility_company_key':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveUtilities_Query(res_GCK.clientKey, 'name', true, null, {})).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'associated_jurisdiction_key':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveJurisdictions_Query(res_GCK.clientKey, 'name', true, null, {})).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'associated_lead_source_key':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveLeadSources_Query(res_GCK.clientKey, 'name', true, null, {})).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                }
                Promise.all(promiseArray).finally(() => {
                  //format options
                  let formattedOptions: { key: string; value: string }[] = []
                  for (let loopOptionKey in associationOptions) {
                    formattedOptions.push({ value: associationOptions[loopOptionKey]['name'], key: loopOptionKey })
                  }
                  //generate form inputs
                  let formInputs: TsInterface_FormInputs = {}

                  if (formInputs_OpportunityAssociations[propKey] != null) {
                    formInputs[propKey] = formInputs_OpportunityAssociations[propKey]
                    formInputs[propKey].options = formattedOptions
                  }
                  //open form dialog
                  uc_setUserInterface_FormDialogDisplay({
                    display: true,
                    form: {
                      form: {
                        formAdditionalData: {},
                        formData: { ...us_opportunityData },
                        formInputs: formInputs,
                        formOnChange: (
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formData: TsInterface_FormData,
                          formInputs: TsInterface_FormInputs,
                          formSettings: TsInterface_FormSettings,
                        ) => {},
                        formSettings: {},
                        formSubmission: (
                          formSubmittedData: TsInterface_FormSubmittedData,
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formHooks: TsInterface_FormHooksObject,
                        ) => {
                          return new Promise((resolve, reject) => {
                            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                let updateObject = formSubmittedData
                                let namePropKey = propKey.replace('_key', '_name')
                                let matchingOption = getProp(associationOptions, formSubmittedData[propKey], {})
                                let nameValue = getProp(matchingOption, 'name', null)

                                updateObject[namePropKey] = nameValue

                                DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, itemKey), updateObject)
                                  .then((res_DAD) => {
                                    resolve(res_DAD)
                                  })
                                  .catch((rej_DAD) => {
                                    reject(rej_DAD)
                                  })
                              })
                              .catch((rej_GCK) => {
                                formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                reject(rej_GCK)
                              })
                          })
                        },
                      },
                      dialog: {
                        formDialogHeaderColor: 'success',
                        formDialogHeaderText: rLIB('Edit Opportunity'),
                        formDialogIcon: (
                          <Icon
                            type="solid"
                            icon="pen-to-square"
                          />
                        ),
                      },
                    },
                  })
                })
              })
            }}
          ></Icon>
        </Box>
      </Tooltip>
    )
  }

  const rJSX_OpportunityDetailsTabContent = (): JSX.Element => {
    return (
      <Box>
        <Masonry
          columns={{ xs: 1, sm: 2, md: 3 }}
          spacing={2}
        >
          <Box
          // xs={12}
          // sm={6}
          // md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Customer')}
            </Typography>
            <Card className="tw-p-2">
              {rJSX_DetailLineItem('user', rLIB('Name'), 'associated_customer_name', 'associated_customer_name', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('phone', rLIB('Phone Number'), 'associated_customer_phone', 'associated_customer_phone', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('envelope', rLIB('Email'), 'associated_customer_email', 'associated_customer_email', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('user', rLIB('Name on Title'), 'name_on_title', 'name_on_title', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('user', rLIB('Name on Utility Bill'), 'name_on_utility_bill', 'name_on_utility_bill', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('location-dot', rLIB('Address'), 'location_address', 'location_address', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('location-dot', rLIB('City'), 'location_city', 'location_address', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('location-dot', rLIB('State'), 'location_state', 'location_state', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('location-dot', rLIB('Zip Code'), 'location_zip', 'location_zip', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('location-dot', rLIB('Latitude'), 'location_latitude', 'location_latitude', rJSX_NotEditableLineItemIcon)}
              {rJSX_DetailLineItem('location-dot', rLIB('Longitude'), 'location_longitude', 'location_longitude', rJSX_NotEditableLineItemIcon)}
              {rJSX_DetailLineItem('asterisk', rLIB('Product Type'), 'product_type', 'product_type', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem(
                'faucet-drip',
                rLIB('Utility Company'),
                'associated_utility_company_name',
                'associated_utility_company_key',
                rJSX_AssociationLineItemEditIcon,
              )}
              {rJSX_DetailLineItem(
                'gavel',
                rLIB('Jurisdiction'),
                'associated_jurisdiction_name',
                'associated_jurisdiction_key',
                rJSX_AssociationLineItemEditIcon,
              )}
              {rJSX_DetailLineItem('user', rLIB('HOA Name'), 'associated_hoa_name', 'associated_hoa_name', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('phone', rLIB('HOA Phone'), 'associated_hoa_phone', 'associated_hoa_phone', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('address-card', rLIB('Contact Preferences'), 'contact_preferences', 'contact_preferences', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem(
                'address-card',
                rLIB('Preferred Contact Method'),
                'contact_preferred_method',
                'contact_preferred_method',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem(
                'address-card',
                rLIB('Preferred Contact Time'),
                'contact_preferred_contact_time',
                'contact_preferred_contact_time',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem(
                'asterisk',
                rLIB('Purchase Timeframe'),
                'person_purchase_timeframe',
                'person_purchase_timeframe',
                rJSX_SimpleEditLineItemIcon,
              )}
            </Card>
          </Box>
          <Box
          // xs={12}
          // sm={6}
          // md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Sales Team')}
            </Typography>
            <Card className="tw-p-2">
              {rJSX_DetailLineItem('user', rLIB('Lead Source'), 'associated_lead_source_name', 'associated_lead_source_key', rJSX_AssociationLineItemEditIcon)}
              {rJSX_DetailLineItem('signal', rLIB('Lead Status'), 'associated_lead_status', 'associated_lead_status', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem(
                'money-bill-transfer',
                rLIB('Sales Partner'),
                'associated_sales_partner_name',
                'associated_sales_partner_key',
                rJSX_AssociationLineItemEditIcon,
              )}
              {rJSX_DetailLineItem('user', rLIB('Sales Rep Name'), 'associated_sales_rep_name', 'associated_sales_rep_name', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('phone', rLIB('Sales Rep Phone'), 'associated_sales_rep_phone', 'associated_sales_rep_phone', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem(
                'envelope',
                rLIB('Sales Rep Email'),
                'associated_sales_rep_email',
                'associated_sales_rep_email',
                rJSX_SimpleEditLineItemIcon,
              )}
            </Card>
          </Box>
          <Box
          // xs={12}
          // sm={6}
          // md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Home')}
            </Typography>
            <Card className="tw-p-2">
              {rJSX_DetailLineItem('house', rLIB('House Type'), 'home_house_type', 'home_house_type', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('home', rLIB('House Age'), 'home_house_age', 'home_house_age', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('rotate', rLIB('Roof Age'), 'home_roof_age', 'home_roof_age', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('people-roof', rLIB('Roof Type'), 'home_roof_type', 'home_roof_type', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('bandage', rLIB('Roof Quality'), 'home_roof_quality', 'home_roof_quality', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('paw', rLIB('Pets on Property'), 'home_has_pets', 'home_has_pets', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('lock', rLIB('Gate Code'), 'home_gate_code', 'home_gate_code', rJSX_SimpleEditLineItemIcon)}
            </Card>
          </Box>
          <Box
          // xs={12}
          // sm={6}
          // md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Electricity Usage')}
            </Typography>
            <Card className="tw-p-2">
              {rJSX_DetailLineItem(
                'rotate',
                rLIB('Annual Electrical Usage'),
                'system_annual_electrical_usage',
                'system_annual_electrical_usage',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem('percent', rLIB('Desired Offset(%)'), 'system_usage_offset', 'system_usage_offset', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem(
                'receipt',
                rLIB('Annual Electrical Bill'),
                'system_annual_electrical_bill',
                'system_annual_electrical_bill',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem(
                'receipt',
                rLIB('Monthly Electrical Bill'),
                'system_monthly_electricity_bill',
                'system_monthly_electricity_bill',
                rJSX_SimpleEditLineItemIcon,
              )}
            </Card>
          </Box>
          <Box
          // xs={12}
          // sm={6}
          // md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Install')}
            </Typography>
            <Card className="tw-p-2">
              {rJSX_DetailLineItem(
                'asterisk',
                rLIB('Panel Placement Preferences'),
                'system_placement_preferences',
                'system_placement_preferences',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem(
                'asterisk',
                rLIB('Inverter Preferences'),
                'system_inverter_preferences',
                'system_inverter_preferences',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem(
                'block-brick',
                rLIB('Outside Wall Access'),
                'home_outside_wall_access',
                'home_outside_wall_access',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem(
                'block-brick',
                rLIB('Outside Wall Access Explanation'),
                'home_outside_wall_access_description',
                'home_outside_wall_access_description',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem('box-open-full', rLIB('Attic'), 'home_has_attic', 'home_has_attic', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('box-open-full', rLIB('Attic Access'), 'home_attic_access', 'home_attic_access', rJSX_SimpleEditLineItemIcon)}
            </Card>
          </Box>
          <Box
          // xs={12}
          // sm={6}
          // md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Home Electrical')}
            </Typography>
            <Card className="tw-p-2">
              {rJSX_DetailLineItem(
                'lightbulb',
                rLIB('Main Breaker Rating'),
                'electric_main_breaker_rating',
                'electric_main_breaker_rating',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem(
                'lightbulb',
                rLIB('Number of Breakers - Main Panel'),
                'electric_main_panel_num_breakers',
                'electric_main_panel_num_breakers',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem(
                'lightbulb',
                rLIB('Breaker Sum - Main Panel'),
                'electric_main_panel_sum_breakers',
                'electric_main_panel_sum_breakers',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem(
                'lightbulb',
                rLIB('Number of Sub Panels'),
                'electric_sub_panel_number',
                'electric_sub_panel_number',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem(
                'lightbulb',
                rLIB('Location of Sub Panels'),
                'electric_sub_panel_locations',
                'electric_sub_panel_locations',
                rJSX_SimpleEditLineItemIcon,
              )}
            </Card>
          </Box>
          <Box
          // xs={12}
          // sm={6}
          // md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Home Misc.')}
            </Typography>
            <Card className="tw-p-2">
              {rJSX_DetailLineItem(
                'asterisk',
                rLIB('Existing Solar System'),
                'home_existing_solar_system',
                'home_existing_solar_system',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem('asterisk', rLIB('Battery Storage'), 'home_battery_storage', 'home_battery_storage', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('asterisk', rLIB('Generator'), 'home_has_generator', 'home_has_generator', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('asterisk', rLIB('EV Charger'), 'home_ev_charger', 'home_ev_charger', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('asterisk', rLIB('Pool'), 'home_has_pool', 'home_has_pool', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('asterisk', rLIB('Hot tub'), 'home_has_hot_tub', 'home_has_hot_tub', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('asterisk', rLIB('Electric Water Heater'), 'electric_water_heater', 'electric_water_heater', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('asterisk', rLIB('Electric Dryer'), 'electric_dryer', 'electric_dryer', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('asterisk', rLIB('Electric Heating'), 'electric_heating', 'electric_heating', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem(
                'asterisk',
                rLIB('Special Electric Equipment'),
                'electric_specialized_equipment',
                'electric_specialized_equipment',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem('asterisk', rLIB('Other'), 'home_electric_other', 'home_electric_other', rJSX_SimpleEditLineItemIcon)}
            </Card>
          </Box>
          <Box
          // xs={12}
          // sm={12}
          // md={8}
          >
            <Box
              display="flex"
              alignItems="center"
              mb={0} // Add some bottom margin
            >
              <Typography
                variant="h6"
                className="tw-opacity-50"
              >
                {rLIB('Utility Bill')}
              </Typography>
              <Tooltip title={us_isUtilityBillEditMode ? rLIB('View Mode') : rLIB('Edit Mode')}>
                <IconButton
                  onClick={() => us_setIsUtilityBillEditMode(!us_isUtilityBillEditMode)}
                  size="small" // Make the button smaller
                  className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100" // Use primary color for better visibility
                >
                  <Icon icon={us_isUtilityBillEditMode ? 'eye' : 'pen-to-square'} />
                </IconButton>
              </Tooltip>
            </Box>
            <Card className="tw-p-2">{rJSX_UtilityBillCalendar()}</Card>
          </Box>
        </Masonry>
      </Box>
    )
  }

  const rJSX_ProposalsTabContent = (): JSX.Element => {
    return (
      <Box>
        <Card>
          <TableBasic
            tableAdditionalData={{
              us_opportunityData: us_opportunityData,
              us_proposals: us_proposals,
            }}
            tableColumns={tableColumns_Proposals}
            tableData={objectToArray(us_proposals)}
            tableSettings={tableSettings_Proposals}
          />
        </Card>
      </Box>
    )
  }

  const rJSX_TimeLineTabContent = (): JSX.Element => {
    return <Box></Box>
  }

  const rJSX_DocumentsTabContent = (): JSX.Element => {
    let fileSystemSettings: TsInterface_FileSystemSettings = {
      allow_file_archiving: false,
      allow_file_movement: false,
      allow_file_unarchiving: false,
      allow_file_uploads: false,
      allow_folder_creation: false,
      allow_folder_deletion: false,
      allow_folder_movement: false,
      allow_folder_rename: false,
      allow_link_creation: false,
      archive_filter_visible: false,
    }
    if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.user_role === 'admin') {
      fileSystemSettings = {
        allow_file_archiving: true,
        allow_file_movement: true,
        allow_file_unarchiving: true,
        allow_file_uploads: true,
        allow_folder_creation: true,
        allow_folder_deletion: true,
        allow_folder_movement: true,
        allow_folder_rename: true,
        allow_link_creation: true,
        archive_filter_visible: true,
      }
    }
    return (
      <Box>
        <FileSystemBasic
          fileSystemCollectionDatabaseEndpoint={(clientKey) => {
            return DatabaseRef_SaleOpportunityFiles_Collection(clientKey, itemKey)
          }}
          fileSystemDocumentDatabaseEndpoint={(clientKey, fileKey) => {
            return DatabaseRef_SaleOpportunityFile_Document(clientKey, itemKey, fileKey)
          }}
          fileSystemStorageEndpoint={(clientKey, fileName) => {
            return StorageRef_SalesOpportunityFile(clientKey, itemKey, fileName)
          }}
          fileSystemHardCodedFolders={{}}
          fileSystemSettings={fileSystemSettings}
        />
      </Box>
    )
  }

  const rJSX_PhotosTabContent = (): JSX.Element => {
    let fileSystemSettings: TsInterface_FileSystemSettings = {
      allow_file_archiving: false,
      allow_file_movement: false,
      allow_file_unarchiving: false,
      allow_file_uploads: false,
      allow_folder_creation: false,
      allow_folder_deletion: false,
      allow_folder_movement: false,
      allow_folder_rename: false,
      allow_link_creation: false,
      archive_filter_visible: false,
    }
    if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.user_role === 'admin') {
      fileSystemSettings = {
        allow_file_archiving: true,
        allow_file_movement: true,
        allow_file_unarchiving: true,
        allow_file_uploads: true,
        allow_folder_creation: true,
        allow_folder_deletion: true,
        allow_folder_movement: true,
        allow_folder_rename: true,
        allow_link_creation: true,
        archive_filter_visible: true,
      }
    }
    return (
      <Box>
        <FileSystemBasic
          fileSystemCollectionDatabaseEndpoint={(clientKey) => {
            return DatabaseRef_SaleOpportunityPhotos_Collection(clientKey, itemKey)
          }}
          fileSystemDocumentDatabaseEndpoint={(clientKey, photoKey) => {
            return DatabaseRef_SaleOpportunityPhoto_Document(clientKey, itemKey, photoKey)
          }}
          fileSystemStorageEndpoint={(clientKey, fileName) => {
            return StorageRef_SalesOpportunityPhoto(clientKey, itemKey, fileName)
          }}
          fileSystemHardCodedFolders={{}}
          fileSystemSettings={fileSystemSettings}
        />
      </Box>
    )
  }

  const rJSX_LogsTabContent = (): JSX.Element => {
    return <Box></Box>
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Sales Opportunity')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'details',
                  tabHeader: rLIB('Details'),
                  tabContent: rJSX_OpportunityDetailsTabContent(),
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'proposals',
                  tabHeader: rLIB('Proposals'),
                  tabContent: rJSX_ProposalsTabContent(),
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 470 },
                    { fullJSX: rJSX_newProposalButton(), minJSX: rJSX_newProposalButton(), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'time_line',
                  tabHeader: rLIB('Time Line'),
                  tabContent: rJSX_TimeLineTabContent(),
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'documents',
                  tabHeader: rLIB('Documents'),
                  tabContent: rJSX_DocumentsTabContent(),
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'photos',
                  tabHeader: rLIB('Photos'),
                  tabContent: rJSX_PhotosTabContent(),
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'logs',
                  tabHeader: rLIB('Logs'),
                  tabContent: rJSX_LogsTabContent(),
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.SalesOpportunityPage.url(itemKey),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Sales Opportunities', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
