///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  AppBar,
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import { globalTaskTypes } from 'app/models/tasks/global_tasks'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_ActiveTaskBlueprints_Query } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import { DatabaseRef_SalesPartner_InvoiceRates_Collection } from 'rfbp_aux/services/database_endpoints/finances/invoice_rates'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { TableBasic } from 'rfbp_core/components/table/table_basic'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_UserInterface_CustomDialog, UserInterface_Default_CustomDialogDisplayState } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { createV2AdditionalWorkTask } from '../v2_services/additional_work_task_creation'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_NewNonBillableTaskDialog {
  clientKey: string
  projectKey: string
  project: TsInterface_UnspecifiedObject
}

///////////////////////////////
// Variables
///////////////////////////////

const tableColumns_SowItems: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('Task Name'), 'name'),
  task_type: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          rowData != null &&
          rowData['task_type'] != null &&
          globalTaskTypes != null &&
          globalTaskTypes[rowData['task_type'] as string] != null &&
          globalTaskTypes[rowData['task_type'] as string]['name'] != null
        ) {
          cellJSX = globalTaskTypes[rowData['task_type'] as string]['name']
        } else {
          cellJSX = getProp(rowData, 'task_type', '')
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Task Type')
      },
      header_sort_by: null,
    },
  },
  TEMP_create: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let requireQuote = true
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_salesPartnerInvoiceRates != null &&
          tableAdditionalData.us_salesPartnerInvoiceRates[rowData['key'] as string] != null &&
          tableAdditionalData.us_salesPartnerInvoiceRates[rowData['key'] as string]['require_quote'] != null
        ) {
          requireQuote = tableAdditionalData.us_salesPartnerInvoiceRates[rowData['key'] as string]['require_quote']
        }
        if (requireQuote === false) {
          cellJSX = (
            <Box>
              <Button
                variant="outlined"
                color="success"
                size="small"
                startIcon={<Icon icon="circle-plus" />}
                className=""
                onClick={() => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      createV2AdditionalWorkTask(
                        res_GCK.clientKey,
                        tableAdditionalData.projectKey,
                        rowData.key as string,
                        null,
                        'sow',
                        tableHooks.uc_setUserInterface_FormDialogDisplay,
                      )
                      tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                }}
              >
                {rLIB('Create Task')}
              </Button>
            </Box>
          )
        } else {
          cellJSX = (
            <Box>
              <Typography sx={{ color: themeVariables.warning_main }}>
                <Icon
                  icon="circle-exclamation"
                  className="tw-mr-2"
                />
                {rLIB('Task is billable')}
              </Typography>
            </Box>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
  },
}

const tableSettings_SowItems: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'name',
  sortable: true,
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

/* eslint-disable react/prop-types */
export const NewNonBillableTaskDialog: React.FC<TsInterface_NewNonBillableTaskDialog> = ({ clientKey, projectKey, project }): JSX.Element => {
  // Hooks - useContext, useState, useReducer, other
  const [us_activeBlueprintTasks, us_setActiveBlueprintTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_additionalWorkTaskType, us_setAdditionalWorkTaskType] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filteredTasks, us_setFilteredTasks] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_salesPartnerInvoiceRates, us_setSalesPartnerInvoiceRates] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskSearch, us_setTaskSearch] = useState<string>('')
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setActiveBlueprintTasks(newData)
      }
      ur_forceRerender()
    }
    if (projectKey != null) {
      unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveTaskBlueprints_Query(clientKey), updateLiveData)
    } else {
      us_setActiveBlueprintTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [clientKey, projectKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setSalesPartnerInvoiceRates(newData)
      }
      ur_forceRerender()
    }
    if (project != null && project.associated_sales_partner_key != null) {
      unsubscribeLiveData = DatabaseGetLiveCollection(
        DatabaseRef_SalesPartner_InvoiceRates_Collection(clientKey, project.associated_sales_partner_key),
        updateLiveData,
      )
    } else {
      us_setActiveBlueprintTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [clientKey, project, projectKey, ur_forceRerender])

  useEffect(() => {
    let filteredTasks: TsInterface_UnspecifiedObject[] = []
    for (let loopTaskKey in us_activeBlueprintTasks) {
      let loopTask = us_activeBlueprintTasks[loopTaskKey]
      // Checkbox Filter
      let addedTask = false
      if (loopTask != null && loopTask.task_type != null) {
        if (us_additionalWorkTaskType[loopTask.task_type] === true) {
          filteredTasks.push(loopTask)
          addedTask = true
        }
      }
      // Search Filter
      if (addedTask === false && us_taskSearch != null && us_taskSearch !== '') {
        if (loopTask.name.toLowerCase().includes(us_taskSearch.toLowerCase())) {
          filteredTasks.push(loopTask)
          addedTask = true
        }
      }
    }
    us_setFilteredTasks(filteredTasks)
    ur_forceRerender()
    return () => {}
  }, [us_activeBlueprintTasks, us_additionalWorkTaskType, us_taskSearch, ur_forceRerender])

  // Functions

  // JSX Generation
  const returnInputDataAsArray = (): any[] => {
    let formattedInputData: any[] = []
    // Causes problems to send this over in value but an empty array works with custom render that always displays even when empty
    return formattedInputData
  }

  const changeCheckboxValue = (option: TsInterface_UnspecifiedObject): void => {
    if (option != null && option.target != null && option.target.value != null && option.target.value[0] != null) {
      let optionKey = option.target.value[0]
      let copyOfTaskTypeArray: TsInterface_UnspecifiedObject = { ...us_additionalWorkTaskType }
      if (copyOfTaskTypeArray[optionKey] == null || copyOfTaskTypeArray[optionKey] === false) {
        copyOfTaskTypeArray[optionKey] = true
      } else {
        copyOfTaskTypeArray[optionKey] = false
      }
      us_setAdditionalWorkTaskType(copyOfTaskTypeArray)
    }
  }

  const checkIfChecked = (task: TsInterface_UnspecifiedObject): boolean => {
    let checked = false
    if (task != null && task['key'] != null && us_additionalWorkTaskType != null && us_additionalWorkTaskType[task['key']] === true) {
      checked = true
    }
    return checked
  }

  const rJSX_TaskTypeSelector = (): JSX.Element => {
    let taskTypesArray = objectToArray(globalTaskTypes).filter((taskType: TsInterface_UnspecifiedObject) => taskType.additional_work_task === true)
    let taskCategory = 'project'
    taskTypesArray = taskTypesArray.filter((taskType: TsInterface_UnspecifiedObject) => taskType.task_category === taskCategory)
    let taskTypeSelectorJSX = (
      <FormControl>
        <Select
          color="primary"
          value={returnInputDataAsArray()}
          multiple
          onChange={(option) => {
            changeCheckboxValue(option)
          }}
          displayEmpty={true}
          renderValue={(selected: any) => {
            let displayString = ''
            let optionsObject = globalTaskTypes
            let loopOptions: TsInterface_UnspecifiedObject = { ...us_additionalWorkTaskType }
            for (let loopOptionKey in loopOptions) {
              if (loopOptions[loopOptionKey] === true) {
                if (optionsObject != null && optionsObject[loopOptionKey] != null && optionsObject[loopOptionKey]['name_string'] != null) {
                  if (displayString.length > 0) {
                    displayString += ', '
                  }
                  displayString += optionsObject[loopOptionKey]['name_string']
                }
              }
            }
            return displayString
          }}
          variant="outlined"
          notched={true}
        >
          {taskTypesArray.sort(dynamicSort('value', 'asc')).map((task: TsInterface_UnspecifiedObject, index: number) => (
            <MenuItem
              key={index}
              value={task.key}
            >
              <Checkbox checked={checkIfChecked(task)} />
              <ListItemText primary={task.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )

    return taskTypeSelectorJSX
  }

  const rJSX_TaskSearch = (): JSX.Element => {
    let searchJSX = (
      <Box>
        <TextField
          label={rLIB('Search')}
          value={us_taskSearch}
          onChange={(e) => {
            us_setTaskSearch(e.target.value)
          }}
        />
      </Box>
    )
    return searchJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Dialog
        className="bp_dialog_xl_width"
        keepMounted
        onClose={() => {
          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        }}
        open={true}
      >
        <AppBar
          position="static"
          color="inherit"
        >
          <Toolbar>
            <IconButton
              aria-label="menu"
              color="inherit"
              disabled
              edge="start"
              size="large"
              sx={{ mr: 1, color: '#fff !important' }}
            >
              <Icon icon="circle-plus" />
            </IconButton>
            <Typography
              component={'span'}
              variant={'h6'}
              sx={{ flexGrow: 1 }}
            >
              <Box className="tw-inline-block">{rLIB('New Non-billable Task')}</Box>
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ padding: '0px' }}>
          <Box className="tw-p-4">
            <Stack
              direction="row"
              spacing={1}
            >
              {rJSX_TaskTypeSelector()}
              {rJSX_TaskSearch()}
            </Stack>
            <Card className="tw-mt-2">
              <TableBasic
                tableAdditionalData={{
                  us_salesPartnerInvoiceRates: us_salesPartnerInvoiceRates,
                  clientKey: clientKey,
                  projectKey: projectKey,
                  project: project,
                }}
                tableColumns={tableColumns_SowItems}
                tableData={us_filteredTasks}
                tableSettings={tableSettings_SowItems}
              />
            </Card>
          </Box>
        </DialogContent>
      </Dialog>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}
