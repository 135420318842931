/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			example component description

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Masonry } from '@mui/lab'
import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material/'
import { BaseInvoiceViewDialog } from 'app/pages/invoices/dialogs/base_invoice_view'
import { downloadBasePricingPDF } from 'app/pages/invoices/services/invoice_pdf_templates'
import { generateProjectInvoice, openApproveBaseInvoiceDialog } from 'app/pages/invoices/services/invoice_progress_functions'
import { tableColumns_AdditionalWorkInvoicesBasic, tableSettings_AdditionalWorkInvoicesBasic } from 'app/pages/invoices/tables/additional_work_invoices'
import { invoiceBillingTypeOptions } from 'app/pages/invoices/tables/project_base_pricing'
import { invoiceBillToOptions } from 'app/pages/invoices/tables/task_invoice_mapping'
import React, { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_AdditionalWorkInvoicesForProject_Query } from 'rfbp_aux/services/database_endpoints/finances/project_additional_work_invoices'
import { DatabaseRef_BaseInvoicesForProject_Query } from 'rfbp_aux/services/database_endpoints/finances/project_base_invoices'
import { Icon } from 'rfbp_core/components/icons'
import { TableBasic } from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_CustomDialog, Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { dynamicSort, formatCurrency, getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { capitalizeFirstLetterInString } from 'rfbp_core/services/helper_functions/capitalize_first_letter_in_string'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectAdditionalWorkTab {
  // propKey: type
  projectKey: string
  project: TsInterface_UnspecifiedObject
  tasks: TsInterface_UnspecifiedObject
  projectFinances: TsInterface_UnspecifiedObject
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const ProjectFinanceTab = forwardRef((props: TsInterface_ProjectAdditionalWorkTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_projectKey: TsInterface_ProjectAdditionalWorkTab['projectKey'] = getProp(props, 'projectKey', null)
  let pr_project: TsInterface_ProjectAdditionalWorkTab['project'] = getProp(props, 'project', null)
  let pr_tasks: TsInterface_ProjectAdditionalWorkTab['tasks'] = getProp(props, 'tasks', null)
  let pr_projectFinances: TsInterface_ProjectAdditionalWorkTab['projectFinances'] = getProp(props, 'projectFinances', null)

  // Hooks - useContext, useState, useReducer, other
  const [us_baseInvoices, us_setBaseInvoices] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectAdditionalWorkInvoices, us_setProjectAdditionalWorkInvoices] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setBaseInvoices(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_BaseInvoicesForProject_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setBaseInvoices({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectAdditionalWorkInvoices(newData)
      }
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        if (pr_projectKey != null) {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AdditionalWorkInvoicesForProject_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        } else {
          us_setProjectAdditionalWorkInvoices({})
        }
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_projectKey, ur_forceRerender, uc_RootData_ClientKey, uc_setRootData_ClientKey])

  // Functions

  // JSX Generation
  const rJSX_SummaryExpensesCard = (): JSX.Element => {
    let cardJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-opacity-50"
        >
          {rLIB('Summary')}
        </Typography>
        <Card className="tw-p-2">
          <Typography variant="h6">{rLIB('Under Construction')}</Typography>
        </Card>
      </Box>
    )
    return cardJSX
  }

  // Base Price Card
  const rJSX_FinanceLineItem = (icon: string, label: string | JSX.Element, color: string, propKey: string): JSX.Element => {
    let propValueJSX = <></>
    let propValue = getProp(pr_project, propKey, null)
    if (propValue == null) {
      propValueJSX = (
        <Typography
          variant="body1"
          sx={{ background: themeVariables.warning_main }}
          className="tw-rounded tw-px-2 tw-py-0"
        >
          {rLIB('Missing')}
        </Typography>
      )
    } else {
      propValueJSX = <Typography variant="body1">{propValue}</Typography>
    }
    let lineItemJSX = (
      <Box className="tw-mb-1">
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Typography
            variant="body1"
            sx={{ color: color }}
          >
            <Icon
              icon={icon}
              className="tw-mr-2"
            />
            {label}:
          </Typography>
          {propValueJSX}
        </Stack>
      </Box>
    )
    return lineItemJSX
  }

  const rJSX_CustomFinanceLineItem = (
    icon: string,
    label: string | JSX.Element,
    color: string,
    propKey: string,
    rJSX_customFormatFunction: (propValue: any) => JSX.Element,
  ): JSX.Element => {
    let propValueJSX = <></>
    let propValue = getProp(pr_project, propKey, null)
    if (propValue == null) {
      propValueJSX = (
        <Typography
          variant="body1"
          sx={{ background: themeVariables.warning_main }}
          className="tw-rounded tw-px-2 tw-py-0"
        >
          {rLIB('Missing')}
        </Typography>
      )
    } else {
      // propValueJSX = <Typography variant="body1">{propValue}</Typography>
      propValueJSX = rJSX_customFormatFunction(propValue)
    }
    let lineItemJSX = (
      <Box className="tw-mb-1">
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Typography
            variant="body1"
            sx={{ color: color }}
          >
            <Icon
              icon={icon}
              className="tw-mr-2"
            />
            {label}:
          </Typography>
          {propValueJSX}
        </Stack>
      </Box>
    )
    return lineItemJSX
  }

  const rJSX_FinanceTimestampLineItem = (icon: string, label: string | JSX.Element, color: string, propKey: string): JSX.Element => {
    let propValueJSX = <></>
    let propValue = getProp(pr_project, propKey, null)
    if (propValue == null) {
      propValueJSX = (
        <Typography
          variant="body1"
          sx={{ background: themeVariables.warning_main }}
          className="tw-rounded tw-px-2 tw-py-0"
        >
          {rLIB('Missing')}
        </Typography>
      )
    } else {
      propValueJSX = <Typography variant="body1">{returnFormattedDate(propValue, 'MM/DD/YYYY h:mm a')}</Typography>
    }
    let lineItemJSX = (
      <Box className="tw-mb-1">
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Typography
            variant="body1"
            sx={{ color: color }}
          >
            <Icon
              icon={icon}
              className="tw-mr-2"
            />
            {label}:
          </Typography>
          {propValueJSX}
        </Stack>
      </Box>
    )
    return lineItemJSX
  }

  const rJSX_ApproveInvoiceButton = (): JSX.Element => {
    let projectInvoiceStatus = getProp(pr_project, 'invoice_status', null)
    let buttonJSX = <></>
    if (
      projectInvoiceStatus === 'unapproved' &&
      pr_projectFinances != null &&
      pr_projectFinances['base'] != null &&
      (pr_projectFinances['base']['approval_type'] === 'internal_no_evidence' ||
        pr_projectFinances['base']['approval_type'] === 'internal_optional_evidence' ||
        pr_projectFinances['base']['approval_type'] === 'internal_required_evidence')
    ) {
      buttonJSX = (
        <Button
          variant="contained"
          color="success"
          className="tw-mt-2 tw-ml-2"
          startIcon={<Icon icon="circle-check" />}
          onClick={() => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                openApproveBaseInvoiceDialog(res_GCK.clientKey, pr_projectKey, uc_setUserInterface_CustomDialogDisplay)
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          }}
        >
          {rLIB('Approve Base Project Quote')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_CreateInvoicesButton = (): JSX.Element => {
    let projectInvoiceStatus = getProp(pr_project, 'invoice_status', null)
    let buttonsJSX = <></>
    let generateBaseInvoiceButtonJSX = <></>
    if (projectInvoiceStatus === 'missing') {
      generateBaseInvoiceButtonJSX = (
        <Button
          variant="outlined"
          color="inherit"
          className="tw-mt-2"
          startIcon={<Icon icon="file-invoice" />}
          disabled={projectInvoiceStatus != 'missing' && projectInvoiceStatus != 'unapproved'}
          onClick={() => {
            generateProjectInvoice(pr_project, uc_RootData_ClientKey, pr_projectKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay)
          }}
        >
          {rLIB('Generate Base Invoice')}
        </Button>
      )
    }
    buttonsJSX = (
      <Box className="tw-text-center">
        {generateBaseInvoiceButtonJSX}
        {rJSX_ApproveInvoiceButton()}
      </Box>
    )
    return buttonsJSX
  }

  const rJSX_BillingMilestoneStackContent = (milestone: TsInterface_UnspecifiedObject): JSX.Element => {
    let stackContentJSX = <></>
    let projectFinanceBasePricing = getProp(pr_projectFinances, 'base', {})
    if (projectFinanceBasePricing != null && projectFinanceBasePricing['billing_type'] === 'percent') {
      stackContentJSX = (
        <>
          <Typography variant="body1">{milestone.invoice_amount_percent}%</Typography>
          <Typography
            variant="body1"
            className="tw-opacity-50 tw-italic"
          >
            ({formatCurrency((milestone.invoice_amount_percent * projectFinanceBasePricing.total_price) / 100)})
          </Typography>
        </>
      )
    } else if (projectFinanceBasePricing != null && projectFinanceBasePricing['billing_type'] === 'deposit') {
      // Remainder After Deposits
      let totalDue = getProp(projectFinanceBasePricing, 'total_price', 0)
      for (let loopBillingTaskKey in getProp(projectFinanceBasePricing, 'billing_times', {})) {
        let billingTask = getProp(projectFinanceBasePricing, 'billing_times', {})[loopBillingTaskKey]
        if (billingTask != null && billingTask.invoice_amount_type === 'deposit' && billingTask.invoice_deposit_amount != null) {
          totalDue -= billingTask.invoice_deposit_amount
        }
      }
      stackContentJSX = (
        <>
          {milestone.invoice_amount_type === 'deposit' ? (
            <Typography variant="body1">
              {formatCurrency(milestone.invoice_deposit_amount)} ({rLIB('Deposit')})
            </Typography>
          ) : (
            <Typography variant="body1">{formatCurrency(totalDue)}</Typography>
          )}
        </>
      )
    }
    return stackContentJSX
  }

  const rJSX_BillingMilestoneStatus = (milestone: TsInterface_UnspecifiedObject): JSX.Element => {
    let statusJSX = <></>
    let foundTaskStatus = false
    if (milestone.associated_task_status === 'completed') {
      statusJSX = (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Box className="tw-opacity-40">{milestone.associated_task_name}:</Box>
          <Box className="tw-opacity-40">{capitalizeFirstLetterInString(milestone.associated_task_status)}</Box>
          <Icon
            icon="circle-check"
            className="tw-ml-2"
            sx={{ color: themeVariables.primary_main }}
            tooltip={rLIB('Completed but not yet billed')}
          />
          {/* <Icon
            icon="paper-plane"
            className="tw-ml-2 tw-cursor-pointer"
            tooltip={rLIB('Email Invoice')}
            onClick={() => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  openSendProjectBaseInvoiceEmailDialog(
                    res_GCK.clientKey,
                    pr_project.key,
                    milestone.associated_task_key,
                    milestone.key,
                    milestone, // TODO
                    uc_setUserInterface_CustomDialogDisplay,
                    uc_setUserInterface_ErrorDialogDisplay,
                  )
                })
                .catch((rej_GCK) => {
                  console.error(rej_GCK)
                })
            }}
          /> */}
        </Stack>
      )
      foundTaskStatus = true
    }
    if (milestone.associated_task_status === 'active') {
      statusJSX = (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Box className="tw-opacity-40">{milestone.associated_task_name}:</Box>
          <Box className="tw-opacity-40">{capitalizeFirstLetterInString(milestone.associated_task_status)}</Box>
          <Icon
            icon="circle-play"
            className="tw-ml-2"
            sx={{ color: themeVariables.success_main }}
            tooltip={capitalizeFirstLetterInString(milestone.associated_task_status)}
          />
          {/* <Icon
            icon="paper-plane"
            className="tw-ml-2 tw-cursor-pointer"
            tooltip={rLIB('Email Invoice')}
            onClick={() => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  openSendProjectBaseInvoiceEmailDialog(
                    res_GCK.clientKey,
                    pr_project.key,
                    milestone.associated_task_key,
                    milestone.key,
                    milestone, // TODO
                    uc_setUserInterface_CustomDialogDisplay,
                    uc_setUserInterface_ErrorDialogDisplay,
                  )
                })
                .catch((rej_GCK) => {
                  console.error(rej_GCK)
                })
            }}
          /> */}
        </Stack>
      )
      foundTaskStatus = true
    }
    if (milestone.status === 'billed') {
      statusJSX = (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Box className="tw-opacity-40">{milestone.associated_task_name}:</Box>
          <Box className="tw-opacity-40">{capitalizeFirstLetterInString(milestone.status)}</Box>
          <Icon
            icon="circle-envelope"
            className="tw-ml-2"
            sx={{ color: themeVariables.info_main }}
            tooltip={rLIB('Billed and waiting for payment')}
          />
        </Stack>
      )
      foundTaskStatus = true
    }
    if (milestone.status === 'paid') {
      statusJSX = (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Box className="tw-opacity-40">{milestone.associated_task_name}:</Box>
          <Box className="tw-opacity-40">{capitalizeFirstLetterInString(milestone.status)}</Box>
          <Icon
            icon="circle-dollar"
            className="tw-ml-2"
            sx={{ color: themeVariables.success_dark }}
            tooltip={rLIB('Invoice Paid')}
          />
        </Stack>
      )
      foundTaskStatus = true
    }
    if (milestone.status === 'cancelled') {
      statusJSX = (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Box className="tw-opacity-40">{milestone.associated_task_name}:</Box>
          <Box className="tw-opacity-40">{capitalizeFirstLetterInString(milestone.status)}</Box>
          <Icon
            icon="circle-trash"
            className="tw-ml-2"
            sx={{ color: themeVariables.error_main }}
            tooltip={rLIB('Cancelled')}
          />
        </Stack>
      )
      foundTaskStatus = true
    }
    if (foundTaskStatus === false) {
      statusJSX = (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Box className="tw-opacity-40">{milestone.associated_task_name}:</Box>
          <Box className="tw-opacity-40">{capitalizeFirstLetterInString(milestone.associated_task_status)}</Box>
          <Icon
            icon="circle-pause"
            className="tw-ml-2"
            sx={{ color: themeVariables.warning_main }}
            tooltip={milestone.associated_task_status}
          />
        </Stack>
      )
    }
    return statusJSX
  }

  const rJSX_DeletedBillingTaskWarning = (milestone: TsInterface_UnspecifiedObject): JSX.Element => {
    let warningJSX = <></>
    if (
      pr_projectKey != null &&
      pr_tasks != null &&
      milestone != null &&
      milestone.associated_task_key != null &&
      pr_tasks[pr_projectKey + '_' + milestone.associated_task_key] != null
    ) {
      let task = pr_tasks[pr_project.key + '_' + milestone.associated_task_key]
      if (task != null && task.status == 'deleted') {
        warningJSX = (
          <Icon
            icon="triangle-exclamation"
            className="tw-text-warning tw-ml-2"
            sx={{ color: themeVariables.error_main }}
            tooltip={rLIB('This billing task has been deleted.')}
          />
        )
      }
    }
    return warningJSX
  }

  const rJSX_BillingMilestoneList = (): JSX.Element => {
    let listJSX = <></>
    let projectFinanceBasePricing = getProp(pr_projectFinances, 'base', {})
    listJSX = (
      <Box className="tw-ml-6">
        {objectToArray(getProp(projectFinanceBasePricing, 'billing_times', {}))
          .sort(dynamicSort('invoice_suffix', 'asc'))
          .map((milestone: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
              >
                <Box
                  className="tw-inline-block tw-mb-1"
                  sx={{ background: themeVariables.info_main, borderRadius: '4px', padding: '1px 4px', marginBottom: '4px' }}
                >
                  {milestone.invoice_suffix}
                </Box>
                {rJSX_DeletedBillingTaskWarning(milestone)}
                <Typography variant="body1">{milestone.associated_task_name}:</Typography>
                {rJSX_BillingMilestoneStackContent(milestone)}
                {pr_project.invoice_status === 'approved' ? (
                  <Icon
                    icon="magnifying-glass"
                    className="tw-ml-2 tw-cursor-pointer"
                    sx={{ color: themeVariables.info_main }}
                    onClick={() => {
                      // TODO: Missing Data
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          uc_setUserInterface_CustomDialogDisplay({
                            display: true,
                            dialog: {
                              dialog_jsx: (
                                <BaseInvoiceViewDialog
                                  clientKey={res_GCK.clientKey}
                                  invoiceKey={(pr_project.key + '_' + milestone.associated_task_key) as string}
                                />
                              ),
                              settings: {
                                max_width: 'xl',
                              },
                            },
                          })
                        })
                        .catch((rej_GCK) => {
                          console.error(rej_GCK)
                        })
                    }}
                  />
                ) : (
                  <></>
                )}
                <Icon
                  icon="file-pdf"
                  className="tw-ml-2 tw-cursor-pointer"
                  sx={{ color: themeVariables.error_main }}
                  onClick={() => {
                    // TODO: Missing Data
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        downloadBasePricingPDF(res_GCK.clientKey, pr_project.key, milestone.associated_task_key, 'download', 'project', {})
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                      })
                  }}
                />
              </Stack>
              <Box className="tw-ml-8 tw-mb-2">{rJSX_BillingMilestoneStatus(milestone)}</Box>
            </Box>
          ))}
      </Box>
    )
    return (
      <Box>
        <Typography
          variant="body1"
          sx={{ color: themeVariables.primary_main }}
        >
          <Icon
            icon="calendar-days"
            className="tw-mr-2"
          />
          {rLIB('Prospective Billing Milestones')}:
        </Typography>
        {listJSX}
      </Box>
    )
  }

  const rJSX_InvoiceBreakdown = (): JSX.Element => {
    let projectFinanceBasePricing = getProp(pr_projectFinances, 'base', {})
    return (
      <Box>
        <Typography
          variant="body1"
          sx={{ color: themeVariables.primary_main }}
        >
          <Icon
            icon="file-invoice-dollar"
            className="tw-mr-2"
          />
          {rLIB('Base Invoice Breakdown')}:
        </Typography>
        <Box className="tw-ml-6">
          {objectToArray(getProp(projectFinanceBasePricing, 'base_pricing', {}))
            .sort(dynamicSort('name', 'asc'))
            .map((lineItem: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography variant="body1">{getProp(lineItem, 'name', '')}:</Typography>
                  <Typography
                    variant="body1"
                    className="tw-opacity-50"
                  >
                    {formatCurrency(getProp(lineItem, 'calculated_price', 0))}
                  </Typography>
                </Stack>
              </Box>
            ))}
          {objectToArray(getProp(projectFinanceBasePricing, 'adder_pricing', {}))
            .sort(dynamicSort('name', 'asc'))
            .map((lineItem: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography variant="body1">{getProp(lineItem, 'name', '')}:</Typography>
                  <Typography
                    variant="body1"
                    className="tw-opacity-50"
                  >
                    {formatCurrency(getProp(lineItem, 'calculated_price', 0))}
                  </Typography>
                </Stack>
              </Box>
            ))}
          <Divider className="tw-my-1 tw-w-40" />
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
          >
            <Typography
              sx={{ fontWeight: 'bold' }}
              variant="body1"
            >
              {rLIB('Total')}:
            </Typography>
            <Typography
              sx={{ fontWeight: 'bold' }}
              variant="body1"
              className="tw-opacity-50"
            >
              {formatCurrency(getProp(projectFinanceBasePricing, 'total_price', 0))}
            </Typography>
          </Stack>
        </Box>
      </Box>
    )
  }

  const rJSX_BasePriceCard = (): JSX.Element => {
    let warningIcon = <></>
    if (pr_project != null && (pr_project.invoice_status == null || pr_project.invoice_status == 'missing')) {
      warningIcon = (
        <Icon
          className="tw-mr-2"
          sx={{ color: themeVariables.error_main, fontSize: '1.25rem' }}
          icon="triangle-exclamation"
        />
      )
    }
    // Show
    let cardJSX = (
      <Box>
        <Stack
          direction="row"
          alignItems="center"
        >
          {warningIcon}
          <Typography
            variant="h6"
            className="tw-opacity-50"
          >
            {rLIB('Project Base Price')}
          </Typography>
        </Stack>
        <Card className="tw-p-2">
          {getProp(pr_project, 'invoice_status', null) === 'approved' ? rJSX_ProjectInvoicesCardContent() : rJSX_ProjectBasePriceCardContent()}
          {rJSX_CreateInvoicesButton()}
        </Card>
      </Box>
    )
    return cardJSX
  }

  const rJSX_AdditionalWorkCard = (): JSX.Element => {
    let cardJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-opacity-50"
        >
          {rLIB('Additional Work')}
        </Typography>
        <Card>
          <TableBasic
            tableAdditionalData={{
              pr_projectKey: pr_projectKey,
            }}
            tableColumns={tableColumns_AdditionalWorkInvoicesBasic}
            tableData={objectToArray(us_projectAdditionalWorkInvoices)}
            tableSettings={tableSettings_AdditionalWorkInvoicesBasic}
          />
        </Card>
      </Box>
    )
    return cardJSX
  }

  const rJSX_PayrollExpensesCard = (): JSX.Element => {
    let cardJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-opacity-50"
        >
          {rLIB('Payroll Expenses')}
        </Typography>
        <Card className="tw-p-2">
          <Typography variant="h6">{rLIB('Under Construction')}</Typography>
        </Card>
      </Box>
    )
    return cardJSX
  }

  const rJSX_MaterialExpensesCard = (): JSX.Element => {
    let cardJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-opacity-50"
        >
          {rLIB('Material Expenses')}
        </Typography>
        <Card className="tw-p-2">
          <Typography variant="h6">{rLIB('Under Construction')}</Typography>
        </Card>
      </Box>
    )
    return cardJSX
  }

  const rJSX_VehicleExpensesCard = (): JSX.Element => {
    let cardJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-opacity-50"
        >
          {rLIB('Vehicle Expenses')}
        </Typography>
        <Card className="tw-p-2">
          <Typography variant="h6">{rLIB('Under Construction')}</Typography>
        </Card>
      </Box>
    )
    return cardJSX
  }

  const rJSX_EvidenceUploadedList = (): JSX.Element => {
    let evidenceUploadedListJSX = <></>
    if (
      pr_projectFinances != null &&
      pr_projectFinances.base != null &&
      (getProp(pr_projectFinances.base, 'approval_type', null) === 'internal_required_evidence' ||
        getProp(pr_projectFinances.base, 'approval_type', null) === 'internal_optional_evidence') &&
      objectToArray(getProp(pr_projectFinances.base, 'approval_evidence_files', {})).length > 0
    ) {
      evidenceUploadedListJSX = (
        <Box className="tw-mb-1">
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.primary_main }}
            >
              <Icon
                icon="image"
                className="tw-mr-2"
              />
              {rLIB('Approval Documentation')}:
            </Typography>
            <Box></Box>
          </Stack>
          <Box className="tw-ml-6">
            {objectToArray(getProp(pr_projectFinances.base, 'approval_evidence_files', {}))
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography variant="body1">{option.name}</Typography>
                  <Icon
                    icon="cloud-arrow-down"
                    className="tw-cursor-pointer tw-ml-2 hover:tw-opacity-100 tw-opacity-50"
                    tooltip={rLIB('Download')}
                    tooltipPlacement="right"
                    onClick={() => {
                      // Download URL
                      window.open(option.url, '_blank')
                    }}
                  />
                </Stack>
              ))}
          </Box>
        </Box>
      )
    }
    return evidenceUploadedListJSX
  }

  const rJSX_ActualBaseInvoiceLineItem = (milestone: TsInterface_UnspecifiedObject): JSX.Element => {
    let totalAmount = getProp(milestone, 'total_price', 0)
    let lineItemJSX = (
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Box
            className="tw-inline-block tw-mb-0"
            sx={{ background: themeVariables.info_main, borderRadius: '4px', padding: '1px 4px', marginBottom: '4px' }}
          >
            {milestone.invoice_suffix}
          </Box>
          {rJSX_DeletedBillingTaskWarning(milestone)}
          <Typography variant="body1">{milestone.invoice_id_number}</Typography>
        </Stack>
        <Box className="tw-ml-8">{rJSX_BillingMilestoneStatus(milestone)}</Box>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          className="tw-mb-4 tw-ml-8"
        >
          <Typography
            variant="body1"
            className="tw-opacity-50 tw-italic"
          >
            {formatCurrency(totalAmount)}
          </Typography>
          {pr_project.invoice_status === 'approved' ? (
            <Icon
              icon="magnifying-glass"
              className="tw-ml-2 tw-cursor-pointer"
              sx={{ color: themeVariables.info_main }}
              onClick={() => {
                // TODO: Missing Data
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    uc_setUserInterface_CustomDialogDisplay({
                      display: true,
                      dialog: {
                        dialog_jsx: (
                          <BaseInvoiceViewDialog
                            clientKey={res_GCK.clientKey}
                            invoiceKey={(pr_project.key + '_' + milestone.associated_task_key) as string}
                          />
                        ),
                        settings: {
                          max_width: 'xl',
                        },
                      },
                    })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }}
            />
          ) : (
            <></>
          )}
          <Icon
            icon="file-pdf"
            className="tw-ml-2 tw-cursor-pointer"
            sx={{ color: themeVariables.error_main }}
            onClick={() => {
              // TODO: Missing Data
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  downloadBasePricingPDF(res_GCK.clientKey, pr_project.key, milestone.associated_task_key, 'download', 'invoice', {})
                })
                .catch((rej_GCK) => {
                  console.error(rej_GCK)
                })
            }}
          />
        </Stack>
      </Box>
    )
    return lineItemJSX
  }

  const rJSX_ActualBaseInvoicesList = (): JSX.Element => {
    let listJSX = <></>
    listJSX = (
      <Box className="tw-ml-6">
        {objectToArray(us_baseInvoices)
          .sort(dynamicSort('invoice_suffix', 'asc'))
          .map((milestone: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>{rJSX_ActualBaseInvoiceLineItem(milestone)}</Box>
          ))}
      </Box>
    )
    return (
      <Box>
        <Typography
          variant="body1"
          sx={{ color: themeVariables.primary_main }}
        >
          <Icon
            icon="calendar-days"
            className="tw-mr-2 tw-mb-2"
          />
          {rLIB('Project Invoices')}:
        </Typography>
        {listJSX}
      </Box>
    )
  }

  const rJSX_ProjectInvoicesCardContent = (): JSX.Element => {
    let contentJSX = <></>
    contentJSX = (
      <Box>
        {rJSX_FinanceLineItem('list', rLIB('Invoice Status'), themeVariables.primary_main, 'invoice_status')}
        {rJSX_FinanceLineItem('building', rLIB('Sales Partner'), themeVariables.primary_main, 'associated_sales_partner_name')}
        {rJSX_FinanceLineItem('sitemap', rLIB('Task Workflow'), themeVariables.primary_main, 'associated_task_workflow_name')}
        {rJSX_FinanceLineItem('location-dot', rLIB('Region'), themeVariables.primary_main, 'associated_region_name')}
        {rJSX_FinanceTimestampLineItem('clock', rLIB('Bill Generated'), themeVariables.primary_main, 'timestamp_invoice_generated')}
        {rJSX_CustomFinanceLineItem('paper-plane', rLIB('Bill To'), themeVariables.primary_main, 'invoice_bill_to', (propValue: any) => {
          let mappedPropValue = propValue
          if (invoiceBillToOptions != null && invoiceBillToOptions[propValue] != null && invoiceBillToOptions[propValue].name != null) {
            mappedPropValue = invoiceBillToOptions[propValue].name
          }
          return <Typography variant="body1">{mappedPropValue}</Typography>
        })}
        {rJSX_CustomFinanceLineItem('ballot-check', rLIB('Billing Type'), themeVariables.primary_main, 'invoice_billing_type', (propValue: any) => {
          let mappedPropValue = propValue
          if (invoiceBillingTypeOptions != null && invoiceBillingTypeOptions[propValue] != null && invoiceBillingTypeOptions[propValue].value != null) {
            mappedPropValue = invoiceBillingTypeOptions[propValue].value
          }
          return <Typography variant="body1">{mappedPropValue}</Typography>
        })}
        {rJSX_ActualBaseInvoicesList()}
        {rJSX_EvidenceUploadedList()}
      </Box>
    )
    return contentJSX
  }

  const rJSX_ProjectBasePriceCardContent = (): JSX.Element => {
    return (
      <Box>
        {rJSX_FinanceLineItem('list', rLIB('Invoice Status'), themeVariables.primary_main, 'invoice_status')}
        {rJSX_FinanceLineItem('building', rLIB('Sales Partner'), themeVariables.primary_main, 'associated_sales_partner_name')}
        {rJSX_FinanceLineItem('sitemap', rLIB('Task Workflow'), themeVariables.primary_main, 'associated_task_workflow_name')}
        {rJSX_FinanceLineItem('location-dot', rLIB('Region'), themeVariables.primary_main, 'associated_region_name')}
        {rJSX_FinanceTimestampLineItem('clock', rLIB('Bill Generated'), themeVariables.primary_main, 'timestamp_invoice_generated')}
        {rJSX_CustomFinanceLineItem('paper-plane', rLIB('Bill To'), themeVariables.primary_main, 'invoice_bill_to', (propValue: any) => {
          let mappedPropValue = propValue
          if (invoiceBillToOptions != null && invoiceBillToOptions[propValue] != null && invoiceBillToOptions[propValue].name != null) {
            mappedPropValue = invoiceBillToOptions[propValue].name
          }
          return <Typography variant="body1">{mappedPropValue}</Typography>
        })}
        {rJSX_CustomFinanceLineItem('ballot-check', rLIB('Billing Type'), themeVariables.primary_main, 'invoice_billing_type', (propValue: any) => {
          let mappedPropValue = propValue
          if (invoiceBillingTypeOptions != null && invoiceBillingTypeOptions[propValue] != null && invoiceBillingTypeOptions[propValue].value != null) {
            mappedPropValue = invoiceBillingTypeOptions[propValue].value
          }
          return <Typography variant="body1">{mappedPropValue}</Typography>
        })}
        {objectToArray(getProp(pr_projectFinances, 'base', {}).billing_times).length > 0 ? rJSX_InvoiceBreakdown() : <></>}
        {objectToArray(getProp(pr_projectFinances, 'base', {}).billing_times).length > 0 ? rJSX_BillingMilestoneList() : <></>}
        {objectToArray(getProp(pr_projectFinances, 'base', {}).billing_times).length > 0 ? rJSX_EvidenceUploadedList() : <></>}
      </Box>
    )
  }

  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        <Masonry
          columns={{ xs: 1, sm: 2, md: 3, xl: 3 }}
          spacing={2}
        >
          {rJSX_SummaryExpensesCard()}
          {rJSX_BasePriceCard()}
          {rJSX_AdditionalWorkCard()}
          {rJSX_PayrollExpensesCard()}
          {rJSX_MaterialExpensesCard()}
          {rJSX_VehicleExpensesCard()}
        </Masonry>
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
})

export const rJSX_FinanceTabHeader = (
  rootProject: TsInterface_UnspecifiedObject,
  projectFinances: TsInterface_UnspecifiedObject,
  tasks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let headerJSX = <>{rLIB('Finance')}</>
  // Get list of deleted tasks
  let hasDeletedBillingTasks = false
  if (
    tasks != null &&
    projectFinances != null &&
    projectFinances.base != null &&
    projectFinances.base.billing_times != null &&
    rootProject != null &&
    rootProject.key != null
  ) {
    for (let loopTaskKey in projectFinances.base.billing_times) {
      if (loopTaskKey != null && tasks[rootProject.key + '_' + loopTaskKey] != null && tasks[rootProject.key + '_' + loopTaskKey].status == 'deleted') {
        hasDeletedBillingTasks = true
      }
    }
  }
  if (rootProject != null && (rootProject.invoice_status == null || rootProject.invoice_status == 'missing' || hasDeletedBillingTasks === true)) {
    headerJSX = (
      <Stack
        direction="row"
        alignItems="center"
      >
        <Icon
          className="tw-mr-2"
          sx={{ color: themeVariables.error_main }}
          icon="triangle-exclamation"
        />
        {rLIB('Finance')}
      </Stack>
    )
  }
  return headerJSX
}
