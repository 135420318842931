/* eslint-disable react/prop-types */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, CircularProgress, ImageList, ImageListItem, Typography, useMediaQuery } from '@mui/material/'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import React, { useEffect, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { Icon } from 'rfbp_core/components/icons'
import { TabsBasic } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { downloadAdditionalWorkQuotePDF, downloadBasePricingPDF } from './services/invoice_pdf_templates'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const clientKey: string = params.ck as string
  const invoiceKey: string = params.ik as string
  const invoiceType: string = params.it as string

  // Hooks - useContext, useState, useReducer, other
  const [us_downloadingPdf, us_setDownloadingPdf] = useState<boolean>(false)
  const [us_dataLoadedStatus, us_setDataLoadedStatus] = useState<string>('loading')
  const [us_invoiceData, us_setInvoiceData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_invoicePdfBase64, us_setInvoicePdfBase64] = useState<string | null>(null)
  const [us_invoiceImageUrls, us_setInvoiceImageUrls] = useState<string[]>([])
  const [us_screenSize, us_setScreenSize] = useState<string>('md')
  const umq_isExtraSmallScreen = useMediaQuery('(max-width: 400px)')
  const umq_isLargeScreen = useMediaQuery('(min-width: 961px) and (max-width: 1280px)')
  const umq_isMediumScreen = useMediaQuery('(min-width: 601px) and (max-width: 960px)')
  const umq_isSmallScreen = useMediaQuery('(min-width: 401px) and (max-width: 600px)')
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Invoice', false) as string
  }, [])

  useEffect(() => {
    if (umq_isExtraSmallScreen === true) {
      us_setScreenSize('xs')
    } else if (umq_isSmallScreen === true) {
      us_setScreenSize('sm')
    } else if (umq_isMediumScreen === true) {
      us_setScreenSize('md')
    } else if (umq_isLargeScreen === true) {
      us_setScreenSize('lg')
    } else {
      us_setScreenSize('xl')
    }
    ur_forceRerender()
    return () => {}
  }, [umq_isExtraSmallScreen, umq_isSmallScreen, umq_isMediumScreen, umq_isLargeScreen, ur_forceRerender])

  useEffect(() => {
    us_setDataLoadedStatus('loading')
    if (clientKey != null && invoiceKey != null && invoiceType != null) {
      let functionName = null
      if (invoiceType === 'bp') {
        functionName = 'getProjectBaseInvoiceData'
      } else if (invoiceType === 'aw') {
        functionName = 'getProjectAdditionalWorkInvoiceData'
      }
      if (functionName != null) {
        cloudFunctionUnauthenticatedRequests({
          function: functionName,
          client_key: clientKey,
          invoice_key: invoiceKey,
        })
          .then((res_CFUR) => {
            us_setDataLoadedStatus('success')
            us_setInvoiceData(getProp(getProp(res_CFUR, 'data', {}), 'data', {}))
          })
          .catch((rej_CFUR) => {
            us_setDataLoadedStatus('error')
          })
      } else {
        us_setDataLoadedStatus('error')
      }
    } else {
      us_setDataLoadedStatus('loading')
    }
  }, [clientKey, invoiceKey, invoiceType])

  useEffect(() => {
    if (us_invoiceData != null && us_invoiceData.invoice != null) {
      if (invoiceType === 'bp') {
        downloadBasePricingPDF(
          clientKey,
          getProp(getProp(us_invoiceData, 'invoice', {}), 'associated_project_key', ''),
          getProp(getProp(us_invoiceData, 'invoice', {}), 'associated_task_key', ''),
          'base64',
          'invoice',
          us_invoiceData,
        ).then((res_DBPP) => {
          us_setInvoicePdfBase64(getProp(res_DBPP, 'data', null))
        })
      } else if (invoiceType === 'aw') {
        downloadAdditionalWorkQuotePDF(
          clientKey,
          getProp(getProp(us_invoiceData, 'invoice', {}), 'associated_project_key', null),
          getProp(getProp(us_invoiceData, 'invoice', {}), 'bill_to', null),
          getProp(getProp(us_invoiceData, 'invoice', {}), 'invoice_id_number', null),
          objectToArray(getProp(getProp(us_invoiceData, 'invoice', {}), 'line_items', {})),
          'base64',
          us_invoiceData,
        ).then((res_DAWP) => {
          us_setInvoicePdfBase64(getProp(res_DAWP, 'data', null))
        })
      }
    }
  }, [clientKey, invoiceType, us_invoiceData])

  useEffect(() => {
    let imageUrls: string[] = []
    // Loop through and get all image urls from invoice.approval_evidence_files and taskFormData / tasksFormData
    if (us_invoiceData != null && us_invoiceData.invoice != null && us_invoiceData.invoice.approval_evidence_files != null) {
      for (let loopImageKey in us_invoiceData.invoice.approval_evidence_files) {
        let loopImage = us_invoiceData.invoice.approval_evidence_files[loopImageKey]
        if (loopImage != null && loopImage.url != null) {
          imageUrls.push(loopImage.url)
        }
      }
    }
    if (us_invoiceData != null && us_invoiceData['tasksFormData'] != null) {
      for (let loopTaskKey in us_invoiceData['tasksFormData']) {
        let loopTask = us_invoiceData['tasksFormData'][loopTaskKey]
        for (let loopTabKey in loopTask) {
          let loopTab = loopTask[loopTabKey]
          if (loopTab != null && loopTab.folders != null) {
            for (let loopFolderKey in loopTab.folders) {
              let loopFolder = loopTab.folders[loopFolderKey]
              for (let loopFileKey in loopFolder) {
                let loopFile = loopFolder[loopFileKey]
                if (loopFile != null && loopFile.url != null && loopFile.upload_type === 'image') {
                  imageUrls.push(loopFile.url)
                }
              }
            }
          }
        }
      }
    }
    if (us_invoiceData != null && us_invoiceData['taskFormData'] != null) {
      for (let loopTabKey in us_invoiceData.taskFormData) {
        let loopTab = us_invoiceData.taskFormData[loopTabKey]
        if (loopTab != null && loopTab.folders != null) {
          for (let loopFolderKey in loopTab.folders) {
            let loopFolder = loopTab.folders[loopFolderKey]
            for (let loopFileKey in loopFolder) {
              let loopFile = loopFolder[loopFileKey]
              if (loopFile != null && loopFile.url != null && loopFile.upload_type === 'image') {
                imageUrls.push(loopFile.url)
              }
            }
          }
        }
      }
    }
    us_setInvoiceImageUrls(imageUrls)
  }, [us_invoiceData])

  // Functions

  // JSX Generation
  const rJSX_ErrorDisplay = (): JSX.Element => {
    let errorsJSX = <></>
    errorsJSX = (
      <Box
        className="tw-text-center tw-m-auto tw-rounded-lg tw-p-4"
        sx={{ maxWidth: '600px', border: '2px solid ' + themeVariables.error_main }}
      >
        <Typography
          variant="h5"
          sx={{ color: themeVariables.error_main }}
        >
          <Icon
            icon="triangle-exclamation"
            className="tw-mr-2"
          />
          {rLIB('Failed to load invoice data')}
        </Typography>
        <Typography
          variant="h6"
          sx={{ color: themeVariables.white }}
          className="tw-mt-2"
        >
          {rLIB('If this error persists please contact ETW Energy for support')}
        </Typography>
      </Box>
    )
    return errorsJSX
  }

  const rJSX_InvoiceTab = (): JSX.Element => {
    if (!us_invoicePdfBase64) {
      return (
        <Box className="tw-text-center tw-p-4">
          <CircularProgress />
        </Box>
      )
    }

    const byteCharacters = atob(us_invoicePdfBase64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: 'application/pdf' })
    const blobUrl = window.URL.createObjectURL(blob)

    return (
      <embed
        src={`${blobUrl}#toolbar=0`}
        type="application/pdf"
        style={{ width: '100%', height: 'calc(100vh - 200px)' }}
      />
    )
  }

  const rJSX_ImagesTab = (): JSX.Element => {
    let columns = 4
    if (us_screenSize === 'xs') {
      columns = 2
    } else if (us_screenSize === 'sm') {
      columns = 3
    } else if (us_screenSize === 'md') {
      columns = 4
    } else if (us_screenSize === 'lg') {
      columns = 5
    } else if (us_screenSize === 'xl') {
      columns = 6
    }
    return (
      <Box>
        <ImageList
          cols={columns}
          variant="standard"
        >
          {us_invoiceImageUrls.sort(dynamicSort('timestamp_uploaded', 'asc')).map((photoUrl: string, index: number) => (
            <ImageListItem key={index}>
              <Box
                sx={{
                  'position': 'relative',
                  'cursor': 'pointer',
                  '&:hover .download-overlay': {
                    opacity: 1,
                  },
                }}
                onClick={() => {
                  window.open(photoUrl, '_blank')
                }}
              >
                <Box
                  sx={{
                    height: '400px',
                    backgroundImage: "url('" + photoUrl + "')",
                    backgroundSize: 'contain',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '5px',
                    border: '1px solid ' + themeVariables.background_paper,
                  }}
                />
                <Box
                  className="download-overlay"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(0, 0, 0, 0.5)',
                    opacity: 0,
                    transition: 'opacity 0.2s',
                  }}
                >
                  <Icon
                    icon="expand"
                    className="tw-text-white tw-text-2xl"
                  />
                </Box>
              </Box>
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    )
  }

  const rJSX_DownloadPDFButton = (): JSX.Element => {
    return (
      <Button
        variant="contained"
        color="error"
        startIcon={
          us_downloadingPdf ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="file-pdf" />
          )
        }
        disabled={us_downloadingPdf}
        onClick={() => {
          if (us_invoiceData != null && us_invoiceData.invoice != null) {
            if (invoiceType === 'bp') {
              us_setDownloadingPdf(true)
              downloadBasePricingPDF(
                clientKey,
                getProp(getProp(us_invoiceData, 'invoice', {}), 'associated_project_key', ''),
                getProp(getProp(us_invoiceData, 'invoice', {}), 'associated_task_key', ''),
                'download',
                'invoice',
                us_invoiceData,
              ).then((res_DBPP) => {
                us_setDownloadingPdf(false)
              })
            } else if (invoiceType === 'aw') {
              us_setDownloadingPdf(true)
              downloadAdditionalWorkQuotePDF(
                clientKey,
                getProp(getProp(us_invoiceData, 'invoice', {}), 'associated_project_key', null),
                getProp(getProp(us_invoiceData, 'invoice', {}), 'bill_to', null),
                getProp(getProp(us_invoiceData, 'invoice', {}), 'invoice_id_number', null),
                objectToArray(getProp(getProp(us_invoiceData, 'invoice', {}), 'line_items', {})),
                'download',
                us_invoiceData,
              ).then((res_DAWP) => {
                us_setDownloadingPdf(false)
              })
            }
          }
        }}
      >
        {rLIB('Download PDF')}
      </Button>
    )
  }

  const rJSX_PageContent = (): JSX.Element => {
    let contentJSX = <></>
    if (us_dataLoadedStatus === 'loading') {
      contentJSX = (
        <Box className="tw-text-center tw-p-4">
          <CircularProgress />
        </Box>
      )
    } else if (us_dataLoadedStatus === 'success') {
      contentJSX = (
        <Box className="tw-p-4">
          <Box>{rJSX_DownloadPDFButton()}</Box>
          <TabsBasic
            tabs={[
              {
                tabHeader: rLIB('Invoice'),
                tabContent: <Box>{rJSX_InvoiceTab()}</Box>,
              },
              {
                tabHeader: rLIB('Images'),
                tabContent: <Box>{rJSX_ImagesTab()}</Box>,
              },
            ]}
            tabsSettings={{ thin: true }}
          />
        </Box>
      )
    } else if (us_dataLoadedStatus === 'error') {
      contentJSX = <Box className="tw-p-4">{rJSX_ErrorDisplay()}</Box>
    }
    return contentJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = <Box>{rJSX_PageContent()}</Box>
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
