import { Box, Button, Tab, Tabs, TextField, Typography } from '@mui/material'
import { updatePassword, User } from 'firebase/auth'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { GeoTiffMap } from './components/geoTiffMap'
import {
  rJSX_AdditionalOptionsSection,
  rJSX_designPreferences,
  rJSX_FinancingSection,
  rJSX_generalInformation,
  rJSX_homeDetails,
  rJSX_LunarSystemSection,
  rJSX_mainPanel,
  rJSX_otherGenerationAndHighLoadItems,
  rJSX_panelLayoutFinanceDetails,
  rJSX_panelLayoutHeader,
  rJSX_panelLayoutSystemDetails,
  rJSX_roofCondition,
  rJSX_subPanel,
  rJSX_welcomeFinanceDetails,
  rJSX_welcomeHeader,
  rJSX_welcomeHomeDetails,
  rJSX_welcomeOptionsDetails,
  rJSX_welcomeSystemDetails,
} from './unauthenticated_sales_opportunity_v3_components/building_page_sections'

export const rJSX_WelcomePage = (
  stateGettersSetters: any,
  palette: any,
  photoInputRef: React.RefObject<HTMLInputElement>,
  clientKey: string,
  opportunityKey: string,
  proposalKeysRef: React.MutableRefObject<{
    primary: string
    secondary: string
    tertiary: string
  }>,
): JSX.Element => {
  let proposalKey: string = ''
  if (stateGettersSetters.us_activeProposal === 'original') {
    proposalKey = proposalKeysRef.current.primary
  } else if (stateGettersSetters.us_activeProposal === 'secondary') {
    proposalKey = proposalKeysRef.current.secondary
  } else {
    proposalKey = proposalKeysRef.current.tertiary
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    // Scroll to the relevant section when the tab changes
    if (newValue === 0) {
      stateGettersSetters.us_setSelectedTab('orderStatus')
    } else if (newValue === 1) {
      stateGettersSetters.us_setSelectedTab('systemDetails')
    } else if (newValue === 2) {
      stateGettersSetters.us_setSelectedTab('financing')
    } else if (newValue === 3) {
      stateGettersSetters.us_setSelectedTab('houseDetails')
    }
  }

  const orderStatusTabSelected = stateGettersSetters.us_selectedTab === 'orderStatus' ? palette.aqua : palette.darkGray
  const financingTabSelected = stateGettersSetters.us_selectedTab === 'financing' ? palette.aqua : palette.darkGray
  const houseDetailsTabSelected = stateGettersSetters.us_selectedTab === 'houseDetails' ? palette.aqua : palette.darkGray
  const systemSizeTabSelected = stateGettersSetters.us_selectedTab === 'systemDetails' ? palette.aqua : palette.darkGray

  return (
    <Box
      sx={{
        minHeight: '100vh',
        minWidth: '100vw',
        maxWidth: '100vw',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Box sx={{ background: palette.white }}>
        <Tabs
          value={false}
          onChange={handleTabChange}
          centered
        >
          <Tab
            label={rLIB('Order Status')}
            sx={{
              fontFamily: 'Poppins',
              fontSize: '9px',
              borderBottom: '2px solid',
              borderColor: orderStatusTabSelected,
              marginX: 0.3,
              color: orderStatusTabSelected,
            }}
          />
          <Tab
            label={rLIB('System Size')}
            sx={{
              fontFamily: 'Poppins',
              fontSize: '9px',
              borderBottom: '2px solid',
              borderColor: systemSizeTabSelected,
              marginX: 0.3,
              color: systemSizeTabSelected,
            }}
          />
          <Tab
            label={rLIB('Financing')}
            sx={{
              fontFamily: 'Poppins',
              fontSize: '9px',
              borderBottom: '2px solid',
              borderColor: financingTabSelected,
              marginX: 0.3,
              color: financingTabSelected,
            }}
          />
          <Tab
            label={rLIB('House Details')}
            sx={{
              fontFamily: 'Poppins',
              fontSize: '9px',
              borderBottom: '2px solid',
              borderColor: houseDetailsTabSelected,
              marginX: 0.3,
              color: houseDetailsTabSelected,
            }}
          />
        </Tabs>
      </Box>
      {/* Aqua background section (3/8 of the page) */}
      {(stateGettersSetters.us_selectedTab === 'orderStatus' || stateGettersSetters.us_selectedTab === 'systemDetails') && (
        <Box
          sx={{
            background: palette.aqua,
            height: '55.5vh', // Reduced from 55.5vh to 37.5vh (3/8 of 100vh)
            position: 'relative',
            padding: '10px',
          }}
        >
          {(stateGettersSetters.us_selectedTab === 'orderStatus' || stateGettersSetters.us_selectedTab === 'systemDetails') && rJSX_welcomeHeader(palette)}
          {stateGettersSetters.us_productType !== 'solar' && (
            <Box sx={{ marginTop: '20px' }}>{rJSX_LunarSystemSection(stateGettersSetters, palette, opportunityKey, clientKey)}</Box>
          )}
        </Box>
      )}
      {/* Map positioned at the intersection */}
      {stateGettersSetters.us_productType === 'solar' && stateGettersSetters.us_selectedTab === 'systemDetails' && (
        <Box
          sx={{
            position: 'absolute',
            top: '40vh', // Adjust this value to fine-tune the map's vertical position
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1,
            width: '80vw',
            height: '35vh',
            overflow: 'hidden',
          }}
        >
          {/* <GeoTiffMap
          tiffUrl={stateGettersSetters.us_evImageURL}
          latitude={stateGettersSetters.us_latitude}
          longitude={stateGettersSetters.us_longitude}
          zoomLevel={2}
        /> */}

          <img
            src={`data:image/jpeg;base64, ${stateGettersSetters.us_evImageURL}`}
            alt="Sales Map"
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
      )}

      {/* White background section (remaining 5/8 of the page) */}
      <Box
        sx={{
          background: palette.white,
          flexGrow: 1,
          padding: '10px',
          paddingTop: stateGettersSetters.us_selectedTab === 'systemDetails' ? '150px' : '0px', // Add padding to account for the overlapping map
        }}
      >
        {stateGettersSetters.us_productType === 'solar' &&
          stateGettersSetters.us_selectedTab === 'systemDetails' &&
          rJSX_welcomeSystemDetails(stateGettersSetters, palette, opportunityKey, clientKey, proposalKeysRef)}
        {stateGettersSetters.us_productType === 'solar' &&
          stateGettersSetters.us_selectedTab === 'systemDetails' &&
          rJSX_welcomeOptionsDetails(stateGettersSetters, palette)}
        {stateGettersSetters.us_selectedTab === 'financing' && rJSX_welcomeFinanceDetails(stateGettersSetters, palette)}
        {stateGettersSetters.us_selectedTab === 'financing' && (
          <Box sx={{ marginBottom: '20px', padding: '10px' }}>
            {rJSX_FinancingSection(stateGettersSetters, palette, clientKey, opportunityKey, proposalKey)}
            {/* <Button
              variant="contained"
              className="tw-w-1/2 tw-mt-4"
              onClick={() => {
                stateGettersSetters.us_setShowFinanceDetailsInWelcome(false)
              }}
              sx={{
                'fontFamily': 'Poppins',
                'textTransform': 'none',
                'backgroundColor': '#42deff',
                'color': palette.white,
                '&:hover': {
                  backgroundColor: palette.aqua,
                },
                'borderRadius': '30px',
                'fontSize': '15px',
                'padding': '10px 10px',
                'width': 'auto', // Adjust width as needed
                'marginLeft': '90px',
              }}
            >
              {rLIB('Save')}
            </Button> */}
          </Box>
        )}
        {stateGettersSetters.us_selectedTab === 'houseDetails' && rJSX_welcomeHomeDetails(stateGettersSetters, palette, photoInputRef)}
      </Box>
    </Box>
  )
}

export const rJSX_SurveyPage = (
  stateGettersSetters: any,
  palette: any,
  fileInputRef: React.RefObject<HTMLInputElement>,
  clientKey: string,
  opportunityKey: string,
): JSX.Element => {
  return (
    <Box
      sx={{
        padding: '20px',
        maxWidth: '800px',
        margin: '0 auto',
        backgroundColor: palette.white, // Add explicit white background
        minHeight: '100vh', // Ensure full height
        position: 'relative', // Ensure proper stacking
        zIndex: 1,
      }}
    >
      <Button
        startIcon={
          <Icon
            icon="arrow-left"
            className="tw-text-gray_600"
            sx={{ fontFamily: 'Poppins', fontSize: '10px' }}
          />
        }
        onClick={() => {
          stateGettersSetters.us_setCurrentView('welcome')
        }}
        className="tw-text-gray_600 "
        sx={{
          'fontFamily': 'Poppins',
          'textTransform': 'none',
          'backgroundColor': 'transparent',
          'position': 'absolute',
          'left': '0',
          'padding': '0px 10px',
          'marginBottom': '10px',
          '&:hover': {
            backgroundColor: palette.gray_600,
          },
        }}
      >
        Back
      </Button>
      <Typography
        variant="h4"
        sx={{
          fontFamily: 'Poppins',
          color: '#939598',
          marginTop: '20px',
          marginBottom: '30px',
          textAlign: 'center',
        }}
      >
        {rLIB('Home Survey')}
      </Typography>

      {rJSX_generalInformation(stateGettersSetters, palette, fileInputRef, clientKey, opportunityKey)}

      {rJSX_homeDetails(stateGettersSetters, palette, clientKey, opportunityKey)}

      {rJSX_roofCondition(stateGettersSetters, palette, clientKey, opportunityKey)}

      {rJSX_mainPanel(stateGettersSetters, palette, clientKey, opportunityKey)}

      {rJSX_subPanel(stateGettersSetters, palette, clientKey, opportunityKey)}

      {rJSX_otherGenerationAndHighLoadItems(stateGettersSetters, palette, clientKey, opportunityKey)}

      {rJSX_designPreferences(stateGettersSetters, palette, clientKey, opportunityKey)}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          onClick={() => {
            stateGettersSetters.us_setCurrentView('welcome')
          }}
          sx={{
            'fontFamily': 'Poppins',
            'textTransform': 'none',
            'backgroundColor': palette.aqua,
            'color': palette.white,
            '&:hover': {
              backgroundColor: palette.teal,
            },
            'marginTop': '20px',
          }}
        >
          {rLIB('Submit Survey')}
        </Button>
      </Box>
    </Box>
  )
}

export const rJSX_WelcomeAddressBanner = (stateGettersSetters: any, logoSrc: string, palette: TsInterface_UnspecifiedObject): JSX.Element => {
  let bannerJSX = (
    <Box
      className="tw-p-2"
      sx={{ background: palette.white }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '5px',
          paddingX: '5px',
          alignItems: 'center',
        }}
      >
        <Box
          className="tw-mb-2"
          component="img"
          sx={{ width: 189, height: 54, background: 'white', borderRadius: '5px', marginRight: '20px' }}
          alt="logo"
          src={logoSrc}
        />
        <Box
          sx={{
            marginTop: '6px',
            marginBottom: '10px',
            marginLeft: '25px',
            display: 'flex', // Added Flexbox
            flexDirection: 'column', // Changed to column layout
            alignItems: 'flex-start', // Align items vertically
            gap: '10px', // Space between buttons
          }}
        >
          <Button
            className="tw-text-gray_600"
            startIcon={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                  icon="circle-user"
                  sx={{ fontSize: '17px' }}
                />
              </Box>
            }
            onClick={() => {
              directAppNavigation(stateGettersSetters.un_routerNavigation, ApplicationPages.UnauthenticatedSalesLoginPage.url())
            }}
            sx={{
              paddingLeft: '53px',
              textTransform: 'none',
              fontSize: '12px',
              whiteSpace: 'nowrap', // Prevent text wrapping
            }}
          >
            {rLIB('Log Out')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              // TODO: open support modal
            }}
            sx={{
              'textTransform': 'none',
              'backgroundColor': '#42deff',
              'color': palette.white,
              '&:hover': {
                backgroundColor: palette.aqua,
              },
              'borderRadius': '5px',
              'fontSize': '12px', // Increased font size for better readability
              'paddingX': '5px', // Added horizontal padding
              'paddingY': '8px', // Added vertical padding
              'whiteSpace': 'nowrap', // Prevent text wrapping
              'minWidth': '120px', // Ensures the button isn't too small
            }}
          >
            {rLIB('Request Support')}
          </Button>
        </Box>
      </Box>
    </Box>
  )

  return bannerJSX
}

export const rJSX_ResetPasswordPage = (stateGettersSetters: any, palette: any, user: User | null): JSX.Element => {
  return (
    <Box
      sx={{
        backgroundColor: palette.aqua, // Set the background color to blue (aqua)
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh', // Ensure the Box takes the full viewport height
        padding: '40px 20px', // Adds some padding to the top and sides
        boxSizing: 'border-box', // Ensures padding is included in the total height
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
        <Button
          startIcon={
            <Icon
              icon="arrow-left"
              className="tw-text-white"
              sx={{ fontFamily: 'Poppins', fontSize: '14px' }}
            />
          }
          onClick={() => {
            stateGettersSetters.us_setCurrentView('welcome')
          }}
          className="tw-text-white"
          sx={{
            'fontFamily': 'Poppins',
            'textTransform': 'none',
            'backgroundColor': 'transparent',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
          }}
        >
          {rLIB('Back')}
        </Button>
      </Box>
      {/* Title */}
      <Typography
        variant="h4"
        sx={{
          color: palette.white,
          marginBottom: '8px', // Reduced margin
          fontWeight: 'bold',
          marginTop: '0', // Remove any default top margin
        }}
      >
        {rLIB('Password Reset')}
      </Typography>

      {/* Subtitle */}
      <Typography
        variant="h6"
        sx={{
          color: palette.white,
          marginBottom: '24px', // Adjusted margin
          fontSize: '14px',
        }}
      >
        {rLIB('Enter your new password below to reset your password')}
      </Typography>

      {/* Password Input */}
      <TextField
        label="Password"
        variant="outlined"
        type="password"
        sx={{
          backgroundColor: palette.white,
          borderRadius: '4px',
          marginBottom: '16px',
          width: '300px',
        }}
        onChange={(e) => {
          stateGettersSetters.us_setNewPassword(e.target.value.trim())
        }}
        onBlur={(e) => {
          stateGettersSetters.us_setNewPassword(e.target.value.trim())
        }}
      />

      {/* Confirm Password Input */}
      <TextField
        label="Confirm Password"
        variant="outlined"
        type="password"
        sx={{
          backgroundColor: palette.white,
          borderRadius: '4px',
          marginBottom: '16px',
          width: '300px',
        }}
        onChange={(e) => {
          stateGettersSetters.us_setConfirmPassword(e.target.value.trim())
        }}
        onBlur={(e) => {
          stateGettersSetters.us_setConfirmPassword(e.target.value.trim())
        }}
      />

      {/* Login Button */}
      <Button
        variant="contained"
        sx={{
          'marginTop': '20px',
          'backgroundColor': palette.white,
          'color': palette.aqua,
          'border': `1px solid ${palette.aqua}`,
          'width': '200px',
          'padding': '10px',
          'borderRadius': '25px',
          'textTransform': 'none',
          'fontWeight': 'bold',
          'fontSize': '16px',
          '&:hover': {
            backgroundColor: palette.aqua,
            color: palette.white,
            border: `2px solid ${palette.aqua}`,
          },
        }}
        onClick={() => {
          if (user) {
            updatePassword(user, stateGettersSetters.us_newPassword)
          }
          stateGettersSetters.us_setCurrentView('welcome')
        }}
        disabled={
          stateGettersSetters.us_newPassword === '' ||
          stateGettersSetters.us_confirmPassword === '' ||
          stateGettersSetters.us_newPassword !== stateGettersSetters.us_confirmPassword
        }
      >
        {rLIB('Submit')}
      </Button>
    </Box>
  )
}

export const rJSX_EditPanelLayout = (stateGettersSetters: any, palette: any, opportunityKey: string, clientKey: string, proposalKeysRef: any): JSX.Element => {
  let proposalKey: string = ''
  if (stateGettersSetters.us_activeProposal === 'original') {
    proposalKey = proposalKeysRef.current.primary
  } else if (stateGettersSetters.us_activeProposal === 'secondary') {
    proposalKey = proposalKeysRef.current.secondary
  } else {
    proposalKey = proposalKeysRef.current.tertiary
  }
  return (
    <Box
      sx={{
        minHeight: '100vh',
        minWidth: '100vw',
        maxWidth: '100vw',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {/* Aqua background section (3/8 of the page) */}
      <Box
        sx={{
          background: palette.aqua,
          height: '55.5vh', // Reduced from 55.5vh to 37.5vh (3/8 of 100vh)
          position: 'relative',
          padding: '10px',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ marginBottom: '15px', display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
          <Button
            startIcon={
              <Icon
                icon="arrow-left"
                className="tw-text-gray_600"
                sx={{ fontFamily: 'Poppins', fontSize: '10px' }}
              />
            }
            onClick={() => {
              stateGettersSetters.us_setCurrentView('welcome')
            }}
            className="tw-text-gray_600 "
            sx={{
              'fontFamily': 'Poppins',
              'textTransform': 'none',
              'backgroundColor': 'transparent',
              'position': 'absolute',
              'left': '0',
              'padding': '0px 10px',
              'marginBottom': '10px',
              '&:hover': {
                backgroundColor: palette.gray_600,
              },
            }}
          >
            Back
          </Button>
        </Box>
        {rJSX_panelLayoutHeader(palette)}
      </Box>

      {/* Map positioned at the intersection */}
      <Box
        sx={{
          position: 'absolute',
          top: '30vh',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1,
          width: '350px',
          height: '350px',
          overflow: 'hidden',
          //position: 'relative',
        }}
      >
        <GeoTiffMap
          tiffUrl={'clients/etw_energy_sales/sales/eagleview-images/HhkjRz5aEjkvAEJSF41K.tif'}
          latitude={stateGettersSetters.us_latitude}
          longitude={stateGettersSetters.us_longitude}
          zoomLevel={2}
          panels={stateGettersSetters.us_panels}
        />
        {/* <img
          src={`data:image/jpeg;base64, ${stateGettersSetters.us_evImageURL}`}
          alt="Sales Map"
          style={{
            width: '100%',
            height: '100%',
          }}
        />

        <svg
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 2,
            transform: 'rotate(-2deg)',
          }}
        >
          {Object.values(stateGettersSetters.us_panels).map((panel: any, index: number) => {
            // Find the bounds of all panels to create a scale
            const allCorners = Object.values(stateGettersSetters.us_panels).flatMap((p: any) =>
              Object.values(p.row.coordinates).map((corner: any) => ({
                lat: corner[0],
                lng: corner[1],
              })),
            )
            const minLat = Math.min(...allCorners.map((c: any) => c.lat))
            const maxLat = Math.max(...allCorners.map((c: any) => c.lat))
            const minLng = Math.min(...allCorners.map((c: any) => c.lng))
            const maxLng = Math.max(...allCorners.map((c: any) => c.lng))

            // Add offset percentages to shift the panels
            const scaleFactor = 1.5
            const xOffset = 65 // Adjust this value to move right/left
            const yOffset = 170 // Adjust this value to move up/down

            // Convert coordinates to relative positions (0-1 scale)
            const points = Object.values(panel.row.coordinates)
              .map((corner: any) => {
                const x = ((corner[1] - minLng) / (maxLng - minLng)) * 100 * scaleFactor + xOffset
                const y = 100 - ((corner[0] - minLat) / (maxLat - minLat)) * 100 * scaleFactor + yOffset
                return `${x},${y}`
              })
              .join(' ')

            return (
              <polygon
                key={index}
                points={points}
                fill="rgba(0, 0, 255, 0.3)"
                stroke="blue"
                strokeWidth="1"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  // Handle click event
                  console.log('Panel clicked:', panel)
                  // Add your click handler here, for example:
                  // stateGettersSetters.us_handlePanelClick(panel)
                }}
              />
            )
          })}
        </svg> */}
      </Box>

      {/* White background section (remaining 5/8 of the page) */}
      <Box
        sx={{
          background: palette.white,
          minHeight: '100vh',
          flexGrow: 1,
          padding: '10px',
          paddingTop: '150px', // Add padding to account for the overlapping map
          overflow: 'auto',
        }}
      >
        {rJSX_panelLayoutSystemDetails(stateGettersSetters, palette, opportunityKey, clientKey, proposalKeysRef)}
        {rJSX_AdditionalOptionsSection(stateGettersSetters, palette, opportunityKey, clientKey, proposalKey, true)}
        {rJSX_panelLayoutFinanceDetails(stateGettersSetters, palette)}
      </Box>
    </Box>
  )
}
