//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Provides easy to use

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Link } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  TsInterface_TableAdditionalData,
  TsInterface_TableColumn,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsType_TableSortBy,
} from 'rfbp_core/components/table'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const TableCellLink = (
  propKey: string,
  propName: string | JSX.Element,
  sortBy: TsType_TableSortBy,
  baseUrl: string,
  urlKey: string,
): TsInterface_TableColumn => {
  let basicCell = {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <>{propName}</>
      },
      header_sort_by: sortBy,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return rowData[propKey] ? (
          <Link
            href={`${baseUrl}/${rowData[urlKey]}`}
            underline="hover"
            target="_blank"
            rel="noopener noreferrer"
          >
            {`${rowData[propKey]}`}
          </Link>
        ) : (
          <></>
        )
      },
    },
  }
  return basicCell
}

export const TableCellProjectLink = (
  propKey: string,
  propName: string | JSX.Element,
  sortBy: TsType_TableSortBy,
  baseUrl: string,
  urlKey: string,
): TsInterface_TableColumn => {
  return {
    header: {
      header_jsx: () => <>{propName}</>,
      header_sort_by: sortBy,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return rowData[propKey] ? (
          <Link
            href={ApplicationPages.SubKProjectViewPage.url(rowData[urlKey] as string)}
            underline="none"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'inline-block',
              backgroundColor: themeVariables.primary_dark,
              color: 'white',
              padding: '4px 12px',
              borderRadius: '16px',
              fontSize: '0.875rem',
              fontWeight: 500,
              textAlign: 'center',
              textDecoration: 'none',
              transition: 'background-color 0.3s ease', // Smooth hover transition
            }}
            onMouseEnter={(e) => {
              ;(e.target as HTMLElement).style.backgroundColor = themeVariables.primary_light
            }}
            onMouseLeave={(e) => {
              ;(e.target as HTMLElement).style.backgroundColor = themeVariables.primary_dark
            }}
          >
            {rowData[propKey]}
          </Link>
        ) : (
          <></>
        )
      },
    },
  }
}
