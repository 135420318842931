///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_RentalFiles_Collection,
  DatabaseRef_RentalFile_Document,
  DatabaseRef_RentalForumMessages_Collection,
  DatabaseRef_RentalForumMessage_Document,
  DatabaseRef_RentalForumThreads_Collection,
  DatabaseRef_RentalForumThread_Document,
  StorageRef_RentalFile,
  StorageRef_Rental_MessageThread_File,
} from 'rfbp_aux/services/database_endpoints/directory/rentals'
import { DatabaseRef_Rental_Document } from 'rfbp_aux/services/database_endpoints/directory/vehicles'
import { FileSystemBasic } from 'rfbp_core/components/file_system/file_system_basic'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { ForumBasic } from 'rfbp_core/components/forum/forum_basic'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { formatCurrency, getProp } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

const formInputs_RentalCompany: TsInterface_FormInputs = {
  rental_company_name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'rental_company_name',
    label: rLIB('Rental Company'),
    required: true,
  },
}

const formInputs_RentalAgreement: TsInterface_FormInputs = {
  rental_agreement_number: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'rental_agreement_number',
    label: rLIB('Rental Agreement Number'),
    required: true,
  },
}

const formInputs_RentalDateTime: TsInterface_FormInputs = {
  rental_date_time: {
    data_type: 'string',
    input_type: 'timestamp_datetime', // For combined date and time selection
    key: 'rental_date_time',
    label: rLIB('Rental Date-Time'),
    required: true,
  },
}

const formInputs_RentalLocation: TsInterface_FormInputs = {
  rental_location: {
    data_type: 'string',
    input_type: 'text_basic', // For address input
    key: 'rental_location',
    label: rLIB('Rental Location'),
    required: true,
  },
}

const formInputs_ReturnDateTime: TsInterface_FormInputs = {
  return_date_time: {
    data_type: 'string',
    input_type: 'timestamp_datetime', // For combined date and time selection
    key: 'return_date_time',
    label: rLIB('Return Date-Time'),
    required: true,
  },
}

const formInputs_ReturnLocation: TsInterface_FormInputs = {
  return_location: {
    data_type: 'string',
    input_type: 'text_basic', // For address input
    key: 'return_location',
    label: rLIB('Return Location'),
    required: false,
  },
}

const formInputs_TotalCharges: TsInterface_FormInputs = {
  total_charges: {
    data_type: 'number',
    input_type: 'text_number', // For numeric input
    key: 'total_charges',
    label: rLIB('Total Charges'),
    required: true,
  },
}

const formInputs_VehicleMake: TsInterface_FormInputs = {
  vehicle_make: {
    data_type: 'string',
    input_type: 'text_basic', // Standard text input for combined field
    key: 'vehicle_make',
    label: rLIB('Vehicle Make'),
    required: true,
  },
}

const formInputs_VehicleModel: TsInterface_FormInputs = {
  vehicle_model: {
    data_type: 'string',
    input_type: 'text_basic', // Standard text input for combined field
    key: 'vehicle_model',
    label: rLIB('Vehicle Model'),
    required: true,
  },
}

const formInputs_VehicleYear: TsInterface_FormInputs = {
  vehicle_year: {
    data_type: 'string',
    input_type: 'text_number', // Standard text input for combined field
    key: 'vehicle_year',
    label: rLIB('Vehicle Year'),
    required: true,
  },
}

const formInputs_LicensePlate: TsInterface_FormInputs = {
  license_plate: {
    data_type: 'string',
    input_type: 'text_basic', // Standard text input for license plate
    key: 'license_plate',
    label: rLIB('License Plate'),
    required: false,
  },
}

const formInputs_Vin: TsInterface_FormInputs = {
  vin: {
    data_type: 'string',
    input_type: 'text_basic', // Standard text input for VIN
    key: 'vin',
    label: rLIB('VIN'),
    required: false,
  },
}

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseRentalViewPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  const params = useParams()
  const rentalKey: string = params.id as string
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_items, us_setItems ] = 		useState<TsInterface_UnspecifiedObject>({})
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  // const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 		useContext( Context_RootData_ClientKey )
  // { sort-end } - hooks

  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  // const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  const [us_rental, us_setRental] = useState<TsInterface_UnspecifiedObject>({})

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Example Page', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData) {
        us_setRental(newData) // Save fetched data to state
        ur_forceRerender() // Trigger re-render
      }
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(
          DatabaseRef_Rental_Document(res_GCK.clientKey, rentalKey), // Correct Firestore Path
          updateLiveData,
        )
      })
      .catch((error) => {
        console.error('Error fetching rental document:', error)
      })

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData() // Cleanup subscription
      }
    }
  }, [uc_RootData_ClientKey, rentalKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions

  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <>{'Rental'}</>
    if (us_rental != null && us_rental.vin != null) {
      pageHeader = (
        <>
          {'Rental'} - {us_rental.vin}
        </>
      )
    }
    return pageHeader
  }

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseVehiclesListPage.url() + '?tab=Rentals')
        }}
        disableElevation
        startIcon={<Icon icon="chevron-left" />}
        className="tw-mr-2"
      >
        {'Back to all Rentals'}
      </Button>
    )
    return buttonJSX
  }

  const editItemProperty = (formInputs: TsInterface_FormInputs) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: us_rental, // Change from `us_trailer` to `us_rental`
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {
            // Handle live changes in the form if necessary
          },
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(
                    DatabaseRef_Rental_Document(res_GCK.clientKey, rentalKey), // Update to rental-specific reference
                    formSubmittedData,
                  )
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({
                        display: true,
                        error: rej_DSMD.error,
                      })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({
                    display: true,
                    error: rej_GCK.error,
                  })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Edit Rental')}</>, // Updated header text
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const rJSX_EditableLineItem = (displayText: JSX.Element | string, propKey: string, formInputs: TsInterface_FormInputs): JSX.Element => {
    // Missing Prop
    const missingTextJSX = (
      <Box
        component="span"
        className="tw-text-error_light tw-italic"
      >
        {'Missing'}
      </Box>
    )

    // Edit Icon
    const editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
        tooltip={'Edit'}
        tooltipPlacement="right"
        onClick={() => {
          editItemProperty(formInputs)
        }}
      />
    )

    // Get Display Value
    const displayValue = getProp(us_rental, propKey, missingTextJSX)

    // Handle Special Cases for Input Types
    let displayContent = displayValue
    const inputType = formInputs[propKey]?.input_type

    if (displayValue === missingTextJSX || displayValue == null) {
      displayContent = missingTextJSX
    } else if (inputType === 'timestamp_datetime' || inputType === 'timestamp_date') {
      // Format date values
      displayContent = new Date(displayValue).toLocaleString()
    } else if (inputType === 'text_number') {
      if (propKey === 'total_charges') {
        // Format currency for total_charges
        displayContent = formatCurrency(displayValue as number)
      } else {
        // Format other numbers
        displayContent = displayValue.toLocaleString()
      }
    }

    // Full JSX
    return (
      <Box>
        <Typography variant="h6">
          <Box
            className="tw-pr-2 tw-font-bold"
            component="span"
          >
            {displayText}:
          </Box>
          <Box
            className="tw-pr-1 tw-opacity-50 tw-font-normal"
            component="span"
          >
            {displayContent}
          </Box>
          {editIconJSX}
        </Typography>
      </Box>
    )
  }

  // Notes Tab
  const rJSX_NotesTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <ForumBasic
          forumThreadCollectionDatabaseEndpoint={(clientKey) => {
            return DatabaseRef_RentalForumThreads_Collection(clientKey, rentalKey)
          }}
          forumThreadDocumentDatabaseEndpoint={(clientKey, threadKey) => {
            return DatabaseRef_RentalForumThread_Document(clientKey, rentalKey, threadKey)
          }}
          forumMessageCollectionDatabaseEndpoint={(clientKey, threadKey) => {
            return DatabaseRef_RentalForumMessages_Collection(clientKey, rentalKey, threadKey)
          }}
          forumMessageDocumentDatabaseEndpoint={(clientKey, threadKey, messageKey) => {
            return DatabaseRef_RentalForumMessage_Document(clientKey, rentalKey, threadKey, messageKey)
          }}
          forumMessageStorageEndpoint={(clientKey, threadKey, fileName) => {
            return StorageRef_Rental_MessageThread_File(clientKey, rentalKey, threadKey, fileName)
          }}
          forumSettings={{
            allow_thread_creation: true,
            scrollbar_track_color: '#272b33',
            scrollbar_thumb_color: '#888',
            input_text_color: '#fff',
            base_message_container_offset_px: 336,
          }}
        />
      </Box>
    )
    return tabJSX
  }

  // Files Tab
  const rJSX_FilesTab = (): JSX.Element => {
    let tabJSX = (
      <FileSystemBasic
        fileSystemCollectionDatabaseEndpoint={(clientKey) => {
          return DatabaseRef_RentalFiles_Collection(clientKey, rentalKey)
        }}
        fileSystemDocumentDatabaseEndpoint={(clientKey, fileKey) => {
          return DatabaseRef_RentalFile_Document(clientKey, rentalKey, fileKey)
        }}
        fileSystemStorageEndpoint={(clientKey, fileName) => {
          return StorageRef_RentalFile(clientKey, rentalKey, fileName)
        }}
        fileSystemHardCodedFolders={{}}
        fileSystemSettings={{
          allow_file_archiving: true,
          allow_file_movement: true,
          allow_file_unarchiving: true,
          allow_file_uploads: true,
          allow_folder_creation: true,
          allow_folder_deletion: true,
          allow_folder_movement: true,
          allow_folder_rename: true,
          archive_filter_visible: true,
        }}
      />
    )
    return tabJSX
  }

  const rJSX_NonEditableLineItem = (displayText: JSX.Element | string, propKey: string): JSX.Element => {
    // Missing Prop
    const missingTextJSX = (
      <Box
        component="span"
        className="tw-text-error_light tw-italic"
      >
        {'Missing'}
      </Box>
    )

    // Get Display Value
    const displayValue = getProp(us_rental, propKey, missingTextJSX)

    // Full JSX for non-editable line item
    return (
      <Box>
        <Typography variant="h6">
          <Box
            className="tw-pr-2 tw-font-bold"
            component="span"
          >
            {displayText}:
          </Box>
          <Box
            className="tw-pr-1 tw-opacity-50 tw-font-normal"
            component="span"
          >
            {displayValue}
          </Box>
        </Typography>
      </Box>
    )
  }

  const rJSX_DetailsTab = (): JSX.Element => {
    let tabJSX = (
      <Card className="tw-p-2">
        {rJSX_NonEditableLineItem(rLIB('Driver Name'), 'associated_user_name')}
        {rJSX_EditableLineItem(rLIB('Rental Company'), 'rental_company_name', formInputs_RentalCompany)}
        {rJSX_EditableLineItem(rLIB('Rental Agreement Number'), 'rental_agreement_number', formInputs_RentalAgreement)}
        {rJSX_EditableLineItem(rLIB('Rental Date-Time'), 'rental_date_time', formInputs_RentalDateTime)}
        {rJSX_EditableLineItem(rLIB('Rental Location'), 'rental_location', formInputs_RentalLocation)}
        {rJSX_EditableLineItem(rLIB('Return Date-Time'), 'return_date_time', formInputs_ReturnDateTime)}
        {rJSX_EditableLineItem(rLIB('Return Location'), 'return_location', formInputs_ReturnLocation)}
        {rJSX_EditableLineItem(rLIB('Total Charges'), 'total_charges', formInputs_TotalCharges)}
        {rJSX_EditableLineItem(rLIB('Vehicle Make'), 'vehicle_make', formInputs_VehicleMake)}
        {rJSX_EditableLineItem(rLIB('Vehicle Model'), 'vehicle_model', formInputs_VehicleModel)}
        {rJSX_EditableLineItem(rLIB('Vehicle Year'), 'vehicle_year', formInputs_VehicleYear)}
        {rJSX_EditableLineItem(rLIB('License Plate'), 'license_plate', formInputs_LicensePlate)}
        {rJSX_EditableLineItem(rLIB('VIN'), 'vin', formInputs_Vin)}
      </Card>
    )

    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <Box>{rJSX_BackButton()}</Box>
            <Box>
              <TabsUrl
                tabsSettings={{
                  baseUrl: ApplicationPages.AdminDatabaseRentalViewPage.url(rentalKey),
                  tabQueryParam: 'tab',
                  overridePageTitle: true,
                  basePageTitle: 'Rentals',
                }}
                tabs={[
                  {
                    tabUrlKey: 'Details',
                    tabHeader: 'Details',
                    tabContent: rJSX_DetailsTab(),
                  },
                  //   {
                  //     tabUrlKey: 'Assignment',
                  //     tabHeader: s_ASSIGNMENT,
                  //     tabContent: rJSX_AssignmentTab(),
                  //   },
                  {
                    tabUrlKey: 'Notes',
                    tabHeader: 'Notes',
                    tabContent: rJSX_NotesTab(),
                  },
                  {
                    tabUrlKey: 'Files',
                    tabHeader: 'Files',
                    tabContent: rJSX_FilesTab(),
                  },
                ]}
              />
            </Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
