///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material/'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import _ from 'lodash'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_UserSettings_Document } from 'rfbp_aux/services/database_endpoints/directory/users'
import { DatabaseRef_SpendingLimits_Collection, DatabaseRef_SpendingProfiles_Document } from 'rfbp_aux/services/database_endpoints/finances/spending'
import { Json } from 'rfbp_core/components/code_display'
import { Icon } from 'rfbp_core/components/icons'
import { TableCellBasic, TableDatabase, TsInterface_TableColumns, TsInterface_TableDatabaseSettings } from 'rfbp_core/components/table'
import { TableCellCommaSeparated } from 'rfbp_core/components/table/cells/table_cell_comma_separated'
import { TableCellCurrency } from 'rfbp_core/components/table/cells/table_cell_currency'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import {
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { formatDateToYYYYMMDD, getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import Stripe from 'stripe'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminFinanceSpendingCardholdersViewPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_BACK_TO_ALL_CARDHOLDERS: JSX.Element = <Trans>Back to all Cardholders</Trans>
const s_DETAILS: JSX.Element = <Trans>Details</Trans>
const s_AUTHORIZATIONS: JSX.Element = <Trans>Authorizations</Trans>
const s_TRANSACTIONS: JSX.Element = <Trans>Transactions</Trans>
const s_CARD_ID: JSX.Element = <Trans>Card ID</Trans>
const s_BRAND: JSX.Element = <Trans>Brand</Trans>
const s_NAME: JSX.Element = <Trans>Name</Trans>
const s_STRIPE_ID: JSX.Element = <Trans>Stripe ID</Trans>
const s_CREATED: JSX.Element = <Trans>Created</Trans>
const s_MISSING: JSX.Element = <Trans>Missing</Trans>
const s_CARDS: JSX.Element = <Trans>Cards</Trans>
const s_LIMITS: JSX.Element = <Trans>Limits</Trans>
const s_STATUS: JSX.Element = <Trans>Status</Trans>
const s_CARD_NUMBER: JSX.Element = <Trans>Card Number</Trans>
const s_EXP_MONTH: JSX.Element = <Trans>Exp. Month</Trans>
const s_EXP_YEAR: JSX.Element = <Trans>Exp. Year</Trans>
const s_TYPE: JSX.Element = <Trans>Type</Trans>
const s_ZIP_CODE: JSX.Element = <Trans>Zip Code</Trans>
const s_VERIFICATIONS: JSX.Element = <Trans>Verifications</Trans>
const s_METADATA: JSX.Element = <Trans>Metadata</Trans>
const s_SPENDING_CONTROLS: JSX.Element = <Trans>Spending Controls</Trans>
const s_ETW_SPENDING_CONTROLS: JSX.Element = <Trans>ETW Spending Controls</Trans>
const s_SPENDING_PROFILE_NAME: JSX.Element = <Trans>Spending Profile</Trans>
const se_Cardholder = 'Cardholder'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const userKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_userSettings, us_setUserSettings] = useState<TsInterface_UnspecifiedObject>({})
  const [us_cardholder, us_setCardholder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_cardholderProfile, us_setCardholderProfile] = useState<TsInterface_UnspecifiedObject>({})
  const [us_authorizations, us_setAuthorizations] = useState<Stripe.Issuing.Authorization[]>([])
  const [us_transactions, us_setTransactions] = useState<Stripe.Issuing.Transaction[]>([])
  const [us_cards, us_setCards] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useEffect

  useEffect(() => {
    document.title = se_Cardholder
  }, [])

  useEffect(() => {
    if (us_userSettings?.stripe_cardholder_id) {
      cloudFunctionManageRequest('manageFinances', {
        function: 'fetchAuthorizations',
        cardholder_id: us_userSettings.stripe_cardholder_id,
        livemode: us_userSettings.stripe_livemode,
      })
        .then((res_CFMMR: any) => {
          us_setAuthorizations(res_CFMMR.authorizations)
        })
        .catch((rej_CFMMR) => {
          console.error(rej_CFMMR)
        })
    }
  }, [us_userSettings])

  useEffect(() => {
    if (us_userSettings?.stripe_cardholder_id) {
      cloudFunctionManageRequest('manageFinances', {
        function: 'fetchTransactions',
        cardholder_id: us_userSettings.stripe_cardholder_id,
        livemode: us_userSettings.stripe_livemode,
      })
        .then((res_CFMMR: any) => {
          console.log('res_CFMMR')
          console.log(res_CFMMR)
          us_setTransactions(res_CFMMR.transactions)
        })
        .catch((rej_CFMMR) => {
          console.error(rej_CFMMR)
        })
    }
  }, [us_userSettings])

  useEffect(() => {
    if (us_userSettings?.stripe_cardholder_id) {
      cloudFunctionManageRequest('manageFinances', {
        function: 'fetchCardholder',
        cardholder_id: us_userSettings.stripe_cardholder_id,
        livemode: us_userSettings.stripe_livemode,
      })
        .then((res_CFMMR: any) => {
          console.log('res_CFMMR.cardholder')
          console.log(res_CFMMR.cardholder)
          us_setCardholder(res_CFMMR.cardholder)
        })
        .catch((rej_CFMMR) => {
          console.error(rej_CFMMR)
        })
    }
  }, [us_userSettings])

  useEffect(() => {
    if (us_userSettings?.stripe_cardholder_id) {
      cloudFunctionManageRequest('manageFinances', {
        function: 'fetchCardholderCards',
        cardholder_id: us_userSettings.stripe_cardholder_id,
        livemode: us_userSettings.stripe_livemode,
      })
        .then((res_CFMMR: any) => {
          us_setCards(res_CFMMR.cards)
        })
        .catch((rej_CFMMR) => {
          console.error(rej_CFMMR)
        })
    }
  }, [us_userSettings])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUserSettings(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_UserSettings_Document(res_GCK.clientKey, userKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, userKey])

  useEffect(() => {
    if (us_userSettings.associated_spending_profile_key) {
      let unsubscribeLiveData: TsType_VoidFunction
      const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
        us_setCardholderProfile(newData)
        ur_forceRerender()
      }
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_SpendingProfiles_Document(res_GCK.clientKey, us_userSettings.associated_spending_profile_key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
      return () => {
        if (typeof unsubscribeLiveData === 'function') {
          unsubscribeLiveData()
        }
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, userKey, us_userSettings.associated_spending_profile_key])

  const tableDatabaseEndpoint_Limits = () => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'associated_user_key', comparator: '==', value: userKey },
      {
        prop: 'status',
        comparator: '==',
        value: 'active',
      },
    ]
    let orderByArray: TsInterface_OrderByArray = []
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    let limit = 100
    return generateDatabaseQuery(
      DatabaseRef_SpendingLimits_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // Functions

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={() => {
          un_routerNavigation(ApplicationPages.AdminFinanceSpendingIndexPage.url())
        }}
        disableElevation
        startIcon={<Icon icon="chevron-left" />}
        className="tw-mr-2"
      >
        {s_BACK_TO_ALL_CARDHOLDERS}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_LineItem = (displayText: JSX.Element, propKey: string, obj: TsInterface_UnspecifiedObject, dataType: string): JSX.Element => {
    let lineJSX = <></>
    let missingTextJSX = (
      <Box
        component={'span'}
        className="tw-text-error_light tw-italic"
      >
        {s_MISSING}
      </Box>
    )

    const propValue = getProp(obj, propKey, missingTextJSX)

    let contentToRender

    if (dataType === 'date') {
      try {
        contentToRender = formatDateToYYYYMMDD(new Date(propValue * 1000))
      } catch (e) {
        contentToRender = propValue
      }
    } else if (dataType === 'card') {
      contentToRender = `**** **** **** ${propValue}`
    } else if (typeof propValue === 'object' && propValue !== null) {
      contentToRender = Object.entries(propValue).map(([key, value]) => {
        let valueObj
        if (typeof value === 'object') {
          if (_.isEmpty(value)) return null
          valueObj = <Json data={value as object} />
        } else {
          valueObj = value as string
        }
        return (
          <Box key={key}>
            <Box
              className="tw-pr-2 tw-font-bold tw-pl-6"
              component={'span'}
            >
              {key}:
            </Box>
            <Box
              className="tw-pr-1 tw-font-normal"
              component={'span'}
            >
              {valueObj}
            </Box>
          </Box>
        )
      })
    } else {
      contentToRender = propValue
    }

    if (propValue === missingTextJSX) {
      contentToRender = propValue
    }

    // Full JSX
    lineJSX = (
      <Box>
        <Typography variant="h6">
          <Box
            className="tw-pr-2 tw-font-bold"
            component={'span'}
          >
            {displayText}:
          </Box>
          <Box
            className="tw-pr-1 tw-opacity-50 tw-font-normal"
            component={'span'}
          >
            {contentToRender}
          </Box>
        </Typography>
      </Box>
    )
    return lineJSX
  }

  const rJSX_DetailsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card className="tw-p-4">
        {rJSX_LineItem(s_STRIPE_ID, 'id', us_cardholder, 'string')}
        {rJSX_LineItem(s_NAME, 'name', us_cardholder, 'string')}
        {rJSX_LineItem(s_ZIP_CODE, 'billing.address.postal_code', us_cardholder, 'string')}
        {rJSX_LineItem(s_CREATED, 'created', us_cardholder, 'date')}
        {rJSX_LineItem(s_STATUS, 'status', us_cardholder, 'string')}
        {rJSX_LineItem(s_SPENDING_PROFILE_NAME, 'name', us_cardholderProfile, 'string')}
        {rJSX_LineItem(s_METADATA, 'metadata', us_cardholder, 'object')}
        {rJSX_LineItem(s_SPENDING_CONTROLS, 'spending_controls', us_cardholder, 'object')}
        {rJSX_LineItem(s_ETW_SPENDING_CONTROLS, 'etw_spending_controls', us_cardholderProfile, 'object')}
      </Card>
    )
    return tabJSX
  }

  interface Card {
    id: string
    last4: string
    brand: string
    status: 'active' | 'inactive'
    type: string
    exp_month: number
    exp_year: number
    created: number
    spending_controls?: {
      spending_limits_currency?: string
      spending_limits?: Record<
        string,
        {
          amount: number
          interval: string
          categories?: string[]
        }
      >
    }
  }

  const rJSX_CardsTab = (): JSX.Element => {
    const [createDialogOpen, setCreateDialogOpen] = React.useState(false)
    const [confirmActionDialogOpen, setConfirmActionDialogOpen] = React.useState(false)
    const [selectedCard, setSelectedCard] = React.useState<Card | null>(null)

    const handleCreateCard = () => {
      cloudFunctionManageRequest('manageFinances', {
        function: 'createVirtualCard',
        cardholder_id: us_cardholder.id,
        livemode: us_userSettings.stripe_livemode,
      })
        .then((res) => {
          setCreateDialogOpen(false)
        })
        .catch((err) => {
          console.error(err)
        })
    }

    const handleStatusChange = (card: Card) => {
      setSelectedCard(card)
      setConfirmActionDialogOpen(true)
    }

    const confirmStatusChange = () => {
      if (!selectedCard) return

      cloudFunctionManageRequest('manageFinances', {
        function: 'updateCardStatus',
        card_id: selectedCard.id,
        status: selectedCard.status === 'active' ? 'inactive' : 'active',
        livemode: us_userSettings.stripe_livemode,
      })
        .then((res) => {
          setConfirmActionDialogOpen(false)
          setSelectedCard(null)
        })
        .catch((err) => {
          console.error(err)
        })
    }

    const rJSX_CardDetails = (card: Card): JSX.Element => {
      if (!card) return <></>

      return (
        <Card className="tw-bg-gray-800 tw-p-6 tw-mb-4">
          <Grid container>
            <Grid
              item
              xs={7}
            >
              <Box className="tw-mb-6">
                <Box className="tw-flex tw-items-center tw-gap-4 tw-mb-6">
                  <Icon
                    icon="credit-card"
                    className="tw-text-2xl tw-opacity-80"
                  />
                  <Typography
                    variant="h5"
                    className="tw-text-white"
                  >
                    •••• •••• •••• {card.last4}
                  </Typography>
                  <Chip
                    label={card.status}
                    color={card.status === 'active' ? 'success' : 'error'}
                    size="small"
                    className="tw-ml-4"
                  />
                  <Switch
                    checked={card.status === 'active'}
                    onChange={() => handleStatusChange(card)}
                    color="primary"
                    size="small"
                  />
                </Box>

                <Grid
                  container
                  spacing={4}
                >
                  <Grid
                    item
                    xs={6}
                  >
                    <Typography
                      color="textSecondary"
                      className="tw-text-xs tw-uppercase tw-mb-1"
                    >
                      CARD ID
                    </Typography>
                    <Typography className="tw-text-gray-300">{card.id}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <Typography
                      color="textSecondary"
                      className="tw-text-xs tw-uppercase tw-mb-1"
                    >
                      TYPE
                    </Typography>
                    <Typography className="tw-text-gray-300">{card.type}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <Typography
                      color="textSecondary"
                      className="tw-text-xs tw-uppercase tw-mb-1"
                    >
                      EXPIRATION
                    </Typography>
                    <Typography className="tw-text-gray-300">
                      {card.exp_month}/{card.exp_year}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <Typography
                      color="textSecondary"
                      className="tw-text-xs tw-uppercase tw-mb-1"
                    >
                      CREATED
                    </Typography>
                    <Typography className="tw-text-gray-300">{new Date(card.created * 1000).toLocaleDateString()}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid
              item
              xs={5}
            >
              <Box className="tw-bg-gray-900 tw-rounded-lg tw-p-4">
                <Typography
                  variant="subtitle1"
                  className="tw-flex tw-items-center tw-text-white tw-mb-3"
                >
                  <Icon
                    icon="sliders"
                    className="tw-mr-2"
                  />
                  Spending Controls
                </Typography>

                <Typography
                  color="textSecondary"
                  className="tw-text-xs tw-uppercase tw-mb-1"
                >
                  CURRENCY
                </Typography>
                <Typography className="tw-text-gray-300 tw-mb-4">{card.spending_controls?.spending_limits_currency?.toUpperCase() || 'USD'}</Typography>

                {card.spending_controls?.spending_limits &&
                  Object.entries(card.spending_controls.spending_limits).map(([key, value]) => (
                    <Box
                      key={key}
                      className="tw-mb-3"
                    >
                      <Typography
                        color="textSecondary"
                        className="tw-text-xs tw-uppercase tw-mb-1"
                      >
                        LIMIT
                      </Typography>
                      <Typography className="tw-text-gray-300">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(value.amount / 100)}{' '}
                        {value.interval}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </Grid>
          </Grid>
        </Card>
      )
    }

    return (
      <Box className="tw-max-w-7xl tw-p-6">
        <Box className="tw-mb-8">
          <Button
            variant="contained"
            color="primary"
            startIcon={
              <Icon
                icon="plus"
                className="tw-text-xl"
              />
            }
            onClick={() => setCreateDialogOpen(true)}
            size="large"
            className="tw-text-base tw-px-6 tw-py-3"
          >
            Create Virtual Card
          </Button>
        </Box>

        <Box>
          {Object.entries(us_cards || {}).map(([key, card]) => (
            <Box key={key}>{rJSX_CardDetails(card as Card)}</Box>
          ))}
        </Box>

        <Dialog
          open={createDialogOpen}
          onClose={() => setCreateDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Create New Virtual Card</DialogTitle>
          <DialogContent>
            <Alert
              severity="info"
              className="tw-mt-2"
            >
              This will create a new pre-activated virtual card for this cardholder. The card details will be available immediately after creation.
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCreateDialogOpen(false)}>Cancel</Button>
            <Button
              onClick={handleCreateCard}
              variant="contained"
              color="primary"
            >
              Create Card
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={confirmActionDialogOpen}
          onClose={() => setConfirmActionDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Confirm Card Status Change</DialogTitle>
          <DialogContent>
            <Alert
              severity="warning"
              className="tw-mt-2"
            >
              Are you sure you want to {selectedCard?.status === 'active' ? 'deactivate' : 'activate'} this card?
              {selectedCard?.status === 'active' && <Typography className="tw-mt-2">Deactivating this card will prevent any future transactions.</Typography>}
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmActionDialogOpen(false)}>Cancel</Button>
            <Button
              onClick={confirmStatusChange}
              variant="contained"
              color={selectedCard?.status === 'active' ? 'error' : 'primary'}
            >
              {selectedCard?.status === 'active' ? 'Deactivate' : 'Activate'} Card
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    )
  }

  const rJSX_VerificationsTab = (): JSX.Element => {
    let tabJSX = (
      <>
        <Card>
          <CardHeader title={'Vehicles'} />
          <CardContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={getProp(us_userSettings, 'stripe_authorizations.vehicle_assigned', false)}
                  onChange={(event, value) => {
                    DatabaseSetMergeDocument(DatabaseRef_UserSettings_Document(uc_RootData_ClientKey as string, userKey), {
                      stripe_authorizations: {
                        vehicle_assigned: value,
                      },
                    })
                  }}
                />
              }
              label={'Vehicle Assigned'}
            />
          </CardContent>
        </Card>
      </>
    )
    return tabJSX
  }

  const rJSX_LimitsTab = (): JSX.Element => {
    const tableColumns_Limits: TsInterface_TableColumns = {
      name: TableCellCurrency('amount', 'Amount', 'amount'),
      interval: TableCellBasic('interval', 'Interval', 'interval'),
      categories: TableCellCommaSeparated('categories', 'Categories', 'categories'),
    }

    const tableSettings_Limits: TsInterface_TableDatabaseSettings = {
      rows_per_page: 100,
      sort_direction: 'asc',
      sort_property: 'key',
    }

    const pageJSX = (
      <Card className="">
        <TableDatabase
          tableColumns={tableColumns_Limits}
          tableDatabaseEndpoint={tableDatabaseEndpoint_Limits}
          tableSettings={tableSettings_Limits}
          tableAdditionalData={{}}
        />
      </Card>
    )
    return pageJSX
  }

  const rJSX_AuthorizationsTab = (): JSX.Element => {
    const columns: GridColDef[] = [
      {
        field: 'created',
        headerName: 'DATE',
        width: 120,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography>
              {new Date(params.value * 1000).toLocaleString('en-US', { month: 'short' })} {new Date(params.value * 1000).getDate()}
            </Typography>
          </Box>
        ),
      },
      {
        field: 'merchant_data.name',
        headerName: 'DESCRIPTION',
        width: 200,
        renderCell: (params) => {
          const name = params.row.merchant_data?.name || ''
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {name}
            </Box>
          )
        },
      },
      {
        field: 'approved',
        headerName: 'STATUS',
        width: 150,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip
            title={params.row.metadata?.error_details ? `${params.row.metadata?.error_details || ''}`.trim() : ''}
            arrow
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {params.value ? (
                <>
                  <Icon
                    icon="circle-check"
                    sx={{ color: themeVariables.secondary_main, mr: 1 }}
                  />
                  <Chip
                    label="Completed"
                    color="success"
                    size="small"
                  />
                </>
              ) : (
                <>
                  <Icon
                    icon="circle-exclamation"
                    sx={{ color: themeVariables.error_main, mr: 1 }}
                  />
                  <Chip
                    label="Failed"
                    color="error"
                    size="small"
                  />
                </>
              )}
            </Box>
          </Tooltip>
        ),
      },
      {
        field: 'amount',
        headerName: 'AMOUNT',
        flex: 1,
        width: 150,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography>{`$${(params.value / 100).toFixed(2)}`}</Typography>
          </Box>
        ),
      },
    ]

    let tabJSX = (
      <Box
        sx={{
          flex: 1,
          height: '100%',
        }}
      >
        <DataGrid
          rows={us_authorizations}
          columns={columns}
          // onRowClick={(params) => setSelectedTransaction(params.row)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          disableRowSelectionOnClick
          pageSizeOptions={[25]}
          localeText={{
            noRowsLabel: '',
          }}
          sx={{
            'width': '100%',
            '& .MuiDataGrid-main': {
              width: '100%',
            },
          }}
        />
      </Box>
    )
    return tabJSX
  }

  const rJSX_TransactionsTab = (): JSX.Element => {
    const columns: GridColDef[] = [
      {
        field: 'created',
        headerName: 'DATE',
        width: 120,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography>{new Date(params.value * 1000).toLocaleDateString()}</Typography>
          </Box>
        ),
      },
      {
        field: 'merchant_data.name',
        headerName: 'DESCRIPTION',
        width: 200,
        renderCell: (params) => {
          const name = params.row.merchant_data?.name || ''
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {name}
            </Box>
          )
        },
      },
      {
        field: 'type',
        headerName: 'STATUS',
        width: 150,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {params.value === 'capture' ? (
              <>
                <Icon
                  icon="circle-check"
                  sx={{ color: themeVariables.secondary_main, mr: 1 }}
                />
                <Chip
                  label="Completed"
                  color="success"
                  size="small"
                />
              </>
            ) : (
              <>
                <Icon
                  icon="circle-exclamation"
                  sx={{ color: themeVariables.error_main, mr: 1 }}
                />
                <Chip
                  label="Failed"
                  color="error"
                  size="small"
                />
              </>
            )}
          </Box>
        ),
      },
      {
        field: 'amount',
        headerName: 'AMOUNT',
        width: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography>{`$${(params.value / -100).toFixed(2)}`}</Typography>
          </Box>
        ),
      },
    ]

    let tabJSX = (
      <Box sx={{ flexGrow: 1, height: '100%' }}>
        <DataGrid
          rows={us_transactions}
          columns={columns}
          // onRowClick={(params) => setSelectedTransaction(params.row)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[25]}
          localeText={{
            noRowsLabel: '',
          }}
        />
      </Box>
    )
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={us_userSettings?.name || ''}
        pageKey={pageKey}
        content={
          <Box>
            <Box>{rJSX_BackButton()}</Box>
            <Box className="">
              <TabsUrl
                tabsSettings={{
                  baseUrl: ApplicationPages.AdminFinanceSpendingCardholdersViewPage.url(userKey),
                  tabQueryParam: 'tab',
                  overridePageTitle: true,
                  basePageTitle: se_Cardholder,
                }}
                tabs={[
                  {
                    tabUrlKey: 'Details',
                    tabHeader: s_DETAILS,
                    tabContent: rJSX_DetailsTab(),
                  },
                  {
                    tabUrlKey: 'Cards',
                    tabHeader: s_CARDS,
                    tabContent: rJSX_CardsTab(),
                  },
                  {
                    tabUrlKey: 'Verifications',
                    tabHeader: s_VERIFICATIONS,
                    tabContent: rJSX_VerificationsTab(),
                  },
                  {
                    tabUrlKey: 'Limits',
                    tabHeader: s_LIMITS,
                    tabContent: rJSX_LimitsTab(),
                  },
                  {
                    tabUrlKey: 'Authorizations',
                    tabHeader: s_AUTHORIZATIONS,
                    tabContent: rJSX_AuthorizationsTab(),
                  },
                  {
                    tabUrlKey: 'Transactions',
                    tabHeader: s_TRANSACTIONS,
                    tabContent: rJSX_TransactionsTab(),
                  },
                ]}
              />
            </Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  return <>{rJSX_Page()}</>
}
