///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Chip, TextField, Tooltip, Typography } from '@mui/material/'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import axios from 'axios'
import { GeoPoint } from 'firebase/firestore'
import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_UserSettings_Collection, DatabaseRef_Users_Collection } from 'rfbp_aux/services/database_endpoints/directory/users'
import {
  DatabaseRef_GeocodedLocations_Collection,
  DatabaseRef_GeocodedLocations_Query,
  DatabaseRef_SpendingProfiles_Collection,
} from 'rfbp_aux/services/database_endpoints/finances/spending'
import { DatabaseRef_VehiclesByDate_Query } from 'rfbp_aux/services/database_endpoints/operations/gm_dashboards'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import { DatabaseAddDocument, DatabaseGetCollection, DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import Stripe from 'stripe'
import { Tab as ApprovalsTab } from './tabs/tab_spending_approvals'
import { Tab as CardholdersTab } from './tabs/tab_spending_cardholders'
import { Tab as ProfilesTab } from './tabs/tab_spending_profiles'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminFinanceSpendingIndexPage']['key']

// Forms
const formInputs_CardholderCreate: TsInterface_FormInputs = {
  user: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'user',
    label: rLIB('User'),
    required: true,
    options: [], // Dynamically populated when form used
  },
  first_name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'first_name',
    label: rLIB('First Name'),
    required: true,
    disabled: true,
  },
  last_name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'last_name',
    label: rLIB('Last Name'),
    required: true,
    disabled: true,
  },
  active: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'active',
    label: rLIB('Activate Cardholder?'),
    required: false,
    disabled: true,
  },
  create_card: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'active',
    label: rLIB('Create Card?'),
    required: false,
    disabled: true,
  },
  profile: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'profile',
    label: rLIB('Spending Profile'),
    required: false,
    disabled: true,
    options: [], // Dynamically populated when form used
  },
}

interface CacheEntry {
  address: string
  coordinates: GeoPoint
  timestamp_created: Date
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_selectedDate, us_setSelectedDate] = useState<Date>(new Date())
  const [us_authorizations, us_setAuthorizations] = useState<Stripe.Issuing.Authorization[]>([])
  const [us_transactions, us_setTransactions] = useState<Stripe.Issuing.Transaction[]>([])
  const [us_users, us_setUsers] = useState<TsInterface_UnspecifiedObject>({})
  const [us_userSettings, us_setUserSettings] = useState<TsInterface_UnspecifiedObject>({})
  const [us_nonCardholders, us_setNonCardholders] = useState<any[]>([])
  const [us_spendingProfiles, us_setSpendingProfiles] = useState<any[]>([])
  const [us_html, us_setHtml] = useState<string>('')
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Spending', false) as string
  }, [])

  useEffect(() => {
    cloudFunctionManageRequest('manageFinances', {
      function: 'fetchAuthorizations',
      livemode: true,
    })
      .then((res_CFMMR: any) => {
        console.log('res_CFMMR')
        res_CFMMR.authorizations.map((authorization: any) => {
          if (authorization.fuel) {
            console.log(authorization.fuel)
          }
        })
        us_setAuthorizations(res_CFMMR.authorizations)
      })
      .catch((rej_CFMMR) => {
        console.error(rej_CFMMR)
      })
  }, [])

  useEffect(() => {
    cloudFunctionManageRequest('manageFinances', {
      function: 'fetchTransactions',
      livemode: true,
    })
      .then((res_CFMMR: any) => {
        const transactions = ((res_CFMMR as any)?.transactions as Stripe.Issuing.Transaction[]) || []
        us_setTransactions(transactions)
      })
      .catch((rej_CFMMR) => {
        console.error(rej_CFMMR)
      })
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (userData: TsInterface_UnspecifiedObject) => {
      us_setUsers(userData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Users_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (userData: TsInterface_UnspecifiedObject) => {
      us_setUserSettings(userData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_UserSettings_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (spendingData: TsInterface_UnspecifiedObject) => {
      let array: any[] = []
      Object.keys(spendingData).forEach((key) => {
        array.push({
          key,
          value: spendingData[key].name,
        })
      })
      us_setSpendingProfiles(array)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SpendingProfiles_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let nonCardholders: any[] = []
    Object.keys(us_users).forEach((userKey) => {
      if (
        (!us_userSettings[userKey] || !us_userSettings[userKey].stripe_cardholder_id) &&
        us_users[userKey].status === 'active' &&
        us_users[userKey].associated_organization_type === 'internal'
      ) {
        nonCardholders.push({ key: userKey, value: us_users[userKey].name })
      }
    })
    nonCardholders.sort(dynamicSort('value', null))
    us_setNonCardholders(nonCardholders)
  }, [us_users, us_userSettings])

  // Functions

  const createCardholder = (): void => {
    let inputs: TsInterface_FormInputs = formInputs_CardholderCreate
    inputs['user']['options'] = us_nonCardholders
    inputs['profile']['options'] = us_spendingProfiles

    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_CardholderCreate,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {
            if (formData.user) {
              formInputs.first_name.disabled = false
              formInputs.last_name.disabled = false
              formInputs.active.disabled = false
              formInputs.profile.disabled = false
              formInputs.create_card.disabled = false
              let firstName = ''
              let lastName = ''
              if (us_users[formData.user as string].name) {
                let nameParts = us_users[formData.user as string].name.split(' ')
                firstName = nameParts[0]
                lastName = nameParts[1]
                if (_.isEmpty(formData.first_name)) {
                  formData.first_name = firstName
                  formData.active = true
                }
                if (_.isEmpty(formData.last_name)) {
                  formData.last_name = lastName
                }
              }
            }
          },
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                // Get IP Address
                fetch('https://api.ipify.org?format=json')
                  .then((response) => response.json())
                  .then((data) => {
                    cloudFunctionManageRequest('manageFinances', {
                      function: 'createCardholder',
                      client_key: res_GCK.clientKey,
                      user_key: formSubmittedData.user,
                      livemode: true,
                      first_name: formSubmittedData.first_name,
                      last_name: formSubmittedData.last_name,
                      active: formSubmittedData.active,
                      create_card: formSubmittedData.create_card,
                      profile_key: formSubmittedData.profile,
                      ip_address: getProp(data, 'ip', null),
                    })
                      .then((res_CFMMR) => {
                        resolve(res_CFMMR)
                      })
                      .catch((rej_CFMMR) => {
                        try {
                          let error = JSON.parse(rej_CFMMR.message)
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error })
                        } catch (error) {
                          uc_setUserInterface_ErrorDialogDisplay({
                            display: true,
                            error: {
                              code: 'error',
                              message: 'An error occurred while creating the cardholder. Please try again.',
                              details: 'error',
                            },
                          })
                        }
                      })
                  })
                  .catch((rej_GCK) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  })
              })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'info',
          formDialogHeaderText: <>{rLIB('New Cardholder')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="credit-card"
            />
          ),
        },
      },
    })
  }

  // JSX Generation

  const rJSX_NewCardholderButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          createCardholder()
        }}
        disableElevation
        className="tw-mr-2"
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {rLIB('New Cardholder')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_AuthorizationsTab = (): JSX.Element => {
    const columns: GridColDef[] = [
      {
        field: 'created',
        headerName: 'DATE',
        width: 120,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography>
              {new Date(params.value * 1000).toLocaleString('en-US', { month: 'short' })} {new Date(params.value * 1000).getDate()}
            </Typography>
          </Box>
        ),
      },
      {
        field: 'merchant_data.name',
        headerName: 'DESCRIPTION',
        width: 200,
        renderCell: (params) => {
          const name = params.row.merchant_data?.name || ''
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {name}
            </Box>
          )
        },
      },
      {
        field: 'card.cardholder.name',
        headerName: 'NAME ON CARD',
        width: 200,
        renderCell: (params) => {
          const name = params.row.card.cardholder.name || ''
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {name}
            </Box>
          )
        },
      },
      {
        field: 'card.last4',
        headerName: 'CARD',
        width: 100,
        renderCell: (params) => {
          const name = '****' + params.row.card.last4 || ''
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {name}
            </Box>
          )
        },
      },
      {
        field: 'approved',
        headerName: 'STATUS',
        width: 150,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip
            title={params.row.metadata?.error_details ? `${params.row.metadata?.error_details || ''}`.trim() : ''}
            arrow
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {params.value ? (
                <>
                  <Icon
                    icon="circle-check"
                    sx={{ color: themeVariables.secondary_main, mr: 1 }}
                  />
                  <Chip
                    label="Completed"
                    color="success"
                    size="small"
                  />
                </>
              ) : (
                <>
                  <Icon
                    icon="circle-exclamation"
                    sx={{ color: themeVariables.error_main, mr: 1 }}
                  />
                  <Chip
                    label="Failed"
                    color="error"
                    size="small"
                  />
                </>
              )}
            </Box>
          </Tooltip>
        ),
      },
      {
        field: 'fuel.quantity_decimal',
        headerName: 'GALLONS',
        width: 150,
        renderCell: (params) => {
          const name = params.row.fuel?.quantity_decimal || ''
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {name}
            </Box>
          )
        },
      },
      {
        field: 'amount',
        headerName: 'AMOUNT',
        flex: 1,
        width: 150,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography>{`$${(params.value / 100).toFixed(2)}`}</Typography>
          </Box>
        ),
      },
    ]

    let tabJSX = (
      <Box
        sx={{
          flex: 1,
          height: '100%',
        }}
      >
        <DataGrid
          rows={us_authorizations}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          disableRowSelectionOnClick
          pageSizeOptions={[25]}
          localeText={{
            noRowsLabel: '',
          }}
          sx={{
            'width': '100%',
            '& .MuiDataGrid-main': {
              width: '100%',
            },
          }}
        />
      </Box>
    )
    return tabJSX
  }

  const rJSX_TransactionsTab = (): JSX.Element => {
    const columns: GridColDef[] = [
      {
        field: 'created',
        headerName: 'DATE',
        width: 120,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography>{new Date(params.value * 1000).toLocaleDateString()}</Typography>
          </Box>
        ),
      },
      {
        field: 'merchant_data.name',
        headerName: 'DESCRIPTION',
        width: 200,
        renderCell: (params) => {
          const name = params.row.merchant_data?.name || ''
          console.log('params')
          console.log(params)
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {name}
            </Box>
          )
        },
      },
      {
        field: 'type',
        headerName: 'STATUS',
        width: 150,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {params.value === 'capture' ? (
              <>
                <Icon
                  icon="circle-check"
                  sx={{ color: themeVariables.secondary_main, mr: 1 }}
                />
                <Chip
                  label="Completed"
                  color="success"
                  size="small"
                />
              </>
            ) : (
              <>
                <Icon
                  icon="circle-exclamation"
                  sx={{ color: themeVariables.error_main, mr: 1 }}
                />
                <Chip
                  label="Failed"
                  color="error"
                  size="small"
                />
              </>
            )}
          </Box>
        ),
      },
      {
        field: 'amount',
        headerName: 'AMOUNT',
        width: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography>{`$${(params.value / -100).toFixed(2)}`}</Typography>
          </Box>
        ),
      },
    ]

    let tabJSX = (
      <Box sx={{ flexGrow: 1, height: '100%' }}>
        <DataGrid
          rows={us_transactions}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[25]}
          localeText={{
            noRowsLabel: '',
          }}
        />
      </Box>
    )
    return tabJSX
  }

  const rJSX_ReportsTab = (): JSX.Element => {
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const GAS_PRICE = 4

    const handleDownloadReport = async () => {
      if (!startDate || !endDate) {
        console.error('Start date and end date are required')
        return
      }

      try {
        const options = {
          created: {
            gte: Math.floor(startDate.getTime() / 1000),
            lte: Math.floor(endDate.getTime() / 1000),
          },
        }

        const authorizationsPromise = cloudFunctionManageRequest('manageFinances', {
          function: 'fetchAuthorizations',
          livemode: true,
          options: options,
        })

        const mileagesPromise = getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
          return DatabaseGetCollection(DatabaseRef_VehiclesByDate_Query(res_GCK.clientKey, startDate, endDate))
        })

        const userSettingsPromise = getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
          return DatabaseGetCollection(DatabaseRef_UserSettings_Collection(res_GCK.clientKey))
        })

        const [authorizationsResult, mileagesResult, userSettingsResult, gasPrice] = await Promise.all([
          authorizationsPromise,
          mileagesPromise,
          userSettingsPromise,
          getGasPriceByState('IL'),
        ])

        const authorizations = ((authorizationsResult as any)?.authorizations as Stripe.Issuing.Authorization[]) || []
        const validAuthorizations = authorizations.filter((auth) => auth.approved && (auth.status === 'pending' || auth.status === 'closed'))
        const mileages = mileagesResult.data || {}
        const userSettings = userSettingsResult.data || {}

        if (validAuthorizations.length > 0 || Object.keys(mileages).length > 0) {
          // const csvContent = await generateCSVContent(validAuthorizations, mileages, userSettings, gasPrice)
          const htmlContent = await generateHTMLTable(validAuthorizations, mileages, userSettings, gasPrice)
          us_setHtml(htmlContent)

          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
            cloudFunctionManageRequest('manageEmails', {
              function: 'sendSendgridHtmlEmail',
              client_key: res_GCK.clientKey,
              to: 'mrcolson12@gmail.com',
              subject: `Combined Fuel Report: ${startDate.toISOString().split('T')[0]} to ${endDate.toISOString().split('T')[0]}`,
              html: htmlContent,
            })
              .then((res_CFMTR) => {
                // Nothing
              })
              .catch((rej_CFMTR) => {
                console.error(rej_CFMTR)
              })
          })
          // const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

          // Create a download link and trigger the download
          // const link = document.createElement('a')
          // if (link.download !== undefined) {
          //   const url = URL.createObjectURL(blob)
          //   link.setAttribute('href', url)
          //   link.setAttribute('download', `combined_report_${startDate.toISOString().split('T')[0]}_to_${endDate.toISOString().split('T')[0]}.csv`)
          //   link.style.visibility = 'hidden'
          //   document.body.appendChild(link)
          //   link.click()
          //   document.body.removeChild(link)
          // }
        } else {
          console.log('No data found for the selected date range')
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    const getCachedGeocode = async (address: string): Promise<GeoPoint | null> => {
      const geocodeQuery = DatabaseRef_GeocodedLocations_Query(uc_RootData_ClientKey as string, address)
      DatabaseGetCollection(geocodeQuery).then((querySnapshot) => {
        if (querySnapshot?.data?.docs?.length > 0) {
          const doc = querySnapshot.data.docs[0]
          const cacheEntry = doc.data() as CacheEntry
          return cacheEntry.coordinates
        }
      })

      return null
    }

    const setCachedGeocode = async (address: string, coordinates: GeoPoint): Promise<void> => {
      const cacheEntry: CacheEntry = {
        address,
        coordinates,
        timestamp_created: new Date(),
      }
      await DatabaseAddDocument(DatabaseRef_GeocodedLocations_Collection(uc_RootData_ClientKey as string), cacheEntry, true)
    }

    const geocode = async (address: string): Promise<GeoPoint | null> => {
      // First, check the cache
      const cachedResult = await getCachedGeocode(address)
      if (cachedResult) {
        return cachedResult
      }

      // If not in cache, use Google Maps API
      const geocoder = new google.maps.Geocoder()

      return new Promise((resolve, reject) => {
        geocoder.geocode({ address: address }, async (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
            const location = results[0].geometry.location
            const coordinates: GeoPoint = new GeoPoint(location.lat(), location.lng())

            // Cache the result
            await setCachedGeocode(address, coordinates)

            resolve(coordinates)
          } else {
            resolve(null)
          }
        })
      })
    }

    function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
      const R = 6371e3 // Earth's radius in meters
      const φ1 = (lat1 * Math.PI) / 180
      const φ2 = (lat2 * Math.PI) / 180
      const Δφ = ((lat2 - lat1) * Math.PI) / 180
      const Δλ = ((lon2 - lon1) * Math.PI) / 180

      const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

      return R * c // Distance in meters
    }

    const generateHTMLTable = async (authorizations: Stripe.Issuing.Authorization[], mileages: any, userSettings: any, gasPrice?: string) => {
      let html = `
    <table style="border-collapse: collapse; width: 100%; font-family: Arial, sans-serif;">
      <thead>
    <tr style="background-color: #ececec;">
      <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: center;" colspan="3">Vehicle</th>
      <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: center;" colspan="9">Transaction</th>
      <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: center;" colspan="4">Trip</th>
      <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: center;" colspan="2">Calculations</th>
    </tr>
        <tr style="background-color: #ececec;">
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: left;">User</th>
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: left;">Truck</th>
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: left;">Trailer</th>
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: left;">Start Date</th>
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: left;">Start Time</th>
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: left;">End Date</th>
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: left;">End Time</th>
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: left;">Address</th>
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: left;">Coordinates</th>
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: right;">Amount</th>
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: right;">Gas Price</th>
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: right;">Gallons</th>
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: right;">Date</th>
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: right;">Time</th>
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: left;">Coordinates</th>
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: right;">Miles</th>
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: left;">Diff (miles)</th>
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: left;">Expected Miles</th>
          <th style="border: 1px solid #ddd; padding: 8px; color: black; text-align: right;">MPG</th>
        </tr>
      </thead>
      <tbody>
  `

      let gasPriceValue = parseFloat(gasPrice || GAS_PRICE.toString())

      // Group authorizations by user
      const authorizationsByUser: { [key: string]: Stripe.Issuing.Authorization[] } = {}
      authorizations.forEach((auth) => {
        const userSetting = Object.values(userSettings).find((setting: any) => setting?.stripe_cardholder_id === auth.cardholder) as any
        if (userSetting) {
          if (!authorizationsByUser[userSetting.name]) {
            authorizationsByUser[userSetting.name] = []
          }
          authorizationsByUser[userSetting.name].push(auth)
        }
      })

      const sortedUsers = Object.entries(authorizationsByUser).sort((a, b) => a[0].localeCompare(b[0]))

      // Function to determine cell style based on value
      const getCellStyle = (value: string | number | undefined) => {
        if (value === 'N/A' || value === 0 || value === '0.00' || value == null || value == undefined) {
          return 'background-color: #d2d7d3;' // Light grey for N/A or 0 values
        }
        return ''
      }

      // Process authorizations and mileages for each user
      for (const [userName, userAuthorizations] of sortedUsers) {
        userAuthorizations.sort((a, b) => a.created - b.created)

        let userTotalMiles = 0
        let userTotalAmount = 0
        let userTotalGallons = 0
        let hasTrailer = false

        for (let i = 0; i < userAuthorizations.length - 1; i++) {
          const startAuthorization = userAuthorizations[i]
          const endAuthorization = userAuthorizations[i + 1]
          const startDate = new Date(startAuthorization.created * 1000)
          const endDate = new Date(endAuthorization.created * 1000)

          let totalMiles = 0
          let expectedMiles = 0
          let userMileages = Object.values(mileages).flatMap((weekData: any) => {
            if (weekData.data && weekData.data[userName]) {
              return Object.values(weekData.data[userName]).flatMap((dayData: any) => dayData.trips || [])
            }
            return []
          })

          let relevantMileages = userMileages.filter((trip: any) => {
            const tripDate = new Date(trip.timestamp_start.toDate())
            return tripDate >= startDate && tripDate <= endDate
          })

          // Find the latest mileage entry
          const latestMileage = relevantMileages.reduce((latest, current) => {
            if (!latest || current.timestamp_start.toDate() > latest.timestamp_start.toDate()) {
              return current
            }
            return latest
          }, null)

          // Extract end coordinates from the latest mileage entry
          const endLat = latestMileage ? latestMileage.location_end_latitude : null
          const endLng = latestMileage ? latestMileage.location_end_longitude : null

          // Extract truck and trailer information
          let truckName = 'N/A'
          let trailerKey = 'N/A'

          for (const mileage of relevantMileages.reverse()) {
            if (mileage.vehicle_name && truckName === 'N/A') {
              truckName = mileage.vehicle_name
            }
            if (mileage.trailer_name && trailerKey === 'N/A') {
              trailerKey = mileage.trailer_name
              hasTrailer = true
            }
            if (truckName !== 'N/A' && trailerKey !== 'N/A') {
              break
            }
          }

          totalMiles = relevantMileages.reduce((sum: number, trip: any) => sum + (trip.distance_miles || 0), 0)
          expectedMiles = relevantMileages.reduce((sum: number, trip: any) => sum + (trip.expected_distance_miles || 0), 0)
          let transactionGasPrice = gasPriceValue
          if ((endAuthorization as any).fuel) {
            transactionGasPrice = parseFloat((endAuthorization as any).fuel?.unit_cost_decimal) / 100
          }

          const totalAmount = endAuthorization.amount / 100
          const formattedTotalAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalAmount)
          const formattedGasPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(transactionGasPrice)
          let gallons = (endAuthorization as any).fuel?.quantity_decimal || null
          if (gallons == null) {
            gallons = (totalAmount / transactionGasPrice).toFixed(2)
          }
          const mpg = gallons
            ? (totalMiles / parseFloat(gallons)).toFixed(2)
            : transactionGasPrice && totalAmount !== 0
              ? (totalMiles / (totalAmount / transactionGasPrice)).toFixed(2)
              : 'N/A'

          const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
          const dateOptions: Intl.DateTimeFormatOptions = { timeZone, year: 'numeric', month: '2-digit', day: '2-digit' }
          const timeOptions: Intl.DateTimeFormatOptions = { timeZone, hour: '2-digit', minute: '2-digit', hour12: true }

          // Extract purchase address from endAuthorization
          const purchaseAddress = endAuthorization.merchant_data
            ? `${endAuthorization.merchant_data.name || 'Unknown'} ${endAuthorization.merchant_data.city || ''} ${endAuthorization.merchant_data.state || ''} ${endAuthorization.merchant_data.country || ''}`
            : 'Address not available'

          // Geocode the purchase address
          const coordinates = await geocode(purchaseAddress)

          // Calculate distance if both sets of coordinates are available
          let distanceInMiles = 'N/A'
          if (endLat != null && endLng != null && coordinates) {
            const distanceInMeters = calculateDistance(endLat, endLng, coordinates.latitude, coordinates.longitude)
            distanceInMiles = (distanceInMeters / 1609.344).toFixed(2) // Convert meters to miles
          }

          const mpgThreshold = trailerKey !== 'N/A' ? 5 : 8
          let mpgStyle = parseFloat(mpg) < mpgThreshold ? 'background-color: #e54f1a9a0a4a;' : ''
          if (mpg === 'N/A' || mpg === '0.00') {
            mpgStyle = 'background-color: #bdc3c7;'
          }
          // Add row to HTML table
          html += `
<tr>
  <td style="border: 1px solid #ddd; padding: 8px;">${userName}</td>
  <td style="border: 1px solid #ddd; padding: 8px;">${truckName}</td>
  <td style="border: 1px solid #ddd; padding: 8px;">${trailerKey}</td>
  <td style="border: 1px solid #ddd; padding: 8px;">${startDate.toLocaleDateString(undefined, dateOptions)}</td>
  <td style="border: 1px solid #ddd; padding: 8px;">${startDate.toLocaleTimeString(undefined, timeOptions)}</td>
  <td style="border: 1px solid #ddd; padding: 8px;">${endDate.toLocaleDateString(undefined, dateOptions)}</td>
  <td style="border: 1px solid #ddd; padding: 8px;">${endDate.toLocaleTimeString(undefined, timeOptions)}</td>
  <td style="border: 1px solid #ddd; padding: 8px;">${purchaseAddress}</td>
  <td style="border: 1px solid #ddd; padding: 8px; ${getCellStyle(coordinates?.latitude && coordinates?.longitude)}">
    ${coordinates ? `${coordinates.latitude.toFixed(6)}, ${coordinates.longitude.toFixed(6)}` : 'N/A'}
  </td>
  <td style="border: 1px solid #ddd; padding: 8px; text-align: right;">${formattedTotalAmount}</td>
  <td style="border: 1px solid #ddd; padding: 8px; text-align: right;">${formattedGasPrice}</td>
  <td style="border: 1px solid #ddd; padding: 8px; text-align: right;">${gallons}</td>
  <td style="border: 1px solid #ddd; padding: 8px;">${latestMileage?.timestamp_end ? latestMileage.timestamp_end.toDate().toLocaleDateString(undefined, dateOptions) : 'N/A'}</td>
  <td style="border: 1px solid #ddd; padding: 8px;">${latestMileage?.timestamp_end ? latestMileage.timestamp_end.toDate().toLocaleTimeString(undefined, timeOptions) : 'N/A'}</td>
  <td style="border: 1px solid #ddd; padding: 8px; ${getCellStyle(endLat && endLng)}">
    ${endLat != null && endLng != null ? `${endLat.toFixed(6)}, ${endLng.toFixed(6)}` : 'N/A'}
  </td>
  <td style="border: 1px solid #ddd; padding: 8px; text-align: right; ${getCellStyle(totalMiles)}">${totalMiles.toFixed(2)}</td>
  <td style="border: 1px solid #ddd; padding: 8px;" ${getCellStyle(distanceInMiles)}>${distanceInMiles}</td>
  <td style="border: 1px solid #ddd; padding: 8px; text-align: right; ${mpgStyle}">${mpg}</td>
</tr>
`

          userTotalMiles += totalMiles
          userTotalAmount += totalAmount
          userTotalGallons += parseFloat(gallons || '0')
        }

        // Add total row for the user
        const userTotalMPG =
          userTotalGallons > 0
            ? (userTotalMiles / userTotalGallons).toFixed(2)
            : gasPriceValue && userTotalAmount !== 0
              ? (userTotalMiles / (userTotalAmount / gasPriceValue)).toFixed(2)
              : 'N/A'

        const userMpgThreshold = hasTrailer ? 5 : 8
        let mpgStyle = parseFloat(userTotalMPG) < userMpgThreshold ? 'background-color: #e54a4a;' : ''
        if (userTotalMPG === 'N/A' || userTotalMPG === '0.00') {
          mpgStyle = 'background-color: #bdc3c7;'
        }

        html += `
        <tr style="background-color: #d2d7d3; font-weight: bold;">
          <td style="border: 1px solid #ddd; padding: 8px; color: black;">${userName} (Total)</td>
          <td style="border: 1px solid #ddd; padding: 8px; color: black;" colspan="8"></td>
          <td style="border: 1px solid #ddd; padding: 8px; color: black; text-align: right;">${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(userTotalAmount)}</td>
          <td style="border: 1px solid #ddd; padding: 8px; color: black;" colspan="5"></td>
          <td style="border: 1px solid #ddd; padding: 8px; color: black; text-align: right;">${userTotalMiles.toFixed(2)}</td>
          <td style="border: 1px solid #ddd; padding: 8px; color: black;"></td>
          <td style="border: 1px solid #ddd; padding: 8px; color: black; text-align: right; ${mpgStyle}">${userTotalMPG}</td>
        </tr>
      `
      }

      html += `
  </tbody>
</table>
`

      return html
    }

    const generateCSVContent = async (authorizations: Stripe.Issuing.Authorization[], mileages: any, userSettings: any, gasPrice?: string) => {
      const headers = ['User Name', 'Start Date', 'Start Time', 'End Date', 'End Time', 'Miles', 'Amount', 'Gas Price', 'Gallons', 'MPG']
      const rows: string[][] = []
      let gasPriceValue = parseFloat(gasPrice || GAS_PRICE.toString())

      // Group authorizations by user
      const authorizationsByUser: { [key: string]: Stripe.Issuing.Authorization[] } = {}
      authorizations.forEach((auth) => {
        const userSetting = Object.values(userSettings).find((setting: any) => setting?.stripe_cardholder_id === auth.cardholder) as any
        if (userSetting) {
          if (!authorizationsByUser[userSetting.name]) {
            authorizationsByUser[userSetting.name] = []
          }
          authorizationsByUser[userSetting.name].push(auth)
        }
      })

      // Process authorizations and mileages for each user
      Object.entries(authorizationsByUser).forEach(async ([userName, userAuthorizations]) => {
        userAuthorizations.sort((a, b) => a.created - b.created)

        let userTotalMiles = 0
        let userTotalAmount = 0
        let userTotalGallons = 0

        for (let i = 0; i < userAuthorizations.length - 1; i++) {
          const startAuthorization = userAuthorizations[i]
          const endAuthorization = userAuthorizations[i + 1]
          const startDate = new Date(startAuthorization.created * 1000)
          const endDate = new Date(endAuthorization.created * 1000)

          let totalMiles = 0
          let userMileages = Object.values(mileages).flatMap((weekData: any) => {
            if (weekData.data && weekData.data[userName]) {
              return Object.values(weekData.data[userName]).flatMap((dayData: any) => dayData.trips || [])
            }
            return []
          })

          let relevantMileages = userMileages.filter((trip: any) => {
            const tripDate = new Date(trip.timestamp_start.toDate())
            return tripDate >= startDate && tripDate <= endDate
          })

          totalMiles = relevantMileages.reduce((sum: number, trip: any) => sum + (trip.distance_miles || 0), 0)

          const totalAmount = endAuthorization.amount / 100
          const formattedTotalAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalAmount)
          const formattedGasPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(gasPriceValue)
          let gallons = (endAuthorization as any).fuel?.quantity_decimal || null
          if (gallons == null) {
            gallons = (totalAmount / gasPriceValue).toFixed(2)
          }
          const mpg = gallons
            ? (totalMiles / parseFloat(gallons)).toFixed(2)
            : gasPriceValue && totalAmount !== 0
              ? (totalMiles / (totalAmount / gasPriceValue)).toFixed(2)
              : 'N/A'

          const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
          const dateOptions: Intl.DateTimeFormatOptions = { timeZone, year: 'numeric', month: '2-digit', day: '2-digit' }
          const timeOptions: Intl.DateTimeFormatOptions = { timeZone, hour: '2-digit', minute: '2-digit', hour12: true }

          rows.push([
            userName,
            startDate.toLocaleDateString(undefined, dateOptions),
            startDate.toLocaleTimeString(undefined, timeOptions),
            endDate.toLocaleDateString(undefined, dateOptions),
            endDate.toLocaleTimeString(undefined, timeOptions),
            totalMiles.toFixed(2),
            formattedTotalAmount,
            formattedGasPrice,
            gallons,
            mpg,
          ])

          userTotalMiles += totalMiles
          userTotalAmount += totalAmount
          userTotalGallons += parseFloat(gallons || '0')
        }

        // Add total row for the user
        const userTotalMPG =
          userTotalGallons > 0
            ? (userTotalMiles / userTotalGallons).toFixed(2)
            : gasPriceValue && userTotalAmount !== 0
              ? (userTotalMiles / (userTotalAmount / gasPriceValue)).toFixed(2)
              : 'N/A'
        rows.push([
          `${userName} (Total)`,
          '',
          '',
          '',
          '',
          userTotalMiles.toFixed(2),
          new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(userTotalAmount),
          '',
          userTotalGallons.toFixed(2),
          userTotalMPG,
        ])
      })

      // Sort rows by user name
      rows.sort((a, b) => a[0].localeCompare(b[0]))

      return [headers.join(','), ...rows.map((r) => r.join(','))].join('\n')
    }

    async function getGasPriceByState(state: string) {
      const apiKey = '2Bu63lZQBWCrLrBIyoluXt:6JCJvwXKxF6A2JbenftWaT'
      const url = `https://api.collectapi.com/gasPrice/stateUsaPrice?state=${state}`

      try {
        const response = await axios.get(url, {
          headers: {
            'content-type': 'application/json',
            'authorization': `apikey ${apiKey}`,
          },
        })

        if (response.data.success) {
          return response.data.result.state.gasoline
        } else {
          throw new Error('API request was not successful')
        }
      } catch (error: any) {
        console.error('Error fetching gas prices:', error.message)
        throw error
      }
    }

    let tabJSX = (
      <Box sx={{ flexGrow: 1, height: '100%', p: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => {
                  if (endDate && newValue && newValue < endDate) {
                    setStartDate(newValue)
                  } else if (!endDate) {
                    setStartDate(newValue)
                  }
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <Box sx={{ width: 16 }} />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => {
                  if (startDate && newValue && newValue > startDate) {
                    setEndDate(newValue)
                  } else if (!startDate) {
                    setEndDate(newValue)
                  }
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadReport}
              disabled={!startDate || !endDate || (startDate && endDate && endDate <= startDate)}
              startIcon={
                <Icon
                  icon="download"
                  type="solid"
                />
              }
            >
              {rLIB('Mileage Report')}
            </Button>
            {us_html && (
              <Box sx={{ mt: 2 }}>
                <div dangerouslySetInnerHTML={{ __html: us_html }} />
              </Box>
            )}
          </Box>
        </LocalizationProvider>
      </Box>
    )
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Spending')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabsSettings={{
                baseUrl: ApplicationPages.AdminFinanceSpendingIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Spending', false) as string,
              }}
              tabs={[
                {
                  tabHeader: rLIB('Cardholders'),
                  tabUrlKey: 'cardholders',
                  tabButtons: [{ fullJSX: rJSX_NewCardholderButton(), minJSX: rJSX_NewCardholderButton(), sizeCutoff: 0 }],
                  tabContent: (
                    <Box>
                      <CardholdersTab
                        selectedDate={us_selectedDate}
                        setSelectedDate={us_setSelectedDate}
                      />
                    </Box>
                  ),
                },
                {
                  tabHeader: rLIB('Authorizations'),
                  tabUrlKey: 'authorizations',
                  tabButtons: [],
                  tabContent: <Box>{rJSX_AuthorizationsTab()}</Box>,
                },
                {
                  tabHeader: rLIB('Transactions'),
                  tabUrlKey: 'transactions',
                  tabButtons: [],
                  tabContent: <Box>{rJSX_TransactionsTab()}</Box>,
                },
                {
                  tabHeader: rLIB('Approvals'),
                  tabUrlKey: 'approvals',
                  tabButtons: [],
                  tabContent: (
                    <Box>
                      <ApprovalsTab
                        selectedDate={us_selectedDate}
                        setSelectedDate={us_setSelectedDate}
                      />
                    </Box>
                  ),
                },
                {
                  tabHeader: rLIB('Profiles'),
                  tabUrlKey: 'profiles',
                  tabButtons: [],
                  tabContent: (
                    <Box>
                      <ProfilesTab
                        selectedDate={us_selectedDate}
                        setSelectedDate={us_setSelectedDate}
                      />
                    </Box>
                  ),
                },
                {
                  tabHeader: rLIB('Reports'),
                  tabUrlKey: 'reports',
                  tabButtons: [],
                  tabContent: <Box>{rJSX_ReportsTab()}</Box>,
                },
              ]}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
