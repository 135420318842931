import { Alert, Box, Button, MenuItem, Select, Snackbar, styled, Switch, TextField, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import { useEffect, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import {
  TsInterface_CashDisclosureForm,
  TsInterface_DynamicFormInput,
  TsInterface_FormSubmissionBundle,
  TsInterface_LeaseDisclosureForm,
} from '../interfaces/i_sales_tools'
import { cashDisclosureForm, generateFormName, leaseDisclosureForm, stateUtilityMap } from './abp_helpers'

type Action =
  | { type: 'CHANGE_INPUT'; field: string; value: string; input: TsInterface_DynamicFormInput }
  | { type: 'RESET_FORM' }
  | { type: 'LOADED_PRESETS'; presets: TsInterface_UnspecifiedObject }

type ABPFormType = 'LEASE' | 'PURCHASE'
const formTypes: ABPFormType[] = ['LEASE', 'PURCHASE']

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '800px',
  margin: '0 auto',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}))

const StyledSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

const StyledFormControl = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

const StyledLabel = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: 'bold',
}))

const StyledSelect = styled(Select)(({ theme }) => ({
  'width': '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.light,
  },
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  'width': '100%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.primary.light,
    },
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

export const Container = (): JSX.Element => {
  return <StyledContainer>{rJSX_PageContent()}</StyledContainer>
}

export const validateFormData = (
  form: TsInterface_LeaseDisclosureForm | TsInterface_CashDisclosureForm,
  formSubmissionBundle: TsInterface_FormSubmissionBundle,
) => {
  for (const [key, value] of Object.entries(form)) {
    if (value.validation_check) {
      if (!value.validation_check(value.value)) {
        formSubmissionBundle.us_setValidationsPassed(false)
        formSubmissionBundle.us_setSnackbar({
          open: true,
          message: <Box>Validation check failed for field "{value.label}".</Box>,
          severity: 'error',
        })
        return false
      }
    }
  }
  formSubmissionBundle.us_setValidationsPassed(true)
  return true
}

const getRECSizeBracket = (size: number) => {
  if (size <= 10) {
    return '<=10'
  } else if (size <= 25) {
    return '10-25'
  } else {
    return '>25'
  }
}

const getRECGroup = (form: TsInterface_CashDisclosureForm): string => {
  if (form['electric_utility'].value === 'Municipal Utility' || form['electric_utility'].value === 'Rural Electric Cooperative') {
    const utilityName = form['muni_coop_name'].value
    return stateUtilityMap[form['state' as keyof TsInterface_CashDisclosureForm].value][utilityName].group
  } else {
    for (const [key, value] of Object.entries(stateUtilityMap[form['customer_address_state' as keyof TsInterface_CashDisclosureForm].value])) {
      if (value.mappedUtility === form['electric_utility'].value) {
        return value.group
      }
    }
  }
  throw new Error('Utility not found')
}

const getExpectedRecValue = (form: TsInterface_CashDisclosureForm, inverterEfficiency: number): number => {
  let project_size_kw_ac = 0

  // safety check in case we have not yet updated the project size in ac
  if (form['project_size_kw_ac'].value === 0) {
    project_size_kw_ac = form['project_size_kw_dc'].value * inverterEfficiency
  } else {
    project_size_kw_ac = form['project_size_kw_ac'].value
  }

  if (project_size_kw_ac < 0 || project_size_kw_ac > 100) {
    throw new Error('Project size is less than 0 kW or greater than 100 kW')
  }

  const recGroup = getRECGroup(form)
  const sizeBracket = getRECSizeBracket(project_size_kw_ac)

  if (recGroup === 'A') {
    if (sizeBracket === '<=10') {
      return 73.71
    } else if (sizeBracket === '10-25') {
      return 63.53
    } else {
      return 55.89
    }
  } else {
    if (sizeBracket === '<=10') {
      return 83.87
    } else if (sizeBracket === '10-25') {
      return 77.53
    } else {
      return 70.23
    }
  }
}

export const precalculatePurchaseFormFields = (
  form: TsInterface_CashDisclosureForm,
  formSubmissionBundle: TsInterface_FormSubmissionBundle,
): TsInterface_CashDisclosureForm => {
  const inverterEfficiency = 0.71428571428 // HARD CODED TO TESLA PER RAPHAEL
  const degradation = 0.65
  const recPayoutAfterFees = 0.85
  const grossElectricProduction = Number(form['gross_electric_production'].value) * 1000

  try {
    for (const [key, value] of Object.entries(form)) {
      switch (key) {
        // case 'final_amount_owed': REMOVED BECAUSE IT IS NOT A SUM TOTAL BUT ANOTHER PAYMENT
        //   form[key as keyof TsInterface_CashDisclosureForm].value = Number(
        //     Number(form['initial_deposit_owed'].value) + Number(form['installation_owed'].value),
        //   ).toFixed(2)
        //   break
        case 'project_size_kw_ac':
          form[key as keyof TsInterface_CashDisclosureForm].value = (Number(form['project_size_kw_dc'].value) * inverterEfficiency).toFixed(2)
          break
        case 'expected_rec_value':
          form[key as keyof TsInterface_CashDisclosureForm].value = (
            Math.floor(grossElectricProduction * ((1 - (1 - degradation) ** 15) / degradation / 1000)) * // calculate the number of expected rec payments and multiply by the expected rec value
            getExpectedRecValue(form, inverterEfficiency)
          ).toFixed(2)
          break
        case 'rec_customer_payment':
          form[key as keyof TsInterface_CashDisclosureForm].value = (form['expected_rec_value'].value * recPayoutAfterFees).toFixed(2)
          break
        case 'form_name':
          form[key as keyof TsInterface_CashDisclosureForm].value = generateFormName(formSubmissionBundle.us_companyNameURL, form)
          break
        default:
          break
      }
    }
  } catch (err: any) {
    console.error('ERROR PRECALCULATING PURCHASE FORM FIELDS')
    formSubmissionBundle.us_setSnackbar({
      open: true,
      message: <Box>Error precalculating fields, details: {err}</Box>,
      severity: 'error',
    })
  }

  return form
}

export const precalculateLeaseFormFields = (
  form: TsInterface_LeaseDisclosureForm,
  formSubmissionBundle: TsInterface_FormSubmissionBundle,
): TsInterface_LeaseDisclosureForm => {
  const inverterEfficiency = 0.71428571428 // HARD CODED TO TESLA PER RAPHAEL

  try {
    for (const [key, value] of Object.entries(form)) {
      switch (key) {
        case 'project_size_kw_ac':
          form[key as keyof TsInterface_LeaseDisclosureForm].value = (Number(form['project_size_kw_dc'].value) * inverterEfficiency).toFixed(2)
          break
        case 'form_name':
          form[key as keyof TsInterface_LeaseDisclosureForm].value = generateFormName(formSubmissionBundle.us_companyNameURL, form)
          break
        // TODO: add other fields as necessary
        default:
          break
      }
    }
  } catch (err: any) {
    console.error('ERROR PRECALCULATING LEASE FORM FIELDS')
    formSubmissionBundle.us_setSnackbar({
      open: true,
      message: <Box>Error precalculating fields, details: {err}</Box>,
      severity: 'error',
    })
  }

  return form
}

export const handleFormSubmit = (formSubmissionBundle: TsInterface_FormSubmissionBundle) => {
  try {
    let form: TsInterface_LeaseDisclosureForm | TsInterface_CashDisclosureForm
    if (formSubmissionBundle.us_formType === 'LEASE') {
      form = formSubmissionBundle.ur_leaseForm
    } else {
      form = formSubmissionBundle.ur_cashForm
    }

    // calculate precalculated fields for purchase form
    if (formSubmissionBundle.us_formType === 'LEASE') {
      form = precalculateLeaseFormFields(form as TsInterface_LeaseDisclosureForm, formSubmissionBundle)
    } else {
      form = precalculatePurchaseFormFields(form as TsInterface_CashDisclosureForm, formSubmissionBundle)
    }

    let formData: any = {}

    // validate form data
    if (!validateFormData(form, formSubmissionBundle)) {
      return
    }

    // reduce the form to a simple object for the api
    for (const [key, value] of Object.entries(form)) {
      formData[key] = value.value
    }

    // submit a form to the backend
    cloudFunctionUnauthenticatedRequests({
      function: 'createDisclosureForm',
      form_data: formData,
      save_preset: formSubmissionBundle.us_savePreset,
      form_type: formData['form_type'],
    })
      .then((res: any) => {
        if (res.data.success && formSubmissionBundle.us_repEmail) {
          // send the sales rep an email if the form is successfully created
          cloudFunctionUnauthenticatedRequests({
            function: 'sendSendgridEmail',
            to_array: [formSubmissionBundle.us_repEmail],
            cc_array: [],
            bcc_array: [],
            subject: `Disclosure Form Created - ${formData['form_name']}`,
            body: `A disclosure form has been successfully created by ${formSubmissionBundle.us_repName} for ${formData['form_name']}. \nThe form ID is ${res.data.FormID}. \nThe form status is "${res.data.FormStatus}". \nThe reference number is ${res.data.reference_number}. \n\nHere is the link where you can view the form status: https://etwenergy.com/a/abp_disclosure_form/${formSubmissionBundle.us_companyNameURL}/status/${res.data.FormID}`,
          }).catch((err) => {
            formSubmissionBundle.us_setSnackbar({
              open: true,
              message: (
                <Box>
                  Error sending email to sales rep.
                  <br />
                  Message: {err.response.data.message}
                  <br />
                  Details: {err.response.data.details}
                </Box>
              ),
              severity: 'error',
            })
            return
          })
        }

        formSubmissionBundle.us_setSnackbar({
          open: true,
          message: (
            <Box>
              Form submitted successfully! {formSubmissionBundle.us_savePreset ? 'Preset saved.' : ''}
              <br />
              <br />
              {Object.entries(res.data).map(([key, value]) => (
                <Box key={key}>
                  <Typography variant="body1">
                    {key}: {String(value)}
                  </Typography>
                </Box>
              ))}
            </Box>
          ),
          severity: 'success',
        })
      })
      .catch((err) => {
        formSubmissionBundle.us_setSnackbar({
          open: true,
          message: (
            <Box>
              Error submitting form. Please try again. Remember to check below each field for that field's instructions. Also ensure that your form name is
              unique and not one that you have previously submitted.
              <br />
              Details: {err.response.data.message}
            </Box>
          ),
          severity: 'error',
        })
        return
      })
  } catch (err: any) {
    formSubmissionBundle.us_setSnackbar({
      open: true,
      message: (
        <Box>
          Error submitting form. This is likely due to a server error, please contact ETW Energy for support.
          <br />
        </Box>
      ),
      severity: 'error',
    })
    return
  }
}

const rJSX_PageContent = (): JSX.Element => {
  const [us_selectedPreset, us_setSelectedPreset] = useState('None')
  const [us_savedPresets, us_setSavedPresets] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formType, us_setFormType] = useState<ABPFormType>('LEASE')
  const [us_showAllFields, us_setShowAllFields] = useState(false)
  const [us_savePreset, us_setSavePreset] = useState(false)
  // const [us_formPresets, us_setFormPresets] = useState<TsInterface_UnspecifiedObject>({})
  const [us_presetFields, us_setPresetFields] = useState<string[]>([])
  const [us_snackbar, us_setSnackbar] = useState({
    open: false,
    message: <></>,
    severity: 'success' as 'success' | 'error',
  })
  const [us_repName, us_setRepName] = useState('')
  const [us_repEmail, us_setRepEmail] = useState('')
  const [us_repPhone, us_setRepPhone] = useState('')
  const [us_isAdmin, us_setIsAdmin] = useState(false)
  const [us_companyNameURL, us_setCompanyNameURL] = useState(useParams().id || '')
  const [us_validationsPassed, us_setValidationsPassed] = useState(false)

  const leaseFormReducer = (state: TsInterface_LeaseDisclosureForm, action: Action): TsInterface_LeaseDisclosureForm => {
    switch (action.type) {
      case 'CHANGE_INPUT':
        return {
          ...state,
          [action.field]: {
            ...action.input,
            value: action.value,
          },
        }
      case 'LOADED_PRESETS':
        if (action.presets) {
          const updatedState = { ...state }
          for (const [key, value] of Object.entries(action.presets)) {
            if (updatedState[key as string as keyof TsInterface_LeaseDisclosureForm]) {
              updatedState[key as string as keyof TsInterface_LeaseDisclosureForm] = {
                ...updatedState[key as string as keyof TsInterface_LeaseDisclosureForm],
                value: value,
              }
            }
          }
          return updatedState
        }
        return state
      case 'RESET_FORM':
        return { ...leaseDisclosureForm }
      default:
        return state
    }
  }

  const cashFormReducer = (state: TsInterface_CashDisclosureForm, action: Action): TsInterface_CashDisclosureForm => {
    switch (action.type) {
      case 'CHANGE_INPUT':
        return {
          ...state,
          [action.field]: {
            ...state[action.field as keyof TsInterface_CashDisclosureForm],
            value: action.value,
          },
        }
      case 'LOADED_PRESETS':
        if (action.presets) {
          const updatedState = { ...state }
          for (const [key, value] of Object.entries(action.presets)) {
            if (updatedState[key as string as keyof TsInterface_CashDisclosureForm]) {
              updatedState[key as string as keyof TsInterface_CashDisclosureForm] = {
                ...updatedState[key as string as keyof TsInterface_CashDisclosureForm],
                value: value,
              }
            }
          }
          return updatedState
        }
        return state
      case 'RESET_FORM':
        return { ...cashDisclosureForm }
      default:
        return state
    }
  }

  const [ur_leaseForm, ur_leaseFormDispatch] = useReducer(leaseFormReducer, { ...leaseDisclosureForm })
  const [ur_cashForm, ur_cashFormDispatch] = useReducer(cashFormReducer, { ...cashDisclosureForm })

  useEffect(() => {
    // set permissions and form type based on company
    if (us_companyNameURL) {
      switch (us_companyNameURL) {
        case 'etw_admin_lease':
          us_setIsAdmin(true)
          us_setFormType('LEASE')
          break
        case 'etw_admin_purchase':
          us_setIsAdmin(true)
          us_setFormType('PURCHASE')
          break
        case 'carbon_solutions':
          us_setFormType('PURCHASE')
          break
        case 'sunnova':
          us_setFormType('LEASE')
          break
        case 'palmetto':
          us_setFormType('PURCHASE')
          break
        default:
          us_setSnackbar({
            open: true,
            message: <Box>Company not found.</Box>,
            severity: 'error',
          })
          break
        // other companies to add
        // sunrun: 'R',
        // tesla: 'T',
        // sunergy: 'U',
        // palmetto: 'P',
        // sunder: 'S',
        // lunar: 'L',
        // powerise: 'W',
        // etw: 'E',
      }
    }
  }, [])

  // load in the presets from the db
  useEffect(() => {
    // inline function to get the presets from the db
    const getABPDisclosureFormPresets = async () => {
      return new Promise((resolve, reject) => {
        cloudFunctionUnauthenticatedRequests({
          function: 'getABPDisclosureFormPresets',
        })
          .then((res: any) => {
            resolve(res.data.data)
          })
          .catch((err) => {
            us_setSnackbar({
              open: true,
              message: (
                <Box>
                  Error loading presets, details: <br />
                  {err.response.data}
                </Box>
              ),
              severity: 'error',
            })
          })
      })
    }

    // get the presets from the db
    getABPDisclosureFormPresets()
      .then((res: any) => {
        // set the saved presets
        us_setSavedPresets(res)
        // FIXME: got to be a better system for this, because this requires that the devs put in the url names manually
        let companyNameElements = us_companyNameURL.toLowerCase().split('_')
        for (const key in res) {
          let keyMatches = true
          for (const element of companyNameElements) {
            if (!key.toLowerCase().includes(element)) {
              keyMatches = false
            }
          }

          // if the key of the database-stored preset contains the url elements of the company name, set the selected preset to that key
          if (keyMatches) {
            us_setSelectedPreset(key)
            us_setPresetFields(Object.keys(res[key]))
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  const rJSX_DynamicFormTextInput = (input: TsInterface_DynamicFormInput, key: string): JSX.Element => {
    return (
      <StyledFormControl>
        <StyledLabel variant="subtitle1">{input.label}</StyledLabel>
        <StyledTextField
          name={key}
          value={input.value}
          helperText={input.instructions}
          onChange={(e) => {
            if (us_formType === 'LEASE') {
              ur_leaseFormDispatch({ type: 'CHANGE_INPUT', field: key, value: e.target.value, input: input })
            } else {
              ur_cashFormDispatch({ type: 'CHANGE_INPUT', field: key, value: e.target.value, input: input })
            }
          }}
        />
      </StyledFormControl>
    )
  }

  const rJSX_DynamicFormSelectInput = (input: TsInterface_DynamicFormInput, key: string): JSX.Element => {
    return (
      <StyledFormControl>
        <StyledLabel variant="subtitle1">{input.label}</StyledLabel>
        <StyledSelect
          labelId={`${key}-label`}
          id={key}
          name={key}
          value={input.value}
          onChange={(e) => {
            if (us_formType === 'LEASE') {
              ur_leaseFormDispatch({ type: 'CHANGE_INPUT', field: key, value: e.target.value as string, input: input })
            } else {
              ur_cashFormDispatch({ type: 'CHANGE_INPUT', field: key, value: e.target.value as string, input: input })
            }
          }}
        >
          {input.options?.map((option) => (
            <MenuItem
              key={option}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </StyledSelect>
        {input.instructions && (
          <Typography
            variant="caption"
            className="tw-text-gray_400 tw-ml-3"
          >
            {input.instructions}
          </Typography>
        )}
      </StyledFormControl>
    )
  }

  const determineIfConditionalDependencyIsMet = (conditionalDependencies: object): boolean => {
    // TODO: part of our hacky solution for the one field with an OR clause for ALL dependencies
    if (Object.keys(conditionalDependencies).includes('or')) {
      return Object.entries(conditionalDependencies).some(([key, values]) => {
        if (key !== 'or') {
          try {
            if (us_formType === 'LEASE') {
              if (values.includes('any') && ur_leaseForm[key as string as keyof TsInterface_LeaseDisclosureForm].value.length > 0) {
                return true
              }
              return (
                values.includes(ur_leaseForm[key as string as keyof TsInterface_LeaseDisclosureForm].value) ||
                values.includes(String(ur_leaseForm[key as string as keyof TsInterface_LeaseDisclosureForm].value))
              )
            } else {
              if (values.includes('any') && ur_cashForm[key as string as keyof TsInterface_CashDisclosureForm].value.length > 0) {
                return true
              }
              return (
                values.includes(ur_cashForm[key as string as keyof TsInterface_CashDisclosureForm].value) ||
                values.includes(String(ur_cashForm[key as string as keyof TsInterface_CashDisclosureForm].value))
              )
            }
          } catch (err: any) {
            console.error('ERROR DETERMINING IF CONDITIONAL DEPENDENCY IS MET')
            us_setSnackbar({
              open: true,
              message: <Box>Error determining if conditional dependency is met, details: {err}</Box>,
              severity: 'error',
            })
          }
        }
      })
    }

    return Object.entries(conditionalDependencies).every(([key, values]) => {
      try {
        if (us_formType === 'LEASE') {
          if (values.includes('any') && ur_leaseForm[key as string as keyof TsInterface_LeaseDisclosureForm].value.length > 0) {
            return true
          }
          return (
            values.includes(ur_leaseForm[key as string as keyof TsInterface_LeaseDisclosureForm].value) ||
            values.includes(String(ur_leaseForm[key as string as keyof TsInterface_LeaseDisclosureForm].value))
          )
        } else {
          if (values.includes('any') && ur_cashForm[key as string as keyof TsInterface_CashDisclosureForm].value.length > 0) {
            return true
          }
          return (
            values.includes(ur_cashForm[key as string as keyof TsInterface_CashDisclosureForm].value) ||
            values.includes(String(ur_cashForm[key as string as keyof TsInterface_CashDisclosureForm].value))
          )
        }
      } catch (err: any) {
        console.error('ERROR DETERMINING IF CONDITIONAL DEPENDENCY IS MET')
        us_setSnackbar({
          open: true,
          message: <Box>Error determining if conditional dependency is met, details: {err}</Box>,
          severity: 'error',
        })
      }
    })
  }

  const rJSX_CreateDynamicForm = (): JSX.Element => {
    let formData: TsInterface_LeaseDisclosureForm | TsInterface_CashDisclosureForm

    // set the form based on the form type and set the preset fields before creating the dynamic form
    if (us_formType === 'LEASE') {
      formData = ur_leaseForm
      if (us_selectedPreset !== 'None') {
        for (const key in us_savedPresets[us_selectedPreset]) {
          formData[key as keyof TsInterface_LeaseDisclosureForm].value = us_savedPresets[us_selectedPreset][key]
        }
      }
    } else {
      formData = ur_cashForm
      if (us_selectedPreset !== 'None') {
        for (const key in us_savedPresets[us_selectedPreset]) {
          formData[key as keyof TsInterface_CashDisclosureForm].value = us_savedPresets[us_selectedPreset][key]
        }
      }
    }

    return (
      <Grid2
        container
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'flex-start' }}
      >
        {Object.keys(formData).map((key: any) => {
          // if the field is a preset field, we ignore it
          if (
            us_formType === 'LEASE' &&
            us_selectedPreset !== 'None' &&
            us_savedPresets[us_selectedPreset] &&
            us_presetFields.includes(key)
            // key != 'project_lessor_legal_name'
          ) {
            return null
          } else if (
            us_formType === 'PURCHASE' &&
            us_selectedPreset !== 'None' &&
            us_savedPresets[us_selectedPreset] &&
            us_presetFields.includes(key)
            // key != 'seller_legal_name'
          ) {
            return null
          }

          let formField: TsInterface_DynamicFormInput
          if (us_formType === 'LEASE') {
            formField = ur_leaseForm[key as keyof typeof ur_leaseForm]
          } else {
            formField = ur_cashForm[key as keyof typeof ur_cashForm]
          }

          const isTextOrNumber = formField.input_type === 'text' || formField.input_type === 'number'

          // handle required fields
          if (isTextOrNumber && formField.is_required) {
            return <Box key={key}>{rJSX_DynamicFormTextInput(formField, key)}</Box>
          }

          if (formField.input_type === 'dropdown' && formField.is_required) {
            return <Box key={key}>{rJSX_DynamicFormSelectInput(formField, key)}</Box>
          }

          // handle conditional fields
          if ((!formField.is_required && Object.keys(formField.conditional_dependencies).length > 0) || (us_showAllFields && key !== 'form_type')) {
            const conditional_dependencies_met = determineIfConditionalDependencyIsMet(formField.conditional_dependencies)
            if (conditional_dependencies_met || us_showAllFields) {
              if (isTextOrNumber) {
                return <Box key={key}>{rJSX_DynamicFormTextInput(formField, key)}</Box>
              }
              if (formField.input_type === 'dropdown') {
                return <Box key={key}>{rJSX_DynamicFormSelectInput(formField, key)}</Box>
              }
            }
          }

          return null
        })}
      </Grid2>
    )
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    us_setSnackbar({ ...us_snackbar, open: false })
  }

  return (
    <Box>
      <StyledTitle variant="h4">ABP Disclosure {us_formType === 'LEASE' ? 'Lease' : 'Purchase'} Form</StyledTitle>
      {us_isAdmin && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
            <Typography variant="overline">Show all form fields</Typography>
            <Switch
              checked={us_showAllFields}
              onChange={() => us_setShowAllFields(!us_showAllFields)}
              color="primary"
            />
          </Box>
          {us_showAllFields && (
            <Typography
              variant="caption"
              className="tw-mb-2"
            >
              <strong style={{ color: '#ffeb3b' }}>
                Caution: This will show all fields, including those that are not required and/or precalculated. This is useful for debugging and for admins to
                set a specific field value, however, do not use this to fill in the form—many fields are precalculated upon submission and may overwrite entered
                values. Only use this to set specific field values that are not precalculated. If you have any questions, please contact ETW Energy.
              </strong>
            </Typography>
          )}
        </Box>
      )}
      <Box className="tw-flex tw-flex-col tw-gap-4 tw-w-6/12 tw-mb-4">
        <TextField
          label="Sales Rep Name"
          value={us_repName}
          onChange={(e) => us_setRepName(e.target.value)}
        />
        <TextField
          label="Sales Rep Email"
          value={us_repEmail}
          onChange={(e) => us_setRepEmail(e.target.value)}
        />
        <TextField
          label="Sales Rep Phone"
          value={us_repPhone}
          onChange={(e) => us_setRepPhone(e.target.value)}
        />
      </Box>
      <StyledSection>{rJSX_CreateDynamicForm()}</StyledSection>
      {us_isAdmin && (
        <StyledSection>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <StyledLabel variant="h6">Save Preset</StyledLabel>
            <Switch
              checked={us_savePreset}
              onChange={() => us_setSavePreset(!us_savePreset)}
              color="primary"
              className="tw-mb-2"
              disabled={us_selectedPreset !== 'None'}
            />
          </Box>
          <Typography
            variant="caption"
            className="tw-text-gray_400"
          >
            Presets allow you to save the majority of your form data and load it later.
          </Typography>
        </StyledSection>
      )}
      <Box className="tw-flex tw-justify-center tw-mt-4">
        <StyledButton
          variant="contained"
          color="primary"
          onClick={() => {
            const formSubmissionBundle: TsInterface_FormSubmissionBundle = {
              us_formType,
              ur_leaseForm,
              ur_cashForm,
              us_companyNameURL,
              us_setSnackbar,
              us_setValidationsPassed,
              us_savePreset,
              us_repName,
              us_repEmail,
            }
            handleFormSubmit(formSubmissionBundle)
          }}
        >
          Submit
        </StyledButton>
      </Box>
      <Snackbar
        open={us_snackbar.open}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={us_snackbar.severity}
          sx={{ width: '100%' }}
        >
          {us_snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}
