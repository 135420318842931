///////////////////////////////
// Description
///////////////////////////////

///////////////////////////////
// Imports
///////////////////////////////
import { roofTypeOptions } from 'app/pages/projects/forms/project_home_details'
import { returnUpdateArrayForV2QuoteApproval } from 'app/pages/projects/v2_services/additional_work_task_creation'
import { DatabaseRef_SalesPartner_Document } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_SalesPartner_InvoiceRates_Task_Document } from 'rfbp_aux/services/database_endpoints/finances/invoice_rates'
import {
  DatabaseRef_ProjectAdditionalWorkInvoice_Document,
  DatabaseRef_ProjectAdditionalWorkInvoice_Logs_Document,
} from 'rfbp_aux/services/database_endpoints/finances/project_additional_work_invoices'
import {
  DatabaseRef_ProjectBaseInvoice_Document,
  DatabaseRef_ProjectBaseInvoice_Logs_Document,
} from 'rfbp_aux/services/database_endpoints/finances/project_base_invoices'
import { DatabaseRef_ProjectFinance_Document, DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  DatabaseRef_AllProjectTasksFromSameWorkflowTask_Query,
  DatabaseRef_TaskLinkedToAdditionalWorkInvoice_Query,
  DatabaseRef_Task_Document,
} from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseSetMergeDocument,
  DatabaseStagedBatchUpdate,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { generateHtmlForEmailFromTemplateObject, TsInterface_EmailTemplateObject } from 'rfbp_core/services/emails'
import { dynamicSort, formatCurrency, generateRandomString, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import { AdditionalWorkInvoiceViewDialog } from '../dialogs/additional_work_invoice_view'
import { ApproveAdditionalWorkInvoiceDialog } from '../dialogs/approve_additional_work_quote_dialog'
import { ApproveBaseInvoiceDialog } from '../dialogs/approve_base_invoice'
import { BillAdditionalWorkInvoiceDialog } from '../dialogs/bill_additional_work_invoice'
import { BillBaseInvoiceDialog } from '../dialogs/bill_base_invoice'
import { projectInvoicePriceTypeOptions } from '../tables/project_base_pricing'
import { downloadAdditionalWorkQuotePDF, downloadBasePricingPDF, returnProjectBasePricingLineItems } from './invoice_pdf_templates'

///////////////////////////////
// Typescript
///////////////////////////////

export interface TsInterface_ProjectData {
  system_size_dc?: number
  system_panel_quantity?: number
  location_distance_from_warehouse?: number
  system_max_roof_pitch?: number
  system_storage_quantity?: number
  home_roof_type?: string
  // roof_replacement?: boolean
  // roof_size_sqft?: number
}

///////////////////////////////
// Variables
///////////////////////////////

const invoiceEmailTemplate: TsInterface_EmailTemplateObject = {
  BODY_DIV: {
    element_type: 'div',
    style: {
      'box-sizing': 'border-box',
      'width': '100%',
      'background': '#eeeeee',
      'padding': '16px',
    },
    contents: {
      CARD_DIV: {
        element_type: 'div',
        style: {
          'max-width': '600px',
          'background': '#ffffff',
          'border-radius': '8px',
          'box-shadow': '0 4px 8px rgba(0, 0, 0, 0.1)',
          'margin': '0 auto',
          'overflow': 'hidden',
        },
        contents: {
          HEADER_DIV: {
            element_type: 'div',
            style: {
              'box-sizing': 'border-box',
              'background': 'rgb(25, 30, 33)',
              'font-size': '20px',
              'width': '100%',
              'min-height': '70px',
              'padding': '8px',
              'text-align': 'center',
            },
            contents: {
              LOGO_IMG: {
                element_type: 'img',
                src_type: 'static',
                src: 'https://firebasestorage.googleapis.com/v0/b/etw-energy.appspot.com/o/global%2Fetw_logo_narrow.png?alt=media&token=13b9e254-6c87-43a2-82b8-6f65cc1a0250',
                alt: 'logo.png',
                width: '80px',
                height: 'auto',
              },
            },
          },
          CONTENT_DIV: {
            element_type: 'div',
            style: {
              padding: '16px',
            },
            contents: {
              INVOICE1: {
                element_type: 'p',
                text_type: 'static',
                text: 'Invoice from ETW Energy LLC',
                style: {
                  'font-size': '16px',
                  'margin-bottom': '4px',
                  'color': 'rgba(0, 0, 0, 0.87)',
                },
              },
              TOTAL: {
                element_type: 'p',
                text_type: 'dynamic',
                data_object_key: 'invoice',
                data_object_prop_key: 'total',
                style: {
                  'font-size': '36px',
                  'font-weight': 'bold',
                  'margin-top': '0px',
                  'margin-bottom': '4px',
                  'color': 'rgba(0, 0, 0, 0.87)',
                },
                // prefix_text: 'Hi ',
                // suffix_text: ',',
              },
              INVOICE2: {
                element_type: 'p',
                text_type: 'static',
                text: 'See the attached invoice for full details.',
                style: {
                  'font-size': '16px',
                  'margin-bottom': '4px',
                  'color': 'rgba(0, 0, 0, 0.87)',
                },
              },
              INVOICE3: {
                element_type: 'p',
                text_type: 'static',
                text: 'If you have any questions, please contact us at support@etwenergy.com.',
                style: {
                  'font-size': '16px',
                  'margin-bottom': '4px',
                  'color': 'rgba(0, 0, 0, 0.87)',
                },
              },
            },
          },
        },
      },
    },
  },
}

const returnBaseInvoicePricing = (
  clientKey: string,
  salesPartnerKey: string,
  workflowKey: string,
  regionKey: string,
  additionalData: TsInterface_ProjectData,
): Promise<TsInterface_UnspecifiedObject> => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && salesPartnerKey != null && workflowKey != null && regionKey != null) {
      let baseRateKey = 'BASE_RATES_' + workflowKey
      DatabaseGetDocument(DatabaseRef_SalesPartner_InvoiceRates_Task_Document(clientKey, salesPartnerKey, baseRateKey))
        .then((res_DGD) => {
          let baseRates = res_DGD.data
          // Get Base Pricing
          let basePrice = 0
          let adderPrice = 0
          let order: number = 0
          let invoiceBaseRateLineItems: TsInterface_UnspecifiedObject = {}
          let invoiceAdderLineItems: TsInterface_UnspecifiedObject = {}
          let foundMatchingBaseRates: boolean = false
          // Base Rates
          if (baseRates != null && baseRates['use_region_override'] != null && baseRates['use_region_override'][regionKey] === true) {
            if (baseRates['base_rates_' + regionKey] != null && objectToArray(baseRates['base_rates_' + regionKey]).length > 0) {
              invoiceBaseRateLineItems = baseRates['base_rates_' + regionKey]
              foundMatchingBaseRates = true
            }
          } else {
            if (baseRates != null && baseRates['base_rates_default'] != null && objectToArray(baseRates['base_rates_default']).length > 0) {
              invoiceBaseRateLineItems = baseRates['base_rates_default']
              foundMatchingBaseRates = true
            }
          }
          // Loop through and add order prop to each base rate line item
          for (let loopLineItemKey in invoiceBaseRateLineItems) {
            invoiceBaseRateLineItems[loopLineItemKey].order = order
            order++
          }
          // Distance Adder
          if (baseRates != null && baseRates['use_region_override'] != null && baseRates['use_region_override'][regionKey] === true) {
            if (baseRates['adders_distance' + regionKey] != null && objectToArray(baseRates['adders_distance' + regionKey]).length > 0) {
              let sortedDistances = objectToArray(baseRates['adders_distance' + regionKey]).sort(dynamicSort('cutoff_miles', 'desc'))
              let foundQualifiedDistance: boolean = false
              for (let loopDistanceAdderIndex in sortedDistances) {
                let loopDistanceAdder = sortedDistances[loopDistanceAdderIndex]
                if (
                  foundQualifiedDistance === false &&
                  additionalData != null &&
                  additionalData.location_distance_from_warehouse != null &&
                  additionalData.location_distance_from_warehouse >= loopDistanceAdder.cutoff_miles
                ) {
                  foundQualifiedDistance = true
                  invoiceAdderLineItems['distance_adder'] = {
                    price: loopDistanceAdder.price,
                    price_type: loopDistanceAdder.price_type,
                    key: 'distance_adder',
                    name: 'Distance Adder',
                    order: order,
                  }
                  order++
                  break
                }
              }
            }
          } else {
            if (baseRates != null && baseRates['adders_distance_default'] != null && objectToArray(baseRates['adders_distance_default']).length > 0) {
              let sortedDistances = objectToArray(baseRates['adders_distance_default']).sort(dynamicSort('cutoff_miles', 'desc'))
              let foundQualifiedDistance: boolean = false
              for (let loopDistanceAdderIndex in sortedDistances) {
                let loopDistanceAdder = sortedDistances[loopDistanceAdderIndex]
                if (
                  foundQualifiedDistance === false &&
                  additionalData != null &&
                  additionalData.location_distance_from_warehouse != null &&
                  additionalData.location_distance_from_warehouse >= loopDistanceAdder.cutoff_miles
                ) {
                  foundQualifiedDistance = true
                  invoiceAdderLineItems['distance_adder'] = {
                    price: loopDistanceAdder.price,
                    price_type: loopDistanceAdder.price_type,
                    key: 'distance_adder',
                    name: 'Distance Adder',
                    order: order,
                  }
                  order++
                  break
                }
              }
            }
          }
          // Roof Pitch Adder
          if (baseRates != null && baseRates['use_region_override'] != null && baseRates['use_region_override'][regionKey] === true) {
            if (baseRates['adders_pitch' + regionKey] != null && objectToArray(baseRates['adders_pitch' + regionKey]).length > 0) {
              let sortedPitches = objectToArray(baseRates['adders_pitch' + regionKey]).sort(dynamicSort('cutoff_pitch', 'desc'))
              let foundQualifiedPitch: boolean = false
              for (let loopPitchAdderIndex in sortedPitches) {
                let loopPitchAdder = sortedPitches[loopPitchAdderIndex]
                if (
                  foundQualifiedPitch === false &&
                  additionalData != null &&
                  additionalData.system_max_roof_pitch != null &&
                  additionalData.system_max_roof_pitch >= loopPitchAdder.cutoff_pitch
                ) {
                  foundQualifiedPitch = true
                  invoiceAdderLineItems['roof_pitch_adder'] = {
                    price: loopPitchAdder.price,
                    price_type: loopPitchAdder.price_type,
                    key: 'roof_pitch_adder',
                    name: 'Roof Pitch Adder',
                    order: order,
                  }
                  order++
                  break
                }
              }
            }
          } else {
            if (baseRates != null && baseRates['adders_pitch_default'] != null && objectToArray(baseRates['adders_pitch_default']).length > 0) {
              let sortedPitches = objectToArray(baseRates['adders_pitch_default']).sort(dynamicSort('cutoff_pitch', 'desc'))
              let foundQualifiedPitch: boolean = false
              for (let loopPitchAdderIndex in sortedPitches) {
                let loopPitchAdder = sortedPitches[loopPitchAdderIndex]
                if (
                  foundQualifiedPitch === false &&
                  additionalData != null &&
                  additionalData.system_max_roof_pitch != null &&
                  additionalData.system_max_roof_pitch >= loopPitchAdder.cutoff_pitch
                ) {
                  foundQualifiedPitch = true
                  invoiceAdderLineItems['roof_pitch_adder'] = {
                    price: loopPitchAdder.price,
                    price_type: loopPitchAdder.price_type,
                    key: 'roof_pitch_adder',
                    name: 'Roof Pitch Adder',
                    order: order,
                  }
                  order++
                  break
                }
              }
            }
          }
          // Battery Adder
          if (baseRates != null && baseRates['use_region_override'] != null && baseRates['use_region_override'][regionKey] === true) {
            if (baseRates['adders_battery' + regionKey] != null && objectToArray(baseRates['adders_battery' + regionKey]).length > 0) {
              let sortedBatteries = objectToArray(baseRates['adders_battery' + regionKey]).sort(dynamicSort('cutoff_battery', 'desc'))
              let foundQualifiedBattery: boolean = false
              for (let loopBatteryAdderIndex in sortedBatteries) {
                let loopBatteryAdder = sortedBatteries[loopBatteryAdderIndex]
                if (
                  foundQualifiedBattery === false &&
                  additionalData != null &&
                  additionalData.system_storage_quantity != null &&
                  additionalData.system_storage_quantity >= loopBatteryAdder.cutoff_battery
                ) {
                  foundQualifiedBattery = true
                  invoiceAdderLineItems['battery_adder'] = {
                    price: loopBatteryAdder.price,
                    price_type: loopBatteryAdder.price_type,
                    key: 'battery_adder',
                    name: 'Battery Adder',
                    order: order,
                  }
                  order++
                  break
                }
              }
            }
          } else {
            if (baseRates != null && baseRates['adders_battery_default'] != null && objectToArray(baseRates['adders_battery_default']).length > 0) {
              let sortedBatteries = objectToArray(baseRates['adders_battery_default']).sort(dynamicSort('cutoff_battery', 'desc'))
              let foundQualifiedBattery: boolean = false
              for (let loopBatteryAdderIndex in sortedBatteries) {
                let loopBatteryAdder = sortedBatteries[loopBatteryAdderIndex]
                if (
                  foundQualifiedBattery === false &&
                  additionalData != null &&
                  additionalData.system_storage_quantity != null &&
                  additionalData.system_storage_quantity >= loopBatteryAdder.cutoff_battery
                ) {
                  foundQualifiedBattery = true
                  invoiceAdderLineItems['battery_adder'] = {
                    price: loopBatteryAdder.price,
                    price_type: loopBatteryAdder.price_type,
                    key: 'battery_adder',
                    name: 'Battery Adder',
                    order: order,
                  }
                  order++
                  break
                }
              }
            }
          }
          // Roof Type Adder
          if (baseRates != null && baseRates['use_region_override'] != null && baseRates['use_region_override'][regionKey] === true) {
            if (baseRates['adders_roof_type' + regionKey] != null && objectToArray(baseRates['adders_roof_type' + regionKey]).length > 0) {
              if (
                additionalData != null &&
                additionalData.home_roof_type != null &&
                baseRates['adders_roof_type' + regionKey][additionalData.home_roof_type] != null
              ) {
                invoiceAdderLineItems['roof_type_adder'] = {
                  price: baseRates['adders_roof_type' + regionKey][additionalData.home_roof_type].price,
                  price_type: baseRates['adders_roof_type' + regionKey][additionalData.home_roof_type].price_type,
                  key: 'roof_type_adder',
                  name: 'Roof Type Adder',
                  order: order,
                }
                order++
              }
            }
          } else {
            if (baseRates != null && baseRates['adders_roof_type_default'] != null && objectToArray(baseRates['adders_roof_type_default']).length > 0) {
              if (
                additionalData != null &&
                additionalData.home_roof_type != null &&
                baseRates['adders_roof_type_default'][additionalData.home_roof_type] != null
              ) {
                invoiceAdderLineItems['roof_type_adder'] = {
                  price: baseRates['adders_roof_type_default'][additionalData.home_roof_type].price,
                  price_type: baseRates['adders_roof_type_default'][additionalData.home_roof_type].price_type,
                  key: 'roof_type_adder',
                  name: 'Roof Type Adder',
                  order: order,
                }
                order++
              }
            }
          }
          // If there are valid base rates
          if (foundMatchingBaseRates === true) {
            // Base Pricing
            let basePricing: TsInterface_UnspecifiedObject = {}
            for (let loopLineItemKey in invoiceBaseRateLineItems) {
              let loopLineItem = invoiceBaseRateLineItems[loopLineItemKey]
              if (loopLineItem != null) {
                let calculatedPrice = null
                if (
                  loopLineItem.price_type != null &&
                  projectInvoicePriceTypeOptions != null &&
                  projectInvoicePriceTypeOptions[loopLineItem.price_type] != null &&
                  projectInvoicePriceTypeOptions[loopLineItem.price_type].calculate != null
                ) {
                  calculatedPrice = projectInvoicePriceTypeOptions[loopLineItem.price_type].calculate(loopLineItem, additionalData)
                }
                if (calculatedPrice != null) {
                  basePricing[loopLineItemKey] = {
                    key: loopLineItemKey,
                    name: loopLineItem.name,
                    calculated_price: calculatedPrice,
                    order: loopLineItem.order,
                  }
                  basePrice += calculatedPrice
                }
              }
            }
            // Adder Pricing
            let adderPricing: TsInterface_UnspecifiedObject = {}
            for (let loopLineItemKey in invoiceAdderLineItems) {
              let loopLineItem = invoiceAdderLineItems[loopLineItemKey]
              if (loopLineItem != null) {
                let calculatedPrice = null
                if (
                  loopLineItem.price_type != null &&
                  projectInvoicePriceTypeOptions != null &&
                  projectInvoicePriceTypeOptions[loopLineItem.price_type] != null &&
                  projectInvoicePriceTypeOptions[loopLineItem.price_type].calculate != null
                ) {
                  calculatedPrice = projectInvoicePriceTypeOptions[loopLineItem.price_type].calculate(loopLineItem, additionalData)
                }
                if (calculatedPrice != null) {
                  adderPricing[loopLineItemKey] = {
                    key: loopLineItemKey,
                    name: loopLineItem.name,
                    calculated_price: calculatedPrice,
                    order: loopLineItem.order,
                  }
                  adderPrice += calculatedPrice
                }
              }
            }
            // Resolve
            resolve({
              success: true,
              approval_type: getProp(baseRates, 'approval_type', null),
              bill_to: getProp(baseRates, 'bill_to', null),
              billing_type: getProp(baseRates, 'billing_type', null),
              billing_times: getProp(baseRates, 'billing_times', null),
              base_pricing: basePricing,
              adder_pricing: adderPricing,
              base_price: basePrice,
              adder_price: adderPrice,
              total_price: basePrice + adderPrice,
            })
          } else {
            reject({
              success: false,
              error: {
                message: rLIB('Failed to return invoice pricing'),
                details: rLIB('Unable to find matching invoice rates'),
                code: 'ER-D-IP-RIP-01',
              },
            })
          }
        })
        .catch((rej_DGD) => {
          reject({
            success: false,
            error: {
              message: rLIB('Failed to return invoice pricing'),
              details: rLIB('Missing Required Parameters'),
              code: 'ER-D-IP-RIP-02',
            },
          })
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to return invoice pricing'),
          details: rLIB('Missing Required Parameters'),
          code: 'ER-D-IP-RIP-03',
        },
      })
    }
  })
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const verifyProjectHasRequiredData = (projectData: TsInterface_UnspecifiedObject) => {
  let result: TsInterface_UnspecifiedObject = {
    success: true,
    missing_fields: [],
    form_fields: {},
  }
  if (getProp(projectData, 'associated_task_workflow_key', null) == null) {
    result.missing_fields.push('Task Workflow')
    result.form_fields['associated_task_workflow_key'] = true
  }
  if (getProp(projectData, 'associated_sales_partner_key', null) == null) {
    result.missing_fields.push('Sales Partner')
    result.form_fields['associated_sales_partner_key'] = true
  }
  if (getProp(projectData, 'system_size_dc', null) == null) {
    result.missing_fields.push('System Size')
    result.form_fields
  }
  if (getProp(projectData, 'system_panel_quantity', null) == null) {
    result.missing_fields.push('System Panel Quantity')
    result.form_fields['system_panel_quantity'] = true
  }
  if (getProp(projectData, 'location_distance_from_warehouse', null) == null) {
    result.missing_fields.push('Miles From Warehouse')
    result.form_fields['location_distance_from_warehouse'] = true
  }
  if (getProp(projectData, 'system_max_roof_pitch', null) == null) {
    result.missing_fields.push('System Max Roof Pitch')
    result.form_fields['system_max_roof_pitch'] = true
  }
  if (getProp(projectData, 'system_storage_quantity', null) == null) {
    result.missing_fields.push('System Storage Quantity')
    result.form_fields['system_storage_quantity'] = true
  }
  if (getProp(projectData, 'home_roof_type', null) == null) {
    result.missing_fields.push('Home Roof Type')
  } else if (roofTypeOptions != null && roofTypeOptions[getProp(projectData, 'home_roof_type', null)] == null) {
    result.missing_fields.push('Invalid Home Roof Type')
    result.form_fields['home_roof_type'] = true
  }
  if (result.missing_fields.length > 0) {
    result.success = false
  }
  return result
}

export const generateProjectInvoice = (
  us_rootProject: TsInterface_UnspecifiedObject,
  uc_RootData_ClientKey: any,
  pr_projectKey: string,
  uc_setRootData_ClientKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
) => {
  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    .then((res_GCK) => {
      let clientKey = res_GCK.clientKey
      let salesPartnerKey = us_rootProject.associated_sales_partner_key
      let workflowKey = us_rootProject.associated_task_workflow_key
      let regionKey = us_rootProject.associated_region_key
      // Verify that the project has all required data
      let verifyProjectHasRequiredDataResult = verifyProjectHasRequiredData(us_rootProject)
      if (verifyProjectHasRequiredDataResult.success === true) {
        returnBaseInvoicePricing(clientKey, salesPartnerKey, workflowKey, regionKey, {
          system_size_dc: us_rootProject.system_size_dc,
          system_panel_quantity: us_rootProject.system_panel_quantity,
          location_distance_from_warehouse: us_rootProject.location_distance_from_warehouse,
          system_max_roof_pitch: us_rootProject.system_max_roof_pitch,
          system_storage_quantity: us_rootProject.system_storage_quantity,
          home_roof_type: us_rootProject.home_roof_type,
        })
          .then((res_RIP) => {
            let projectUpdateObject: TsInterface_UnspecifiedObject = {
              timestamp_invoice_generated: new Date(),
              invoice_bill_to: getProp(res_RIP, 'bill_to', null),
              invoice_billing_type: getProp(res_RIP, 'billing_type', null),
            }
            if (getProp(us_rootProject, 'invoice_status', null) === 'missing') {
              projectUpdateObject.invoice_status = 'unapproved'
            }
            // Update Project
            let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
              {
                type: 'setMerge',
                ref: DatabaseRef_Project_Document(clientKey, pr_projectKey),
                data: projectUpdateObject,
              },
              {
                type: 'setOverwrite',
                ref: DatabaseRef_ProjectFinance_Document(clientKey, pr_projectKey, 'base'),
                data: res_RIP,
              },
            ]
            DatabaseBatchUpdate(updateArray)
              .then((res_DBU) => {
                if (getProp(res_RIP, 'approval_type', null) === 'auto_approved') {
                  approveProjectBaseInvoice(clientKey, pr_projectKey, true)
                }
                // Nothing
              })
              .catch((rej_DBU) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
              })
          })
          .catch((rej_RIP) => {
            uc_setUserInterface_ErrorDialogDisplay({
              display: true,
              error: {
                message: rLIB('Failed to generate base invoice'),
                details: rej_RIP.error.message,
                code: rej_RIP.error.code,
              },
            })
          })
      } else {
        let error = {
          message: rLIB('Failed to generate base invoice'),
          details: 'Project missing the following fields: ' + verifyProjectHasRequiredDataResult.missing_fields.join(', '),
          code: 'ER-D-IPF-GPI-01',
        }
        uc_setUserInterface_ErrorDialogDisplay({
          display: true,
          error: error,
        })
      }
    })
    .catch((rej_GCK) => {
      uc_setUserInterface_ErrorDialogDisplay({
        display: true,
        error: rej_GCK.error,
      })
    })
}

export const openApproveBaseInvoiceDialog = (clientKey: string, projectKey: string, uc_setUserInterface_CustomDialogDisplay: any) => {
  uc_setUserInterface_CustomDialogDisplay({
    display: true,
    dialog: {
      dialog_jsx: (
        <ApproveBaseInvoiceDialog
          clientKey={clientKey}
          projectKey={projectKey}
        />
      ),
      settings: {
        max_width: 'lg',
      },
    },
  })
}

export const approveProjectBaseInvoice = (clientKey: string, projectKey: string, showError: boolean) => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectKey != null) {
      // Get Project and Project Base Data
      DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
        .then((res_GDD) => {
          let project = res_GDD.data
          DatabaseGetDocument(DatabaseRef_ProjectFinance_Document(clientKey, projectKey, 'base'))
            .then((res_GDD) => {
              let projectFinances = res_GDD.data
              if (projectFinances != null && projectFinances.billing_times != null && objectToArray(projectFinances.billing_times).length > 0) {
                // Loop through and get tasks on the project for each billing event task
                let promiseArray1: TsType_UnknownPromise[] = []
                let billingEventTasks: TsInterface_UnspecifiedObject = {}
                for (let loopTaskKey in projectFinances.billing_times) {
                  billingEventTasks[loopTaskKey] = {
                    key: loopTaskKey,
                    name: getProp(projectFinances.billing_times[loopTaskKey], 'associated_task_name', null),
                    invoice_suffix: getProp(projectFinances.billing_times[loopTaskKey], 'invoice_suffix', null),
                    found_task: false,
                    task_status: null,
                    task: {},
                  }
                  promiseArray1.push(
                    DatabaseGetCollection(DatabaseRef_AllProjectTasksFromSameWorkflowTask_Query(clientKey, projectKey, loopTaskKey)).then((res_DGC) => {
                      let matchingTasks = res_DGC.data
                      if (matchingTasks != null && objectToArray(matchingTasks).length > 0) {
                        billingEventTasks[loopTaskKey]['found_task'] = true
                        billingEventTasks[loopTaskKey]['task_status'] = getProp(objectToArray(matchingTasks)[0], 'status', null)
                        billingEventTasks[loopTaskKey]['task'] = objectToArray(matchingTasks)[0]
                      }
                    }),
                  )
                }
                // After Tasks Loaded
                // let projectFinanceUpdateObject: TsInterface_UnspecifiedObject = {}
                Promise.all(promiseArray1).finally(() => {
                  // Check if all tasks are found
                  let allTasksFound = true
                  let missingTasks: string[] = []
                  for (let loopTaskKey in billingEventTasks) {
                    if (billingEventTasks[loopTaskKey]['found_task'] === false) {
                      allTasksFound = false
                      missingTasks.push(billingEventTasks[loopTaskKey]['name'])
                    }
                  }
                  if (allTasksFound === true) {
                    let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_Project_Document(clientKey, projectKey),
                        data: { invoice_status: 'approved' },
                      },
                    ]
                    let usingDeletedTask = false
                    let deletedTasks: string[] = []
                    // Loop through and create base_project_invoices for each billing event task
                    for (let loopTaskKey in billingEventTasks) {
                      let projectBaseInvoiceUpdateObject: TsInterface_UnspecifiedObject = {}
                      let invoiceStatus = 'approved'
                      if (billingEventTasks[loopTaskKey]['task_status'] === 'completed') {
                        invoiceStatus = 'completed'
                      } else if (billingEventTasks[loopTaskKey]['task_status'] === 'deleted') {
                        invoiceStatus = 'approved'
                        usingDeletedTask = true
                        deletedTasks.push(billingEventTasks[loopTaskKey]['name'])
                      }
                      // Get Line Items and Total
                      let projectBaseInvoiceKey = projectKey + '_' + loopTaskKey
                      let invoiceLineItems = returnProjectBasePricingLineItems(projectFinances, loopTaskKey, project.id_number)
                      let invoiceTotal = invoiceLineItems.reduce((acc, curr) => acc + curr.amount, 0)
                      // Update Object
                      projectBaseInvoiceUpdateObject['key'] = projectBaseInvoiceKey
                      projectBaseInvoiceUpdateObject['associated_project_key'] = projectKey
                      projectBaseInvoiceUpdateObject['associated_sales_partner_key'] = getProp(project, 'associated_sales_partner_key', null)
                      projectBaseInvoiceUpdateObject['associated_region_key'] = getProp(project, 'associated_region_key', null)
                      projectBaseInvoiceUpdateObject['associated_finance_partner_key'] = getProp(project, 'associated_finance_partner_key', null)
                      projectBaseInvoiceUpdateObject['associated_task_workflow_key'] = getProp(project, 'associated_task_workflow_key', null)
                      projectBaseInvoiceUpdateObject['associated_project_job_code'] = project.id_number
                      projectBaseInvoiceUpdateObject['invoice_id_number'] = project.id_number + '-' + billingEventTasks[loopTaskKey]['invoice_suffix']
                      projectBaseInvoiceUpdateObject['invoice_suffix'] = billingEventTasks[loopTaskKey]['invoice_suffix']
                      projectBaseInvoiceUpdateObject['associated_task_key'] = billingEventTasks[loopTaskKey]['key']
                      projectBaseInvoiceUpdateObject['associated_task_name'] = billingEventTasks[loopTaskKey]['name']
                      projectBaseInvoiceUpdateObject['associated_task_status'] = getProp(billingEventTasks[loopTaskKey], 'task_status', null)
                      projectBaseInvoiceUpdateObject['status'] = invoiceStatus
                      projectBaseInvoiceUpdateObject['line_items'] = invoiceLineItems
                      projectBaseInvoiceUpdateObject['total_price'] = invoiceTotal
                      projectBaseInvoiceUpdateObject['timestamp_created'] = new Date()
                      updateArray.push({
                        type: 'setMerge',
                        ref: DatabaseRef_ProjectBaseInvoice_Document(clientKey, projectBaseInvoiceKey),
                        data: projectBaseInvoiceUpdateObject,
                      })
                    }
                    updateArray.push({
                      type: 'setOverwrite',
                      ref: DatabaseRef_ProjectFinance_Document(clientKey, projectKey, 'base'),
                      data: {
                        approval_evidence_files: getProp(projectFinances, 'approval_evidence_files', {}),
                        approval_evidence_uploaded: getProp(projectFinances, 'approval_evidence_uploaded', null),
                        approval_type: getProp(projectFinances, 'approval_type', null),
                        timestamp_converted_to_invoice: new Date(),
                      },
                    })
                    // Batch Update
                    DatabaseBatchUpdate(updateArray)
                      .then((res_DBU) => {
                        if (usingDeletedTask === false || showError === false) {
                          resolve(res_DBU)
                        } else {
                          reject({
                            success: false,
                            error: {
                              message: rLIB('Invoices created but some milestone tasks have been deleted'),
                              details: (
                                <>
                                  {rLIB('The following tasks are deleted and will not trigger billing without intervention:')} {deletedTasks.join(', ')}
                                </>
                              ),
                              code: 'ER-D-IPF-APBI-01',
                            },
                          })
                        }
                      })
                      .catch((rej_DBU) => {
                        reject(rej_DBU)
                      })
                  } else {
                    reject({
                      success: false,
                      error: {
                        message: rLIB('Failed to approve project base invoice'),
                        details: rLIB('The project is missing the following tasks:') + ' ' + missingTasks.join(', '),
                        code: 'ER-D-IPF-APBI-02',
                      },
                    })
                  }
                })
              } else {
                reject({
                  success: false,
                  error: {
                    message: rLIB('Failed to approve project base invoice'),
                    details: rLIB('No billing task events set up for this project'),
                    code: 'ER-D-IPF-APBI-03',
                  },
                })
              }
            })
            .catch((rej_GDD) => {
              reject(rej_GDD)
            })
        })
        .catch((rej_GDD) => {
          reject(rej_GDD)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to approve project base invoice'),
          details: rLIB('Invalid client or project key'),
          code: 'ER-D-IPF-APBI-04',
        },
      })
    }
  })
}

export const openSendProjectBaseInvoiceEmailDialog = (
  clientKey: string,
  projectKey: string,
  taskKey: string,
  invoiceKey: string,
  invoice: TsInterface_UnspecifiedObject,
  uc_setUserInterface_CustomDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
) => {
  let promiseArray1: TsType_UnknownPromise[] = []
  let project: TsInterface_UnspecifiedObject = {}
  let salesPartner: TsInterface_UnspecifiedObject = {}
  // Get Project Data
  DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
    .then((res_GDD) => {
      project = res_GDD.data
      let invoiceKey = projectKey + '_' + taskKey
      DatabaseGetDocument(DatabaseRef_ProjectBaseInvoice_Document(clientKey, invoiceKey))
        .then((res_GDD) => {
          let loadedInvoice = res_GDD.data
          let emailAndBillingContactInfo: TsInterface_UnspecifiedObject = {}
          if (project.invoice_bill_to === 'sales_partner' && project.associated_sales_partner_key != null) {
            // Get Sales Partner Data
            promiseArray1.push(
              DatabaseGetDocument(DatabaseRef_SalesPartner_Document(clientKey, project.associated_sales_partner_key as string)).then((res_GDD) => {
                salesPartner = res_GDD.data
                emailAndBillingContactInfo = {
                  email: getProp(salesPartner, 'base_billing_email', null),
                  location_address: getProp(salesPartner, 'location_address', null),
                  location_city: getProp(salesPartner, 'location_city', null),
                  location_state: getProp(salesPartner, 'location_state', null),
                  location_zip: getProp(salesPartner, 'location_zip', null),
                }
              }),
            )
          } else if (project.invoice_bill_to === 'customer' && project.associated_customer_email != null) {
            emailAndBillingContactInfo = {
              email: getProp(project, 'associated_customer_email', null),
              location_address: getProp(project, 'location_address', null),
              location_city: getProp(project, 'location_city', null),
              location_state: getProp(project, 'location_state', null),
              location_zip: getProp(project, 'location_zip', null),
            }
          }
          Promise.all(promiseArray1).finally(() => {
            uc_setUserInterface_CustomDialogDisplay({
              display: true,
              dialog: {
                dialog_jsx: (
                  <BillBaseInvoiceDialog
                    clientKey={clientKey}
                    taskKey={taskKey}
                    invoiceKey={invoiceKey}
                    projectKey={projectKey}
                    project={project}
                    emailAndBillingContactInfo={emailAndBillingContactInfo}
                    invoice={loadedInvoice}
                  />
                ),
                settings: {
                  max_width: 'lg',
                },
              },
            })
          })
        })
        .catch((rej_GDD) => {
          console.error(rej_GDD)
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: rej_GDD.error,
          })
        })
    })
    .catch((rej_GDD) => {
      console.error(rej_GDD)
      uc_setUserInterface_ErrorDialogDisplay({
        display: true,
        error: rej_GDD.error,
      })
    })
}

export const addLineItemToProjectBaseInvoice = (
  clientKey: string,
  invoiceKey: string,
  lineItem: TsInterface_UnspecifiedObject,
  jobCode: string,
  lineItemType: 'line_item' | 'post_billing_correction',
  uc_RootData_GlobalUser: TsInterface_UnspecifiedObject,
  uc_RootData_ClientUser: TsInterface_UnspecifiedObject,
  process: 'save' | 'return_update_object',
) => {
  return new Promise((resolve, reject) => {
    // Get existing invoice
    let existingInvoice: TsInterface_UnspecifiedObject = {}
    DatabaseGetDocument(DatabaseRef_ProjectBaseInvoice_Document(clientKey, invoiceKey))
      .then((res_GDD) => {
        existingInvoice = res_GDD.data
        let newLineItem = {
          description: getProp(lineItem, 'description', null),
          amount: getProp(lineItem, 'amount', null),
          notes: getProp(lineItem, 'notes', null),
          job_code: jobCode,
          line_item_type: lineItemType,
        }
        let newTotalPrice = getProp(existingInvoice, 'total_price', 0) + parseFloat(lineItem['amount'])
        let newLogKey = new Date().getTime().toString() + '_' + generateRandomString(8, null)
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
          {
            type: 'setMerge',
            ref: DatabaseRef_ProjectBaseInvoice_Document(clientKey, invoiceKey),
            data: {
              line_items: [...getProp(existingInvoice, 'line_items', []), newLineItem],
              total_price: newTotalPrice,
            },
          },
          {
            type: 'setMerge',
            ref: DatabaseRef_ProjectBaseInvoice_Logs_Document(clientKey, invoiceKey, newLogKey),
            data: {
              timestamp_created: new Date(),
              action: 'Line Item Added -' + newLineItem['description'] + ' (' + formatCurrency(newLineItem['amount']) + ')',
              associated_user_key: getProp(uc_RootData_GlobalUser, 'key', null),
              associated_user_name: getProp(uc_RootData_ClientUser, 'name', null),
            },
          },
        ]
        if (process === 'save') {
          // Batch Update
          DatabaseBatchUpdate(updateArray)
            .then((res_DBU) => {
              resolve(res_DBU)
            })
            .catch((rej_DBU) => {
              reject(rej_DBU)
            })
        } else {
          resolve({ success: true, data: updateArray })
        }
      })
      .catch((rej_GDD) => {
        console.error(rej_GDD)
        reject(rej_GDD)
      })
  })
}

// TODO: change to cloud function???
export const sendProjectBaseInvoiceEmail = (clientKey: string, projectKey: string, taskKey: string, invoiceKey: string) => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectKey != null && taskKey != null && invoiceKey != null) {
      downloadBasePricingPDF(clientKey, projectKey, taskKey, 'base64', 'invoice', {})
        .then((res_DBPP) => {
          DatabaseGetDocument(DatabaseRef_ProjectBaseInvoice_Document(clientKey, invoiceKey))
            .then((res_GDD) => {
              let promiseArray1: TsType_UnknownPromise[] = []
              let invoice: TsInterface_UnspecifiedObject = res_GDD.data
              let project: TsInterface_UnspecifiedObject = {}
              let salesPartner: TsInterface_UnspecifiedObject = {}
              // Get Project Data
              DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
                .then((res_GDD) => {
                  project = res_GDD.data
                  let emailAndBillingContactInfo: TsInterface_UnspecifiedObject = {}
                  if (project.invoice_bill_to === 'sales_partner' && project.associated_sales_partner_key != null) {
                    // Get Sales Partner Data
                    promiseArray1.push(
                      DatabaseGetDocument(DatabaseRef_SalesPartner_Document(clientKey, project.associated_sales_partner_key as string)).then((res_GDD) => {
                        salesPartner = res_GDD.data
                        emailAndBillingContactInfo = {
                          email: getProp(salesPartner, 'base_billing_email', null),
                        }
                      }),
                    )
                  } else if (project.invoice_bill_to === 'customer' && project.associated_customer_email != null) {
                    emailAndBillingContactInfo = {
                      email: getProp(project, 'associated_customer_email', null),
                    }
                  }
                  Promise.all(promiseArray1).finally(() => {
                    // TODO: TEMP
                    let projectJobCode = project.id_number
                    let invoiceLineItems = invoice.line_items
                    let invoiceTotal = invoiceLineItems.reduce((acc: number, curr: TsInterface_UnspecifiedObject) => acc + curr.amount, 0)
                    let invoiceEmailDataObject: TsInterface_UnspecifiedObject = {
                      invoice: {
                        total: formatCurrency(invoiceTotal),
                      },
                    }
                    // Send Email
                    cloudFunctionManageRequest('manageEmails', {
                      function: 'sendSendgridHtmlEmailWithAttachments',
                      to: emailAndBillingContactInfo.email,
                      subject: 'Your invoice from ETW Energy: ' + getProp(res_DBPP, 'invoice_id_number', projectJobCode),
                      html: generateHtmlForEmailFromTemplateObject(invoiceEmailTemplate, invoiceEmailDataObject),
                      cc: [], // TODO: Fill this in?
                      bcc: [],
                      attachments: [
                        {
                          content: getProp(res_DBPP, 'data', null),
                          filename: getProp(res_DBPP, 'file_name', 'Invoice ' + projectJobCode + '.pdf'),
                          type: 'application/pdf',
                          disposition: 'attachment',
                        },
                      ],
                    })
                      .then((res) => {
                        // Update Invoice
                        let invoiceUpdateObject = {
                          status: 'billed',
                          timestamp_invoice_billed: new Date(),
                        }
                        let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                          {
                            type: 'setMerge',
                            ref: DatabaseRef_ProjectBaseInvoice_Document(clientKey, invoiceKey),
                            data: invoiceUpdateObject,
                          },
                        ]
                        DatabaseBatchUpdate(updateArray)
                          .then((res_SMD) => {
                            resolve(res_SMD)
                          })
                          .catch((rej_SMD) => {
                            console.error('Error updating invoice:', rej_SMD)
                            reject(rej_SMD)
                          })
                      })
                      .catch((rej) => {
                        console.error('Error sending email:', rej)
                        reject(rej)
                      })
                  })
                })
                .catch((rej_GDD) => {
                  console.error(rej_GDD)
                  reject(rej_GDD)
                })
            })
            .catch((rej_GDD) => {
              console.error(rej_GDD)
              reject(rej_GDD)
            })
        })
        .catch((rej_DBPP) => {
          reject(rej_DBPP)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to send project base invoice email'),
          details: rLIB('Invalid client, project, task, or invoice key'),
          code: 'ER-D-IPF-SPBIE-01',
        },
      })
    }
  })
}

// Additional Work
export const createAdditionalWorkInvoice = (
  clientKey: string,
  projectKey: string,
  approvalType: string,
  billTo: 'sales_partner' | 'customer',
  billableReferenceNumber: string,
  formattedLineItems: TsInterface_UnspecifiedObject[],
  associatedTasks: TsInterface_UnspecifiedObject,
  newInvoiceKey: string,
  tasksToCreate: TsInterface_UnspecifiedObject,
  approverUserKey: string,
  approverUserName: string,
) => {
  return new Promise((resolve, reject) => {
    // Get Project Data
    let promiseArray1: Promise<any>[] = []
    let project: TsInterface_UnspecifiedObject = {}
    // Get Project Data
    promiseArray1.push(
      DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
        .then((res_DGD) => {
          project = res_DGD.data
        })
        .catch((rej_DGD) => {
          console.error(rej_DGD)
        }),
    )
    // After Data Loaded
    Promise.all(promiseArray1).finally(() => {
      // Update Object
      let invoiceTotal = formattedLineItems.reduce((acc, curr) => acc + curr.amount, 0)
      let updateObject: TsInterface_UnspecifiedObject = {
        approval_type: approvalType,
        associated_finance_partner_key: getProp(project, 'associated_finance_partner_key', null),
        associated_finance_partner_name: getProp(project, 'associated_finance_partner_name', null),
        associated_project_job_code: getProp(project, 'id_number', null),
        associated_project_key: projectKey,
        associated_region_key: getProp(project, 'associated_region_key', null),
        associated_region_name: getProp(project, 'associated_region_name', null),
        associated_sales_partner_key: getProp(project, 'associated_sales_partner_key', null),
        associated_sales_partner_name: getProp(project, 'associated_sales_partner_name', null),
        associated_tasks: associatedTasks,
        associated_task_workflow_key: getProp(project, 'associated_task_workflow_key', null),
        associated_tasks_form_data: tasksToCreate,
        bill_to: billTo,
        invoice_id_number: billableReferenceNumber,
        line_items: formattedLineItems,
        key: newInvoiceKey,
        status: 'unapproved',
        timestamp_created: new Date(),
        total_price: invoiceTotal,
      }
      DatabaseSetMergeDocument(DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, newInvoiceKey), updateObject)
        .then((res_DSMD) => {
          if (approvalType === 'auto_approved') {
            approveAdditionalWorkInvoice(clientKey, newInvoiceKey, approverUserKey, approverUserName)
              .then(() => {
                resolve(res_DSMD)
              })
              .catch((rej_AWI) => {
                console.error(rej_AWI)
                resolve(res_DSMD)
              })
          } else {
            resolve(res_DSMD)
          }
        })
        .catch((rej_DSMD) => {
          console.error(rej_DSMD)
          reject(rej_DSMD)
        })
    })
  })
}

export const addTasksToAdditionalWorkInvoice = (
  clientKey: string,
  projectKey: string,
  invoiceKey: string,
  formattedLineItems: TsInterface_UnspecifiedObject[],
  associatedTasks: TsInterface_UnspecifiedObject,
  tasksToCreate: TsInterface_UnspecifiedObject,
  approverUserKey: string,
  approverUserName: string,
) => {
  return new Promise((resolve, reject) => {
    let promiseArray1: Promise<any>[] = []
    // let project: TsInterface_UnspecifiedObject = {}
    let invoice: TsInterface_UnspecifiedObject = {}
    // Get Project Data
    // promiseArray1.push(
    //   DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
    //     .then((res_DGD) => {
    //       project = res_DGD.data
    //     })
    //     .catch((rej_DGD) => {
    //       console.error(rej_DGD)
    //     }),
    // )
    promiseArray1.push(
      DatabaseGetDocument(DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey))
        .then((res_DGD) => {
          invoice = res_DGD.data
        })
        .catch((rej_DGD) => {
          console.error(rej_DGD)
        }),
    )
    // After Data Loaded
    Promise.all(promiseArray1).finally(() => {
      // Make sure that the tasks being added
      let foundDuplicateTasks = false
      for (let loopTaskKey in tasksToCreate) {
        if (invoice.associated_tasks[loopTaskKey] != null) {
          foundDuplicateTasks = true
        }
      }
      if (foundDuplicateTasks === false) {
        // New Total
        let invoiceTotal = formattedLineItems.reduce((acc, curr) => acc + curr.amount, 0) + invoice.total_price
        // Get Task Update Array
        let invoiceUpdateObject: TsInterface_UnspecifiedObject = {
          associated_tasks: associatedTasks,
          associated_tasks_form_data: tasksToCreate,
          line_items: [...(invoice.line_items || []), ...formattedLineItems],
          total_price: invoiceTotal,
        }
        if (invoice.status === 'completed') {
          invoiceUpdateObject['status'] = 'approved'
        }
        let newLogKey = new Date().getTime().toString() + '_' + generateRandomString(8, null)
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
          {
            type: 'setMerge',
            ref: DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey),
            data: invoiceUpdateObject,
          },
          {
            type: 'setMerge',
            ref: DatabaseRef_ProjectAdditionalWorkInvoice_Logs_Document(clientKey, invoiceKey, newLogKey),
            data: {
              timestamp_created: new Date(),
              action:
                'New Tasks added to Additional Work Invoice: ' +
                Object.values(associatedTasks)
                  .map((task) => task.name)
                  .join(', '),
              associated_user_key: approverUserKey,
              associated_user_name: approverUserName,
            },
          },
        ]
        DatabaseBatchUpdate(updateArray)
          .then((res_DBU) => {
            // If Invoice has already been approved, we need to create the tasks
            if (invoice.status === 'approved') {
              returnUpdateArrayForV2QuoteApproval(clientKey, projectKey, invoiceKey, tasksToCreate, approverUserKey, approverUserName)
                .then((res_RUA) => {
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = getProp(res_RUA, 'updateArray', [])
                  let updateObject: TsInterface_UnspecifiedObject = {
                    associated_tasks: {},
                  }
                  // Update Task Status
                  for (let loopTaskKey in tasksToCreate) {
                    updateObject['associated_tasks'][loopTaskKey] = {
                      status: 'active',
                    }
                  }
                  updateArray.push({
                    type: 'setMerge',
                    ref: DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey),
                    data: updateObject,
                  })
                  DatabaseStagedBatchUpdate(updateArray)
                    .then((res_SMD) => {
                      resolve(res_SMD)
                    })
                    .catch((rej_SMD) => {
                      console.error(rej_SMD)
                      reject(rej_SMD)
                    })
                })
                .catch((rej_RUA) => {
                  console.error(rej_RUA)
                  reject(rej_RUA)
                })
            } else {
              resolve(res_DBU)
            }
          })
          .catch((rej_DBU) => {
            reject(rej_DBU)
          })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to add tasks to additional work invoice'),
            details: rLIB('Duplicate tasks found'),
            code: 'ER-D-IPF-ATTAWI-01',
          },
        })
      }
    })
  })
}

export const openAdditionalWorkInvoiceDialog = (clientKey: string, invoiceKey: string, uc_setUserInterface_CustomDialogDisplay: any) => {
  uc_setUserInterface_CustomDialogDisplay({
    display: true,
    dialog: {
      dialog_jsx: (
        <AdditionalWorkInvoiceViewDialog
          clientKey={clientKey}
          invoiceKey={invoiceKey}
        />
      ),
      settings: {
        max_width: 'lg',
      },
    },
  })
}

export const openApproveAdditionalWorkInvoiceDialog = (
  clientKey: string,
  projectKey: string,
  invoiceKey: string,
  uc_setUserInterface_CustomDialogDisplay: any,
) => {
  uc_setUserInterface_CustomDialogDisplay({
    display: true,
    dialog: {
      dialog_jsx: (
        <ApproveAdditionalWorkInvoiceDialog
          clientKey={clientKey}
          projectKey={projectKey}
          quoteKey={invoiceKey}
        />
      ),
      settings: {
        max_width: 'lg',
      },
    },
  })
}

export const approveAdditionalWorkInvoice = (clientKey: string, invoiceKey: string, approverUserKey: string, approverUserName: string) => {
  return new Promise((resolve, reject) => {
    DatabaseGetDocument(DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey))
      .then((res_GDD) => {
        let invoice = res_GDD.data
        if (invoice != null && invoice.associated_project_key != null) {
          let projectKey = invoice.associated_project_key
          let tasksToCreate = getProp(invoice, 'associated_tasks_form_data', {})
          returnUpdateArrayForV2QuoteApproval(clientKey, projectKey, invoiceKey, tasksToCreate, approverUserKey, approverUserName)
            .then((res_RUA) => {
              let updateArray: TsInterface_DatabaseBatchUpdatesArray = getProp(res_RUA, 'updateArray', [])
              let updateObject: TsInterface_UnspecifiedObject = {
                status: 'approved',
                timestamp_invoice_approved: new Date(),
                associated_tasks: {},
              }
              // Update Task Status
              for (let loopTaskKey in tasksToCreate) {
                updateObject['associated_tasks'][loopTaskKey] = {
                  status: 'active',
                }
              }
              updateArray.push({
                type: 'setMerge',
                ref: DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey),
                data: updateObject,
              })
              DatabaseStagedBatchUpdate(updateArray)
                .then((res_SMD) => {
                  resolve(res_SMD)
                })
                .catch((rej_SMD) => {
                  console.error(rej_SMD)
                  reject(rej_SMD)
                })
            })
            .catch((rej_RUA) => {
              console.error(rej_RUA)
              reject(rej_RUA)
            })
        } else {
          reject({
            success: false,
            error: {
              message: rLIB('Failed to approve additional work invoice'),
              details: rLIB('Invalid client or project key'),
              code: 'ER-D-IPF-AAWI-01',
            },
          })
        }
      })
      .catch((rej_GDD) => {
        console.error(rej_GDD)
        reject(rej_GDD)
      })
  })
}

export const openSendAdditionalWorkInvoiceEmailDialog = (
  clientKey: string,
  projectKey: string,
  invoiceKey: string,
  uc_setUserInterface_CustomDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
) => {
  let promiseArray1: TsType_UnknownPromise[] = []
  let project: TsInterface_UnspecifiedObject = {}
  let salesPartner: TsInterface_UnspecifiedObject = {}
  // Get Project Data
  DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
    .then((res_GDD) => {
      project = res_GDD.data
      DatabaseGetDocument(DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey))
        .then((res_GDD) => {
          let loadedInvoice = res_GDD.data
          let emailAndBillingContactInfo: TsInterface_UnspecifiedObject = {}
          if (loadedInvoice.bill_to === 'sales_partner' && loadedInvoice.associated_sales_partner_key != null) {
            // Get Sales Partner Data
            promiseArray1.push(
              DatabaseGetDocument(DatabaseRef_SalesPartner_Document(clientKey, project.associated_sales_partner_key as string)).then((res_GDD) => {
                salesPartner = res_GDD.data
                emailAndBillingContactInfo = {
                  email: getProp(salesPartner, 'sow_billing_email', null),
                  location_address: getProp(salesPartner, 'location_address', null),
                  location_city: getProp(salesPartner, 'location_city', null),
                  location_state: getProp(salesPartner, 'location_state', null),
                  location_zip: getProp(salesPartner, 'location_zip', null),
                }
              }),
            )
          } else if (project.invoice_bill_to === 'customer' && project.associated_customer_email != null) {
            emailAndBillingContactInfo = {
              email: getProp(project, 'associated_customer_email', null),
              location_address: getProp(project, 'location_address', null),
              location_city: getProp(project, 'location_city', null),
              location_state: getProp(project, 'location_state', null),
              location_zip: getProp(project, 'location_zip', null),
            }
          }
          Promise.all(promiseArray1).finally(() => {
            uc_setUserInterface_CustomDialogDisplay({
              display: true,
              dialog: {
                dialog_jsx: (
                  <BillAdditionalWorkInvoiceDialog
                    clientKey={clientKey}
                    invoiceKey={invoiceKey}
                    projectKey={projectKey}
                    project={project}
                    emailAndBillingContactInfo={emailAndBillingContactInfo}
                    invoice={loadedInvoice}
                  />
                ),
                settings: {
                  max_width: 'lg',
                },
              },
            })
          })
        })
        .catch((rej_GDD) => {
          console.error(rej_GDD)
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: rej_GDD.error,
          })
        })
    })
    .catch((rej_GDD) => {
      console.error(rej_GDD)
      uc_setUserInterface_ErrorDialogDisplay({
        display: true,
        error: rej_GDD.error,
      })
    })
}

export const addLineItemToAdditionalWorkInvoice = (
  clientKey: string,
  invoiceKey: string,
  lineItem: TsInterface_UnspecifiedObject,
  jobCode: string,
  lineItemType: 'line_item' | 'post_billing_correction',
  uc_RootData_GlobalUser: TsInterface_UnspecifiedObject,
  uc_RootData_ClientUser: TsInterface_UnspecifiedObject,
  process: 'save' | 'return_update_object',
) => {
  return new Promise((resolve, reject) => {
    // Get existing invoice
    DatabaseGetDocument(DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey))
      .then((res_GDD) => {
        let existingInvoice = res_GDD.data

        let newLineItem = {
          description: getProp(lineItem, 'description', null),
          amount: getProp(lineItem, 'amount', null),
          notes: getProp(lineItem, 'notes', null),
          job_code: jobCode,
          line_item_type: lineItemType,
        }
        let newTotalPrice = getProp(existingInvoice, 'total_price', 0) + parseFloat(lineItem['amount'])
        let newLogKey = new Date().getTime().toString() + '_' + generateRandomString(8, null)
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
          {
            type: 'setMerge',
            ref: DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey),
            data: {
              line_items: [...getProp(existingInvoice, 'line_items', []), newLineItem],
              total_price: newTotalPrice,
            },
          },
          {
            type: 'setMerge',
            ref: DatabaseRef_ProjectAdditionalWorkInvoice_Logs_Document(clientKey, invoiceKey, newLogKey),
            data: {
              timestamp_created: new Date(),
              action: 'Line Item Added -' + newLineItem['description'] + ' (' + formatCurrency(newLineItem['amount']) + ')',
              associated_user_key: getProp(uc_RootData_GlobalUser, 'key', null),
              associated_user_name: getProp(uc_RootData_ClientUser, 'name', null),
            },
          },
        ]
        if (process === 'save') {
          // Batch Update
          DatabaseBatchUpdate(updateArray)
            .then((res_DBU) => {
              resolve(res_DBU)
            })
            .catch((rej_DBU) => {
              reject(rej_DBU)
            })
        } else {
          resolve({ success: true, data: updateArray })
        }
      })
      .catch((rej_GDD) => {
        console.error(rej_GDD)
        reject(rej_GDD)
      })
  })
}

// TODO: change to cloud function???
export const sendProjectAdditionalWorkInvoiceEmail = (clientKey: string, projectKey: string, invoiceKey: string) => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectKey != null && invoiceKey != null) {
      DatabaseGetDocument(DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey))
        .then((res_GDD) => {
          let invoice: TsInterface_UnspecifiedObject = res_GDD.data
          downloadAdditionalWorkQuotePDF(
            clientKey,
            projectKey,
            getProp(invoice, 'bill_to', null),
            getProp(invoice, 'invoice_id_number', null),
            objectToArray(getProp(invoice, 'line_items', {})),
            'base64',
            {},
          )
            .then((res_DBPP) => {
              let promiseArray1: TsType_UnknownPromise[] = []
              let project: TsInterface_UnspecifiedObject = {}
              let salesPartner: TsInterface_UnspecifiedObject = {}
              // Get Project Data
              DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
                .then((res_GDD) => {
                  project = res_GDD.data
                  let emailAndBillingContactInfo: TsInterface_UnspecifiedObject = {}
                  if (project.invoice_bill_to === 'sales_partner' && project.associated_sales_partner_key != null) {
                    // Get Sales Partner Data
                    promiseArray1.push(
                      DatabaseGetDocument(DatabaseRef_SalesPartner_Document(clientKey, project.associated_sales_partner_key as string)).then((res_GDD) => {
                        salesPartner = res_GDD.data
                        emailAndBillingContactInfo = {
                          email: getProp(salesPartner, 'sow_billing_email', null),
                        }
                      }),
                    )
                  } else if (project.invoice_bill_to === 'customer' && project.associated_customer_email != null) {
                    emailAndBillingContactInfo = {
                      email: getProp(project, 'associated_customer_email', null),
                    }
                  }
                  Promise.all(promiseArray1).finally(() => {
                    // TODO: TEMP
                    let projectJobCode = project.id_number
                    let invoiceLineItems = invoice.line_items
                    let invoiceTotal = invoiceLineItems.reduce((acc: number, curr: TsInterface_UnspecifiedObject) => acc + curr.amount, 0)
                    let invoiceEmailDataObject: TsInterface_UnspecifiedObject = {
                      invoice: {
                        total: formatCurrency(invoiceTotal),
                      },
                    }
                    // Send Email
                    cloudFunctionManageRequest('manageEmails', {
                      function: 'sendSendgridHtmlEmailWithAttachments',
                      to: emailAndBillingContactInfo.email,
                      subject: 'Your invoice from ETW Energy: ' + getProp(res_DBPP, 'invoice_id_number', projectJobCode),
                      html: generateHtmlForEmailFromTemplateObject(invoiceEmailTemplate, invoiceEmailDataObject),
                      cc: [], // TODO: Fill this in?
                      bcc: [],
                      attachments: [
                        {
                          content: getProp(res_DBPP, 'data', null),
                          filename: getProp(res_DBPP, 'file_name', 'Invoice ' + projectJobCode + '.pdf'),
                          type: 'application/pdf',
                          disposition: 'attachment',
                        },
                      ],
                    })
                      .then((res) => {
                        // Update Invoice
                        let invoiceUpdateObject = {
                          status: 'billed',
                          timestamp_invoice_billed: new Date(),
                        }
                        let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                          {
                            type: 'setMerge',
                            ref: DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey),
                            data: invoiceUpdateObject,
                          },
                        ]
                        DatabaseBatchUpdate(updateArray)
                          .then((res_SMD) => {
                            resolve(res_SMD)
                          })
                          .catch((rej_SMD) => {
                            console.error('Error updating invoice:', rej_SMD)
                            reject(rej_SMD)
                          })
                      })
                      .catch((rej) => {
                        console.error('Error sending email:', rej)
                        reject(rej)
                      })
                  })
                })
                .catch((rej_GDD) => {
                  console.error(rej_GDD)
                  reject(rej_GDD)
                })
            })
            .catch((rej_DBPP) => {
              reject(rej_DBPP)
            })
        })
        .catch((rej_GDD) => {
          console.error(rej_GDD)
          reject(rej_GDD)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to send project additional work invoice email'),
          details: rLIB('Invalid client, project, or invoice key'),
          code: 'ER-D-IPF-SPAWIE-01',
        },
      })
    }
  })
}

export const cancelAdditionalWorkInvoice = (clientKey: string, invoiceKey: string) => {
  return new Promise((resolve, reject) => {
    // TODO: Get Tasks associated with invoice
    // Update Status to 'cancelled'
    // Loop through and delete tasks with existing function AFTER the invoice status is cancelled
  })
}

export const removeTasksFromAdditionalWorkInvoice = (
  clientKey: string,
  invoiceKey: string,
  taskKey: string,
  approverUserKey: string,
  approverUserName: string,
) => {
  return new Promise((resolve, reject) => {
    DatabaseGetCollection(DatabaseRef_TaskLinkedToAdditionalWorkInvoice_Query(clientKey, invoiceKey, taskKey))
      .then((res_DGD) => {
        let newLogKey = new Date().getTime().toString() + '_' + generateRandomString(8, null)
        if (objectToArray(res_DGD.data).length > 0) {
          let task = objectToArray(res_DGD.data)[0]

          // Get Invoice Data
          DatabaseGetDocument(DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey))
            .then((res_DGD) => {
              let invoice = res_DGD.data
              let invoiceUpdateObject: TsInterface_UnspecifiedObject = {
                associated_tasks: {
                  [taskKey]: null,
                },
                associated_tasks_form_data: {
                  [taskKey]: null,
                },
              }
              // Loop through invoice and if all the tasks are completed, set the invoice status to completed
              let allTasksCompleted = true
              for (let loopTaskKey in invoice.associated_tasks) {
                let loopTask = invoice.associated_tasks[loopTaskKey]
                if (loopTask.status !== 'completed' && loopTask.status !== 'cancelled' && loopTask.status !== 'deleted' && loopTaskKey !== taskKey) {
                  allTasksCompleted = false
                }
              }
              if (allTasksCompleted === true) {
                invoiceUpdateObject['status'] = 'completed'
              }
              let taskUpdateObject: TsInterface_UnspecifiedObject = {
                associated_additional_work_invoice_key: null,
              }
              let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                {
                  type: 'setMerge',
                  ref: DatabaseRef_ProjectAdditionalWorkInvoice_Document(clientKey, invoiceKey),
                  data: invoiceUpdateObject,
                },
                {
                  type: 'setMerge',
                  ref: DatabaseRef_Task_Document(clientKey, taskKey),
                  data: taskUpdateObject,
                },
                {
                  type: 'setMerge',
                  ref: DatabaseRef_ProjectAdditionalWorkInvoice_Logs_Document(clientKey, invoiceKey, newLogKey),
                  data: {
                    timestamp_created: new Date(),
                    action: 'Task removed from Additional Work Invoice: ' + task.name,
                    associated_user_key: approverUserKey,
                    associated_user_name: approverUserName,
                  },
                },
              ]
              DatabaseBatchUpdate(updateArray)
                .then((res_DBU) => {
                  resolve(res_DBU)
                })
                .catch((rej_DBU) => {
                  reject(rej_DBU)
                })
            })
            .catch((rej_DGD) => {
              reject(rej_DGD)
            })
        } else {
          reject({
            success: false,
            error: {
              message: rLIB('Failed to remove task from additional work invoice'),
              details: rLIB('Task not linked to additional work invoice'),
              code: 'ER-D-IPF-RTTAWI-01',
            },
          })
        }
      })
      .catch((rej_DGD) => {
        reject(rej_DGD)
      })
  })
}
