/* eslint-disable react/prop-types */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			DIALOGS
				Assign Task (Drag and Drop Event)
					Guard Rails for event creation

				Assigned Field Ops Task Dialog (double click)
					Edit team members

			BACKBURNER FOR NOW
				Arrow Buttons not updating date dropdown... (removed for now)
				TIME Off for freeform teams???

			SITE AUDIT CLOUD FUNCTIONS
				- Select a time slot (fill into highest priority team)

			LOGS
				Logs for following (not all inclusive)
				- team creation
				- team edit membership?
				- team edit name
				- team delete
				- assign task
				- unassign task



			MAP
				render table with schedule (show breaks and overlaps)
				hover over pins or table should hightlight user, pins, etc
				for site audit, render the slots as empty events and time off and etc
				ability to quickly trade events or move events, etc


	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  AppBar,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2'
import { Stack } from '@mui/system'
import {
  formInputs_ScheduleTeamNew,
  formInputs_TaskAssignmentDefinedTimeslots,
  formInputs_TaskAssignmentFreeformTimeslots,
  formInputs_TeamTimezoneNew,
  formOnChange_ScheduleTeam,
  formOnChange_TaskAssignment,
  formOnChange_TeamTimezone,
  formSettings_ScheduleTeamCreate,
  formSettings_TaskAssignment,
  formSettings_TeamTimezoneCreate,
  formSubmission_ScheduleTeamCreate,
  formSubmission_TaskAssignment,
  formSubmission_TaskScheduleUpdate,
  formSubmission_TeamTimezoneCreate,
  timeOptions,
} from 'app/models/scheduling/scheduling_teams'
import {
  formInputs_RetryTask,
  formOnChange_RetryTask,
  formSettings_RetryTask,
  formSubmission_RetryTask,
  onSiteTaskRedoTasks,
} from 'app/models/tasks/task_forms'
import { sendAppPushNotification } from 'app/services/external_requests/external_requests'
import dayjs from 'dayjs'
import { memo, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Trans } from 'react-i18next'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages, schedulingTeamTypes } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveRegions_Query, DatabaseRef_Regions_Collection } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_UsersWithSpecificTaskRole_Query } from 'rfbp_aux/services/database_endpoints/directory/users'
import { DatabaseRef_AdditionalWorkInvoicesForProject_Query } from 'rfbp_aux/services/database_endpoints/finances/project_additional_work_invoices'
import { DatabaseRef_BaseInvoicesForProject_Query } from 'rfbp_aux/services/database_endpoints/finances/project_base_invoices'
import {
  DatabaseRef_CancelledScheduledTasksInDateRangeArray_Query,
  DatabaseRef_CancelledScheduledTasksInDateRange_Query,
  DatabaseRef_CancelledScheduledTasks_LastUpdated_Query,
  DatabaseRef_CancelledScheduledTask_Document,
} from 'rfbp_aux/services/database_endpoints/operations/cancelled_scheduled_tasks'
import { DatabaseRef_MapLocations_Collection } from 'rfbp_aux/services/database_endpoints/operations/maps'
import { DatabaseRef_ProjectAdditionalData_Document, DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  DatabaseRef_ScheduledTeamTimeOn_Collection,
  DatabaseRef_ScheduledTeamTimeOn_Document,
  DatabaseRef_ScheduledTimeOff_Collection,
  DatabaseRef_ScheduledTimeOff_Document,
  DatabaseRef_ScheduledTimeOff_LastUpdated_Query,
  DatabaseRef_ScheduledTimeOff_Query,
  DatabaseRef_SchedulingActiveTeams_Query,
  DatabaseRef_SchedulingDeletedTeams_Query,
  DatabaseRef_SchedulingTeams_Document,
  DatabaseRef_SchedulingTeamTypeSettings_Collection,
  DatabaseRef_SchedulingTeamTypeSettings_Document,
} from 'rfbp_aux/services/database_endpoints/operations/schedules'
import {
  DatabaseRef_AssignedButNotCompleteTasks_Query,
  DatabaseRef_ContradictoryActiveCompletedTasks_Query,
  DatabaseRef_ContradictoryAssignedCompletedTasks_Query,
  DatabaseRef_JobsToClose_Query,
  DatabaseRef_OpenDispatcherTasks_Query,
  DatabaseRef_ScheduledTasksInDateRangeArray_Query,
  DatabaseRef_ScheduledTasksInDateRange_Query,
  DatabaseRef_ScheduledTasks_LastUpdated_Query,
  DatabaseRef_Task_Document,
} from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { DatabaseRef_TimePunches_DateRange_Query } from 'rfbp_aux/services/database_endpoints/timesheets/time_punches'
import { DatePicker } from 'rfbp_core/components/date_picker'
import {
  Form,
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { MapBasic, TsInterface_MapMarkers, TsInterface_MapSettings, TsType_MapOnClick } from 'rfbp_core/components/map'
import { TableBasic, TableCellBasic, TsInterface_TableAdditionalData, TsInterface_TableDataRow, TsInterface_TableHooks } from 'rfbp_core/components/table'
import { TabsBasic, TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
  TsInterface_FormDialogObject,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseBatchUpdate,
  DatabaseDeleteDocument,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseUpdateDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import {
  cloneObjectWithoutReference,
  downloadCSV,
  dynamicSort,
  formatDateToYYYYMMDD,
  getProp,
  isDaylightSavings,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnFormattedDateKey,
  underscoresToSpaces,
} from 'rfbp_core/services/helper_functions'
import { getPageLocalStorage, setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { getStartAndEndOfWeek } from '../payroll/data/payroll_calculations'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_DraggableUnassignedTask {
  task: TsInterface_UnspecifiedObject
  taskKey: string
  name: string
  type: string
  viewNotes: boolean
  onDoubleClick: (task: any) => void
}

interface TsInterface_DraggableAssignedDefinedTimeslotTask {
  task: TsInterface_UnspecifiedObject
  taskKey: string
  type: string
  childrenJSX: JSX.Element
}

interface TsInterface_DraggableAssignedFreeformTimeTask {
  task: TsInterface_UnspecifiedObject
  type: string
  childrenJSX: JSX.Element
}

interface TsInterface_DroppableTeamSlot {
  definedTimeSlotKey: string | null
  taskAssignmentType: string
  team: TsInterface_UnspecifiedObject
  allScheduledTasks: TsInterface_UnspecifiedObject
  allTeams: TsInterface_UnspecifiedObject
  teamTypeSettings: TsInterface_UnspecifiedObject
  assignDate: Date
  assignTime: number | null
  accept: string[]
  children: JSX.Element
  sx: TsInterface_UnspecifiedObject
  uc_setUserInterface_CustomDialogDisplay: any
}

interface TsInterface_DroppableReassignTeamDefinedSlot {
  team: TsInterface_UnspecifiedObject
  accept: string[]
  childrenJSX: JSX.Element
  sx: TsInterface_UnspecifiedObject
  setLastTaskSwappedTimestamp: any
}

interface TsInterface_DroppableReassignTeamWithTaskDefinedSlot {
  team: TsInterface_UnspecifiedObject
  allTeams: TsInterface_UnspecifiedObject
  calendarRenderData: TsInterface_UnspecifiedObject
  accept: string[]
  windowKey: string
  childrenJSX: JSX.Element
  sx: TsInterface_UnspecifiedObject
  setLastTaskSwappedTimestamp: any
}

interface TsInterface_DroppableReassignTeamFreeformSlot {
  team: TsInterface_UnspecifiedObject
  accept: string[]
  childrenJSX: JSX.Element
  sx: TsInterface_UnspecifiedObject
  setLastTaskSwappedTimestamp: any
}

interface TsInterface_TeamMembershipDialog {
  teamKey: string
}

interface TsInterface_AssignTaskToTeamDialog {
  definedTimeSlotKey: string | null
  taskAssignmentType: string
  teamKey: string
  teamType: string
  taskKey: string
  assignDate: Date
  assignTime: number | null
  allScheduledTasks: TsInterface_UnspecifiedObject
  allTeams: TsInterface_UnspecifiedObject
  teamTypeSettings: TsInterface_UnspecifiedObject
}

interface TsInterface_AssignedTaskDialog {
  event: TsInterface_UnspecifiedObject
  allScheduledTasks: TsInterface_UnspecifiedObject
  allTeams: TsInterface_UnspecifiedObject
  teamKey: string | null
}

interface TsInterface_UnassignedTaskDialog {
  task: TsInterface_UnspecifiedObject
  us_calendarEvents: TsInterface_UnspecifiedObject
  us_activeTeams: TsInterface_UnspecifiedObject
  us_teamTypeSettings: TsInterface_UnspecifiedObject
}

interface TsInterface_EventDayMembershipDialog {
  teamKey: string
  taskKey: string
  date: Date
}

// export type anyPromise = Promise< any >
type TsType_ScheduleExportData = Promise<{
  success: boolean
  data: TsInterface_UnspecifiedObject
}>

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['TaskCalendarSchedulePage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ACTIVE_TEAMS: JSX.Element = <Trans>Active Teams</Trans>
const s_ADD_TEAM_MEMBERS: JSX.Element = <Trans>Add Team Members</Trans>
const s_ADD_TIME_SLOT: JSX.Element = <Trans>Add time slot</Trans>
const s_ALL_EVENTS: JSX.Element = <Trans>All Events</Trans>
const s_ALL_REGIONS: JSX.Element = <Trans>All Regions</Trans>
const s_ALL_TASKS: JSX.Element = <Trans>All Tasks</Trans>
const s_AN_UNKNOWN_ERROR_OCCURRED: JSX.Element = <Trans>An unknown error occured</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CANCEL_THIS_TIME_OFF: JSX.Element = <Trans>Are you sure that you want to cancel this time off?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_REASSIGN_THIS_TASK: JSX.Element = <Trans>Are you sure that you want to reassign this task?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_TRADE_THESE_TASKS_BETWEEN_TEAMS: JSX.Element = (
  <Trans>Are you sure that you want to trade these tasks between teams?</Trans>
)
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNASSIGN_THIS_TASK: JSX.Element = <Trans>Are you sure that you want to unassign this task?</Trans>
const s_ASSIGNED: JSX.Element = <Trans>Assigned</Trans>
const s_ASSIGNED_TASK_ID: JSX.Element = <Trans>Assigned Task ID</Trans>
const s_ASSIGNED_TASK_NAME: JSX.Element = <Trans>Assigned Task Name</Trans>
const s_ASSIGN_TASK: JSX.Element = <Trans>Assign Task</Trans>
const s_ATTEMPTED_GEOCODE: JSX.Element = <Trans>Attempted Geocode</Trans>
const s_ATTIC_RUN: JSX.Element = <Trans>Attic Run</Trans>
const s_BENCHED: JSX.Element = <Trans>Benched</Trans>
const s_BLOCKED_BY_OVERLAPPING_EVENT: JSX.Element = <Trans>Blocked by overlapping event</Trans>
const s_CANCEL: JSX.Element = <Trans>Cancel</Trans>
const s_CANCELLED: JSX.Element = <Trans>Cancelled</Trans>
const s_CANCEL_TIME_OFF: JSX.Element = <Trans>Cancel Time Off</Trans>
const s_CAP_APPROVED: JSX.Element = <Trans>CAP Approved</Trans>
const s_CENTRAL_TIME: JSX.Element = <Trans>Central Time</Trans>
const s_CHOOSE_A_TEAM_TYPE: JSX.Element = <Trans>Choose a team type</Trans>
const s_CITY: JSX.Element = <Trans>City</Trans>
const s_COMPLETED: JSX.Element = <Trans>Completed</Trans>
const s_CONFIG: JSX.Element = <Trans>Config</Trans>
const s_COUNTY: JSX.Element = <Trans>County</Trans>
const s_CREATE_NEW_TEAM: JSX.Element = <Trans>Create New Team</Trans>
const s_CURRENT_TEAM_MEMBERS: JSX.Element = <Trans>Current Team Members</Trans>
const s_CURRENT_TEAM_MEMBERS_DIFFER_FROM_SCHEDULED_TEAM_MEMBERS: JSX.Element = <Trans>Current team members differ from scheduled team members</Trans>
const s_CUSTOMER: JSX.Element = <Trans>Customer</Trans>
const s_DATE: JSX.Element = <Trans>Date</Trans>
const s_DAY: JSX.Element = <Trans>Day</Trans>
const s_DEFINED_SLOTS: JSX.Element = <Trans>Defined Slots</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_DELETED: JSX.Element = <Trans>Deleted</Trans>
const s_DELETE_TEAM: JSX.Element = <Trans>Delete Team</Trans>
const s_DISPATCHER_TASK_ID: JSX.Element = <Trans>Dispatcher Task ID</Trans>
const s_DISPATCHER_TASK_NAME: JSX.Element = <Trans>Dispatcher Task Name</Trans>
const s_DISTANCE_FROM_WAREHOUSE: JSX.Element = <Trans>Distance from warehouse</Trans>
const s_DOWNLOADS: JSX.Element = <Trans>Downloads</Trans>
const s_EASTERN_TIME: JSX.Element = <Trans>Eastern Time</Trans>
const s_EDIT_SCHEDULE: JSX.Element = <Trans>Edit Schedule</Trans>
const s_EDIT_TASK_DURATION: JSX.Element = <Trans>Edit Task Duration</Trans>
const s_EDIT_TASK_FILL_PRIORITY: JSX.Element = <Trans>Edit task fill priority</Trans>
const s_EDIT_TASK_QA_REVIEW: JSX.Element = <Trans>Edit Task QA Review</Trans>
const s_EDIT_TASK_SCHEDULE: JSX.Element = <Trans>Edit Task Schedule</Trans>
const s_EDIT_TEAM: JSX.Element = <Trans>Edit Team</Trans>
const s_EDIT_TEAM_TIMEZONE: JSX.Element = <Trans>Edit Team Timezone</Trans>
const s_END_TIME: JSX.Element = <Trans>End Time</Trans>
const s_ENTER_A_FILL_PRIORITY_NUMBER: JSX.Element = <Trans>Enter a fill priority number</Trans>
const s_ENTER_A_NAME_FOR_THIS_TEAM: JSX.Element = <Trans>Enter a name for this team</Trans>
const s_ENTER_A_REASON_FOR_TIME_OFF: JSX.Element = <Trans>Enter a reason for time off</Trans>
const s_ESTIMATED_TASK_DURATION: JSX.Element = <Trans>Estimated Task Duration</Trans>
const s_EVENTS_ASSIGNED_TO_DELETED_TEAMS: JSX.Element = <Trans>Events assigned to deleted teams</Trans>
const s_FAIL: JSX.Element = <Trans>Fail</Trans>
const s_FAILED_GEOCODE: JSX.Element = <Trans>Failed Geocode</Trans>
const s_FAILED_TO_CALCULATE_COORDIATES: JSX.Element = <Trans>Failed to calculate coordinates</Trans>
const s_FAILED_TO_CALCULATE_MILES: JSX.Element = <Trans>Failed to calculate miles</Trans>
const s_FAILED_TO_DELETE_TEAM: JSX.Element = <Trans>Failed to delete team</Trans>
const s_FAILED_TO_REASSIGN_TASK: JSX.Element = <Trans>Failed to reassign task</Trans>
const s_FAILED_TO_TRADE_TASKS: JSX.Element = <Trans>Failed to trade tasks</Trans>
const s_FAILED_TO_UNDELETE_TEAM: JSX.Element = <Trans>Failed to undelete team</Trans>
const s_FAILED_TO_UPDATE_QA_RESULTS: JSX.Element = <Trans>Failed to update QA Results</Trans>
const s_FAILED_TO_UPDATE_TIME_SLOT_AVAILABILITY: JSX.Element = <Trans>Failed to update time slot availability</Trans>
const s_FILL_PRIORITY: JSX.Element = <Trans>Fill priority</Trans>
const s_FIX_MISSING_DATA: JSX.Element = <Trans>Fix Missing Data</Trans>
const s_FREEFORM_ASSIGNMENT: JSX.Element = <Trans>Freeform Assignment</Trans>
const s_FRI: JSX.Element = <Trans>Fri</Trans>
const s_FRIDAY: JSX.Element = <Trans>Friday</Trans>
const s_HOURS: JSX.Element = <Trans>hours</Trans>
const s_INACTIVE_TEAMS: JSX.Element = <Trans>Inactive Teams</Trans>
const s_INSPECTION_QA_REVIEW: JSX.Element = <Trans>Inspection / QA Review</Trans>
const s_INSTALLER: JSX.Element = <Trans>Installer</Trans>
const s_INSTALL_COMPLETE: JSX.Element = <Trans>Install Complete</Trans>
const s_INSTALL_FOREMAN: JSX.Element = <Trans>Install Foreman</Trans>
const s_INSTALL_LEAD: JSX.Element = <Trans>Install Lead</Trans>
const s_INVERTERS: JSX.Element = <Trans>Inverters</Trans>
const s_IS_SCHEDULED_AS_TIME_OFF_FOR_THIS_TEAM: JSX.Element = <Trans>is scheduled as time off for this team.</Trans>
const s_JURISDICTION: JSX.Element = <Trans>Jurisdiction</Trans>
const s_MAP: JSX.Element = <Trans>Map</Trans>
const s_MAX_ROOF_PITCH: JSX.Element = <Trans>Max Roof Pitch</Trans>
const s_MILEAGE_REPAIR_SUMMARY: JSX.Element = <Trans>Mileage Repair Summary</Trans>
const s_MISSING_COORDINATES: JSX.Element = <Trans>Missing Coordinates</Trans>
const s_MISSING_FOREMAN: JSX.Element = <Trans>Missing Foreman</Trans>
const s_MISSING_REGION: JSX.Element = <Trans>Missing Region</Trans>
const s_MISSING_REQUIRED_PARAMETERS: JSX.Element = <Trans>Missing required parameters</Trans>
const s_MON: JSX.Element = <Trans>Mon</Trans>
const s_MONDAY: JSX.Element = <Trans>Monday</Trans>
const s_MOUNTAIN_TIME: JSX.Element = <Trans>Mountain Time</Trans>
const s_MY_TASKS: JSX.Element = <Trans>My Tasks</Trans>
const s_NOTE: JSX.Element = <Trans>Note</Trans>
const s_NOTES: JSX.Element = <Trans>Notes</Trans>
const s_NOTES_HIDDEN: JSX.Element = <Trans>Notes Hidden</Trans>
const s_NOTES_VISIBLE: JSX.Element = <Trans>Notes Visible</Trans>
const s_NOT_SCHEDULED: JSX.Element = <Trans>Not Scheduled</Trans>
const s_NO_ACTIVE: JSX.Element = <Trans>No Active</Trans>
const s_NO_ASSIGNED_TEAM_MEMBERS: JSX.Element = <Trans>No assigned team members</Trans>
const s_NO_COORDINATES_FOR_THIS_TASK: JSX.Element = <Trans>No coordinates for this task</Trans>
const s_NO_NOTES: JSX.Element = <Trans>No Notes</Trans>
const s_NO_PEOPLE_ASSIGNED_TO_TEAM: JSX.Element = <Trans>No people assigned to team</Trans>
const s_NO_TASKS_TO_ASSIGN: JSX.Element = <Trans>No Tasks to assign</Trans>
const s_NO_TEAM_ROLE_SELECTED: JSX.Element = <Trans>No team role selected</Trans>
const s_NO_TIMEZONE_SELECTED: JSX.Element = <Trans>No Timezone Selected</Trans>
const s_NO_TIME_SLOTS_ADDED_YET: JSX.Element = <Trans>No time slots added yet</Trans>
const s_NUMBER_OF_ARRAYS: JSX.Element = <Trans>Number of Arrays</Trans>
const s_NUMBER_OF_STRINGS: JSX.Element = <Trans>Number of Strings</Trans>
const s_OPEN: JSX.Element = <Trans>Open</Trans>
const s_OPEN_TIME_SLOT: JSX.Element = <Trans>Open Time Slot</Trans>
const s_OTHER_SETTINGS: JSX.Element = <Trans>Other Settings</Trans>
const s_PACIFIC_TIME: JSX.Element = <Trans>Pacific Time</Trans>
const s_PANELS: JSX.Element = <Trans>Panels</Trans>
const s_PASS: JSX.Element = <Trans>Pass</Trans>
const s_PROJECT: JSX.Element = <Trans>Project</Trans>
const s_PROJECTS_MISSING_DISTANCE_TO_WAREHOUSE: JSX.Element = <Trans>Projects Missing Distance to Warehouse</Trans>
const s_PROJECTS_MISSING_PANELS: JSX.Element = <Trans>Projects Missing Panels</Trans>
const s_PROJECTS_MISSING_REGION: JSX.Element = <Trans>Projects Missing Region</Trans>
const s_PROJECTS_MISSING_ROOF_PITCH: JSX.Element = <Trans>Projects Missing Roof Pitch</Trans>
const s_QA_REVIEW: JSX.Element = <Trans>QA Review</Trans>
const s_QA_REVIEW_DATE: JSX.Element = <Trans>QA Review Date</Trans>
const s_QA_REVIEW_NOTES: JSX.Element = <Trans>QA Review Notes</Trans>
const s_QA_REVIEW_RESULT: JSX.Element = <Trans>QA Review Result</Trans>
const s_REASSIGN: JSX.Element = <Trans>Reassign</Trans>
const s_REASSIGN_TASK: JSX.Element = <Trans>Reassign Task</Trans>
const s_REDO_ON_SITE_TASK: JSX.Element = <Trans>Redo On Site Task</Trans>
const s_REDO_TASK: JSX.Element = <Trans>Redo Task</Trans>
const s_REGION: JSX.Element = <Trans>Region</Trans>
const s_RENAME_TEAM: JSX.Element = <Trans>Rename Team</Trans>
const s_REQUESTED_CHANGES_TO_TEAM_COMPOSITION: JSX.Element = <Trans>Requested changes to team composition</Trans>
const s_RESULT: JSX.Element = <Trans>Result</Trans>
const s_RESULTS: JSX.Element = <Trans>Results</Trans>
const s_ROOF_TYPE: JSX.Element = <Trans>Roof Type</Trans>
const s_SAT: JSX.Element = <Trans>Sat</Trans>
const s_SATURDAY: JSX.Element = <Trans>Saturday</Trans>
const s_SAVE: JSX.Element = <Trans>Save</Trans>
const s_SCHEDULED_TIME_OFF: JSX.Element = <Trans>Scheduled Time Off</Trans>
const s_SCHEDULE_AS_TIME_OFF: JSX.Element = <Trans>Schedule as time off</Trans>
const s_SCHEDULE_SLOT_AS_TIME_OFF: JSX.Element = <Trans>Schedule slot as time off</Trans>
const s_SCHEDULE_TIME_OFF: JSX.Element = <Trans>Schedule Time Off</Trans>
const s_SCHEDULING: JSX.Element = <Trans>Scheduling</Trans>
const s_SEARCH_FOR_USERS: JSX.Element = <Trans>Search for Users</Trans>
const s_SEASON: JSX.Element = <Trans>Season</Trans>
const s_SETTINGS: JSX.Element = <Trans>Settings</Trans>
const s_SOW_FOREMAN: JSX.Element = <Trans>SOW Foreman</Trans>
const s_SOW_HELPER: JSX.Element = <Trans>SOW Helper</Trans>
const s_START_TIME: JSX.Element = <Trans>Start Time</Trans>
const s_STATE: JSX.Element = <Trans>State</Trans>
const s_STORAGE: JSX.Element = <Trans>Storage</Trans>
const s_SUCCESSFUL_GEOCODE: JSX.Element = <Trans>Successful Geocode</Trans>
const s_SUMMER_SCHEDULE: JSX.Element = <Trans>Summer Schedule</Trans>
const s_SUN: JSX.Element = <Trans>Sun</Trans>
const s_SUNDAY: JSX.Element = <Trans>Sunday</Trans>
const s_SYSTEM_SIZE: JSX.Element = <Trans>System Size</Trans>
const s_TASK: JSX.Element = <Trans>Task</Trans>
const s_TASKS_FILTER: JSX.Element = <Trans>Tasks Filter</Trans>
const s_TASKS_ON_DELETED_TEAMS: JSX.Element = <Trans>Tasks on Deleted Teams</Trans>
const s_TASK_ASSIGNER: JSX.Element = <Trans>Task Assigner</Trans>
const s_TASK_ASSIGNMENT: JSX.Element = <Trans>Task Assignment</Trans>
const s_TASK_ASSIGNMENT_COMPLETE: JSX.Element = <Trans>Task Assignment Complete</Trans>
const s_TASK_ASSIGNMENT_TYPE: JSX.Element = <Trans>Task Assignment Type</Trans>
const s_TASK_CALENDAR_NOTES: JSX.Element = <Trans>Task Calendar Notes</Trans>
const s_TASK_DETAILS: JSX.Element = <Trans>Task Details</Trans>
const s_TASK_ID: JSX.Element = <Trans>Task ID</Trans>
const s_TASK_SEARCH: JSX.Element = <Trans>Task Search</Trans>
const s_TASK_SPECIAL_INSTRUCTIONS: JSX.Element = <Trans>Task Special Instructions</Trans>
const s_TASK_STATUS: JSX.Element = <Trans>Task Status</Trans>
const s_TEAM: JSX.Element = <Trans>Team</Trans>
const s_TEAMS: JSX.Element = <Trans>Teams</Trans>
const s_TEAM_FILTER: JSX.Element = <Trans>Team Filter</Trans>
const s_TEAM_MEMBER: JSX.Element = <Trans>Team Member</Trans>
const s_TEAM_MEMBERS: JSX.Element = <Trans>Team Members</Trans>
const s_TEAM_MEMBERSHIP: JSX.Element = <Trans>Team Membership</Trans>
const s_TEAM_NAME: JSX.Element = <Trans>Team Name</Trans>
const s_TEAM_SLOT_SETUP: JSX.Element = <Trans>Team Slot Setup</Trans>
const s_TEAM_TYPE: JSX.Element = <Trans>Team Type</Trans>
const s_THIS_APPOINTMENT_SLOT_IS_SCHEDULED_AS_TIME_OFF_FOR: JSX.Element = <Trans>This appointment slot is scheduled as time off for</Trans>
const s_THIS_DAY: JSX.Element = <Trans>This day</Trans>
const s_THU: JSX.Element = <Trans>Thu</Trans>
const s_THURSDAY: JSX.Element = <Trans>Thursday</Trans>
const s_TIME_OFF: JSX.Element = <Trans>Time Off</Trans>
const s_TIME_OFF_REASON: JSX.Element = <Trans>Time off reason</Trans>
const s_TIME_OFF_TYPE: JSX.Element = <Trans>Time Off Type</Trans>
const s_TO: JSX.Element = <Trans>to</Trans>
const s_TRADE_TASKS: JSX.Element = <Trans>Trade Tasks</Trans>
const s_TRAVELLED_AND_BENCHED: JSX.Element = <Trans>Travelled and Benched</Trans>
const s_TUE: JSX.Element = <Trans>Tue</Trans>
const s_TUESDAY: JSX.Element = <Trans>Tuesday</Trans>
const s_TYPE_DELETE_TO_PROCEED_WITH_DELETING_THIS_TEAM: JSX.Element = <Trans>Type "delete" to proceed with deleting this team</Trans>
const s_TYPE_UNDELETE_TO_PROCEED_WITH_UNDELETING_THIS_TEAM: JSX.Element = <Trans>Type "undelete" to proceed with undeleting this team</Trans>
const s_UNASSIGN: JSX.Element = <Trans>Unassign</Trans>
const s_UNASSIGN_TASK: JSX.Element = <Trans>Unassign Task</Trans>
const s_UNDELETE: JSX.Element = <Trans>Undelete</Trans>
const s_UNDELETE_TEAM: JSX.Element = <Trans>Undelete Team</Trans>
const s_UPDATED_EVENTS: JSX.Element = <Trans>Updated Events</Trans>
const s_USERS_HIDDEN: JSX.Element = <Trans>Users Hidden</Trans>
const s_USERS_VISIBLE: JSX.Element = <Trans>Users Visible</Trans>
const s_USER_FILTER: JSX.Element = <Trans>User Filter</Trans>
const s_UTILITY: JSX.Element = <Trans>Utility</Trans>
const s_VIEWING_INACTIVE_TEAMS: JSX.Element = <Trans>Viewing Inactive Teams</Trans>
const s_VIEW_PROJECT: JSX.Element = <Trans>View Project</Trans>
const s_WED: JSX.Element = <Trans>Wed</Trans>
const s_WEDNESDAY: JSX.Element = <Trans>Wednesday</Trans>
const s_WEEK: JSX.Element = <Trans>Week</Trans>
const s_WINDOW_END_TIME: JSX.Element = <Trans>Window end time</Trans>
const s_WINDOW_START_TIME: JSX.Element = <Trans>Window start time</Trans>
const s_WINTER_SCHEDULE: JSX.Element = <Trans>Winter Schedule</Trans>
const s_YOU_CANT_PUT_QA_RESULTS_IN_PAST_WEEKS: JSX.Element = <Trans>You can't put QA results in past weeks</Trans>
const s_ZIP: JSX.Element = <Trans>Zip</Trans>
const se_SCHEDULE = 'Schedule'
const se_THE_ABOVE_DATES_ARE_COMING_FROM_SALESFORCE_AND_ARE_NOT_DYNAMICALLY_UPDATED_BY_TASK_COMPLETION_YET =
  'The above dates are coming from salesforce and are not dynamically updated by task completion yet'
// { sort-end } - displayed text

// Form Options
const taskFilterOptions: TsInterface_UnspecifiedObject[] = [
  { key: 'all', value: s_ALL_TASKS },
  { key: 'user', value: s_MY_TASKS },
  { key: 'deleted_teams', value: s_TASKS_ON_DELETED_TEAMS },
]

const taskAssignmentTypeOptions: TsInterface_UnspecifiedObject = {
  freeform_assignment: { key: 'freeform_assignment', value: s_FREEFORM_ASSIGNMENT },
  defined_slots: { key: 'defined_slots', value: s_DEFINED_SLOTS },
}

const windowTimeOptions: TsInterface_UnspecifiedObject[] = [
  { key: 6, value: '6:00am' },
  { key: 6.5, value: '6:30am' },
  { key: 7, value: '7:00am' },
  { key: 7.5, value: '7:30am' },
  { key: 8, value: '8:00am' },
  { key: 8.5, value: '8:30am' },
  { key: 9, value: '9:00am' },
  { key: 9.5, value: '9:30am' },
  { key: 10, value: '10:00am' },
  { key: 10.5, value: '10:30am' },
  { key: 11, value: '11:00am' },
  { key: 11.5, value: '11:30am' },
  { key: 12, value: '12:00pm' },
  { key: 12.5, value: '12:30pm' },
  { key: 13, value: '1:00pm' },
  { key: 13.5, value: '1:30pm' },
  { key: 14, value: '2:00pm' },
  { key: 14.5, value: '2:30pm' },
  { key: 15, value: '3:00pm' },
  { key: 15.5, value: '3:30pm' },
  { key: 16, value: '4:00pm' },
  { key: 16.5, value: '4:30pm' },
  { key: 17, value: '5:00pm' },
  { key: 17.5, value: '5:30pm' },
  { key: 18, value: '6:00pm' },
  { key: 18.5, value: '6:30pm' },
  { key: 19, value: '7:00pm' },
  { key: 19.5, value: '7:30pm' },
]

// Map Icons
const returnMapIcons = (iconKey: string): TsInterface_UnspecifiedObject => {
  let icon: TsInterface_UnspecifiedObject = {}
  switch (iconKey) {
    case 'pin':
      icon = {
        path: 'M550,325L550,50L600,50L600,0L200,0L200,50L250,50L250,325C250,325 150,400 150,500C150,525 245,535 350,535L350,645C350,680 360,715 375,750L400,800L425,750C440,720 450,685 450,645L450,535C555,535 650,520 650,500C650,400 550,325 550,325ZM350,330C350,330 325,345 270,400C220,450 195,495 195,495C195,495 200,445 235,400C280,345 300,330 300,330L300,50L350,50L350,330Z',
        scale: 0.05,
        anchor: new google.maps.Point(400, 800),
      }
      break
    case 'pin_old':
      icon = {
        path: 'M399.999,0C240.037,0 110.867,129.17 110.867,289.132C110.867,344.905 128.684,398.161 153.472,441.926L341.902,768.044C353.715,789.347 377.535,799.998 399.999,799.998C422.465,799.998 445.121,789.347 458.096,768.044L646.526,442.12C671.508,398.351 689.131,346.063 689.131,289.321C689.131,129.364 559.961,0 399.999,0ZM399.999,374.535C341.902,374.535 294.455,327.089 294.455,268.991C294.455,210.894 341.902,163.448 399.999,163.448C458.096,163.448 505.543,210.894 505.543,268.991C505.543,327.282 458.096,374.535 399.999,374.535Z',
        scale: 0.05,
        anchor: new google.maps.Point(400, 800),
      }
      break
    case 'home':
      icon = {
        path: 'M3.012,10.981,3,11H5v9a1,1,0,0,0,1,1H18a1,1,0,0,0,1-1V11h2a1,1,0,0,0,.555-1.832l-9-6a1,1,0,0,0-1.11,0l-9,6a1,1,0,0,0-.277,1.387A.98.98,0,0,0,3.012,10.981ZM10,14a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v5H10Z',
        scale: 1.8,
        anchor: new google.maps.Point(12, 21),
      }
      break
    default:
      icon = {
        path: 'M399.999,0C240.037,0 110.867,129.17 110.867,289.132C110.867,344.905 128.684,398.161 153.472,441.926L341.902,768.044C353.715,789.347 377.535,799.998 399.999,799.998C422.465,799.998 445.121,789.347 458.096,768.044L646.526,442.12C671.508,398.351 689.131,346.063 689.131,289.321C689.131,129.364 559.961,0 399.999,0ZM399.999,374.535C341.902,374.535 294.455,327.089 294.455,268.991C294.455,210.894 341.902,163.448 399.999,163.448C458.096,163.448 505.543,210.894 505.543,268.991C505.543,327.282 458.096,374.535 399.999,374.535Z',
        scale: 0.05,
        anchor: new google.maps.Point(400, 800),
      }
  }
  return icon
}

// Team Roles
export const availableTeamRoles: TsInterface_UnspecifiedObject = {
  install_foreman: {
    key: 'install_foreman',
    value: s_INSTALL_FOREMAN,
    icon: 'helmet-safety',
    icon_type: 'solid',
  },
  install_lead: {
    key: 'install_lead',
    value: s_INSTALL_LEAD,
    icon: 'helmet-safety',
    icon_type: 'light',
  },
  installer: {
    key: 'installer',
    value: s_INSTALLER,
    icon: 'user-helmet-safety',
    icon_type: 'solid',
  },
  sow_foreman: {
    key: 'sow_foreman',
    value: s_SOW_FOREMAN,
    icon: 'helmet-safety',
    icon_type: 'solid',
  },
  sow_helper: {
    key: 'sow_helper',
    value: s_SOW_HELPER,
    icon: 'user-helmet-safety',
    icon_type: 'solid',
  },
}

// @ts-expect-error - TODO: reason for error
const returnFormattedTeamScheduleData = (dateKey, teamKey, events) => {
  // filter events by team and date
  // @ts-expect-error - TODO: reason for error
  const filteredEvents = events.filter((event) => {
    return event.associated_team_key === teamKey && event.task_completion_scheduled_dates.includes(dateKey)
  })
  // add selected_date_start_time, selected_date_end_time, duration, and type properties and sort by start time
  // @ts-expect-error - TODO: reason for error
  filteredEvents.forEach((event) => {
    const startTime = event.task_completion_scheduled_start_times[dateKey]
    const endTime = event.task_completion_scheduled_end_times[dateKey]
    event.selected_date_start_time = startTime
    event.selected_date_end_time = endTime
    event.duration = endTime - startTime
    event.type = 'event'
  })
  // @ts-expect-error - TODO: reason for error
  filteredEvents.sort((a, b) => a.selected_date_start_time - b.selected_date_start_time)
  // create data array with breaks and durations
  // @ts-expect-error - TODO: reason for error
  const data = []
  for (let i = 0; i < filteredEvents.length; i++) {
    const event = filteredEvents[i]
    data.push(event)
    if (i !== filteredEvents.length - 1) {
      const nextEvent = filteredEvents[i + 1]
      const breakStart = event.selected_date_end_time
      const breakEnd = nextEvent.selected_date_start_time
      if (breakEnd > breakStart) {
        const breakDuration = breakEnd - breakStart
        const breakEvent = {
          selected_date_start_time: breakStart,
          selected_date_end_time: breakEnd,
          duration: breakDuration,
          name: 'Break',
          type: 'break',
        }
        data.push(breakEvent)
      }
    }
  }
  // find overlapping events and add properties
  data.forEach((event, index) => {
    const overlapEvents = {}
    // @ts-expect-error - TODO: reason for error
    data.forEach((otherEvent, otherIndex) => {
      if (index !== otherIndex) {
        const otherStartTime = otherEvent.selected_date_start_time
        const otherEndTime = otherEvent.selected_date_end_time
        if (
          (event.selected_date_start_time >= otherStartTime && event.selected_date_start_time < otherEndTime) ||
          (event.selected_date_end_time > otherStartTime && event.selected_date_end_time <= otherEndTime)
        ) {
          event.overlap = true
          // @ts-expect-error - TODO: reason for error
          overlapEvents[otherEvent.key] = true
        }
      }
    })
    event.overlap_events = overlapEvents
  })
  // return formatted data
  return {
    dateKey: dateKey,
    teamKey: teamKey,
    data: data,
  }
}

///////////////////////////////
// Functions
///////////////////////////////

// Copied to server
function convertTimeToNumber(input: string | number) {
  if (typeof input === 'number') {
    return input
  } else if (typeof input === 'string') {
    const timeRegex = /^(\d{1,2}):(\d{2})\s*([ap]m)$/i
    const match = input.match(timeRegex)
    if (match) {
      let hours = parseInt(match[1], 10)
      const minutes = parseInt(match[2], 10)
      const period = match[3].toLowerCase()
      // Convert to 24-hour format
      if (period === 'pm' && hours !== 12) {
        hours += 12
      } else if (period === 'am' && hours === 12) {
        hours = 0
      }
      // Calculate the decimal representation of the time
      const decimalTime = hours + minutes / 60
      return decimalTime
    }
  } else {
    return input
  }
}

// Copied to server
function checkForSpecifiedTimeOverlap(event1Start: number, event1End: number, event2Start: number, event2End: number, overlapPercent: number) {
  const event1Duration = event1End - event1Start
  const maxStart = Math.max(event1Start, event2Start)
  const minEnd = Math.min(event1End, event2End)
  const overlapDuration = Math.max(0, minEnd - maxStart)
  const event1Overlap = overlapDuration / event1Duration
  return event1Overlap >= overlapPercent
}

// Copied to server
const returnTeamDefinedTimeslotsForSpecifiedDayAndTeamType = (
  startDate: Date,
  endDate: Date,
  teams: TsInterface_UnspecifiedObject | TsInterface_UnspecifiedObject[],
  globalTeamTypeTimeslots: TsInterface_UnspecifiedObject,
  teamScheduledTimeslots: TsInterface_UnspecifiedObject,
  teamScheduledTimeOff: TsInterface_UnspecifiedObject,
  taskEvents: TsInterface_UnspecifiedObject,
  cancelledEvents: TsInterface_UnspecifiedObject,
): TsInterface_UnspecifiedObject => {
  // Possible Statuses of Events
  // not_scheduled
  // open_time_slot
  // time_off
  // task
  // cancelled_task
  // Instantiate Variables
  let overlapPercentTolerance = 0.51
  const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
  let definedTimeslots: TsInterface_UnspecifiedObject = {
    teams: {},
    aggregate_time_slots: {
      start_date: returnFormattedDateKey(startDate),
      end_date: returnFormattedDateKey(endDate),
      open_time_slots: {},
    },
    aggregate_rendered_task_keys: {},
    aggregate_tasks_not_rendering: {},
  }
  // Loop through all teams
  for (let loopTeamKeyOrIndex in teams) {
    // @ts-expect-error - TODO: reason for error
    let loopTeam = teams[loopTeamKeyOrIndex]
    let loopTeamKey = loopTeam.key
    definedTimeslots['teams'][loopTeamKey] = {
      key: loopTeamKey,
      name: loopTeam.name,
      associated_member_keys: loopTeam.associated_member_keys,
      associated_member_names: loopTeam.associated_member_names,
      associated_member_roles: loopTeam.associated_member_roles,
      team_type: loopTeam.team_type,
      days: {},
    }
    // Add each date to the definedTimeslots and then each team to each date
    let currentDate = new Date(startDate.getTime())
    while (currentDate <= endDate) {
      definedTimeslots['teams'][loopTeamKey]['days'][currentDate.toISOString().slice(0, 10)] = {
        key: currentDate.toISOString().slice(0, 10),
        base_time_slots: {},
        scheduled_time_off_without_slot: {},
        task_events_without_slot: {},
        cancelled_events_without_slot: {},
        rendered_events: {},
      }
      // Increment
      currentDate.setDate(currentDate.getDate() + 1)
    }
    // Loop through dates on each team
    for (let loopDateKey in definedTimeslots['teams'][loopTeamKey]['days']) {
      // Instantiate Variables
      let loopDate = new Date(loopDateKey)
      let loopTimezoneOffset = loopDate.getTimezoneOffset()
      loopDate.setMinutes(loopDate.getMinutes() + loopTimezoneOffset + 60 * 8)
      let loopDateDST = isDaylightSavings(loopDate)
      let dayOfWeek = daysOfWeek[loopDate.getDay()]
      let seasonKey = 'winter'
      if (loopDateDST === true) {
        seasonKey = 'summer'
      }
      // Add Timeslot to aggregate
      definedTimeslots['aggregate_time_slots']['open_time_slots'][loopDateKey] = {}
      // Add all time slots
      if (
        globalTeamTypeTimeslots != null &&
        globalTeamTypeTimeslots[loopTeam.team_type] != null &&
        globalTeamTypeTimeslots[loopTeam.team_type]['task_assignment_slots'] != null &&
        globalTeamTypeTimeslots[loopTeam.team_type]['task_assignment_slots'][seasonKey] != null
      ) {
        for (let loopGlobalTimeSlotKey in globalTeamTypeTimeslots[loopTeam.team_type]['task_assignment_slots'][seasonKey]) {
          let loopGlobalTimeSlot = globalTeamTypeTimeslots[loopTeam.team_type]['task_assignment_slots'][seasonKey][loopGlobalTimeSlotKey]
          let timeslotStatus = 'not_scheduled'
          let timeslotName = s_NOT_SCHEDULED
          if (
            teamScheduledTimeslots != null &&
            teamScheduledTimeslots[loopTeamKey] != null &&
            teamScheduledTimeslots[loopTeamKey][seasonKey] != null &&
            teamScheduledTimeslots[loopTeamKey][seasonKey][dayOfWeek] != null &&
            teamScheduledTimeslots[loopTeamKey][seasonKey][dayOfWeek][loopGlobalTimeSlotKey] === true
          ) {
            timeslotStatus = 'open_time_slot'
            timeslotName = s_OPEN
          }
          definedTimeslots['teams'][loopTeamKey]['days'][loopDateKey]['base_time_slots'][loopGlobalTimeSlotKey] = {
            key: getProp(loopGlobalTimeSlot, 'key', null),
            window_start_time: getProp(loopGlobalTimeSlot, 'window_start_time', null),
            window_end_time: getProp(loopGlobalTimeSlot, 'window_end_time', null),
            status: timeslotStatus,
            name: timeslotName,
          }
        }
      }
    }
  }
  // Loop through time off
  if (teamScheduledTimeOff != null) {
    for (let loopTimeOffEventKey in teamScheduledTimeOff) {
      let loopTimeOffEvent = teamScheduledTimeOff[loopTimeOffEventKey]
      if (loopTimeOffEvent != null && loopTimeOffEvent.associated_team_key != null && loopTimeOffEvent.timestamp_time_off != null) {
        let loopDate = returnDateFromUnknownDateFormat(loopTimeOffEvent.timestamp_time_off)
        let loopTimezoneOffset = loopDate.getTimezoneOffset()
        loopDate.setMinutes(loopDate.getMinutes() + loopTimezoneOffset + 60 * 8)
        let loopDateKey = returnFormattedDateKey(loopDate)
        if (
          loopTimeOffEvent.associated_scheduled_time_slot != null &&
          definedTimeslots != null &&
          definedTimeslots['teams'] != null &&
          definedTimeslots['teams'][loopTimeOffEvent.associated_team_key] != null &&
          definedTimeslots['teams'][loopTimeOffEvent.associated_team_key]['days'] != null &&
          definedTimeslots['teams'][loopTimeOffEvent.associated_team_key]['days'][loopDateKey] != null &&
          definedTimeslots['teams'][loopTimeOffEvent.associated_team_key]['days'][loopDateKey]['base_time_slots'] != null &&
          definedTimeslots['teams'][loopTimeOffEvent.associated_team_key]['days'][loopDateKey]['base_time_slots'][
            loopTimeOffEvent.associated_scheduled_time_slot
          ] != null
        ) {
          definedTimeslots['teams'][loopTimeOffEvent.associated_team_key]['days'][loopDateKey]['base_time_slots'][
            loopTimeOffEvent.associated_scheduled_time_slot
          ]['status'] = 'time_off'
          definedTimeslots['teams'][loopTimeOffEvent.associated_team_key]['days'][loopDateKey]['base_time_slots'][
            loopTimeOffEvent.associated_scheduled_time_slot
          ]['name'] = s_TIME_OFF
          definedTimeslots['teams'][loopTimeOffEvent.associated_team_key]['days'][loopDateKey]['base_time_slots'][
            loopTimeOffEvent.associated_scheduled_time_slot
          ]['event'] = loopTimeOffEvent
        } else if (
          definedTimeslots != null &&
          definedTimeslots['teams'] != null &&
          definedTimeslots['teams'][loopTimeOffEvent.associated_team_key] != null &&
          definedTimeslots['teams'][loopTimeOffEvent.associated_team_key]['days'] != null &&
          definedTimeslots['teams'][loopTimeOffEvent.associated_team_key]['days'][loopDateKey] != null
        ) {
          definedTimeslots['teams'][loopTimeOffEvent.associated_team_key]['days'][loopDateKey]['scheduled_time_off_without_slot'][loopTimeOffEventKey] =
            loopTimeOffEvent
        }
      }
    }
  }
  // Loop through task events
  if (taskEvents != null) {
    for (let loopTaskEventKey in taskEvents) {
      let loopTaskEvent = taskEvents[loopTaskEventKey]
      if (
        loopTaskEvent != null &&
        loopTaskEvent.associated_team_key != null &&
        loopTaskEvent.task_completion_scheduled_dates != null &&
        loopTaskEvent.task_completion_scheduled_dates[0] != null
      ) {
        let loopDate = returnDateFromUnknownDateFormat(loopTaskEvent.task_completion_scheduled_dates[0])
        let loopTimezoneOffset = loopDate.getTimezoneOffset()
        loopDate.setMinutes(loopDate.getMinutes() + loopTimezoneOffset + 60 * 8)
        let loopDateKey = returnFormattedDateKey(loopDate)
        if (
          loopTaskEvent.associated_scheduled_time_slot != null &&
          definedTimeslots != null &&
          definedTimeslots['teams'] != null &&
          definedTimeslots['teams'][loopTaskEvent.associated_team_key] != null &&
          definedTimeslots['teams'][loopTaskEvent.associated_team_key]['days'] != null &&
          definedTimeslots['teams'][loopTaskEvent.associated_team_key]['days'][loopDateKey] != null &&
          definedTimeslots['teams'][loopTaskEvent.associated_team_key]['days'][loopDateKey]['base_time_slots'] != null &&
          definedTimeslots['teams'][loopTaskEvent.associated_team_key]['days'][loopDateKey]['base_time_slots'][loopTaskEvent.associated_scheduled_time_slot] !=
            null
        ) {
          definedTimeslots['teams'][loopTaskEvent.associated_team_key]['days'][loopDateKey]['base_time_slots'][loopTaskEvent.associated_scheduled_time_slot][
            'status'
          ] = 'task'
          definedTimeslots['teams'][loopTaskEvent.associated_team_key]['days'][loopDateKey]['base_time_slots'][loopTaskEvent.associated_scheduled_time_slot][
            'name'
          ] = getProp(loopTaskEvent, 'name', null)
          definedTimeslots['teams'][loopTaskEvent.associated_team_key]['days'][loopDateKey]['base_time_slots'][loopTaskEvent.associated_scheduled_time_slot][
            'event'
          ] = loopTaskEvent
          definedTimeslots['aggregate_rendered_task_keys'][loopTaskEventKey] = true
        } else if (
          definedTimeslots != null &&
          definedTimeslots['teams'] != null &&
          definedTimeslots['teams'][loopTaskEvent.associated_team_key] != null &&
          definedTimeslots['teams'][loopTaskEvent.associated_team_key]['days'] != null &&
          definedTimeslots['teams'][loopTaskEvent.associated_team_key]['days'][loopDateKey] != null
        ) {
          definedTimeslots['teams'][loopTaskEvent.associated_team_key]['days'][loopDateKey]['task_events_without_slot'][loopTaskEventKey] = loopTaskEvent
        }
      }
    }
  }
  // Loop through cancelled events
  if (cancelledEvents != null) {
    for (let loopCancelledEventKey in cancelledEvents) {
      let loopCancelledEvent = cancelledEvents[loopCancelledEventKey]
      if (
        loopCancelledEvent != null &&
        loopCancelledEvent.associated_team_key != null &&
        loopCancelledEvent.task_completion_scheduled_dates != null &&
        loopCancelledEvent.task_completion_scheduled_dates[0] != null
      ) {
        let loopDate = returnDateFromUnknownDateFormat(loopCancelledEvent.task_completion_scheduled_dates[0])
        let loopTimezoneOffset = loopDate.getTimezoneOffset()
        loopDate.setMinutes(loopDate.getMinutes() + loopTimezoneOffset + 60 * 8)
        let loopDateKey = returnFormattedDateKey(loopDate)
        if (
          loopCancelledEvent.associated_scheduled_time_slot != null &&
          definedTimeslots != null &&
          definedTimeslots['teams'] != null &&
          definedTimeslots['teams'][loopCancelledEvent.associated_team_key] != null &&
          definedTimeslots['teams'][loopCancelledEvent.associated_team_key]['days'] != null &&
          definedTimeslots['teams'][loopCancelledEvent.associated_team_key]['days'][loopDateKey] != null &&
          definedTimeslots['teams'][loopCancelledEvent.associated_team_key]['days'][loopDateKey]['base_time_slots'] != null &&
          definedTimeslots['teams'][loopCancelledEvent.associated_team_key]['days'][loopDateKey]['base_time_slots'][
            loopCancelledEvent.associated_scheduled_time_slot
          ] != null
        ) {
          definedTimeslots['teams'][loopCancelledEvent.associated_team_key]['days'][loopDateKey]['base_time_slots'][
            loopCancelledEvent.associated_scheduled_time_slot
          ]['status'] = 'cancelled_task'
          definedTimeslots['teams'][loopCancelledEvent.associated_team_key]['days'][loopDateKey]['base_time_slots'][
            loopCancelledEvent.associated_scheduled_time_slot
          ]['name'] = getProp(loopCancelledEvent, 'name', null)
          definedTimeslots['teams'][loopCancelledEvent.associated_team_key]['days'][loopDateKey]['base_time_slots'][
            loopCancelledEvent.associated_scheduled_time_slot
          ]['event'] = loopCancelledEvent
        } else if (
          definedTimeslots != null &&
          definedTimeslots['teams'] != null &&
          definedTimeslots['teams'][loopCancelledEvent.associated_team_key] != null &&
          definedTimeslots['teams'][loopCancelledEvent.associated_team_key]['days'] != null &&
          definedTimeslots['teams'][loopCancelledEvent.associated_team_key]['days'][loopDateKey] != null
        ) {
          definedTimeslots['teams'][loopCancelledEvent.associated_team_key]['days'][loopDateKey]['cancelled_events_without_slot'][loopCancelledEventKey] =
            loopCancelledEvent
        }
      }
    }
  }
  // Loop through and combine everything
  for (let loopTeamKey in definedTimeslots.teams) {
    let loopTeam = definedTimeslots['teams'][loopTeamKey]
    for (let loopDateKey in loopTeam['days']) {
      let loopDate = loopTeam['days'][loopDateKey]
      for (let loopKey in loopDate['base_time_slots']) {
        let loopEvent = loopDate['base_time_slots'][loopKey]
        loopDate['rendered_events']['base_' + loopKey] = loopEvent
        loopDate['rendered_events']['base_' + loopKey]['date'] = loopDateKey
      }
      for (let loopKey in loopDate['scheduled_time_off_without_slot']) {
        let loopEvent = loopDate['scheduled_time_off_without_slot'][loopKey]
        if (
          loopEvent != null &&
          loopEvent['task_completion_scheduled_start_times'] != null &&
          loopEvent['task_completion_scheduled_start_times'][loopDateKey] != null &&
          loopEvent['task_completion_scheduled_end_times'] != null &&
          loopEvent['task_completion_scheduled_end_times'][loopDateKey] != null
        ) {
          loopDate['rendered_events']['time_off_' + loopKey] = {
            key: 'time_off_' + loopKey,
            date: loopDateKey,
            window_start_time: convertTimeToNumber(loopEvent['task_completion_scheduled_start_times'][loopDateKey]),
            window_end_time: convertTimeToNumber(loopEvent['task_completion_scheduled_end_times'][loopDateKey]),
            status: 'time_off',
            name: s_TIME_OFF,
            event: loopEvent,
            mismatch: true,
          }
        }
      }
      for (let loopKey in loopDate['task_events_without_slot']) {
        let loopEvent = loopDate['task_events_without_slot'][loopKey]
        if (
          loopEvent != null &&
          loopEvent['task_completion_scheduled_start_times'] != null &&
          loopEvent['task_completion_scheduled_start_times'][loopDateKey] != null &&
          loopEvent['task_completion_scheduled_end_times'] != null &&
          loopEvent['task_completion_scheduled_end_times'][loopDateKey] != null
        ) {
          loopDate['rendered_events']['task_' + loopKey] = {
            key: 'task_' + loopKey,
            date: loopDateKey,
            window_start_time: convertTimeToNumber(loopEvent['task_completion_scheduled_start_times'][loopDateKey]),
            window_end_time: convertTimeToNumber(loopEvent['task_completion_scheduled_end_times'][loopDateKey]),
            status: 'task',
            name: getProp(loopEvent, 'name', null),
            event: loopEvent,
            mismatch: true,
          }
          definedTimeslots['aggregate_rendered_task_keys'][loopKey] = true
        }
      }
      for (let loopKey in loopDate['cancelled_events_without_slot']) {
        let loopEvent = loopDate['cancelled_events_without_slot'][loopKey]
        if (
          loopEvent != null &&
          loopEvent['task_completion_scheduled_start_times'] != null &&
          loopEvent['task_completion_scheduled_start_times'][loopDateKey] != null &&
          loopEvent['task_completion_scheduled_end_times'] != null &&
          loopEvent['task_completion_scheduled_end_times'][loopDateKey] != null
        ) {
          loopDate['rendered_events']['cancelled_task_' + loopKey] = {
            key: 'cancelled_task_' + loopKey,
            date: loopDateKey,
            window_start_time: convertTimeToNumber(loopEvent['task_completion_scheduled_start_times'][loopDateKey]),
            window_end_time: convertTimeToNumber(loopEvent['task_completion_scheduled_end_times'][loopDateKey]),
            status: 'cancelled_task',
            name: getProp(loopEvent, 'name', null),
            event: loopEvent,
            mismatch: true,
          }
        }
      }
    }
  }
  // Loop through teams
  for (let loopTeamKey in definedTimeslots['teams']) {
    let loopTeam = definedTimeslots['teams'][loopTeamKey]
    let hasTeamMember = false
    if (loopTeam != null && loopTeam.associated_member_keys != null) {
      for (let loopTeamMemberKey in loopTeam['associated_member_keys']) {
        if (loopTeam['associated_member_keys'][loopTeamMemberKey] === true) {
          hasTeamMember = true
        }
      }
    }
    if (loopTeam != null && loopTeam['days'] != null && hasTeamMember === true) {
      // Loop through days
      for (let loopDayKey in loopTeam['days']) {
        let loopDay = loopTeam['days'][loopDayKey]
        if (definedTimeslots['aggregate_time_slots']['open_time_slots'][loopDayKey] == null) {
          definedTimeslots['aggregate_time_slots']['open_time_slots'][loopDayKey] = {}
        }
        if (loopDay != null && loopDay['base_time_slots'] != null) {
          // Loop through Times
          for (let loopTimeKey in loopDay['base_time_slots']) {
            let loopTime = loopDay['base_time_slots'][loopTimeKey]
            // Find all "open_time_slot" in base_time_slots
            let overlappingEvent = false
            if (loopTime['status'] === 'open_time_slot') {
              // Check for overlapping events that shuld prevent time slot availability
              if (loopDay['rendered_events'] != null) {
                for (let loopRenderedEventKey in loopDay['rendered_events']) {
                  let loopRenderedEvent = loopDay['rendered_events'][loopRenderedEventKey]
                  if (
                    loopRenderedEvent != null &&
                    loopRenderedEvent.mismatch === true &&
                    loopRenderedEvent.window_end_time != null &&
                    loopRenderedEvent.window_start_time != null &&
                    (loopRenderedEvent.status === 'task' || loopRenderedEvent.status === 'time_off')
                  ) {
                    let overlappingEventCheck = checkForSpecifiedTimeOverlap(
                      loopTime.window_start_time,
                      loopTime.window_end_time,
                      loopRenderedEvent.window_start_time,
                      loopRenderedEvent.window_end_time,
                      overlapPercentTolerance,
                    )
                    if (overlappingEventCheck === true) {
                      overlappingEvent = true
                      definedTimeslots['teams'][loopTeamKey]['days'][loopDayKey]['rendered_events']['base_' + loopTimeKey]['name'] =
                        s_BLOCKED_BY_OVERLAPPING_EVENT
                    }
                  }
                }
              }
              if (overlappingEvent === false) {
                if (definedTimeslots['aggregate_time_slots']['open_time_slots'][loopDayKey][loopTimeKey] == null) {
                  definedTimeslots['aggregate_time_slots']['open_time_slots'][loopDayKey][loopTimeKey] = 0
                }
                definedTimeslots['aggregate_time_slots']['open_time_slots'][loopDayKey][loopTimeKey]++
              }
            }
          }
        }
      }
    }
  }
  // Get Tasks that aren't rendering
  for (let loopTaskKey in taskEvents) {
    if (definedTimeslots['aggregate_rendered_task_keys'][loopTaskKey] !== true) {
      definedTimeslots['aggregate_tasks_not_rendering'][loopTaskKey] = taskEvents[loopTaskKey]
    }
  }
  return definedTimeslots
}

const rJSX_Weekday = (weekdayNumber: number): JSX.Element => {
  let weekdayJSX = <></>
  switch (weekdayNumber) {
    case 0:
      weekdayJSX = s_SUNDAY
      break
    case 1:
      weekdayJSX = s_MONDAY
      break
    case 2:
      weekdayJSX = s_TUESDAY
      break
    case 3:
      weekdayJSX = s_WEDNESDAY
      break
    case 4:
      weekdayJSX = s_THURSDAY
      break
    case 5:
      weekdayJSX = s_FRIDAY
      break
    case 6:
      weekdayJSX = s_SATURDAY
      break
  }
  return weekdayJSX
}

function timeToDecimal(time: string | number): number {
  if (typeof time === 'number') {
    return time
  } else {
    const [hoursMinutes, amPm] = time.split(' ')
    const [hours, minutes] = hoursMinutes.split(':')
    let hoursInDecimal = (parseInt(hours, 10) % 12) + parseInt(minutes, 10) / 60
    if (amPm != null) {
      return amPm.toLowerCase() === 'pm' ? hoursInDecimal + 12 : hoursInDecimal
    } else {
      if (time.toLowerCase().includes('am')) {
        // Nothing
      } else if (time.toLowerCase().includes('pm')) {
        hoursInDecimal += 12
      }
      return hoursInDecimal
    }
  }
}

const addCoordinatesToTask = (clientKey: string, taskKey: string, project: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (project != null && project['location_latitude'] != null && project['location_longitude'] != null) {
      // Save Coordinates to Task
      let updateObject = {
        location_latitude: project['location_latitude'],
        location_longitude: project['location_longitude'],
        timestamp_last_updated: new Date(),
      }
      DatabaseSetMergeDocument(DatabaseRef_Task_Document(clientKey, taskKey), updateObject)
        .then((res_DSMD) => {
          // Nothing
          resolve(res_DSMD)
        })
        .catch((rej_DSMD) => {
          // uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
          reject(rej_DSMD)
        })
    } else if (
      project != null &&
      project['location_address'] != null &&
      project['location_city'] != null &&
      project['location_state'] != null &&
      project['location_zip'] != null
    ) {
      // Get Coordinates
      let address = ''
      if (project['location_address'] != null) {
        address += project['location_address'] + ' '
      }
      if (project['location_city'] != null) {
        address += project['location_city'] + ' '
      }
      if (project['location_state'] != null) {
        address += project['location_state'] + ' '
      }
      if (project['location_zip'] != null) {
        address += project['location_zip'] + ' '
      }
      // Have Google Maps Geocode it
      let geocoder = new google.maps.Geocoder()
      geocoder.geocode({ address: address }, (results: any, status: any) => {
        // If there is a result
        if (status === 'OK') {
          // Set Latitude and Longitude
          let projectUpdateObject = {
            location_latitude: results[0].geometry.location.lat(),
            location_longitude: results[0].geometry.location.lng(),
          }
          let taskUpdateObject = {
            location_latitude: results[0].geometry.location.lat(),
            location_longitude: results[0].geometry.location.lng(),
            timestamp_last_updated: new Date(),
          }
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
            { type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, taskKey), data: taskUpdateObject },
            { type: 'setMerge', ref: DatabaseRef_Project_Document(clientKey, project.key), data: projectUpdateObject },
          ]
          DatabaseBatchUpdate(updateArray)
            .then((res_DBU) => {
              resolve(res_DBU)
            })
            .catch((rej_DBU) => {
              reject(rej_DBU)
            })
        } else {
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CALCULATE_COORDIATES,
              details: status,
              code: 'ER-D-TDI-ACTT-01',
            },
          })
        }
      })
    } else {
      // Not enough data to generate GPS Coordinates
      reject({ success: false })
    }
  })
}

function hasVisibleRole(visibleUserTypes: string[], taskRoles: TsInterface_UnspecifiedObject) {
  for (let i = 0; i < visibleUserTypes.length; i++) {
    const role = visibleUserTypes[i]
    if (taskRoles[role] === true) {
      return true
    }
  }
  return false
}

// Task Reassignment Functions
function checkIfDateHasOccured(dateString: string) {
  const inputDate = new Date(dateString)
  const now = new Date()
  const currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1) // Annoying workaround
  // Adjust the input date according to the browser's timezone
  inputDate.setMinutes(inputDate.getMinutes() - inputDate.getTimezoneOffset())
  return inputDate < currentDate
}

const assignFreeformTaskToDifferentTeam = (
  clientKey: string,
  taskKey: string,
  newTeamKey: string,
  newTeamName: string,
  newTeamMemberKeys: TsInterface_UnspecifiedObject,
  newTeamMemberNames: TsInterface_UnspecifiedObject,
  newTeamMemberRoles: TsInterface_UnspecifiedObject,
  taskScheduledDates: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (
      clientKey != null &&
      taskKey != null &&
      newTeamKey != null &&
      newTeamMemberKeys != null &&
      objectToArray(newTeamMemberKeys).length > 0 &&
      newTeamMemberNames != null &&
      objectToArray(newTeamMemberNames).length > 0 &&
      taskScheduledDates != null &&
      objectToArray(taskScheduledDates).length > 0
    ) {
      // Generate Update Object
      let newTeamKeys: TsInterface_UnspecifiedObject = {}
      let newTeamNames: TsInterface_UnspecifiedObject = {}
      for (let loopDateIndex in taskScheduledDates) {
        let loopDateKey = taskScheduledDates[loopDateIndex]
        newTeamKeys[loopDateKey] = newTeamMemberKeys
        newTeamNames[loopDateKey] = newTeamMemberNames
      }
      let teamRolesAcrossDates: TsInterface_UnspecifiedObject = {}
      for (let loopDateIndex in taskScheduledDates) {
        let loopDateKey = taskScheduledDates[loopDateIndex]
        teamRolesAcrossDates[loopDateKey] = newTeamMemberRoles
      }
      let updateObject: TsInterface_UnspecifiedObject = {
        associated_team_key: newTeamKey,
        associated_team_name: newTeamName,
        task_completion_scheduled_team_keys: newTeamKeys,
        task_completion_scheduled_team_names: newTeamNames,
        // task_completion_scheduled_team_roles: newTeamMemberRoles,
        task_completion_scheduled_team_roles: teamRolesAcrossDates,
        timestamp_last_updated: new Date(),
      }
      // Save to Database
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [{ type: 'update', ref: DatabaseRef_Task_Document(clientKey, taskKey), data: updateObject }]
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_REASSIGN_TASK,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-TDI-AFTTDT-01',
        },
      })
    }
  })
}

const assignDefinedTaskToDifferentTeam = (
  clientKey: string,
  taskKey: string,
  newTeamKey: string,
  newTeamName: string,
  newTeamMemberKeys: TsInterface_UnspecifiedObject,
  newTeamMemberNames: TsInterface_UnspecifiedObject,
  newTeamMemberRoles: TsInterface_UnspecifiedObject,
  taskScheduledDates: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (
      clientKey != null &&
      taskKey != null &&
      newTeamKey != null &&
      newTeamMemberKeys != null &&
      objectToArray(newTeamMemberKeys).length > 0 &&
      newTeamMemberNames != null &&
      objectToArray(newTeamMemberNames).length > 0 &&
      taskScheduledDates != null &&
      objectToArray(taskScheduledDates).length > 0
    ) {
      // Generate Update Object
      let newTeamKeys: TsInterface_UnspecifiedObject = {}
      let newTeamNames: TsInterface_UnspecifiedObject = {}
      for (let loopDateIndex in taskScheduledDates) {
        let loopDateKey = taskScheduledDates[loopDateIndex]
        newTeamKeys[loopDateKey] = newTeamMemberKeys
        newTeamNames[loopDateKey] = newTeamMemberNames
      }
      let teamRolesAcrossDates: TsInterface_UnspecifiedObject = {}
      for (let loopDateIndex in taskScheduledDates) {
        let loopDateKey = taskScheduledDates[loopDateIndex]
        teamRolesAcrossDates[loopDateKey] = newTeamMemberRoles
      }
      let updateObject: TsInterface_UnspecifiedObject = {
        associated_team_key: newTeamKey,
        associated_team_name: newTeamName,
        task_completion_scheduled_team_keys: newTeamKeys,
        task_completion_scheduled_team_names: newTeamNames,
        // task_completion_scheduled_team_roles: newTeamMemberRoles,
        task_completion_scheduled_team_roles: teamRolesAcrossDates,
        timestamp_last_updated: new Date(),
      }
      // Save to Database
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [{ type: 'update', ref: DatabaseRef_Task_Document(clientKey, taskKey), data: updateObject }]
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_REASSIGN_TASK,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-TDI-ADTTDT-01',
        },
      })
    }
  })
}

const tradeDefinedTasksBetweenDifferentTeams = (
  clientKey: string,
  taskKey1: string,
  newTeamKey1: string,
  newTeamName1: string,
  newTeamMemberKeys1: TsInterface_UnspecifiedObject,
  newTeamMemberNames1: TsInterface_UnspecifiedObject,
  newTeamMemberRoles1: TsInterface_UnspecifiedObject,
  taskScheduledDates1: TsInterface_UnspecifiedObject,
  taskKey2: string,
  newTeamKey2: string,
  newTeamName2: string,
  newTeamMemberKeys2: TsInterface_UnspecifiedObject,
  newTeamMemberNames2: TsInterface_UnspecifiedObject,
  newTeamMemberRoles2: TsInterface_UnspecifiedObject,
  taskScheduledDates2: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (
      clientKey != null &&
      taskKey1 != null &&
      newTeamKey1 != null &&
      newTeamMemberKeys1 != null &&
      objectToArray(newTeamMemberKeys1).length > 0 &&
      newTeamMemberNames1 != null &&
      objectToArray(newTeamMemberNames1).length > 0 &&
      taskScheduledDates1 != null &&
      objectToArray(taskScheduledDates1).length > 0 &&
      taskKey2 != null &&
      newTeamKey2 != null &&
      newTeamMemberKeys2 != null &&
      objectToArray(newTeamMemberKeys2).length > 0 &&
      newTeamMemberNames2 != null &&
      objectToArray(newTeamMemberNames2).length > 0 &&
      taskScheduledDates2 != null &&
      objectToArray(taskScheduledDates2).length > 0 &&
      taskKey1 !== taskKey2 &&
      newTeamKey1 !== newTeamKey2
    ) {
      // Generate Update Object
      let newTeamKeys1: TsInterface_UnspecifiedObject = {}
      let newTeamNames1: TsInterface_UnspecifiedObject = {}
      for (let loopDateIndex in taskScheduledDates1) {
        let loopDateKey = taskScheduledDates1[loopDateIndex]
        newTeamKeys1[loopDateKey] = newTeamMemberKeys1
        newTeamNames1[loopDateKey] = newTeamMemberNames2
      }
      let teamRolesAcrossDates1: TsInterface_UnspecifiedObject = {}
      for (let loopDateIndex in taskScheduledDates1) {
        let loopDateKey = taskScheduledDates1[loopDateIndex]
        teamRolesAcrossDates1[loopDateKey] = newTeamMemberRoles1
      }
      let updateObject1: TsInterface_UnspecifiedObject = {
        associated_team_key: newTeamKey1,
        associated_team_name: newTeamName1,
        task_completion_scheduled_team_keys: newTeamKeys1,
        task_completion_scheduled_team_names: newTeamNames1,
        // task_completion_scheduled_team_roles: newTeamMemberRoles1,
        task_completion_scheduled_team_roles: teamRolesAcrossDates1,
        timestamp_last_updated: new Date(),
      }
      let newTeamKeys2: TsInterface_UnspecifiedObject = {}
      let newTeamNames2: TsInterface_UnspecifiedObject = {}
      for (let loopDateIndex in taskScheduledDates2) {
        let loopDateKey = taskScheduledDates2[loopDateIndex]
        newTeamKeys2[loopDateKey] = newTeamMemberKeys1
        newTeamNames2[loopDateKey] = newTeamMemberNames2
      }
      let teamRolesAcrossDates2: TsInterface_UnspecifiedObject = {}
      for (let loopDateIndex in taskScheduledDates2) {
        let loopDateKey = taskScheduledDates2[loopDateIndex]
        teamRolesAcrossDates2[loopDateKey] = newTeamMemberRoles2
      }
      let updateObject2: TsInterface_UnspecifiedObject = {
        associated_team_key: newTeamKey2,
        associated_team_name: newTeamName2,
        task_completion_scheduled_team_keys: newTeamKeys2,
        task_completion_scheduled_team_names: newTeamNames2,
        // task_completion_scheduled_team_roles: newTeamMemberRoles2,
        task_completion_scheduled_team_roles: teamRolesAcrossDates2,
        timestamp_last_updated: new Date(),
      }
      // Save to Database
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
        { type: 'update', ref: DatabaseRef_Task_Document(clientKey, taskKey1), data: updateObject1 },
        { type: 'update', ref: DatabaseRef_Task_Document(clientKey, taskKey2), data: updateObject2 },
      ]
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_TRADE_TASKS,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-TDI-TDTBDT-01',
        },
      })
    }
  })
}

// Drag and Drop
const DraggableUnassignedTask: React.FC<TsInterface_DraggableUnassignedTask> = memo(function DraggableUnassignedTask({
  // eslint-disable-next-line react/prop-types
  task,
  // eslint-disable-next-line react/prop-types
  taskKey,
  // eslint-disable-next-line react/prop-types
  name,
  // eslint-disable-next-line react/prop-types
  type,
  // eslint-disable-next-line react/prop-types
  viewNotes,
  // eslint-disable-next-line react/prop-types
  onDoubleClick,
}) {
  // Hooks
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Variables
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: task,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.2 : 1,
      }),
    }),
    [name, type],
  )
  let backgroundColor = themeVariables.background_json
  if (type != null && schedulingTeamTypes != null && schedulingTeamTypes[type] != null && schedulingTeamTypes[type]['color'] != null) {
    backgroundColor = schedulingTeamTypes[type]['color']
  }

  // Functions

  // JSX
  let notesJSX = rJSX_TaskCalendarNotes(task, null, viewNotes, uc_setUserInterface_FormDialogDisplay)

  // Return JSX
  return (
    <Box
      ref={drag}
      style={{ opacity }}
      // data-taskkey={ task.key }
      data-taskkey={taskKey}
      className="tw-inline-block tw-p-1 tw-rounded-md tw-m-1"
      sx={{
        backgroundColor: backgroundColor,
      }}
      onClick={(event) => {
        if (event.detail === 2) {
          // Double Click
          onDoubleClick(task)
        }
      }}
    >
      {/* eslint-disable-next-line react/prop-types */}
      <Box>{task.associated_project_id_number}</Box>
      <Divider />
      <Box>
        {/* { task.abbreviation } - { task.name } */}
        {/* eslint-disable-next-line react/prop-types */}
        {task.name}
      </Box>
      {notesJSX}
    </Box>
  )
})

const DroppableTeamSlot: React.FC<TsInterface_DroppableTeamSlot> = memo(function DroppableTeamSlot({
  // eslint-disable-next-line react/prop-types
  team,
  // eslint-disable-next-line react/prop-types
  definedTimeSlotKey,
  // eslint-disable-next-line react/prop-types
  taskAssignmentType,
  // eslint-disable-next-line react/prop-types
  allScheduledTasks,
  // eslint-disable-next-line react/prop-types
  teamTypeSettings,
  // eslint-disable-next-line react/prop-types
  allTeams,
  // eslint-disable-next-line react/prop-types
  assignDate,
  // eslint-disable-next-line react/prop-types
  assignTime,
  // eslint-disable-next-line react/prop-types
  accept,
  // eslint-disable-next-line react/prop-types
  children,
  // eslint-disable-next-line react/prop-types
  sx,
  // eslint-disable-next-line react/prop-types
  uc_setUserInterface_CustomDialogDisplay,
}) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: (task: TsInterface_UnspecifiedObject) => {
      uc_setUserInterface_CustomDialogDisplay({
        display: true,
        dialog: {
          dialog_jsx: (
            <Box>
              <AssignTaskToTeamCustomDialog
                definedTimeSlotKey={definedTimeSlotKey}
                taskAssignmentType={taskAssignmentType}
                // eslint-disable-next-line react/prop-types
                teamKey={team.key}
                // eslint-disable-next-line react/prop-types
                teamType={team.team_type}
                taskKey={task.key}
                assignDate={assignDate}
                assignTime={assignTime}
                allScheduledTasks={allScheduledTasks}
                allTeams={allTeams}
                teamTypeSettings={teamTypeSettings}
              />
            </Box>
          ),
          settings: {
            max_width: 'lg',
          },
        },
      })
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  const isActive = isOver && canDrop
  let backgroundColor = 'rgba(0,0,0,0)'
  if (isActive) {
    // backgroundColor = themeVariables.success_wash
    backgroundColor = themeVariables.info_light
  } else if (canDrop) {
    backgroundColor = themeVariables.warning_dark
  }
  // eslint-disable-next-line react/prop-types
  sx['background'] = backgroundColor
  // Return JSX
  return (
    <Box
      ref={drop}
      style={{ backgroundColor }}
      // eslint-disable-next-line react/prop-types
      data-teamkey={team.key}
      sx={sx}
    >
      {children}
    </Box>
  )
})

const DraggableAssignedDefinedTimeslotTask: React.FC<TsInterface_DraggableAssignedDefinedTimeslotTask> = memo(function DraggableAssignedDefinedTimeslotTask({
  // eslint-disable-next-line react/prop-types
  task,
  // eslint-disable-next-line react/prop-types
  taskKey,
  // eslint-disable-next-line react/prop-types
  type,
  // eslint-disable-next-line react/prop-types
  childrenJSX,
}) {
  // Hooks

  // Variables
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: task,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.2 : 1,
      }),
    }),
    [],
    // [name, type],
  )

  // Functions

  // JSX

  // Return JSX
  return (
    <Box
      ref={drag}
      style={{ opacity }}
      data-taskkey={taskKey}
      // data-taskkey={ task.key }
    >
      {childrenJSX}
    </Box>
  )
})

const DraggableAssignedFreeformTimeTask: React.FC<TsInterface_DraggableAssignedFreeformTimeTask> = memo(function DraggableAssignedFreeformTimeTask({
  // eslint-disable-next-line react/prop-types
  task,
  // eslint-disable-next-line react/prop-types
  type,
  // eslint-disable-next-line react/prop-types
  childrenJSX,
}) {
  // Hooks

  // Variables
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: task,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.2 : 1,
      }),
    }),
    [],
    // [name, type],
  )

  // Functions

  // JSX

  // Return JSX
  return (
    <Box
      ref={drag}
      style={{ opacity }}
      // eslint-disable-next-line react/prop-types
      data-taskkey={task.key}
    >
      {childrenJSX}
    </Box>
  )
})

const DroppableReassignTeamFreeformSlot: React.FC<TsInterface_DroppableReassignTeamFreeformSlot> = memo(function DroppableReassignTeamFreeformSlot({
  // eslint-disable-next-line react/prop-types
  team,
  // eslint-disable-next-line react/prop-types
  accept,
  // eslint-disable-next-line react/prop-types
  childrenJSX,
  // eslint-disable-next-line react/prop-types
  sx,
  // eslint-disable-next-line react/prop-types
  setLastTaskSwappedTimestamp,
}) {
  // Hooks
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)

  // Variables && Functions
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: (task: TsInterface_UnspecifiedObject) => {
      // Get Keys
      let taskKey = getProp(task, 'key', null)
      let currentTeamKey = getProp(task, 'associated_team_key', null)
      let newTeamKey = getProp(team, 'key', null)
      let newTeamName = getProp(team, 'name', null)
      let newTeamMemberKeys = getProp(team, 'associated_member_keys', {})
      let newTeamMemberNames = getProp(team, 'associated_member_names', {})
      let newTeamMemberRoles = getProp(team, 'associated_member_roles', {})
      let taskScheduledDates = getProp(task, 'task_completion_scheduled_dates', null)
      // Make sure that it's a different team and not in the past, has team members, etc
      if (
        taskKey != null &&
        currentTeamKey != null &&
        newTeamKey != null &&
        currentTeamKey !== newTeamKey &&
        taskScheduledDates != null &&
        taskScheduledDates[0] != null &&
        checkIfDateHasOccured(taskScheduledDates[0]) === false &&
        objectToArray(newTeamMemberKeys).length > 0
      ) {
        // Confirm
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'info',
            header: s_REASSIGN_TASK,
            icon: (
              <Icon
                icon="right-left"
                type="solid"
              />
            ),
            submit_text: s_REASSIGN,
            text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_REASSIGN_THIS_TASK,
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    assignFreeformTaskToDifferentTeam(
                      res_GCK.clientKey,
                      taskKey,
                      newTeamKey,
                      newTeamName,
                      newTeamMemberKeys,
                      newTeamMemberNames,
                      newTeamMemberRoles,
                      taskScheduledDates,
                    )
                      .then((res_AFTTDT) => {
                        setLastTaskSwappedTimestamp(new Date())
                        resolve(res_AFTTDT)
                      })
                      .catch((rej_AFTTDT) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_AFTTDT.error })
                        reject(rej_AFTTDT)
                      })
                  })
                  .catch((rej_GCK) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
        })
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  const isActive = isOver && canDrop
  let backgroundColor = 'rgba(0,0,0,0)'
  if (isActive) {
    // backgroundColor = themeVariables.success_wash
    backgroundColor = themeVariables.info_light
  } else if (canDrop) {
    backgroundColor = themeVariables.warning_dark
  }
  // eslint-disable-next-line react/prop-types
  sx['background'] = backgroundColor

  // Return JSX
  return (
    <Box
      ref={drop}
      style={{ backgroundColor }}
      // eslint-disable-next-line react/prop-types
      data-teamkey={team.key}
      sx={sx}
    >
      {childrenJSX}
    </Box>
  )
})

const DroppableReassignTeamDefinedSlot: React.FC<TsInterface_DroppableReassignTeamDefinedSlot> = memo(function DroppableReassignTeamDefinedSlot({
  // eslint-disable-next-line react/prop-types
  team,
  // eslint-disable-next-line react/prop-types
  accept,
  // eslint-disable-next-line react/prop-types
  childrenJSX,
  // eslint-disable-next-line react/prop-types
  sx,
  // eslint-disable-next-line react/prop-types
  setLastTaskSwappedTimestamp,
}) {
  // Hooks
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)

  // Variables && Functions
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: (task: TsInterface_UnspecifiedObject) => {
      // Get Keys
      let actualTask = getProp(task, 'actual_event', {})
      let taskKey = getProp(task, 'key', null)
      let currentTeamKey = getProp(actualTask, 'associated_team_key', null)
      let newTeamKey = getProp(team, 'key', null)
      let newTeamName = getProp(team, 'name', null)
      let newTeamMemberKeys = getProp(team, 'associated_member_keys', {})
      let newTeamMemberNames = getProp(team, 'associated_member_names', {})
      let newTeamMemberRoles = getProp(team, 'associated_member_roles', {})
      let taskScheduledDates = getProp(actualTask, 'task_completion_scheduled_dates', null)
      // Make sure that it's a different team and not in the past, has team members, etc
      if (
        taskKey != null &&
        currentTeamKey != null &&
        newTeamKey != null &&
        currentTeamKey !== newTeamKey &&
        taskScheduledDates != null &&
        taskScheduledDates[0] != null &&
        checkIfDateHasOccured(taskScheduledDates[0]) === false &&
        objectToArray(newTeamMemberKeys).length > 0
      ) {
        // Confirm
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'info',
            header: s_REASSIGN_TASK,
            icon: (
              <Icon
                icon="right-left"
                type="solid"
              />
            ),
            submit_text: s_REASSIGN,
            text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_REASSIGN_THIS_TASK,
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    assignDefinedTaskToDifferentTeam(
                      res_GCK.clientKey,
                      taskKey,
                      newTeamKey,
                      newTeamName,
                      newTeamMemberKeys,
                      newTeamMemberNames,
                      newTeamMemberRoles,
                      taskScheduledDates,
                    )
                      .then((res_ADTTDT) => {
                        setLastTaskSwappedTimestamp(new Date())
                        resolve(res_ADTTDT)
                      })
                      .catch((rej_ADTTDT) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_ADTTDT.error })
                        reject(rej_ADTTDT)
                      })
                  })
                  .catch((rej_GCK) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
        })
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  const isActive = isOver && canDrop
  let backgroundColor = 'rgba(0,0,0,0)'
  if (isActive) {
    // backgroundColor = themeVariables.success_wash
    backgroundColor = themeVariables.info_light
  } else if (canDrop) {
    backgroundColor = themeVariables.warning_dark
  }
  // eslint-disable-next-line react/prop-types
  sx['background'] = backgroundColor

  // Return JSX
  return (
    <Box
      ref={drop}
      style={{ backgroundColor }}
      // eslint-disable-next-line react/prop-types
      data-teamkey={team.key}
      sx={sx}
    >
      {childrenJSX}
    </Box>
  )
})

const DroppableReassignTeamWithTaskDefinedSlot: React.FC<TsInterface_DroppableReassignTeamWithTaskDefinedSlot> = memo(
  // eslint-disable-next-line react/prop-types
  function DroppableReassignTeamWithTaskDefinedSlot({ team, allTeams, calendarRenderData, accept, childrenJSX, windowKey, sx, setLastTaskSwappedTimestamp }) {
    // Hooks
    const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
    const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
    const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)

    // Variables && Functions
    const [{ isOver, canDrop }, drop] = useDrop({
      accept,
      drop: (task: TsInterface_UnspecifiedObject) => {
        // Get Keys
        let actualTask = getProp(task, 'actual_event', {})
        // let taskKey1 = getProp( task, "key", null )
        let taskKey1 = ''
        let newTeamKey1 = getProp(team, 'key', null)
        let newTeamName1 = getProp(team, 'name', null)
        let newTeamMemberKeys1 = getProp(team, 'associated_member_keys', {})
        let newTeamMemberNames1 = getProp(team, 'associated_member_names', {})
        let newTeamMemberRoles1 = getProp(team, 'associated_member_roles', {})
        let taskScheduledDates1 = getProp(actualTask, 'task_completion_scheduled_dates', null)
        let taskKey2: string = ''
        let newTeamKey2 = getProp(actualTask, 'associated_team_key', null)
        let newTeamName2 = getProp(actualTask, 'associated_team_name', null)
        let team2 = getProp(allTeams, newTeamKey2, {})
        let newTeamMemberKeys2 = getProp(team2, 'associated_member_keys', {})
        let newTeamMemberNames2 = getProp(team2, 'associated_member_names', {})
        let newTeamMemberRoles2 = getProp(team2, 'associated_member_roles', {})
        // let taskKey2 = getProp( teamCurrentTask, "key", null )
        if (
          windowKey != null &&
          taskScheduledDates1 != null &&
          taskScheduledDates1[0] != null &&
          calendarRenderData != null &&
          // eslint-disable-next-line react/prop-types
          calendarRenderData['teams'] != null &&
          // eslint-disable-next-line react/prop-types
          calendarRenderData['teams'][newTeamKey1] != null &&
          // eslint-disable-next-line react/prop-types
          calendarRenderData['teams'][newTeamKey1]['days'] != null &&
          // eslint-disable-next-line react/prop-types
          calendarRenderData['teams'][newTeamKey1]['days'][taskScheduledDates1[0]] != null &&
          // eslint-disable-next-line react/prop-types
          calendarRenderData['teams'][newTeamKey1]['days'][taskScheduledDates1[0]]['base_time_slots'] != null &&
          // eslint-disable-next-line react/prop-types
          calendarRenderData['teams'][newTeamKey1]['days'][taskScheduledDates1[0]]['base_time_slots'][windowKey] != null &&
          // eslint-disable-next-line react/prop-types
          calendarRenderData['teams'][newTeamKey1]['days'][taskScheduledDates1[0]]['base_time_slots'][windowKey]['event'] != null &&
          // eslint-disable-next-line react/prop-types
          calendarRenderData['teams'][newTeamKey1]['days'][taskScheduledDates1[0]]['base_time_slots'][windowKey]['event']['key'] != null
        ) {
          // eslint-disable-next-line react/prop-types
          taskKey2 = calendarRenderData['teams'][newTeamKey1]['days'][taskScheduledDates1[0]]['base_time_slots'][windowKey]['event']['key']
        }
        if (
          windowKey != null &&
          taskScheduledDates1 != null &&
          taskScheduledDates1[0] != null &&
          calendarRenderData != null &&
          // eslint-disable-next-line react/prop-types
          calendarRenderData['teams'] != null &&
          // eslint-disable-next-line react/prop-types
          calendarRenderData['teams'][newTeamKey2] != null &&
          // eslint-disable-next-line react/prop-types
          calendarRenderData['teams'][newTeamKey2]['days'] != null &&
          // eslint-disable-next-line react/prop-types
          calendarRenderData['teams'][newTeamKey2]['days'][taskScheduledDates1[0]] != null &&
          // eslint-disable-next-line react/prop-types
          calendarRenderData['teams'][newTeamKey2]['days'][taskScheduledDates1[0]]['base_time_slots'] != null &&
          // eslint-disable-next-line react/prop-types
          calendarRenderData['teams'][newTeamKey2]['days'][taskScheduledDates1[0]]['base_time_slots'][windowKey] != null &&
          // eslint-disable-next-line react/prop-types
          calendarRenderData['teams'][newTeamKey2]['days'][taskScheduledDates1[0]]['base_time_slots'][windowKey]['event'] != null &&
          // eslint-disable-next-line react/prop-types
          calendarRenderData['teams'][newTeamKey2]['days'][taskScheduledDates1[0]]['base_time_slots'][windowKey]['event']['key'] != null
        ) {
          // eslint-disable-next-line react/prop-types
          taskKey1 = calendarRenderData['teams'][newTeamKey2]['days'][taskScheduledDates1[0]]['base_time_slots'][windowKey]['event']['key']
        }
        // Make sure that it's a different team and not in the past, has team members, etc
        if (
          taskKey1 != null &&
          taskKey1 !== '' &&
          taskKey2 != null &&
          taskKey2 !== '' &&
          newTeamKey2 != null &&
          newTeamKey1 != null &&
          newTeamKey2 !== newTeamKey1 &&
          taskScheduledDates1 != null &&
          taskScheduledDates1[0] != null &&
          checkIfDateHasOccured(taskScheduledDates1[0]) === false &&
          objectToArray(newTeamMemberKeys1).length > 0 &&
          objectToArray(newTeamMemberKeys2).length > 0
        ) {
          // Confirm
          uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'info',
              header: s_TRADE_TASKS,
              icon: (
                <Icon
                  icon="right-left"
                  type="solid"
                />
              ),
              submit_text: s_REASSIGN,
              text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_TRADE_THESE_TASKS_BETWEEN_TEAMS,
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      tradeDefinedTasksBetweenDifferentTeams(
                        res_GCK.clientKey,
                        taskKey1,
                        newTeamKey1,
                        newTeamName1,
                        newTeamMemberKeys1,
                        newTeamMemberNames1,
                        newTeamMemberRoles1,
                        taskScheduledDates1,
                        taskKey2,
                        newTeamKey2,
                        newTeamName2,
                        newTeamMemberKeys2,
                        newTeamMemberNames2,
                        newTeamMemberRoles2,
                        taskScheduledDates1, // Has to be the same slot as other for trade so no need to get from other task
                      )
                        .then((res_TDTBDT) => {
                          setLastTaskSwappedTimestamp(new Date())
                          resolve(res_TDTBDT)
                        })
                        .catch((rej_TDTBDT) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_TDTBDT.error })
                          reject(rej_TDTBDT)
                        })
                    })
                    .catch((rej_GCK) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    })
    const isActive = isOver && canDrop
    let backgroundColor = 'rgba(0,0,0,0)'
    if (isActive) {
      // backgroundColor = themeVariables.success_wash
      backgroundColor = themeVariables.info_light
    } else if (canDrop) {
      backgroundColor = themeVariables.warning_dark
    }
    // eslint-disable-next-line react/prop-types
    sx['background'] = backgroundColor

    // Return JSX
    return (
      <Box
        ref={drop}
        style={{ backgroundColor }}
        // eslint-disable-next-line react/prop-types
        data-teamkey={team.key}
        sx={sx}
      >
        {childrenJSX}
      </Box>
    )
  },
)

// Event Day Memebership
// eslint-disable-next-line react/prop-types
const EventDayMembershipCustomDialog: React.FC<TsInterface_EventDayMembershipDialog> = ({ teamKey, taskKey, date }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_activeTask, us_setActiveTask] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeTeam, us_setActiveTeam] = useState<TsInterface_UnspecifiedObject>({})
  const [us_backgroundColor, us_setBackgroundColor] = useState<string>(themeVariables.background_json)
  const [us_combinedUserOptions, us_setCombinedUserOptions] = useState<TsInterface_UnspecifiedObject>({})
  const [us_searchInputValue, us_setSearchInputValue] = useState<string>('')
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveTeam(newData)
      // setLoadedActiveTeam( true )
      if (newData != null && newData.team_type != null && schedulingTeamTypes != null && schedulingTeamTypes[newData.team_type] != null) {
        if (schedulingTeamTypes[newData.team_type]['color'] != null) {
          us_setBackgroundColor(schedulingTeamTypes[newData.team_type]['color'])
        } else {
          us_setBackgroundColor(themeVariables.background_json)
        }
      }
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_SchedulingTeams_Document(res_GCK.clientKey, teamKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, teamKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveTask(newData)
      ur_forceRerender()
    }
    if (taskKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Task_Document(res_GCK.clientKey, taskKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, taskKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let combinedUsers: TsInterface_UnspecifiedObject = {}
        let promiseArray: TsType_UnknownPromise[] = []
        // let listOfSchedulingTeamTypes: TsInterface_UnspecifiedObject = {}
        for (let loopTeamTypeKey in schedulingTeamTypes) {
          let loopTeamType = schedulingTeamTypes[loopTeamTypeKey]
          if (loopTeamType['user_roles'] != null) {
            for (let loopUserRoleIndex in loopTeamType['user_roles']) {
              let loopUserRoleKey = loopTeamType['user_roles'][loopUserRoleIndex]
              promiseArray.push(
                DatabaseGetCollection(DatabaseRef_UsersWithSpecificTaskRole_Query(res_GCK.clientKey, loopUserRoleKey))
                  .then((res_DGC) => {
                    for (let loopUserKey in res_DGC.data) {
                      let loopUser = res_DGC.data[loopUserKey]
                      combinedUsers[loopUserKey] = loopUser
                    }
                  })
                  .catch((rej_DGC) => {
                    console.error(rej_DGC)
                  }),
              )
            }
          }
        }
        Promise.all(promiseArray).finally(() => {
          us_setCombinedUserOptions(combinedUsers)
        })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  // Functions
  function filterArray(array: TsInterface_UnspecifiedObject[], input: string, prop: string) {
    return array.filter((item) => item[prop].toLowerCase().includes(input.toLowerCase()))
  }

  const addUserToTaskDate = (user: TsInterface_UnspecifiedObject) => {
    if (user != null && user.key != null && user.name != null) {
      let updateObject: TsInterface_UnspecifiedObject = {
        task_completion_scheduled_team_names: {},
        task_completion_scheduled_team_keys: {},
        timestamp_last_updated: new Date(),
      }
      updateObject['task_completion_scheduled_team_names'][returnFormattedDateKey(date)] = {}
      updateObject['task_completion_scheduled_team_names'][returnFormattedDateKey(date)][user.key] = user.name
      updateObject['task_completion_scheduled_team_keys'][returnFormattedDateKey(date)] = {}
      updateObject['task_completion_scheduled_team_keys'][returnFormattedDateKey(date)][user.key] = true
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_Task_Document(res_GCK.clientKey, taskKey), updateObject)
            .then((res_DSMD) => {
              ur_forceRerender()
            })
            .catch((rej_DSMD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  }

  const removeUserFromTaskDate = (userKey: string) => {
    if (userKey != null) {
      let updateObject: TsInterface_UnspecifiedObject = {
        task_completion_scheduled_team_names: getProp(us_activeTask, 'task_completion_scheduled_team_names', {}),
        task_completion_scheduled_team_keys: getProp(us_activeTask, 'task_completion_scheduled_team_keys', {}),
        timestamp_last_updated: new Date(),
      }
      updateObject['task_completion_scheduled_team_names'][returnFormattedDateKey(date)] = getProp(
        us_activeTask.task_completion_scheduled_team_names,
        returnFormattedDateKey(date),
        {},
      )
      delete updateObject['task_completion_scheduled_team_names'][returnFormattedDateKey(date)][userKey]
      updateObject['task_completion_scheduled_team_keys'][returnFormattedDateKey(date)] = getProp(
        us_activeTask.task_completion_scheduled_team_keys,
        returnFormattedDateKey(date),
        {},
      )
      delete updateObject['task_completion_scheduled_team_keys'][returnFormattedDateKey(date)][userKey]
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseUpdateDocument(DatabaseRef_Task_Document(res_GCK.clientKey, taskKey), updateObject)
            .then((res_DUD) => {
              ur_forceRerender()
            })
            .catch((rej_DUD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  }

  const updateUserTeamRole = (userKey: string, roleKey: string) => {
    if (userKey != null && roleKey != null) {
      let updateObject: TsInterface_UnspecifiedObject = {
        task_completion_scheduled_team_roles: {},
        timestamp_last_updated: new Date(),
      }
      updateObject['task_completion_scheduled_team_roles'][returnFormattedDateKey(date)] = {}
      updateObject['task_completion_scheduled_team_roles'][returnFormattedDateKey(date)][userKey] = roleKey
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_Task_Document(res_GCK.clientKey, taskKey), updateObject)
            .then((res_DSMD) => {
              ur_forceRerender()
            })
            .catch((rej_DSMD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  }

  // JSX Generation
  const rJSX_CheckedTeamMembers = (): JSX.Element => {
    let checkedTeamMembers = <></>
    let selectedTeamMembers: TsInterface_UnspecifiedObject = {}
    if (
      us_activeTask != null &&
      us_activeTask['task_completion_scheduled_team_names'] != null &&
      us_activeTask['task_completion_scheduled_team_names'][returnFormattedDateKey(date)] != null
    ) {
      for (let loopUserKey in us_activeTask['task_completion_scheduled_team_names'][returnFormattedDateKey(date)]) {
        selectedTeamMembers[loopUserKey] = {
          key: loopUserKey,
          name: us_activeTask['task_completion_scheduled_team_names'][returnFormattedDateKey(date)][loopUserKey],
          team_role: getProp(getProp(us_activeTask, 'task_completion_scheduled_team_roles', {}), returnFormattedDateKey(date) + '.' + loopUserKey, null),
        }
      }
    }
    checkedTeamMembers = (
      <Box className="tw-mt-2">
        {objectToArray(selectedTeamMembers)
          .sort(dynamicSort('name', null))
          .map((user, index) => (
            <Box key={index}>
              <Typography
                variant="subtitle1"
                className="tw-cursor-pointer"
                onClick={() => {
                  removeUserFromTaskDate(user.key)
                }}
              >
                <Icon
                  icon="square-check"
                  type="solid"
                  className="tw-mr-2"
                />
                {user.name}
              </Typography>
              <Select
                className="bp_thin_select_input tw-ml-6"
                color="primary"
                value={user.team_role || ''}
                onChange={(event: any) => {
                  if (event != null && event.target != null && event.target.value != null) {
                    updateUserTeamRole(user.key, event.target.value)
                  }
                }}
                variant="outlined"
              >
                {objectToArray(availableTeamRoles)
                  .sort(dynamicSort('key', null))
                  .map((role: TsInterface_UnspecifiedObject, index: number) => (
                    <MenuItem
                      key={index}
                      value={role['key']}
                    >
                      {role.value}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          ))}
      </Box>
    )
    return checkedTeamMembers
  }

  const rJSX_SearchTeamMembers = (): JSX.Element => {
    let searchTeamMembers = <></>
    let filteredOptions: TsInterface_UnspecifiedObject[] = []
    let unassignedUsers: TsInterface_UnspecifiedObject = {}
    let visibleUserTypes: string[] = []
    if (
      us_activeTeam != null &&
      us_activeTeam.team_type != null &&
      schedulingTeamTypes != null &&
      schedulingTeamTypes[us_activeTeam.team_type] != null &&
      schedulingTeamTypes[us_activeTeam.team_type]['user_roles'] != null
    ) {
      visibleUserTypes = schedulingTeamTypes[us_activeTeam.team_type]['user_roles']
    }
    for (let loopUserKey in us_combinedUserOptions) {
      let loopUser = us_combinedUserOptions[loopUserKey]
      if (loopUser.name != null && loopUser.key != null) {
        if (
          us_activeTask != null &&
          us_activeTask['task_completion_scheduled_team_names'] != null &&
          us_activeTask['task_completion_scheduled_team_names'][returnFormattedDateKey(date)] != null &&
          us_activeTask['task_completion_scheduled_team_names'][returnFormattedDateKey(date)][loopUser.key] != null
        ) {
          // Nothing
        } else {
          if (hasVisibleRole(visibleUserTypes, loopUser.task_roles) === true) {
            unassignedUsers[loopUser.key] = loopUser
          }
        }
      }
    }
    if (us_searchInputValue != null && us_searchInputValue.length > 0) {
      filteredOptions = filterArray(objectToArray(unassignedUsers), us_searchInputValue, 'name')
    } else {
      filteredOptions = objectToArray(unassignedUsers)
    }
    searchTeamMembers = (
      <Box className="tw-mt-2">
        {objectToArray(filteredOptions)
          .sort(dynamicSort('name', null))
          .map((user, index) => (
            <Box key={index}>
              <Typography
                variant="subtitle1"
                className="tw-cursor-pointer"
                onClick={() => {
                  addUserToTaskDate(user)
                }}
              >
                <Icon
                  icon="square"
                  type="light"
                  className="tw-mr-2"
                />
                {user.name}
              </Typography>
            </Box>
          ))}
      </Box>
    )
    return searchTeamMembers
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
            sx={{ backgroundColor: us_backgroundColor }}
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="users" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                <Box className="tw-inline-block">
                  {s_TEAM_MEMBERSHIP} - {us_activeTask.associated_project_id_number} - {us_activeTask.name} ({returnFormattedDateKey(date)})
                </Box>
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Box className="tw-p-4">
              <Grid2
                container
                spacing={2}
              >
                <Grid2
                  xs={6}
                  md={6}
                  lg={6}
                >
                  <Typography variant="h6">{s_CURRENT_TEAM_MEMBERS}</Typography>
                  {rJSX_CheckedTeamMembers()}
                </Grid2>
                <Grid2
                  xs={6}
                  md={6}
                  lg={6}
                >
                  <Typography variant="h6">{s_ADD_TEAM_MEMBERS}</Typography>
                  <TextField
                    sx={{ width: '100%' }}
                    value={us_searchInputValue}
                    onChange={(event) => {
                      us_setSearchInputValue(event.target.value)
                    }}
                    label={s_SEARCH_FOR_USERS}
                    variant="outlined"
                  />
                  {rJSX_SearchTeamMembers()}
                </Grid2>
              </Grid2>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

//
const rJSX_TaskCalendarNotes = (
  event: TsInterface_UnspecifiedObject,
  date: Date | null,
  viewNotesOnCalendar: boolean,
  uc_setUserInterface_FormDialogDisplay: any,
): JSX.Element => {
  let notesJSX = <></>
  if (viewNotesOnCalendar === true) {
    let notesTextJSX = s_NO_NOTES
    if (event != null && event.calendar_notes != null) {
      notesTextJSX = <>{event.calendar_notes}</>
    }
    notesJSX = (
      <Box>
        <Divider />
        <Box
          className="tw-italic"
          sx={{ color: 'rgba(255,255,255,0.5)' }}
        >
          {notesTextJSX}
          <Box
            className="tw-inline-block tw-ml-2"
            onClick={() => {
              const FormDialogObject: TsInterface_FormDialogObject = {
                form: {
                  formAdditionalData: {},
                  formData: event,
                  formInputs: {
                    calendar_notes: {
                      data_type: 'string',
                      input_type: 'text_multiline',
                      key: 'calendar_notes',
                      label: s_TASK_CALENDAR_NOTES,
                      required: true,
                    },
                  },
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {},
                  formSettings: {},
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          formSubmittedData.timestamp_last_updated = new Date()
                          DatabaseSetMergeDocument(DatabaseRef_Task_Document(res_GCK.clientKey, event.key), formSubmittedData)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              reject(rej_DSMD)
                            })
                        })
                        .catch((rej_GCK) => {
                          console.error(rej_GCK)
                          reject(rej_GCK)
                        })
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'success',
                  formDialogHeaderText: s_NOTES,
                  formDialogIcon: <Icon icon="plus-circle" />,
                },
              }
              uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: FormDialogObject,
              })
            }}
          >
            <Icon icon="pen-to-square" />
          </Box>
        </Box>
      </Box>
    )
  }
  return notesJSX
}

const rJSX_AdditionalProjectDetails = (
  task: TsInterface_UnspecifiedObject,
  associatedProject: TsInterface_UnspecifiedObject,
  additionalProjectData: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let additionallProjectDetailsJSX = (
    <Box>
      <Divider className="tw-my-2" />
      <Typography variant="body1">
        {s_PROJECT}: <span className="tw-italic tw-opacity-50">{task.associated_project_id_number}</span>
      </Typography>
      <Typography variant="body1">
        {s_CUSTOMER}: <span className="tw-italic tw-opacity-50">{associatedProject.associated_customer_name}</span>
      </Typography>
      <Typography variant="body1">
        {s_DISTANCE_FROM_WAREHOUSE}: <span className="tw-italic tw-opacity-50">{associatedProject.location_distance_from_warehouse}</span>
      </Typography>
      <Typography variant="body1">
        {rLIB('Address')}: <span className="tw-italic tw-opacity-50">{associatedProject.location_address}</span>
      </Typography>
      <Typography variant="body1">
        {s_CITY}: <span className="tw-italic tw-opacity-50">{associatedProject.location_city}</span>
      </Typography>
      <Typography variant="body1">
        {s_COUNTY}: <span className="tw-italic tw-opacity-50">{associatedProject.location_county}</span>
      </Typography>
      <Typography variant="body1">
        {s_ZIP}: <span className="tw-italic tw-opacity-50">{associatedProject.location_zip}</span>
      </Typography>
      <Typography variant="body1">
        {s_STATE}: <span className="tw-italic tw-opacity-50">{associatedProject.location_state}</span>
      </Typography>
      <Typography variant="body1">
        {s_JURISDICTION}: <span className="tw-italic tw-opacity-50">{associatedProject.location_jurisdiction}</span>
      </Typography>
      <Typography variant="body1">
        {s_UTILITY}: <span className="tw-italic tw-opacity-50">{associatedProject.associated_utility_company_name}</span>
      </Typography>
      <Divider className="tw-my-2" />
      <Typography variant="body1">
        {s_PANELS}:{' '}
        <span className="tw-italic tw-opacity-50">
          {rJSX_SystemDetailsRow(associatedProject.system_panel_quantity, associatedProject.system_panel_manufacturer, associatedProject.system_panel_model)}
        </span>
      </Typography>
      <Typography variant="body1">
        {s_INVERTERS}:{' '}
        <span className="tw-italic tw-opacity-50">
          {rJSX_SystemDetailsRow(
            associatedProject.system_inverter_quantity,
            associatedProject.system_inverter_manufacturer,
            associatedProject.system_inverter_model,
          )}
        </span>
      </Typography>
      <Typography variant="body1">
        {s_STORAGE}:{' '}
        <span className="tw-italic tw-opacity-50">
          {rJSX_SystemDetailsRow(
            associatedProject.system_storage_quantity,
            associatedProject.system_storage_manufacturer,
            associatedProject.system_storage_model,
          )}
        </span>
      </Typography>
      <Typography variant="body1">
        {s_SYSTEM_SIZE}: <span className="tw-italic tw-opacity-50">{associatedProject.system_size_dc}</span>
      </Typography>
      <Typography variant="body1">
        {s_NUMBER_OF_ARRAYS}: <span className="tw-italic tw-opacity-50">{associatedProject.system_number_of_arrays}</span>
      </Typography>
      <Typography variant="body1">
        {s_NUMBER_OF_STRINGS}: <span className="tw-italic tw-opacity-50">{associatedProject.system_number_of_strings}</span>
      </Typography>
      <Typography variant="body1">
        {s_MAX_ROOF_PITCH}: <span className="tw-italic tw-opacity-50">{associatedProject.system_max_roof_pitch}</span>
      </Typography>
      <Typography variant="body1">
        {s_ATTIC_RUN}: <span className="tw-italic tw-opacity-50">{associatedProject.system_has_attic_run}</span>
      </Typography>
      <Typography variant="body1">
        {s_ROOF_TYPE}: <span className="tw-italic tw-opacity-50">{associatedProject.home_roof_type}</span>
      </Typography>
      {/* <Typography variant="body1">{ s_ROOF_TYPE }: <span className="tw-italic tw-opacity-50">{ additionalProjectData.home_roof_type }</span></Typography> */}
      <Divider className="tw-my-2" />
      <Typography variant="body1">
        {s_CAP_APPROVED}:{' '}
        <span className="tw-italic tw-opacity-50">{returnFormattedDate(associatedProject.timestamp_customer_proposal_approved, 'DD MMM YYYY')}</span>
      </Typography>
      <Typography variant="body1">
        {s_INSTALL_COMPLETE}:{' '}
        <span className="tw-italic tw-opacity-50">{returnFormattedDate(associatedProject.timestamp_install_completed, 'DD MMM YYYY')}</span>
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: themeVariables.error_main }}
      >
        *{s_NOTE}: <span className="tw-italic">{se_THE_ABOVE_DATES_ARE_COMING_FROM_SALESFORCE_AND_ARE_NOT_DYNAMICALLY_UPDATED_BY_TASK_COMPLETION_YET}</span>
      </Typography>
      <Divider className="tw-my-2" />
    </Box>
  )
  return additionallProjectDetailsJSX
}

// Task Field Edits
const rJSX_EditTaskDurationButton = (task: TsInterface_UnspecifiedObject, uc_setUserInterface_FormDialogDisplay: any): JSX.Element => {
  let buttonJSX = (
    <Icon
      icon="pen-to-square"
      className="tw-ml-1 tw-cursor-pointer tw-opacity-50 hover:tw-opacity-100"
      onClick={() => {
        const FormDialogObject: TsInterface_FormDialogObject = {
          form: {
            formAdditionalData: {
              task: task,
            },
            formData: task,
            formInputs: {
              estimated_task_duration_hours: {
                key: 'estimated_task_duration_hours',
                label: (
                  <>
                    {s_ESTIMATED_TASK_DURATION} ({s_HOURS})
                  </>
                ),
                input_type: 'text_number',
                required: true,
                data_type: 'number',
              },
            },
            formOnChange: (
              formAdditionalData: TsInterface_FormAdditionalData,
              formData: TsInterface_FormData,
              formInputs: TsInterface_FormInputs,
              formSettings: TsInterface_FormSettings,
            ) => {},
            formSettings: {},
            formSubmission: (
              formSubmittedData: TsInterface_FormSubmittedData,
              formAdditionalData: TsInterface_FormAdditionalData,
              formHooks: TsInterface_FormHooksObject,
            ) => {
              return new Promise((resolve, reject) => {
                let updateObject = {
                  estimated_task_duration_hours: formSubmittedData.estimated_task_duration_hours,
                  OVERRIDE_estimated_task_duration_hours: formSubmittedData.estimated_task_duration_hours,
                  timestamp_last_updated: new Date(),
                }
                getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_Task_Document(res_GCK.clientKey, task.key), updateObject)
                      .then((res_DSMD) => {
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        reject(rej_DSMD)
                      })
                  })
                  .catch((rej_GCK) => {
                    formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
          dialog: {
            formDialogHeaderColor: 'success',
            formDialogHeaderText: (
              <>
                {s_EDIT_TASK_DURATION} - {task.name}
              </>
            ),
            formDialogIcon: <Icon icon="pen-to-square" />,
          },
        }
        uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: FormDialogObject,
        })
      }}
    />
  )
  // <Icon icon="pen-to-square" className="tw-ml-1 tw-cursor-pointer tw-opacity-50 hover:tw-opacity-100" />
  // </Box>
  return buttonJSX
}

const rJSX_EditInvoiceNumberButton = (task: TsInterface_UnspecifiedObject, uc_setUserInterface_FormDialogDisplay: any): JSX.Element => {
  let buttonJSX = (
    <Icon
      icon="pen-to-square"
      className="tw-ml-1 tw-cursor-pointer tw-opacity-50 hover:tw-opacity-100"
      onClick={() => {
        const FormDialogObject: TsInterface_FormDialogObject = {
          form: {
            formAdditionalData: {
              task: task,
            },
            formData: task,
            formInputs: {
              invoice_id_number: {
                key: 'invoice_id_number',
                label: rLIB('Billing Reference'),
                input_type: 'text_basic',
                required: true,
                data_type: 'string',
              },
            },
            formOnChange: (
              formAdditionalData: TsInterface_FormAdditionalData,
              formData: TsInterface_FormData,
              formInputs: TsInterface_FormInputs,
              formSettings: TsInterface_FormSettings,
            ) => {},
            formSettings: {},
            formSubmission: (
              formSubmittedData: TsInterface_FormSubmittedData,
              formAdditionalData: TsInterface_FormAdditionalData,
              formHooks: TsInterface_FormHooksObject,
            ) => {
              return new Promise((resolve, reject) => {
                let updateObject = {
                  invoice_id_number: formSubmittedData.invoice_id_number,
                }
                getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_Task_Document(res_GCK.clientKey, task.key), updateObject)
                      .then((res_DSMD) => {
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        reject(rej_DSMD)
                      })
                  })
                  .catch((rej_GCK) => {
                    formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
          dialog: {
            formDialogHeaderColor: 'success',
            formDialogHeaderText: (
              <>
                {s_EDIT_TASK_DURATION} - {task.name}
              </>
            ),
            formDialogIcon: <Icon icon="pen-to-square" />,
          },
        }
        uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: FormDialogObject,
        })
      }}
    />
  )
  // <Icon icon="pen-to-square" className="tw-ml-1 tw-cursor-pointer tw-opacity-50 hover:tw-opacity-100" />
  // </Box>
  return buttonJSX
}

const rJSX_EditSpecialInstructionsButton = (
  task: TsInterface_UnspecifiedObject,
  uc_setUserInterface_FormDialogDisplay: any,
  uc_RootData_ClientUser: any,
): JSX.Element => {
  let buttonJSX = (
    <Icon
      icon="pen-to-square"
      className="tw-ml-1 tw-cursor-pointer tw-opacity-50 hover:tw-opacity-100"
      onClick={() => {
        const FormDialogObject: TsInterface_FormDialogObject = {
          form: {
            formAdditionalData: {
              task: task,
            },
            formData: task,
            formInputs: {
              special_instructions: {
                key: 'special_instructions',
                label: s_TASK_SPECIAL_INSTRUCTIONS,
                input_type: 'text_multiline',
                required: true,
                data_type: 'string',
              },
            },
            formOnChange: (
              formAdditionalData: TsInterface_FormAdditionalData,
              formData: TsInterface_FormData,
              formInputs: TsInterface_FormInputs,
              formSettings: TsInterface_FormSettings,
            ) => {},
            formSettings: {},
            formSubmission: (
              formSubmittedData: TsInterface_FormSubmittedData,
              formAdditionalData: TsInterface_FormAdditionalData,
              formHooks: TsInterface_FormHooksObject,
            ) => {
              return new Promise((resolve, reject) => {
                let updateObject = {
                  special_instructions: formSubmittedData.special_instructions,
                }
                getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_Task_Document(res_GCK.clientKey, task.key), updateObject)
                      .then((res_DSMD) => {
                        let userKeysObject: TsInterface_UnspecifiedObject = {}
                        let userKeysArray: string[] = []
                        for (let loopDateKey in task.task_completion_scheduled_team_keys) {
                          let loopDate = task.task_completion_scheduled_team_keys[loopDateKey]
                          for (let loopUserKey in loopDate) {
                            if (loopDate[loopUserKey] === true) {
                              userKeysObject[loopUserKey] = true
                            }
                          }
                        }
                        for (let loopUserKey in userKeysObject) {
                          userKeysArray.push(loopUserKey)
                        }
                        sendAppPushNotification(
                          userKeysArray,
                          'task_update',
                          'Special Instructions Added to Task',
                          getProp(task, 'associated_project_id_number', '') + ' - ' + getProp(task, 'name', '') + ': ' + formSubmittedData.special_instructions,
                          getProp(uc_RootData_ClientUser, 'key', null),
                          getProp(task, 'associated_project_key', null),
                          getProp(task, 'key', null),
                          {},
                          false,
                        )
                          .then((res_SAPN) => {
                            console.log(res_SAPN)
                          })
                          .catch((rej_SAPN) => {
                            console.error(rej_SAPN)
                          })
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        reject(rej_DSMD)
                      })
                  })
                  .catch((rej_GCK) => {
                    formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
          dialog: {
            formDialogHeaderColor: 'success',
            formDialogHeaderText: (
              <>
                {s_EDIT_TASK_DURATION} - {task.name}
              </>
            ),
            formDialogIcon: <Icon icon="pen-to-square" />,
          },
        }
        uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: FormDialogObject,
        })
      }}
    />
  )
  return buttonJSX
}

const rJSX_EditQAButton = (task: TsInterface_UnspecifiedObject, uc_setUserInterface_FormDialogDisplay: any): JSX.Element => {
  let buttonJSX = (
    <Icon
      icon="pen-to-square"
      className="tw-ml-1 tw-cursor-pointer tw-opacity-50 hover:tw-opacity-100"
      onClick={() => {
        let formData: TsInterface_UnspecifiedObject = {}
        if (getProp(task, 'timestamp_qa_review_date', null) != null) {
          formData['timestamp_qa_review_date'] = returnFormattedDate(task.timestamp_qa_review_date, 'YYYY-MM-DDTHH:mm')
        }
        if (getProp(task, 'qa_review_result', null) != null) {
          formData['qa_review_result'] = task.qa_review_result
        }
        if (getProp(task, 'qa_review_notes', null) != null) {
          formData['qa_review_notes'] = task.qa_review_notes
        }
        const FormDialogObject: TsInterface_FormDialogObject = {
          form: {
            formAdditionalData: {
              task: task,
            },
            formData: formData,
            formInputs: {
              timestamp_qa_review_date: {
                key: 'timestamp_qa_review_date',
                label: s_QA_REVIEW_DATE,
                input_type: 'timestamp_datetime',
                required: true,
                data_type: 'number',
              },
              qa_review_result: {
                key: 'qa_review_result',
                label: s_QA_REVIEW_RESULT,
                input_type: 'multiple_choice_radio',
                required: true,
                data_type: 'string',
                options: [
                  { key: 'pass', value: s_PASS },
                  { key: 'fail', value: s_FAIL },
                ],
              },
              qa_review_notes: {
                key: 'qa_review_notes',
                label: s_QA_REVIEW_NOTES,
                input_type: 'text_multiline',
                required: true,
                data_type: 'string',
              },
            },
            formOnChange: (
              formAdditionalData: TsInterface_FormAdditionalData,
              formData: TsInterface_FormData,
              formInputs: TsInterface_FormInputs,
              formSettings: TsInterface_FormSettings,
            ) => {},
            formSettings: {},
            formSubmission: (
              formSubmittedData: TsInterface_FormSubmittedData,
              formAdditionalData: TsInterface_FormAdditionalData,
              formHooks: TsInterface_FormHooksObject,
            ) => {
              return new Promise((resolve, reject) => {
                let updateObject = {
                  timestamp_qa_review_date: returnDateFromUnknownDateFormat(formSubmittedData['timestamp_qa_review_date']),
                  qa_review_result: formSubmittedData['qa_review_result'],
                  qa_review_notes: formSubmittedData['qa_review_notes'],
                }
                let todayPayrollDateKey = returnFormattedDateKey(getStartAndEndOfWeek(new Date()).startOfWeek)
                let qaReviewDateKey = returnFormattedDateKey(
                  getStartAndEndOfWeek(returnDateFromUnknownDateFormat(formSubmittedData['timestamp_qa_review_date'])).startOfWeek,
                )
                if (qaReviewDateKey < todayPayrollDateKey) {
                  let error = {
                    message: s_FAILED_TO_UPDATE_QA_RESULTS,
                    details: s_YOU_CANT_PUT_QA_RESULTS_IN_PAST_WEEKS,
                    code: 'ER-D-TDI-FS-01',
                  }
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                  reject({ success: false, error: error })
                } else {
                  getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_Task_Document(res_GCK.clientKey, task.key), updateObject)
                        .then((res_DSMD) => {
                          resolve(res_DSMD)
                        })
                        .catch((rej_DSMD) => {
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          reject(rej_DSMD)
                        })
                    })
                    .catch((rej_GCK) => {
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                }
              })
            },
          },
          dialog: {
            formDialogHeaderColor: 'success',
            formDialogHeaderText: (
              <>
                {s_EDIT_TASK_QA_REVIEW} - {task.name}
              </>
            ),
            formDialogIcon: <Icon icon="pen-to-square" />,
          },
        }
        uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: FormDialogObject,
        })
      }}
    />
  )
  // <Icon icon="pen-to-square" className="tw-ml-1 tw-cursor-pointer tw-opacity-50 hover:tw-opacity-100" />
  // </Box>
  return buttonJSX
}

// Helper
const rJSX_SystemDetailsRow = (quantity: string, manufacturer: string, model: string): JSX.Element => {
  let rowJSX = <></>
  if (manufacturer != null && manufacturer !== '' && model != null && model !== '') {
    rowJSX = (
      <span className="tw-inline-block">
        {quantity} {manufacturer.substring(0, manufacturer.indexOf(' '))} {model}
      </span>
    )
  } else {
    rowJSX = <span className="tw-inline-block"></span>
  }
  return rowJSX
}

// Custom Dialogs
// eslint-disable-next-line react/prop-types
const TeamMembershipCustomDialog: React.FC<TsInterface_TeamMembershipDialog> = ({ teamKey }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_activeTeam, us_setActiveTeam] = useState<TsInterface_UnspecifiedObject>({})
  const [us_backgroundColor, us_setBackgroundColor] = useState<string>(themeVariables.background_json)
  const [us_combinedUserOptions, us_setCombinedUserOptions] = useState<TsInterface_UnspecifiedObject>({})
  const [us_regionLists, us_setRegionLists] = useState<TsInterface_UnspecifiedObject>({})
  const [us_searchInputValue, us_setSearchInputValue] = useState<string>('')
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveTeam(newData)
      // setLoadedActiveTeam( true )
      if (newData != null && newData.team_type != null && schedulingTeamTypes != null && schedulingTeamTypes[newData.team_type] != null) {
        // setTeamTypeData( schedulingTeamTypes[ newData.team_type ] )
        if (schedulingTeamTypes[newData.team_type]['color'] != null) {
          us_setBackgroundColor(schedulingTeamTypes[newData.team_type]['color'])
        } else {
          us_setBackgroundColor(themeVariables.background_json)
        }
      }
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_SchedulingTeams_Document(res_GCK.clientKey, teamKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, teamKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let combinedUsers: TsInterface_UnspecifiedObject = {}
        let promiseArray: TsType_UnknownPromise[] = []
        // let listOfSchedulingTeamTypes: TsInterface_UnspecifiedObject = {}
        for (let loopTeamTypeKey in schedulingTeamTypes) {
          let loopTeamType = schedulingTeamTypes[loopTeamTypeKey]
          if (loopTeamType['user_roles'] != null) {
            for (let loopUserRoleIndex in loopTeamType['user_roles']) {
              let loopUserRoleKey = loopTeamType['user_roles'][loopUserRoleIndex]
              promiseArray.push(
                DatabaseGetCollection(DatabaseRef_UsersWithSpecificTaskRole_Query(res_GCK.clientKey, loopUserRoleKey))
                  .then((res_DGC) => {
                    for (let loopUserKey in res_DGC.data) {
                      let loopUser = res_DGC.data[loopUserKey]
                      combinedUsers[loopUserKey] = loopUser
                    }
                  })
                  .catch((rej_DGC) => {
                    console.error(rej_DGC)
                  }),
              )
            }
          }
        }
        Promise.all(promiseArray).finally(() => {
          us_setCombinedUserOptions(combinedUsers)
        })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRegionLists(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveRegions_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const addUserToTeam = (user: TsInterface_UnspecifiedObject) => {
    if (user != null && user.key != null && user.name != null) {
      let updateObject: TsInterface_UnspecifiedObject = {}
      updateObject['associated_member_keys'] = getProp(us_activeTeam, 'associated_member_keys', {})
      updateObject['associated_member_names'] = getProp(us_activeTeam, 'associated_member_names', {})
      updateObject['associated_member_keys'][user.key] = true
      updateObject['associated_member_names'][user.key] = user.name
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_SchedulingTeams_Document(res_GCK.clientKey, teamKey), updateObject)
            .then((res_DSMD) => {
              ur_forceRerender()
            })
            .catch((rej_DSMD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  }

  const removeUserFromTeam = (userKey: string) => {
    if (userKey != null) {
      let updateObject: TsInterface_UnspecifiedObject = {}
      updateObject['associated_member_keys'] = getProp(us_activeTeam, 'associated_member_keys', {})
      updateObject['associated_member_names'] = getProp(us_activeTeam, 'associated_member_names', {})
      delete updateObject['associated_member_keys'][userKey]
      delete updateObject['associated_member_names'][userKey]
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseUpdateDocument(DatabaseRef_SchedulingTeams_Document(res_GCK.clientKey, teamKey), updateObject)
            .then((res_DUD) => {
              ur_forceRerender()
            })
            .catch((rej_DUD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  }

  const updateUserTeamRole = (userKey: string, roleKey: string) => {
    if (userKey != null && roleKey != null) {
      let updateObject: TsInterface_UnspecifiedObject = {}
      updateObject['associated_member_roles'] = getProp(us_activeTeam, 'associated_member_roles', {})
      updateObject['associated_member_roles'][userKey] = roleKey
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_SchedulingTeams_Document(res_GCK.clientKey, teamKey), updateObject)
            .then((res_DSMD) => {
              ur_forceRerender()
            })
            .catch((rej_DSMD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  }

  function filterArray(array: TsInterface_UnspecifiedObject[], input: string, prop: string) {
    return array.filter((item) => item[prop].toLowerCase().includes(input.toLowerCase()))
  }

  const updateTeamSetting = (propKey: string, propValue: string) => {
    return new Promise((resolve, reject) => {
      let updateObject = {
        [propKey]: propValue,
      }
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_SchedulingTeams_Document(res_GCK.clientKey, teamKey), updateObject)
            .then((res_DSMD) => {
              resolve(res_DSMD)
            })
            .catch((rej_DSMD) => {
              reject(rej_DSMD)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    })
  }

  // JSX Generation
  const rJSX_SortPriorityButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: {
              color: 'info',
              confirm_text: s_SAVE,
              default_value: us_activeTeam.task_fill_priority,
              header: s_EDIT_TASK_FILL_PRIORITY,
              icon: (
                <Icon
                  icon="pen-to-square"
                  type="solid"
                />
              ),
              input_label: s_FILL_PRIORITY,
              input_type: 'number',
              text: s_ENTER_A_FILL_PRIORITY_NUMBER,
              submit_callback: (promptValue: string) => {
                return new Promise((resolve, reject) => {
                  let updateObject = {
                    task_fill_priority: promptValue,
                  }
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_SchedulingTeams_Document(res_GCK.clientKey, teamKey), updateObject)
                        .then((res_DSMD) => {
                          ur_forceRerender()
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }}
      >
        <Icon
          icon="pen-to-square"
          className="tw-mr-2"
        />
        {s_EDIT_TASK_FILL_PRIORITY}: {us_activeTeam.task_fill_priority}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_CheckedTeamMembers = (): JSX.Element => {
    let checkedTeamMembers = <></>
    let selectedTeamMembers: TsInterface_UnspecifiedObject = {}
    if (us_activeTeam != null && us_activeTeam['associated_member_keys'] != null && us_activeTeam['associated_member_names'] != null) {
      for (let loopUserKey in us_activeTeam['associated_member_keys']) {
        if (us_activeTeam['associated_member_names'][loopUserKey] != null) {
          selectedTeamMembers[loopUserKey] = {
            key: loopUserKey,
            name: us_activeTeam['associated_member_names'][loopUserKey],
            team_role: getProp(us_activeTeam, 'associated_member_roles', {})[loopUserKey],
          }
        }
      }
    }
    checkedTeamMembers = (
      <Box className="tw-mt-2">
        {objectToArray(selectedTeamMembers)
          .sort(dynamicSort('name', null))
          .map((user, index) => (
            <Box key={index}>
              <Typography
                variant="subtitle1"
                className="tw-cursor-pointer"
                onClick={() => {
                  removeUserFromTeam(user.key)
                }}
              >
                <Icon
                  icon="square-check"
                  type="solid"
                  className="tw-mr-2"
                />
                {user.name}
              </Typography>
              <Select
                className="bp_thin_select_input tw-ml-6"
                color="primary"
                value={user.team_role || ''}
                onChange={(event: any) => {
                  if (event != null && event.target != null && event.target.value != null) {
                    updateUserTeamRole(user.key, event.target.value)
                  }
                }}
                variant="outlined"
              >
                {objectToArray(availableTeamRoles)
                  .sort(dynamicSort('key', null))
                  .map((role: TsInterface_UnspecifiedObject, index: number) => (
                    <MenuItem
                      key={index}
                      value={role['key']}
                    >
                      {role.value}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          ))}
      </Box>
    )
    return checkedTeamMembers
  }

  const rJSX_SearchTeamMembers = (): JSX.Element => {
    let searchTeamMembers = <></>
    let filteredOptions: TsInterface_UnspecifiedObject[] = []
    let unassignedUsers: TsInterface_UnspecifiedObject = {}
    let visibleUserTypes: string[] = []
    if (
      us_activeTeam != null &&
      us_activeTeam.team_type != null &&
      schedulingTeamTypes != null &&
      schedulingTeamTypes[us_activeTeam.team_type] != null &&
      schedulingTeamTypes[us_activeTeam.team_type]['user_roles'] != null
    ) {
      visibleUserTypes = schedulingTeamTypes[us_activeTeam.team_type]['user_roles']
    }
    for (let loopUserKey in us_combinedUserOptions) {
      let loopUser = us_combinedUserOptions[loopUserKey]
      if (loopUser.name != null && loopUser.key != null) {
        if (us_activeTeam == null || us_activeTeam.associated_member_keys == null || us_activeTeam.associated_member_keys[loopUser.key] !== true) {
          if (hasVisibleRole(visibleUserTypes, loopUser.task_roles) === true) {
            unassignedUsers[loopUser.key] = loopUser
          }
        }
      }
    }
    if (us_searchInputValue != null && us_searchInputValue.length > 0) {
      filteredOptions = filterArray(objectToArray(unassignedUsers), us_searchInputValue, 'name')
    } else {
      filteredOptions = objectToArray(unassignedUsers)
    }
    searchTeamMembers = (
      <Box className="tw-mt-2">
        {objectToArray(filteredOptions)
          .sort(dynamicSort('name', null))
          .map((user, index) => (
            <Box key={index}>
              <Typography
                variant="subtitle1"
                className="tw-cursor-pointer"
                onClick={() => {
                  addUserToTeam(user)
                }}
              >
                <Icon
                  icon="square"
                  type="light"
                  className="tw-mr-2"
                />
                {user.name}
              </Typography>
            </Box>
          ))}
      </Box>
    )
    return searchTeamMembers
  }

  const rJSX_TimezoneButton = (): JSX.Element => {
    let buttonJSX = <></>
    let buttonText = s_NO_TIMEZONE_SELECTED
    switch (getProp(us_activeTeam, 'selected_timezone', null)) {
      case 'et':
        buttonText = s_EASTERN_TIME
        break
      case 'ct':
        buttonText = s_CENTRAL_TIME
        break
      case 'mt':
        buttonText = s_MOUNTAIN_TIME
        break
      case 'pt':
        buttonText = s_PACIFIC_TIME
        break
      default:
        break
    }
    buttonJSX = (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          const FormDialogObject: TsInterface_FormDialogObject = {
            form: {
              formAdditionalData: {
                teamKey: us_activeTeam.key,
              },
              formData: {},
              formInputs: formInputs_TeamTimezoneNew,
              formOnChange: formOnChange_TeamTimezone,
              formSettings: formSettings_TeamTimezoneCreate,
              formSubmission: formSubmission_TeamTimezoneCreate,
            },
            dialog: {
              formDialogHeaderColor: 'secondary',
              formDialogHeaderText: (
                <>
                  {s_EDIT_TEAM_TIMEZONE} - {us_activeTeam.name}
                </>
              ),
              formDialogIcon: <Icon icon="clock" />,
            },
          }
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: FormDialogObject,
          })
        }}
      >
        <Icon
          icon="clock"
          className="tw-mr-2"
        />
        {buttonText}
      </Button>
    )
    return buttonJSX
  }

  const returnDeleteOrUndeleteButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_activeTeam.status === 'active') {
      buttonJSX = (
        <Button
          variant="contained"
          color="error"
          // disabled={ true }
          onClick={() => {
            uc_setUserInterface_PromptDialogDisplay({
              display: true,
              prompt: {
                color: 'error',
                confirm_text: s_DELETE_TEAM,
                default_value: null,
                header: s_DELETE_TEAM,
                icon: (
                  <Icon
                    icon="trash"
                    type="solid"
                  />
                ),
                input_label: s_DELETE,
                input_type: 'text',
                text: s_TYPE_DELETE_TO_PROCEED_WITH_DELETING_THIS_TEAM,
                submit_callback: (promptValue: string) => {
                  return new Promise((resolve, reject) => {
                    if (promptValue.toLowerCase() === 'delete') {
                      let updateObject = {
                        status: 'deleted',
                      }
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(DatabaseRef_SchedulingTeams_Document(res_GCK.clientKey, teamKey), updateObject)
                            .then((res_DSMD) => {
                              ur_forceRerender()
                              resolve({ success: true })
                            })
                            .catch((rej_DSMD) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            })
                        })
                        .catch((rej_GCK) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        })
                    } else {
                      reject({ success: false })
                      uc_setUserInterface_ErrorDialogDisplay({
                        display: true,
                        error: {
                          message: s_FAILED_TO_DELETE_TEAM,
                          details: s_TYPE_DELETE_TO_PROCEED_WITH_DELETING_THIS_TEAM,
                          code: 'ER-D-TDI-DT-01',
                        },
                      })
                    }
                  })
                },
              },
            })
          }}
        >
          <Icon
            icon="trash"
            className="tw-mr-2"
          />
          {s_DELETE_TEAM}
        </Button>
      )
    } else {
      buttonJSX = (
        <Button
          variant="contained"
          color="warning"
          // disabled={ true }
          onClick={() => {
            uc_setUserInterface_PromptDialogDisplay({
              display: true,
              prompt: {
                color: 'warning',
                confirm_text: s_UNDELETE_TEAM,
                default_value: null,
                header: s_UNDELETE_TEAM,
                icon: (
                  <Icon
                    icon="trash-can-undo"
                    type="solid"
                  />
                ),
                input_label: s_UNDELETE,
                input_type: 'text',
                text: s_TYPE_UNDELETE_TO_PROCEED_WITH_UNDELETING_THIS_TEAM,
                submit_callback: (promptValue: string) => {
                  return new Promise((resolve, reject) => {
                    if (promptValue.toLowerCase() === 'undelete') {
                      let updateObject = {
                        status: 'deleted',
                      }
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(DatabaseRef_SchedulingTeams_Document(res_GCK.clientKey, teamKey), updateObject)
                            .then((res_DSMD) => {
                              ur_forceRerender()
                              resolve({ success: true })
                            })
                            .catch((rej_DSMD) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            })
                        })
                        .catch((rej_GCK) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        })
                    } else {
                      reject({ success: false })
                      uc_setUserInterface_ErrorDialogDisplay({
                        display: true,
                        error: {
                          message: s_FAILED_TO_UNDELETE_TEAM,
                          details: s_TYPE_UNDELETE_TO_PROCEED_WITH_UNDELETING_THIS_TEAM,
                          code: 'ER-D-TDI-DT-01',
                        },
                      })
                    }
                  })
                },
              },
            })
          }}
        >
          <Icon
            icon="trash-can-undo"
            className="tw-mr-2"
          />
          {s_UNDELETE_TEAM}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_RegionDropdown = (): JSX.Element => {
    // JSX
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top tw-mb-4">
        <Typography variant="h6">{s_REGION}</Typography>
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={getProp(us_activeTeam, 'associated_region_key', null)}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                updateTeamSetting('associated_region_key', event.target.value)
              }
            }}
            variant="outlined"
          >
            {objectToArray(us_regionLists)
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['name']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_TeamSettingsTab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-p-4">
        <Box>{rJSX_RegionDropdown()}</Box>
        <Box>
          <Typography variant="h6">{s_OTHER_SETTINGS}</Typography>
        </Box>
        <Stack
          spacing={1}
          direction="row"
        >
          {rJSX_TimezoneButton()}
          <Button
            variant="contained"
            color="info"
            onClick={() => {
              uc_setUserInterface_PromptDialogDisplay({
                display: true,
                prompt: {
                  color: 'info',
                  confirm_text: s_RENAME_TEAM,
                  default_value: us_activeTeam.name,
                  header: s_RENAME_TEAM,
                  icon: (
                    <Icon
                      icon="pen-to-square"
                      type="solid"
                    />
                  ),
                  input_label: s_TEAM_NAME,
                  input_type: 'text',
                  text: s_ENTER_A_NAME_FOR_THIS_TEAM,
                  submit_callback: (promptValue: string) => {
                    return new Promise((resolve, reject) => {
                      let updateObject = {
                        name: promptValue,
                      }
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(DatabaseRef_SchedulingTeams_Document(res_GCK.clientKey, teamKey), updateObject)
                            .then((res_DSMD) => {
                              ur_forceRerender()
                              resolve({ success: true })
                            })
                            .catch((rej_DSMD) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            })
                        })
                        .catch((rej_GCK) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        })
                    })
                  },
                },
              })
            }}
          >
            <Icon
              icon="pen-to-square"
              className="tw-mr-2"
            />
            {s_RENAME_TEAM}
          </Button>
          {returnDeleteOrUndeleteButton()}
          {rJSX_SortPriorityButton()}
        </Stack>
      </Box>
    )
    return tabJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
            sx={{ backgroundColor: us_backgroundColor }}
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="users" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                <Box className="tw-inline-block">
                  {s_TEAM}: {us_activeTeam.name}
                </Box>
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <TabsBasic
              tabs={[
                {
                  tabHeader: s_TEAM_MEMBERSHIP,
                  tabContent: (
                    <Box className="tw-p-4">
                      <Grid2
                        container
                        spacing={2}
                      >
                        <Grid2
                          xs={6}
                          md={6}
                          lg={6}
                        >
                          <Typography variant="h6">{s_CURRENT_TEAM_MEMBERS}</Typography>
                          {rJSX_CheckedTeamMembers()}
                        </Grid2>
                        <Grid2
                          xs={6}
                          md={6}
                          lg={6}
                        >
                          <Typography variant="h6">{s_ADD_TEAM_MEMBERS}</Typography>
                          <TextField
                            sx={{ width: '100%' }}
                            value={us_searchInputValue}
                            onChange={(event) => {
                              us_setSearchInputValue(event.target.value)
                            }}
                            label={s_SEARCH_FOR_USERS}
                            variant="outlined"
                          />
                          {rJSX_SearchTeamMembers()}
                        </Grid2>
                      </Grid2>
                    </Box>
                  ),
                },
                {
                  tabHeader: s_SETTINGS,
                  tabContent: (
                    <Box>
                      <Box>{rJSX_TeamSettingsTab()}</Box>
                      <Box className="tw-text-right tw-opacity-20 tw-p-2">{teamKey}</Box>
                    </Box>
                  ),
                },
              ]}
              tabsSettings={{}}
            />
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

// eslint-disable-next-line react/prop-types
const UnassignedTaskCustomDialog: React.FC<TsInterface_UnassignedTaskDialog> = ({
  task,
  us_calendarEvents,
  us_activeTeams,
  us_teamTypeSettings,
}): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const { uc_setUserInterface_FormDialogDisplay } = 					useContext( Context_UserInterface_FormDialog )
  const [us_activeTask, us_setActiveTask] = useState<TsInterface_UnspecifiedObject>({})
  const [us_additionalProjectData, us_setAdditionalProjectData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_associatedProject, us_setAssociatedProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_backgroundColor, us_setBackgroundColor] = useState<string>(themeVariables.background_json)
  const [us_downstreamTask, us_setDownstreamTask] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedTeam, us_setSelectedTeam] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const ur_mapRef = useRef(null)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setDownstreamTask(newData)
      ur_forceRerender()
    }
    // eslint-disable-next-line react/prop-types
    if (task != null && task.associated_dispatched_task_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          // eslint-disable-next-line react/prop-types
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Task_Document(res_GCK.clientKey, task.associated_dispatched_task_key), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, task])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveTask(newData)
      ur_forceRerender()
    }
    // eslint-disable-next-line react/prop-types
    if (task != null && task.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          // eslint-disable-next-line react/prop-types
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Task_Document(res_GCK.clientKey, task.key), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, task])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAssociatedProject(newData)
      // eslint-disable-next-line react/prop-types
      if (task != null && task.associated_project_key != null && (task.location_latitude == null || task.location_longitude == null)) {
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            // eslint-disable-next-line react/prop-types
            addCoordinatesToTask(res_GCK.clientKey, task.key, newData)
          })
          .catch((rej_GCK) => {
            console.error(rej_GCK)
          })
      }
      // ur_forceRerender()
    }
    // eslint-disable-next-line react/prop-types
    if (task != null && task.associated_project_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          // eslint-disable-next-line react/prop-types
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(res_GCK.clientKey, task.associated_project_key), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, task])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    if (
      task != null &&
      // eslint-disable-next-line react/prop-types
      task.associated_team_type != null &&
      schedulingTeamTypes != null &&
      // eslint-disable-next-line react/prop-types
      schedulingTeamTypes[task.associated_team_type] != null &&
      // eslint-disable-next-line react/prop-types
      schedulingTeamTypes[task.associated_team_type]['color'] != null
    ) {
      // eslint-disable-next-line react/prop-types
      us_setBackgroundColor(schedulingTeamTypes[task.associated_team_type]['color'])
    } else {
      us_setBackgroundColor(themeVariables.background_json)
    }
    ur_forceRerender()
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [task, ur_forceRerender])

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (task != null && task.associated_project_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          // eslint-disable-next-line react/prop-types
          DatabaseGetDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, task.associated_project_key))
            .then((res_DGD) => {
              us_setAdditionalProjectData(res_DGD.data)
            })
            .catch((rej_DGD) => {
              console.error(rej_DGD)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, task])

  // Functions
  const mapBasicOnClick: TsType_MapOnClick = (lat: number, lng: number) => {
    // Nothing
  }

  // JSX Generation
  const rJSX_DetailsTab = (): JSX.Element => {
    let teamTypeKey = getProp(task, 'associated_team_type', '')
    let teamTypeName = teamTypeKey
    if (teamTypeKey != null && schedulingTeamTypes != null && schedulingTeamTypes[teamTypeKey] != null && schedulingTeamTypes[teamTypeKey]['value'] != null) {
      teamTypeName = schedulingTeamTypes[teamTypeKey]['value']
    }
    // JSX
    let tabJSX = (
      <Box className="tw-px-4 tw-pb-2">
        <Box>
          <Typography variant="body1">
            {s_TASK_STATUS}: <span className="tw-italic tw-opacity-50">{us_activeTask.status}</span>
          </Typography>
          <Typography variant="body1">
            {s_DISPATCHER_TASK_ID}: <span className="tw-italic tw-opacity-50">{us_activeTask.id_number}</span>
          </Typography>
          <Typography variant="body1">
            {s_DISPATCHER_TASK_NAME}: <span className="tw-italic tw-opacity-50">{us_activeTask.name}</span>
          </Typography>
          <Typography variant="body1">
            {s_ASSIGNED_TASK_ID}: <span className="tw-italic tw-opacity-50">{us_downstreamTask.id_number}</span>
          </Typography>
          <Typography variant="body1">
            {s_ASSIGNED_TASK_NAME}: <span className="tw-italic tw-opacity-50">{us_downstreamTask.name}</span>
          </Typography>
          <Typography variant="body1">
            {s_TEAM_TYPE}: <span className="tw-italic tw-opacity-50">{teamTypeName}</span>
          </Typography>
          <Typography variant="body1">
            {s_TASK_ASSIGNER}: <span className="tw-italic tw-opacity-50">{us_activeTask.associated_owner_name}</span>
          </Typography>
          {rJSX_AdditionalProjectDetails(us_activeTask, us_associatedProject, us_additionalProjectData)}
        </Box>
        <Button
          color="info"
          variant="contained"
          onClick={() => {
            // eslint-disable-next-line react/prop-types
            let url = window.location.origin + ApplicationPages.AdminActiveProjectViewPage.url(task.associated_project_key)
            window.open(url, '_blank')
          }}
          // eslint-disable-next-line react/prop-types
          disabled={task.associated_project_key == null}
          className="tw-mt-2"
        >
          <Icon
            icon="square-arrow-up-right"
            className="tw-mr-2"
          />
          {s_VIEW_PROJECT}
        </Button>
        {/* // eslint-disable-next-line react/prop-types */}
        <Box className="tw-text-right tw-opacity-10">{task.key}</Box>
      </Box>
    )
    return tabJSX
  }

  const rJSX_MapTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_activeTask != null && us_activeTask['location_latitude'] != null && us_activeTask['location_longitude'] != null) {
      let mapBasicSettings: TsInterface_MapSettings = {
        center_lat: us_activeTask['location_latitude'],
        center_lng: us_activeTask['location_longitude'],
        height: '500px',
        render_clusters: false,
        render_heatmap: false,
        render_markers: true,
        width: '100%',
        zoom: 12,
      }
      const mapBasicMarkers: TsInterface_MapMarkers = {
        task: {
          position: {
            lat: us_activeTask['location_latitude'],
            lng: us_activeTask['location_longitude'],
          },
          icon: '',
          label: '',
        },
      }
      tabJSX = (
        <Box>
          <MapBasic
            ref={ur_mapRef}
            mapSettings={mapBasicSettings}
            mapMarkers={mapBasicMarkers}
            mapOnClick={mapBasicOnClick}
          />
        </Box>
      )
    } else {
      tabJSX = (
        <Box className="tw-p-4 tw-text-center">
          <Typography variant="h6">{s_NO_COORDINATES_FOR_THIS_TASK}</Typography>
        </Box>
      )
    }
    return tabJSX
  }

  const rJSX_TeamDropdown = (): JSX.Element => {
    let dropdownJSX = <></>
    let teamOptions: TsInterface_UnspecifiedObject = {}
    for (let loopTeamKey in us_activeTeams) {
      let loopTeam = us_activeTeams[loopTeamKey]
      if (loopTeam.name != null && loopTeam.key != null && loopTeam.team_type != null && loopTeam.team_type === task.associated_team_type) {
        teamOptions[loopTeam.key] = loopTeam
      }
    }
    dropdownJSX = (
      <Box className="tw-mb-4 tw-mt-1">
        <FormControl
          className="bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ width: '100%' }}
        >
          <InputLabel id={'userFilter'}>{rLIB('Team')}</InputLabel>
          <Select
            autoWidth={true}
            id={'userFilter'}
            labelId={'userFilter'}
            value={getProp(us_selectedTeam, 'key', '')}
            label={<Box>{s_TASKS_FILTER}</Box>}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                let selectedTeam = teamOptions[event.target.value]
                us_setSelectedTeam(selectedTeam)
              }
            }}
          >
            {objectToArray(teamOptions)
              .sort(dynamicSort('name', 'asc'))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option.key}
                >
                  {option.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_AssignTab = (): JSX.Element => {
    let tabJSX = <></>
    if (
      us_teamTypeSettings == null ||
      us_teamTypeSettings[task.associated_team_type] == null ||
      us_teamTypeSettings[task.associated_team_type]['task_assignment_type'] != 'defined_slots'
    ) {
      tabJSX = (
        <Box>
          <Box>
            <Box className="tw-p-4">
              {rJSX_TeamDropdown()}
              <Button
                color="info"
                variant="contained"
                disabled={getProp(us_selectedTeam, 'key', null) == null}
                onClick={() => {
                  uc_setUserInterface_CustomDialogDisplay({
                    display: true,
                    dialog: {
                      dialog_jsx: (
                        <Box>
                          <AssignTaskToTeamCustomDialog
                            definedTimeSlotKey={null}
                            taskAssignmentType={'freeform_slots'}
                            teamKey={getProp(us_selectedTeam, 'key', null)}
                            teamType={getProp(us_selectedTeam, 'team_type', null)}
                            taskKey={task.key}
                            assignDate={new Date()}
                            assignTime={null}
                            allScheduledTasks={us_calendarEvents}
                            allTeams={us_activeTeams}
                            teamTypeSettings={us_teamTypeSettings}
                          />
                        </Box>
                      ),
                      settings: {
                        max_width: 'lg',
                      },
                    },
                  })
                }}
              >
                {rLIB('Assign to Team')}
              </Button>
            </Box>
          </Box>
        </Box>
      )
    } else {
      tabJSX = <Box className="tw-p-4">{rLIB('Defined timeslot task assignment only works with drag and drop')}</Box>
    }
    return tabJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
            sx={{ backgroundColor: us_backgroundColor }}
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="clipboard-list-check" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                {/* // eslint-disable-next-line react/prop-types */}
                {s_PROJECT} {task.associated_project_id_number}-{task.abbreviation} - {task.name}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <TabsBasic
              tabs={[
                {
                  tabHeader: s_TASK,
                  tabContent: rJSX_DetailsTab(),
                },
                {
                  tabHeader: s_MAP,
                  tabContent: rJSX_MapTab(),
                },
                {
                  tabHeader: rLIB('Assign to Team'),
                  tabContent: rJSX_AssignTab(),
                },
              ]}
              tabsSettings={{}}
            />
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

const AssignTaskToTeamCustomDialog: React.FC<TsInterface_AssignTaskToTeamDialog> = ({
  // eslint-disable-next-line react/prop-types
  teamKey,
  // eslint-disable-next-line react/prop-types
  teamType,
  // eslint-disable-next-line react/prop-types
  taskKey,
  // eslint-disable-next-line react/prop-types
  allScheduledTasks,
  // eslint-disable-next-line react/prop-types
  allTeams,
  // eslint-disable-next-line react/prop-types
  assignDate,
  // eslint-disable-next-line react/prop-types
  assignTime,
  // eslint-disable-next-line react/prop-types
  definedTimeSlotKey,
  // eslint-disable-next-line react/prop-types
  taskAssignmentType,
  // eslint-disable-next-line react/prop-types
  teamTypeSettings,
}): JSX.Element => {
  // Custom Init
  let initialFormData: TsInterface_UnspecifiedObject = {}
  if (assignDate != null) {
    initialFormData['day_1_date'] = returnFormattedDate(assignDate, 'YYYY-MM-DD')
  }
  if (assignTime != null) {
    initialFormData['day_1_start_time'] = assignTime
  }
  let seasonKey = 'winter'
  if (isDaylightSavings(assignDate) === true) {
    seasonKey = 'summer'
  }
  if (
    taskAssignmentType === 'defined_slots' &&
    definedTimeSlotKey != null &&
    teamKey != null &&
    allTeams != null &&
    allTeams[teamKey] != null &&
    // eslint-disable-next-line react/prop-types
    allTeams[teamKey]['team_type'] != null &&
    teamTypeSettings != null &&
    teamTypeSettings[teamType] != null &&
    // eslint-disable-next-line react/prop-types
    teamTypeSettings[teamType]['task_assignment_slots'] != null &&
    // eslint-disable-next-line react/prop-types
    teamTypeSettings[teamType]['task_assignment_slots'][seasonKey] != null &&
    // eslint-disable-next-line react/prop-types
    teamTypeSettings[teamType]['task_assignment_slots'][seasonKey][definedTimeSlotKey] != null &&
    // eslint-disable-next-line react/prop-types
    teamTypeSettings[teamType]['task_assignment_slots'][seasonKey][definedTimeSlotKey] != null &&
    // eslint-disable-next-line react/prop-types
    teamTypeSettings[teamType]['task_assignment_slots'][seasonKey][definedTimeSlotKey]['window_start_time'] != null &&
    // eslint-disable-next-line react/prop-types
    teamTypeSettings[teamType]['task_assignment_slots'][seasonKey][definedTimeSlotKey]['window_end_time'] != null
  ) {
    // eslint-disable-next-line react/prop-types
    initialFormData['day_1_start_time'] = teamTypeSettings[teamType]['task_assignment_slots'][seasonKey][definedTimeSlotKey]['window_start_time']
    // eslint-disable-next-line react/prop-types
    initialFormData['day_1_end_time'] = teamTypeSettings[teamType]['task_assignment_slots'][seasonKey][definedTimeSlotKey]['window_end_time']
  }
  if (taskAssignmentType === 'freeform_slots') {
    initialFormData['day_1_start_time'] = 8
    initialFormData['day_1_end_time'] = 17
  }
  if (taskAssignmentType === 'freeform_slots') {
    if (initialFormData['day_1_date']) {
      const day1Date = new Date(initialFormData['day_1_date']) // Convert day_1_date to a Date object

      // Day 2
      const day2Date = new Date(day1Date)
      day2Date.setDate(day1Date.getDate() + 1)
      initialFormData['day_2_date'] = day2Date.toISOString().split('T')[0]
      initialFormData['day_2_start_time'] = 8 // Default to 8:00 AM
      initialFormData['day_2_end_time'] = 17 // Default to 5:00 PM

      // Day 3
      const day3Date = new Date(day1Date)
      day3Date.setDate(day1Date.getDate() + 2)
      initialFormData['day_3_date'] = day3Date.toISOString().split('T')[0]
      initialFormData['day_3_start_time'] = 8 // Default to 8:00 AM
      initialFormData['day_3_end_time'] = 17 // Default to 5:00 PM

      // Day 4
      const day4Date = new Date(day1Date)
      day4Date.setDate(day1Date.getDate() + 3)
      initialFormData['day_4_date'] = day4Date.toISOString().split('T')[0]
      initialFormData['day_4_start_time'] = 8 // Default to 8:00 AM
      initialFormData['day_4_end_time'] = 17 // Default to 5:00 PM

      // Day 5
      const day5Date = new Date(day1Date)
      day5Date.setDate(day1Date.getDate() + 4)
      initialFormData['day_5_date'] = day5Date.toISOString().split('T')[0]
      initialFormData['day_5_start_time'] = 8 // Default to 8:00 AM
      initialFormData['day_5_end_time'] = 17 // Default to 5:00 PM
    }
  }

  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const { uc_setUserInterface_FormDialogDisplay } = 					useContext( Context_UserInterface_FormDialog )
  const [us_activeTask, us_setActiveTask] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeTeam, us_setActiveTeam] = useState<TsInterface_UnspecifiedObject>({})
  const [us_additionalProjectData, us_setAdditionalProjectData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_associatedProject, us_setAssociatedProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_backgroundColor, us_setBackgroundColor] = useState<string>(themeVariables.background_json)
  const [us_downstreamTask, us_setDownstreamTask] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formData] = useState<TsInterface_FormData>(initialFormData)
  const [us_formInputs, us_setFormInputs] = useState<TsInterface_FormInputs>({})
  const [us_taskAssigned, us_setTaskAssigned] = useState<boolean>(false)
  const [us_teamScheduledTasks, us_setTeamScheduledTasks] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const ur_mapRef = useRef(null)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setDownstreamTask(newData)
      ur_forceRerender()
    }
    if (us_activeTask != null && us_activeTask.associated_dispatched_task_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_Task_Document(res_GCK.clientKey, us_activeTask.associated_dispatched_task_key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_activeTask])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveTask(newData)
      ur_forceRerender()
    }
    if (taskKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Task_Document(res_GCK.clientKey, taskKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, taskKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAssociatedProject(newData)
      if (
        us_activeTask != null &&
        us_activeTask.associated_project_key != null &&
        (us_activeTask.location_latitude == null || us_activeTask.location_longitude == null)
      ) {
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            addCoordinatesToTask(res_GCK.clientKey, taskKey, newData)
          })
          .catch((rej_GCK) => {
            console.error(rej_GCK)
          })
      }
    }
    if (us_activeTask != null && us_activeTask.associated_project_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(res_GCK.clientKey, us_activeTask.associated_project_key), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_activeTask, taskKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveTeam(newData)
      // setLoadedActiveTeam( true )
      if (
        newData != null &&
        newData.team_type != null &&
        schedulingTeamTypes != null &&
        schedulingTeamTypes[newData.team_type] != null &&
        schedulingTeamTypes[newData.team_type]['color'] != null
      ) {
        us_setBackgroundColor(schedulingTeamTypes[newData.team_type]['color'])
      } else {
        us_setBackgroundColor(themeVariables.background_json)
      }
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_SchedulingTeams_Document(res_GCK.clientKey, teamKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, teamKey])

  useEffect(() => {
    let teamScheduledTasks: TsInterface_UnspecifiedObject = {}
    for (let loopTaskKey in allScheduledTasks) {
      let loopTask = allScheduledTasks[loopTaskKey]
      // eslint-disable-next-line react/prop-types
      if (loopTask.associated_team_key === teamKey) {
        teamScheduledTasks[loopTaskKey] = loopTask
      }
    }
    us_setTeamScheduledTasks(teamScheduledTasks)
  }, [allScheduledTasks, teamKey])

  useEffect(() => {
    // Customize Form
    if (taskAssignmentType === 'defined_slots') {
      us_setFormInputs(formInputs_TaskAssignmentDefinedTimeslots)
    } else {
      us_setFormInputs(formInputs_TaskAssignmentFreeformTimeslots)
    }
  }, [taskAssignmentType, definedTimeSlotKey])

  useEffect(() => {
    if (us_activeTask != null && us_activeTask.associated_project_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, us_activeTask.associated_project_key))
            .then((res_DGD) => {
              us_setAdditionalProjectData(res_DGD.data)
            })
            .catch((rej_DGD) => {
              console.error(rej_DGD)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_activeTask])

  // Other Variables
  let formAdditionalData: TsInterface_FormAdditionalData = {
    task_key: taskKey,
    team_key: teamKey,
    team_scheduled_tasks: us_teamScheduledTasks,
    time_slot_key: definedTimeSlotKey,
    estimated_task_duration_hours: us_downstreamTask.estimated_task_duration_hours,
  }

  // Functions
  const mapBasicOnClick: TsType_MapOnClick = (lat: number, lng: number) => {
    // Nothing
  }

  // JSX Generation
  const rJSX_TaskDetails = (): JSX.Element => {
    let tabJSX = <></>
    let teamTypeKey = getProp(us_activeTask, 'associated_team_type', '')
    let teamTypeName = teamTypeKey
    if (teamTypeKey != null && schedulingTeamTypes != null && schedulingTeamTypes[teamTypeKey] != null && schedulingTeamTypes[teamTypeKey]['value'] != null) {
      teamTypeName = schedulingTeamTypes[teamTypeKey]['value']
    }
    tabJSX = (
      <Box className="tw-px-4 tw-pb-2">
        <Box>
          <Typography variant="body1">
            {s_TASK_STATUS}: <span className="tw-italic tw-opacity-50">{us_activeTask.status}</span>
          </Typography>
          <Typography variant="body1">
            {s_DISPATCHER_TASK_ID}: <span className="tw-italic tw-opacity-50">{us_activeTask.id_number}</span>
          </Typography>
          <Typography variant="body1">
            {s_DISPATCHER_TASK_NAME}: <span className="tw-italic tw-opacity-50">{us_activeTask.name}</span>
          </Typography>
          <Typography variant="body1">
            {s_ASSIGNED_TASK_ID}: <span className="tw-italic tw-opacity-50">{us_downstreamTask.id_number}</span>
          </Typography>
          <Typography variant="body1">
            {s_ASSIGNED_TASK_NAME}: <span className="tw-italic tw-opacity-50">{us_downstreamTask.name}</span>
          </Typography>
          <Typography variant="body1">
            {s_TEAM_TYPE}: <span className="tw-italic tw-opacity-50">{teamTypeName}</span>
          </Typography>
          <Typography variant="body1">
            {s_TASK_ASSIGNER}: <span className="tw-italic tw-opacity-50">{us_activeTask.associated_owner_name}</span>
          </Typography>
          {rJSX_AdditionalProjectDetails(us_activeTask, us_associatedProject, us_additionalProjectData)}
        </Box>
        <Button
          color="info"
          variant="contained"
          onClick={() => {
            let url = window.location.origin + ApplicationPages.AdminActiveProjectViewPage.url(us_activeTask.associated_project_key)
            window.open(url, '_blank')
          }}
          disabled={us_activeTask.associated_project_key == null}
          className="tw-mt-2"
        >
          <Icon
            icon="square-arrow-up-right"
            className="tw-mr-2"
          />
          {s_VIEW_PROJECT}
        </Button>
      </Box>
    )
    return tabJSX
  }

  const rJSX_AssignmentTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_taskAssigned === true) {
      tabJSX = (
        <Box className="tw-p-4 tw-text-center">
          <Typography variant="h6">{s_TASK_ASSIGNMENT_COMPLETE}</Typography>
        </Box>
      )
    } else {
      tabJSX = (
        <Box className="tw-p-4">
          <Typography
            variant="h6"
            className="tw-mb-2"
          >
            <Box className="tw-inline-block tw-font-bold tw-mr-1">{s_TASK_ID}: </Box>
            <Box className="tw-inline-block tw-opacity-50">{us_activeTask.id_number} </Box>
          </Typography>
          <Form
            // ref={ formRef }
            formAdditionalData={formAdditionalData}
            formData={us_formData}
            formInputs={us_formInputs}
            formOnChange={formOnChange_TaskAssignment}
            formSettings={formSettings_TaskAssignment}
            // formSubmission={ formSubmission_TaskAssignment }
            formSubmission={(
              formSubmittedData: TsInterface_FormSubmittedData,
              formAdditionalData: TsInterface_FormAdditionalData,
              formHooks: TsInterface_FormHooksObject,
            ) => {
              return new Promise((resolve, reject) => {
                formSubmission_TaskAssignment(formSubmittedData, formAdditionalData, formHooks)
                  .then((res_FSTA) => {
                    us_setTaskAssigned(true)
                    resolve(res_FSTA)
                  })
                  .catch((rej_FSTA) => {
                    reject(rej_FSTA)
                  })
              })
            }}
          />
          <Box className="tw-text-right tw-opacity-10">
            <Typography
              variant="body1"
              className="tw-mt-4 tw-inline-block "
            >
              {us_activeTask.key}
            </Typography>
          </Box>
        </Box>
      )
    }
    return tabJSX
  }

  const rJSX_MapTab = (): JSX.Element => {
    let tabJSX = <></>
    // Generate List of Teams
    let loopTeams: TsInterface_UnspecifiedObject[] = []
    if (us_activeTeam.team_type != null) {
      for (let loopTeamKey in allTeams) {
        let loopTeam = allTeams[loopTeamKey]
        // eslint-disable-next-line react/prop-types
        if (loopTeam != null && loopTeamKey !== us_activeTeam.key && loopTeam.team_type === us_activeTeam.team_type) {
          loopTeams.push(loopTeam)
        }
      }
    }
    // Generate JSX if coordinates are specified
    if (us_activeTask != null && us_activeTask['location_latitude'] != null && us_activeTask['location_longitude'] != null) {
      // TODO - on hover color the pins on the map assigned to the highlighted team

      let mapBasicSettings: TsInterface_MapSettings = {
        center_lat: us_activeTask['location_latitude'],
        center_lng: us_activeTask['location_longitude'],
        height: '500px',
        render_clusters: false,
        render_heatmap: false,
        render_markers: true,
        width: '100%',
        zoom: 12,
      }
      const mapBasicMarkers: TsInterface_MapMarkers = {
        task: {
          position: {
            lat: us_activeTask['location_latitude'],
            lng: us_activeTask['location_longitude'],
          },
          icon: '',
          label: '',
        },
      }
      tabJSX = (
        <Box>
          {/* <Box>
						<Box
							className="tw-m-1 tw-inline-block tw-p-2 tw-rounded-md"
							sx={{ background: backgroundColor, "&:hover": { backgroundColor: themeVariables.success_main } }}
						>
							{ activeTeam.name }
						</Box>
						{ objectToArray( loopTeams ).sort( dynamicSort( "name", "asc" )).map(( team, index ) => (
							<Box
								key={ index }
								className="tw-m-1 tw-inline-block tw-p-2 tw-rounded-md"
								sx={{ background: backgroundColor, "&:hover": { backgroundColor: themeVariables.success_main } }}
							>
								{ team.name }
							</Box>
						))}
					</Box> */}
          <MapBasic
            ref={ur_mapRef}
            mapSettings={mapBasicSettings}
            mapMarkers={mapBasicMarkers}
            mapOnClick={mapBasicOnClick}
          />
        </Box>
      )
    } else {
      tabJSX = (
        <Box className="tw-p-4 tw-text-center">
          <Typography variant="h6">{s_NO_COORDINATES_FOR_THIS_TASK}</Typography>
        </Box>
      )
    }
    return tabJSX
  }

  const rJSX_AssignTabHeader = (): JSX.Element => {
    let tabHeader = <></>
    if (us_taskAssigned === false) {
      tabHeader = (
        <>
          <Badge
            badgeContent={<Icon icon="paper-plane" />}
            color="error"
          >
            {s_ASSIGN_TASK}
          </Badge>
        </>
      )
    } else {
      tabHeader = (
        <>
          <Badge
            badgeContent={<Icon icon="check" />}
            color="success"
          >
            {s_ASSIGN_TASK}
          </Badge>
        </>
      )
    }
    return tabHeader
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
            sx={{ backgroundColor: us_backgroundColor }}
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="paper-plane" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                <Box className="tw-inline-block">
                  {s_TASK_ASSIGNMENT}: {us_activeTask.name}
                </Box>
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <Box>
              <TabsBasic
                tabs={[
                  {
                    tabHeader: rJSX_AssignTabHeader(),
                    tabContent: rJSX_AssignmentTab(),
                  },
                  {
                    tabHeader: s_TASK_DETAILS,
                    tabContent: rJSX_TaskDetails(),
                  },
                  {
                    tabHeader: s_MAP,
                    tabContent: rJSX_MapTab(),
                  },
                ]}
                tabsSettings={{}}
              />
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

// eslint-disable-next-line react/prop-types
const AssignedEventCustomDialog: React.FC<TsInterface_AssignedTaskDialog> = ({ event, allScheduledTasks, allTeams, teamKey }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_activeTask, us_setActiveTask] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeTeam, us_setActiveTeam] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeTeamType, us_setActiveTeamType] = useState<string | null>(null)
  const [us_additionalProjectData, us_setAdditionalProjectData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_associatedProject, us_setAssociatedProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_backgroundColor, us_setBackgroundColor] = useState<string>(themeVariables.background_json)
  const [us_editMode, us_setEditMode] = useState<boolean>(false)
  const [us_formInputs, us_setFormInputs] = useState<TsInterface_FormInputs>({})
  const [us_formattedEventSchedule, us_setFormattedEventSchedule] = useState<TsInterface_UnspecifiedObject>({})
  const [us_teamTypeSettings, us_setTeamTypeSettings] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const ur_mapRef = useRef(null)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveTask(newData)
      ur_forceRerender()
    }
    // eslint-disable-next-line react/prop-types
    if (event != null && event.key != null && event.status === 'cancelled') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_CancelledScheduledTask_Document(res_GCK.clientKey, event.key), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
      // eslint-disable-next-line react/prop-types
    } else if (event != null && event.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          // eslint-disable-next-line react/prop-types
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Task_Document(res_GCK.clientKey, event.key), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, event])

  useEffect(() => {
    if (
      event != null &&
      // eslint-disable-next-line react/prop-types
      event.associated_team_type != null &&
      schedulingTeamTypes != null &&
      // eslint-disable-next-line react/prop-types
      schedulingTeamTypes[event.associated_team_type] != null &&
      // eslint-disable-next-line react/prop-types
      schedulingTeamTypes[event.associated_team_type]['color'] != null
    ) {
      // eslint-disable-next-line react/prop-types
      us_setBackgroundColor(schedulingTeamTypes[event.associated_team_type]['color'])
    } else {
      us_setBackgroundColor(themeVariables.background_json)
    }
    ur_forceRerender()
    return () => {}
  }, [event, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveTeam(newData)
      // setLoadedActiveTeam( true )
      if (newData != null && newData.team_type != null) {
        us_setActiveTeamType(newData.team_type)
      }
      if (
        newData != null &&
        newData.team_type != null &&
        schedulingTeamTypes != null &&
        schedulingTeamTypes[newData.team_type] != null &&
        schedulingTeamTypes[newData.team_type]['color'] != null
      ) {
        us_setBackgroundColor(schedulingTeamTypes[newData.team_type]['color'])
      } else {
        us_setBackgroundColor(themeVariables.background_json)
      }
      ur_forceRerender()
    }
    if (teamKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_SchedulingTeams_Document(res_GCK.clientKey, teamKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, teamKey])

  useEffect(() => {
    if (us_activeTeamType != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetDocument(DatabaseRef_SchedulingTeamTypeSettings_Document(res_GCK.clientKey, us_activeTeamType))
            .then((res_DGD) => {
              us_setTeamTypeSettings(res_DGD.data)
              ur_forceRerender()
            })
            .catch((rej_DGD) => {
              console.error(rej_DGD)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {}
  }, [us_activeTeamType, ur_forceRerender, uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAssociatedProject(newData)
    }
    if (us_activeTask != null && us_activeTask.associated_project_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(res_GCK.clientKey, us_activeTask.associated_project_key), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_activeTask])

  useEffect(() => {
    us_setFormInputs(formInputs_TaskAssignmentFreeformTimeslots)
  }, [])

  useEffect(() => {
    if (us_activeTask != null && us_activeTask.associated_project_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, us_activeTask.associated_project_key))
            .then((res_DGD) => {
              us_setAdditionalProjectData(res_DGD.data)
            })
            .catch((rej_DGD) => {
              console.error(rej_DGD)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_activeTask])

  // Other Variables
  let formAdditionalData: TsInterface_FormAdditionalData = {
    // eslint-disable-next-line react/prop-types
    task_key: event.key,
    team_key: teamKey,
    // team_scheduled_tasks: teamScheduledTasks,
    // time_slot_key: definedTimeSlotKey,
    // estimated_task_duration_hours: downstreamTask.estimated_task_duration_hours,
  }

  // Functions
  const mapBasicOnClick: TsType_MapOnClick = (lat: number, lng: number) => {
    // Nothing
  }

  const toggleEventEdit = (formVisible: boolean) => {
    us_setEditMode(formVisible)
    if (
      formVisible === true &&
      event != null &&
      // eslint-disable-next-line react/prop-types
      event['task_completion_scheduled_dates'] != null &&
      // eslint-disable-next-line react/prop-types
      event['task_completion_scheduled_start_times'] != null &&
      // eslint-disable-next-line react/prop-types
      event['task_completion_scheduled_end_times'] != null
    ) {
      let formattedDatesAndTimes: TsInterface_UnspecifiedObject = {}
      let formDayIndex = 0
      // Loop through event and format Event Data in Form Format
      // eslint-disable-next-line react/prop-types
      for (let loopDateIndex in event['task_completion_scheduled_dates']) {
        // eslint-disable-next-line react/prop-types
        let loopDateKey = event['task_completion_scheduled_dates'][loopDateIndex]
        if (
          loopDateKey != null &&
          // eslint-disable-next-line react/prop-types
          event['task_completion_scheduled_start_times'][loopDateKey] != null &&
          // eslint-disable-next-line react/prop-types
          event['task_completion_scheduled_end_times'][loopDateKey] != null
        ) {
          formDayIndex++
          formattedDatesAndTimes['day_' + formDayIndex.toString() + '_display'] = true
          formattedDatesAndTimes['day_' + formDayIndex.toString() + '_date'] = loopDateKey
          // eslint-disable-next-line react/prop-types
          formattedDatesAndTimes['day_' + formDayIndex.toString() + '_start_time'] = event['task_completion_scheduled_start_times'][loopDateKey]
          // eslint-disable-next-line react/prop-types
          formattedDatesAndTimes['day_' + formDayIndex.toString() + '_end_time'] = event['task_completion_scheduled_end_times'][loopDateKey]
        }
      }
      us_setFormattedEventSchedule(formattedDatesAndTimes)
    }
  }

  // JSX Generation
  const rJSX_TaskDetails = (): JSX.Element => {
    let tabJSX = <></>
    let teamTypeKey = getProp(us_activeTask, 'associated_team_type', '')
    let teamTypeName = teamTypeKey
    if (teamTypeKey != null && schedulingTeamTypes != null && schedulingTeamTypes[teamTypeKey] != null && schedulingTeamTypes[teamTypeKey]['value'] != null) {
      teamTypeName = schedulingTeamTypes[teamTypeKey]['value']
    }
    tabJSX = (
      <Box className="tw-px-4 tw-pb-2">
        <Box>
          <Typography variant="body1">
            {s_TASK_STATUS}: <span className="tw-italic tw-opacity-50">{us_activeTask.status}</span>
          </Typography>
          <Typography variant="body1">
            {s_ASSIGNED_TASK_ID}: <span className="tw-italic tw-opacity-50">{us_activeTask.id_number}</span>
          </Typography>
          <Typography variant="body1">
            {s_ASSIGNED_TASK_NAME}: <span className="tw-italic tw-opacity-50">{us_activeTask.name}</span>
          </Typography>
          <Typography variant="body1">
            {s_TEAM_TYPE}: <span className="tw-italic tw-opacity-50">{teamTypeName}</span>
          </Typography>
          <Typography variant="body1">
            {s_TASK_ASSIGNER}: <span className="tw-italic tw-opacity-50">{us_activeTask.associated_owner_name}</span>
          </Typography>
          <Typography variant="body1">
            {s_ESTIMATED_TASK_DURATION}:{' '}
            <span className="tw-italic tw-opacity-50">
              {us_activeTask.estimated_task_duration_hours} {s_HOURS}
            </span>{' '}
            {rJSX_EditTaskDurationButton(us_activeTask, uc_setUserInterface_FormDialogDisplay)}
          </Typography>
          <Typography
            variant="body1"
            sx={{ background: us_activeTask.invoice_id_number != null ? 'rgba(0,0,0,0)' : themeVariables.warning_main }}
          >
            {rLIB('Billing Reference')}: <span className="tw-italic tw-opacity-50">{us_activeTask.invoice_id_number} </span>{' '}
            {rJSX_EditInvoiceNumberButton(us_activeTask, uc_setUserInterface_FormDialogDisplay)}
          </Typography>
          <Typography variant="body1">
            {s_TASK_SPECIAL_INSTRUCTIONS}: <span className="tw-italic tw-opacity-50">{us_activeTask.special_instructions} </span>{' '}
            {rJSX_EditSpecialInstructionsButton(us_activeTask, uc_setUserInterface_FormDialogDisplay, uc_RootData_ClientUser)}
          </Typography>
          {rJSX_AdditionalProjectDetails(us_activeTask, us_associatedProject, us_additionalProjectData)}
        </Box>
        <Button
          color="info"
          variant="contained"
          onClick={() => {
            let url = window.location.origin + ApplicationPages.AdminActiveProjectViewPage.url(us_activeTask.associated_project_key)
            window.open(url, '_blank')
          }}
          disabled={us_activeTask.associated_project_key == null}
          className="tw-mt-2"
        >
          <Icon
            icon="square-arrow-up-right"
            className="tw-mr-2"
          />
          {s_VIEW_PROJECT}
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={() => {
            uc_setUserInterface_ConfirmDialogDisplay({
              display: true,
              confirm: {
                color: 'error',
                header: s_UNASSIGN_TASK,
                icon: (
                  <Icon
                    icon="arrow-rotate-left"
                    type="solid"
                  />
                ),
                submit_text: s_UNASSIGN,
                text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNASSIGN_THIS_TASK,
                submit_callback: () => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        cloudFunctionManageRequest('manageTasks', {
                          function: 'unassignDispatchedTask',
                          client_key: res_GCK.clientKey,
                          // eslint-disable-next-line react/prop-types
                          task_key: event.key,
                        })
                          .then((res_CFMTR) => {
                            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                            resolve(res_CFMTR)
                          })
                          .catch((rej_CFMTR) => {
                            reject(rej_CFMTR)
                          })
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                      })
                  })
                },
              },
            })
          }}
          className="tw-mt-2 tw-ml-2"
        >
          <Icon
            icon="arrow-rotate-left"
            className="tw-mr-2"
          />
          {s_UNASSIGN_TASK}
        </Button>
        <Button
          color="warning"
          variant="contained"
          onClick={() => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let promiseArray: TsType_UnknownPromise[] = []
                let baseInvoices: TsInterface_UnspecifiedObject = {}
                let additionalWorkInvoices: TsInterface_UnspecifiedObject = {}
                promiseArray.push(
                  DatabaseGetCollection(DatabaseRef_BaseInvoicesForProject_Query(res_GCK.clientKey, us_activeTask.associated_project_key as string))
                    .then((res_DGC) => {
                      baseInvoices = res_DGC.data
                    })
                    .catch((rej_DGC) => {
                      console.log(rej_DGC)
                    }),
                )

                promiseArray.push(
                  DatabaseGetCollection(DatabaseRef_AdditionalWorkInvoicesForProject_Query(res_GCK.clientKey, us_activeTask.associated_project_key as string))
                    .then((res_DGC) => {
                      additionalWorkInvoices = res_DGC.data
                    })
                    .catch((rej_DGC) => {
                      console.log(rej_DGC)
                    }),
                )

                Promise.all(promiseArray).then(() => {
                  // Set Invoice Options
                  let baseInvoiceOptions: TsInterface_UnspecifiedObject = {}
                  let additionalWorkInvoiceOptions: TsInterface_UnspecifiedObject = {}
                  for (let loopInvoiceKey in baseInvoices) {
                    let loopInvoice = baseInvoices[loopInvoiceKey]
                    if (loopInvoice != null && loopInvoice.invoice_id_number != null && loopInvoice.invoice_id_number != '') {
                      let disabled = false
                      if (
                        loopInvoice.status == 'completed' ||
                        loopInvoice.status == 'billed' ||
                        loopInvoice.status == 'paid' ||
                        loopInvoice.status == 'cancelled'
                      ) {
                        disabled = true
                      }
                      baseInvoiceOptions[loopInvoiceKey] = {
                        key: loopInvoiceKey,
                        value: baseInvoices[loopInvoiceKey].invoice_id_number + ' (Base)',
                        disabled: disabled,
                      }
                    }
                  }
                  for (let loopInvoiceKey in additionalWorkInvoices) {
                    let loopInvoice = additionalWorkInvoices[loopInvoiceKey]
                    if (loopInvoice != null && loopInvoice.invoice_id_number != null && loopInvoice.invoice_id_number != '') {
                      let disabled = false
                      if (
                        loopInvoice.status == 'completed' ||
                        loopInvoice.status == 'billed' ||
                        loopInvoice.status == 'paid' ||
                        loopInvoice.status == 'cancelled'
                      ) {
                        disabled = true
                      }
                      additionalWorkInvoiceOptions[loopInvoiceKey] = {
                        key: loopInvoiceKey,
                        value: additionalWorkInvoices[loopInvoiceKey].invoice_id_number + ' (Additional Work)',
                        disabled: disabled,
                      }
                    }
                  }
                  if (objectToArray(baseInvoiceOptions).length > 0) {
                    formInputs_RetryTask.associated_base_invoice_key.options = objectToArray(baseInvoiceOptions).sort(dynamicSort('value', 'asc'))
                  } else {
                    formInputs_RetryTask.associated_base_invoice_key.options = [{ key: 'none', value: 'No Invoices Found for this Project', disabled: true }]
                  }
                  if (objectToArray(additionalWorkInvoiceOptions).length > 0) {
                    formInputs_RetryTask.associated_additional_work_invoice_key.options = objectToArray(additionalWorkInvoiceOptions).sort(
                      dynamicSort('value', 'asc'),
                    )
                  } else {
                    formInputs_RetryTask.associated_additional_work_invoice_key.options = [
                      { key: 'none', value: 'No Invoices Found for this Project', disabled: true },
                    ]
                  }
                  uc_setUserInterface_FormDialogDisplay({
                    display: true,
                    form: {
                      form: {
                        formAdditionalData: {
                          task_key: getProp(us_activeTask, 'key', null),
                          associated_project_key: getProp(us_activeTask, 'associated_project_key', null),
                        },
                        formData: {},
                        formInputs: formInputs_RetryTask,
                        formOnChange: formOnChange_RetryTask,
                        formSettings: formSettings_RetryTask,
                        formSubmission: formSubmission_RetryTask,
                      },
                      dialog: {
                        formDialogHeaderColor: 'info',
                        formDialogHeaderText: (
                          <>
                            {s_REDO_ON_SITE_TASK} - {us_activeTask.name}
                          </>
                        ),
                        formDialogIcon: (
                          <Icon
                            type="solid"
                            icon="rotate-right"
                          />
                        ),
                      },
                    },
                  })
                })
              })
              .catch((rej_GCK) => {
                console.log(rej_GCK)
              })
          }}
          className="tw-mt-2 tw-ml-2"
        >
          <Icon
            icon="arrow-rotate-right"
            className="tw-mr-2"
          />
          {s_REDO_TASK}
        </Button>
        <Box className="tw-text-right tw-opacity-10 tw-italic">{us_activeTask.key}</Box>
      </Box>
    )
    return tabJSX
  }

  const rJSX_ScheduledDayOffDialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="calendar-circle-exclamation" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                <Box className="tw-inline-block">{s_SCHEDULED_TIME_OFF}</Box>
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <Box className="tw-p-4 tw-text-center">
              <Typography variant="h6">
                {s_THIS_DAY} ({rJSX_Weekday(event.scheduled_day_off.getDay())}) {s_IS_SCHEDULED_AS_TIME_OFF_FOR_THIS_TEAM}
              </Typography>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  const rJSX_DefinedSlotPlaceholderDialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="calendar" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                <Box className="tw-inline-block">{s_OPEN_TIME_SLOT}</Box>
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <Box className="tw-p-4 tw-text-center">
              <Box>
                <Typography variant="h6">{event.associated_team.name}</Typography>
                <Typography variant="subtitle1">
                  {s_OPEN} - {event.window_start_time} {s_TO} {event.window_end_time} ({returnFormattedDate(event.scheduled_day, 'D MMM YYYY')})
                </Typography>
              </Box>
              <Box className="tw-mt-4">
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => {
                    uc_setUserInterface_PromptDialogDisplay({
                      display: true,
                      prompt: {
                        color: 'info',
                        confirm_text: s_SAVE,
                        default_value: '',
                        header: s_SCHEDULE_SLOT_AS_TIME_OFF,
                        icon: (
                          <Icon
                            icon="pen-to-square"
                            type="solid"
                          />
                        ),
                        input_label: s_TIME_OFF_REASON,
                        input_type: 'text',
                        text: s_ENTER_A_REASON_FOR_TIME_OFF,
                        submit_callback: (promptValue: string) => {
                          return new Promise((resolve, reject) => {
                            let startTime: TsInterface_UnspecifiedObject = {}
                            startTime[returnFormattedDateKey(event.scheduled_day)] = event.window_start_time
                            let endTime: TsInterface_UnspecifiedObject = {}
                            endTime[returnFormattedDateKey(event.scheduled_day)] = event.window_end_time
                            let updateObject = {
                              // time_off_reason: // TODO - maybe include dropdown later....
                              associated_scheduled_time_slot: event.key,
                              timestamp_time_off: event.scheduled_day,
                              associated_team_key: event.associated_team.key,
                              associated_user_key: getProp(uc_RootData_ClientUser, 'key', null),
                              associated_user_name: getProp(uc_RootData_ClientUser, 'name', null),
                              time_off_reason_notes: promptValue,
                              timestamp_created: new Date(),
                              timestamp_last_updated: new Date(),
                              task_completion_scheduled_start_times: startTime,
                              task_completion_scheduled_end_times: endTime,
                            }
                            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                DatabaseAddDocument(DatabaseRef_ScheduledTimeOff_Collection(res_GCK.clientKey), updateObject, true)
                                  .then((res_DAD) => {
                                    resolve({ success: true })
                                    uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                                  })
                                  .catch((rej_DAD) => {
                                    console.error(rej_DAD)
                                  })
                              })
                              .catch((rej_GCK) => {
                                console.error(rej_GCK)
                              })
                          })
                        },
                      },
                    })
                  }}
                >
                  <Icon
                    icon="calendar-circle-plus"
                    className="tw-mr-2"
                  />
                  {s_SCHEDULE_AS_TIME_OFF}
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  const rJSX_TimeOffDialog = (): JSX.Element => {
    let timeOffEvent = getProp(event, 'actual_event', {})
    let timeOffCreatedDate = ''
    if (timeOffEvent.timestamp_created != null) {
      timeOffCreatedDate = '(' + returnFormattedDate(timeOffEvent.timestamp_created, 'D MMM YYYY h:mm a') + ')'
    }
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="clock" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                <Box className="tw-inline-block">{s_SCHEDULED_TIME_OFF}</Box>
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <Box className="tw-p-4 tw-text-center">
              <Box>
                <Typography variant="h6">
                  {s_THIS_APPOINTMENT_SLOT_IS_SCHEDULED_AS_TIME_OFF_FOR} {event.associated_team.name}
                </Typography>
                <Typography variant="subtitle1">
                  <Box className="tw-inline-block">{s_TIME_OFF_REASON}: </Box>
                  <Box className="tw-inline-block tw-italic tw-opacity-60">&nbsp;{getProp(timeOffEvent, 'time_off_reason_notes', '')} </Box>
                  <Box className="tw-inline-block tw-italic tw-opacity-60">
                    &nbsp;- {getProp(timeOffEvent, 'associated_user_name', '')} {timeOffCreatedDate}
                  </Box>
                </Typography>
              </Box>
              <Box className="tw-mt-4">
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => {
                    uc_setUserInterface_ConfirmDialogDisplay({
                      display: true,
                      confirm: {
                        color: 'error',
                        header: s_CANCEL_TIME_OFF,
                        icon: (
                          <Icon
                            icon="calendar-circle-minus"
                            type="solid"
                          />
                        ),
                        submit_text: s_CANCEL,
                        text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CANCEL_THIS_TIME_OFF,
                        submit_callback: () => {
                          return new Promise((resolve, reject) => {
                            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                DatabaseDeleteDocument(DatabaseRef_ScheduledTimeOff_Document(res_GCK.clientKey, timeOffEvent.key))
                                  .then((res_DDD) => {
                                    resolve({ success: true })
                                    uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                                  })
                                  .catch((rej_DDD) => {
                                    console.error(rej_DDD)
                                  })
                              })
                              .catch((rej_GCK) => {
                                console.error(rej_GCK)
                              })
                          })
                        },
                      },
                    })
                  }}
                >
                  <Icon
                    icon="calendar-circle-minus"
                    className="tw-mr-2"
                  />
                  {s_CANCEL_TIME_OFF}
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  const rJSX_TeamMembers = (): JSX.Element => {
    let tabJSX = <></>
    let mappedDateKeys: TsInterface_UnspecifiedObject = {}
    let loopIndex = 0
    for (let loopDateKey in us_activeTask['task_completion_scheduled_team_names']) {
      mappedDateKeys[loopIndex] = loopDateKey
      loopIndex++
    }
    if (us_activeTask != null && us_activeTask['task_completion_scheduled_team_names']) {
      tabJSX = (
        <Box className="tw-px-4 tw-py-2">
          <Typography variant="h6">
            {s_TEAM}: {us_activeTeam.name}
          </Typography>
          {objectToArray(us_activeTask['task_completion_scheduled_team_names']).map((scheduledDay, scheduledDateIndex) => (
            <Box
              key={scheduledDateIndex}
              className="tw-my-2"
            >
              <Typography variant="h6">{mappedDateKeys[scheduledDateIndex]}</Typography>
              {objectToArray(scheduledDay).map((teamMember, teamMemberIndex) => (
                <Box
                  key={teamMemberIndex}
                  className="tw-ml-4"
                >
                  {teamMember}
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      )
    }
    return tabJSX
  }

  const rJSX_MapTab = (): JSX.Element => {
    let tabJSX = <></>
    // Generate List of Teams
    let loopTeams: TsInterface_UnspecifiedObject[] = []
    if (us_activeTeam.team_type != null) {
      for (let loopTeamKey in allTeams) {
        let loopTeam = allTeams[loopTeamKey]
        if (loopTeam != null && loopTeamKey !== us_activeTeam.key && loopTeam.team_type === us_activeTeam.team_type) {
          loopTeams.push(loopTeam)
        }
      }
    }
    // Generate JSX if coordinates are specified
    if (us_activeTask != null && us_activeTask['location_latitude'] != null && us_activeTask['location_longitude'] != null) {
      // TODO - on hover color the pins on the map assigned to the highlighted team

      let mapBasicSettings: TsInterface_MapSettings = {
        center_lat: us_activeTask['location_latitude'],
        center_lng: us_activeTask['location_longitude'],
        height: '500px',
        render_clusters: false,
        render_heatmap: false,
        render_markers: true,
        width: '100%',
        zoom: 12,
      }
      const mapBasicMarkers: TsInterface_MapMarkers = {
        task: {
          position: {
            lat: us_activeTask['location_latitude'],
            lng: us_activeTask['location_longitude'],
          },
          icon: '',
          label: '',
        },
      }
      tabJSX = (
        <Box>
          <MapBasic
            ref={ur_mapRef}
            mapSettings={mapBasicSettings}
            mapMarkers={mapBasicMarkers}
            mapOnClick={mapBasicOnClick}
          />
        </Box>
      )
    } else {
      tabJSX = (
        <Box className="tw-p-4 tw-text-center">
          <Typography variant="h6">{s_NO_COORDINATES_FOR_THIS_TASK}</Typography>
        </Box>
      )
    }
    return tabJSX
  }

  const rJSX_AssignmentEditTab = (): JSX.Element => {
    let tabJSX = <></>
    let editFormJSX = <></>
    let editToggleJSX = (
      <Stack direction="row">
        <Switch
          checked={us_editMode}
          onChange={(event: any) => {
            if (event != null && event.target != null && event.target.checked != null) {
              toggleEventEdit(event.target.checked)
            }
          }}
        />
        <Typography
          className="tw-mt-1"
          variant="subtitle1"
        >
          {s_EDIT_TASK_SCHEDULE}
        </Typography>
      </Stack>
    )

    if (us_editMode === true) {
      editFormJSX = (
        <Form
          formAdditionalData={formAdditionalData}
          formData={us_formattedEventSchedule}
          formInputs={us_formInputs}
          formOnChange={formOnChange_TaskAssignment}
          formSettings={formSettings_TaskAssignment}
          formSubmission={(
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              formSubmission_TaskScheduleUpdate(formSubmittedData, formAdditionalData, formHooks)
                .then((res_FSTSU) => {
                  us_setEditMode(false)
                  resolve(res_FSTSU)
                })
                .catch((rej_FSTSU) => {
                  reject(rej_FSTSU)
                })
            })
          }}
        />
      )
    } else {
      editFormJSX = <></>
    }

    tabJSX = (
      <Box className="tw-p-4">
        <Box className="tw-mb-2">{editToggleJSX}</Box>
        {editFormJSX}
      </Box>
    )

    return tabJSX
  }

  const rJSX_QAReviewTab = (): JSX.Element => {
    let timestampJSX = <></>
    if (us_activeTask != null && us_activeTask.timestamp_qa_review_date != null) {
      timestampJSX = <>{returnFormattedDate(us_activeTask.timestamp_qa_review_date, 'D MMM YYYY h:mm a')}</>
    }
    let tabJSX = (
      <Box className="tw-px-4 tw-pb-2">
        <Typography variant="h5">{s_INSPECTION_QA_REVIEW}:</Typography>
        <Typography variant="body1">
          {s_DATE}: <span className="tw-italic tw-opacity-50">{timestampJSX} </span> {rJSX_EditQAButton(us_activeTask, uc_setUserInterface_FormDialogDisplay)}
        </Typography>
        <Typography variant="body1">
          {s_RESULT}: <span className="tw-italic tw-opacity-50">{us_activeTask.qa_review_result} </span>{' '}
          {rJSX_EditQAButton(us_activeTask, uc_setUserInterface_FormDialogDisplay)}
        </Typography>
        <Typography variant="body1">
          {s_NOTES}: <span className="tw-italic tw-opacity-50">{us_activeTask.qa_review_notes} </span>{' '}
          {rJSX_EditQAButton(us_activeTask, uc_setUserInterface_FormDialogDisplay)}
        </Typography>
      </Box>
    )
    return tabJSX
  }

  const rJSX_DialogTabs = (): JSX.Element => {
    let tabsJSX = <></>
    if (us_teamTypeSettings != null && us_teamTypeSettings.task_assignment_type === 'freeform_assignment') {
      tabsJSX = (
        <TabsBasic
          tabs={[
            {
              tabHeader: s_TASK_DETAILS,
              tabContent: rJSX_TaskDetails(),
            },
            {
              tabHeader: s_TEAM_MEMBERS,
              tabContent: rJSX_TeamMembers(),
            },
            {
              tabHeader: s_EDIT_SCHEDULE,
              tabContent: rJSX_AssignmentEditTab(),
            },
            {
              tabHeader: s_QA_REVIEW,
              tabContent: rJSX_QAReviewTab(),
            },
            {
              tabHeader: s_MAP,
              tabContent: rJSX_MapTab(),
            },
          ]}
          tabsSettings={{}}
        />
      )
    } else {
      tabsJSX = (
        <TabsBasic
          tabs={[
            {
              tabHeader: s_TASK_DETAILS,
              tabContent: rJSX_TaskDetails(),
            },
            {
              tabHeader: s_TEAM_MEMBERS,
              tabContent: rJSX_TeamMembers(),
            },
            {
              tabHeader: s_MAP,
              tabContent: rJSX_MapTab(),
            },
          ]}
          tabsSettings={{}}
        />
      )
    }
    return tabsJSX
  }

  const rJSX_EventDialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
            sx={{ backgroundColor: us_backgroundColor }}
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="paper-plane" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                <Box className="tw-inline-block">
                  {s_TASK}: {getProp(us_activeTask, 'abbreviation', null)} - {getProp(us_activeTask, 'name', null)}
                </Box>
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <Box>{rJSX_DialogTabs()}</Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  const rJSX_CancelledTaskDetails = (): JSX.Element => {
    let tabJSX = <></>
    let teamTypeKey = getProp(us_activeTask, 'associated_team_type', '')
    let teamTypeName = teamTypeKey
    if (teamTypeKey != null && schedulingTeamTypes != null && schedulingTeamTypes[teamTypeKey] != null && schedulingTeamTypes[teamTypeKey]['value'] != null) {
      teamTypeName = schedulingTeamTypes[teamTypeKey]['value']
    }
    tabJSX = (
      <Box className="tw-p-4">
        <Box>
          <Typography variant="h6">
            {s_TASK_STATUS}: <span className="tw-italic tw-opacity-50">{us_activeTask.status}</span>
          </Typography>
          <Typography variant="h6">
            {s_PROJECT}: <span className="tw-italic tw-opacity-50">{us_activeTask.associated_project_id_number}</span>
          </Typography>
          <Typography variant="h6">
            {s_ASSIGNED_TASK_NAME}: <span className="tw-italic tw-opacity-50">{us_activeTask.name}</span>
          </Typography>
          <Typography variant="h6">
            {s_TEAM_TYPE}: <span className="tw-italic tw-opacity-50">{teamTypeName}</span>
          </Typography>
        </Box>
        <Button
          color="info"
          variant="contained"
          onClick={() => {
            let url = window.location.origin + ApplicationPages.AdminActiveProjectViewPage.url(us_activeTask.associated_project_key)
            window.open(url, '_blank')
          }}
          disabled={us_activeTask.associated_project_key == null}
          className="tw-mt-2"
        >
          <Icon
            icon="square-arrow-up-right"
            className="tw-mr-2"
          />
          {s_VIEW_PROJECT}
        </Button>
        <Box className="tw-text-right tw-opacity-10 tw-italic">{us_activeTask.key}</Box>
      </Box>
    )
    return tabJSX
  }

  const rJSX_CancelledDialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
            sx={{ backgroundColor: us_backgroundColor }}
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="ban" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                <Box className="tw-inline-block">
                  {s_TASK}: {getProp(us_activeTask, 'name', null)}
                </Box>
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <Box>{rJSX_CancelledTaskDetails()}</Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = <></>
    if (event['is_scheduled_day_off']) {
      dialogJSX = rJSX_ScheduledDayOffDialog()
    } else if (event['status'] === 'open_time_slot') {
      dialogJSX = rJSX_DefinedSlotPlaceholderDialog()
    } else if (event['status'] === 'time_off') {
      dialogJSX = rJSX_TimeOffDialog()
    } else if (event['status'] === 'cancelled') {
      dialogJSX = rJSX_CancelledDialog()
    } else {
      dialogJSX = rJSX_EventDialog()
    }
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

// TODO - implement in team settings
function getDaylightSavingDates(inputDate: Date) {
  const year = new Date(inputDate).getFullYear()
  function getNthSundayOfMonth(month: number, nthSunday: number) {
    const firstDayOfMonth = new Date(year, month, 1)
    const firstSundayOfMonth = firstDayOfMonth.getDay() === 0 ? firstDayOfMonth : new Date(year, month, 1 + (7 - firstDayOfMonth.getDay()))
    const nthSundayOfMonth = new Date(firstSundayOfMonth.valueOf() + (nthSunday - 1) * 7 * 24 * 60 * 60 * 1000)
    return `${year}-${(month + 1).toString().padStart(2, '0')}-${nthSundayOfMonth.getDate().toString().padStart(2, '0')}`
  }
  const dst_start = getNthSundayOfMonth(2, 2) // March, 2nd Sunday
  const dst_end = getNthSundayOfMonth(10, 1) // November, 1st Sunday
  return { dst_start, dst_end }
}

function calculateSeasons(dst_start: string, dst_end: string) {
  function createDateFromISOString(isoString: any) {
    const date = new Date(isoString)
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  }
  const dstStart = createDateFromISOString(dst_start)
  const dstEnd = createDateFromISOString(dst_end)
  const winter1Start = new Date(Date.UTC(dstStart.getFullYear(), 0, 1))
  const winter1End = new Date(Date.UTC(dstStart.getFullYear(), dstStart.getUTCMonth(), dstStart.getUTCDate()))
  const summer1Start = new Date(Date.UTC(dstStart.getFullYear(), dstStart.getUTCMonth(), dstStart.getUTCDate() + 1))
  const summer1End = new Date(Date.UTC(dstEnd.getFullYear(), dstEnd.getUTCMonth(), dstEnd.getUTCDate()))
  const winter2Start = new Date(Date.UTC(dstEnd.getFullYear(), dstEnd.getUTCMonth(), dstEnd.getUTCDate() + 1))
  const winter2End = new Date(Date.UTC(dstEnd.getFullYear(), 11, 31))
  function formatDate(date: Date) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    return `${date.getUTCDate()} ${monthNames[date.getUTCMonth()]} ${date.getUTCFullYear()}`
  }
  return {
    winter1_start: formatDate(winter1Start),
    winter1_end: formatDate(winter1End),
    summer1_start: formatDate(summer1Start),
    summer1_end: formatDate(summer1End),
    winter2_start: formatDate(winter2Start),
    winter2_end: formatDate(winter2End),
  }
}

// Team Filters
const returnedFilteredArrayOfObjects = (
  inputArray: TsInterface_UnspecifiedObject[],
  filterProperty: string,
  filterValues: string[],
): TsInterface_UnspecifiedObject[] => {
  let outputArray: TsInterface_UnspecifiedObject[] = inputArray.filter((element, index, arr) => {
    if (filterValues == null || filterValues.length === 0) {
      return true
    } else {
      return filterValues.indexOf(element[filterProperty]) > -1
    }
  })
  return outputArray
}

const groupAndSortArrayOfObjects = (arr: TsInterface_UnspecifiedObject[], groupKey: string, sortKey: string): TsInterface_UnspecifiedObject[] => {
  // Group the array by the groupKey
  const grouped = arr.reduce((result, obj) => {
    const group = obj[groupKey]
    if (!result[group]) {
      result[group] = []
    }
    result[group].push(obj)
    return result
  }, {})
  // Sort each group by the sortKey
  for (const group in grouped) {
    grouped[group].sort((a: { [x: string]: string }, b: { [x: string]: any }) => a[sortKey].localeCompare(b[sortKey]))
  }
  // Sort the groups alphabetically
  const sortedGroups = Object.keys(grouped).sort()
  // Create a new array with the sorted groups and objects
  const sortedArr = []
  for (const group of sortedGroups) {
    sortedArr.push(...grouped[group])
  }
  return sortedArr
}

const returnFilteredTeams = (
  activeTeams: TsInterface_UnspecifiedObject,
  selectedRegion: string,
  visibleTeamsTypesFilter: string[],
  filteredUserKey: string,
  calendarEvents: TsInterface_UnspecifiedObject,
): TsInterface_UnspecifiedObject[] => {
  let filteredTeams = returnedFilteredArrayOfObjects(objectToArray(activeTeams), 'team_type', visibleTeamsTypesFilter).sort(dynamicSort('name', 'asc'))
  if (filteredUserKey != null && filteredUserKey !== '') {
    let tempFilteredTeamsObject: TsInterface_UnspecifiedObject = {}
    for (let loopTeamKey in filteredTeams) {
      let loopTeam = filteredTeams[loopTeamKey]
      // Show teams that the user is tied to
      if (loopTeam != null && loopTeam.associated_member_keys != null && loopTeam.associated_member_keys[filteredUserKey] === true) {
        tempFilteredTeamsObject[loopTeam.key] = loopTeam
      }
    }
    // Show teams that have a task that this user is on
    for (let loopEventKey in calendarEvents) {
      let loopEvent = calendarEvents[loopEventKey]
      if (loopEvent != null && loopEvent.task_completion_scheduled_team_keys != null && loopEvent.associated_team_key != null) {
        for (let loopDateKey in loopEvent.task_completion_scheduled_team_keys) {
          let loopDate = loopEvent.task_completion_scheduled_team_keys[loopDateKey]
          for (let loopUserKey in loopDate) {
            if (filteredUserKey === loopUserKey) {
              tempFilteredTeamsObject[loopEvent.associated_team_key] = activeTeams[loopEvent.associated_team_key]
            }
          }
        }
      }
    }
    filteredTeams = objectToArray(tempFilteredTeamsObject).sort(dynamicSort('name', 'asc'))
  }
  if (selectedRegion != null && selectedRegion !== '' && selectedRegion !== 'ALL_REGIONS') {
    let tempFilteredTeamsObject2: TsInterface_UnspecifiedObject = {}
    for (let loopTeamKey in filteredTeams) {
      let loopTeam = filteredTeams[loopTeamKey]
      // Show teams that the user is tied to
      if (loopTeam != null && loopTeam.associated_region_key === selectedRegion) {
        tempFilteredTeamsObject2[loopTeam.key] = loopTeam
      }
    }
    filteredTeams = objectToArray(tempFilteredTeamsObject2).sort(dynamicSort('name', 'asc'))
  }
  return groupAndSortArrayOfObjects(filteredTeams, 'team_type', 'name')
}

function getWeekDates(dateString: string) {
  let utcDate = new Date(dateString as string)
  let date = new Date(utcDate.getTime() + Math.abs(utcDate.getTimezoneOffset() * 60000))
  // const date = new Date(dateString);
  const dayOfWeek = date.getDay() // 0 (Sunday) to 6 (Saturday)
  const sundayDate = new Date(date.getTime() - dayOfWeek * 24 * 60 * 60 * 1000)
  const weekDates = []
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(sundayDate.getTime() + i * 24 * 60 * 60 * 1000)
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')
    const formattedDate = `${year}-${month}-${day}`
    weekDates.push(formattedDate)
  }
  return weekDates
}

function processTeamData(
  teamMembers: TsInterface_UnspecifiedObject,
  teamRoles: TsInterface_UnspecifiedObject,
  taskKey: string,
  rawTimepunchData: TsInterface_UnspecifiedObject,
) {
  const userMap: TsInterface_UnspecifiedObject = {}
  // Iterate through teamMembers and teamRoles to populate userMap
  for (const dateKey in teamMembers) {
    const users = teamMembers[dateKey]
    const roles = teamRoles[dateKey]
    for (const userKey in users) {
      const userName = users[userKey]
      const roleKey = roles && roles[userKey] // Check if roles exist
      if (!userMap[userKey]) {
        userMap[userKey] = {
          dateCount: 0,
          userKey,
          name: userName,
          roles: {},
          totalTimeSpent: 0, // Initialize totalTimeSpent
          foundPunchData: false,
        }
      }
      userMap[userKey].dateCount++
      if (roleKey) {
        // Check if roleKey exists
        userMap[userKey].roles[roleKey] = roleKey
      }
    }
  }
  // Calculate totalTimeSpent for each user
  for (const userKey in userMap) {
    const user = userMap[userKey]
    let filteredTimepuncheData = returnedFilteredArrayOfObjects(objectToArray(rawTimepunchData), 'associated_user_key', [userKey])
    filteredTimepuncheData.sort(dynamicSort('timestamp', 'asc'))
    if (filteredTimepuncheData.length > 0) {
      for (let i = 0; i < filteredTimepuncheData.length - 1; i++) {
        const punch1: TsInterface_UnspecifiedObject = filteredTimepuncheData[i]
        const punch2: TsInterface_UnspecifiedObject = filteredTimepuncheData[i + 1]
        if (punch1.type === 'clock_in' && punch1.associated_tasks && punch1.associated_tasks[taskKey] && punch2.type === 'clock_out') {
          const timeDiff = (punch2.timestamp - punch1.timestamp) / 1000 / 60 / 60
          user.totalTimeSpent += timeDiff
          user.foundPunchData = true
        }
      }
    }
  }
  // Convert userMap to an array and sort by dateCount in descending order
  const resultArray = Object.values(userMap).sort((a, b) => b.dateCount - a.dateCount)
  return resultArray
}

function calculateTotalHoursWorked(startTimes: TsInterface_UnspecifiedObject, endTimes: TsInterface_UnspecifiedObject) {
  let totalHoursWorked = 0
  // Loop through each date in startTimes
  for (const dateKey in startTimes) {
    // eslint-disable-next-line no-prototype-builtins
    if (startTimes.hasOwnProperty(dateKey) && endTimes.hasOwnProperty(dateKey)) {
      const startTime = new Date(` ${dateKey} ${startTimes[dateKey]}`)
      const endTime = new Date(` ${dateKey} ${endTimes[dateKey]}`)
      // Calculate the time difference in milliseconds
      // @ts-expect-error - TODO: reason for error
      const timeDifference = endTime - startTime
      // Convert the time difference to hours
      const hoursWorked = timeDifference / (1000 * 60 * 60)
      // Add the hours worked to the total
      totalHoursWorked += hoursWorked
    }
  }
  return totalHoursWorked
}

const getScheduleExportData = (
  clientKey: string,
  dateKey: string,
  activeTeams: TsInterface_UnspecifiedObject,
  startSundayAt12am: Date,
  endSaturdayAt12am: Date,
  endSundayAt12am: Date,
  dataQueryType: 'all' | 'recently_updated', // Added 'assigned_only'
): TsType_ScheduleExportData => {
  return new Promise((resolve, reject) => {
    if (dataQueryType === 'all') {
      // Original logic for 'all' data query type
      let taskData: TsInterface_UnspecifiedObject = {}
      let cancelledTaskData: TsInterface_UnspecifiedObject = {}
      let timeOffData: TsInterface_UnspecifiedObject = {}
      let punchData: TsInterface_UnspecifiedObject = {}
      let promiseArray1: TsType_UnknownPromise[] = []
      let sunToSatdateKeyArray: string[] = []

      // Generate Date Queries
      for (let loopTime = startSundayAt12am.getTime(); loopTime <= endSaturdayAt12am.getTime() + 3600000; loopTime += 86400000) {
        let loopDate = new Date(loopTime)
        let loopDateKey = formatDateToYYYYMMDD(loopDate)
        sunToSatdateKeyArray.push(loopDateKey)
      }

      let sunToSatDateKeySplitArrays: string[][] = []
      for (let loopIndex = 0; loopIndex < sunToSatdateKeyArray.length; loopIndex += 10) {
        sunToSatDateKeySplitArrays.push(sunToSatdateKeyArray.slice(loopIndex, loopIndex + 10))
      }

      // Get Data
      for (let loopIndex in sunToSatDateKeySplitArrays) {
        let loopDateKeyArray = sunToSatDateKeySplitArrays[loopIndex]
        promiseArray1.push(
          DatabaseGetCollection(DatabaseRef_ScheduledTasksInDateRangeArray_Query(clientKey, loopDateKeyArray))
            .then((res_GCC) => {
              for (let loopKey in getProp(res_GCC, 'data', {})) {
                taskData[loopKey] = res_GCC.data[loopKey]
              }
            })
            .catch((rej_GCC) => {
              console.error(rej_GCC)
            }),
        )
        promiseArray1.push(
          DatabaseGetCollection(DatabaseRef_CancelledScheduledTasksInDateRangeArray_Query(clientKey, loopDateKeyArray))
            .then((res_GCC) => {
              for (let loopKey in getProp(res_GCC, 'data', {})) {
                cancelledTaskData[loopKey] = res_GCC.data[loopKey]
              }
            })
            .catch((rej_GCC) => {
              console.error(rej_GCC)
            }),
        )
      }

      // Time off and punch data
      promiseArray1.push(
        DatabaseGetCollection(DatabaseRef_ScheduledTimeOff_Query(clientKey, startSundayAt12am, endSundayAt12am))
          .then((res_GCC) => {
            timeOffData = res_GCC.data
          })
          .catch((rej_GCC) => {
            console.error(rej_GCC)
          }),
      )
      promiseArray1.push(
        DatabaseGetCollection(DatabaseRef_TimePunches_DateRange_Query(clientKey, startSundayAt12am, endSundayAt12am))
          .then((res_GCC) => {
            punchData = res_GCC.data
          })
          .catch((rej_GCC) => {
            console.error(rej_GCC)
          }),
      )

      // Resolve all promises
      Promise.all(promiseArray1).then((res_PA) => {
        resolve({
          success: true,
          data: {
            taskData: taskData,
            cancelledTaskData: cancelledTaskData,
            timeOffData: timeOffData,
            punchData: punchData,
          },
        })
      })
    } else if (dataQueryType === 'recently_updated') {
      // Original logic for 'recently_updated' data query type
      let taskData: TsInterface_UnspecifiedObject = {}
      let cancelledTaskData: TsInterface_UnspecifiedObject = {}
      let timeOffData: TsInterface_UnspecifiedObject = {}
      let punchData: TsInterface_UnspecifiedObject = {}
      let promiseArray1: TsType_UnknownPromise[] = []

      // Get Data
      promiseArray1.push(
        DatabaseGetCollection(DatabaseRef_ScheduledTasks_LastUpdated_Query(clientKey, startSundayAt12am, endSaturdayAt12am))
          .then((res_GCC) => {
            // taskData = res_GCC.data
          })
          .catch((rej_GCC) => {
            console.error(rej_GCC)
          }),
      )
      promiseArray1.push(
        DatabaseGetCollection(DatabaseRef_CancelledScheduledTasks_LastUpdated_Query(clientKey, startSundayAt12am, endSaturdayAt12am))
          .then((res_GCC) => {
            // cancelledTaskData = res_GCC.data
          })
          .catch((rej_GCC) => {
            console.error(rej_GCC)
          }),
      )
      promiseArray1.push(
        DatabaseGetCollection(DatabaseRef_ScheduledTimeOff_LastUpdated_Query(clientKey, startSundayAt12am, endSundayAt12am))
          .then((res_GCC) => {
            timeOffData = res_GCC.data
          })
          .catch((rej_GCC) => {
            console.error(rej_GCC)
          }),
      )
      promiseArray1.push(
        DatabaseGetCollection(DatabaseRef_TimePunches_DateRange_Query(clientKey, startSundayAt12am, endSundayAt12am))
          .then((res_GCC) => {
            punchData = res_GCC.data
          })
          .catch((rej_GCC) => {
            console.error(rej_GCC)
          }),
      )

      // Resolve
      Promise.all(promiseArray1).then((res_PA) => {
        resolve({
          success: true,
          data: {
            taskData: taskData,
            cancelledTaskData: cancelledTaskData,
            timeOffData: timeOffData,
            punchData: punchData,
          },
        })
      })
    } else {
      // Empty report
      resolve({
        success: true,
        data: {
          taskData: {},
          cancelledTaskData: {},
          timeOffData: {},
          punchData: {},
        },
      })
    }
  })
}

const exportScheduleWithTimesheetData = (
  clientKey: string,
  dateKey: string,
  activeTeams: TsInterface_UnspecifiedObject,
  startSundayAt12am: Date,
  endSaturdayAt12am: Date,
  endSundayAt12am: Date,
  dataQueryType: 'all' | 'recently_updated',
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    // Instantiate Variables
    let exportData: string[][] = [
      [
        // TASK DETAILS
        'Project Code',
        'Task Name',
        'Task Status',
        'Task Assigned By',
        'Number of Scheduled Days',
        'First Scheduled Date',
        'Last Scheduled Date',
        'Scheduled Day Falls Outside Week',
        // TEAM DETAILS
        'Team Name',
        'Team Type',
        'Team Members',
        'Crew Size',
        'Total Clocked Team Hours',
        // Totals
        'Total Scheduled On Site Hours',
        'Total Allocated Units',
        'Distance to Warehouse',
        'Pulloff Reason (If Applicable)',
        // Individual Team Members
        'Install Foreman',
        'Install Lead',
        'Installer 1',
        'Installer 2',
        'Installer 3',
        'SOW Foreman',
        'SOW Helper 1',
        'SOW Helper 2',
        'Additional Users',
        // Unit Pay
        'FIC Date',
        'Unit Pay Date',
        'Zip',
        'Region',
        'Roof Pitch',
        'Has Pitch Adder',
        'Batteries',
        'Has Battery Adder',
        'Roof Type',
        'Number of Panels',
        'Utility',
        'CSS Owner',
        'System Size',
        'Task Workflow Name',
        'Billing Reference',
        'Task Unique Identifier',
      ],
    ]

    let weekDays = getWeekDates(dateKey)
    let promiseArray2: TsType_UnknownPromise[] = []

    // Modified query logic based on taskFilterType
    getScheduleExportData(clientKey, dateKey, activeTeams, startSundayAt12am, endSaturdayAt12am, endSundayAt12am, dataQueryType)
      .then((res_GSED) => {
        let taskData = getProp(res_GSED.data, 'taskData', {})
        let cancelledTaskData = getProp(res_GSED.data, 'cancelledTaskData', {})
        let timeOffData = getProp(res_GSED.data, 'timeOffData', {})
        let punchData = getProp(res_GSED.data, 'punchData', {})
        let projectsToLoad: TsInterface_UnspecifiedObject = {}
        let loadedProjects: TsInterface_UnspecifiedObject = {}

        // Get Project Data for the filtered tasks
        for (let loopTaskKey in taskData) {
          let loopTask = taskData[loopTaskKey]
          if (loopTask != null && loopTask.associated_project_key != null) {
            projectsToLoad[loopTask.associated_project_key] = true
          }
        }
        for (let loopTaskKey in cancelledTaskData) {
          let loopTask = cancelledTaskData[loopTaskKey]
          if (loopTask != null && loopTask.associated_project_key != null) {
            projectsToLoad[loopTask.associated_project_key] = true
          }
        }
        for (let loopProjectKey in projectsToLoad) {
          promiseArray2.push(
            DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, loopProjectKey))
              .then((res_GD) => {
                loadedProjects[loopProjectKey] = res_GD.data
              })
              .catch((rej_GD) => {
                console.error(rej_GD)
              }),
          )
        }
        Promise.all(promiseArray2).then((res_PA) => {
          // Generate Spreadsheet Data Array
          // Clean Dates
          for (let loopPunchKey in punchData) {
            punchData[loopPunchKey]['timestamp'] = returnDateFromUnknownDateFormat(punchData[loopPunchKey]['timestamp'])
          }
          // Loop through Tasks
          for (let loopTaskKey in taskData) {
            let loopTask = taskData[loopTaskKey]
            let associatedProject: TsInterface_UnspecifiedObject = {}
            if (
              loopTask != null &&
              loopTask.associated_project_key != null &&
              loadedProjects != null &&
              loadedProjects[loopTask.associated_project_key] != null
            ) {
              associatedProject = loadedProjects[loopTask.associated_project_key]
            }
            let dataRow: any[] = []
            //===============================
            // TASK DETAILS
            //===============================
            // DATA - Project Code
            dataRow.push(getProp(loopTask, 'associated_project_id_number', ''))
            // DATA - Task Name
            dataRow.push(getProp(loopTask, 'name', ''))
            let status = getProp(loopTask, 'status', '')
            // DATA - Task Status
            if (status === 'active') {
              dataRow.push('assigned')
            } else {
              dataRow.push(getProp(loopTask, 'status', ''))
            }
            // DATA - Task Assigned By
            dataRow.push(getProp(loopTask, 'timestamp_scheduled_by_name', ''))
            let scheduledDates = getProp(loopTask, 'task_completion_scheduled_dates', [])
            // DATA - Number of Scheduled Days
            dataRow.push(scheduledDates.length)
            if (scheduledDates.length === 0) {
              // DATA - First Scheduled Date
              dataRow.push('')
              // DATA - Last Scheduled Date
              dataRow.push('')
            } else if (scheduledDates.length === 1) {
              // DATA - First Scheduled Date
              dataRow.push(scheduledDates[0])
              // DATA - Last Scheduled Date
              dataRow.push('')
            } else {
              // DATA - First Scheduled Date
              dataRow.push(scheduledDates[0])
              // DATA - Last Scheduled Date
              dataRow.push(scheduledDates[scheduledDates.length - 1])
            }
            let foundDateOutsideWeek = false
            for (let loopDateKey in scheduledDates) {
              let foundLoopDate = false
              for (let loopCheckDateKey in weekDays) {
                if (scheduledDates[loopDateKey] === weekDays[loopCheckDateKey]) {
                  foundLoopDate = true
                }
              }
              if (foundLoopDate === false) {
                foundDateOutsideWeek = true
              }
            }
            if (foundDateOutsideWeek === true) {
              // DATA - Scheduled Day Falls Outside Week
              dataRow.push("Yes, clocked hours won't pull from dates outside of this week")
            } else {
              // DATA - Scheduled Day Falls Outside Week
              dataRow.push('')
            }
            //===============================
            // TEAM DETAILS
            //===============================
            // DATA - Team Name
            dataRow.push(getProp(loopTask, 'associated_team_name', ''))
            // DATA - Team Type
            dataRow.push(getProp(loopTask, 'associated_team_type', '').replace('_', ' '))
            let teamMembersObject = getProp(loopTask, 'task_completion_scheduled_team_names', {})
            let teamRolesObject = getProp(loopTask, 'task_completion_scheduled_team_roles', {})
            let cleanTeamMembers = processTeamData(teamMembersObject, teamRolesObject, loopTaskKey, punchData)
            let compositeTeamMemberString = ''
            let totalTimeSpent = 0
            for (let loopTeamMemberKey in cleanTeamMembers) {
              if (compositeTeamMemberString.length > 0) {
                compositeTeamMemberString += ' | '
              }
              if (cleanTeamMembers[loopTeamMemberKey].foundPunchData === false) {
                compositeTeamMemberString += cleanTeamMembers[loopTeamMemberKey].name + ' (NO PUNCH DATA)'
              } else {
                compositeTeamMemberString +=
                  cleanTeamMembers[loopTeamMemberKey].name + ' (' + cleanTeamMembers[loopTeamMemberKey].totalTimeSpent.toFixed(6) + ')'
              }
              totalTimeSpent += cleanTeamMembers[loopTeamMemberKey].totalTimeSpent
            }
            // DATA - Team Members
            dataRow.push(compositeTeamMemberString)
            let maxCrewSize = 0
            for (let loopDateKey in teamMembersObject) {
              let loopDate = teamMembersObject[loopDateKey]
              if (objectToArray(loopDate).length > maxCrewSize) {
                maxCrewSize = objectToArray(loopDate).length
              }
            }
            // DATA - Crew Size
            dataRow.push(maxCrewSize)
            // DATA - Total Clocked Team Hours
            dataRow.push(totalTimeSpent.toFixed(1))
            let scheduledHours = calculateTotalHoursWorked(loopTask.task_completion_scheduled_start_times, loopTask.task_completion_scheduled_end_times)
            // DATA - Total Scheduled On Site Hours
            dataRow.push(scheduledHours)
            // DATA - Total Allocated Units
            dataRow.push(getProp(loopTask, 'estimated_task_duration_hours', ''))
            // DATA - Distance to Warehouse
            dataRow.push(getProp(associatedProject, 'location_distance_from_warehouse', ''))
            // DATA - Pulloff Reason (If Applicable)
            dataRow.push('')
            let filledTeamMembers: TsInterface_UnspecifiedObject = {
              install_foreman: null,
              install_lead: null,
              installer1: null,
              installer2: null,
              installer3: null,
              sow_foreman: null,
              sow_helper1: null,
              sow_helper2: null,
            }
            let additionallUsersString = ''
            for (let loopUserIndex in cleanTeamMembers) {
              let loopUser = cleanTeamMembers[loopUserIndex]
              let addToAdditionallUsers = false
              let additionallUserRole = 'Missing Team Role'
              if (loopUser != null && loopUser.roles != null && loopUser.roles['install_foreman'] != null) {
                if (filledTeamMembers['install_foreman'] == null) {
                  filledTeamMembers['install_foreman'] = loopUser.name
                } else {
                  addToAdditionallUsers = true
                  additionallUserRole = 'Additionall Install Foreman'
                }
              } else if (loopUser != null && loopUser.roles != null && loopUser.roles['install_lead'] != null) {
                if (filledTeamMembers['install_lead'] == null) {
                  filledTeamMembers['install_lead'] = loopUser.name
                } else {
                  addToAdditionallUsers = true
                  additionallUserRole = 'Additionall Install Lead'
                }
              } else if (loopUser != null && loopUser.roles != null && loopUser.roles['installer'] != null) {
                if (filledTeamMembers['installer1'] == null) {
                  filledTeamMembers['installer1'] = loopUser.name
                } else if (filledTeamMembers['installer2'] == null) {
                  filledTeamMembers['installer2'] = loopUser.name
                } else if (filledTeamMembers['installer3'] == null) {
                  filledTeamMembers['installer3'] = loopUser.name
                } else {
                  addToAdditionallUsers = true
                  additionallUserRole = 'Additionall Installer'
                }
              } else if (loopUser != null && loopUser.roles != null && loopUser.roles['sow_foreman'] != null) {
                if (filledTeamMembers['sow_foreman'] == null) {
                  filledTeamMembers['sow_foreman'] = loopUser.name
                } else {
                  addToAdditionallUsers = true
                  additionallUserRole = 'Additionall SOW Foreman'
                }
              } else if (loopUser != null && loopUser.roles != null && loopUser.roles['sow_helper'] != null) {
                if (filledTeamMembers['sow_helper1'] == null) {
                  filledTeamMembers['sow_helper1'] = loopUser.name
                } else if (filledTeamMembers['sow_helper2'] == null) {
                  filledTeamMembers['sow_helper2'] = loopUser.name
                } else {
                  addToAdditionallUsers = true
                  additionallUserRole = 'Additionall SOW Helper'
                }
              }
              if (addToAdditionallUsers === true) {
                if (additionallUsersString.length > 0) {
                  additionallUsersString += ' | '
                }
                additionallUsersString += loopUser.name + ' (' + additionallUserRole + ')'
              }
            }
            if (filledTeamMembers['install_foreman'] != null) {
              // DATA - Install Foreman
              dataRow.push(filledTeamMembers['install_foreman'])
            } else {
              // DATA - Install Foreman
              dataRow.push('')
            }
            if (filledTeamMembers['install_lead'] != null) {
              // DATA - Install Lead
              dataRow.push(filledTeamMembers['install_lead'])
            } else {
              // DATA - Install Lead
              dataRow.push('')
            }
            if (filledTeamMembers['installer1'] != null) {
              // DATA - Installer 1
              dataRow.push(filledTeamMembers['installer1'])
            } else {
              // DATA - Installer 1
              dataRow.push('')
            }
            if (filledTeamMembers['installer2'] != null) {
              // DATA - Installer 2
              dataRow.push(filledTeamMembers['installer2'])
            } else {
              // DATA - Installer 2
              dataRow.push('')
            }
            if (filledTeamMembers['installer3'] != null) {
              // DATA - Installer 3
              dataRow.push(filledTeamMembers['installer3'])
            } else {
              // DATA - Installer 3
              dataRow.push('')
            }
            if (filledTeamMembers['sow_foreman'] != null) {
              // DATA - SOW Foreman
              dataRow.push(filledTeamMembers['sow_foreman'])
            } else {
              // DATA - SOW Foreman
              dataRow.push('')
            }
            if (filledTeamMembers['sow_helper1'] != null) {
              // DATA - SOW Helper 1
              dataRow.push(filledTeamMembers['sow_helper1'])
            } else {
              // DATA - SOW Helper 1
              dataRow.push('')
            }
            if (filledTeamMembers['sow_helper2'] != null) {
              // DATA - SOW Helper 2
              dataRow.push(filledTeamMembers['sow_helper2'])
            } else {
              // DATA - SOW Helper 2
              dataRow.push('')
            }
            // DATA - Additionall Users
            dataRow.push(additionallUsersString)
            if (getProp(associatedProject, 'timestamp_construction_complete', null) != null) {
              // DATA - FIC Date
              dataRow.push(returnFormattedDate(associatedProject.timestamp_construction_complete, 'M/D/YYYY'))
            } else {
              // DATA - FIC Date
              dataRow.push('')
            }
            // DATA - Unit Pay Date
            if (getProp(associatedProject, 'timestamp_unit_pay_date', null) != null) {
              // DATA - FIC Date
              dataRow.push(returnFormattedDate(associatedProject.timestamp_unit_pay_date, 'M/D/YYYY'))
            } else {
              // DATA - FIC Date
              dataRow.push('')
            }
            // DATA - Zip
            dataRow.push(getProp(associatedProject, 'location_zip', ''))
            // DATA - Region
            dataRow.push(getProp(associatedProject, 'associated_region_name', ''))
            // DATA - Roof Pitch
            dataRow.push(getProp(associatedProject, 'system_max_roof_pitch', ''))
            // DATA - Has Pitch Adder
            if (getProp(associatedProject, 'system_max_roof_pitch', null) != null) {
              if (getProp(associatedProject, 'system_max_roof_pitch', null) >= 35) {
                dataRow.push('Yes')
              } else {
                dataRow.push('No')
              }
            } else {
              dataRow.push('')
            }
            // DATA - Distance From Warehouse
            // dataRow.push( getProp( associatedProject, "location_distance_from_warehouse", "" ) )
            // DATA - Has Distance Adder
            // if( getProp( loopProject, "location_distance_from_warehouse", null ) == null ){
            // 	errorsArray.push( "MISSING DISTANCE FROM WAREHOUSE" )
            // 	projectDataRow.push( "UNKNOWN" )
            // } else {
            // 	let distanceFromWarehouse = parseFloat( getProp( loopProject, "location_distance_from_warehouse", 0 ) )
            // 	if(
            // 		getProp( loopProject, "associated_region_key", null ) != null &&
            // 		reportConfig != null &&
            // 		reportConfig["regions"] != null &&
            // 		reportConfig["regions"][ getProp( loopProject, "associated_region_key", null ) ] != null &&
            // 		reportConfig["regions"][ getProp( loopProject, "associated_region_key", null ) ]["distance_threshold"] != null
            // 	){
            // 		if( distanceFromWarehouse >= reportConfig["regions"][ getProp( loopProject, "associated_region_key", null ) ]["distance_threshold"] ){
            // 			projectDataRow.push( "Yes" )
            // 		} else {
            // 			projectDataRow.push( "No" )
            // 		}
            // 	} else {
            // 		projectDataRow.push( "UNKNOWN" )
            // 		if( getProp( loopProject, "associated_region_key", null ) != null ){
            // 			errorsArray.push( "MISSING DISTANCE THRESHOLD FOR REGION - TALK TO JOHN" )
            // 		}
            // 	}
            // }
            // DATA - Batteries
            dataRow.push(
              getProp(associatedProject, 'system_storage_quantity', '') +
                ' ' +
                getProp(associatedProject, 'system_storage_manufacturer', '') +
                ' ' +
                getProp(associatedProject, 'system_storage_model', ''),
            )
            // DATA - Has Battery Adder
            if (getProp(associatedProject, 'system_storage_quantity', 0) > 0) {
              dataRow.push('Yes')
            } else {
              dataRow.push('No')
            }
            // DATA - Roof Type
            dataRow.push(getProp(associatedProject, 'home_roof_type', ''))
            // DATA - Number of Panels
            dataRow.push(getProp(associatedProject, 'system_panel_quantity', ''))
            // DATA - Utility
            dataRow.push(getProp(associatedProject, 'associated_utility_company_name', ''))
            // DATA - CSS Owner
            dataRow.push(getProp(associatedProject, 'associated_css_rep_name', ''))
            // DATA - System Size
            dataRow.push(getProp(associatedProject, 'system_size_dc', ''))
            // DATA - Task Workflow Name
            dataRow.push(getProp(associatedProject, 'associated_task_workflow_name', ''))
            // DATA - Billing Reference
            dataRow.push(getProp(loopTask, 'invoice_id_number', ''))
            // DATA - Unique Identifier
            dataRow.push(loopTask.key)
            // DATA - Add Row to Export Data
            exportData.push(dataRow)
          }
          for (let loopTaskKey in cancelledTaskData) {
            let loopTask = cancelledTaskData[loopTaskKey]
            let associatedProject: TsInterface_UnspecifiedObject = {}
            if (
              loopTask != null &&
              loopTask.associated_project_key != null &&
              loadedProjects != null &&
              loadedProjects[loopTask.associated_project_key] != null
            ) {
              associatedProject = loadedProjects[loopTask.associated_project_key]
            }
            let dataRow: any[] = []
            //===============================
            // TASK DETAILS
            //===============================
            // DATA - Project Code
            dataRow.push(getProp(loopTask, 'associated_project_id_number', ''))
            // DATA - Task Name
            dataRow.push(getProp(loopTask, 'name', ''))
            let status = getProp(loopTask, 'status', '')
            if (status === 'active') {
              // DATA - Task Status
              dataRow.push('assigned')
            } else {
              // DATA - Task Status
              dataRow.push(getProp(loopTask, 'status', ''))
            }
            // DATA - Task Assigned By
            dataRow.push(getProp(loopTask, 'timestamp_scheduled_by_name', ''))
            let scheduledDates = getProp(loopTask, 'task_completion_scheduled_dates', [])
            // DATA - Number of Scheduled Days
            dataRow.push(scheduledDates.length)
            if (scheduledDates.length === 0) {
              // DATA - First Scheduled Date
              dataRow.push('')
              // DATA - Last Scheduled Date
              dataRow.push('')
            } else if (scheduledDates.length === 1) {
              // DATA - First Scheduled Date
              dataRow.push(scheduledDates[0])
              // DATA - Last Scheduled Date
              dataRow.push('')
            } else {
              // DATA - First Scheduled Date
              dataRow.push(scheduledDates[0])
              // DATA - Last Scheduled Date
              dataRow.push(scheduledDates[scheduledDates.length - 1])
            }
            let foundDateOutsideWeek = false
            for (let loopDateKey in scheduledDates) {
              let foundLoopDate = false
              for (let loopCheckDateKey in weekDays) {
                if (scheduledDates[loopDateKey] === weekDays[loopCheckDateKey]) {
                  foundLoopDate = true
                }
              }
              if (foundLoopDate === false) {
                foundDateOutsideWeek = true
              }
            }
            if (foundDateOutsideWeek === true) {
              // DATA - Scheduled Day Falls Outside Week
              dataRow.push("Yes, clocked hours won't pull from dates outside of this week")
            } else {
              // DATA - Scheduled Day Falls Outside Week
              dataRow.push('')
            }
            //===============================
            // TEAM DETAILS
            //===============================
            // DATA - Team Name
            dataRow.push(getProp(loopTask, 'associated_team_name', ''))
            // DATA - Team Type
            dataRow.push(getProp(loopTask, 'associated_team_type', '').replace('_', ' '))
            let teamMembersObject = getProp(loopTask, 'task_completion_scheduled_team_names', {})
            let teamRolesObject = getProp(loopTask, 'task_completion_scheduled_team_roles', {})
            let cleanTeamMembers = processTeamData(teamMembersObject, teamRolesObject, loopTaskKey, punchData)
            let compositeTeamMemberString = ''
            let totalTimeSpent = 0
            for (let loopTeamMemberKey in cleanTeamMembers) {
              if (compositeTeamMemberString.length > 0) {
                compositeTeamMemberString += ' | '
              }
              if (cleanTeamMembers[loopTeamMemberKey].foundPunchData === false) {
                compositeTeamMemberString += cleanTeamMembers[loopTeamMemberKey].name + ' (NO PUNCH DATA)'
              } else {
                compositeTeamMemberString +=
                  cleanTeamMembers[loopTeamMemberKey].name + ' (' + cleanTeamMembers[loopTeamMemberKey].totalTimeSpent.toFixed(6) + ')'
              }
              totalTimeSpent += cleanTeamMembers[loopTeamMemberKey].totalTimeSpent
            }
            // DATA - Team Members
            dataRow.push(compositeTeamMemberString)
            let maxCrewSize = 0
            for (let loopDateKey in teamMembersObject) {
              let loopDate = teamMembersObject[loopDateKey]
              if (objectToArray(loopDate).length > maxCrewSize) {
                maxCrewSize = objectToArray(loopDate).length
              }
            }
            // DATA - Crew Size
            dataRow.push(maxCrewSize)
            // DATA - Total Clocked Team Hours
            dataRow.push(totalTimeSpent.toFixed(1))
            let scheduledHours = calculateTotalHoursWorked(loopTask.task_completion_scheduled_start_times, loopTask.task_completion_scheduled_end_times)
            // DATA - Total Scheduled On Site Hours
            dataRow.push(scheduledHours)
            // DATA - Total Allocated Units
            dataRow.push(getProp(loopTask, 'estimated_task_duration_hours', ''))
            // DATA - Distance to Warehouse
            dataRow.push(getProp(associatedProject, 'location_distance_from_warehouse', ''))
            if (loopTask != null && loopTask.redo_reason != null) {
              // DATA - Pulloff Reason (If Applicable)
              dataRow.push(underscoresToSpaces(loopTask.redo_reason) + ': ' + getProp(loopTask, 'redo_notes', ''))
            } else {
              // DATA - Pulloff Reason (If Applicable)
              dataRow.push('UNSPECIFIED: ' + getProp(loopTask, 'redo_notes', ''))
            }
            let filledTeamMembers: TsInterface_UnspecifiedObject = {
              install_foreman: null,
              install_lead: null,
              installer1: null,
              installer2: null,
              installer3: null,
              sow_foreman: null,
              sow_helper1: null,
              sow_helper2: null,
            }
            let additionallUsersString = ''
            for (let loopUserIndex in cleanTeamMembers) {
              let loopUser = cleanTeamMembers[loopUserIndex]
              let addToAdditionallUsers = false
              let additionallUserRole = 'Missing Team Role'
              if (loopUser != null && loopUser.roles != null && loopUser.roles['install_foreman'] != null) {
                if (filledTeamMembers['install_foreman'] == null) {
                  filledTeamMembers['install_foreman'] = loopUser.name
                } else {
                  addToAdditionallUsers = true
                  additionallUserRole = 'Additionall Install Foreman'
                }
              } else if (loopUser != null && loopUser.roles != null && loopUser.roles['install_lead'] != null) {
                if (filledTeamMembers['install_lead'] == null) {
                  filledTeamMembers['install_lead'] = loopUser.name
                } else {
                  addToAdditionallUsers = true
                  additionallUserRole = 'Additionall Install Lead'
                }
              } else if (loopUser != null && loopUser.roles != null && loopUser.roles['installer'] != null) {
                if (filledTeamMembers['installer1'] == null) {
                  filledTeamMembers['installer1'] = loopUser.name
                } else if (filledTeamMembers['installer2'] == null) {
                  filledTeamMembers['installer2'] = loopUser.name
                } else if (filledTeamMembers['installer3'] == null) {
                  filledTeamMembers['installer3'] = loopUser.name
                } else {
                  addToAdditionallUsers = true
                  additionallUserRole = 'Additionall Installer'
                }
              } else if (loopUser != null && loopUser.roles != null && loopUser.roles['sow_foreman'] != null) {
                if (filledTeamMembers['sow_foreman'] == null) {
                  filledTeamMembers['sow_foreman'] = loopUser.name
                } else {
                  addToAdditionallUsers = true
                  additionallUserRole = 'Additionall SOW Foreman'
                }
              } else if (loopUser != null && loopUser.roles != null && loopUser.roles['sow_helper'] != null) {
                if (filledTeamMembers['sow_helper1'] == null) {
                  filledTeamMembers['sow_helper1'] = loopUser.name
                } else if (filledTeamMembers['sow_helper2'] == null) {
                  filledTeamMembers['sow_helper2'] = loopUser.name
                } else {
                  addToAdditionallUsers = true
                  additionallUserRole = 'Additionall SOW Helper'
                }
              }
              if (addToAdditionallUsers === true) {
                if (additionallUsersString.length > 0) {
                  additionallUsersString += ' | '
                }
                additionallUsersString += loopUser.name + ' (' + additionallUserRole + ')'
              }
            }
            // Add to row download
            if (filledTeamMembers['install_foreman'] != null) {
              // DATA - Install Foreman
              dataRow.push(filledTeamMembers['install_foreman'])
            } else {
              // DATA - Install Foreman
              dataRow.push('')
            }
            if (filledTeamMembers['install_lead'] != null) {
              // DATA - Install Lead
              dataRow.push(filledTeamMembers['install_lead'])
            } else {
              // DATA - Install Lead
              dataRow.push('')
            }
            if (filledTeamMembers['installer1'] != null) {
              // DATA - Installer 1
              dataRow.push(filledTeamMembers['installer1'])
            } else {
              // DATA - Installer 1
              dataRow.push('')
            }
            if (filledTeamMembers['installer2'] != null) {
              // DATA - Installer 2
              dataRow.push(filledTeamMembers['installer2'])
            } else {
              // DATA - Installer 2
              dataRow.push('')
            }
            if (filledTeamMembers['installer3'] != null) {
              // DATA - Installer 3
              dataRow.push(filledTeamMembers['installer3'])
            } else {
              // DATA - Installer 3
              dataRow.push('')
            }
            if (filledTeamMembers['sow_foreman'] != null) {
              // DATA - SOW Foreman
              dataRow.push(filledTeamMembers['sow_foreman'])
            } else {
              // DATA - SOW Foreman
              dataRow.push('')
            }
            if (filledTeamMembers['sow_helper1'] != null) {
              // DATA - SOW Helper 1
              dataRow.push(filledTeamMembers['sow_helper1'])
            } else {
              // DATA - SOW Helper 1
              dataRow.push('')
            }
            if (filledTeamMembers['sow_helper2'] != null) {
              // DATA - SOW Helper 2
              dataRow.push(filledTeamMembers['sow_helper2'])
            } else {
              // DATA - SOW Helper 2
              dataRow.push('')
            }
            // DATA - Additionall Users
            dataRow.push(additionallUsersString)
            if (getProp(associatedProject, 'timestamp_construction_complete', null) != null) {
              // DATA - FIC Date
              dataRow.push(returnFormattedDate(associatedProject.timestamp_construction_complete, 'M/D/YYYY'))
            } else {
              // DATA - FIC Date
              dataRow.push('')
            }
            // DATA - Unit Pay Date
            if (getProp(associatedProject, 'timestamp_unit_pay_date', null) != null) {
              // DATA - FIC Date
              dataRow.push(returnFormattedDate(associatedProject.timestamp_unit_pay_date, 'M/D/YYYY'))
            } else {
              // DATA - FIC Date
              dataRow.push('')
            }
            // DATA - Zip
            dataRow.push(getProp(associatedProject, 'location_zip', ''))
            // DATA - Region
            dataRow.push(getProp(associatedProject, 'associated_region_name', ''))
            // DATA - Roof Pitch
            dataRow.push(getProp(associatedProject, 'system_max_roof_pitch', ''))
            // DATA - Has Pitch Adder
            if (getProp(associatedProject, 'system_max_roof_pitch', null) != null) {
              if (getProp(associatedProject, 'system_max_roof_pitch', null) >= 35) {
                dataRow.push('Yes')
              } else {
                dataRow.push('No')
              }
            } else {
              dataRow.push('')
            }
            // DATA - Distance From Warehouse
            // dataRow.push( getProp( associatedProject, "location_distance_from_warehouse", "" ) )
            // DATA - Has Distance Adder
            // if( getProp( loopProject, "location_distance_from_warehouse", null ) == null ){
            // 	errorsArray.push( "MISSING DISTANCE FROM WAREHOUSE" )
            // 	projectDataRow.push( "UNKNOWN" )
            // } else {
            // 	let distanceFromWarehouse = parseFloat( getProp( loopProject, "location_distance_from_warehouse", 0 ) )
            // 	if(
            // 		getProp( loopProject, "associated_region_key", null ) != null &&
            // 		reportConfig != null &&
            // 		reportConfig["regions"] != null &&
            // 		reportConfig["regions"][ getProp( loopProject, "associated_region_key", null ) ] != null &&
            // 		reportConfig["regions"][ getProp( loopProject, "associated_region_key", null ) ]["distance_threshold"] != null
            // 	){
            // 		if( distanceFromWarehouse >= reportConfig["regions"][ getProp( loopProject, "associated_region_key", null ) ]["distance_threshold"] ){
            // 			projectDataRow.push( "Yes" )
            // 		} else {
            // 			projectDataRow.push( "No" )
            // 		}
            // 	} else {
            // 		projectDataRow.push( "UNKNOWN" )
            // 		if( getProp( loopProject, "associated_region_key", null ) != null ){
            // 			errorsArray.push( "MISSING DISTANCE THRESHOLD FOR REGION - TALK TO JOHN" )
            // 		}
            // 	}
            // }
            // DATA - Batteries
            dataRow.push(
              getProp(associatedProject, 'system_storage_quantity', '') +
                ' ' +
                getProp(associatedProject, 'system_storage_manufacturer', '') +
                ' ' +
                getProp(associatedProject, 'system_storage_model', ''),
            )
            // DATA - Has Battery Adder
            if (getProp(associatedProject, 'system_storage_quantity', 0) > 0) {
              dataRow.push('Yes')
            } else {
              dataRow.push('No')
            }
            // DATA - Roof Type
            dataRow.push(getProp(associatedProject, 'home_roof_type', ''))
            // DATA - Number of Panels
            dataRow.push(getProp(associatedProject, 'system_panel_quantity', ''))
            // DATA - Utility
            dataRow.push(getProp(associatedProject, 'associated_utility_company_name', ''))
            // DATA - CSS Owner
            dataRow.push(getProp(associatedProject, 'associated_css_rep_name', ''))
            // DATA - System Size
            dataRow.push(getProp(associatedProject, 'system_size_dc', ''))
            // DATA - Task Workflow Name
            dataRow.push(getProp(associatedProject, 'associated_task_workflow_name', ''))
            // DATA - Billing Reference
            dataRow.push(getProp(loopTask, 'invoice_id_number', ''))
            // DATA - Unique Identifier
            dataRow.push(loopTask.key)
            // DATA - Add Row to Export Data
            exportData.push(dataRow)
          }
          for (let loopTaskKey in timeOffData) {
            let loopTask = timeOffData[loopTaskKey]
            let dataRow: any[] = []
            //===============================
            // TASK DETAILS
            //===============================
            // DATA - Project Code
            dataRow.push('')
            // DATA - Task Name
            dataRow.push(getProp(loopTask, 'time_off_reason_notes', ''))
            if (loopTask != null && loopTask.time_off_type != null) {
              // DATA - Task Status
              dataRow.push(loopTask.time_off_type.replace('_', ' '))
            } else {
              // DATA - Task Status
              dataRow.push('time off')
            }
            // DATA - Task Assigned By
            dataRow.push(getProp(loopTask, 'associated_user_name', ''))
            // DATA - Number of Scheduled Days
            dataRow.push(1)
            let alreadyAddedDate = false
            for (let loopDateKey in loopTask.task_completion_scheduled_start_times) {
              if (alreadyAddedDate === false) {
                // DATA - First Scheduled Date
                dataRow.push(loopDateKey)
                alreadyAddedDate = true
              }
            }
            // DATA - Last Scheduled Date
            dataRow.push('')
            // DATA - Scheduled Day Falls Outside Week
            dataRow.push('')
            //===============================
            // TEAM DETAILS
            //===============================
            if (getProp(loopTask, 'associated_team_name', null) != null) {
              // DATA - Team Name
              dataRow.push(getProp(loopTask, 'associated_team_name', ''))
            } else {
              if (
                loopTask != null &&
                loopTask.associated_team_key != null &&
                activeTeams[loopTask.associated_team_key] != null &&
                activeTeams[loopTask.associated_team_key].name != null
              ) {
                // DATA - Team Name
                dataRow.push(activeTeams[loopTask.associated_team_key].name)
              } else {
                // DATA - Team Name
                dataRow.push('')
              }
            }
            if (
              loopTask != null &&
              loopTask.associated_team_key != null &&
              activeTeams[loopTask.associated_team_key] != null &&
              activeTeams[loopTask.associated_team_key].team_type != null
            ) {
              // DATA - Team Type
              dataRow.push(activeTeams[loopTask.associated_team_key].team_type.replace('_', ' '))
            } else {
              // DATA - Team Type
              dataRow.push('')
            }
            if (
              loopTask != null &&
              loopTask.associated_team_key != null &&
              activeTeams[loopTask.associated_team_key] != null &&
              activeTeams[loopTask.associated_team_key].associated_member_names != null
            ) {
              // DATA - Team Members
              dataRow.push(objectToArray(activeTeams[loopTask.associated_team_key].associated_member_names).join(' | '))
            } else {
              // DATA - Team Members
              dataRow.push('')
            }
            if (
              loopTask != null &&
              loopTask.associated_team_key != null &&
              activeTeams[loopTask.associated_team_key] != null &&
              activeTeams[loopTask.associated_team_key].associated_member_names != null
            ) {
              // DATA - Crew Size
              dataRow.push(objectToArray(activeTeams[loopTask.associated_team_key].associated_member_names).length)
            } else {
              // DATA - Crew Size
              dataRow.push('')
            }
            // DATA - Total Clocked Team Hours
            dataRow.push('')
            let totalHoursOff = 0
            if (
              loopTask != null &&
              loopTask.task_completion_scheduled_start_times != null &&
              objectToArray(loopTask.task_completion_scheduled_start_times).length > 0 &&
              loopTask.task_completion_scheduled_end_times != null &&
              objectToArray(loopTask.task_completion_scheduled_end_times).length > 0
            ) {
              totalHoursOff = objectToArray(loopTask.task_completion_scheduled_end_times)[0] - objectToArray(loopTask.task_completion_scheduled_start_times)[0]
            }
            // DATA - Total Scheduled On Site Hours
            dataRow.push(totalHoursOff)
            // DATA - Total Allocated Units
            dataRow.push('')
            // DATA - Distance to Warehouse
            dataRow.push('')
            // DATA - Pulloff Reason (If Applicable)
            dataRow.push('')
            // DATA - Install Foreman
            dataRow.push('')
            // DATA - Install Lead
            dataRow.push('')
            // DATA - Installer 1
            dataRow.push('')
            // DATA - Installer 2
            dataRow.push('')
            // DATA - Installer 3
            dataRow.push('')
            // DATA - SOW Foreman
            dataRow.push('')
            // DATA - SOW Helper 1
            dataRow.push('')
            // DATA - SOW Helper 2
            dataRow.push('')
            // DATA - Additionall Users
            dataRow.push('')
            // DATA - FIC Date
            dataRow.push('')
            // DATA - Unit Pay Date
            dataRow.push('')
            // DATA - Zip
            dataRow.push('')
            // DATA - Region
            dataRow.push('')
            // DATA - Roof Pitch
            dataRow.push('')
            // DATA - Has Pitch Adder
            dataRow.push('')
            // DATA - Distance From Warehouse
            // dataRow.push( "" )
            // DATA - Has Distance Adder
            // dataRow.push( "" )
            // DATA - Batteries
            dataRow.push('')
            // DATA - Has Battery Adder
            dataRow.push('')
            // DATA - Roof Type
            dataRow.push('')
            // DATA - Number of Panels
            dataRow.push('')
            // DATA - Utility
            dataRow.push('')
            // DATA - CSS Owner
            dataRow.push('')
            // DATA - System Size
            dataRow.push('')
            // DATA - Task Workflow Name
            dataRow.push('')
            // DATA - Billing Reference
            dataRow.push('')
            // DATA - Unique Identifier
            dataRow.push(loopTask.key)
            // DATA - Add Row to Export Data
            exportData.push(dataRow)
          }
          // Download
          let fileName = 'Calendar Export (' + weekDays[0] + ' to ' + weekDays[6] + ').csv'
          downloadCSV(fileName, exportData)
            .then((res_DCSV) => {
              resolve(res_DCSV)
            })
            .catch((rej_DCSV) => {
              reject(rej_DCSV)
            })
        })
      })
      .catch((rej_GSED) => {
        reject(rej_GSED)
      })
  })
}

const calculateDistanceBetweenTwoPoints = (origin: string, destination: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    let service = new google.maps.DistanceMatrixService()
    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
        avoidHighways: false,
        avoidTolls: false,
      },
      (response, status) => {
        if (
          status === 'OK' &&
          response != null &&
          response.rows != null &&
          response.rows[0] != null &&
          response.rows[0].elements != null &&
          response.rows[0].elements[0] != null &&
          response.rows[0].elements[0].distance != null &&
          response.rows[0].elements[0].distance.value != null
        ) {
          resolve({ success: true, miles: response.rows[0].elements[0].distance.value / 1609.344 })
        } else {
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CALCULATE_MILES,
              details: s_AN_UNKNOWN_ERROR_OCCURRED,
              code: 'ER-D-TDI-CDBTP-01',
            },
          })
        }
      },
    )
  })
}

///////////////////////////////
// Page Specific Permissions
///////////////////////////////

const defaultPagePermissions: TsInterface_UnspecifiedObject = {
  edit_timestamp_unit_pay_date: false,
}

const pageRolePermissions: TsInterface_UnspecifiedObject = {
  accounts_payable: {
    edit_timestamp_unit_pay_date: true,
  },
  // accounts_receivable: {
  //   edit_timestamp_unit_pay_date: true,
  // },
  // admin
  // api_user
  // construction_lead
  // css_manager
  // css_rep
  // design
  // electrical_foreman
  // field_service
  // general_manager
  // home_upgrades_electrician
  // home_upgrades_electrician_trainee
  // inspection
  // install_coordinator
  // install_lead
  // installer
  // interconnection
  // permitting
  // repair
  // scheduling
  // site_auditor
  // warehouse
}

const determinePageSpecificPermissions = (clientUser: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject => {
  let pageSpecificPermissions: TsInterface_UnspecifiedObject = { ...defaultPagePermissions }
  if (clientUser != null && clientUser.user_role != null) {
    for (let loopPermissionKey in defaultPagePermissions) {
      if (
        pageRolePermissions != null &&
        pageRolePermissions[clientUser.user_role] != null &&
        pageRolePermissions[clientUser.user_role][loopPermissionKey] === true
      ) {
        pageSpecificPermissions[loopPermissionKey] = true
      }
    }
  }
  if (clientUser != null && clientUser.task_roles != null) {
    for (let loopTaskRole in clientUser.task_roles) {
      let loopRoleValue = clientUser.task_roles[loopTaskRole]
      if (loopRoleValue === true) {
        for (let loopPermissionKey in defaultPagePermissions) {
          if (pageRolePermissions != null && pageRolePermissions[loopTaskRole] != null && pageRolePermissions[loopTaskRole][loopPermissionKey] === true) {
            pageSpecificPermissions[loopPermissionKey] = true
          }
        }
      }
    }
  }
  return pageSpecificPermissions
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const pr_dateKey: string = params.date as string
  const pr_utcDate = new Date(pr_dateKey as string)
  const pr_localDate = new Date(pr_utcDate.getTime() + Math.abs(pr_utcDate.getTimezoneOffset() * 60000))

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_activeTeams, us_setActiveTeams] = useState<TsInterface_UnspecifiedObject>({})
  const [us_additionallMapLocations, us_setAdditionallMapLocations] = useState<TsInterface_UnspecifiedObject>({})
  const [us_allUnassignedTasks, us_setAllUnassignedTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_allowTeamTimeslotDeletion, us_setAllowTeamTimeslotDeletion] = useState<boolean>(true)
  const [us_calendarEvents, us_setCalendarEvents] = useState<TsInterface_UnspecifiedObject>({})
  const [us_calendarEventsWithoutTeams, us_setCalendarEventsWithoutTeams] = useState<TsInterface_UnspecifiedObject>({})
  const [us_calendarRenderData, us_setCalendarRenderData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_cancelledTaskEvents, us_setCancelledTaskEvents] = useState<TsInterface_UnspecifiedObject>({})
  const [us_combinedUserOptions, us_setCombinedUserOptions] = useState<TsInterface_UnspecifiedObject>({})
  const [us_exportingSchedule, us_setExportingSchedule] = useState<boolean>(false)
  const [us_filteredTaskKeysToDisplay, us_setFilteredTaskKeysToDisplay] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filteredUserKey, us_setFilteredUserKey] = useState<string>('')
  const [us_highlighedMapEvent, us_setHighlightedMapEvent] = useState<string | null>(null)
  const [us_highlighedMapTeam, us_setHighlightedMapTeam] = useState<string | null>(null)
  const [us_lastTaskSwappedTimestamp, us_setLastTaskSwappedTimestamp] = useState<Date>(pr_localDate)
  const [us_loadedActiveTeams, us_setLoadedActiveTeams] = useState<boolean>(false)
  const [us_pageSpecificUserRolePermission, us_setPageSpecificUserRolePermission] = useState<TsInterface_UnspecifiedObject>(defaultPagePermissions)
  const [us_regionLists, us_setRegionLists] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTeamTimeOnSettings, us_setScheduledTeamTimeOnSettings] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTimeOff, us_setScheduledTimeOff] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedConfigSeason, us_setSelectedConfigSeason] = useState<'winter' | 'summer'>('winter')
  const [us_selectedConfigTeam, us_setSelectedConfigTeam] = useState<string | null>(null)
  const [us_selectedDate, us_setSelectedDate] = useState<Date>(pr_localDate)
  const [us_selectedRegion, us_setSelectedRegion] = useState<string>('ALL_REGIONS')
  const [us_selectedTab, us_setSelectedTab] = useState<string>('week_schedule')
  const [us_showActiveTeams, us_setShowActiveTeams] = useState<boolean>(true)
  const [us_taskSearchFilter, us_setTaskSearchFilter] = useState<string>('')
  const [us_teamTypeSettings, us_setTeamTypeSettings] = useState<TsInterface_UnspecifiedObject>({})
  const [us_jobsToClose, us_setJobsToClose] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filteredJobsToClose, us_setFilteredJobsToClose] = useState<TsInterface_UnspecifiedObject>({})
  const [us_unassignedTasksFilter, us_setUnassignedTasksFilter] = useState<string>('all')
  const [us_viewNotesOnCalendar, us_setViewNotesOnCalendar] = useState<boolean>(true)
  const [us_viewUsersOnCalendar, us_setViewUsersOnCalendar] = useState<boolean>(true)
  const [us_visibleTeamsTypesFilter, us_setVisibleTeamsTypesFilter] = useState<string[]>([])
  const umq_weekTableBreakpoint1 = useMediaQuery('(max-width: 1px)')
  const umq_weekTableBreakpoint2 = useMediaQuery('(max-width: 2px)')
  const umq_weekTableBreakpoint3 = useMediaQuery('(max-width: 3px)')
  const umq_weekTableBreakpoint4 = useMediaQuery('(max-width: 4px)')
  const umq_weekTableBreakpoint5 = useMediaQuery('(max-width: 347px)')
  const umq_weekTableBreakpoint6 = useMediaQuery('(max-width: 401px)')
  const umq_weekTableBreakpoint7 = useMediaQuery('(max-width: 724px)')
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const ur_mapRef = useRef(null)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    // Set Region from Local Storage
    let pageLocalStorageData = getPageLocalStorage(pageKey)
    if (getProp(pageLocalStorageData, 'us_selectedRegion', null) != null) {
      us_setSelectedRegion(getProp(pageLocalStorageData, 'us_selectedRegion', null))
    }
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let cleanEvents: TsInterface_UnspecifiedObject = {}
      for (let loopEventKey in newData) {
        let loopEvent = cloneObjectWithoutReference(newData[loopEventKey])
        if (loopEvent['task_completion_scheduled_start_times'] != null) {
          for (let loopDateKey in loopEvent['task_completion_scheduled_start_times']) {
            if (loopEvent['task_completion_scheduled_start_times'][loopDateKey] != null) {
              loopEvent['task_completion_scheduled_start_times'][loopDateKey] = timeToDecimal(loopEvent['task_completion_scheduled_start_times'][loopDateKey])
            }
          }
        }
        if (loopEvent['task_completion_scheduled_end_times'] != null) {
          for (let loopDateKey in loopEvent['task_completion_scheduled_end_times']) {
            if (loopEvent['task_completion_scheduled_end_times'][loopDateKey] != null) {
              loopEvent['task_completion_scheduled_end_times'][loopDateKey] = timeToDecimal(loopEvent['task_completion_scheduled_end_times'][loopDateKey])
            }
          }
        }
        cleanEvents[loopEventKey] = loopEvent
      }
      us_setCancelledTaskEvents(cleanEvents)
      ur_forceRerender()
    }
    // Determine start and end date
    let dayOfWeek = us_selectedDate.getDay()
    let weekStart = new Date(us_selectedDate.getFullYear(), us_selectedDate.getMonth(), us_selectedDate.getDate() - dayOfWeek - 1)
    let weekEnd = new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() + 8)
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(
          DatabaseRef_CancelledScheduledTasksInDateRange_Query(res_GCK.clientKey, weekStart, weekEnd),
          updateLiveData,
        )
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_selectedDate])

  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      us_setPageSpecificUserRolePermission(determinePageSpecificPermissions(uc_RootData_ClientUser))
    }
  }, [uc_RootData_ClientUser])

  useEffect(() => {
    us_setAllowTeamTimeslotDeletion(true)
  }, [])

  useEffect(() => {
    // Cleanup calendar
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let promiseArray: TsType_UnknownPromise[] = []
        let tasksToUpdate: TsInterface_UnspecifiedObject = {}
        let tasksToNotUpdate: TsInterface_UnspecifiedObject = {}
        promiseArray.push(
          DatabaseGetCollection(DatabaseRef_ContradictoryActiveCompletedTasks_Query(res_GCK.clientKey))
            .then((res_DGC) => {
              for (let loopTaskKey in res_DGC.data) {
                let loopTask = res_DGC.data[loopTaskKey]
                if (loopTask != null && loopTask.timestamp_completed != null && loopTask.timestamp_completed < new Date()) {
                  tasksToUpdate[loopTaskKey] = loopTask
                } else {
                  tasksToNotUpdate[loopTaskKey] = loopTask
                }
              }
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            }),
        )
        promiseArray.push(
          DatabaseGetCollection(DatabaseRef_ContradictoryAssignedCompletedTasks_Query(res_GCK.clientKey))
            .then((res_DGC) => {
              for (let loopTaskKey in res_DGC.data) {
                let loopTask = res_DGC.data[loopTaskKey]
                if (loopTask != null && loopTask.timestamp_completed != null && loopTask.timestamp_completed < new Date()) {
                  tasksToUpdate[loopTaskKey] = loopTask
                } else {
                  tasksToNotUpdate[loopTaskKey] = loopTask
                }
              }
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            }),
        )
        Promise.all(promiseArray).finally(() => {
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
          for (let loopTaskKey in tasksToUpdate) {
            updateArray.push({
              type: 'setMerge',
              ref: DatabaseRef_Task_Document(res_GCK.clientKey, loopTaskKey),
              data: { status: 'completed', timestamp_last_updated: new Date() },
            })
          }
          if (objectToArray(tasksToNotUpdate).length > 0) {
            //
            // console.warn( tasksToNotUpdate )
          }
          if (updateArray.length > 0) {
            DatabaseBatchUpdate(updateArray)
              .then((res_DBU) => {})
              .catch((rej_DBU) => {
                console.error(rej_DBU)
              })
          }
        })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let utcDate = new Date(pr_dateKey as string)
    let localDate = new Date(utcDate.getTime() + Math.abs(utcDate.getTimezoneOffset() * 60000))
    us_setSelectedDate(localDate)
  }, [pr_dateKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveTeams(newData)
      us_setLoadedActiveTeams(true)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        if (us_showActiveTeams === false) {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SchedulingDeletedTeams_Query(res_GCK.clientKey), updateLiveData)
        } else {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SchedulingActiveTeams_Query(res_GCK.clientKey), updateLiveData)
        }
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_showActiveTeams])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAdditionallMapLocations(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_MapLocations_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTeamTypeSettings(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SchedulingTeamTypeSettings_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledTeamTimeOnSettings(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ScheduledTeamTimeOn_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledTimeOff(newData)
      ur_forceRerender()
    }
    // Determine start and end date
    let dayOfWeek = us_selectedDate.getDay()
    let weekStart = new Date(us_selectedDate.getFullYear(), us_selectedDate.getMonth(), us_selectedDate.getDate() - dayOfWeek - 1)
    let weekEnd = new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() + 8)
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ScheduledTimeOff_Query(res_GCK.clientKey, weekStart, weekEnd), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_selectedDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let cleanEvents: TsInterface_UnspecifiedObject = {}
      for (let loopEventKey in newData) {
        let loopEvent = cloneObjectWithoutReference(newData[loopEventKey])
        if (loopEvent['task_completion_scheduled_start_times'] != null) {
          for (let loopDateKey in loopEvent['task_completion_scheduled_start_times']) {
            if (loopEvent['task_completion_scheduled_start_times'][loopDateKey] != null) {
              loopEvent['task_completion_scheduled_start_times'][loopDateKey] = timeToDecimal(loopEvent['task_completion_scheduled_start_times'][loopDateKey])
            }
          }
        }
        if (loopEvent['task_completion_scheduled_end_times'] != null) {
          for (let loopDateKey in loopEvent['task_completion_scheduled_end_times']) {
            if (loopEvent['task_completion_scheduled_end_times'][loopDateKey] != null) {
              loopEvent['task_completion_scheduled_end_times'][loopDateKey] = timeToDecimal(loopEvent['task_completion_scheduled_end_times'][loopDateKey])
            }
          }
        }
        cleanEvents[loopEventKey] = loopEvent
      }
      us_setCalendarEvents(cleanEvents)
      // @ts-expect-error - TODO: reason for error
      if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.recalculateMapBounds != null) {
        // @ts-expect-error - TODO: reason for error
        ur_mapRef.current.recalculateMapBounds(true)
      }
      ur_forceRerender()
    }
    // Determine start and end date
    let dayOfWeek = us_selectedDate.getDay()
    let weekStart = new Date(us_selectedDate.getFullYear(), us_selectedDate.getMonth(), us_selectedDate.getDate() - dayOfWeek - 1)
    let weekEnd = new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() + 8)
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ScheduledTasksInDateRange_Query(res_GCK.clientKey, weekStart, weekEnd), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_selectedDate])

  useEffect(() => {
    let eventsWithoutTeams: TsInterface_UnspecifiedObject = {}
    if (us_loadedActiveTeams === true) {
      for (let loopEventKey in us_calendarEvents) {
        let loopEvent = us_calendarEvents[loopEventKey]
        if (loopEvent != null && loopEvent.associated_team_key != null) {
          if (us_activeTeams != null && us_activeTeams[loopEvent.associated_team_key] != null) {
            // Should render on assigned team row
          } else {
            eventsWithoutTeams[loopEventKey] = loopEvent
          }
        } else {
          eventsWithoutTeams[loopEventKey] = loopEvent
        }
      }
    }
    us_setCalendarEventsWithoutTeams(eventsWithoutTeams)
  }, [us_activeTeams, us_loadedActiveTeams, us_calendarEvents])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAllUnassignedTasks(newData)
      // let tasksToAssign: TsInterface_UnspecifiedObject = {}
      // for( let loopTaskKey in newData ){
      // 	let loopTask = newData[ loopTaskKey ]
      // 	if(
      // 		loopTask != null &&
      // 		loopTask.associated_team_type != null
      // 	){
      // 		let hasIncompletePrerequisites = false
      // 		for( let loopPrerequisiteTaskKey in loopTask.prerequisite_tasks ){
      // 			if(
      // 				loopTask.prerequisite_tasks_completion == null ||
      // 				loopTask.prerequisite_tasks_completion[loopPrerequisiteTaskKey] == null
      // 			){
      // 				hasIncompletePrerequisites = true
      // 			}
      // 		}
      // 		if( hasIncompletePrerequisites !== true ){
      // 			tasksToAssign[ loopTaskKey ] = loopTask
      // 		}
      // 	}
      // }
      // setAllUnassignedTasks( tasksToAssign )
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_OpenDispatcherTasks_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [us_setAllUnassignedTasks, uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let combinedUsers: TsInterface_UnspecifiedObject = {}
        let promiseArray: TsType_UnknownPromise[] = []
        // let listOfSchedulingTeamTypes: TsInterface_UnspecifiedObject = {}
        for (let loopTeamTypeKey in schedulingTeamTypes) {
          let loopTeamType = schedulingTeamTypes[loopTeamTypeKey]
          if (loopTeamType['user_roles'] != null) {
            for (let loopUserRoleIndex in loopTeamType['user_roles']) {
              let loopUserRoleKey = loopTeamType['user_roles'][loopUserRoleIndex]
              promiseArray.push(
                DatabaseGetCollection(DatabaseRef_UsersWithSpecificTaskRole_Query(res_GCK.clientKey, loopUserRoleKey))
                  .then((res_DGC) => {
                    for (let loopUserKey in res_DGC.data) {
                      let loopUser = res_DGC.data[loopUserKey]
                      combinedUsers[loopUserKey] = loopUser
                    }
                  })
                  .catch((rej_DGC) => {
                    console.error(rej_DGC)
                  }),
              )
            }
          }
        }
        Promise.all(promiseArray).finally(() => {
          us_setCombinedUserOptions(combinedUsers)
        })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    // Get formatted data to render calendar
    let dayOfWeek = us_selectedDate.getDay()
    let sunDate = new Date(us_selectedDate.getFullYear(), us_selectedDate.getMonth(), us_selectedDate.getDate() - dayOfWeek)
    let monDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 1)
    // let satDate = new Date( sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 6 )
    let endSunDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 7)
    let calendarRenderDataResult = returnTeamDefinedTimeslotsForSpecifiedDayAndTeamType(
      // sunDate,
      // satDate,
      monDate,
      endSunDate,
      returnFilteredTeams(us_activeTeams, us_selectedRegion, us_visibleTeamsTypesFilter, us_filteredUserKey, us_calendarEvents),
      us_teamTypeSettings,
      us_scheduledTeamTimeOnSettings,
      us_scheduledTimeOff,
      us_calendarEvents,
      us_cancelledTaskEvents,
    )
    us_setCalendarRenderData(calendarRenderDataResult)
    if (us_lastTaskSwappedTimestamp.getTime() > 0) {
      // Nothing
    }
    return () => {
      // Nothing
    }
  }, [
    us_cancelledTaskEvents,
    us_selectedDate,
    us_calendarEvents,
    us_scheduledTimeOff,
    us_scheduledTeamTimeOnSettings,
    us_teamTypeSettings,
    us_activeTeams,
    us_visibleTeamsTypesFilter,
    us_filteredUserKey,
    us_lastTaskSwappedTimestamp,
    us_selectedRegion,
  ])

  useEffect(() => {
    let filteredUnassignedTasks: TsInterface_UnspecifiedObject[] = []
    let taskKeysToDisplay: TsInterface_UnspecifiedObject = {}
    // Search Input
    let propertiesToSearch = ['id_number']
    if (us_taskSearchFilter != null && us_taskSearchFilter !== '') {
      filteredUnassignedTasks = filterObjectsByProperties(objectToArray(us_allUnassignedTasks), us_taskSearchFilter, propertiesToSearch)
    } else {
      filteredUnassignedTasks = objectToArray(us_allUnassignedTasks)
    }
    // Filter By User or All
    let secondStepFilteredUnassignedTasks: TsInterface_UnspecifiedObject[] = []

    if (us_unassignedTasksFilter === 'user') {
      for (let loopIndex in filteredUnassignedTasks) {
        let loopTask = filteredUnassignedTasks[loopIndex]
        if (loopTask != null && loopTask.associated_owner_key === uc_RootData_GlobalUser.key) {
          secondStepFilteredUnassignedTasks.push(loopTask)
        }
      }
    } else {
      secondStepFilteredUnassignedTasks = filteredUnassignedTasks
    }
    // Team Filter
    if (us_visibleTeamsTypesFilter.length > 0) {
      let finalFilteredTasks: TsInterface_UnspecifiedObject[] = []
      for (let loopIndex in secondStepFilteredUnassignedTasks) {
        let loopTask = secondStepFilteredUnassignedTasks[loopIndex]
        if (loopTask.associated_team_type != null && us_visibleTeamsTypesFilter.includes(loopTask.associated_team_type)) {
          finalFilteredTasks.push(loopTask)
        }
      }
      // setFilteredTasksToAssign( finalFilteredTasks )

      for (let loopTaskIndex in finalFilteredTasks) {
        let loopTask = finalFilteredTasks[loopTaskIndex]
        taskKeysToDisplay[loopTask.key] = true
      }
      us_setFilteredTaskKeysToDisplay(taskKeysToDisplay)
      ur_forceRerender()
    } else {
      // setFilteredTasksToAssign( secondStepFilteredUnassignedTasks )
      for (let loopTaskIndex in secondStepFilteredUnassignedTasks) {
        let loopTask = secondStepFilteredUnassignedTasks[loopTaskIndex]
        taskKeysToDisplay[loopTask.key] = true
      }
      us_setFilteredTaskKeysToDisplay(taskKeysToDisplay)
      ur_forceRerender()
    }
  }, [uc_RootData_GlobalUser.key, us_taskSearchFilter, us_allUnassignedTasks, us_unassignedTasksFilter, us_visibleTeamsTypesFilter, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRegionLists(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveRegions_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  //get jobs to complete data
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setJobsToClose(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_JobsToClose_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  const getMondayOfCurrentWeek = (): string => {
    const today = new Date()
    const dayOfWeek = today.getUTCDay() // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    const diff = today.getUTCDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1) // Adjust when it's Sunday
    const monday = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), diff))
    return `${monday.getFullYear()}-${String(monday.getMonth() + 1).padStart(2, '0')}-${String(monday.getDate()).padStart(2, '0')}` // Return YYYY-MM-DD
  }

  useEffect(() => {
    if (!us_jobsToClose || Object.keys(us_jobsToClose).length === 0) return

    const mondayOfCurrentWeek = getMondayOfCurrentWeek() // Get Monday's date in YYYY-MM-DD format

    // Filter jobs based on `timestamp_last_scheduled_date_key` and reformat `timestamp_assigned`
    const filteredJobs = objectToArray(us_jobsToClose)
      .filter((job: { timestamp_last_scheduled_date_key?: string }) => {
        const lastScheduledDateKey = job.timestamp_last_scheduled_date_key

        // Include jobs without `timestamp_last_scheduled_date_key`
        if (!lastScheduledDateKey) return true

        // Compare string dates directly (YYYY-MM-DD format)
        return lastScheduledDateKey < mondayOfCurrentWeek // Exclude tasks scheduled for or after Monday
      })
      .map((job: any) => {
        // Reformat `timestamp_assigned`
        if (job.timestamp_assigned) {
          job.timestamp_assigned = returnFormattedDate(job.timestamp_assigned, 'YYYY/MM/DD')
        }
        return job
      })

    // Set the filtered and transformed jobs to state
    us_setFilteredJobsToClose(filteredJobs)
  }, [us_jobsToClose])

  // Functions
  const changeCalendarDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      let formattedDateKey = returnFormattedDateKey(new Date(newDate))
      us_setSelectedDate(new Date(newDate))
      ur_forceRerender()
      directAppNavigation(un_routerNavigation, ApplicationPages.TaskCalendarSchedulePage.url(formattedDateKey))
      resolve({ success: true })
    })
  }

  const openNewTeamDialog = (): void => {
    const FormDialogObject: TsInterface_FormDialogObject = {
      form: {
        formAdditionalData: {},
        formData: {},
        formInputs: formInputs_ScheduleTeamNew,
        formOnChange: formOnChange_ScheduleTeam,
        formSettings: formSettings_ScheduleTeamCreate,
        formSubmission: formSubmission_ScheduleTeamCreate,
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: s_CREATE_NEW_TEAM,
        formDialogIcon: <Icon icon="plus-circle" />,
      },
    }
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: FormDialogObject,
    })
  }

  const openTeamDialog = (team: TsInterface_UnspecifiedObject, ur_forceRerender: any): void => {
    uc_setUserInterface_CustomDialogDisplay({
      display: true,
      dialog: {
        dialog_jsx: (
          <Box>
            <TeamMembershipCustomDialog teamKey={team.key} />
          </Box>
        ),
        settings: {
          max_width: 'lg',
        },
      },
    })
  }

  const openEventDayTeamMembershipDialog = (teamKey: string, taskKey: string, date: Date): void => {
    uc_setUserInterface_CustomDialogDisplay({
      display: true,
      dialog: {
        dialog_jsx: (
          <Box>
            <EventDayMembershipCustomDialog
              teamKey={teamKey}
              taskKey={taskKey}
              date={date}
            />
          </Box>
        ),
        settings: {
          max_width: 'md',
        },
      },
    })
  }

  const openAssignedEventDialog = (event: TsInterface_UnspecifiedObject): void => {
    let teamKey = null
    if (event != null && event.associated_team_key != null) {
      teamKey = event.associated_team_key
    }
    uc_setUserInterface_CustomDialogDisplay({
      display: true,
      dialog: {
        dialog_jsx: (
          <Box>
            <AssignedEventCustomDialog
              teamKey={teamKey}
              event={event}
              allScheduledTasks={us_calendarEvents}
              allTeams={us_activeTeams}
            />
          </Box>
        ),
        settings: {
          max_width: 'lg',
        },
      },
    })
  }

  const openUnassignedEventDialog = (event: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_CustomDialogDisplay({
      display: true,
      dialog: {
        dialog_jsx: (
          <Box>
            <UnassignedTaskCustomDialog
              task={event}
              us_calendarEvents={us_calendarEvents}
              us_activeTeams={us_activeTeams}
              us_teamTypeSettings={us_teamTypeSettings}
            />
          </Box>
        ),
        settings: {
          max_width: 'lg',
        },
      },
    })
  }

  const filterObjectsByProperties = (arr: TsInterface_UnspecifiedObject[], searchStr: string, properties: string[]) => {
    return arr.filter((obj) => properties.some((prop) => obj[prop].toLowerCase().includes(searchStr.toLowerCase())))
  }

  const formatTime = (num: number) => {
    let hour = Math.floor(num)
    let minutes: string | number = Math.floor((num - hour) * 60)
    let period = hour >= 12 ? 'pm' : 'am'
    if (hour === 0) {
      hour = 12
    } else if (hour > 12) {
      hour -= 12
    }
    if (minutes < 10) {
      minutes = '0' + minutes
    }
    return hour + ':' + minutes + ' ' + period
  }

  const returnArrayUniqueValues = (arr1: any, arr2: any) => {
    // combine the two arrays
    const combinedArr: any[] = [...arr1, ...arr2]
    // create an object to store the counts of each value
    const counts: TsInterface_UnspecifiedObject = {}
    // loop through the combined array and count the occurrences of each value
    for (const val of combinedArr) {
      if (counts[val]) {
        counts[val]++
      } else {
        counts[val] = 1
      }
    }
    // create an array to store the unique values
    const uniqueArr = []
    // loop through the counts object and add the values that only appear once to the unique array
    for (const val in counts) {
      if (counts[val] === 1) {
        uniqueArr.push(val)
      }
    }
    return uniqueArr
  }

  const setMergeTeamTypeSettings = (teamTypeKey: string, updateObject: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_SchedulingTeamTypeSettings_Document(res_GCK.clientKey, teamTypeKey), updateObject)
            .then((res_DSMD) => {
              resolve(res_DSMD)
            })
            .catch((rej_DSMD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD })
              reject(rej_DSMD)
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          reject(rej_GCK)
        })
    })
  }

  // TODO - maybe only show the below to admins?
  const updateTeamTypeSettings = (teamTypeKey: string, updateObject: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseUpdateDocument(DatabaseRef_SchedulingTeamTypeSettings_Document(res_GCK.clientKey, teamTypeKey), updateObject)
            .then((res_DUD) => {
              resolve(res_DUD)
            })
            .catch((rej_DUD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD })
              reject(rej_DUD)
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          reject(rej_GCK)
        })
    })
  }

  const deleteTeamTimeSlot = (teamTypeKey: string, seasonType: 'winter' | 'summer', window: TsInterface_UnspecifiedObject) => {
    if (
      teamTypeKey != null &&
      seasonType != null &&
      window != null &&
      window.key != null &&
      us_teamTypeSettings != null &&
      us_teamTypeSettings[teamTypeKey] != null &&
      us_teamTypeSettings[teamTypeKey]['task_assignment_slots'] != null &&
      us_teamTypeSettings[teamTypeKey]['task_assignment_slots'][seasonType] != null &&
      us_teamTypeSettings[teamTypeKey]['task_assignment_slots'][seasonType][window.key] != null
    ) {
      let updateObject: TsInterface_UnspecifiedObject = {
        task_assignment_slots: us_teamTypeSettings[teamTypeKey]['task_assignment_slots'],
      }
      delete us_teamTypeSettings[teamTypeKey]['task_assignment_slots'][seasonType][window.key]
      updateTeamTypeSettings(teamTypeKey, updateObject)
        .then((res_UTTS) => {
          // Nothing
        })
        .catch((rej_UTTS) => {
          // Nothing
        })
    }
  }

  const returnFreeformReassignTaskKey = (teamTypeKey: string, dateKey: string): string => {
    let reassignKey = teamTypeKey + '+' + dateKey
    return reassignKey
  }

  const returnDefinedReassignTaskKey = (teamTypeKey: string, dateKey: string, timeKey: string): string => {
    let reassignKey = teamTypeKey + '+' + dateKey + '+' + timeKey
    return reassignKey
  }

  const exportScheduleV2 = (
    startSundayAt12am: Date,
    endSaturdayAt12am: Date,
    endSundayAt12am: Date,
    dataQueryType: 'all' | 'recently_updated', // Added 'assigned_only'
  ) => {
    us_setExportingSchedule(true)
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        exportScheduleWithTimesheetData(res_GCK.clientKey, pr_dateKey, us_activeTeams, startSundayAt12am, endSaturdayAt12am, endSundayAt12am, dataQueryType)
          .then((res_ESWTD) => {
            us_setExportingSchedule(false)
          })
          .catch((rej_ESWTD) => {
            us_setExportingSchedule(false)
            uc_setUserInterface_ErrorDialogDisplay({
              display: true,
              error: rej_ESWTD.error,
            })
          })
      })
      .catch((rej_GCK) => {
        us_setExportingSchedule(false)
        uc_setUserInterface_ErrorDialogDisplay({
          display: true,
          error: rej_GCK.error,
        })
      })
  }

  const fixMissingMilesProper = (clientKey: string, projectKey: string, projectAddress: string, warehouseAddress: string): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      calculateDistanceBetweenTwoPoints(projectAddress, warehouseAddress)
        .then((res_CDBTP) => {
          if (res_CDBTP != null && getProp(res_CDBTP, 'miles', null) != null && !isNaN(getProp(res_CDBTP, 'miles', null))) {
            let updateObject: TsInterface_UnspecifiedObject = {
              location_distance_from_warehouse: getProp(res_CDBTP, 'miles', null),
            }
            DatabaseSetMergeDocument(DatabaseRef_Project_Document(clientKey, projectKey), updateObject)
              .then((res_DSMD) => {
                resolve(res_DSMD)
              })
              .catch((rej_DSMD) => {
                reject(rej_DSMD)
              })
          } else {
            reject({ success: false })
          }
        })
        .catch((rej_CDBTP) => {
          reject(rej_CDBTP)
        })
    })
  }

  const fixMissingMiles = (startSundayAt12am: Date, endSaturdayAt12am: Date, endSundayAt12am: Date, dataQueryType: 'all' | 'recently_updated'): void => {
    us_setExportingSchedule(true)
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        getScheduleExportData(res_GCK.clientKey, pr_dateKey, us_activeTeams, startSundayAt12am, endSaturdayAt12am, endSundayAt12am, dataQueryType)
          .then((res_GSED) => {
            let loadedProjects: TsInterface_UnspecifiedObject = {}
            let promiseArray1: TsType_UnknownPromise[] = []
            let promiseArray2: TsType_UnknownPromise[] = []
            let taskData = getProp(res_GSED.data, 'taskData', {})
            let cancelledTaskData = getProp(res_GSED.data, 'cancelledTaskData', {})
            let projectsToLoad: TsInterface_UnspecifiedObject = {}
            let regions: TsInterface_UnspecifiedObject = {}
            for (let loopTaskKey in taskData) {
              let loopTask = taskData[loopTaskKey]
              if (loopTask != null && loopTask.associated_project_key != null) {
                projectsToLoad[loopTask.associated_project_key] = true
              }
            }
            for (let loopTaskKey in cancelledTaskData) {
              let loopTask = cancelledTaskData[loopTaskKey]
              if (loopTask != null && loopTask.associated_project_key != null) {
                projectsToLoad[loopTask.associated_project_key] = true
              }
            }
            for (let loopProjectKey in projectsToLoad) {
              promiseArray1.push(
                DatabaseGetDocument(DatabaseRef_Project_Document(res_GCK.clientKey, loopProjectKey))
                  .then((res_GD) => {
                    loadedProjects[loopProjectKey] = res_GD.data
                  })
                  .catch((rej_GD) => {
                    console.error(rej_GD)
                  }),
              )
            }
            promiseArray1.push(
              DatabaseGetCollection(DatabaseRef_Regions_Collection(res_GCK.clientKey))
                .then((res_GD) => {
                  regions = res_GD.data
                })
                .catch((rej_GD) => {
                  console.error(rej_GD)
                }),
            )
            Promise.all(promiseArray1).finally(() => {
              let projectsMissingMilesAndRegions: TsInterface_UnspecifiedObject = {}
              let projectsMissingRoofPitch: TsInterface_UnspecifiedObject = {}
              let projectsMissingPanels: TsInterface_UnspecifiedObject = {}
              let projectsMissingMilesToRepair: TsInterface_UnspecifiedObject = {}
              let projectsWithSuccessfulRepairedMileages: TsInterface_UnspecifiedObject = {}
              let projectsWithFailureRepairedMileages: TsInterface_UnspecifiedObject = {}
              // Loop through tasks
              for (let loopProjectKey in loadedProjects) {
                let loopProject = loadedProjects[loopProjectKey]
                if (loopProject != null && loopProject.system_max_roof_pitch == null) {
                  projectsMissingRoofPitch[loopProjectKey] = loopProject
                }
                if (loopProject != null && loopProject.system_panel_quantity == null) {
                  projectsMissingPanels[loopProjectKey] = loopProject
                }
                if (loopProject != null && loopProject.location_distance_from_warehouse == null) {
                  if (
                    loopProject.associated_region_key != null &&
                    loopProject.location_address != null &&
                    loopProject.location_city != null &&
                    loopProject.location_state != null &&
                    loopProject.location_zip != null &&
                    regions != null &&
                    regions[loopProject.associated_region_key] != null &&
                    regions[loopProject.associated_region_key].location_address != null &&
                    regions[loopProject.associated_region_key].location_city != null &&
                    regions[loopProject.associated_region_key].location_state != null &&
                    regions[loopProject.associated_region_key].location_zip != null
                  ) {
                    projectsMissingMilesToRepair[loopProjectKey] = loopProject
                    let projectAddress =
                      loopProject.location_address + ', ' + loopProject.location_city + ', ' + loopProject.location_state + ' ' + loopProject.location_zip
                    let warehouseAddress =
                      regions[loopProject.associated_region_key].location_address +
                      ', ' +
                      regions[loopProject.associated_region_key].location_city +
                      ', ' +
                      regions[loopProject.associated_region_key].location_state +
                      ' ' +
                      regions[loopProject.associated_region_key].location_zip
                    promiseArray2.push(
                      fixMissingMilesProper(res_GCK.clientKey, loopProjectKey, projectAddress, warehouseAddress)
                        .then((res_FMMP) => {
                          projectsWithSuccessfulRepairedMileages[loopProjectKey] = loopProject
                        })
                        .catch((rej_FMMP) => {
                          projectsWithFailureRepairedMileages[loopProjectKey] = loopProject
                        }),
                    )
                  } else {
                    projectsMissingMilesAndRegions[loopProjectKey] = loopProject
                  }
                }
              }
              Promise.all(promiseArray2).finally(() => {
                let notRepairCount = objectToArray(projectsMissingMilesAndRegions).length
                let repairAttemptCount = objectToArray(projectsMissingMilesToRepair).length
                let allMissingMilesCount = notRepairCount + repairAttemptCount
                let repairSuccessCount = objectToArray(projectsWithSuccessfulRepairedMileages).length
                let repairFailedCount = objectToArray(projectsWithFailureRepairedMileages).length
                let dialogJSX = (
                  <Box>
                    <Dialog
                      // TransitionComponent={ Transition }
                      className="bp_dialog_xl_width"
                      keepMounted
                      onClose={() => {
                        uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                      }}
                      open={true}
                    >
                      <AppBar
                        position="static"
                        color="inherit"
                      >
                        <Toolbar>
                          <IconButton
                            aria-label="menu"
                            color="inherit"
                            disabled
                            edge="start"
                            size="large"
                            sx={{ mr: 2, color: '#fff !important' }}
                          >
                            <Icon icon="route" />
                          </IconButton>
                          <Typography
                            component={'span'}
                            variant={'h6'}
                            sx={{ flexGrow: 1 }}
                          >
                            <Box className="tw-inline-block">{s_MILEAGE_REPAIR_SUMMARY}</Box>
                          </Typography>
                        </Toolbar>
                      </AppBar>
                      <DialogContent sx={{ padding: '0px' }}>
                        <TabsBasic
                          tabs={[
                            {
                              tabHeader: s_RESULTS,
                              tabContent: (
                                <Box className="tw-p-4">
                                  <Typography variant="body1">
                                    {s_PROJECTS_MISSING_DISTANCE_TO_WAREHOUSE}: {allMissingMilesCount}
                                  </Typography>
                                  <Divider className="tw-my-2" />
                                  <Box className="tw-ml-4">
                                    <Typography variant="body1">
                                      {s_MISSING_REGION}: {notRepairCount}
                                    </Typography>
                                    <Typography variant="body1">
                                      {s_ATTEMPTED_GEOCODE}: {repairAttemptCount}
                                    </Typography>
                                    <Divider className="tw-my-2" />
                                    <Box className="tw-ml-4">
                                      <Typography variant="body1">
                                        {s_SUCCESSFUL_GEOCODE}: {repairSuccessCount}
                                      </Typography>
                                      <Typography variant="body1">
                                        {s_FAILED_GEOCODE}: {repairFailedCount}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              ),
                            },
                            {
                              tabHeader: s_PROJECTS_MISSING_REGION,
                              tabContent: (
                                <Box className="tw-p-4">
                                  {objectToArray(projectsMissingMilesAndRegions)
                                    .sort()
                                    .map((project, index) => (
                                      <Button
                                        key={index}
                                        color="inherit"
                                        variant="outlined"
                                        className="tw-mb-2 tw-mr-2"
                                        onClick={() => {
                                          let url = window.location.origin + ApplicationPages.AdminActiveProjectViewPage.url(project.key)
                                          window.open(url, '_blank')
                                        }}
                                      >
                                        <Icon
                                          icon="external-link"
                                          className="tw-mr-2"
                                        />
                                        {project.id_number}
                                      </Button>
                                    ))}
                                </Box>
                              ),
                            },
                            {
                              tabHeader: s_PROJECTS_MISSING_ROOF_PITCH,
                              tabContent: (
                                <Box className="tw-p-4">
                                  {objectToArray(projectsMissingRoofPitch)
                                    .sort()
                                    .map((project, index) => (
                                      <Button
                                        key={index}
                                        color="inherit"
                                        variant="outlined"
                                        className="tw-mb-2 tw-mr-2"
                                        onClick={() => {
                                          let url = window.location.origin + ApplicationPages.AdminActiveProjectViewPage.url(project.key)
                                          window.open(url, '_blank')
                                        }}
                                      >
                                        <Icon
                                          icon="external-link"
                                          className="tw-mr-2"
                                        />
                                        {project.id_number}
                                      </Button>
                                    ))}
                                </Box>
                              ),
                            },
                            {
                              tabHeader: s_PROJECTS_MISSING_PANELS,
                              tabContent: (
                                <Box className="tw-p-4">
                                  {objectToArray(projectsMissingPanels)
                                    .sort()
                                    .map((project, index) => (
                                      <Button
                                        key={index}
                                        color="inherit"
                                        variant="outlined"
                                        className="tw-mb-2 tw-mr-2"
                                        onClick={() => {
                                          let url = window.location.origin + ApplicationPages.AdminActiveProjectViewPage.url(project.key)
                                          window.open(url, '_blank')
                                        }}
                                      >
                                        <Icon
                                          icon="external-link"
                                          className="tw-mr-2"
                                        />
                                        {project.id_number}
                                      </Button>
                                    ))}
                                </Box>
                              ),
                            },
                          ]}
                          tabsSettings={{}}
                        />
                      </DialogContent>
                    </Dialog>
                  </Box>
                )
                uc_setUserInterface_CustomDialogDisplay({
                  display: true,
                  dialog: {
                    dialog_jsx: dialogJSX,
                    settings: {
                      max_width: 'lg',
                    },
                  },
                })
                us_setExportingSchedule(false)
              })
            })
          })
          .catch((rej_GSED) => {
            us_setExportingSchedule(false)
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GSED.error })
          })
      })
      .catch((rej_GCK) => {
        us_setExportingSchedule(false)
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const openFreeformTimeOffDialog = (team: TsInterface_UnspecifiedObject, date: Date) => {
    let formattedDateKey = returnFormattedDateKey(date)

    // Helper function to calculate weekdays with dates (Monday to Sunday)
    const getWeekdaysWithDates = (startDate: Date) => {
      const weekdayMap = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      const monday = new Date(startDate)
      monday.setDate(startDate.getDate() - startDate.getDay() + 1) // Get Monday of the week
      return Array.from({ length: 7 }, (_, i) => {
        const currentDate = new Date(monday)
        currentDate.setDate(monday.getDate() + i)

        // Format date as "MM/DD"
        const formattedDate = `${(currentDate.getMonth() + 1).toString().padStart(2, '0')}/${currentDate.getDate().toString().padStart(2, '0')}`

        return {
          key: weekdayMap[currentDate.getDay()].toLowerCase(), // e.g., 'monday'
          value: `${weekdayMap[currentDate.getDay()]} (${formattedDate})`, // e.g., 'Monday (11/25)'
          date: currentDate,
        }
      })
    }

    // Helper function to calculate the week range (Monday to Sunday)
    const getWeekRange = (startDate: Date) => {
      const monday = new Date(startDate)
      monday.setDate(startDate.getDate() - startDate.getDay() + 1) // Get Monday of the week
      const sunday = new Date(monday)
      sunday.setDate(monday.getDate() + 6) // Get Sunday of the same week

      // Format the dates as "MM/DD/YYYY"
      const formattedMonday = `${(monday.getMonth() + 1).toString().padStart(2, '0')}/${monday.getDate().toString().padStart(2, '0')}/${monday.getFullYear()}`
      const formattedSunday = `${(sunday.getMonth() + 1).toString().padStart(2, '0')}/${sunday.getDate().toString().padStart(2, '0')}/${sunday.getFullYear()}`

      return `${formattedMonday} - ${formattedSunday}` // Return range as "MM/DD/YYYY - MM/DD/YYYY"
    }

    // Generate the weekdays with dates array
    const weekdaysWithDates = getWeekdaysWithDates(date)

    // Get the week range
    const weekRange = getWeekRange(date)

    console.log('Weekdays with dates in correct order:', weekdaysWithDates)
    console.log('Week range:', weekRange)

    const FormDialogObject: TsInterface_FormDialogObject = {
      form: {
        formAdditionalData: {},
        formData: {
          start_time: 8, // Numeric key for '8:00am'
          end_time: 17, // Numeric key for '5:00pm'
          time_off_type: 'time_off', // Default to "time_off"
          notes: '',
        },
        formInputs: {
          time_off_type: {
            key: 'time_off_type',
            label: s_TIME_OFF_TYPE,
            input_type: 'multiple_choice_radio',
            required: true,
            data_type: 'string',
            options: [
              { key: 'time_off', value: s_TIME_OFF },
              { key: 'travelled_and_benched', value: s_TRAVELLED_AND_BENCHED },
            ],
          },
          selected_weekdays: {
            key: 'selected_weekdays',
            label: rLIB('Select Days Off'),
            input_type: 'multiple_select_checklist',
            data_type: 'string',
            required: true,
            options: weekdaysWithDates.map((weekday) => ({
              key: weekday.key, // Monday, Tuesday, etc.
              value: weekday.value, // 'Monday (11/25)'
            })),
          },
          start_time: {
            data_type: 'string',
            input_type: 'multiple_choice_select',
            key: 'start_time',
            options: timeOptions,
            label: <>{s_START_TIME}</>,
            required: true,
            disabled: false,
          },
          end_time: {
            data_type: 'string',
            input_type: 'multiple_choice_select',
            key: 'end_time',
            options: timeOptions,
            label: <>{s_END_TIME}</>,
            required: true,
            disabled: false,
          },
          notes: {
            data_type: 'string',
            input_type: 'text_multiline',
            key: 'notes',
            label: <>{s_NOTES}</>,
            required: true,
            disabled: false,
          },
        },
        formOnChange: (
          formAdditionalData: TsInterface_FormAdditionalData,
          formData: TsInterface_FormData,
          formInputs: TsInterface_FormInputs,
          formSettings: TsInterface_FormSettings,
        ) => {},
        formSettings: {
          submit_button_alignment: 'right',
          submit_button_icon: <Icon icon="calendar-lines-pen" />,
          submit_button_saving_icon: true,
          submit_button_text: s_SCHEDULE_TIME_OFF,
          submit_button_theme: 'info',
        },
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            console.log('Form submission started with data:', formSubmittedData)

            // Map selected weekdays back to their corresponding dates
            const mapSelectedWeekdaysToDates = (selectedWeekdays: { [key: string]: boolean }) => {
              console.log('Mapping selected weekdays:', selectedWeekdays)

              return weekdaysWithDates
                .filter((weekday) => selectedWeekdays[weekday.key]) // Include only selected days
                .map((weekday) => weekday.date) // Return the actual Date object
            }

            let selectedDates
            try {
              selectedDates = mapSelectedWeekdaysToDates(formSubmittedData.selected_weekdays)
              console.log('Mapped selected weekdays to dates:', selectedDates)
            } catch (error) {
              console.error('Error mapping selected weekdays to dates:', error)
              reject(error)
              return
            }

            // Create documents for all selected days
            const createDocumentPromises = selectedDates.map((day) => {
              try {
                const formattedDateKey = returnFormattedDateKey(day)
                console.log('Processing day:', day, 'Formatted date key:', formattedDateKey)

                let startTime: TsInterface_UnspecifiedObject = {}
                let endTime: TsInterface_UnspecifiedObject = {}

                // Ensure start_time and end_time are saved as numbers
                startTime[formattedDateKey] = parseFloat(formSubmittedData.start_time)
                endTime[formattedDateKey] = parseFloat(formSubmittedData.end_time)

                const updateObject = {
                  associated_scheduled_time_slot: null,
                  associated_team_key: team.key,
                  associated_team_name: getProp(team, 'name', null),
                  associated_user_key: getProp(uc_RootData_ClientUser, 'key', null),
                  associated_user_name: getProp(uc_RootData_ClientUser, 'name', null),
                  task_completion_scheduled_start_times: startTime,
                  task_completion_scheduled_end_times: endTime,
                  time_off_type: formSubmittedData.time_off_type,
                  time_off_reason_notes: formSubmittedData.notes,
                  timestamp_created: new Date(),
                  timestamp_last_updated: new Date(),
                  timestamp_time_off: day,
                }

                console.log('Constructed update object for day:', updateObject)

                return getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                  console.log('Retrieved client key:', res_GCK.clientKey, 'Adding document to collection.')
                  return DatabaseAddDocument(DatabaseRef_ScheduledTimeOff_Collection(res_GCK.clientKey), updateObject, true)
                })
              } catch (error) {
                console.error('Error processing day:', day, error)
                throw error
              }
            })

            // Resolve all document creation promises
            Promise.all(createDocumentPromises)
              .then(() => {
                console.log('All documents created successfully.')
                resolve({ success: true })
                uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
              })
              .catch((error) => {
                console.error('Error during document creation promises:', error)
                reject(error)
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'info',
        formDialogHeaderText: (
          <>
            {s_TIME_OFF} ({weekRange}) - {team.name}
          </>
        ),
        formDialogIcon: <Icon icon="calendar-lines-pen" />,
      },
    }

    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: FormDialogObject,
    })
  }

  const returnTasksMissingBillingReference = (tasks: TsInterface_UnspecifiedObject) => {
    let tasksMissingBillingReference: TsInterface_UnspecifiedObject = {}
    for (let loopTaskKey in tasks) {
      let loopTask = tasks[loopTaskKey]
      if (
        loopTask != null &&
        loopTask.invoice_id_number == null &&
        loopTask.associated_team_type != null &&
        schedulingTeamTypes != null &&
        schedulingTeamTypes[loopTask.associated_team_type] != null &&
        schedulingTeamTypes[loopTask.associated_team_type].show_missing_invoice_ids === true
      ) {
        tasksMissingBillingReference[loopTaskKey] = loopTask
      }
    }
    return tasksMissingBillingReference
  }

  // JSX Generation
  const rJSX_TeamCellContent = (team: TsInterface_UnspecifiedObject): JSX.Element => {
    let teamCellJSX = <></>
    if (team != null && team.team_type != null && schedulingTeamTypes != null && schedulingTeamTypes[team.team_type] != null) {
      let teamMembershipJSX = <></>
      let teamBackgroundColor = getProp(schedulingTeamTypes[team.team_type], 'color', themeVariables.background_json)
      let teamOutline = '2px solid rgba(0,0,0,0)'
      if (team != null && team.associated_member_names != null && objectToArray(team.associated_member_names).length > 0) {
        let teamMembersFull: TsInterface_UnspecifiedObject = {}
        for (let loopMemberKey in team.associated_member_names) {
          let loopMemberName = team.associated_member_names[loopMemberKey]
          teamMembersFull[loopMemberKey] = {
            key: loopMemberKey,
            name: loopMemberName,
          }
          if (team.associated_member_roles != null && team.associated_member_roles[loopMemberKey] != null) {
            teamMembersFull[loopMemberKey]['role'] = team.associated_member_roles[loopMemberKey]
          }
        }
        let memberListJSX = (
          <Box>
            {objectToArray(teamMembersFull)
              .sort()
              .map((user, index) => (
                <Box key={index}>{rJSX_UserListName(user)}</Box>
              ))}
          </Box>
        )
        if (us_viewUsersOnCalendar === true) {
          teamMembershipJSX = (
            <Box>
              <Divider />
              {rJSX_TeamMembersList(teamMembersFull)}
            </Box>
          )
        } else {
          if (objectToArray(team.associated_member_names).length === 1) {
            teamMembershipJSX = (
              <Tooltip
                title={memberListJSX}
                placement="bottom-start"
              >
                <Box className="tw-italic tw-opacity-50">
                  {objectToArray(team.associated_member_names).length} {s_TEAM_MEMBER}
                </Box>
              </Tooltip>
            )
          } else {
            teamMembershipJSX = (
              <Tooltip
                title={memberListJSX}
                placement="bottom-start"
              >
                <Box className="tw-italic tw-opacity-50">
                  {objectToArray(team.associated_member_names).length} {s_TEAM_MEMBERS}
                </Box>
              </Tooltip>
            )
          }
        }
      } else {
        teamMembershipJSX = (
          <Box
            className="tw-italic"
            sx={{ color: 'rgba(255,255,255,0.5)' }}
          >
            {s_NO_PEOPLE_ASSIGNED_TO_TEAM}
          </Box>
        )
        teamOutline = '2px solid ' + teamBackgroundColor
        teamBackgroundColor = themeVariables.gray_600
      }
      if (team.key === us_highlighedMapTeam) {
        teamOutline = '4px solid ' + themeVariables.info_main
        teamBackgroundColor = 'rgba(0,0,0,0)'
      }
      // JSX
      teamCellJSX = (
        <Box
          className="tw-p-1 tw-rounded-md tw-inline-block"
          sx={{
            width: '200px',
            background: teamBackgroundColor,
            outline: teamOutline,
          }}
          onClick={(event) => {
            if (event.detail === 2) {
              openTeamDialog(team, ur_forceRerender)
            }
          }}
        >
          <Box>
            <Box className="tw-mr-1 tw-inline-block">
              <Tooltip
                title={getProp(schedulingTeamTypes[team.team_type], 'value', team.team_type)}
                placement="right"
              >
                <Box>{schedulingTeamTypes[team.team_type]['icon']}</Box>
              </Tooltip>
            </Box>
            {team.name}
          </Box>
          <Box>{teamMembershipJSX}</Box>
        </Box>
      )
    }
    return teamCellJSX
  }

  const rJSX_UnassignedTaskDraggable = (task: TsInterface_UnspecifiedObject): JSX.Element => {
    let taskJSX = <></>
    if (task != null && task.key != null) {
      let hasIncompletePrerequisites = false
      for (let loopPrerequisiteTaskKey in task.prerequisite_tasks) {
        if (task.prerequisite_tasks_completion == null || task.prerequisite_tasks_completion[loopPrerequisiteTaskKey] == null) {
          hasIncompletePrerequisites = true
        }
      }
      if (
        hasIncompletePrerequisites !== true &&
        task.associated_team_type != null &&
        us_filteredTaskKeysToDisplay != null &&
        us_filteredTaskKeysToDisplay[task.key] === true
      ) {
        taskJSX = (
          <DraggableUnassignedTask
            task={task}
            name={task.name}
            taskKey={task.key}
            type={task.associated_team_type}
            onDoubleClick={openUnassignedEventDialog}
            viewNotes={us_viewNotesOnCalendar}
          />
        )
      }
    }
    return taskJSX
  }

  const rJSX_UnassignedTasks = (): JSX.Element => {
    let taskFilterDropdownsJSX = (
      <Box>
        <Box className="tw-mb-4 tw-mt-1">
          <FormControl
            className="bp_thin_select_input bp_thin_select_multiple_input"
            sx={{ width: '100%' }}
          >
            <InputLabel id={'userFilter'}>{s_TASKS_FILTER}</InputLabel>
            <Select
              autoWidth={true}
              id={'userFilter'}
              labelId={'userFilter'}
              value={us_unassignedTasksFilter}
              label={<Box>{s_TASKS_FILTER}</Box>}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  us_setUnassignedTasksFilter(event.target.value)
                }
              }}
            >
              {objectToArray(taskFilterOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option.key}
                >
                  {option.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box className="tw-mb-2">
          <FormControl
            className="bp_thin_text_input"
            sx={{ width: '100%' }}
          >
            <TextField
              className="bp_thin_text_input"
              sx={{ marginTop: 0, marginBottom: 0 }}
              color="primary"
              value={us_taskSearchFilter}
              margin="normal"
              type="text"
              label={<Box>{s_TASK_SEARCH}</Box>}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  us_setTaskSearchFilter(event.target.value)
                }
              }}
              onBlur={(event: any) => {}}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </Box>
      </Box>
    )
    let unassignedTasksJSX = <></>
    if (us_unassignedTasksFilter === 'deleted_teams') {
      unassignedTasksJSX = <Box>{rJSX_EventsWithoutTeams()}</Box>
    } else {
      if (us_allUnassignedTasks != null && objectToArray(us_allUnassignedTasks).length > 0) {
        unassignedTasksJSX = (
          <Box sx={returnSX_UnassignedTaskContainer()}>
            <Box>
              {objectToArray(us_allUnassignedTasks)
                .sort(dynamicSort('name', 'asc'))
                .map((task, index) => (
                  <Box key={index}>{rJSX_UnassignedTaskDraggable(task)}</Box>
                ))}
            </Box>
          </Box>
        )
      } else {
        unassignedTasksJSX = (
          <Box>
            <Box className="tw-text-center">
              <Typography variant="h6">{s_NO_TASKS_TO_ASSIGN}</Typography>
            </Box>
          </Box>
        )
      }
    }
    let combinedJSX = (
      <Box>
        {taskFilterDropdownsJSX}
        {unassignedTasksJSX}
      </Box>
    )

    return combinedJSX
  }

  const rJSX_EventMissingTeam = (event: TsInterface_UnspecifiedObject): JSX.Element => {
    // TODO - probably show date and time...
    let eventJSX = <></>
    let backgroundColor = themeVariables.background_json
    if (
      event != null &&
      event.associated_team_type != null &&
      schedulingTeamTypes != null &&
      schedulingTeamTypes[event.associated_team_type] != null &&
      schedulingTeamTypes[event.associated_team_type]['color'] != null
    ) {
      backgroundColor = schedulingTeamTypes[event.associated_team_type]['color']
    }
    eventJSX = (
      <Box
        className="tw-rounded-md tw-p-1 tw-m-1 tw-inline-block"
        sx={{ backgroundColor: backgroundColor, minWidth: '140px' }}
        onDoubleClick={(clickEvent) => {
          openAssignedEventDialog(event)
        }}
      >
        <Box>
          {event.associated_project_id_number}
          <Divider />
          {event.name}
        </Box>
      </Box>
    )
    return eventJSX
  }

  const rJSX_EventsWithoutTeams = (): JSX.Element => {
    let eventsWithoutTeamsJSX = <></>
    if (us_calendarEventsWithoutTeams != null && objectToArray(us_calendarEventsWithoutTeams).length > 0) {
      eventsWithoutTeamsJSX = (
        <Box sx={returnSX_UnassignedTaskContainer()}>
          <Box>
            <Typography variant="h6">{s_EVENTS_ASSIGNED_TO_DELETED_TEAMS}</Typography>
          </Box>
          <Box>
            {objectToArray(us_calendarEventsWithoutTeams)
              .sort(dynamicSort('name', 'asc'))
              .map((event, index) => (
                <Box
                  key={index}
                  className="tw-inline-block"
                >
                  {rJSX_EventMissingTeam(event)}
                </Box>
              ))}
          </Box>
        </Box>
      )
    }
    return eventsWithoutTeamsJSX
  }

  // Week Schedule
  const returnSX_WeekCell = (day: number, date: Date | null): TsInterface_UnspecifiedObject => {
    let dayCellSX: TsInterface_UnspecifiedObject = { borderLeft: '1px solid ' + themeVariables.gray_700 }
    // if(day === 0 || day === 6){
    if (day === 6 || day === 7) {
      dayCellSX['background'] = themeVariables.background_default
    }
    if (date != null) {
      let formattedComparisonDate = returnFormattedDate(date, 'YYYY-MM-DD')
      let formattedComparisonToday = returnFormattedDate(new Date(), 'YYYY-MM-DD')
      if (formattedComparisonDate === formattedComparisonToday) {
        dayCellSX['borderBottom'] = '2px solid ' + themeVariables.white
        dayCellSX['borderLeft'] = '2px solid ' + themeVariables.white
        dayCellSX['borderTop'] = '2px solid ' + themeVariables.white
        dayCellSX['borderRight'] = '2px solid ' + themeVariables.white
      }
    }
    let dayOfWeek = us_selectedDate.getDay()
    if (day === dayOfWeek) {
      dayCellSX['borderColor'] = themeVariables.info_light
      dayCellSX['background'] = themeVariables.info_dark
    }
    return dayCellSX
  }

  const rJSX_EventTeamMembershipEdit = (teamKey: string, taskKey: string, date: Date, task: TsInterface_UnspecifiedObject): JSX.Element => {
    let editJSX = (
      <Box
        sx={{ color: 'rgba(255,255,255,0.3)' }}
        className="tw-italic tw-cursor-pointer tw-ml-4"
        onClick={() => {
          if (task.status !== 'cancelled') {
            openEventDayTeamMembershipDialog(teamKey, taskKey, date)
          }
        }}
      >
        <Icon icon="pen-to-square tw-mr-2" />
        {s_EDIT_TEAM}
      </Box>
    )
    return editJSX
  }

  const rJSX_TeamCompositionWarning = (team: TsInterface_UnspecifiedObject, actualEvent: TsInterface_UnspecifiedObject, date: Date): JSX.Element => {
    let warningJSX = <></>
    let errors: TsInterface_UnspecifiedObject = {}
    let dateKey = returnFormattedDateKey(date)
    // Requested changes to team composition
    if (actualEvent != null && actualEvent['requested_changes_to_team_composition'] === true) {
      errors['requested_changes_to_team_composition'] = (
        <>
          {s_REQUESTED_CHANGES_TO_TEAM_COMPOSITION}: {getProp(actualEvent, 'requested_changes_to_team_composition_notes', s_NO_NOTES)}
        </>
      )
    }
    // No assigned team members
    if (
      actualEvent != null &&
      actualEvent['task_completion_scheduled_team_keys'] != null &&
      actualEvent['task_completion_scheduled_team_keys'][dateKey] != null &&
      objectToArray(actualEvent['task_completion_scheduled_team_keys'][dateKey]).length === 0
    ) {
      errors['no_team_members'] = s_NO_ASSIGNED_TEAM_MEMBERS
    }
    // Different team members on incomplete task
    if (
      team != null &&
      team['associated_member_keys'] != null &&
      actualEvent != null &&
      actualEvent['task_completion_scheduled_team_keys'] != null &&
      actualEvent['task_completion_scheduled_team_keys'][dateKey] != null &&
      actualEvent['status_complete'] === false
    ) {
      let differentTeamMembers = false
      for (let loopTeamMemberKey in team['associated_member_keys']) {
        if (actualEvent['task_completion_scheduled_team_keys'][dateKey][loopTeamMemberKey] !== true) {
          differentTeamMembers = true
        }
      }
      if (differentTeamMembers === true) {
        errors['different_team_members_on_incomplete_task'] = s_CURRENT_TEAM_MEMBERS_DIFFER_FROM_SCHEDULED_TEAM_MEMBERS
      }
    }
    // Missing Foreman Role
    if (
      team != null &&
      team['associated_member_keys'] != null &&
      actualEvent != null &&
      actualEvent['task_completion_scheduled_team_keys'] != null &&
      actualEvent['task_completion_scheduled_team_keys'][dateKey] != null &&
      actualEvent['task_completion_scheduled_team_roles'] != null &&
      actualEvent['task_completion_scheduled_team_roles'][dateKey] != null
    ) {
      let hasForeman = false
      for (let loopTeamMemberKey in actualEvent['task_completion_scheduled_team_keys'][dateKey]) {
        if (
          actualEvent['task_completion_scheduled_team_roles'][dateKey][loopTeamMemberKey] === 'install_foreman' ||
          actualEvent['task_completion_scheduled_team_roles'][dateKey][loopTeamMemberKey] === 'sow_foreman'
        ) {
          hasForeman = true
        }
      }
      if (hasForeman === false) {
        errors['missing_foreman'] = s_MISSING_FOREMAN
      }
    }
    // Errors
    if (objectToArray(errors).length > 0) {
      let tooltipJSX = (
        <Box>
          {objectToArray(errors).map((error: string, index: number) => (
            <Box key={index}>{error}</Box>
          ))}
        </Box>
      )
      // TODO
      warningJSX = (
        <Tooltip
          title={tooltipJSX}
          placement="bottom"
        >
          <Box
            className="tw-rounded-md tw-m-1 tw-px-1"
            sx={{
              color: themeVariables.white,
              backgroundColor: themeVariables.background_json,
            }}
          >
            <Icon
              icon="users"
              className="tw-mr-1"
            />
            {s_TEAM}
          </Box>
        </Tooltip>
      )
    }
    return warningJSX
  }

  const rJSX_FreeformTimeOff = (event: TsInterface_UnspecifiedObject): JSX.Element => {
    let eventJSX = <></>
    // Times
    let startTimeLabel = ''
    let endTimeLabel = ''
    let startTimeMinutesText = ''
    let endTimeMinutesText = ''
    let startTimeHours = Math.floor(event['start_time'])
    let startTimeMinutes = parseInt(((event['start_time'] % 1) * 60).toFixed(0))
    let endTimeHours = Math.floor(event['end_time'])
    let endTimeMinutes = parseInt(((event['end_time'] % 1) * 60).toFixed(0))
    if (startTimeMinutes < 10) {
      startTimeMinutesText = '0' + startTimeMinutes
    } else {
      startTimeMinutesText = startTimeMinutes.toString()
    }
    if (endTimeMinutes < 10) {
      endTimeMinutesText = '0' + endTimeMinutes
    } else {
      endTimeMinutesText = endTimeMinutes.toString()
    }
    if (startTimeHours === 0) {
      startTimeLabel += '12:' + startTimeMinutesText + 'am'
    } else if (startTimeHours === 12) {
      startTimeLabel += '12:' + startTimeMinutesText + 'pm'
    } else if (startTimeHours < 12) {
      startTimeLabel += startTimeHours + ':' + startTimeMinutesText + 'am'
    } else {
      startTimeLabel += startTimeHours - 12 + ':' + startTimeMinutesText + 'pm'
    }
    if (endTimeHours === 0) {
      endTimeLabel += '12:' + endTimeMinutesText + 'am'
    } else if (endTimeHours === 12) {
      endTimeLabel += '12:' + endTimeMinutesText + 'pm'
    } else if (endTimeHours < 12) {
      endTimeLabel += endTimeHours + ':' + endTimeMinutesText + 'am'
    } else {
      endTimeLabel += endTimeHours - 12 + ':' + endTimeMinutesText + 'pm'
    }
    // Handle Types
    let timeOffText = s_TIME_OFF
    let taskEventBannerJSX = <></>
    switch (event.time_off_type) {
      case 'travelled_and_benched':
        timeOffText = s_TRAVELLED_AND_BENCHED
        taskEventBannerJSX = (
          <Box
            sx={{ backgroundColor: themeVariables.orange_500 }}
            className="tw-pl-1"
          >
            <Icon
              icon="plane"
              className="tw-mr-2"
            />
            {s_BENCHED}
          </Box>
        )
        break
      case 'time_off':
        timeOffText = s_TIME_OFF
        taskEventBannerJSX = (
          <Box
            sx={{ backgroundColor: themeVariables.orange_500 }}
            className="tw-pl-1"
          >
            <Icon
              icon="island-tropical"
              className="tw-mr-2"
            />
            {s_TIME_OFF}
          </Box>
        )
        break
      default:
        timeOffText = s_TIME_OFF
        taskEventBannerJSX = (
          <Box
            sx={{ backgroundColor: themeVariables.orange_500 }}
            className="tw-pl-1"
          >
            <Icon
              icon="island-tropical"
              className="tw-mr-2"
            />
            {s_TIME_OFF}
          </Box>
        )
    }

    // JSX
    eventJSX = (
      <Box
        className="tw-rounded-md tw-m-1"
        sx={{
          color: themeVariables.white,
          backgroundColor: themeVariables.background_paper,
          outline: '1px solid ' + themeVariables.orange_300,
          minWidth: '140px',
        }}
        onDoubleClick={(clickEvent) => {
          clickEvent.stopPropagation()
          uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: s_CANCEL_TIME_OFF,
              icon: (
                <Icon
                  icon="calendar-circle-minus"
                  type="solid"
                />
              ),
              submit_text: s_CANCEL,
              text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CANCEL_THIS_TIME_OFF,
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseDeleteDocument(DatabaseRef_ScheduledTimeOff_Document(res_GCK.clientKey, event.key))
                        .then((res_DDD) => {
                          resolve({ success: true })
                          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                        })
                        .catch((rej_DDD) => {
                          console.error(rej_DDD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                })
              },
            },
          })
        }}
      >
        {taskEventBannerJSX}
        <Box className="tw-p-1">
          <Box>
            {startTimeLabel} - {endTimeLabel}
          </Box>
          <Box>
            {timeOffText}
            <Tooltip
              title={
                <>
                  {s_NOTES}: {event.time_off_reason_notes}
                </>
              }
              placement="top"
            >
              <Box className="tw-inline-block">
                <Icon
                  className="tw-ml-2 tw-opacity-30"
                  icon="magnifying-glass"
                />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    )
    // Return
    return eventJSX
  }

  const rJSX_UserListName = (user: TsInterface_UnspecifiedObject): JSX.Element => {
    let userListNameJSX = <></>
    if (user != null) {
      if (user.role != null && availableTeamRoles != null && availableTeamRoles[user.role] != null && availableTeamRoles[user.role]['value'] != null) {
        userListNameJSX = (
          <Box>
            <Tooltip
              title={availableTeamRoles[user.role]['value']}
              placement="left"
            >
              <Box>
                <Icon
                  icon={availableTeamRoles[user.role]['icon']}
                  type={availableTeamRoles[user.role]['icon_type']}
                  className="tw-ml-3 tw-mr-2"
                />
                {user.name}
              </Box>
            </Tooltip>
          </Box>
        )
      } else {
        userListNameJSX = userListNameJSX = (
          <Box>
            <Tooltip
              title={s_NO_TEAM_ROLE_SELECTED}
              placement="left"
            >
              <Box>
                <Icon
                  icon="user"
                  className="tw-ml-3 tw-mr-2"
                />
                {user.name}
              </Box>
            </Tooltip>
          </Box>
        )
      }
    }
    return userListNameJSX
  }

  const rJSX_TeamMemberName = (user: TsInterface_UnspecifiedObject, roleFilter: string | null): JSX.Element => {
    let nameJSX = <></>
    if (user.role === roleFilter) {
      nameJSX = rJSX_UserListName(user)
    } else if (roleFilter == null && (user.role == null || availableTeamRoles[user.role] == null)) {
      nameJSX = rJSX_UserListName(user)
    }
    return nameJSX
  }

  const rJSX_TeamMembersList = (eventTeamDayMembersFull: TsInterface_UnspecifiedObject): JSX.Element => {
    let listJSX = (
      <Box>
        {objectToArray(eventTeamDayMembersFull)
          .sort(dynamicSort('name', 'asc'))
          .map((user, index) => (
            <Box key={index}>{rJSX_TeamMemberName(user, 'install_foreman')}</Box>
          ))}
        {objectToArray(eventTeamDayMembersFull)
          .sort(dynamicSort('name', 'asc'))
          .map((user, index) => (
            <Box key={index}>{rJSX_TeamMemberName(user, 'sow_foreman')}</Box>
          ))}
        {objectToArray(eventTeamDayMembersFull)
          .sort(dynamicSort('name', 'asc'))
          .map((user, index) => (
            <Box key={index}>{rJSX_TeamMemberName(user, 'install_lead')}</Box>
          ))}
        {objectToArray(eventTeamDayMembersFull)
          .sort(dynamicSort('name', 'asc'))
          .map((user, index) => (
            <Box key={index}>{rJSX_TeamMemberName(user, 'installer')}</Box>
          ))}
        {objectToArray(eventTeamDayMembersFull)
          .sort(dynamicSort('name', 'asc'))
          .map((user, index) => (
            <Box key={index}>{rJSX_TeamMemberName(user, 'sow_helper')}</Box>
          ))}
        {objectToArray(eventTeamDayMembersFull)
          .sort(dynamicSort('name', 'asc'))
          .map((user, index) => (
            <Box key={index}>{rJSX_TeamMemberName(user, null)}</Box>
          ))}
      </Box>
    )
    return listJSX
  }

  const rJSX_WeekEvent = (
    event: TsInterface_UnspecifiedObject,
    team: TsInterface_UnspecifiedObject,
    date: Date,
    scheduleView: boolean,
    teamAssignmentType: 'defined' | 'freeform',
    draggable: boolean,
  ): JSX.Element => {
    let eventJSX = <></>
    let backgroundColor = themeVariables.background_json
    let backgroundColor2 = themeVariables.background_json
    if (
      event != null &&
      event.associated_team_type != null &&
      schedulingTeamTypes != null &&
      schedulingTeamTypes[event.associated_team_type] != null &&
      schedulingTeamTypes[event.associated_team_type]['color'] != null
    ) {
      backgroundColor = schedulingTeamTypes[event.associated_team_type]['color']
      backgroundColor2 = schedulingTeamTypes[event.associated_team_type]['color2']
    }
    // Times
    let startTimeLabel = ''
    let endTimeLabel = ''
    let startTimeMinutesText = ''
    let endTimeMinutesText = ''
    let startTimeHours = Math.floor(event['start_time'])
    let startTimeMinutes = parseInt(((event['start_time'] % 1) * 60).toFixed(0))
    let endTimeHours = Math.floor(event['end_time'])
    let endTimeMinutes = parseInt(((event['end_time'] % 1) * 60).toFixed(0))
    if (startTimeMinutes < 10) {
      startTimeMinutesText = '0' + startTimeMinutes
    } else {
      startTimeMinutesText = startTimeMinutes.toString()
    }
    if (endTimeMinutes < 10) {
      endTimeMinutesText = '0' + endTimeMinutes
    } else {
      endTimeMinutesText = endTimeMinutes.toString()
    }
    if (startTimeHours === 0) {
      startTimeLabel += '12:' + startTimeMinutesText + 'am'
    } else if (startTimeHours === 12) {
      startTimeLabel += '12:' + startTimeMinutesText + 'pm'
    } else if (startTimeHours < 12) {
      startTimeLabel += startTimeHours + ':' + startTimeMinutesText + 'am'
    } else {
      startTimeLabel += startTimeHours - 12 + ':' + startTimeMinutesText + 'pm'
    }
    if (endTimeHours === 0) {
      endTimeLabel += '12:' + endTimeMinutesText + 'am'
    } else if (endTimeHours === 12) {
      endTimeLabel += '12:' + endTimeMinutesText + 'pm'
    } else if (endTimeHours < 12) {
      endTimeLabel += endTimeHours + ':' + endTimeMinutesText + 'am'
    } else {
      endTimeLabel += endTimeHours - 12 + ':' + endTimeMinutesText + 'pm'
    }

    // Event Status Options
    // not_scheduled
    // time_off
    // open_time_slot
    // task
    // cancelled_task

    if (event.status === 'not_scheduled') {
      eventJSX = (
        <Box
          className="tw-rounded-md tw-p-1 tw-m-1"
          sx={{
            color: themeVariables.gray_600,
            backgroundColor: themeVariables.background_default,
            outline: '1px solid ' + themeVariables.gray_600,
            minWidth: '140px',
          }}
          onDoubleClick={(clickEvent) => {
            clickEvent.stopPropagation()
          }}
        >
          <Box>
            <Box>
              {startTimeLabel} - {endTimeLabel}
            </Box>
            <Box>{event.name}</Box>
          </Box>
        </Box>
      )
    } else if (event.status === 'time_off') {
      let outlineStyle = 'solid'
      if (event.mismatch === true) {
        outlineStyle = 'dashed'
      }
      // JSX
      eventJSX = (
        <Box
          className="tw-rounded-md tw-m-1"
          sx={{
            color: themeVariables.white,
            backgroundColor: themeVariables.background_paper,
            outline: '1px ' + outlineStyle + ' ' + themeVariables.orange_300,
            minWidth: '140px',
          }}
          onDoubleClick={(clickEvent) => {
            clickEvent.stopPropagation()
            openAssignedEventDialog(event)
          }}
        >
          <Box
            sx={{ backgroundColor: themeVariables.orange_500 }}
            className="tw-pl-1"
          >
            <Icon
              icon="island-tropical"
              className="tw-mr-2"
            />
            {s_TIME_OFF}
          </Box>
          <Box className="tw-p-1">
            <Box>
              {startTimeLabel} - {endTimeLabel}
            </Box>
            <Box>{event.name}</Box>
          </Box>
        </Box>
      )
    } else if (event.status === 'open_time_slot') {
      // JSX
      if (scheduleView === true) {
        eventJSX = (
          <Box
            className="tw-rounded-md tw-p-1 tw-m-1"
            sx={{
              background: themeVariables.background_json,
              outline: '1px solid ' + themeVariables.white,
              minWidth: '140px',
            }}
            onDoubleClick={(clickEvent) => {
              clickEvent.stopPropagation()
              openAssignedEventDialog(event)
            }}
          >
            <DroppableReassignTeamDefinedSlot
              team={team}
              setLastTaskSwappedTimestamp={us_setLastTaskSwappedTimestamp}
              accept={[returnDefinedReassignTaskKey(team.team_type, returnFormattedDate(date, 'YYYY-MM-DD'), event.window_start_time)]}
              childrenJSX={
                <DroppableTeamSlot
                  definedTimeSlotKey={event.key}
                  taskAssignmentType={'defined_slots'}
                  allScheduledTasks={us_calendarEvents}
                  allTeams={us_activeTeams}
                  teamTypeSettings={us_teamTypeSettings}
                  assignDate={date}
                  assignTime={null}
                  team={team}
                  accept={[team.team_type]}
                  sx={{ width: '100%' }}
                  uc_setUserInterface_CustomDialogDisplay={uc_setUserInterface_CustomDialogDisplay}
                >
                  <Box>
                    <Box>
                      {startTimeLabel} - {endTimeLabel}
                    </Box>
                    <Box>{event.name}</Box>
                  </Box>
                </DroppableTeamSlot>
              }
              sx={{}}
            />
          </Box>
        )
      } else {
        eventJSX = (
          <Box
            className="tw-rounded-md tw-p-1 tw-m-1"
            sx={{
              background: themeVariables.background_json,
              outline: '1px solid ' + themeVariables.white,
              minWidth: '140px',
            }}
            onDoubleClick={(clickEvent) => {
              clickEvent.stopPropagation()
              openAssignedEventDialog(event)
            }}
          >
            <DroppableReassignTeamDefinedSlot
              team={team}
              setLastTaskSwappedTimestamp={us_setLastTaskSwappedTimestamp}
              accept={[returnDefinedReassignTaskKey(team.team_type, returnFormattedDate(date, 'YYYY-MM-DD'), event.window_start_time)]}
              childrenJSX={
                <Box>
                  <Box>
                    {startTimeLabel} - {endTimeLabel}
                  </Box>
                  <Box>{event.name}</Box>
                </Box>
              }
              sx={{}}
            />
          </Box>
        )
      }
    } else {
      // task && cancelled_task - TODO check cancelled
      let actualEvent = event
      if (event.actual_event != null) {
        actualEvent = getProp(event, 'actual_event', {})
      }
      if (actualEvent.status === 'completed') {
        // backgroundColor = "repeating-linear-gradient(45deg, " + backgroundColor + ", " + backgroundColor + " 16px, " + themeVariables.success_dark + " 16px, " + themeVariables.success_dark + " 32px)"
      }
      // Coordinates
      let coordinatesJSX = <></>
      if (scheduleView === true && (actualEvent.location_latitude == null || actualEvent.location_longitude == null)) {
        coordinatesJSX = (
          <Box>
            <Divider />
            <Icon icon="triangle-exclamation tw-mr-2" />
            {s_MISSING_COORDINATES}
          </Box>
        )
      }
      // Outline
      let outlineStyle = 'solid'
      if (event.mismatch === true) {
        outlineStyle = 'dashed'
      }
      let eventOutline = '1px ' + outlineStyle + ' ' + themeVariables.white
      if (actualEvent.status === 'deleted' || actualEvent.status === 'cancelled') {
        // Cancelled Events
        eventOutline = '2px ' + outlineStyle + ' ' + schedulingTeamTypes[event.associated_team_type]['color']
        backgroundColor = 'rgba(0,0,0,0)'
      }
      if (us_highlighedMapEvent === actualEvent.key) {
        eventOutline = '2px ' + outlineStyle + ' ' + themeVariables.success_main
        backgroundColor = 'rgba(0,0,0,0)'
      } else if (us_highlighedMapTeam === actualEvent.associated_team_key) {
        eventOutline = '2px ' + outlineStyle + ' ' + themeVariables.info_main
        backgroundColor = 'rgba(0,0,0,0)'
      } else if (event.mismatch === true) {
        backgroundColor =
          'repeating-linear-gradient(45deg, ' +
          backgroundColor +
          ', ' +
          backgroundColor +
          ' 16px, ' +
          backgroundColor2 +
          ' 16px, ' +
          backgroundColor2 +
          ' 32px)'
      }
      if (actualEvent.status === 'completed') {
        eventOutline = '2px ' + outlineStyle + ' ' + themeVariables.success_main
      }
      let teamMembershipJSX = <></>
      if (us_viewUsersOnCalendar === true) {
        let eventTeamDayMembers: TsInterface_UnspecifiedObject = {}
        let eventTeamDayMembersFull: TsInterface_UnspecifiedObject = {}
        let dateKey = returnFormattedDateKey(date)
        if (
          actualEvent != null &&
          actualEvent.task_completion_scheduled_team_names != null &&
          actualEvent.task_completion_scheduled_team_names[dateKey] != null
        ) {
          eventTeamDayMembers = actualEvent.task_completion_scheduled_team_names[dateKey]
        }
        for (let loopMemberKey in eventTeamDayMembers) {
          let loopMemberName = eventTeamDayMembers[loopMemberKey]
          eventTeamDayMembersFull[loopMemberKey] = {
            key: loopMemberKey,
            name: loopMemberName,
          }
          if (
            actualEvent.task_completion_scheduled_team_roles != null &&
            actualEvent.task_completion_scheduled_team_roles[dateKey] != null &&
            actualEvent.task_completion_scheduled_team_roles[dateKey][loopMemberKey] != null
          ) {
            eventTeamDayMembersFull[loopMemberKey]['role'] = actualEvent.task_completion_scheduled_team_roles[dateKey][loopMemberKey]
          }
        }
        // JSX
        if (objectToArray(eventTeamDayMembersFull).length > 0) {
          teamMembershipJSX = (
            <Box>
              <Divider />
              {rJSX_TeamMembersList(eventTeamDayMembersFull)}
              {rJSX_EventTeamMembershipEdit(team.key, actualEvent.key, date, actualEvent)}
              {rJSX_TeamCompositionWarning(team, actualEvent, date)}
            </Box>
          )
        } else {
          teamMembershipJSX = (
            <Box
              className="tw-italic"
              sx={{ color: 'rgba(255,255,255,0.5)' }}
            >
              <Divider />
              <Icon icon="triangle-exclamation tw-mr-2" />
              {s_NO_PEOPLE_ASSIGNED_TO_TEAM}
              {rJSX_EventTeamMembershipEdit(team.key, actualEvent.key, date, actualEvent)}
              {rJSX_TeamCompositionWarning(team, actualEvent, date)}
            </Box>
          )
        }
      }
      let notesJSX = rJSX_TaskCalendarNotes(actualEvent, date, us_viewNotesOnCalendar, uc_setUserInterface_FormDialogDisplay)
      // Banner JSX
      let taskEventBannerJSX = <></>
      let cancelledTaskFooter = <></>
      if (actualEvent.status === 'completed') {
        taskEventBannerJSX = (
          <Box
            sx={{ backgroundColor: themeVariables.success_main }}
            className="tw-pl-1"
          >
            <Icon
              icon="badge-check"
              className="tw-mr-2"
            />
            {s_COMPLETED}
          </Box>
        )
      } else if (actualEvent.status === 'deleted') {
        taskEventBannerJSX = (
          <Box
            sx={{ backgroundColor: themeVariables.error_dark }}
            className="tw-pl-1"
          >
            <Icon
              icon="trash-xmark"
              className="tw-mr-2"
            />
            {s_DELETED}
          </Box>
        )
      } else if (actualEvent.status === 'cancelled' || actualEvent.status === 'cancelled_task') {
        taskEventBannerJSX = (
          <Box
            sx={{ backgroundColor: themeVariables.error_dark }}
            className="tw-pl-1"
          >
            <Icon
              icon="trash-xmark"
              className="tw-mr-2"
            />
            {s_CANCELLED}
          </Box>
        )
        // Cancel Redo Footer
        let redoReasonTextJSX = <></>
        if (actualEvent != null && actualEvent.redo_reason != null) {
          if (
            onSiteTaskRedoTasks != null &&
            onSiteTaskRedoTasks[actualEvent.redo_reason as string] != null &&
            onSiteTaskRedoTasks[actualEvent.redo_reason as string]['value'] != null
          ) {
            redoReasonTextJSX = <Box>{onSiteTaskRedoTasks[actualEvent.redo_reason as string]['value']}</Box>
          } else {
            redoReasonTextJSX = <Box>{underscoresToSpaces(actualEvent.redo_reason as string)}</Box>
          }
        }
        cancelledTaskFooter = (
          <Box
            className="tw-p-0.5"
            sx={{ backgroundColor: themeVariables.error_dark }}
          >
            <Box className="tw-flex tw-font-bold">{redoReasonTextJSX}:</Box>
            <Box className="tw-opacity-40">{getProp(actualEvent, 'redo_notes', <Box className="tw-inline-block tw-opacity-40">{s_NO_NOTES}</Box>)}</Box>
          </Box>
        )
      } else if (actualEvent.status === 'active') {
        taskEventBannerJSX = (
          <Box
            sx={{ backgroundColor: themeVariables.info_dark }}
            className="tw-pl-1"
          >
            <Icon
              icon="play"
              className="tw-mr-2"
            />
            {s_ASSIGNED}
          </Box>
        )
      } else if (actualEvent.status === 'assigned') {
        taskEventBannerJSX = (
          <Box
            sx={{ backgroundColor: themeVariables.info_dark }}
            className="tw-pl-1"
          >
            <Icon
              icon="play"
              className="tw-mr-2"
            />
            {s_ASSIGNED}
          </Box>
        )
      } else if (actualEvent.status === 'not_scheduled') {
        // Nothing
      } else if (actualEvent.status === 'open_time_slot') {
        // Nothing
      }
      // JSX
      let innerEventJSX = (
        <Box
          className="tw-rounded-md tw-m-1 tw-mb-2"
          sx={{
            outline: eventOutline,
            background: backgroundColor,
            minWidth: '140px',
          }}
          onDoubleClick={(clickEvent) => {
            clickEvent.stopPropagation()
            openAssignedEventDialog(actualEvent)
          }}
        >
          {taskEventBannerJSX}
          <Box className="tw-p-1">
            <Box>{getProp(actualEvent, 'associated_project_id_number', null)}</Box>
            <Divider />
            <Box>
              {startTimeLabel} - {endTimeLabel}
            </Box>
            <Box>{event.name}</Box>
            {teamMembershipJSX}
            {notesJSX}
            {coordinatesJSX}
            {/* { rJSX_UnitPayTimestamp( actualEvent ) } */}
          </Box>
          {cancelledTaskFooter}
        </Box>
      )
      if (teamAssignmentType === 'defined') {
        let doubleInnerJSX = (
          <Box>
            <DroppableReassignTeamWithTaskDefinedSlot
              team={team}
              windowKey={event.window_start_time.toString() + '-' + event.window_end_time.toString()}
              setLastTaskSwappedTimestamp={us_setLastTaskSwappedTimestamp}
              allTeams={us_activeTeams}
              calendarRenderData={us_calendarRenderData}
              accept={[returnDefinedReassignTaskKey(team.team_type, returnFormattedDate(date, 'YYYY-MM-DD'), event.window_start_time)]}
              childrenJSX={innerEventJSX}
              sx={{}}
            />
          </Box>
        )
        eventJSX = (
          <Box>
            <DraggableAssignedDefinedTimeslotTask
              task={event}
              taskKey={event.key}
              type={returnDefinedReassignTaskKey(team.team_type, returnFormattedDate(date, 'YYYY-MM-DD'), event.window_start_time)}
              childrenJSX={doubleInnerJSX}
            />
          </Box>
        )
      } else {
        eventJSX = innerEventJSX
      }
      // Wipe if User Filter doesn't line up
      if (us_filteredUserKey != null && us_filteredUserKey !== '') {
        let userOnEvent = false
        if (actualEvent != null && actualEvent.task_completion_scheduled_team_keys != null) {
          for (let loopDateKey in actualEvent.task_completion_scheduled_team_keys) {
            let loopDate = actualEvent.task_completion_scheduled_team_keys[loopDateKey]
            for (let loopUserKey in loopDate) {
              if (loopDate[loopUserKey] === true && us_filteredUserKey === loopUserKey) {
                userOnEvent = true
              }
            }
          }
        }
        if (userOnEvent === false) {
          eventJSX = <></>
        }
      }
    }
    return eventJSX
  }

  const rJSX_WeekCellContent_DefinedSlots = (team: TsInterface_UnspecifiedObject, date: Date, scheduleView: boolean, draggable: boolean): JSX.Element => {
    // Instantiate Variables
    let cellContentJSX: JSX.Element = <></>
    let formattedComparisonDate = returnFormattedDate(date, 'YYYY-MM-DD')
    let dayTeamEvents: TsInterface_UnspecifiedObject = {}
    // Get Data from render calculation
    if (
      team != null &&
      team.key != null &&
      us_calendarRenderData != null &&
      us_calendarRenderData['teams'] != null &&
      us_calendarRenderData['teams'][team.key] != null &&
      us_calendarRenderData['teams'][team.key]['days'] != null &&
      us_calendarRenderData['teams'][team.key]['days'][formattedComparisonDate] != null &&
      us_calendarRenderData['teams'][team.key]['days'][formattedComparisonDate]['rendered_events'] != null
    ) {
      // Loop through events for this team and day
      for (let loopEventKey in us_calendarRenderData['teams'][team.key]['days'][formattedComparisonDate]['rendered_events']) {
        let loopRenderEvent = us_calendarRenderData['teams'][team.key]['days'][formattedComparisonDate]['rendered_events'][loopEventKey]
        let loopRenderEventProper = getProp(loopRenderEvent, 'event', {})
        // Add to render array
        dayTeamEvents[loopEventKey] = {
          actual_event: getProp(loopRenderEvent, 'event', {}),
          associated_team: team,
          associated_team_type: team.team_type,
          end_time: getProp(loopRenderEvent, 'window_end_time', 0),
          key: getProp(loopRenderEventProper, 'key', loopRenderEvent.key),
          mismatch: getProp(loopRenderEvent, 'mismatch', false),
          name: getProp(loopRenderEvent, 'name', null),
          scheduled_day: date,
          start_time: getProp(loopRenderEvent, 'window_start_time', 0),
          status: loopRenderEvent.status,
          window_end_time: getProp(loopRenderEvent, 'window_end_time', null),
          window_start_time: getProp(loopRenderEvent, 'window_start_time', null),
        }
      }
    }
    // JSX
    if (scheduleView === true) {
      cellContentJSX = (
        <Box>
          {objectToArray(dayTeamEvents)
            .sort(dynamicSort('start_time', null))
            .map((event, index) => (
              <Box key={index}>{rJSX_WeekEvent(event, team, date, scheduleView, 'defined', draggable)}</Box>
            ))}
        </Box>
      )
    } else {
      cellContentJSX = (
        <Stack direction="row">
          {objectToArray(dayTeamEvents)
            .sort(dynamicSort('start_time', null))
            .map((event, index) => (
              <Box
                key={index}
                className="tw-m-auto tw-inline-block"
                onMouseEnter={() => {
                  us_setHighlightedMapTeam(event.actual_event.associated_team_key)
                  us_setHighlightedMapEvent(event.key)
                }}
                onMouseLeave={() => {
                  us_setHighlightedMapTeam(null)
                  us_setHighlightedMapEvent(null)
                }}
                // sx={ highlightBorderSX }
              >
                {rJSX_WeekEvent(event, team, date, scheduleView, 'defined', draggable)}
              </Box>
            ))}
        </Stack>
      )
    }
    return cellContentJSX
  }

  const rJSX_WeekCellContent_FreeformSlots = (team: TsInterface_UnspecifiedObject, date: Date, scheduleView: boolean, draggable: boolean): JSX.Element => {
    let cellContentJSX: JSX.Element = <></>
    let formattedComparisonDate = returnFormattedDate(date, 'YYYY-MM-DD')
    // Get Events for Specified Day and Team
    let dayTeamEvents: TsInterface_UnspecifiedObject = {}
    for (let loopEventKey in us_calendarEvents) {
      let loopEvent = us_calendarEvents[loopEventKey]
      if (
        formattedComparisonDate != null &&
        team != null &&
        team['key'] != null &&
        loopEvent != null &&
        loopEvent['associated_team_key'] != null &&
        loopEvent['associated_team_key'] === team['key'] &&
        loopEvent['task_completion_scheduled_start_times'] != null &&
        loopEvent['task_completion_scheduled_start_times'][formattedComparisonDate] != null
      ) {
        dayTeamEvents[loopEventKey] = loopEvent
        dayTeamEvents[loopEventKey]['key'] = loopEventKey
        dayTeamEvents[loopEventKey]['name'] = getProp(loopEvent, 'name', null)
        dayTeamEvents[loopEventKey]['associated_team_type'] = getProp(loopEvent, 'associated_team_type', null)
        dayTeamEvents[loopEventKey]['associated_team_key'] = getProp(loopEvent, 'associated_team_key', null)
        dayTeamEvents[loopEventKey]['start_time'] = getProp(loopEvent, 'task_completion_scheduled_start_times.' + formattedComparisonDate, 0)
        dayTeamEvents[loopEventKey]['end_time'] = getProp(loopEvent, 'task_completion_scheduled_end_times.' + formattedComparisonDate, 23.75)
        dayTeamEvents[loopEventKey]['status'] = getProp(loopEvent, 'status', null)
      }
    }
    // Time Off
    let dayTeamTimeOff: TsInterface_UnspecifiedObject = {}
    for (let loopTimeOffKey in us_scheduledTimeOff) {
      let loopTimeOff = us_scheduledTimeOff[loopTimeOffKey]
      if (
        formattedComparisonDate != null &&
        team != null &&
        team['key'] != null &&
        loopTimeOff != null &&
        loopTimeOff['associated_team_key'] != null &&
        loopTimeOff['associated_team_key'] === team['key'] &&
        loopTimeOff['task_completion_scheduled_start_times'] != null &&
        loopTimeOff['task_completion_scheduled_start_times'][formattedComparisonDate] != null
      ) {
        dayTeamTimeOff[loopTimeOffKey] = loopTimeOff
        dayTeamTimeOff[loopTimeOffKey]['key'] = loopTimeOffKey
        dayTeamTimeOff[loopTimeOffKey]['name'] = getProp(loopTimeOff, 'name', null)
        dayTeamTimeOff[loopTimeOffKey]['associated_team_type'] = getProp(loopTimeOff, 'associated_team_type', null)
        dayTeamTimeOff[loopTimeOffKey]['associated_team_key'] = getProp(loopTimeOff, 'associated_team_key', null)
        dayTeamTimeOff[loopTimeOffKey]['start_time'] = getProp(loopTimeOff, 'task_completion_scheduled_start_times.' + formattedComparisonDate, 0)
        dayTeamTimeOff[loopTimeOffKey]['end_time'] = getProp(loopTimeOff, 'task_completion_scheduled_end_times.' + formattedComparisonDate, 23.75)
        dayTeamTimeOff[loopTimeOffKey]['status'] = getProp(loopTimeOff, 'status', null)
      }
    }
    let cancelledEvents: TsInterface_UnspecifiedObject = {}
    for (let cancelledEventKey in us_cancelledTaskEvents) {
      let cancelledEvent = us_cancelledTaskEvents[cancelledEventKey]
      if (
        formattedComparisonDate != null &&
        team != null &&
        team['key'] != null &&
        cancelledEvent != null &&
        cancelledEvent['associated_team_key'] != null &&
        cancelledEvent['associated_team_key'] === team['key'] &&
        cancelledEvent['task_completion_scheduled_start_times'] != null &&
        cancelledEvent['task_completion_scheduled_start_times'][formattedComparisonDate] != null
      ) {
        cancelledEvents[cancelledEventKey] = cancelledEvent
        cancelledEvents[cancelledEventKey]['key'] = cancelledEventKey
        cancelledEvents[cancelledEventKey]['name'] = getProp(cancelledEvent, 'name', null)
        cancelledEvents[cancelledEventKey]['associated_team_type'] = getProp(cancelledEvent, 'associated_team_type', null)
        cancelledEvents[cancelledEventKey]['associated_team_key'] = getProp(cancelledEvent, 'associated_team_key', null)
        cancelledEvents[cancelledEventKey]['start_time'] = getProp(cancelledEvent, 'task_completion_scheduled_start_times.' + formattedComparisonDate, 0)
        cancelledEvents[cancelledEventKey]['end_time'] = getProp(cancelledEvent, 'task_completion_scheduled_end_times.' + formattedComparisonDate, 23.75)
        cancelledEvents[cancelledEventKey]['status'] = getProp(cancelledEvent, 'status', null)
      }
    }
    // JSX
    let scheduleEventListViewContentJSX = (
      <Box>
        {objectToArray(dayTeamTimeOff)
          .sort(dynamicSort('start_time', null))
          .map((event, index) => (
            <Box key={index}>{rJSX_FreeformTimeOff(event)}</Box>
          ))}
        {objectToArray(dayTeamEvents)
          .sort(dynamicSort('start_time', null))
          .map((event, index) => (
            <Box key={index}>
              <DraggableAssignedFreeformTimeTask
                task={event}
                type={returnFreeformReassignTaskKey(team.team_type, formattedComparisonDate)}
                childrenJSX={rJSX_WeekEvent(event, team, date, scheduleView, 'freeform', draggable)}
              />
            </Box>
          ))}
        {objectToArray(cancelledEvents)
          .sort(dynamicSort('start_time', null))
          .map((event, index) => (
            <Box key={index}>{rJSX_WeekEvent(event, team, date, scheduleView, 'freeform', draggable)}</Box>
          ))}
      </Box>
    )
    let maybeMapEventsListViewJSX = (
      <Stack
        direction="row"
        sx={{ minWidth: '150px', minHeight: '60px' }}
      >
        {objectToArray(dayTeamEvents)
          .sort(dynamicSort('start_time', null))
          .map((event, index) => (
            <Box
              key={index}
              className="tw-inline-block"
              onMouseEnter={() => {
                us_setHighlightedMapTeam(event.associated_team_key)
                us_setHighlightedMapEvent(event.key)
              }}
              onMouseLeave={() => {
                us_setHighlightedMapTeam(null)
                us_setHighlightedMapEvent(null)
              }}
            >
              <DraggableAssignedFreeformTimeTask
                task={event}
                type={returnFreeformReassignTaskKey(team.team_type, formattedComparisonDate)}
                childrenJSX={rJSX_WeekEvent(event, team, date, scheduleView, 'freeform', draggable)}
              />
            </Box>
          ))}
      </Stack>
    )
    if (draggable === true) {
      if (scheduleView === true) {
        cellContentJSX = (
          <DroppableReassignTeamFreeformSlot
            team={team}
            setLastTaskSwappedTimestamp={us_setLastTaskSwappedTimestamp}
            accept={[returnFreeformReassignTaskKey(team.team_type, formattedComparisonDate)]}
            childrenJSX={
              <DroppableTeamSlot
                definedTimeSlotKey={null}
                taskAssignmentType={'freeform_slots'}
                allScheduledTasks={us_calendarEvents}
                allTeams={us_activeTeams}
                teamTypeSettings={us_teamTypeSettings}
                assignDate={date}
                assignTime={null}
                team={team}
                accept={[team.team_type]}
                sx={{ padding: '3px', minHeight: '65px', width: '100%' }}
                uc_setUserInterface_CustomDialogDisplay={uc_setUserInterface_CustomDialogDisplay}
              >
                {scheduleEventListViewContentJSX}
              </DroppableTeamSlot>
            }
            sx={{}}
          />
        )
      } else {
        cellContentJSX = (
          <Box className="tw-text-left">
            <DroppableReassignTeamFreeformSlot
              team={team}
              setLastTaskSwappedTimestamp={us_setLastTaskSwappedTimestamp}
              accept={[returnFreeformReassignTaskKey(team.team_type, formattedComparisonDate)]}
              childrenJSX={maybeMapEventsListViewJSX}
              sx={{}}
            />
          </Box>
        )
      }
    } else {
      if (scheduleView === true) {
        cellContentJSX = scheduleEventListViewContentJSX
      } else {
        cellContentJSX = maybeMapEventsListViewJSX
      }
    }
    return cellContentJSX
  }

  const rJSX_WeekCellContent_Container = (team: TsInterface_UnspecifiedObject, date: Date, scheduleView: boolean, draggable: boolean): JSX.Element => {
    // Instantiate Variables
    let cellContentJSX: JSX.Element = <></>
    if (
      team != null &&
      team.team_type != null &&
      us_teamTypeSettings != null &&
      us_teamTypeSettings[team.team_type] != null &&
      us_teamTypeSettings[team.team_type]['task_assignment_type'] === 'defined_slots'
    ) {
      cellContentJSX = rJSX_WeekCellContent_DefinedSlots(team, date, scheduleView, draggable)
    } else {
      cellContentJSX = rJSX_WeekCellContent_FreeformSlots(team, date, scheduleView, draggable)
    }
    return cellContentJSX
  }

  const rJSX_TeamWeekRow = (team: TsInterface_UnspecifiedObject): JSX.Element => {
    let rowJSX = <></>
    let dayOfWeek = us_selectedDate.getDay()
    let sunDate = new Date(us_selectedDate.getFullYear(), us_selectedDate.getMonth(), us_selectedDate.getDate() - dayOfWeek)
    let monDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 1)
    let tueDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 2)
    let wedDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 3)
    let thuDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 4)
    let friDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 5)
    let satDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 6)
    let endSunDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 7)
    rowJSX = (
      <>
        <TableCell
          className="tw-align-top"
          sx={{
            width: '200px',
            padding: '6px',
            position: 'sticky',
            left: 0,
            background: themeVariables.background_default,
            borderRight: '1px solid ' + themeVariables.gray_700,
          }}
        >
          {rJSX_TeamCellContent(team)}
        </TableCell>
        {/* <TableCell className="tw-p-0 tw-align-top" sx={ returnSX_WeekCell(0, null) } onDoubleClick={ () => {
					openFreeformTimeOffDialog( team, sunDate )
				}}>
					{ rJSX_WeekCellContent_Container( team, sunDate, true, true ) }
				</TableCell> */}
        <TableCell
          className="tw-p-0 tw-align-top"
          sx={returnSX_WeekCell(1, null)}
          onDoubleClick={() => {
            openFreeformTimeOffDialog(team, monDate)
          }}
        >
          {rJSX_WeekCellContent_Container(team, monDate, true, true)}
        </TableCell>
        <TableCell
          className="tw-p-0 tw-align-top"
          sx={returnSX_WeekCell(2, null)}
          onDoubleClick={() => {
            openFreeformTimeOffDialog(team, tueDate)
          }}
        >
          {rJSX_WeekCellContent_Container(team, tueDate, true, true)}
        </TableCell>
        <TableCell
          className="tw-p-0 tw-align-top"
          sx={returnSX_WeekCell(3, null)}
          onDoubleClick={() => {
            openFreeformTimeOffDialog(team, wedDate)
          }}
        >
          {rJSX_WeekCellContent_Container(team, wedDate, true, true)}
        </TableCell>
        <TableCell
          className="tw-p-0 tw-align-top"
          sx={returnSX_WeekCell(4, null)}
          onDoubleClick={() => {
            openFreeformTimeOffDialog(team, thuDate)
          }}
        >
          {rJSX_WeekCellContent_Container(team, thuDate, true, true)}
        </TableCell>
        <TableCell
          className="tw-p-0 tw-align-top"
          sx={returnSX_WeekCell(5, null)}
          onDoubleClick={() => {
            openFreeformTimeOffDialog(team, friDate)
          }}
        >
          {rJSX_WeekCellContent_Container(team, friDate, true, true)}
        </TableCell>
        <TableCell
          className="tw-p-0 tw-align-top"
          sx={returnSX_WeekCell(6, null)}
          onDoubleClick={() => {
            openFreeformTimeOffDialog(team, satDate)
          }}
        >
          {rJSX_WeekCellContent_Container(team, satDate, true, true)}
        </TableCell>
        <TableCell
          className="tw-p-0 tw-align-top"
          sx={returnSX_WeekCell(7, null)}
          onDoubleClick={() => {
            openFreeformTimeOffDialog(team, endSunDate)
          }}
        >
          {rJSX_WeekCellContent_Container(team, endSunDate, true, true)}
        </TableCell>
      </>
    )
    return rowJSX
  }

  const returnSX_WeekCalendarHeader = (date: Date): TsInterface_UnspecifiedObject => {
    let weekHeaderSX: TsInterface_UnspecifiedObject = { width: '70px' }
    let formattedDate = returnFormattedDate(date, 'YYYYMMDD')
    let formattedToday = returnFormattedDate(new Date(), 'YYYYMMDD')
    if (formattedDate === formattedToday) {
      weekHeaderSX['fontWeight'] = 800
    }
    return weekHeaderSX
  }

  const returnSX_WeekScheduleTableContainer = (): TsInterface_UnspecifiedObject => {
    let tableContainerSX: TsInterface_UnspecifiedObject = {}
    if (umq_weekTableBreakpoint1 === true) {
      tableContainerSX = { height: 'calc(100vh - 570px)' }
    } else if (umq_weekTableBreakpoint2 === true) {
      tableContainerSX = { height: 'calc(100vh - 485px)' }
    } else if (umq_weekTableBreakpoint3 === true) {
      tableContainerSX = { height: 'calc(100vh - 485px)' }
    } else if (umq_weekTableBreakpoint4 === true) {
      tableContainerSX = { height: 'calc(100vh - 440px)' }
    } else if (umq_weekTableBreakpoint5 === true) {
      tableContainerSX = { height: 'calc(100vh - 395px)' }
    } else if (umq_weekTableBreakpoint6 === true) {
      tableContainerSX = { height: 'calc(100vh - 350px)' }
    } else if (umq_weekTableBreakpoint7 === true) {
      tableContainerSX = { height: 'calc(100vh - 310px)' }
    } else {
      tableContainerSX = { height: 'calc(100vh - 265px)' }
    }
    return tableContainerSX
  }

  const returnSX_UnassignedTaskContainer = (): TsInterface_UnspecifiedObject => {
    let unassignedTaskContainerSX: TsInterface_UnspecifiedObject = {
      borderRadius: '5px',
      overflowX: 'scroll',
      padding: '3px',
      borderColor: 'rgba(255,255,255,0.2)',
    }
    if (umq_weekTableBreakpoint1 === true) {
      unassignedTaskContainerSX['height'] = 'calc(100vh - 650px)'
    } else if (umq_weekTableBreakpoint2 === true) {
      unassignedTaskContainerSX['height'] = 'calc(100vh - 565px)'
    } else if (umq_weekTableBreakpoint3 === true) {
      unassignedTaskContainerSX['height'] = 'calc(100vh - 565px)'
    } else if (umq_weekTableBreakpoint4 === true) {
      unassignedTaskContainerSX['height'] = 'calc(100vh - 520px)'
    } else if (umq_weekTableBreakpoint5 === true) {
      unassignedTaskContainerSX['height'] = 'calc(100vh - 475px)'
    } else if (umq_weekTableBreakpoint6 === true) {
      unassignedTaskContainerSX['height'] = 'calc(100vh - 430px)'
    } else if (umq_weekTableBreakpoint7 === true) {
      unassignedTaskContainerSX['height'] = 'calc(100vh - 390px)'
    } else {
      unassignedTaskContainerSX['height'] = 'calc(100vh - 345px)'
    }
    return unassignedTaskContainerSX
  }

  const rJSX_WeekSchedule = (): JSX.Element => {
    let scheduleJSX = <></>
    let dayOfWeek = us_selectedDate.getDay()
    let sunDate = new Date(us_selectedDate.getFullYear(), us_selectedDate.getMonth(), us_selectedDate.getDate() - dayOfWeek)
    let monDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 1)
    let tueDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 2)
    let wedDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 3)
    let thuDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 4)
    let friDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 5)
    let satDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 6)
    let endSunDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 7)
    // JSX
    scheduleJSX = (
      <Card sx={{ border: '1px solid ' + themeVariables.gray_700 }}>
        <TableContainer
          className="bp_show_horizontal_scroll"
          sx={returnSX_WeekScheduleTableContainer()}
        >
          <Table
            size="small"
            stickyHeader={true}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: '200px',
                    padding: '6px',
                    position: 'sticky',
                    left: 0,
                    background: themeVariables.background_default,
                    borderRight: '1px solid ' + themeVariables.gray_700,
                  }}
                >
                  {s_TEAM}
                  <Tooltip
                    title={s_CREATE_NEW_TEAM}
                    placement="bottom-start"
                  >
                    <Box
                      className="tw-inline-block tw-float-right tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100"
                      onClick={() => {
                        openNewTeamDialog()
                      }}
                    >
                      <Icon icon="circle-plus" />
                    </Box>
                  </Tooltip>
                </TableCell>
                {/* <TableCell colSpan={1} className="tw-px-0 tw-text-center" sx={ returnSX_WeekCell( 0, sunDate ) }>
									<Box className="tw-m-auto" sx={ returnSX_WeekCalendarHeader( sunDate ) }>{ s_SUN } { returnFormattedDate( sunDate, "M/D") }</Box>
								</TableCell> */}
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(1, monDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(monDate)}
                  >
                    {s_MON} {returnFormattedDate(monDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(2, tueDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(tueDate)}
                  >
                    {s_TUE} {returnFormattedDate(tueDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(3, wedDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(wedDate)}
                  >
                    {s_WED} {returnFormattedDate(wedDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(4, thuDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(thuDate)}
                  >
                    {s_THU} {returnFormattedDate(thuDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(5, friDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(friDate)}
                  >
                    {s_FRI} {returnFormattedDate(friDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(6, satDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(satDate)}
                  >
                    {s_SAT} {returnFormattedDate(satDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(7, endSunDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(endSunDate)}
                  >
                    {s_SUN} {returnFormattedDate(endSunDate, 'M/D')}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {returnFilteredTeams(us_activeTeams, us_selectedRegion, us_visibleTeamsTypesFilter, us_filteredUserKey, us_calendarEvents).map((team, index) => (
                <TableRow key={index}>{rJSX_TeamWeekRow(team)}</TableRow>
              ))}
            </TableBody>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: '200px',
                    padding: '6px',
                    position: 'sticky',
                    left: 0,
                    background: themeVariables.background_default,
                    borderRight: '1px solid ' + themeVariables.gray_700,
                  }}
                >
                  {s_TEAM}
                  <Tooltip
                    title={s_CREATE_NEW_TEAM}
                    placement="bottom-start"
                  >
                    <Box
                      className="tw-inline-block tw-float-right tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100"
                      onClick={() => {
                        openNewTeamDialog()
                      }}
                    >
                      <Icon icon="circle-plus" />
                    </Box>
                  </Tooltip>
                </TableCell>
                {/* <TableCell colSpan={1} className="tw-px-0 tw-text-center" sx={ returnSX_WeekCell( 0, sunDate ) }>
									<Box className="tw-m-auto" sx={ returnSX_WeekCalendarHeader( sunDate ) }>{ s_SUN } { returnFormattedDate( sunDate, "M/D") }</Box>
								</TableCell> */}
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(1, monDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(monDate)}
                  >
                    {s_MON} {returnFormattedDate(monDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(2, tueDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(tueDate)}
                  >
                    {s_TUE} {returnFormattedDate(tueDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(3, wedDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(wedDate)}
                  >
                    {s_WED} {returnFormattedDate(wedDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(4, thuDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(thuDate)}
                  >
                    {s_THU} {returnFormattedDate(thuDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(5, friDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(friDate)}
                  >
                    {s_FRI} {returnFormattedDate(friDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(6, satDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(satDate)}
                  >
                    {s_SAT} {returnFormattedDate(satDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(7, endSunDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(endSunDate)}
                  >
                    {s_SAT} {returnFormattedDate(endSunDate, 'M/D')}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Card>
    )
    return scheduleJSX
  }

  // Page
  const rJSX_Schedule = (): JSX.Element => {
    let scheduleJSX = <></>
    // if( calendarType === "day" ){
    // 	scheduleJSX = rJSX_DaySchedule()
    // } else if( calendarType === "week" ){
    scheduleJSX = rJSX_WeekSchedule()
    // }
    return scheduleJSX
  }

  const rJSX_DatePicker = (shrinkButton: boolean): JSX.Element => {
    const isDateChanged = () => {
      const currentDate = new Date()
      return us_selectedDate.toDateString() !== currentDate.toDateString() // Compare only the date, ignoring the time
    }

    let buttonJSX = (
      <Box className="tw-inline-block tw-mb-2 tw-align-top">
        <DatePicker
          key={`calendar_date_${us_selectedDate?.toISOString()}`} // Force re-render when `us_selectedDate` changes
          datePickerText={s_DATE}
          datePickerDate={us_selectedDate}
          datePickerDisabled={false}
          datePickerDateOnChange={changeCalendarDate}
          datePickerSettings={{ thin_input: true }}
        />
        {isDateChanged() && ( // Show "X" button only if the date has been changed
          <Box
            className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              const currentDate = new Date()
              us_setSelectedDate(currentDate) // Reset to current date
              changeCalendarDate(currentDate) // Update the DatePicker value
            }}
          >
            <Icon icon="circle-x tw-ml-1 tw-mt-2" />
          </Box>
        )}
      </Box>
    )
    return buttonJSX
  }

  const returnTeamTypeChip = (teamType: string): JSX.Element => {
    let chipJSX = <></>
    if (teamType != null && schedulingTeamTypes != null && schedulingTeamTypes[teamType] != null && schedulingTeamTypes[teamType] != null) {
      let chipText = getProp(schedulingTeamTypes[teamType], 'value', teamType)
      let chipIcon = getProp(schedulingTeamTypes[teamType], 'icon', <Icon icon="square-question" />)
      let chipColor = getProp(schedulingTeamTypes[teamType], 'color', themeVariables.background_json)
      chipJSX = (
        <Chip
          sx={{ backgroundColor: chipColor }}
          label={
            <>
              <Box className="tw-mr-1 tw-inline-block">{chipIcon}</Box>
              {chipText}
            </>
          }
        />
      )
    } else {
      chipJSX = <Chip label={teamType} />
    }
    return chipJSX
  }

  const rJSX_FilterTeamDropdown = (shrinkButton: boolean): JSX.Element => {
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top">
        <FormControl
          className="tw-ml-2 bp_thin_select_input bp_thin_select_multiple_input tw-mb-2"
          sx={{ minWidth: '130px', maxWidth: '130px' }}
        >
          <InputLabel id={'team_filter'}>{s_TEAM_FILTER}</InputLabel>
          <Select
            // className="bp_thin_select_input"
            autoWidth={true}
            id={'team_filter'}
            labelId={'team_filter'}
            multiple
            value={us_visibleTeamsTypesFilter}
            label={<Box>{s_TEAM_FILTER}</Box>}
            onChange={(event: SelectChangeEvent<typeof us_visibleTeamsTypesFilter>) => {
              if (us_selectedTab === 'map' || us_selectedTab === 'config') {
                let newArray = returnArrayUniqueValues(event.target.value, us_visibleTeamsTypesFilter)
                us_setVisibleTeamsTypesFilter(newArray)
              } else {
                const {
                  target: { value },
                } = event
                us_setVisibleTeamsTypesFilter(
                  // On autofill we get a stringified value.
                  typeof value === 'string' ? value.split(',') : value,
                )
              }
            }}
            renderValue={(selected) => {
              let displayJSX = (
                <Box>
                  {selected.map((teamKey: string, index: number) => (
                    <Box
                      key={index}
                      className="tw-inline-block tw-mr-1"
                    >
                      {returnTeamTypeChip(teamKey)}
                    </Box>
                  ))}
                </Box>
              )
              return displayJSX
            }}
          >
            {objectToArray(schedulingTeamTypes)
              .sort(dynamicSort('key', 'asc'))
              .map((teamType: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={teamType.key}
                >
                  <Checkbox checked={us_visibleTeamsTypesFilter.indexOf(teamType.key) > -1} />
                  {teamType.value}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Box
          className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
          onClick={() => {
            us_setVisibleTeamsTypesFilter([])
          }}
        >
          <Icon icon="circle-x tw-ml-1 tw-mt-2" />
        </Box>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_RegionDropdown = (shrinkButton: boolean): JSX.Element => {
    // JSX
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top tw-ml-2">
        <FormControl
          className="bp_thin_select_input"
          sx={{ maxWidth: '120px' }}
        >
          <Select
            color="primary"
            value={us_selectedRegion}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSelectedRegion(event.target.value)
                setPageLocalStorage(pageKey, 'us_selectedRegion', event.target.value)
              }
            }}
            variant="outlined"
          >
            <MenuItem value={'ALL_REGIONS'}>{s_ALL_REGIONS}</MenuItem>
            {objectToArray(us_regionLists)
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['name']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_FilterUserDropdown = (shrinkButton: boolean): JSX.Element => {
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top">
        <FormControl
          className="tw-ml-2 bp_thin_select_input bp_thin_select_multiple_input tw-mb-2"
          sx={{ minWidth: '130px', maxWidth: '130px' }}
        >
          <InputLabel id={'userFilter'}>{s_USER_FILTER}</InputLabel>
          <Select
            // className="bp_thin_select_input"
            autoWidth={true}
            id={'userFilter'}
            labelId={'userFilter'}
            value={us_filteredUserKey}
            label={<Box>{s_USER_FILTER}</Box>}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setFilteredUserKey(event.target.value)
              }
            }}
          >
            {objectToArray(us_combinedUserOptions)
              .sort(dynamicSort('name', 'asc'))
              .map((user: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={user.key}
                >
                  {user.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Box
          className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
          onClick={() => {
            us_setFilteredUserKey('')
          }}
        >
          <Icon icon="circle-x tw-ml-1 tw-mt-2" />
        </Box>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_ViewUserToggle = (shrinkButton: boolean): JSX.Element => {
    let textJSX = <></>
    let toggleJSX = <></>
    if (us_viewUsersOnCalendar === true) {
      textJSX = s_USERS_VISIBLE
    } else {
      textJSX = s_USERS_HIDDEN
    }
    if (shrinkButton === true) {
      toggleJSX = (
        <Tooltip
          title={textJSX}
          placement="top"
        >
          <Box
            className="tw-inline-block tw-ml-2 tw-rounded tw-mb-2 tw-align-top"
            sx={{ border: '1px solid rgba(255,255,255,0.25)' }}
          >
            <Stack
              direction="row"
              spacing={1}
            >
              <Switch
                sx={{ height: '36px', marginTop: '-1px', marginBottom: '-1px' }}
                checked={us_viewUsersOnCalendar}
                onChange={(event, value) => {
                  if (value === true || value === false) {
                    us_setViewUsersOnCalendar(value)
                  }
                }}
              />
            </Stack>
          </Box>
        </Tooltip>
      )
    } else {
      toggleJSX = (
        <Box
          className="tw-inline-block tw-ml-2 tw-rounded tw-mb-2 tw-align-top"
          sx={{ border: '1px solid rgba(255,255,255,0.25)' }}
        >
          <Stack
            direction="row"
            spacing={1}
          >
            <Switch
              sx={{ height: '36px', marginTop: '-1px', marginBottom: '-1px' }}
              checked={us_viewUsersOnCalendar}
              onChange={(event, value) => {
                if (value === true || value === false) {
                  us_setViewUsersOnCalendar(value)
                }
              }}
            />
            <Typography
              variant="body1"
              className="tw-mt-1 tw-ml-0 tw-mr-2"
            >
              {textJSX}
            </Typography>
          </Stack>
        </Box>
      )
    }
    return toggleJSX
  }

  const rJSX_ViewNotesToggle = (shrinkButton: boolean): JSX.Element => {
    let textJSX = <></>
    let toggleJSX = <></>
    if (us_viewNotesOnCalendar === true) {
      textJSX = s_NOTES_VISIBLE
    } else {
      textJSX = s_NOTES_HIDDEN
    }
    if (shrinkButton === true) {
      toggleJSX = (
        <Tooltip
          title={textJSX}
          placement="top"
        >
          <Box
            className="tw-inline-block tw-ml-2 tw-mr-2 tw-mb-2 tw-rounded tw-align-top"
            sx={{ border: '1px solid rgba(255,255,255,0.25)' }}
          >
            <Stack
              direction="row"
              spacing={1}
            >
              <Switch
                sx={{ height: '36px', marginTop: '-1px', marginBottom: '-1px' }}
                checked={us_viewNotesOnCalendar}
                onChange={(event, value) => {
                  if (value === true || value === false) {
                    us_setViewNotesOnCalendar(value)
                  }
                }}
              />
            </Stack>
          </Box>
        </Tooltip>
      )
    } else {
      toggleJSX = (
        <Box
          className="tw-inline-block tw-ml-2 tw-mr-2 tw-mb-2 tw-rounded tw-align-top"
          sx={{ border: '1px solid rgba(255,255,255,0.25)' }}
        >
          <Stack
            direction="row"
            spacing={1}
          >
            <Switch
              sx={{ height: '36px', marginTop: '-1px', marginBottom: '-1px' }}
              checked={us_viewNotesOnCalendar}
              onChange={(event, value) => {
                if (value === true || value === false) {
                  us_setViewNotesOnCalendar(value)
                }
              }}
            />
            <Typography
              variant="body1"
              className="tw-mt-1 tw-ml-0 tw-mr-2"
            >
              {textJSX}
            </Typography>
          </Stack>
        </Box>
      )
    }
    return toggleJSX
  }

  const rJSX_TeamActivityToggle = (shrinkButton: boolean): JSX.Element => {
    let textJSX = <></>
    let toggleJSX = <></>
    if (us_showActiveTeams === false) {
      textJSX = s_INACTIVE_TEAMS
    } else {
      textJSX = s_ACTIVE_TEAMS
    }
    if (shrinkButton === true) {
      toggleJSX = (
        <Tooltip
          title={textJSX}
          placement="top"
        >
          <Box
            className="tw-inline-block tw-mr-2 tw-mb-2 tw-rounded tw-align-top"
            sx={{ border: '1px solid rgba(255,255,255,0.25)' }}
          >
            <Stack
              direction="row"
              spacing={1}
            >
              <Switch
                sx={{ height: '36px', marginTop: '-1px', marginBottom: '-1px' }}
                checked={us_showActiveTeams}
                onChange={(event, value) => {
                  if (value === true || value === false) {
                    us_setShowActiveTeams(value)
                  }
                }}
              />
            </Stack>
          </Box>
        </Tooltip>
      )
    } else {
      toggleJSX = (
        <Box
          className="tw-inline-block tw-mr-2 tw-mb-2 tw-rounded tw-align-top"
          sx={{ border: '1px solid rgba(255,255,255,0.25)' }}
        >
          <Stack
            direction="row"
            spacing={1}
          >
            <Switch
              sx={{ height: '36px', marginTop: '-1px', marginBottom: '-1px' }}
              checked={us_showActiveTeams}
              onChange={(event, value) => {
                if (value === true || value === false) {
                  us_setShowActiveTeams(value)
                }
              }}
            />
            <Typography
              variant="body1"
              className="tw-mt-1 tw-ml-0 tw-mr-2"
            >
              {textJSX}
            </Typography>
          </Stack>
        </Box>
      )
    }
    return toggleJSX
  }

  const rJSX_WeekScheduleTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_showActiveTeams === true) {
      tabJSX = (
        <Box>
          <Grid2
            container
            spacing={2}
          >
            <Grid2
              xs={3}
              sm={3}
              md={2}
              lg={2}
            >
              <Box className="tw-mt-2">{rJSX_UnassignedTasks()}</Box>
            </Grid2>
            <Grid2
              xs={9}
              sm={9}
              md={10}
              lg={10}
            >
              <Box className="tw-mt-2">{rJSX_Schedule()}</Box>
            </Grid2>
          </Grid2>
        </Box>
      )
    } else {
      tabJSX = (
        <Box>
          <Grid2
            container
            spacing={2}
          >
            <Grid2
              xs={3}
              sm={3}
              md={2}
              lg={2}
            >
              <Box className="tw-mt-2 tw-text-center tw-italic tw-opacity-30">{s_VIEWING_INACTIVE_TEAMS}</Box>
            </Grid2>
            <Grid2
              xs={9}
              sm={9}
              md={10}
              lg={10}
            >
              <Box className="tw-mt-2">{rJSX_Schedule()}</Box>
            </Grid2>
          </Grid2>
        </Box>
      )
    }
    return tabJSX
  }

  // Day Schedule
  const rJSX_DayTeamHeader = (team: TsInterface_UnspecifiedObject): JSX.Element => {
    let teamCellJSX = <></>
    if (team != null && team.team_type != null && schedulingTeamTypes != null && schedulingTeamTypes[team.team_type] != null) {
      let teamBackgroundColor = getProp(schedulingTeamTypes[team.team_type], 'color', themeVariables.background_json)
      teamCellJSX = (
        // let ateamCellJSX =
        <Box>
          <Typography
            variant="h6"
            sx={{ color: teamBackgroundColor, marginBottom: '-6px' }}
            className="tw-inline-block"
          >
            <Box className="tw-mr-1 tw-inline-block">
              <Tooltip
                title={getProp(schedulingTeamTypes[team.team_type], 'value', team.team_type)}
                placement="right"
              >
                <Box>{schedulingTeamTypes[team.team_type]['icon']}</Box>
              </Tooltip>
            </Box>
            {team.name}
          </Typography>
          <Box
            className="tw-inline-block tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={(event) => {
              openTeamDialog(team, ur_forceRerender)
            }}
          >
            <Icon icon="pen-to-square" />
          </Box>
          <Box className="tw-ml-6">
            <Box className="tw-opacity-30 tw-inline-block">
              {objectToArray(team.associated_member_names)
                .sort()
                .map((user, index) => (
                  <Box
                    key={index}
                    className="tw-inline-block tw-mr-2"
                  >
                    {user}
                    {index < objectToArray(team.associated_member_names).length - 1 ? ', ' : ''}
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      )
    }
    return teamCellJSX
  }

  const rJSX_DayScheduleTab = (): JSX.Element => {
    let tabJSX = <></>
    // JSX
    tabJSX = (
      <Box className="tw-max-w-xl tw-m-auto">
        {returnFilteredTeams(us_activeTeams, us_selectedRegion, us_visibleTeamsTypesFilter, us_filteredUserKey, us_calendarEvents).map((team, index) => (
          <Box key={index}>
            {rJSX_DayTeamHeader(team)}
            <Box className="tw-ml-6">{rJSX_WeekCellContent_Container(team, us_selectedDate, true, false)}</Box>
            <Divider className="tw-my-2" />
          </Box>
        ))}
      </Box>
    )
    return tabJSX
  }

  // Map Tab
  const rJSX_MapView = (): JSX.Element => {
    let mapViewJSX = <></>
    let mapBasicSettings: TsInterface_MapSettings = {
      center_lat: 0,
      center_lng: 0,
      height: 'calc(100vh - 260px)',
      render_clusters: false,
      render_heatmap: false,
      render_markers: true,
      width: '100%',
      zoom: 12,
    }
    // Unusued Map On Click
    const mapBasicOnClick: TsType_MapOnClick = (lat: number, lng: number) => {
      // Nothing
    }
    // Instantiate Variables
    let selectedTeams = returnFilteredTeams(us_activeTeams, us_selectedRegion, us_visibleTeamsTypesFilter, us_filteredUserKey, us_calendarEvents)
    let allEventsWithCoordinates: TsInterface_UnspecifiedObject = []
    let allEventsWithoutCoordinates: TsInterface_UnspecifiedObject = []
    let mapBasicMarkers: TsInterface_MapMarkers = {}
    // Loop through visible teams
    for (let loopTeamIndex in selectedTeams) {
      let loopTeam = selectedTeams[loopTeamIndex]
      // Other Icons
      for (let loopLocationKey in us_additionallMapLocations) {
        let loopLocation = us_additionallMapLocations[loopLocationKey]
        if (
          loopLocation != null &&
          loopLocation['location_latitude'] != null &&
          loopLocation['location_longitude'] != null &&
          loopLocation['icon_path'] != null &&
          loopLocation['icon_color'] != null
        ) {
          mapBasicMarkers['LOCATION_' + loopLocation.key] = {
            position: {
              lat: getProp(loopLocation, 'location_latitude', null),
              lng: getProp(loopLocation, 'location_longitude', null),
            },
            icon: {
              path: returnMapIcons(loopLocation['icon_path'])['path'],
              fillColor: getProp(themeVariables, loopLocation['icon_color'], null),
              fillOpacity: 1,
              strokeWeight: 0,
              rotation: 0,
              scale: returnMapIcons(loopLocation['icon_path'])['scale'],
              anchor: returnMapIcons(loopLocation['icon_path'])['anchor'],
            },
            label: '',
          }
        }
      }
      // Filtered Events for Loop Team
      let filteredTeamEventData = getProp(returnFormattedTeamScheduleData(pr_dateKey, loopTeam.key, objectToArray(us_calendarEvents)), 'data', [])
      for (let filteredEventLoopIndex in filteredTeamEventData) {
        let loopEvent = filteredTeamEventData[filteredEventLoopIndex]
        if (loopEvent.location_latitude != null && loopEvent.location_longitude != null) {
          allEventsWithCoordinates.push(loopEvent)
        } else {
          allEventsWithoutCoordinates.push(loopEvent)
        }
      }
    }
    // Loop through and add pins to map
    for (let loopEventIndex in allEventsWithCoordinates) {
      let loopEvent = allEventsWithCoordinates[loopEventIndex]
      // Icon
      let iconColor = themeVariables.error_light
      if (us_highlighedMapEvent === loopEvent.key) {
        iconColor = themeVariables.success_main
      } else if (us_highlighedMapTeam === loopEvent.associated_team_key) {
        iconColor = themeVariables.info_light
      }
      let icon: any = {
        path: returnMapIcons('pin')['path'],
        fillColor: iconColor,
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: returnMapIcons('pin')['scale'],
        anchor: returnMapIcons('pin')['anchor'],
      }
      mapBasicMarkers[loopEvent.key] = {
        position: {
          lat: getProp(loopEvent, 'location_latitude', null),
          lng: getProp(loopEvent, 'location_longitude', null),
        },
        icon: icon,
        label: '',
        onDblClick: () => {
          openAssignedEventDialog(loopEvent)
        },
        onMouseOver: () => {
          us_setHighlightedMapTeam(loopEvent.associated_team_key)
          us_setHighlightedMapEvent(loopEvent.key)
        },
        onMouseOut: () => {
          us_setHighlightedMapTeam(null)
          us_setHighlightedMapEvent(null)
        },
      }
    }
    // Resize Map Bounds
    setTimeout(() => {
      if (
        ur_mapRef != null &&
        ur_mapRef.current != null &&
        // @ts-expect-error - TODO: reason for error
        ur_mapRef.current.mapCenter != null &&
        // @ts-expect-error - TODO: reason for error
        ur_mapRef.current.mapCenter.lat === 0 &&
        // @ts-expect-error - TODO: reason for error
        ur_mapRef.current.mapCenter.lng === 0
      ) {
        // @ts-expect-error - TODO: reason for error
        if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.recalculateMapBounds != null) {
          // @ts-expect-error - TODO: reason for error
          ur_mapRef.current.recalculateMapBounds(true)
        }
      }
    }, 0)
    // Generate Filtered Events
    if (selectedTeams.length === 0) {
      mapViewJSX = (
        <Box className="tw-text-center">
          <Typography variant="h6">
            {s_NO_ACTIVE} {schedulingTeamTypes[us_visibleTeamsTypesFilter[0]].value} {s_TEAMS}
          </Typography>
        </Box>
      )
    } else {
      mapViewJSX = (
        <Box>
          <PanelGroup direction="horizontal">
            <Panel
              defaultSize={50}
              minSize={20}
            >
              <Card sx={{ overflow: 'scroll', height: 'calc(100vh - 260px)' }}>
                <Box className="bp_table_va_top">
                  <TableContainer className="bp_show_horizontal_scroll">
                    <Table size="small">
                      <TableBody>
                        {objectToArray(selectedTeams).map((team, index) => (
                          <TableRow key={index}>
                            <TableCell
                              sx={{
                                width: '200px',
                                padding: '6px',
                                position: 'sticky',
                                left: 0,
                                background: themeVariables.background_default,
                                borderRight: '1px solid ' + themeVariables.gray_700,
                              }}
                            >
                              <Box
                                onMouseEnter={() => {
                                  us_setHighlightedMapTeam(team.key as string)
                                }}
                                onMouseLeave={() => {
                                  us_setHighlightedMapTeam(null)
                                }}
                              >
                                {rJSX_TeamCellContent(team)}
                              </Box>
                            </TableCell>
                            <TableCell>{rJSX_WeekCellContent_Container(team, us_selectedDate, false, true)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Card>
            </Panel>
            <PanelResizeHandle className="ResizeHandleOuter">
              <Box className="ResizeHandleInner">
                <svg
                  className="ResizeHandleIcon"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M18,16V13H15V22H13V2H15V11H18V8L22,12L18,16M2,12L6,16V13H9V22H11V2H9V11H6V8L2,12Z"
                  />
                </svg>
              </Box>
            </PanelResizeHandle>
            <Panel minSize={20}>
              <Box>
                <MapBasic
                  ref={ur_mapRef}
                  mapSettings={mapBasicSettings}
                  mapMarkers={mapBasicMarkers}
                  mapOnClick={mapBasicOnClick}
                />
              </Box>
            </Panel>
          </PanelGroup>
        </Box>
      )
    }
    return mapViewJSX
  }

  const rJSX_TeamTypeSelection = (): JSX.Element => {
    let teamSelectionJSX = (
      <Box>
        <Box className="tw-text-center tw-py-4">
          <Typography variant="h6">{s_CHOOSE_A_TEAM_TYPE}</Typography>
        </Box>
        <Box className="tw-text-center">
          {objectToArray(schedulingTeamTypes).map((team, index) => (
            <Button
              variant="contained"
              color="inherit"
              className="tw-mr-2 tw-mb-2"
              sx={{
                'background': team.color,
                '&:hover': {
                  background: team.color,
                },
              }}
              key={index}
              onClick={() => {
                let teamTypeFilter: string[] = []
                teamTypeFilter.push(team.key)
                //
                us_setVisibleTeamsTypesFilter(teamTypeFilter)
              }}
            >
              <Box className="tw-inline-block tw-mr-1">{team.icon}</Box>
              <Box className="tw-inline-block">{team.value}</Box>
            </Button>
          ))}
        </Box>
      </Box>
    )
    return teamSelectionJSX
  }

  const rJSX_MapTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_visibleTeamsTypesFilter.length === 1) {
      tabJSX = <Box>{rJSX_MapView()}</Box>
    } else {
      // Make user select a single filtered team type
      tabJSX = rJSX_TeamTypeSelection()
    }
    return tabJSX
  }

  // Config Tab
  const rJSX_AddTimeSlotButton = (teamTypeKey: string, seasonType: 'winter' | 'summer'): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        variant="contained"
        color="success"
        className="tw-mr-2 tw-mb-2"
        startIcon={<Icon icon="circle-plus" />}
        onClick={() => {
          const FormDialogObject: TsInterface_FormDialogObject = {
            form: {
              formAdditionalData: {},
              formData: {},
              formInputs: {
                window_start_time: {
                  data_type: 'string',
                  input_type: 'multiple_choice_select',
                  key: 'window_start_time',
                  label: s_WINDOW_START_TIME,
                  required: true,
                  options: objectToArray(windowTimeOptions),
                },
                window_end_time: {
                  data_type: 'string',
                  input_type: 'multiple_choice_select',
                  key: 'window_end_time',
                  label: s_WINDOW_END_TIME,
                  required: true,
                  options: objectToArray(windowTimeOptions),
                },
              },
              formOnChange: (
                formAdditionalData: TsInterface_FormAdditionalData,
                formData: TsInterface_FormData,
                formInputs: TsInterface_FormInputs,
                formSettings: TsInterface_FormSettings,
              ) => {},
              formSettings: {},
              formSubmission: (
                formSubmittedData: TsInterface_FormSubmittedData,
                formAdditionalData: TsInterface_FormAdditionalData,
                formHooks: TsInterface_FormHooksObject,
              ) => {
                return new Promise((resolve, reject) => {
                  let updateObject: TsInterface_UnspecifiedObject = { task_assignment_slots: {} }
                  let newTimeSlotButtonKey = formSubmittedData.window_start_time.toString() + '-' + formSubmittedData.window_end_time.toString()
                  updateObject['task_assignment_slots'][seasonType] = {}
                  updateObject['task_assignment_slots'][seasonType][newTimeSlotButtonKey] = {
                    key: newTimeSlotButtonKey,
                    window_start_time: formSubmittedData.window_start_time,
                    window_end_time: formSubmittedData.window_end_time,
                  }
                  setMergeTeamTypeSettings(teamTypeKey, updateObject)
                    .then((res_SMTTS) => {
                      resolve({ success: true })
                    })
                    .catch((rej_SMTTS) => {
                      reject({ success: false })
                    })
                })
              },
            },
            dialog: {
              formDialogHeaderColor: 'success',
              formDialogHeaderText: s_ADD_TIME_SLOT,
              formDialogIcon: <Icon icon="plus-circle" />,
            },
          }
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: FormDialogObject,
          })
        }}
      >
        {s_ADD_TIME_SLOT}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_DefinedTimeslotSchedule = (seasonType: 'winter' | 'summer', teamTypeKey: string, teamTypeSettings: TsInterface_UnspecifiedObject): JSX.Element => {
    let scheduledJSX = <></>
    if (
      teamTypeSettings.task_assignment_slots == null ||
      teamTypeSettings.task_assignment_slots[seasonType] == null ||
      objectToArray(teamTypeSettings.task_assignment_slots[seasonType]).length === 0
    ) {
      scheduledJSX = (
        <Box className="tw-text-left">
          {rJSX_AddTimeSlotButton(teamTypeKey, seasonType)}
          <Button
            variant="outlined"
            color="inherit"
            disabled={true}
            className="tw-mr-2 tw-mb-2"
          >
            <Icon
              icon="circle-exclamation"
              className="tw-mr-2"
            ></Icon>
            {s_NO_TIME_SLOTS_ADDED_YET}
          </Button>
        </Box>
      )
    } else {
      scheduledJSX = (
        <Box className="tw-text-left">
          {rJSX_AddTimeSlotButton(teamTypeKey, seasonType)}
          {objectToArray(teamTypeSettings.task_assignment_slots[seasonType])
            .sort(dynamicSort('window_start_time', null))
            .map((window: TsInterface_UnspecifiedObject, index: number) => (
              <Box
                key={index}
                className="tw-inline-block"
              >
                <Button
                  variant="outlined"
                  color="inherit"
                  className="tw-mr-2 tw-mb-2"
                >
                  <Icon
                    icon="clock"
                    className="tw-mr-2"
                  ></Icon>
                  {formatTime(window.window_start_time)} {s_TO} {formatTime(window.window_end_time)}
                </Button>
                <Box
                  className="tw-inline-block tw-opacity-20 hover:tw-opacity-100 tw-cursor-pointer tw-mr-2"
                  onClick={() => {
                    if (us_allowTeamTimeslotDeletion === true) {
                      deleteTeamTimeSlot(teamTypeKey, seasonType, window)
                    }
                  }}
                >
                  <Icon icon="trash" />
                </Box>
              </Box>
            ))}
        </Box>
      )
    }
    return scheduledJSX
  }

  const rJSX_DstDates = (seasonType: 'winter' | 'summer'): JSX.Element => {
    let dateJSX = <></>
    let dstDateCutoffs = getDaylightSavingDates(us_selectedDate)
    let fullDates = calculateSeasons(dstDateCutoffs.dst_start, dstDateCutoffs.dst_end)
    let winter1StartDate = fullDates.winter1_start
    let winter1EndDate = fullDates.winter1_end
    let summerStartDate = fullDates.summer1_start
    let summerEndDate = fullDates.summer1_end
    let winter2StartDate = fullDates.winter2_start
    let winter2EndDate = fullDates.winter2_end
    if (seasonType === 'winter') {
      dateJSX = (
        <Box className="tw-inline-block">
          <Box>
            {winter1StartDate} - {winter1EndDate}
          </Box>
          <Box>
            {winter2StartDate} - {winter2EndDate}
          </Box>
        </Box>
      )
    } else if (seasonType === 'summer') {
      dateJSX = (
        <Box className="tw-inline-block">
          {summerStartDate} - {summerEndDate}
        </Box>
      )
    }
    return dateJSX
  }

  const rJSX_WindowTimeSlotAvailabilityToggle = (
    teamScheduledTimeOn: TsInterface_UnspecifiedObject,
    window: TsInterface_UnspecifiedObject,
    dayOfWeekKey: string,
  ): JSX.Element => {
    let windowToggleJSX = <></>
    if (
      window != null &&
      window.key != null &&
      teamScheduledTimeOn != null &&
      teamScheduledTimeOn[us_selectedConfigSeason] != null &&
      teamScheduledTimeOn[us_selectedConfigSeason][dayOfWeekKey] != null &&
      teamScheduledTimeOn[us_selectedConfigSeason][dayOfWeekKey][window.key] === true
    ) {
      windowToggleJSX = (
        <Box className="tw-mb-2">
          <Button
            variant="contained"
            color="success"
            startIcon={<Icon icon="traffic-light-go" />}
            onClick={() => {
              // Create Update Object
              let updateObject: TsInterface_UnspecifiedObject = {}
              updateObject[us_selectedConfigSeason] = {}
              updateObject[us_selectedConfigSeason][dayOfWeekKey] = {}
              updateObject[us_selectedConfigSeason][dayOfWeekKey][window.key] = false
              // Save Update
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  if (us_selectedConfigTeam != null) {
                    DatabaseSetMergeDocument(DatabaseRef_ScheduledTeamTimeOn_Document(res_GCK.clientKey, us_selectedConfigTeam), updateObject)
                      .then((res_DSMD) => {
                        // Nothing
                      })
                      .catch((rej_DSMD) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  } else {
                    uc_setUserInterface_ErrorDialogDisplay({
                      display: true,
                      error: {
                        message: s_FAILED_TO_UPDATE_TIME_SLOT_AVAILABILITY,
                        details: s_MISSING_REQUIRED_PARAMETERS,
                        code: 'ER-D-TDI-RJWTSAT-01',
                      },
                    })
                  }
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            }}
          >
            {formatTime(window.window_start_time)} {s_TO} {formatTime(window.window_end_time)}
          </Button>
        </Box>
      )
    } else {
      windowToggleJSX = (
        <Box className="tw-mb-2">
          <Button
            variant="outlined"
            color="error"
            startIcon={<Icon icon="octagon-xmark" />}
            onClick={() => {
              // Create Update Object
              let updateObject: TsInterface_UnspecifiedObject = {}
              updateObject[us_selectedConfigSeason] = {}
              updateObject[us_selectedConfigSeason][dayOfWeekKey] = {}
              updateObject[us_selectedConfigSeason][dayOfWeekKey][window.key] = true
              // Save Update
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  if (us_selectedConfigTeam != null) {
                    DatabaseSetMergeDocument(DatabaseRef_ScheduledTeamTimeOn_Document(res_GCK.clientKey, us_selectedConfigTeam), updateObject)
                      .then((res_DSMD) => {
                        // Nothing
                      })
                      .catch((rej_DSMD) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  } else {
                    uc_setUserInterface_ErrorDialogDisplay({
                      display: true,
                      error: {
                        message: s_FAILED_TO_UPDATE_TIME_SLOT_AVAILABILITY,
                        details: s_MISSING_REQUIRED_PARAMETERS,
                        code: 'ER-D-TDI-RJWTSAT-01',
                      },
                    })
                  }
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            }}
          >
            {formatTime(window.window_start_time)} {s_TO} {formatTime(window.window_end_time)}
          </Button>
        </Box>
      )
    }
    return windowToggleJSX
  }

  const rJSX_SelectedConfigTeam = (teamTypeSettings: TsInterface_UnspecifiedObject): JSX.Element => {
    let teamJSX = <></>
    if (us_selectedConfigTeam != null) {
      let scheduledTimeOn = getProp(us_scheduledTeamTimeOnSettings, us_selectedConfigTeam, {})
      teamJSX = (
        <Box className="tw-mt-2">
          <TableContainer className="bp_show_horizontal_scroll">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className="tw-px-0 tw-text-center">
                    <Box className="tw-m-auto">{s_SUN}</Box>
                  </TableCell>
                  <TableCell className="tw-px-0 tw-text-center">
                    <Box className="tw-m-auto">{s_MON}</Box>
                  </TableCell>
                  <TableCell className="tw-px-0 tw-text-center">
                    <Box className="tw-m-auto">{s_TUE}</Box>
                  </TableCell>
                  <TableCell className="tw-px-0 tw-text-center">
                    <Box className="tw-m-auto">{s_WED}</Box>
                  </TableCell>
                  <TableCell className="tw-px-0 tw-text-center">
                    <Box className="tw-m-auto">{s_THU}</Box>
                  </TableCell>
                  <TableCell className="tw-px-0 tw-text-center">
                    <Box className="tw-m-auto">{s_FRI}</Box>
                  </TableCell>
                  <TableCell className="tw-px-0 tw-text-center">
                    <Box className="tw-m-auto">{s_SAT}</Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className="tw-px-0 tw-text-center">
                    <Box className="tw-m-auto">
                      {objectToArray(teamTypeSettings.task_assignment_slots[us_selectedConfigSeason])
                        .sort(dynamicSort('window_start_time', null))
                        .map((window: TsInterface_UnspecifiedObject, index: number) => (
                          <Box
                            key={index}
                            className="tw-inline-block tw-mr-4"
                          >
                            {rJSX_WindowTimeSlotAvailabilityToggle(scheduledTimeOn, window, 'sun')}
                          </Box>
                        ))}
                    </Box>
                  </TableCell>
                  <TableCell className="tw-px-0 tw-text-center">
                    <Box className="tw-m-auto">
                      {objectToArray(teamTypeSettings.task_assignment_slots[us_selectedConfigSeason])
                        .sort(dynamicSort('window_start_time', null))
                        .map((window: TsInterface_UnspecifiedObject, index: number) => (
                          <Box
                            key={index}
                            className="tw-inline-block tw-mr-4"
                          >
                            {rJSX_WindowTimeSlotAvailabilityToggle(scheduledTimeOn, window, 'mon')}
                          </Box>
                        ))}
                    </Box>
                  </TableCell>
                  <TableCell className="tw-px-0 tw-text-center">
                    <Box className="tw-m-auto">
                      {objectToArray(teamTypeSettings.task_assignment_slots[us_selectedConfigSeason])
                        .sort(dynamicSort('window_start_time', null))
                        .map((window: TsInterface_UnspecifiedObject, index: number) => (
                          <Box
                            key={index}
                            className="tw-inline-block tw-mr-4"
                          >
                            {rJSX_WindowTimeSlotAvailabilityToggle(scheduledTimeOn, window, 'tue')}
                          </Box>
                        ))}
                    </Box>
                  </TableCell>
                  <TableCell className="tw-px-0 tw-text-center">
                    <Box className="tw-m-auto">
                      {objectToArray(teamTypeSettings.task_assignment_slots[us_selectedConfigSeason])
                        .sort(dynamicSort('window_start_time', null))
                        .map((window: TsInterface_UnspecifiedObject, index: number) => (
                          <Box
                            key={index}
                            className="tw-inline-block tw-mr-4"
                          >
                            {rJSX_WindowTimeSlotAvailabilityToggle(scheduledTimeOn, window, 'wed')}
                          </Box>
                        ))}
                    </Box>
                  </TableCell>
                  <TableCell className="tw-px-0 tw-text-center">
                    <Box className="tw-m-auto">
                      {objectToArray(teamTypeSettings.task_assignment_slots[us_selectedConfigSeason])
                        .sort(dynamicSort('window_start_time', null))
                        .map((window: TsInterface_UnspecifiedObject, index: number) => (
                          <Box
                            key={index}
                            className="tw-inline-block tw-mr-4"
                          >
                            {rJSX_WindowTimeSlotAvailabilityToggle(scheduledTimeOn, window, 'thu')}
                          </Box>
                        ))}
                    </Box>
                  </TableCell>
                  <TableCell className="tw-px-0 tw-text-center">
                    <Box className="tw-m-auto">
                      {objectToArray(teamTypeSettings.task_assignment_slots[us_selectedConfigSeason])
                        .sort(dynamicSort('window_start_time', null))
                        .map((window: TsInterface_UnspecifiedObject, index: number) => (
                          <Box
                            key={index}
                            className="tw-inline-block tw-mr-4"
                          >
                            {rJSX_WindowTimeSlotAvailabilityToggle(scheduledTimeOn, window, 'fri')}
                          </Box>
                        ))}
                    </Box>
                  </TableCell>
                  <TableCell className="tw-px-0 tw-text-center">
                    <Box className="tw-m-auto">
                      {objectToArray(teamTypeSettings.task_assignment_slots[us_selectedConfigSeason])
                        .sort(dynamicSort('window_start_time', null))
                        .map((window: TsInterface_UnspecifiedObject, index: number) => (
                          <Box
                            key={index}
                            className="tw-inline-block tw-mr-4"
                          >
                            {rJSX_WindowTimeSlotAvailabilityToggle(scheduledTimeOn, window, 'sat')}
                          </Box>
                        ))}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )
    }
    return teamJSX
  }

  const rJSX_ConfigSettings = (teamTypeKey: string, teamTypeSettings: TsInterface_UnspecifiedObject): JSX.Element => {
    let settingsJSX = <></>
    if (teamTypeSettings.task_assignment_type === 'defined_slots') {
      settingsJSX = (
        <Box>
          <Box className="tw-mb-2">
            <Box
              sx={{ color: themeVariables.primary_main, fontSize: '20px' }}
              className="tw-inline-block"
            >
              <Icon
                icon="calendar"
                className="tw-mr-2"
              />
            </Box>
            <Typography
              variant="h6"
              className="tw-inline-block tw-mr-2 tw-font-bold"
            >
              {s_TASK_ASSIGNMENT_TYPE}:
            </Typography>
            <Typography
              variant="h6"
              className="tw-inline-block tw-opacity-50 tw-font-normal"
            >
              {s_DEFINED_SLOTS}
            </Typography>
          </Box>
          <Divider className="tw-m-4" />
          <Box className="tw-mb-2 tw-mt-2">
            <Box className="tw-align-top tw-inline-block">
              <Box
                sx={{ color: themeVariables.info_wash, fontSize: '20px' }}
                className="tw-inline-block"
              >
                <Icon
                  icon="snowflake"
                  className="tw-mr-2"
                />
              </Box>
              <Typography
                variant="h6"
                className="tw-inline-block tw-mr-2 tw-font-bold"
              >
                {s_WINTER_SCHEDULE}:
              </Typography>
            </Box>
            <Typography
              variant="h6"
              className="tw-inline-block tw-mr-2 tw-opacity-50 tw-font-normal"
            >
              {rJSX_DstDates('winter')}
            </Typography>
          </Box>
          <Box>
            {rJSX_DefinedTimeslotSchedule('winter', teamTypeKey, teamTypeSettings)}
            <Divider className="tw-m-4" />
          </Box>
          <Box className="tw-mb-2 tw-mt-2">
            <Box
              sx={{ color: themeVariables.warning_main, fontSize: '20px' }}
              className="tw-inline-block"
            >
              <Icon
                icon="sun-bright"
                className="tw-mr-2"
              />
            </Box>
            <Typography
              variant="h6"
              className="tw-inline-block tw-mr-2 tw-font-bold"
            >
              {s_SUMMER_SCHEDULE}:
            </Typography>
            <Typography
              variant="h6"
              className="tw-inline-block tw-mr-2 tw-opacity-50 tw-font-normal"
            >
              {rJSX_DstDates('summer')}
            </Typography>
          </Box>
          <Box>
            {rJSX_DefinedTimeslotSchedule('summer', teamTypeKey, teamTypeSettings)}
            <Divider className="tw-m-4" />
          </Box>
          <Box className="tw-mt-2">
            <Box
              sx={{ color: themeVariables.error_main, fontSize: '20px' }}
              className="tw-inline-block"
            >
              <Icon
                icon="wrench"
                className="tw-mr-2"
              />
            </Box>
            <Typography
              variant="h6"
              className="tw-inline-block tw-mr-2 tw-font-bold"
            >
              {s_TEAM_SLOT_SETUP}:
            </Typography>
            <FormControl
              className="bp_thin_select_input bp_thin_select_multiple_input tw-mr-2"
              sx={{ minWidth: '220px' }}
            >
              <InputLabel>{s_TEAM}</InputLabel>
              <Select
                value={us_selectedConfigTeam || ''}
                label={s_TEAM}
                onChange={(event: any) => {
                  if (event != null && event.target != null && event.target.value != null) {
                    us_setSelectedConfigTeam(event.target.value)
                  }
                }}
              >
                {returnFilteredTeams(us_activeTeams, us_selectedRegion, us_visibleTeamsTypesFilter, us_filteredUserKey, us_calendarEvents).map(
                  (team: TsInterface_UnspecifiedObject, index: number) => (
                    <MenuItem
                      key={index}
                      value={team.key}
                    >
                      {team.name}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
            <FormControl
              className="bp_thin_select_input bp_thin_select_multiple_input"
              sx={{ minWidth: '120px' }}
            >
              <InputLabel>{s_SEASON}</InputLabel>
              <Select
                value={us_selectedConfigSeason || ''}
                label={s_SEASON}
                onChange={(event: any) => {
                  if (event != null && event.target != null && event.target.value != null) {
                    us_setSelectedConfigSeason(event.target.value)
                  }
                }}
              >
                <MenuItem value={'winter'}>{s_WINTER_SCHEDULE}</MenuItem>
                <MenuItem value={'summer'}>{s_SUMMER_SCHEDULE}</MenuItem>
              </Select>
            </FormControl>
            {rJSX_SelectedConfigTeam(teamTypeSettings)}
          </Box>
        </Box>
      )
    } else if (teamTypeSettings.task_assignment_type === 'freeform_assignment') {
      settingsJSX = (
        <Box>
          <Box>
            <Box
              sx={{ color: themeVariables.primary_main, fontSize: '20px' }}
              className="tw-inline-block"
            >
              <Icon
                icon="calendar"
                className="tw-mr-2"
              />
            </Box>
            <Typography
              variant="h6"
              className="tw-inline-block tw-mr-2 tw-font-bold"
            >
              {s_TASK_ASSIGNMENT_TYPE}:
            </Typography>
            <Typography
              variant="h6"
              className="tw-inline-block tw-opacity-50"
            >
              {s_FREEFORM_ASSIGNMENT}
            </Typography>
          </Box>
        </Box>
      )
    } else {
      settingsJSX = (
        <Box>
          <FormControl
            className="bp_thin_select_input bp_thin_select_multiple_input"
            sx={{ minWidth: '220px' }}
          >
            <InputLabel>{s_TASK_ASSIGNMENT_TYPE}</InputLabel>
            <Select
              value={teamTypeSettings.task_assignment_type || ''}
              label={s_TASK_ASSIGNMENT_TYPE}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  let updateObject = {
                    task_assignment_type: event.target.value,
                  }
                  setMergeTeamTypeSettings(teamTypeKey, updateObject)
                }
              }}
            >
              {objectToArray(taskAssignmentTypeOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option.key}
                >
                  {option.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )
    }
    return settingsJSX
  }

  const rJSX_ConfigView = (): JSX.Element => {
    let viewJSX = <></>
    let selectedTeamSettings = schedulingTeamTypes[us_visibleTeamsTypesFilter[0]]
    viewJSX = (
      <Box>
        <Typography variant="h5">
          <Box
            className="tw-inline-block tw-mr-2"
            sx={{ color: selectedTeamSettings['color'] }}
          >
            {selectedTeamSettings['icon']}
          </Box>
          {selectedTeamSettings['value']}
        </Typography>
        <Card className="tw-p-4 tw-mt-2">{rJSX_ConfigSettings(selectedTeamSettings.key, getProp(us_teamTypeSettings, selectedTeamSettings['key'], {}))}</Card>
      </Box>
    )
    return viewJSX
  }

  const rJSX_ConfigTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_visibleTeamsTypesFilter.length === 1) {
      tabJSX = <Box>{rJSX_ConfigView()}</Box>
    } else {
      // Make user select a single filtered team type
      tabJSX = rJSX_TeamTypeSelection()
    }
    return tabJSX
  }

  const rJSX_DownloadTab = (): JSX.Element => {
    let tabJSX = <></>
    // Generate Dates
    let utcDate = new Date(pr_dateKey as string)
    let selectedDate = new Date(utcDate.getTime() + Math.abs(utcDate.getTimezoneOffset() * 60000))
    let dayOfWeek = selectedDate.getDay()
    let weekStartMondayAt12am = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - dayOfWeek + 1)
    let weekEndSundayAt12am = new Date(weekStartMondayAt12am.getFullYear(), weekStartMondayAt12am.getMonth(), weekStartMondayAt12am.getDate() + 6)
    let weekEndMondayAt12am = new Date(weekStartMondayAt12am.getFullYear(), weekStartMondayAt12am.getMonth(), weekStartMondayAt12am.getDate() + 7)
    let monthStartMondayAt12am = new Date(weekStartMondayAt12am.getFullYear(), weekStartMondayAt12am.getMonth(), weekStartMondayAt12am.getDate() - 21)

    // JSX
    tabJSX = (
      <Card>
        <Box className="tw-pt-2">
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell className="tw-text-center">
                    {returnFormattedDate(weekStartMondayAt12am, 'D MMM YYYY')} - {returnFormattedDate(weekEndSundayAt12am, 'D MMM YYYY')}
                  </TableCell>
                  <TableCell className="tw-text-center">
                    {returnFormattedDate(monthStartMondayAt12am, 'D MMM YYYY')} - {returnFormattedDate(weekEndSundayAt12am, 'D MMM YYYY')}
                  </TableCell>
                  {/* New column for downloadAssignedButNotCompletedTasksCSV */}
                  <TableCell className="tw-text-center">{rLIB('All Time (No Time Period)')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="tw-text-center">
                    <Button
                      className="tw-m-auto"
                      variant="contained"
                      color="info"
                      disabled={us_exportingSchedule}
                      onClick={() => {
                        exportScheduleV2(weekStartMondayAt12am, weekEndSundayAt12am, weekEndMondayAt12am, 'all')
                      }}
                    >
                      {s_ALL_EVENTS}
                    </Button>
                  </TableCell>
                  <TableCell className="tw-text-center">
                    <Button
                      className="tw-m-auto"
                      variant="contained"
                      color="info"
                      disabled={us_exportingSchedule}
                      onClick={() => {
                        exportScheduleV2(monthStartMondayAt12am, weekEndSundayAt12am, weekEndMondayAt12am, 'all')
                      }}
                    >
                      {s_ALL_EVENTS}
                    </Button>
                  </TableCell>
                  <TableCell className="tw-text-center">
                    <Button
                      className="tw-m-auto"
                      variant="contained"
                      color="info"
                      disabled={us_exportingSchedule}
                      onClick={() => {
                        downloadAssignedButNotCompletedTasksCSV()
                      }}
                    >
                      {rLIB('All assigned but not completed tasks')}
                    </Button>
                  </TableCell>
                  <TableCell></TableCell> {/* Empty column for alignment */}
                </TableRow>
                <TableRow>
                  <TableCell className="tw-text-center">
                    <Button
                      className="tw-m-auto"
                      variant="contained"
                      color="info"
                      disabled={us_exportingSchedule}
                      onClick={() => {
                        exportScheduleV2(weekStartMondayAt12am, weekEndSundayAt12am, weekEndMondayAt12am, 'recently_updated')
                      }}
                    >
                      {s_UPDATED_EVENTS}
                    </Button>
                  </TableCell>
                  <TableCell className="tw-text-center">
                    <Button
                      className="tw-m-auto"
                      variant="contained"
                      color="info"
                      disabled={us_exportingSchedule}
                      onClick={() => {
                        exportScheduleV2(monthStartMondayAt12am, weekEndSundayAt12am, weekEndMondayAt12am, 'recently_updated')
                      }}
                    >
                      {s_UPDATED_EVENTS}
                    </Button>
                  </TableCell>
                  <TableCell></TableCell> {/* Empty column for alignment */}
                </TableRow>
                <TableRow>
                  <TableCell className="tw-text-center">
                    <Button
                      className="tw-m-auto"
                      variant="contained"
                      color="success"
                      disabled={us_exportingSchedule}
                      onClick={() => {
                        fixMissingMiles(weekStartMondayAt12am, weekEndSundayAt12am, weekEndMondayAt12am, 'all')
                      }}
                    >
                      {s_FIX_MISSING_DATA}
                    </Button>
                  </TableCell>
                  <TableCell className="tw-text-center">
                    <Button
                      className="tw-m-auto"
                      variant="contained"
                      color="success"
                      disabled={us_exportingSchedule}
                      onClick={() => {
                        fixMissingMiles(monthStartMondayAt12am, weekEndSundayAt12am, weekEndMondayAt12am, 'all')
                      }}
                    >
                      {s_FIX_MISSING_DATA}
                    </Button>
                  </TableCell>
                  <TableCell></TableCell> {/* Empty column for alignment */}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Card>
    )
    return tabJSX
  }

  const downloadAssignedButNotCompletedTasksCSV = () => {
    us_setExportingSchedule(true)
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          // Step 1: Fetch tasks that are assigned but not complete
          DatabaseGetCollection(DatabaseRef_AssignedButNotCompleteTasks_Query(res_GCK.clientKey))
            .then((res_TaskData) => {
              const taskData = res_TaskData.data

              // Filter out tasks that are already completed (just in case)
              const filteredTaskData = Object.values(taskData).filter((task: any) => task.status === 'assigned' && !task.status_complete)

              // Sort tasks by associated_project_id_number
              const sortedTaskData = filteredTaskData.sort((a: any, b: any) => {
                return a.associated_project_id_number.localeCompare(b.associated_project_id_number)
              })

              // Step 2: Prepare CSV Data with the new fields
              let csvData = [['Project Code', 'Task Name', 'Task Status', 'Task Owner', 'Team Name', 'Team Type', 'Date Assigned']]

              sortedTaskData.forEach((task: any) => {
                const assignedTimestamp =
                  task.timestamp_assigned && task.timestamp_assigned.seconds
                    ? new Date(task.timestamp_assigned.seconds * 1000).toLocaleDateString('en-CA') // Format as yyyy/mm/dd
                    : '' // Handle missing timestamp

                csvData.push([
                  task.associated_project_id_number,
                  task.name,
                  task.status,
                  task.associated_owner_name,
                  task.associated_team_name || '', // Handle missing associated_team_name
                  task.associated_team_type || '', // Handle missing associated_team_type
                  assignedTimestamp, // Formatted timestamp as yyyy/mm/dd
                ])
              })

              // Step 3: Download CSV
              downloadCSV('AssignedButNotCompletedTasksExport', csvData)
                .then((res_DC) => {
                  us_setExportingSchedule(false)
                  resolve(res_DC)
                })
                .catch((rej_DC) => {
                  console.error('Error downloading CSV:', rej_DC)
                  us_setExportingSchedule(false)
                  reject(rej_DC)
                })
            })
            .catch((rej_TaskData) => {
              console.error('Error fetching task data:', rej_TaskData)
              us_setExportingSchedule(false)
              reject(rej_TaskData)
            })
        })
        .catch((rej_GCK) => {
          console.error('Error fetching client key:', rej_GCK)
          us_setExportingSchedule(false)
          reject(rej_GCK)
        })
    })
  }

  // Missing Data List
  const rJSX_MissingDataHeader = (): JSX.Element => {
    let headerJSX = <></>

    headerJSX = <Box>{rLIB('Missing Data')}</Box>
    if (objectToArray(returnTasksMissingBillingReference(us_calendarEvents)).length > 0) {
      headerJSX = (
        <Box sx={{ background: themeVariables.warning_main, color: themeVariables.black, padding: '4px', borderRadius: '6px' }}>
          {rLIB('Missing Data')} ({objectToArray(returnTasksMissingBillingReference(us_calendarEvents)).length})
        </Box>
      )
    }

    return headerJSX
  }

  const rJSX_MissingDataTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card>
        <Box className="tw-p-4">
          <Typography variant="h6">{rLIB('Tasks missing billing reference')}</Typography>
        </Box>
        <TableBasic
          tableAdditionalData={{}}
          tableColumns={{
            timestamp_scheduled_by_name: TableCellBasic('timestamp_scheduled_by_name', rLIB('Scheduled By'), 'timestamp_scheduled_by_name'),
            associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Project ID'), 'associated_project_id_number'),
            name: TableCellBasic('name', rLIB('Task Name'), 'name'),
            associated_team_name: TableCellBasic('associated_team_name', rLIB('Team'), 'associated_team_name'),
            timestamp_last_scheduled_date_key: TableCellBasic(
              'timestamp_last_scheduled_date_key',
              rLIB('Last Scheduled Date'),
              'timestamp_last_scheduled_date_key',
            ),
            invoice_id_number: {
              cell: {
                cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                  return ''
                },
                cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                  let cellJSX = <></>
                  if (rowData.invoice_id_number != null) {
                    cellJSX = <Box>{rowData.invoice_id_number}</Box>
                  } else {
                    cellJSX = (
                      <Box>
                        <Box className="tw-inline-block tw-opacity-40 tw-italic">{rLIB('No Billing Reference')}</Box>
                        {rJSX_EditInvoiceNumberButton(rowData, tableHooks.uc_setUserInterface_FormDialogDisplay)}
                      </Box>
                    )
                  }
                  return cellJSX
                },
              },
              header: {
                header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                  return ''
                },
                header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                  return rLIB('Billing Reference') as JSX.Element
                },
                header_sort_by: null,
              },
            },
          }}
          tableData={objectToArray(returnTasksMissingBillingReference(us_calendarEvents))}
          tableSettings={{
            paginated: false,
            show_header: true,
            size: 'small',
            sort_direction: 'asc',
            sort_property_default: 'associated_project_id_number',
            sortable: true,
            collapsible_columns: true,
            sticky_header: true,
          }}
        />
      </Card>
    )

    return tabJSX
  }
  // Jobs To Close Header
  const rJSX_JobsToCloseHeader = (): JSX.Element => {
    let headerJSX: JSX.Element = <></>

    const jobsToCloseCount = objectToArray(us_filteredJobsToClose).length // Get the number of jobs to close

    headerJSX = <Box>{rLIB('Jobs To Close')}</Box>

    // If there are jobs to close, show the count with a special style
    if (jobsToCloseCount > 0) {
      headerJSX = (
        <Box sx={{ background: themeVariables.warning_main, color: themeVariables.black, padding: '4px', borderRadius: '6px' }}>
          {rLIB('Jobs To Close')} ({jobsToCloseCount})
        </Box>
      )
    }

    return headerJSX
  }

  const rJSX_Jobs_To_Close = (): JSX.Element => {
    let tabJSX = <></>

    // Helper function to create clickable project link
    const getClickableProjectID = (projectKey: string | null, projectID: string): JSX.Element | string => {
      if (!projectKey) {
        return projectID // If no project key, just show the project ID number
      }
      return (
        <Button
          color="info"
          variant="contained"
          onClick={() => {
            const url = window.location.origin + ApplicationPages.AdminActiveProjectViewPage.url(projectKey)
            window.open(url, '_blank')
          }}
          className="tw-mt-2"
        >
          <Icon
            icon="square-arrow-up-right"
            className="tw-mr-2"
          />
          {projectID} {/* Show the project ID */}
        </Button>
      )
    }

    // Preprocess data to add clickable project ID links
    const processedTableData = objectToArray(us_filteredJobsToClose).map((task) => ({
      ...task,
      associated_project_id_number: getClickableProjectID(task.associated_project_key, task.associated_project_id_number),
    }))

    tabJSX = (
      <Card>
        <Box className="tw-p-4">
          <Box
            display="flex"
            alignItems="center"
          >
            <Tooltip
              title={
                <Card style={{ padding: '16px', width: '400px', maxWidth: '100%' }}>
                  <Box>
                    <Typography variant="body2">
                      {rLIB('This table shows all tasks that are currently assigned, not yet complete, and scheduled in a previous week.')}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ marginTop: '16px' }}
                    >
                      {rLIB('Note: Changing the date picker will not affect the data shown here.')}
                    </Typography>
                  </Box>
                </Card>
              }
              arrow
              PopperProps={{
                style: { maxWidth: '400px', padding: '16px' },
              }}
            >
              <IconButton
                size="small"
                aria-label="info"
              >
                <Icon
                  size="lg"
                  icon="circle-info"
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <TableBasic
          tableAdditionalData={{}}
          tableColumns={{
            timestamp_last_scheduled_date_key: TableCellBasic(
              'timestamp_last_scheduled_date_key',
              rLIB('Last Scheduled Date'),
              'timestamp_last_scheduled_date_key',
            ),
            // timestamp_started: TableCellFormattedTimestamp('timestamp_started', 'Assigned Date'),
            associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Project ID'), 'associated_project_id_number'),
            name: TableCellBasic('name', rLIB('Task Name'), 'name'),
            associated_owner_name: TableCellBasic('associated_owner_name', rLIB('Owner'), 'associated_owner_name'),
            timestamp_scheduled_by_name: TableCellBasic('timestamp_scheduled_by_name', rLIB('Scheduled By'), 'timestamp_scheduled_by_name'),
            associated_team_name: TableCellBasic('associated_team_name', rLIB('Team'), 'associated_team_name'),
          }}
          tableData={processedTableData}
          tableSettings={{
            paginated: false,
            show_header: true,
            size: 'small',
            sort_direction: 'desc',
            sort_property_default: 'timestamp_last_scheduled_date_key',
            sortable: true,
            collapsible_columns: true,
            sticky_header: true,
          }}
        />
      </Card>
    )

    return tabJSX
  }

  // Custom Table Cell for timestamp_assigned
  const TableCellFormattedTimestamp = (propKey: string, displayName: string) => ({
    header: {
      header_jsx: () => <Box>{displayName}</Box>,
      header_sort_by: propKey,
    },
    cell: {
      cell_jsx: (rowData: any) => {
        const value = rowData[propKey]
        if (!value) return <Box>Missing</Box> // Show "Missing" if null or undefined

        // Handle Firebase Timestamp or Date
        const formattedDate =
          value.toDate && typeof value.toDate === 'function'
            ? dayjs(value.toDate()).format('YYYY/MM/DD') // Format Firestore Timestamp
            : dayjs(value).format('YYYY/MM/DD') // Handle direct Date objects or strings

        return <Box>{formattedDate || 'Missing'}</Box> // Fallback to "Missing"
      },
    },
  })

  // All
  const rJSX_ScheduleTabs = (): JSX.Element => {
    let tabsJSX = <></>
    tabsJSX = (
      <TabsUrl
        tabs={[
          {
            tabUrlKey: 'Week',
            tabOnChange: () => {
              us_setSelectedTab('week_schedule')
            },
            tabHeader: s_WEEK,
            tabContent: <Box>{rJSX_WeekScheduleTab()}</Box>,
            tabButtons: [
              { fullJSX: rJSX_DatePicker(false), minJSX: rJSX_DatePicker(true), sizeCutoff: 0 },
              { fullJSX: rJSX_RegionDropdown(false), minJSX: rJSX_RegionDropdown(true), sizeCutoff: 0 },
              { fullJSX: rJSX_FilterTeamDropdown(false), minJSX: rJSX_FilterTeamDropdown(true), sizeCutoff: 0 },
              { fullJSX: rJSX_FilterUserDropdown(false), minJSX: rJSX_FilterUserDropdown(true), sizeCutoff: 0 },
              { fullJSX: rJSX_ViewUserToggle(false), minJSX: rJSX_ViewUserToggle(true), sizeCutoff: 835 },
              { fullJSX: rJSX_ViewNotesToggle(false), minJSX: rJSX_ViewNotesToggle(true), sizeCutoff: 945 },
              { fullJSX: rJSX_TeamActivityToggle(false), minJSX: rJSX_TeamActivityToggle(true), sizeCutoff: 1060 },
            ],
          },
          {
            tabUrlKey: 'Day',
            tabOnChange: () => {
              us_setSelectedTab('day_schedule')
            },
            tabHeader: s_DAY,
            tabContent: <Box>{rJSX_DayScheduleTab()}</Box>,
            tabButtons: [
              { fullJSX: rJSX_DatePicker(false), minJSX: rJSX_DatePicker(true), sizeCutoff: 0 },
              { fullJSX: rJSX_RegionDropdown(false), minJSX: rJSX_RegionDropdown(true), sizeCutoff: 0 },
              { fullJSX: rJSX_FilterTeamDropdown(false), minJSX: rJSX_FilterTeamDropdown(true), sizeCutoff: 0 },
              { fullJSX: rJSX_FilterUserDropdown(false), minJSX: rJSX_FilterUserDropdown(true), sizeCutoff: 0 },
              { fullJSX: rJSX_ViewUserToggle(false), minJSX: rJSX_ViewUserToggle(true), sizeCutoff: 835 },
              { fullJSX: rJSX_ViewNotesToggle(false), minJSX: rJSX_ViewNotesToggle(true), sizeCutoff: 945 },
              { fullJSX: rJSX_TeamActivityToggle(false), minJSX: rJSX_TeamActivityToggle(true), sizeCutoff: 1060 },
            ],
          },
          {
            tabUrlKey: 'Missing_Data',
            tabOnChange: () => {
              us_setSelectedTab('missing_data')
            },
            tabHeader: rJSX_MissingDataHeader(),
            tabContent: <Box>{rJSX_MissingDataTab()}</Box>,
            tabButtons: [
              { fullJSX: rJSX_DatePicker(false), minJSX: rJSX_DatePicker(true), sizeCutoff: 0 },
              { fullJSX: rJSX_RegionDropdown(false), minJSX: rJSX_RegionDropdown(true), sizeCutoff: 0 },
            ],
          },
          {
            tabUrlKey: 'Jobs_To_Close',
            tabOnChange: () => {
              us_setSelectedTab('jobs_to_close')
            },
            tabHeader: rJSX_JobsToCloseHeader(),
            tabContent: <Box>{rJSX_Jobs_To_Close()}</Box>,
            tabButtons: [{ fullJSX: rJSX_DatePicker(false), minJSX: rJSX_DatePicker(true), sizeCutoff: 0 }],
          },
          {
            tabUrlKey: 'Map',
            tabOnChange: () => {
              us_setSelectedTab('map')
              setTimeout(() => {
                // // @ts-expect-error - TODO: reason for error
                // if ( ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.recalculateMapBounds != null){ ur_mapRef.current.recalculateMapBounds( true ) }
              }, 500)
            },
            tabHeader: s_MAP,
            tabContent: <Box>{rJSX_MapTab()}</Box>,
            tabButtons: [
              { fullJSX: rJSX_DatePicker(false), minJSX: rJSX_DatePicker(true), sizeCutoff: 0 },
              { fullJSX: rJSX_RegionDropdown(false), minJSX: rJSX_RegionDropdown(true), sizeCutoff: 0 },
              { fullJSX: rJSX_FilterTeamDropdown(false), minJSX: rJSX_FilterTeamDropdown(true), sizeCutoff: 0 },
              { fullJSX: rJSX_FilterUserDropdown(false), minJSX: rJSX_FilterUserDropdown(true), sizeCutoff: 0 },
              { fullJSX: rJSX_ViewUserToggle(false), minJSX: rJSX_ViewUserToggle(true), sizeCutoff: 835 },
              { fullJSX: rJSX_ViewNotesToggle(false), minJSX: rJSX_ViewNotesToggle(true), sizeCutoff: 945 },
              { fullJSX: rJSX_TeamActivityToggle(false), minJSX: rJSX_TeamActivityToggle(true), sizeCutoff: 1060 },
            ],
          },
          {
            tabUrlKey: 'Config',
            tabOnChange: () => {
              us_setSelectedTab('config')
            },
            tabHeader: s_CONFIG,
            tabContent: <Box>{rJSX_ConfigTab()}</Box>,
            tabButtons: [{ fullJSX: rJSX_DatePicker(false), minJSX: rJSX_DatePicker(true), sizeCutoff: 0 }],
          },
          {
            tabUrlKey: 'Downloads',
            tabOnChange: () => {
              us_setSelectedTab('downloads')
            },
            tabHeader: s_DOWNLOADS,
            tabContent: <Box>{rJSX_DownloadTab()}</Box>,
            tabButtons: [{ fullJSX: rJSX_DatePicker(false), minJSX: rJSX_DatePicker(true), sizeCutoff: 0 }],
          },
        ]}
        tabsSettings={{
          baseUrl: ApplicationPages.TaskCalendarSchedulePage.url(pr_dateKey),
          tabQueryParam: 'tab',
          overridePageTitle: true,
          basePageTitle: se_SCHEDULE,
        }}
      />
    )
    return tabsJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_SCHEDULING}
        pageKey={pageKey}
        content={
          <Box>
            <DndProvider backend={HTML5Backend}>{rJSX_ScheduleTabs()}</DndProvider>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
