import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'
import { getStorage, ref, StorageReference } from 'firebase/storage'

export const DatabaseRef_RentalForumThreads_Collection = (clientKey: string, rentalKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'rentals', 'main', rentalKey, 'forum')
}

export const DatabaseRef_RentalForumThread_Document = (clientKey: string, rentalKey: string, threadKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'rentals', 'main', rentalKey, 'forum', threadKey)
}

export const DatabaseRef_RentalForumMessages_Collection = (clientKey: string, rentalKey: string, threadKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'rentals', 'main', rentalKey, 'forum', threadKey, 'messages')
}

export const DatabaseRef_RentalForumMessage_Document = (
  clientKey: string,
  rentalKey: string,
  threadKey: string,
  messageKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'rentals', 'main', rentalKey, 'forum', threadKey, 'messages', messageKey)
}

export const DatabaseRef_RentalFiles_Collection = (clientKey: string, rentalKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'rentals', 'main', rentalKey, 'files')
}

export const DatabaseRef_RentalFile_Document = (clientKey: string, rentalKey: string, fileKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'rentals', 'main', rentalKey, 'files', fileKey)
}

export const StorageRef_RentalFile = (clientKey: string, rentalKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/rentals/' + rentalKey + '/files/' + fileName)
}

export const StorageRef_Rental_MessageThread_File = (clientKey: string, rentalKey: string, threadKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/rentals/' + rentalKey + '/threads/' + threadKey + '/files/' + fileName)
}
