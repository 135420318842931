///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { formInputs_AdHocTaskNew, formOnChange_AdHocTask, formSettings_AdHocTask } from 'app/models/tasks/task_forms'
import { adHocTaskAssignmentTypeInputOptions } from 'app/models/tasks/task_workflow_services'
import { returnClientUserRoles } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_TaskBlueprint_Document } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import { DatabaseRef_TaskFormsActive_Query } from 'rfbp_aux/services/database_endpoints/directory/task_forms'
import {
  DatabaseRef_ProjectLogs_Document,
  DatabaseRef_ProjectTaskWorkflow_Document,
  DatabaseRef_Project_Document,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  DatabaseRef_AllProjectTasks_Query,
  DatabaseRef_TaskByProjectAndBlueprintKey_Query,
  DatabaseRef_Task_Document,
} from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { TsInterface_FormAdditionalData, TsInterface_FormHooksObject, TsInterface_FormSubmittedData } from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { DatabaseBatchUpdate, DatabaseGetCollection, DatabaseGetDocument, TsInterface_DatabaseBatchUpdatesArray } from 'rfbp_core/services/database_management'
import { arrayToObject, cloneObjectWithoutReference, generateRandomString, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

// associated_task_blueprint_key
// associated_additional_work_invoice_key

const createV2AdditionalWorkTaskProper = (
  formSubmittedData: TsInterface_FormSubmittedData,
  formAdditionalData: TsInterface_FormAdditionalData,
  formHooks: TsInterface_FormHooksObject,
) => {
  return new Promise((resolve, reject) => {
    try {
      if (
        formAdditionalData.associated_project_key != null &&
        (formAdditionalData.task_type === 'ad_hoc' || (formAdditionalData.task_type === 'sow' && formAdditionalData.sow_item_key != null))
      ) {
        let createTimestamp = new Date()
        let mainAdHocAbbreviation = ''
        let dispatchAdHocAbbreviation = ''
        let newTaskKey = ''
        let previousVersionTaskKeyForAssociatedTaskKey = ''
        let logCode = ''
        if (formAdditionalData.task_type === 'ad_hoc') {
          mainAdHocAbbreviation = 'AHCC'
          dispatchAdHocAbbreviation = 'AHCA'
          newTaskKey = formAdditionalData.associated_project_key + '_adhoc_' + createTimestamp.getTime().toString() + '_' + generateRandomString(6, null)
          previousVersionTaskKeyForAssociatedTaskKey =
            formAdditionalData.associated_project_key + '_adhoc_' + createTimestamp.getTime().toString() + '_' + generateRandomString(6, null)
          logCode = 'create_ad_hoc_task'
        } else if (formAdditionalData.task_type === 'sow') {
          mainAdHocAbbreviation = 'SOWC'
          dispatchAdHocAbbreviation = 'SOWA'
          newTaskKey = formAdditionalData.associated_project_key + '_sow_' + formAdditionalData.sow_item_key + '_' + createTimestamp.getTime().toString()
          previousVersionTaskKeyForAssociatedTaskKey = formAdditionalData.associated_project_key + '_sow_' + formAdditionalData.sow_item_key
          logCode = 'create_sow_task'
        }
        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
          .then((res_GCK) => {
            // Get Upstream Tasks
            let upstreamTasks: TsInterface_UnspecifiedObject = {}
            let databasePromiseArray: TsType_UnknownPromise[] = []
            if (formSubmittedData.upstream_tasks != null) {
              for (let loopTaskKey in formSubmittedData.upstream_tasks) {
                if (formSubmittedData.upstream_tasks[loopTaskKey] === true) {
                  databasePromiseArray.push(
                    DatabaseGetDocument(DatabaseRef_Task_Document(res_GCK.clientKey, loopTaskKey as string))
                      .then((res_DGD) => {
                        upstreamTasks[loopTaskKey] = res_DGD.data
                      })
                      .catch((rej_DGD) => {
                        console.error(rej_DGD)
                      }),
                  )
                }
              }
            }
            // Get Identical Blueprint Tasks on Project to get proper count
            let matchingProjectBlueprintTasks: TsInterface_UnspecifiedObject = {}
            if (getProp(formAdditionalData, 'sow_item_key', null) != null) {
              databasePromiseArray.push(
                DatabaseGetCollection(
                  DatabaseRef_TaskByProjectAndBlueprintKey_Query(
                    res_GCK.clientKey,
                    formAdditionalData.associated_project_key as string,
                    getProp(formAdditionalData, 'sow_item_key', null) as string,
                  ),
                )
                  .then((res_DGC) => {
                    matchingProjectBlueprintTasks = res_DGC.data
                  })
                  .catch((rej_DGC) => {
                    console.error(rej_DGC)
                  }),
              )
            }
            // After Data Loaded
            Promise.all(databasePromiseArray).finally(() => {
              // Get Project
              DatabaseGetDocument(DatabaseRef_Project_Document(res_GCK.clientKey, formAdditionalData.associated_project_key as string))
                .then((res_DGD) => {
                  // Generate Task Name
                  let taskName = formSubmittedData.name
                  if (matchingProjectBlueprintTasks != null && objectToArray(matchingProjectBlueprintTasks).length > 0) {
                    taskName = taskName + ' (' + (objectToArray(matchingProjectBlueprintTasks).length + 1).toString() + ')'
                  }
                  // Instantiate Variables
                  let project = res_DGD.data
                  let logKey = createTimestamp.getTime().toString() + '_' + generateRandomString(6, null)
                  let taskGroup: TsInterface_UnspecifiedObject = {}
                  if (
                    formSubmittedData != null &&
                    formSubmittedData['associated_task_group_key'] != null &&
                    formAdditionalData != null &&
                    formAdditionalData['task_groups'] != null
                  ) {
                    taskGroup = getProp(formAdditionalData['task_groups'], formSubmittedData['associated_task_group_key'], {})
                  }
                  let delayReasons: TsInterface_UnspecifiedObject = {}
                  let defaultDelayReason: string | null = null
                  if (
                    formAdditionalData != null &&
                    formAdditionalData.originalFormData != null &&
                    // @ts-expect-error - TODO: reason for error
                    formAdditionalData.originalFormData.delay_reasons != null
                  ) {
                    // @ts-expect-error - TODO: reason for error
                    delayReasons = formAdditionalData.originalFormData.delay_reasons
                  }
                  if (
                    formAdditionalData != null &&
                    formAdditionalData.originalFormData != null &&
                    // @ts-expect-error - TODO: reason for error
                    formAdditionalData.originalFormData.default_delay_reason != null
                  ) {
                    // @ts-expect-error - TODO: reason for error
                    defaultDelayReason = formAdditionalData.originalFormData.default_delay_reason
                  }
                  // Initial Update Objects
                  let projectUpdateObject: TsInterface_UnspecifiedObject = {}
                  let taskUpdateObject: TsInterface_UnspecifiedObject = {
                    abbreviation: mainAdHocAbbreviation,
                    associated_owner_key: null,
                    associated_owner_name: null,
                    associated_owner_type: formSubmittedData.associated_owner_type,
                    associated_project_id_number: project.id_number,
                    associated_project_key: formAdditionalData.associated_project_key,
                    associated_task_form_key: getProp(formSubmittedData, 'associated_task_form_key', null),
                    associated_task_group_name: getProp(taskGroup, 'name', null),
                    associated_task_group_key: getProp(formSubmittedData, 'associated_task_group_key', null),
                    associated_task_blueprint_key: getProp(formAdditionalData, 'sow_item_key', null),
                    associated_task_key: previousVersionTaskKeyForAssociatedTaskKey,
                    associated_task_origin: formAdditionalData.task_type,
                    associated_team_type: getProp(formSubmittedData, 'associated_team_type', null),
                    default_delay_reason: defaultDelayReason,
                    delay_reasons: delayReasons,
                    id_number: null,
                    key: newTaskKey,
                    name: taskName,
                    prerequisite_tasks: {},
                    prerequisite_tasks_completion: {},
                    ready_to_start: true,
                    status: null,
                    status_complete: false,
                    status_green_days_cutoff: formSubmittedData.status_green_days_cutoff,
                    status_yellow_days_cutoff: formSubmittedData.status_yellow_days_cutoff,
                    task_completion_type: formSubmittedData.task_completion_type,
                    timestamp_assigned: null,
                    timestamp_created: createTimestamp,
                    timestamp_last_updated: new Date(),
                  }
                  let logUpdateObject: TsInterface_UnspecifiedObject = {
                    timestamp: createTimestamp,
                    associated_user_key: getProp(formHooks.uc_RootData_ClientUser, 'key', null),
                    associated_user_name: getProp(formHooks.uc_RootData_ClientUser, 'name', null),
                    event: logCode,
                    additional_data: {
                      associated_task_name: taskName,
                    },
                  }
                  // Dynamic Update Object Changes
                  let addHocTaskNumber = 0
                  if (project['count_ad_hoc_tasks'] == null) {
                    addHocTaskNumber = 1
                    projectUpdateObject['count_ad_hoc_tasks'] = 1
                  } else {
                    addHocTaskNumber = project['count_ad_hoc_tasks'] + 1
                    projectUpdateObject['count_ad_hoc_tasks'] = addHocTaskNumber + 1
                  }
                  let taskIdString = addHocTaskNumber.toString()
                  if (taskIdString.length === 0) {
                    taskIdString = '000'
                  } else if (taskIdString.length === 1) {
                    taskIdString = '00' + taskIdString
                  } else if (taskIdString.length === 2) {
                    taskIdString = '0' + taskIdString
                  } else if (taskIdString.length === 3) {
                    // Nothing
                  } else if (taskIdString.length > 3) {
                    taskIdString = taskIdString.substring(taskIdString.length - 3, taskIdString.length)
                  }
                  taskUpdateObject['id_number'] = project['id_number'] + '-' + mainAdHocAbbreviation + taskIdString
                  // Task Completion Order
                  let readyToStart = true
                  for (let loopTaskKey in formSubmittedData.upstream_tasks) {
                    if (formSubmittedData.upstream_tasks[loopTaskKey] === true) {
                      taskUpdateObject['prerequisite_tasks'][loopTaskKey] = loopTaskKey
                      let fullUpstreamTask = getProp(upstreamTasks, loopTaskKey, {})
                      if (fullUpstreamTask != null && fullUpstreamTask.status_complete === false) {
                        readyToStart = false
                      } else if (fullUpstreamTask != null && fullUpstreamTask.status_complete === true && fullUpstreamTask.timestamp_completed != null) {
                        taskUpdateObject['prerequisite_tasks_completion'][loopTaskKey] = fullUpstreamTask.timestamp_completed
                      }
                    }
                  }
                  taskUpdateObject['ready_to_start'] = readyToStart
                  // Task Ownership
                  if (
                    formSubmittedData.associated_owner_type != null &&
                    project != null &&
                    project['associated_' + formSubmittedData.associated_owner_type + '_key'] != null &&
                    project['associated_' + formSubmittedData.associated_owner_type + '_name'] != null
                  ) {
                    taskUpdateObject['status'] = 'active'
                    taskUpdateObject['timestamp_assigned'] = createTimestamp
                    taskUpdateObject['associated_owner_key'] = project['associated_' + formSubmittedData.associated_owner_type + '_key']
                    taskUpdateObject['associated_owner_name'] = project['associated_' + formSubmittedData.associated_owner_type + '_name']
                  } else {
                    taskUpdateObject['status'] = 'unassigned'
                  }
                  // If Scheduled Task, Generate Upstream Task
                  let hasDispatchTask = false
                  let dispatchTaskUpdateObject: TsInterface_UnspecifiedObject = {
                    timestamp_last_updated: new Date(),
                  }
                  if (formSubmittedData.task_completion_type === 'scheduled') {
                    hasDispatchTask = true
                    dispatchTaskUpdateObject = cloneObjectWithoutReference(taskUpdateObject)
                    dispatchTaskUpdateObject['associated_dispatched_task_key'] = newTaskKey
                    dispatchTaskUpdateObject['abbreviation'] = dispatchAdHocAbbreviation
                    dispatchTaskUpdateObject['associated_owner_type'] = formSubmittedData.associated_dispatcher_type
                    dispatchTaskUpdateObject['associated_task_form_key'] = null
                    dispatchTaskUpdateObject['id_number'] = project['id_number'] + '-' + dispatchAdHocAbbreviation + taskIdString

                    dispatchTaskUpdateObject['key'] = dispatchTaskUpdateObject['key'] + '_dispatch'
                    dispatchTaskUpdateObject['name'] = 'Schedule ' + dispatchTaskUpdateObject['name']
                    // dispatchTaskUpdateObject["name"] = "Assign " + dispatchTaskUpdateObject["name"]
                    dispatchTaskUpdateObject['task_completion_type'] = 'dispatcher'
                    dispatchTaskUpdateObject['timestamp_created'] = createTimestamp
                    if (
                      formSubmittedData.associated_dispatcher_type != null &&
                      project != null &&
                      project['associated_' + formSubmittedData.associated_dispatcher_type + '_key'] != null &&
                      project['associated_' + formSubmittedData.associated_dispatcher_type + '_name'] != null
                    ) {
                      dispatchTaskUpdateObject['status'] = 'active'
                      dispatchTaskUpdateObject['timestamp_assigned'] = createTimestamp
                      dispatchTaskUpdateObject['associated_owner_key'] = project['associated_' + formSubmittedData.associated_dispatcher_type + '_key']
                      dispatchTaskUpdateObject['associated_owner_name'] = project['associated_' + formSubmittedData.associated_dispatcher_type + '_name']
                    } else {
                      dispatchTaskUpdateObject['status'] = 'unassigned'
                    }
                    taskUpdateObject['prerequisite_tasks'][dispatchTaskUpdateObject['key']] = dispatchTaskUpdateObject['key']
                    taskUpdateObject['ready_to_start'] = false
                  }
                  // Invoice and Blueprint Properties
                  if (formSubmittedData.invoice_key != null) {
                    taskUpdateObject['associated_additional_work_invoice_key'] = formSubmittedData.invoice_key
                  }
                  // Update Array
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                    { type: 'setMerge', ref: DatabaseRef_Task_Document(res_GCK.clientKey, taskUpdateObject.key as string), data: taskUpdateObject },
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_Project_Document(res_GCK.clientKey, formAdditionalData.associated_project_key as string),
                      data: projectUpdateObject,
                    },
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_ProjectLogs_Document(res_GCK.clientKey, formAdditionalData.associated_project_key as string, logKey),
                      data: logUpdateObject,
                    },
                  ]
                  if (hasDispatchTask === true) {
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_Task_Document(res_GCK.clientKey, dispatchTaskUpdateObject.key),
                      data: dispatchTaskUpdateObject,
                    })
                  }
                  // Downstream Tasks
                  let downstreamTaskUpdateObject: TsInterface_UnspecifiedObject = {
                    prerequisite_tasks: {},
                    timestamp_last_updated: new Date(),
                  }
                  downstreamTaskUpdateObject['prerequisite_tasks'][newTaskKey] = newTaskKey
                  for (let loopTaskKey in formSubmittedData.downstream_tasks) {
                    if (formSubmittedData.downstream_tasks[loopTaskKey] === true) {
                      updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(res_GCK.clientKey, loopTaskKey), data: downstreamTaskUpdateObject })
                    }
                  }
                  // Batch Update
                  DatabaseBatchUpdate(updateArray)
                    .then((res_DBU) => {
                      cloudFunctionManageRequest('manageTasks', {
                        function: 'refreshProjectTaskProgressBar',
                        client_key: res_GCK.clientKey,
                        project_key: formAdditionalData.associated_project_key,
                      })
                      resolve(res_DBU)
                    })
                    .catch((rej_DBU) => {
                      reject(rej_DBU)
                    })
                })
                .catch((rej_DGD) => {
                  reject(rej_DGD)
                })
            })
          })
          .catch((rej_GCK) => {
            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            reject(rej_GCK)
          })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to create task'),
            details: rLIB('Missing required parameters'),
            code: 'ER-D-TC-CTP-01',
          },
        })
      }
    } catch (rej_T) {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to create task'),
          details: rej_T,
          code: 'ER-D-TC-CTP-02',
        },
      })
    }
  })
}

///////////////////////////////
// Exports
///////////////////////////////

export const returnUpdateArrayForV2QuoteApproval = (
  clientKey: string,
  projectKey: string,
  invoiceKey: string,
  tasksToCreate: TsInterface_UnspecifiedObject,
  approverUserKey: string,
  approverUserName: string,
) => {
  return new Promise((resolve, reject) => {
    DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
      .then((res_DGD) => {
        let project = res_DGD.data
        let promiseArray: TsType_UnknownPromise[] = []
        let projectTaskWorkflow: TsInterface_UnspecifiedObject = {}
        let projectTasks: TsInterface_UnspecifiedObject = {}
        let taskBlueprint: TsInterface_UnspecifiedObject = {}
        promiseArray.push(
          DatabaseGetDocument(DatabaseRef_TaskBlueprint_Document(clientKey, projectKey))
            .then((res_DGD) => {
              taskBlueprint = res_DGD.data
            })
            .catch((rej_DGD) => {
              console.error(rej_DGD)
            }),
        )
        promiseArray.push(
          DatabaseGetDocument(DatabaseRef_ProjectTaskWorkflow_Document(clientKey, projectKey))
            .then((res_DGD) => {
              projectTaskWorkflow = res_DGD.data
            })
            .catch((rej_DGD) => {
              console.error(rej_DGD)
            }),
        )
        promiseArray.push(
          DatabaseGetCollection(DatabaseRef_AllProjectTasks_Query(clientKey, projectKey))
            .then((res_DGC) => {
              projectTasks = res_DGC.data
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            }),
        )
        // After Data Loaded
        Promise.all(promiseArray).finally(() => {
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
          let projectUpdateObject: TsInterface_UnspecifiedObject = {}

          let addHocTaskNumber = 0
          if (project['count_ad_hoc_tasks'] != null) {
            addHocTaskNumber = project['count_ad_hoc_tasks']
          }
          // Loop through tasks to create
          for (let loopTaskKey in tasksToCreate) {
            let loopTask = tasksToCreate[loopTaskKey]
            addHocTaskNumber++
            projectUpdateObject['count_ad_hoc_tasks'] = addHocTaskNumber
            let createTimestamp = new Date()
            let mainAdHocAbbreviation = 'SOWC'
            let dispatchAdHocAbbreviation = 'SOWA'
            let newTaskKey = projectKey + '_sow_' + loopTaskKey + '_' + createTimestamp.getTime().toString()
            let previousVersionTaskKeyForAssociatedTaskKey = projectKey + '_sow_' + loopTaskKey
            let logCode = 'create_sow_task'
            let logKey = createTimestamp.getTime().toString() + '_' + generateRandomString(6, null)
            let taskGroup: TsInterface_UnspecifiedObject = {}
            if (
              loopTask != null &&
              loopTask['associated_task_group_key'] != null &&
              projectTaskWorkflow != null &&
              projectTaskWorkflow['task_groups'] != null
            ) {
              taskGroup = getProp(projectTaskWorkflow['task_groups'], loopTask['associated_task_group_key'], {})
            }
            let delayReasons: TsInterface_UnspecifiedObject = {}
            let defaultDelayReason: string | null = null
            if (taskBlueprint != null && taskBlueprint.delay_reasons != null) {
              delayReasons = taskBlueprint.delay_reasons
            }
            if (taskBlueprint != null && taskBlueprint.default_delay_reason != null) {
              defaultDelayReason = taskBlueprint.default_delay_reason
            }
            // Initial Update Objects
            let taskUpdateObject: TsInterface_UnspecifiedObject = {
              abbreviation: mainAdHocAbbreviation,
              associated_owner_key: null,
              associated_owner_name: null,
              associated_owner_type: loopTask.associated_owner_type,
              associated_project_id_number: project.id_number,
              associated_project_key: projectKey,
              associated_task_form_key: getProp(loopTask, 'associated_task_form_key', null),
              associated_task_group_name: getProp(taskGroup, 'name', null),
              associated_task_group_key: getProp(loopTask, 'associated_task_group_key', null),
              associated_task_blueprint_key: loopTaskKey,
              associated_task_key: previousVersionTaskKeyForAssociatedTaskKey,
              associated_task_origin: 'sow',
              associated_team_type: getProp(loopTask, 'associated_team_type', null),
              default_delay_reason: defaultDelayReason,
              delay_reasons: delayReasons,
              id_number: null,
              key: newTaskKey,
              name: loopTask.name,
              prerequisite_tasks: {},
              prerequisite_tasks_completion: {},
              ready_to_start: true,
              status: null,
              status_complete: false,
              status_green_days_cutoff: loopTask.status_green_days_cutoff,
              status_yellow_days_cutoff: loopTask.status_yellow_days_cutoff,
              task_completion_type: loopTask.task_completion_type,
              timestamp_assigned: null,
              timestamp_created: createTimestamp,
              timestamp_last_updated: new Date(),
            }
            let logUpdateObject: TsInterface_UnspecifiedObject = {
              timestamp: createTimestamp,
              associated_user_key: approverUserKey,
              associated_user_name: approverUserName,
              event: logCode,
              additional_data: {
                associated_task_name: loopTask.name,
              },
            }
            let taskIdString = addHocTaskNumber.toString()
            if (taskIdString.length === 0) {
              taskIdString = '000'
            } else if (taskIdString.length === 1) {
              taskIdString = '00' + taskIdString
            } else if (taskIdString.length === 2) {
              taskIdString = '0' + taskIdString
            } else if (taskIdString.length === 3) {
              // Nothing
            } else if (taskIdString.length > 3) {
              taskIdString = taskIdString.substring(taskIdString.length - 3, taskIdString.length)
            }
            taskUpdateObject['id_number'] = project['id_number'] + '-' + mainAdHocAbbreviation + taskIdString
            // Task Completion Order
            let readyToStart = true
            for (let loopTaskKey in getProp(loopTask, 'upstream_tasks', {})) {
              if (loopTask.upstream_tasks[loopTaskKey] === true) {
                taskUpdateObject['prerequisite_tasks'][loopTaskKey] = loopTaskKey
                let fullUpstreamTask = getProp(projectTasks, loopTaskKey, {})
                if (fullUpstreamTask != null && fullUpstreamTask.status_complete === false) {
                  readyToStart = false
                } else if (fullUpstreamTask != null && fullUpstreamTask.status_complete === true && fullUpstreamTask.timestamp_completed != null) {
                  taskUpdateObject['prerequisite_tasks_completion'][loopTaskKey] = fullUpstreamTask.timestamp_completed
                }
              }
            }
            taskUpdateObject['ready_to_start'] = readyToStart
            // Task Ownership
            if (
              loopTask.associated_owner_type != null &&
              project != null &&
              project['associated_' + loopTask.associated_owner_type + '_key'] != null &&
              project['associated_' + loopTask.associated_owner_type + '_name'] != null
            ) {
              taskUpdateObject['status'] = 'active'
              taskUpdateObject['timestamp_assigned'] = createTimestamp
              taskUpdateObject['associated_owner_key'] = project['associated_' + loopTask.associated_owner_type + '_key']
              taskUpdateObject['associated_owner_name'] = project['associated_' + loopTask.associated_owner_type + '_name']
            } else {
              taskUpdateObject['status'] = 'unassigned'
            }
            // If Scheduled Task, Generate Upstream Task
            let hasDispatchTask = false
            let dispatchTaskUpdateObject: TsInterface_UnspecifiedObject = {
              timestamp_last_updated: new Date(),
            }
            if (loopTask.task_completion_type === 'scheduled') {
              hasDispatchTask = true
              dispatchTaskUpdateObject = cloneObjectWithoutReference(taskUpdateObject)
              dispatchTaskUpdateObject['associated_dispatched_task_key'] = newTaskKey
              dispatchTaskUpdateObject['abbreviation'] = dispatchAdHocAbbreviation
              dispatchTaskUpdateObject['associated_owner_type'] = loopTask.associated_dispatcher_type
              dispatchTaskUpdateObject['associated_task_form_key'] = null
              dispatchTaskUpdateObject['id_number'] = project['id_number'] + '-' + dispatchAdHocAbbreviation + taskIdString
              dispatchTaskUpdateObject['key'] = dispatchTaskUpdateObject['key'] + '_dispatch'
              dispatchTaskUpdateObject['name'] = 'Schedule ' + dispatchTaskUpdateObject['name']
              // dispatchTaskUpdateObject["name"] = "Assign " + dispatchTaskUpdateObject["name"]
              dispatchTaskUpdateObject['task_completion_type'] = 'dispatcher'
              dispatchTaskUpdateObject['timestamp_created'] = createTimestamp
              if (
                loopTask.associated_dispatcher_type != null &&
                project != null &&
                project['associated_' + loopTask.associated_dispatcher_type + '_key'] != null &&
                project['associated_' + loopTask.associated_dispatcher_type + '_name'] != null
              ) {
                dispatchTaskUpdateObject['status'] = 'active'
                dispatchTaskUpdateObject['timestamp_assigned'] = createTimestamp
                dispatchTaskUpdateObject['associated_owner_key'] = project['associated_' + loopTask.associated_dispatcher_type + '_key']
                dispatchTaskUpdateObject['associated_owner_name'] = project['associated_' + loopTask.associated_dispatcher_type + '_name']
              } else {
                dispatchTaskUpdateObject['status'] = 'unassigned'
              }
              taskUpdateObject['prerequisite_tasks'][dispatchTaskUpdateObject['key']] = dispatchTaskUpdateObject['key']
              taskUpdateObject['ready_to_start'] = false
            }
            // Invoice and Blueprint Properties
            if (invoiceKey != null) {
              taskUpdateObject['associated_additional_work_invoice_key'] = invoiceKey
            }
            // Update Array
            updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, taskUpdateObject.key as string), data: taskUpdateObject })
            updateArray.push({
              type: 'setMerge',
              ref: DatabaseRef_ProjectLogs_Document(clientKey, projectKey as string, logKey),
              data: logUpdateObject,
            })
            if (hasDispatchTask === true) {
              updateArray.push({
                type: 'setMerge',
                ref: DatabaseRef_Task_Document(clientKey, dispatchTaskUpdateObject.key),
                data: dispatchTaskUpdateObject,
              })
            }
            // Downstream Tasks
            let downstreamTaskUpdateObject: TsInterface_UnspecifiedObject = {
              prerequisite_tasks: {},
              timestamp_last_updated: new Date(),
            }
            downstreamTaskUpdateObject['prerequisite_tasks'][newTaskKey] = newTaskKey
            for (let loopTaskKey in loopTask.downstream_tasks) {
              if (loopTask.downstream_tasks[loopTaskKey] === true) {
                updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, loopTaskKey), data: downstreamTaskUpdateObject })
              }
            }
          }
          updateArray.push({
            type: 'setMerge',
            ref: DatabaseRef_Project_Document(clientKey, projectKey as string),
            data: projectUpdateObject,
          })
          resolve({ success: true, updateArray: updateArray })
        })
      })
      .catch((rej_DGD) => {
        reject(rej_DGD)
      })
  })
}

export const createV2AdditionalWorkTask = (
  clientKey: string,
  projectKey: string,
  taskKey: string,
  invoiceKey: string | null,
  taskType: string,
  uc_setUserInterface_FormDialogDisplay: any,
): void => {
  // Instantiate Variables
  let promiseArray: TsType_UnknownPromise[] = []
  let projectTaskWorkflow: TsInterface_UnspecifiedObject = {}
  let projectTasks: TsInterface_UnspecifiedObject = {}
  let formData: TsInterface_UnspecifiedObject = {}
  let taskFormOptions: TsInterface_UnspecifiedObject[] = []
  // Load Data
  let taskOwnerRolesOptions = objectToArray(returnClientUserRoles(clientKey))
  promiseArray.push(
    DatabaseGetDocument(DatabaseRef_ProjectTaskWorkflow_Document(clientKey, projectKey))
      .then((res_DGD) => {
        projectTaskWorkflow = res_DGD.data
      })
      .catch((rej_DGD) => {
        console.error(rej_DGD)
      }),
  )
  promiseArray.push(
    DatabaseGetCollection(DatabaseRef_AllProjectTasks_Query(clientKey, projectKey))
      .then((res_DGC) => {
        projectTasks = res_DGC.data
      })
      .catch((rej_DGC) => {
        console.error(rej_DGC)
      }),
  )
  promiseArray.push(
    DatabaseGetDocument(DatabaseRef_TaskBlueprint_Document(clientKey, taskKey))
      .then((res_DGD) => {
        formData = res_DGD.data
      })
      .catch((rej_DGD) => {
        console.error(rej_DGD)
      }),
  )
  promiseArray.push(
    DatabaseGetCollection(DatabaseRef_TaskFormsActive_Query(clientKey, 'name', false, null, {}))
      .then((res_DGC) => {
        for (let loopFormKey in res_DGC.data) {
          let loopForm = res_DGC.data[loopFormKey]
          taskFormOptions.push({ key: loopForm.key, value: loopForm.name })
        }
      })
      .catch((rej_DGC) => {
        console.error(rej_DGC)
      }),
  )
  // After Data Loaded
  Promise.all(promiseArray).finally(() => {
    let taskGroupOptions: TsInterface_UnspecifiedObject[] = []
    if (projectTaskWorkflow != null && projectTaskWorkflow.task_groups) {
      for (let loopTaskGroupIndex in projectTaskWorkflow.task_groups) {
        let loopTaskGroup = projectTaskWorkflow.task_groups[loopTaskGroupIndex]
        if (loopTaskGroup != null && loopTaskGroup.key != null && loopTaskGroup.name != null) {
          taskGroupOptions.push({ key: loopTaskGroup.key, value: loopTaskGroup.name })
        }
      }
    }
    let upstreamTaskOptions: TsInterface_UnspecifiedObject[] = []
    let downstreamTaskOptions: TsInterface_UnspecifiedObject[] = []
    if (projectTasks != null) {
      for (let loopTaskKey in projectTasks) {
        let loopTask = projectTasks[loopTaskKey]
        if (loopTask != null && loopTask.key != null && loopTask.name != null) {
          if (loopTask.status === 'deleted') {
            upstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: true })
            downstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: true })
          } else {
            upstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: false })
            if (loopTask.status_complete === true) {
              downstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: true })
            } else {
              downstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: false })
            }
          }
        }
      }
    }
    if (formData != null && formData.downstream_tasks != null) {
      for (let loopTaskKey in formData.downstream_tasks) {
        formData.downstream_tasks[projectKey + '_' + loopTaskKey] = true
      }
    }
    if (formData != null && formData.upstream_tasks != null) {
      for (let loopTaskKey in formData.upstream_tasks) {
        formData.upstream_tasks[projectKey + '_' + loopTaskKey] = true
      }
    }
    if (formInputs_AdHocTaskNew['associated_owner_type'] != null && taskOwnerRolesOptions != null) {
      formInputs_AdHocTaskNew['associated_owner_type']['options'] = taskOwnerRolesOptions
    }
    if (formInputs_AdHocTaskNew['associated_dispatcher_type'] != null && taskOwnerRolesOptions != null) {
      formInputs_AdHocTaskNew['associated_dispatcher_type']['options'] = taskOwnerRolesOptions
    }
    if (formInputs_AdHocTaskNew['associated_task_form_key'] != null && taskFormOptions != null) {
      formInputs_AdHocTaskNew['associated_task_form_key']['options'] = taskFormOptions
    }
    if (formInputs_AdHocTaskNew['associated_task_group_key'] != null && taskGroupOptions != null) {
      formInputs_AdHocTaskNew['associated_task_group_key']['options'] = taskGroupOptions
    }
    if (formInputs_AdHocTaskNew['upstream_tasks'] != null && upstreamTaskOptions != null) {
      formInputs_AdHocTaskNew['upstream_tasks']['options'] = upstreamTaskOptions
    }
    if (formInputs_AdHocTaskNew['downstream_tasks'] != null && downstreamTaskOptions != null) {
      formInputs_AdHocTaskNew['downstream_tasks']['options'] = downstreamTaskOptions
    }
    if (projectTaskWorkflow != null && projectTaskWorkflow.workflow_version_number != null && projectTaskWorkflow.workflow_version_number >= 2) {
      let disabledTaskCompletionTypeOptions = { ...adHocTaskAssignmentTypeInputOptions }
      // disabledTaskCompletionTypeOptions['scheduled']['disabled'] = true
      formInputs_AdHocTaskNew['task_completion_type']['options'] = objectToArray(disabledTaskCompletionTypeOptions)
    }
    // Open up dialog
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {
            projectTasks: projectTasks,
            originalFormData: cloneObjectWithoutReference(formData),
            sow_item_key: taskKey,
            task_type: taskType,
            associated_project_key: projectKey,
            task_groups: arrayToObject(projectTaskWorkflow.task_groups, 'key'),
            invoice_key: invoiceKey,
          },
          formData: formData,
          formInputs: formInputs_AdHocTaskNew,
          formOnChange: formOnChange_AdHocTask,
          formSettings: formSettings_AdHocTask,
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              createV2AdditionalWorkTaskProper(formSubmittedData, formAdditionalData, formHooks)
                .then((res_FSAHTC) => {
                  resolve(res_FSAHTC)
                })
                .catch((rej_FSAHTC) => {
                  reject(rej_FSAHTC)
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_FSAHTC.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('New Task'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  })
}
