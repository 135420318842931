import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'

export const formInputs_NewVehicle: TsInterface_FormInputs = {
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: rLIB('Vehicle Name'),
    required: true,
  },
  vin: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'vin',
    label: rLIB('Vin'),
    required: true,
  },
}

export const formInputs_NewRental: TsInterface_FormInputs = {
  rental_company_name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'rental_company_name',
    label: rLIB('Rental Company Name'),
    required: true,
  },
  driver_name: {
    data_type: 'string',
    input_type: 'multiple_choice_select', // Dropdown for driver selection
    key: 'driver_name',
    label: rLIB('Driver Name'),
    required: true,
    options: [], // Options will be populated dynamically
  },
  vin: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'vin',
    label: rLIB('Vin'),
    required: true,
  },
}
