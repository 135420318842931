//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Wrapper that includes navigation and page header for authenticated parts of the software

		TODO:
			[ ] Typescript - 5 instances of @ts-expect-error - on functions / components copied from mui examples

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  Drawer,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material/'
import MuiAppBar from '@mui/material/AppBar'
import Badge from '@mui/material/Badge'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme' // OUTSIDE BOILERPLATE
import { devOrProdEnvironment } from 'rfbp_aux/config/config'
import {
  ApplicationMajorPages,
  ApplicationNavPages,
  ApplicationNavSections,
  ApplicationPages,
  EmptyApplicationNavigationObject,
  generateActiveUserApplicationPermissions,
  generateApplicationNavigationObject,
  TsInterface_NavigationObject,
  TsInterface_NavPage,
} from 'rfbp_aux/data/application_structure' // OUTSIDE BOILERPLATE
import { DatabaseRef_Regions_Collection } from 'rfbp_aux/services/database_endpoints/directory/regions'
import {
  DatabaseRef_GlobalDesktopWiki_DevRequestsActiveItems_Query,
  DatabaseRef_GlobalDesktopWiki_DevRequestsCompletedItems_Query,
  DatabaseRef_GlobalDesktopWiki_DevRequests_Collection,
  DatabaseRef_GlobalDesktopWiki_DevRequest_Document,
} from 'rfbp_aux/services/database_endpoints/global/wiki'
import { DatabaseRef_ClientUser_Document } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { TsInterface_FormInput, TsInterface_InputHooksObject, TsType_InputChangeCallback } from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import { rJSX_HighlightedSearchString, SearchInput } from 'rfbp_core/components/search'
import { TsInterface_TableAdditionalData, TsInterface_TableDataRow, TsInterface_TableHooks } from 'rfbp_core/components/table'
import { TableCellManageProper } from 'rfbp_core/components/table/cells/table_cell_manage'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_AuthenticatedUser,
  Context_RootData_ClientKey,
  Context_RootData_ClientPermissions,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_RootData_UserPermissions,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_LoadingBar,
  Context_UserInterface_NavBadges,
  Context_UserInterface_NavBar,
  Context_UserInterface_PageContentViewType,
  TsInterface_RootData_GlobalUser,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseSetMergeDocument,
  StorageUploadFile,
} from 'rfbp_core/services/database_management'
import { cloneObjectWithoutReference, dynamicSort, getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
// @ts-expect-error - TODO: reason for error
import logoSrc from '../../app/images/logos/logo_temp.png'

import { StorageRef_DevRequestImages } from 'rfbp_aux/services/storage_endpoints/dev_requests'
import { v4 as uuidv4 } from 'uuid'

import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  content: JSX.Element
  pageHeader: JSX.Element | string
  pageKey: string
  noPadding?: boolean
}

interface TsInterface_NavLinkStyle {
  'py': string
  'px': number
  'color'?: string
  'fontWeight'?: number
  '&:hover, &:focus': {
    bgcolor: string
  }
  'borderLeft': string
}

interface TsInterface_ItemCategoryStyle {
  boxShadow: string
  py: number
  px: number
}

///////////////////////////////
// Variables
///////////////////////////////

// Other
const drawerWidth: number = 280
// const drawerWidth: number = 0
const itemCategory: TsInterface_ItemCategoryStyle = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
}

// Colors
const unselectedNavColor: string = themeVariables.white
const selectedNavColor = themeVariables.primary_light
// const logoTopColor: string = themeVariables.white
// const logoMiddleColor: string = themeVariables.white
// const logoBottomColor: string = themeVariables.white
// const navGradientTopColor: string = themeVariables.primary_dark
// const navGradientBottomColor: string = themeVariables.primary_dark

let navCSS: string = `
		#etw_bottom_nav .MuiBadge-root .MuiBadge-badge {
			margin-top: -10px;
			height: 16px;
			min-width: 16px;
		}

    #etw_side_nav::-webkit-scrollbar {
      display: none !important;
      height: 14px;
      background-color: #fff;
    }

    #etw_side_nav::-webkit-scrollbar-thumb {
      background-color: #48505e;
      border-radius: 10px;
      border-top: 2px solid #fff;
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
      border-right: 2px solid #fff;
    }
	`

// Wiki Data
const wikiSections: TsInterface_UnspecifiedObject = {
  support: {
    key: 'support',
    title: rLIB('Support Docs'),
    icon: (
      <Icon
        className="tw-mr-2"
        icon="book"
      />
    ),
  },
  philosophy: {
    key: 'philosophy',
    title: rLIB('Philosophy'),
    icon: (
      <Icon
        className="tw-mr-2"
        icon="lightbulb-on"
      />
    ),
  },
  business_requirements: {
    key: 'business_requirements',
    title: rLIB('Business Requirements'),
    icon: (
      <Icon
        className="tw-mr-2"
        icon="house"
      />
    ),
  },

  known_issues: {
    key: 'known_issues',
    title: rLIB('Known Issues'),
    icon: (
      <Icon
        className="tw-mr-2"
        icon="bug"
      />
    ),
  },
  future_improvement: {
    key: 'future_improvement',
    title: rLIB('Future Improvements'),
    icon: (
      <Icon
        className="tw-mr-2"
        icon="road"
      />
    ),
  },

  dev_history: {
    key: 'dev_history',
    title: 'Dev History',
    icon: (
      <Icon
        className="tw-mr-2"
        icon="rectangle-history"
      />
    ),
  },
}

const formInputs_WikiItem: TsInterface_FormInputs = {
  subject: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'subject',
    label: rLIB('Subject'),
    required: true,
  },
  body: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'body',
    label: rLIB('Body'),
    required: true,
  },
  image_upload: {
    data_type: 'string',
    input_type: 'custom_form_input_jsx', // Custom input type for file upload
    key: 'image_upload',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      // Will call the helper functions and the JSX rendering
      return renderFileUploadJSX(formInput, formInputs, formData, formInputChange, formAdditionalData, formHooks)
    },
  },
}

// Helper function to read the file as Data URL
const readAsDataURL = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => resolve(reader.result)
    reader.readAsDataURL(file)
  })
}

// Function to handle file selection
const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>, formInputChange: TsType_InputChangeCallback) => {
  if (event.target.files && event.target.files.length > 0) {
    const file = event.target.files[0]
    readAsDataURL(file)
      .then((dataUrl) => {
        formInputChange('image_upload', file, true) // Store the file in the form data
        console.log('File selected:', file)
        console.log('Data URL:', dataUrl) // This is the base64 encoded version of the image
      })
      .catch((error) => {
        console.error('Error reading file:', error)
      })
  }
}

const renderFileUploadJSX = (
  formInput: TsInterface_FormInput,
  formInputs: TsInterface_FormInputs,
  formData: TsInterface_FormData,
  formInputChange: TsType_InputChangeCallback,
  formAdditionalData: TsInterface_FormAdditionalData,
  formHooks: TsInterface_FormHooksObject,
): JSX.Element => {
  // JSX for file input field
  const fileUploadJSX = (
    <Box className="tw-mt-4">
      <input
        type="file"
        accept="image/*"
        onChange={(event) => handleFileSelect(event, formInputChange)}
        className="tw-mt-2"
      />
    </Box>
  )

  return fileUploadJSX
}

const us_navType: 'hamburger' | 'drawer' = 'hamburger'

///////////////////////////////
// Functions
///////////////////////////////

export const rJSX_StagingWarning = (): JSX.Element => {
  let warningJSX = <></>
  // @ts-ignore
  if (devOrProdEnvironment === 'dev') {
    warningJSX = (
      <Box
        sx={{
          background: themeVariables.warning_main,
          position: 'fixed',
          top: '0px',
          right: '0px',
          width: '240px',
          textAlign: 'center',
          borderBottomLeftRadius: '10px',
        }}
      >
        <Icon
          icon="display-code"
          className="tw-mr-2"
        />
        STAGING ENVIRONMENT
      </Box>
    )
  }
  return warningJSX
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

const getMainWidth = (open: boolean): string => {
  let width = `100%`
  // @ts-ignore
  if (us_navType === 'hamburger') {
    width = '100%'
    if (open) {
      width = `calc(100% - ${drawerWidth + 16}px)`
    }
  }
  return width
}

const getMainLeftMargin = (open: boolean): string => {
  let width = `0px`
  // @ts-ignore
  if (us_navType === 'hamburger') {
    width = `-${drawerWidth}px`
    if (open) {
      width = `-${drawerWidth + 16}px`
    }
  }
  return width
}

// @ts-expect-error
const Main = styled('main', { shouldForwardProp: (prop: string) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  width: getMainWidth(open),
  // padding: theme.spacing( 3 ),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: getMainLeftMargin(open),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop: string) => prop !== 'open',
  // @ts-expect-error
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth + 0}px)`,
    marginLeft: `${drawerWidth + 0}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const GlobalKeypressListener = (props: any) => {
  // Props
  const openNav: any = getProp(props, 'openNav', () => {})
  const closeNav: any = getProp(props, 'closeNav', () => {})
  const navOpen: any = getProp(props, 'navOpen', null)
  const openSearch: any = getProp(props, 'openSearch', null)
  const openHotKeysDialog: any = getProp(props, 'openHotKeysDialog', null)
  const openNavSearch: any = getProp(props, 'openNavSearch', null) // New navigation search
  const un_routerNavigation = useNavigate()

  // Hooks - useEffect
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      // Command / Control + B - Open / Close Nav
      if ((event.metaKey && event.keyCode === 66) || (event.ctrlKey && event.keyCode === 66)) {
        setTimeout(() => {
          if (navOpen === true) {
            closeNav()
          }
          if (navOpen === false) {
            openNav(true)
          }
        }, 1)
      }

      // Command / Control + Shift + S - Open Search
      if ((event.shiftKey && event.metaKey && event.keyCode === 83) || (event.shiftKey && event.ctrlKey && event.keyCode === 83)) {
        setTimeout(() => {
          openSearch()
        }, 1)
      }

      // Command / Control + Shift + H - Open Hotkeys Dialog
      if ((event.shiftKey && event.metaKey && event.keyCode === 72) || (event.shiftKey && event.ctrlKey && event.keyCode === 72)) {
        event.preventDefault()
        setTimeout(() => {
          openHotKeysDialog()
        }, 1)
      }

      // Cmd/Ctrl + Shift + K: Open Nav Search
      if ((event.shiftKey && event.metaKey && event.keyCode === 75) || (event.shiftKey && event.ctrlKey && event.keyCode === 75)) {
        event.preventDefault()
        setTimeout(() => {
          openNavSearch()
        }, 1)
      }

      // Command / Control + Alt + P - Open Projects in a New Tab
      if ((event.metaKey && event.altKey && event.keyCode === 80) || (event.ctrlKey && event.altKey && event.keyCode === 80)) {
        event.preventDefault()
        window.open('/a/active_projects?tab=All_Projects', '_blank')
      }

      // Command / Control + Shift + P - Navigate to Projects
      if ((event.shiftKey && event.metaKey && event.keyCode === 80) || (event.shiftKey && event.ctrlKey && event.keyCode === 80)) {
        event.preventDefault()
        setTimeout(() => {
          directAppNavigation(un_routerNavigation, '/a/active_projects?tab=All_Projects')
        }, 1)
      }

      // Command / Control + Alt + D - Open Dev Requests in a New Tab
      if ((event.metaKey && event.altKey && event.keyCode === 68) || (event.ctrlKey && event.altKey && event.keyCode === 68)) {
        event.preventDefault()
        window.open('/a/issue_tracker', '_blank')
      }

      // Command / Control + Shift + D - Navigate to Dev Requests
      if ((event.shiftKey && event.metaKey && event.keyCode === 68) || (event.shiftKey && event.ctrlKey && event.keyCode === 68)) {
        event.preventDefault()
        setTimeout(() => {
          directAppNavigation(un_routerNavigation, '/a/issue_tracker')
        }, 1)
      }

      // Command / Control + Alt + C - Open Scheduler in a New Tab
      if ((event.metaKey && event.altKey && event.keyCode === 67) || (event.ctrlKey && event.altKey && event.keyCode === 67)) {
        event.preventDefault()
        const currentDate = new Date().toISOString().split('T')[0]
        window.open(`/a/scheduling/${currentDate}`, '_blank')
      }

      // Command / Control + Shift + C - Navigate to Scheduler
      if ((event.shiftKey && event.metaKey && event.keyCode === 67) || (event.shiftKey && event.ctrlKey && event.keyCode === 67)) {
        event.preventDefault()
        const currentDate = new Date().toISOString().split('T')[0]
        setTimeout(() => {
          directAppNavigation(un_routerNavigation, `/a/scheduling/${currentDate}`)
        }, 1)
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [openNav, closeNav, navOpen, openSearch, openHotKeysDialog, un_routerNavigation])

  return null
}

///////////////////////////////
// Component
///////////////////////////////

export const AuthenticatedContainer = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  const pr_pageContent: TsInterface_ComponentProps['content'] = getProp(props, 'content', <></>)
  const pr_pageHeader: TsInterface_ComponentProps['pageHeader'] = getProp(props, 'pageHeader', <></>)
  const pr_pageKey: TsInterface_ComponentProps['pageKey'] = getProp(props, 'pageKey', '')

  const pr_noPadding: TsInterface_ComponentProps['noPadding'] = getProp(props, 'noPadding', false)

  // Hooks - useContext, useState, useReducer, other
  const [us_screenSize, us_setScreenSize] = useState<string>('md')
  const [us_selectedWikiSection, us_setSelectedWikiSection] = useState<string>('support')
  const [us_viewingRegionSelectionDialog, us_setViewingRegionSelectionDialog] = useState<boolean>(false)
  const [us_wikiData, us_setWikiData] = useState<TsInterface_UnspecifiedObject>({})
  const umq_isExtraSmallScreen = useMediaQuery('(max-width: 400px)')
  const umq_isLargeScreen = useMediaQuery('(min-width: 961px) and (max-width: 1280px)')
  const umq_isMediumScreen = useMediaQuery('(min-width: 601px) and (max-width: 960px)')
  const umq_isSmallScreen = useMediaQuery('(min-width: 401px) and (max-width: 600px)')
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_AuthenticatedUser } = useContext(Context_RootData_AuthenticatedUser)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_RootData_UserPermissions, uc_setRootData_UserPermissions } = useContext(Context_RootData_UserPermissions)
  const { uc_UserInterface_LoadingBarDisplay } = useContext(Context_UserInterface_LoadingBar)
  const { uc_UserInterface_NavBadges } = useContext(Context_UserInterface_NavBadges)
  const { uc_UserInterface_NavBarDisplay, uc_setUserInterface_NavBarDisplay } = useContext(Context_UserInterface_NavBar)
  const { uc_UserInterface_PageContentViewType, uc_setUserInterface_PageContentViewType } = useContext(Context_UserInterface_PageContentViewType)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  const [us_uploadedUrls, us_setUploadedUrls] = useState<string[]>([]) // To store the uploaded URLs
  const [devRequestKey, setDevRequestKey] = useState<string>(uuidv4()) // To store the unique key for the dev request

  const [us_uploadedFiles, us_setUploadedFiles] = useState<File[] | null>(null) // Use File[] instead of FileList

  // Hooks - useEffect
  useEffect(() => {
    if (umq_isExtraSmallScreen === true) {
      us_setScreenSize('xs')
    } else if (umq_isSmallScreen === true) {
      us_setScreenSize('sm')
    } else if (umq_isMediumScreen === true) {
      us_setScreenSize('md')
    } else if (umq_isLargeScreen === true) {
      us_setScreenSize('lg')
    } else {
      us_setScreenSize('xl')
    }
    ur_forceRerender()
    return () => {}
  }, [umq_isExtraSmallScreen, umq_isSmallScreen, umq_isMediumScreen, umq_isLargeScreen, ur_forceRerender])

  useEffect(() => {
    // us_setNavType('drawer')
    // us_setNavType('hamburger')
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        // Nothing
      })
      .catch((rej_GCK) => {
        directAppNavigation(un_routerNavigation, ApplicationPages.UnauthenticatedLoginPage.url())
      })
  }, [un_routerNavigation, uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    generateActiveUserApplicationPermissions(uc_RootData_ClientUser, uc_RootData_GlobalUser, uc_RootData_ClientPermissions).then((res_GAUAP) => {
      if (res_GAUAP.success) {
        uc_setRootData_UserPermissions(res_GAUAP.permissions)
      }
    })
    return () => {}
  }, [uc_RootData_ClientUser, uc_RootData_GlobalUser, uc_RootData_ClientPermissions, uc_setRootData_UserPermissions])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setWikiData(newData) // Set the retrieved data
      ur_forceRerender()
    }

    if (pr_pageKey != null && uc_UserInterface_PageContentViewType === 'info' && us_selectedWikiSection != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          if (us_selectedWikiSection === 'dev_history') {
            // Use the new query for completed dev requests
            unsubscribeLiveData = DatabaseGetLiveCollection(
              DatabaseRef_GlobalDesktopWiki_DevRequestsCompletedItems_Query(pr_pageKey), // Adjust type if needed
              updateLiveData,
            )
          } else {
            // Use the default query for active dev requests
            unsubscribeLiveData = DatabaseGetLiveCollection(
              DatabaseRef_GlobalDesktopWiki_DevRequestsActiveItems_Query(pr_pageKey, us_selectedWikiSection),
              updateLiveData,
            )
          }
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_pageKey, uc_UserInterface_PageContentViewType, us_selectedWikiSection])

  // Other Variables
  const pageRootNavPermission: string = getProp(ApplicationPages[pr_pageKey], 'root_nav_page_permission', '')
  const pageRoleAccessPermissions: TsInterface_NavPage['page_role_access_permissions'] = getProp(
    ApplicationNavPages[pageRootNavPermission],
    'page_role_access_permissions',
    {},
  )
  let topLoadingBar: JSX.Element
  let sideBarNavObject: TsInterface_NavigationObject
  let authorizedToViewPage: boolean = false
  let clientType: string
  let userRole: string

  // Functions

  //handle image upload

  // Helper function to read file as data URL
  const handleFileUpload = (files: File[]): Promise<string[]> => {
    console.log('Starting file upload for files:', files)
    return new Promise((resolve, reject) => {
      if (!files || files.length === 0) {
        console.log('No files to upload')
        return resolve([]) // No files to upload
      }

      const uploadPromises: Promise<any>[] = []
      const uploadedUrls: string[] = []

      files.forEach((file) => {
        // Generate a unique key for each file using uuidv4
        const uniqueFileKey = uuidv4()
        console.log('Uploading file:', file.name, 'with key:', uniqueFileKey)

        const uploadPromise = StorageUploadFile(
          StorageRef_DevRequestImages('etw_energy', devRequestKey, uniqueFileKey), // Use unique key
          file,
          {},
        )
          .then((res_SUF: any) => {
            const url = getProp(res_SUF, 'url', null)
            if (url) {
              console.log('File uploaded successfully. URL:', url)
              uploadedUrls.push(url) // Add URL to array
            } else {
              console.warn('URL not returned after upload')
            }
          })
          .catch((error) => {
            console.error('Error uploading file:', file.name, error)
            reject(error) // Reject promise on upload failure
          })

        uploadPromises.push(uploadPromise)
      })

      // Wait for all uploads to complete
      Promise.all(uploadPromises)
        .then(() => {
          console.log('All files uploaded successfully. URLs:', uploadedUrls)
          us_setUploadedUrls(uploadedUrls) // Store URLs in state
          resolve(uploadedUrls) // Resolve with URLs
        })
        .catch((error) => {
          console.error('Error during file upload:', error)
          reject(error) // Handle errors
        })
    })
  }

  const handleFormSubmission = (formSubmittedData: TsInterface_FormSubmittedData): Promise<any> => {
    console.log('Submitting form with data:', formSubmittedData)
    return new Promise((resolve, reject) => {
      const uploadedFiles = us_uploadedFiles // Get uploaded files from state
      console.log('Files to upload:', uploadedFiles)

      // If files are selected, upload them first
      if (uploadedFiles && uploadedFiles.length > 0) {
        handleFileUpload(uploadedFiles as any)
          .then((uploadedUrls) => {
            console.log('Files uploaded. URLs:', uploadedUrls)

            // Now that the files are uploaded, submit the form data
            const updateObject = {
              associated_user_key: uc_RootData_ClientUser.key,
              associated_user_name: uc_RootData_ClientUser.name,
              body: formSubmittedData.body,
              associated_page_key: pr_pageKey,
              status: 'unassigned',
              subject: formSubmittedData.subject,
              timestamp_created: new Date(),
              type: us_selectedWikiSection,
              image_urls: uploadedUrls, // Include uploaded image URLs
            }

            console.log('Submitting form data to the database:', updateObject)

            // Save form data to the database
            DatabaseAddDocument(DatabaseRef_GlobalDesktopWiki_DevRequests_Collection(), updateObject, true)
              .then((res_DAD) => {
                console.log('Form data saved successfully:', res_DAD)
                resolve(res_DAD)
              })
              .catch((error) => {
                console.error('Error saving form data:', error)
                reject(error)
              })
          })
          .catch((error) => {
            console.error('Error uploading files:', error)
            reject(error)
          })
      } else {
        // If no files, just save the form data
        console.log('No files to upload. Saving form data directly.')

        const updateObject = {
          associated_user_key: uc_RootData_ClientUser.key,
          associated_user_name: uc_RootData_ClientUser.name,
          body: formSubmittedData.body,
          associated_page_key: pr_pageKey,
          status: 'unassigned',
          subject: formSubmittedData.subject,
          timestamp_created: new Date(),
          type: us_selectedWikiSection,
          image_urls: [], // No images uploaded
        }

        console.log('Submitting form data to the database without images:', updateObject)

        DatabaseAddDocument(DatabaseRef_GlobalDesktopWiki_DevRequests_Collection(), updateObject, true)
          .then((res_DAD) => {
            console.log('Form data saved successfully (no images):', res_DAD)
            resolve(res_DAD)
          })
          .catch((error) => {
            console.error('Error saving form data (no images):', error)
            reject(error)
          })
      }
    })
  }

  // Event handler for file input change
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      console.log('Files selected for upload:', files)
      us_setUploadedFiles(Array.from(files)) // Set files in state as array
    } else {
      console.warn('No files selected')
    }
  }

  const determineClientTypeAndUserRole = (): void => {
    if (uc_RootData_ClientPermissions != null && uc_RootData_ClientPermissions.client_type != null) {
      clientType = uc_RootData_ClientPermissions.client_type
    }
    if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.user_role != null) {
      userRole = uc_RootData_ClientUser.user_role
    } else if (uc_RootData_GlobalUser != null && uc_RootData_GlobalUser.user_role != null) {
      userRole = uc_RootData_GlobalUser.user_role
    }
  }

  const applyPermissionsToSideNavBarObject = (): void => {
    let initialApplicationNavigationObject = generateApplicationNavigationObject(clientType, userRole, uc_RootData_ClientUser)
    let permittedApplicationNavigationObject: TsInterface_NavigationObject = {}
    for (let loopSectionKey in initialApplicationNavigationObject) {
      let loopSection = initialApplicationNavigationObject[loopSectionKey]
      for (let loopLinkKey in loopSection['links']) {
        let loopLink = loopSection['links'][loopLinkKey]
        if (uc_RootData_UserPermissions != null && uc_RootData_UserPermissions[loopLinkKey] === true && loopLink != null) {
          if (permittedApplicationNavigationObject[loopSectionKey] == null) {
            permittedApplicationNavigationObject[loopSectionKey] = cloneObjectWithoutReference(loopSection)
            permittedApplicationNavigationObject[loopSectionKey]['links'] = {}
          }
          permittedApplicationNavigationObject[loopSectionKey]['links'][loopLinkKey] = loopLink
        }
      }
    }
    if (permittedApplicationNavigationObject == null || objectToArray(permittedApplicationNavigationObject).length === 0) {
      permittedApplicationNavigationObject = EmptyApplicationNavigationObject
    }
    sideBarNavObject = permittedApplicationNavigationObject
  }

  const checkIfNavSectionIsActive = (sectionKey: string, linkKey: string): boolean => {
    let active = false
    if (sectionKey === 'home' && linkKey === 'home' && pr_pageKey === 'HomePage') {
      active = true
    } else if (clientType != null && userRole != null && pageRoleAccessPermissions != null && pageRoleAccessPermissions[clientType + '_' + userRole] != null) {
      let compositeKey: string = clientType + '_' + userRole
      if (
        pageRoleAccessPermissions[compositeKey]['highlighted_nav_section'] === sectionKey &&
        pageRoleAccessPermissions[compositeKey]['highlighted_nav_page'] === linkKey
      ) {
        active = true
      }
    }
    return active
  }

  const returnNavLinkStyle = (selected: boolean): TsInterface_NavLinkStyle => {
    let style
    if (selected === true) {
      style = {
        'py': '2px',
        'px': 3,
        'color': selectedNavColor,
        'fontWeight': 700,
        // color: 'rgba(255, 255, 255, 0.7)',
        '&:hover, &:focus': {
          bgcolor: 'rgba(255, 255, 255, 0.08)',
        },
        'borderLeft': '6px solid ' + selectedNavColor,
      }
    } else {
      style = {
        'py': '2px',
        'px': 3,
        'color': 'rgba(255, 255, 255, 0.7)',
        'fontWeight': 700,
        '&:hover, &:focus': {
          bgcolor: 'rgba(255, 255, 255, 0.08)',
        },
        'borderLeft': '6px solid rgba(0,0,0,0)',
      }
    }
    return style
  }

  const returnNavIconStyle = (selected: boolean): TsInterface_UnspecifiedObject => {
    let navIconSX = {
      color: unselectedNavColor,
    }
    if (selected === true) {
      navIconSX['color'] = selectedNavColor
    }
    return navIconSX
  }

  const determineLoadingBarVisibility = (): void => {
    if (uc_UserInterface_LoadingBarDisplay === true) {
      topLoadingBar = <LinearProgress color="secondary" />
    } else {
      topLoadingBar = <Box className="top_loading_bar_placeholder"></Box>
    }
  }

  const determinePageAuthorization = (): void => {
    if (pr_pageKey === 'HomePage') {
      authorizedToViewPage = true
    } else if (clientType == null || userRole == null) {
      authorizedToViewPage = true
    } else {
      if (
        uc_RootData_UserPermissions == null ||
        objectToArray(uc_RootData_UserPermissions).length === 0 ||
        uc_RootData_UserPermissions[pageRootNavPermission] === true
      ) {
        authorizedToViewPage = true
      }
    }
  }

  const getMainSectionPadding = (open: boolean): string => {
    let cssClassName = ''
    if (open === true) {
      cssClassName = 'tw-pl-2'
    }
    return cssClassName
  }

  const navigateToPage = (pageKey: string): void => {
    if (pageKey != null && ApplicationNavPages != null && ApplicationNavPages[pageKey] != null && ApplicationNavPages[pageKey]['url'] != null) {
      directAppNavigation(un_routerNavigation, ApplicationNavPages[pageKey]['url'])
    }
  }

  const returnTopNavLevelKey = (): string => {
    let rootPageKey = ''
    if (ApplicationPages != null && ApplicationPages[pr_pageKey] != null && ApplicationPages[pr_pageKey]['root_nav_page_permission'] != null) {
      rootPageKey = ApplicationPages[pr_pageKey]['root_nav_page_permission']
    }
    return rootPageKey
  }

  const returnSelectedNavSX = (navKey: string): TsInterface_UnspecifiedObject => {
    let sx = {
      // alignSelf: "flex-start"
      // alignSelf: "center"
      // alignSelf: "flex-start",
      // paddingTop: "22px"
      justifyContent: 'flex-start',
      paddingTop: '28px',
      // paddingLeft: "0px !important",
      // paddingRight: "0px !important",
      // paddingBottom: "17px"
    }
    if (returnTopNavLevelKey() === navKey) {
      sx = {
        // alignSelf: "center",
        // paddingTop: "0px"
        justifyContent: 'flex-start',
        paddingTop: '28px',
        // paddingLeft: "0px !important",
        // paddingRight: "0px !important",
        // paddingBottom: "0px"
      }
    }
    return sx
  }

  // const getHeaderTextMaxWidth = ( open: boolean ): string => {
  // // TODO - add 40 for each nav icon visible - i.e. notifications
  // let otherHeaderContentWidth = 130
  // let maxWidth = `calc(100% - ${otherHeaderContentWidth}px)`
  // return maxWidth
  // }

  // const rJSX_NavButton = ( open: boolean ): JSX.Element => {
  // let navButtonJSX = <></>
  // if ( open === true ){
  // 	navButtonJSX =
  // 	<IconButton sx={{ color: themeVariables.white, height: "40px", width: "40px", marginTop: "4px", ...( UserInterface_NavBarDisplay && { display: 'none' } ) }} className="tw-inline-block" onClick={ () => { closeNav() } }>
  // 		<ChevronLeftIcon/>
  // 	</IconButton>
  // } else {
  // 	navButtonJSX =
  // 	<IconButton sx={{ color: themeVariables.white, height: "40px", width: "40px", marginTop: "4px", ...( !UserInterface_NavBarDisplay && { display: 'none' } ) }} className="tw-inline-block" onClick={ () => { openNav() } }>
  // 		<MenuIcon/>
  // 	</IconButton>
  // }
  // return navButtonJSX
  // }

  const returnFlatBottomTabNavLinks = (): TsInterface_UnspecifiedObject[] => {
    let flatNavLinks: TsInterface_UnspecifiedObject[] = []
    for (let loopNavSectionKey in sideBarNavObject) {
      let loopNavSection = sideBarNavObject[loopNavSectionKey]
      if (loopNavSection != null && loopNavSection['links'] != null) {
        for (let pageKey in loopNavSection['links']) {
          let loopPage = loopNavSection['links'][pageKey]
          flatNavLinks.push(loopPage)
        }
      }
    }
    return flatNavLinks
  }

  const returnNavBadgeContent = (pageKey: string): string | null => {
    let badgeCount = null
    if (pageKey != null && ApplicationNavPages != null && ApplicationNavPages[pageKey] != null && ApplicationNavPages[pageKey]['nav_badges'] != null) {
      for (let loopBadgeKey in ApplicationNavPages[pageKey]['nav_badges']) {
        let loopBadgeBoolean = ApplicationNavPages[pageKey]['nav_badges'][loopBadgeKey]
        if (
          loopBadgeBoolean === true &&
          uc_UserInterface_NavBadges != null &&
          uc_UserInterface_NavBadges.counts != null &&
          uc_UserInterface_NavBadges.counts[loopBadgeKey] > 0
        ) {
          badgeCount = ' '
        }
      }
    }
    return badgeCount
  }

  determineClientTypeAndUserRole()
  applyPermissionsToSideNavBarObject()
  determineLoadingBarVisibility()
  determinePageAuthorization()

  const rJSX_ItemManageButton = (item: TsInterface_UnspecifiedObject): JSX.Element => {
    let editIconJSX = <></>
    editIconJSX = (
      <TableCellManageProper
        actions={{
          edit: {
            icon: (
              <Icon
                icon="pen-to-square"
                type="solid"
              />
            ),
            label: rLIB('Edit'),
            onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
              uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: rowData,
                    formInputs: {
                      ...formInputs_WikiItem,
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {
                      console.log('Form data on change:', formData) // Log form data changes

                      // Check if image_upload is a FileList and has files
                      if (formData.image_upload instanceof FileList && formData.image_upload.length > 0) {
                        const fileArray = Array.from(formData.image_upload) // Convert FileList to array
                        us_setUploadedFiles(fileArray) // Store file array in the state
                        console.log('Image upload detected and stored:', fileArray)
                      }
                    },

                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      console.log('Submitting form data:', formSubmittedData) // Log form submission
                      return new Promise((resolve, reject) => {
                        console.log('Submit Button Clicked')
                        handleFormSubmission(formSubmittedData)
                          .then((res) => resolve(res))
                          .catch((err) => reject(err))
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Add Content')} - {wikiSections[us_selectedWikiSection].title}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="cloud-arrow-up"
                      />
                    ),
                  },
                },
              })
            },
          },
          delete: {
            icon: (
              <Icon
                icon="trash"
                type="solid"
              />
            ),
            label: rLIB('Delete'),
            onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
              tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
                display: true,
                confirm: {
                  color: 'error',
                  icon: <Icon icon="lock" />,
                  header: rLIB('Delete'),
                  text: rLIB('Are you sure that you want to delete this post?'),
                  submit_text: rLIB('Delete'),
                  submit_callback: () => {
                    return new Promise((resolve, reject) => {
                      let updateObject = {
                        status: 'deleted', // Updated status field
                      }
                      DatabaseSetMergeDocument(DatabaseRef_GlobalDesktopWiki_DevRequest_Document(item.key), updateObject)
                        .then((res_DAD) => {
                          resolve(res_DAD)
                        })
                        .catch((rej_DAD) => {
                          uc_setUserInterface_ErrorDialogDisplay({
                            display: true,
                            error: rej_DAD.error,
                          })
                          reject(rej_DAD)
                        })
                    })
                  },
                },
              })
            },
          },
        }}
        rowData={item}
        tableAdditionalData={{}}
      />
    )

    return editIconJSX
  }

  const rJSX_InfoContentItems = (sectionKey: string): JSX.Element => {
    let itemsJSX = <></>

    // Fetch all wiki data (dev requests in this case)
    const allItems = objectToArray(us_wikiData)

    let filteredItems = allItems

    // Only filter for dev history section
    if (sectionKey === 'dev_history') {
      filteredItems = allItems.filter((item: TsInterface_UnspecifiedObject) => {
        console.log('Checking item for dev history, Status: ', item.status, 'Item: ', item)
        return item.status === 'completed' // Only include completed dev requests
      })
    }

    // Sort based on the section
    const sortField = sectionKey === 'dev_history' ? 'timestamp_completed' : 'timestamp_created'
    filteredItems = filteredItems.sort(dynamicSort(sortField, 'desc'))

    if (filteredItems.length > 0) {
      itemsJSX = (
        <Box>
          {filteredItems.map((item: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>
              <Card className="tw-mb-2 tw-px-4 tw-py-2">
                <Box className="tw">
                  <Stack
                    direction="row"
                    spacing={1}
                    className="tw-justify-between"
                  >
                    <Typography
                      variant="h6"
                      className="tw-font-bold"
                    >
                      {item.subject}
                    </Typography>
                    {/* Only show the manage button for sections other than dev_history */}
                    {sectionKey !== 'dev_history' && item.associated_user_key === uc_RootData_ClientUser.key && rJSX_ItemManageButton(item)}
                  </Stack>
                </Box>
                <Typography
                  variant="body1"
                  className="tw-opacity-50"
                >
                  {item.body}
                </Typography>
                <Box className="tw-text-right">
                  <Chip
                    label={item.associated_user_name}
                    color="success"
                    variant="outlined"
                    className="tw-mr-2"
                  />
                  {sectionKey === 'dev_history' && (
                    <Typography
                      variant="body2"
                      className="tw-opacity-70 tw-font-italic"
                    >
                      {item.type === 'known_issues' ? 'Bug' : 'Feature Request'}
                    </Typography>
                  )}
                  {/* Conditionally show timestamp based on section */}
                  {sectionKey === 'dev_history' && item.timestamp_completed ? (
                    <Typography
                      variant="body2"
                      className="tw-opacity-70"
                    >
                      Completed: {returnFormattedDate(item.timestamp_completed.seconds * 1000, 'D MMM YYYY h:mm a')}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      className="tw-opacity-70"
                    >
                      {returnFormattedDate(item.timestamp_created.seconds * 1000, 'D MMM YYYY h:mm a')}
                    </Typography>
                  )}
                </Box>
              </Card>
            </Box>
          ))}
        </Box>
      )
    } else {
      itemsJSX = (
        <Box>
          <Typography
            variant="body1"
            component="div"
            className="tw-opacity-20"
          >
            {rLIB('No Content')}
          </Typography>
        </Box>
      )
    }

    return itemsJSX
  }

  const rJSX_InfoContent = (): JSX.Element => {
    let sidebarWidth = '250px'

    let infoContentJSX = (
      <Box>
        <Box
          className="tw-inline-block tw-p-4"
          sx={{
            background: '#142135',
            borderBottom: '6px solid ' + themeVariables.info_light,
            borderLeft: '6px solid ' + themeVariables.info_light,
            borderRight: '1px solid rgba(255,255,255,0.1)',
            borderTop: '6px solid ' + themeVariables.info_light,
            height: 'calc( 100vh - 144px )',
            overflowY: 'auto',
            verticalAlign: 'top',
            width: sidebarWidth,
          }}
        >
          {objectToArray(wikiSections).map((section: TsInterface_UnspecifiedObject, index: number) => (
            <Box
              key={index}
              className="tw-mb-2"
            >
              <Box
                className="tw-px-2 tw-py-2 tw-rounded-xl tw-cursor-pointer"
                sx={{
                  'fontSize': '16px',
                  'background': us_selectedWikiSection === section.key ? themeVariables.success_main : 'rgba(0,0,0,0)',
                  'color': us_selectedWikiSection === section.key ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.3)',
                  '&:hover': {
                    color: 'rgba(255,255,255,1)',
                    background: us_selectedWikiSection === section.key ? themeVariables.success_main : '#1B2D48',
                  },
                }}
                onClick={() => {
                  us_setSelectedWikiSection(section.key)
                }}
              >
                {section.icon}
                {section.title}
              </Box>
            </Box>
          ))}
        </Box>

        <Box
          className="tw-inline-block tw-p-4"
          sx={{
            background: '#1B2D48',
            borderBottom: '6px solid ' + themeVariables.info_light,
            borderRight: '6px solid ' + themeVariables.info_light,
            borderTop: '6px solid ' + themeVariables.info_light,
            height: 'calc( 100vh - 144px )',
            overflowY: 'auto',
            verticalAlign: 'top',
            width: 'calc( 100% - ' + sidebarWidth + ' )',
          }}
        >
          <Typography
            variant="h5"
            component="div"
            className="tw-font-bold tw-mb-2"
          >
            {wikiSections[us_selectedWikiSection].title}

            {/* Hide the plus icon for the dev_history section */}
            {us_selectedWikiSection !== 'dev_history' && (
              <Icon
                icon="circle-plus"
                className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
                tooltip={rLIB('Add Content')}
                tooltipPlacement="right"
                onClick={() => {
                  const newDevRequestKey = uuidv4() // Generate unique devRequestKey when opening dialog
                  setDevRequestKey(newDevRequestKey) // Set the new devRequestKey

                  console.log('Opening form dialog for section:', us_selectedWikiSection)
                  uc_setUserInterface_FormDialogDisplay({
                    display: true,
                    form: {
                      form: {
                        formAdditionalData: {}, // Add any extra data you might need
                        formData: {},
                        formInputs: {
                          ...formInputs_WikiItem, // Ensure the file upload is part of this object
                          image_upload: {
                            data_type: 'string',
                            input_type: 'custom_form_input_jsx', // Use the custom JSX for file upload
                            key: 'image_upload',
                            label: 'Upload Image',
                            required: false,
                            renderCustomFormInput: (
                              formInput: TsInterface_FormInput,
                              formInputs: TsInterface_FormInputs,
                              formData: TsInterface_FormData,
                              formInputChange: TsType_InputChangeCallback,
                              formSettings: TsInterface_FormSettings,
                              formAdditionalData: TsInterface_FormAdditionalData,
                              formHooks: TsInterface_FormHooksObject,
                            ) => {
                              return (
                                <input
                                  type="file"
                                  multiple
                                  onChange={(event) => {
                                    // Handle file change
                                    handleFileChange(event)
                                    formInputChange('image_upload', event.target.files, true) // Trigger form change event
                                  }}
                                />
                              )
                            },
                          },
                        },
                        formOnChange: (
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formData: TsInterface_FormData,
                          formInputs: TsInterface_FormInputs,
                          formSettings: TsInterface_FormSettings,
                        ) => {
                          console.log('Form data on change:', formData)
                        },
                        formSettings: {},
                        formSubmission: (
                          formSubmittedData: TsInterface_FormSubmittedData,
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formHooks: TsInterface_FormHooksObject,
                        ) => {
                          console.log('Submitting form data:', formSubmittedData)
                          return handleFormSubmission(formSubmittedData)
                        },
                      },
                      dialog: {
                        formDialogHeaderColor: 'success',
                        formDialogHeaderText: (
                          <>
                            {rLIB('Add Content')} - {wikiSections[us_selectedWikiSection].title}
                          </>
                        ),
                        formDialogIcon: (
                          <Icon
                            type="solid"
                            icon="cloud-arrow-up"
                          />
                        ),
                      },
                    },
                  })
                }}
              />
            )}
          </Typography>

          {rJSX_InfoContentItems(us_selectedWikiSection)}
        </Box>
      </Box>
    )

    return infoContentJSX
  }

  const rJSX_BottomNav = (): JSX.Element => {
    let bottomNavJSX = <></>
    // @ts-ignore
    if (us_navType === 'drawer') {
      bottomNavJSX = (
        <Box>
          <Box sx={{ height: '85px' }}></Box>
          <Box
            id="etw_bottom_nav"
            sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
          >
            <BottomNavigation
              sx={{
                height: '80px',
                width: '100%',
                background: themeVariables.background_paper,
                borderTopLeftRadius: '25px',
                borderTopRightRadius: '25px',
                borderTop: '1px solid ' + themeVariables.background_default,
              }}
              value={returnTopNavLevelKey()}
              onChange={(event, newValue) => {
                navigateToPage(newValue)
              }}
            >
              {returnFlatBottomTabNavLinks().map((link, linkIndex) => (
                <BottomNavigationAction
                  sx={returnSelectedNavSX(link.key)}
                  key={linkIndex}
                  label={<Box sx={{ marginBottom: '-30px', marginTop: '15px' }}>{link.name}</Box>}
                  value={link.key}
                  icon={
                    <>
                      <Badge
                        badgeContent={returnNavBadgeContent(link.key)}
                        color="error"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      >
                        {link.icon}
                      </Badge>
                    </>
                  }
                />
              ))}
            </BottomNavigation>
          </Box>
        </Box>
      )
    }
    return bottomNavJSX
  }

  const rJSX_DefaultPageContent = (): JSX.Element => {
    let pageContent = (
      <Box>
        {pr_noPadding ? <Box className="tw-m-auto">{pr_pageContent}</Box> : <Box className="tw-py-2 tw-px-4 tw-m-auto">{pr_pageContent}</Box>}

        {rJSX_BottomNav()}
      </Box>
    )
    return pageContent
  }

  const rJSX_PageContent = (): JSX.Element => {
    let contentJSX = <></>
    if (uc_UserInterface_PageContentViewType === 'info') {
      contentJSX = (
        <Box>
          <Box sx={{ height: '64px' }} />
          <Box className="tw-m-auto">
            <PanelGroup direction="horizontal">
              <Panel
                defaultSize={50}
                minSize={0}
              >
                {rJSX_InfoContent()}
              </Panel>
              <PanelResizeHandle className="ResizeHandleOuter">
                <Box className="ResizeHandleInner">
                  {/* @ts-ignore */}
                  <svg
                    className="ResizeHandleIcon"
                    viewBox="0 0 24 24"
                  >
                    {/* @ts-ignore */}
                    <path
                      fill="currentColor"
                      d="M18,16V13H15V22H13V2H15V11H18V8L22,12L18,16M2,12L6,16V13H9V22H11V2H9V11H6V8L2,12Z"
                    />
                    {/* @ts-ignore */}
                  </svg>
                </Box>
              </PanelResizeHandle>
              <Panel minSize={0}>{rJSX_DefaultPageContent()}</Panel>
            </PanelGroup>
          </Box>
        </Box>
      )
    } else {
      contentJSX = (
        <Box>
          <Box sx={{ height: '64px' }} />
          {rJSX_DefaultPageContent()}
        </Box>
      )
    }
    return contentJSX
  }

  const rJSX_NotificationIconLink = (): JSX.Element => {
    let iconJSX = <></>
    // Hide if small screen and nav is open
    let smallScreenAndNavOpen = false
    if (uc_UserInterface_NavBarDisplay === true && (us_screenSize === 'xs' || us_screenSize === 'sm')) {
      smallScreenAndNavOpen = true
    }
    let notificationBadgeInvisible = true
    if (smallScreenAndNavOpen === false) {
      if (
        uc_UserInterface_NavBadges != null &&
        uc_UserInterface_NavBadges.counts != null &&
        (uc_UserInterface_NavBadges.counts['unreadAlerts'] > 0 ||
          uc_UserInterface_NavBadges.counts['unreadMessages'] > 0 ||
          uc_UserInterface_NavBadges.counts['unreadReminders'] > 0)
      ) {
        notificationBadgeInvisible = false
      }
      iconJSX = (
        <Link to={ApplicationMajorPages.AllAlertsListPage.url()}>
          <IconButton
            sx={{
              color: pr_pageKey === 'AllAlertsListPage' ? themeVariables.primary_main : themeVariables.white,
              height: '40px',
              width: '40px',
              marginTop: '0px',
            }}
            className="tw-inline-block"
            onClick={() => {}}
          >
            <Badge
              color="error"
              className="tw-align-super tw-mt-0.5"
              variant="dot"
              invisible={notificationBadgeInvisible}
              badgeContent={<></>}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Icon
                type={pr_pageKey === 'AllAlertsListPage' ? 'solid' : 'thin'}
                icon="bell"
              />
            </Badge>
          </IconButton>
        </Link>
      )
    }
    return iconJSX
  }

  const rJSX_RegionSelectionTile = (clientKey: string, region: TsInterface_UnspecifiedObject): JSX.Element => {
    let tileJSX = <></>
    let isSelectedRegion = getProp(uc_RootData_ClientUser, 'associated_visible_region_key', null) === region.key
    if (region.region_photo_url != null) {
      tileJSX = (
        <Box
          className="tw-rounded-xl tw-p-2 tw-m-2 tw-inline-block tw-cursor-pointer tw-text-center tw-font-bold tw-text-lg tw-align-top"
          sx={{
            'width': '250px',
            'height': '250px',
            'filter': isSelectedRegion ? 'grayscale(0)' : 'grayscale(1)',
            'opacity': isSelectedRegion ? '100%' : '100%',
            'border': isSelectedRegion ? '2px solid ' + themeVariables.warning_main : '2px solid ' + themeVariables.background_paper,
            'background': themeVariables.black,
            '&:hover': {
              filter: isSelectedRegion ? 'grayscale(0)' : 'grayscale(0.5)',
              opacity: isSelectedRegion ? '100%' : '100%',
              background: themeVariables.background_paper,
            },
          }}
          onClick={() => {
            let updateObject = {
              associated_visible_region_key: region.key,
              associated_visible_region_name: region.name,
            }
            DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(clientKey, uc_RootData_ClientUser.key), updateObject)
              .then((res_DAD) => {
                uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                us_setViewingRegionSelectionDialog(false)
              })
              .catch((rej_DAD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
              })
          }}
        >
          <Avatar
            variant="rounded"
            className="tw-mx-auto tw-my-2"
            src={region.region_photo_url}
            sx={{ width: 190, height: 190 }}
          />
          {region['name']}
        </Box>
      )
    } else {
      tileJSX = (
        <Box
          className="tw-rounded-xl tw-p-2 tw-m-2 tw-inline-block tw-cursor-pointer tw-text-center tw-font-bold tw-text-lg tw-align-top"
          sx={{
            'width': '250px',
            'height': '250px',
            'filter': isSelectedRegion ? 'grayscale(0)' : 'grayscale(1)',
            'opacity': isSelectedRegion ? '100%' : '100%',
            'border': isSelectedRegion ? '2px solid ' + themeVariables.warning_main : '2px solid ' + themeVariables.background_paper,
            'background': themeVariables.black,
            '&:hover': {
              filter: isSelectedRegion ? 'grayscale(0)' : 'grayscale(0.5)',
              opacity: isSelectedRegion ? '100%' : '100%',
              background: themeVariables.background_paper,
            },
          }}
          onClick={() => {
            let updateObject = {
              associated_visible_region_key: region.key,
              associated_visible_region_name: region.name,
            }
            DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(clientKey, uc_RootData_ClientUser.key), updateObject)
              .then((res_DAD) => {
                uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                us_setViewingRegionSelectionDialog(false)
              })
              .catch((rej_DAD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
              })
          }}
        >
          <Box sx={{ marginTop: '100px' }}>{region['name']}</Box>
        </Box>
      )
    }

    return tileJSX
  }

  const rJSX_RegionIconLink = (): JSX.Element => {
    let iconJSX = <></>
    // Hide if small screen and nav is open
    let smallScreenAndNavOpen = false
    if (uc_UserInterface_NavBarDisplay === true && (us_screenSize === 'xs' || us_screenSize === 'sm')) {
      smallScreenAndNavOpen = true
    }
    if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.user_role === 'admin' && smallScreenAndNavOpen === false) {
      iconJSX = (
        <IconButton
          sx={{
            color: us_viewingRegionSelectionDialog === true ? themeVariables.success_main : themeVariables.white,
            height: '40px',
            width: '40px',
            marginTop: '0px',
          }}
          className="tw-inline-block"
          onClick={() => {
            let promiseArray: TsType_UnknownPromise[] = []
            let allRegions: TsInterface_UnspecifiedObject = {}
            let visibleRegions: TsInterface_UnspecifiedObject = {}
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                // Load Regions and Visible Regions
                promiseArray.push(
                  DatabaseGetCollection(DatabaseRef_Regions_Collection(res_GCK.clientKey))
                    .then((res_DGC) => {
                      allRegions = res_DGC.data
                    })
                    .catch((rej_DGC) => {
                      console.error(rej_DGC)
                    }),
                )
                // After Regions and Visible Regions are loaded
                Promise.all(promiseArray).finally(() => {
                  // TODO - limit visible regions to only those that are visible to the user
                  for (let loopRegionKey in allRegions) {
                    let loopRegion = allRegions[loopRegionKey]
                    if (loopRegion != null && loopRegion.status === 'active') {
                      visibleRegions[loopRegionKey] = loopRegion
                    }
                  }

                  // Open Dialog
                  us_setViewingRegionSelectionDialog(true)
                  uc_setUserInterface_CustomDialogDisplay({
                    display: true,
                    dialog: {
                      dialog_jsx: (
                        <Dialog
                          className="etw_region_selection_dialog"
                          keepMounted
                          onClose={() => {
                            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                            us_setViewingRegionSelectionDialog(false)
                          }}
                          open={true}
                        >
                          <Box className="tw-p-4 tw-text-center">
                            {objectToArray(visibleRegions)
                              .sort(dynamicSort('name', 'asc'))
                              .map((region, index) => (
                                <React.Fragment key={index}>{rJSX_RegionSelectionTile(res_GCK.clientKey, region)}</React.Fragment>
                              ))}
                          </Box>
                        </Dialog>
                      ),
                      settings: {
                        max_width: 'lg',
                      },
                    },
                  })
                })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          }}
        >
          <Icon
            type={us_viewingRegionSelectionDialog === true ? 'solid' : 'thin'}
            icon="compass"
            className="tw-align-super"
          />
        </IconButton>
      )
    }
    return iconJSX
  }

  const rJSX_InfoIconLink = (): JSX.Element => {
    let iconJSX = <></>
    // Hide if small screen and nav is open
    let smallScreenAndNavOpen = false
    if (uc_UserInterface_NavBarDisplay === true && (us_screenSize === 'xs' || us_screenSize === 'sm')) {
      smallScreenAndNavOpen = true
    }

    if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.user_role === 'admin' && smallScreenAndNavOpen === false) {
      iconJSX = (
        <IconButton
          sx={{
            color: uc_UserInterface_PageContentViewType === 'info' ? themeVariables.success_main : themeVariables.white,
            height: '40px',
            width: '40px',
            marginTop: '0px',
          }}
          className="tw-inline-block"
          onClick={() => {
            if (uc_UserInterface_PageContentViewType !== 'info') {
              uc_setUserInterface_PageContentViewType('info')
            } else {
              uc_setUserInterface_PageContentViewType('default')
            }
          }}
        >
          <Icon
            type={uc_UserInterface_PageContentViewType === 'info' ? 'solid' : 'thin'}
            icon="circle-info"
            className="tw-align-super"
          />
        </IconButton>
      )
    }
    return iconJSX
  }

  const rJSX_UserSettingsIconLink = (): JSX.Element => {
    let iconJSX = <></>
    // Hide if small screen and nav is open
    let smallScreenAndNavOpen = false
    if (uc_UserInterface_NavBarDisplay === true && (us_screenSize === 'xs' || us_screenSize === 'sm')) {
      smallScreenAndNavOpen = true
    }
    if (smallScreenAndNavOpen === false) {
      iconJSX = (
        <Link to={ApplicationMajorPages.UserSettingsPage.url()}>
          <IconButton
            sx={{
              color: pr_pageKey === 'UserSettingsPage' ? themeVariables.primary_main : themeVariables.white,
              height: '40px',
              width: '40px',
              marginTop: '0px',
            }}
            className="tw-inline-block"
            onClick={() => {}}
          >
            <Icon
              type={pr_pageKey === 'UserSettingsPage' ? 'solid' : 'thin'}
              icon="circle-user"
              className="tw-align-super"
            />
          </IconButton>
        </Link>
      )
    }
    return iconJSX
  }

  const rJSX_GlobalProjectSearchResult = (
    option: TsInterface_UnspecifiedObject,
    searchInputValue: string | null,
    inputHooks: TsInterface_InputHooksObject,
    additionalSearchData: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    let searchResultJSX = (
      <Box sx={{ marginLeft: '8px', marginRight: '8px' }}>
        <Typography>
          {rLIB('Job Code')}: {rJSX_HighlightedSearchString(searchInputValue, option.id_number)}{' '}
        </Typography>
        <Typography>
          {rLIB('Customer')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_customer_name)}{' '}
        </Typography>
        <Typography>
          {rLIB('Sales Rep')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_sales_rep_name)}{' '}
        </Typography>
        <Typography>
          {rLIB('CSS Rep')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_css_rep_name)}{' '}
        </Typography>
        <Typography>
          {rLIB('Status')}: {rJSX_HighlightedSearchString(searchInputValue, option.status)}{' '}
        </Typography>
        <Typography>
          {rLIB('Address')}: {rJSX_HighlightedSearchString(searchInputValue, option.location_address)}{' '}
        </Typography>
        <Button
          tabIndex={0}
          color={'info'}
          onKeyDown={(event) => {
            if (event.key === 'ArrowDown') {
              event.preventDefault() // Prevent default arrow down behavior
              const activeElement = document.activeElement

              console.log(activeElement)

              let currentIndex = 0
              if (activeElement === document.getElementById('search_input')) {
                console.log('HIGHLGIHTING SEARCH 1')
                currentIndex = -1
              } else {
                console.log('HIGHLGIHTING OPTION 1')
                try {
                  currentIndex = inputHooks.us_searchOptions.findIndex((option: any) => inputHooks.optionRefs.current[option.id].current === activeElement)
                } catch (err) {
                  console.error(err)
                }
              }

              console.log(currentIndex)

              const nextIndex = currentIndex + 1 < inputHooks.us_searchOptions.length ? currentIndex + 1 : 0

              console.log(nextIndex)

              // refs.current[us_searchOptions[nextIndex].id].current.focus()
            }
          }}
          onClick={(event) => {
            if (option.id != null) {
              uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
              // TODO - handle other user types probably
              onClickAppNavigation(event, inputHooks.un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(option.id as string))
            }
          }}
          variant="contained"
          sx={{ marginBottom: '5px' }}
        >
          <Icon icon="magnifying-glass" />
          {rLIB('View Project')}
        </Button>
        <Divider />
      </Box>
    )
    return searchResultJSX
  }

  const openProjectSearchDialog = (): void => {
    if (getProp(uc_RootData_UserPermissions, 'AdminActiveProjectsListPage', false) === true) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          uc_setUserInterface_CustomDialogDisplay({
            display: true,
            dialog: {
              dialog_jsx: (
                <Dialog
                  className="etw_region_selection_dialog"
                  keepMounted
                  onClose={() => {
                    uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                    us_setViewingRegionSelectionDialog(false)
                  }}
                  open={true}
                >
                  <Box>
                    <Typography
                      variant="h5"
                      component="div"
                      className="tw-font-bold tw-mb-2"
                    >
                      {rLIB('Search Projects')}
                    </Typography>
                    <SearchInput
                      clientKey={res_GCK.clientKey}
                      searchIndexKey={'projects'}
                      searchFilters={[]}
                      searchResultRenderer={rJSX_GlobalProjectSearchResult}
                      additionalSearchData={{}}
                      sx={{
                        width: '400px',
                        background: themeVariables.background_json,
                      }}
                    />
                    <Box sx={{ height: '30vh' }}></Box>
                  </Box>
                </Dialog>
              ),
              settings: {
                max_width: 'lg',
              },
            },
          })
          // Set Focus
          setTimeout(() => {
            console.log(document.getElementById('search_input'))
            document.getElementById('search_input')?.focus()
          }, 100)
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  }

  const NavSearchDialog = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const navigate = useNavigate()

    const pages = React.useMemo(() => {
      const permittedPages: { key: string; nameText: string; url: string }[] = []

      Object.keys(sideBarNavObject).forEach((sectionKey) => {
        const section = sideBarNavObject[sectionKey]
        if (section && section.links) {
          Object.keys(section.links).forEach((linkKey) => {
            const link = section.links[linkKey]
            if (link) {
              let nameText = ''
              if (React.isValidElement(link.name)) {
                const props = link.name.props as { children?: React.ReactNode }
                // eslint-disable-next-line react/prop-types
                nameText = typeof props.children === 'string' ? props.children : ''
              } else if (typeof link.name === 'string') {
                nameText = link.name
              }

              permittedPages.push({
                key: linkKey,
                nameText,
                url: link.url,
              })
            }
          })
        }
      })

      return permittedPages
    }, [])

    const [searchQuery, setSearchQuery] = React.useState('')
    const [filteredPages, setFilteredPages] = React.useState(pages)
    const [focusedIndex, setFocusedIndex] = React.useState(0) // Default to the first option

    const listRef = React.useRef<HTMLUListElement>(null)
    const inputRef = React.useRef<HTMLInputElement>(null)

    React.useEffect(() => {
      const updatedFilteredPages = pages.filter((page) => page.nameText.toLowerCase().includes(searchQuery.toLowerCase()))
      setFilteredPages(updatedFilteredPages)
      setFocusedIndex(0) // Auto-select the first option when search changes
    }, [searchQuery, pages])

    React.useEffect(() => {
      if (isOpen && inputRef.current) {
        inputRef.current.focus()
      }
    }, [isOpen])

    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault()
        setFocusedIndex((prevIndex) => {
          const nextIndex = prevIndex < filteredPages.length - 1 ? prevIndex + 1 : 0
          scrollToFocused(nextIndex)
          return nextIndex
        })
      } else if (event.key === 'ArrowUp') {
        event.preventDefault()
        setFocusedIndex((prevIndex) => {
          const nextIndex = prevIndex > 0 ? prevIndex - 1 : filteredPages.length - 1
          scrollToFocused(nextIndex)
          return nextIndex
        })
      } else if (event.key === 'Enter' && focusedIndex >= 0) {
        event.preventDefault()
        const selectedPage = filteredPages[focusedIndex]
        if (selectedPage) {
          onClose()
          navigate(selectedPage.url)
        }
      }
    }

    const scrollToFocused = (index: number) => {
      if (listRef.current) {
        const item = listRef.current.children[index]
        if (item) {
          item.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
        }
      }
    }

    return (
      <Dialog
        className="etw_nav_search_dialog"
        keepMounted
        onClose={onClose}
        open={isOpen}
        maxWidth={false}
        PaperProps={{
          sx: {
            width: '50%',
            maxWidth: '800px',
            boxShadow: 'none',
            background: 'none',
            color: '#fff',
          },
        }}
      >
        <Box sx={{ padding: '20px', margin: '0 auto' }}>
          <TextField
            inputRef={inputRef}
            fullWidth
            placeholder="Search for a page..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus
            sx={{
              'marginBottom': '20px',
              '& .MuiInputBase-input': {
                fontSize: '1.2rem',
              },
            }}
          />
          <List
            ref={listRef}
            sx={{
              maxHeight: '60vh',
              overflowY: 'auto',
              padding: '10px',
            }}
          >
            {filteredPages.map((page, index) => (
              <ListItemButton
                key={page.key}
                onClick={() => {
                  onClose()
                  navigate(page.url)
                }}
                selected={index === focusedIndex}
                sx={{
                  'borderRadius': '8px',
                  'padding': '10px',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  },
                  '&.Mui-selected': {
                    outline: '2px solid #1976d2',
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'bold' }}
                >
                  {page.nameText}
                </Typography>
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Dialog>
    )
  }

  const openNavSearch = (): void => {
    uc_setUserInterface_CustomDialogDisplay({
      display: true,
      dialog: {
        dialog_jsx: (
          <NavSearchDialog
            isOpen={true}
            onClose={() => uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)}
          />
        ),
        settings: {
          max_width: 'md',
        },
      },
    })
  }

  const getOperatingSystem = (): 'Mac' | 'Windows' | 'Other' => {
    // Check if `navigator.userAgentData` exists and is supported
    if ('userAgentData' in navigator) {
      const userAgentData = (navigator as any).userAgentData
      const platform = userAgentData.platform.toLowerCase()
      if (platform.includes('mac')) return 'Mac'
      if (platform.includes('win')) return 'Windows'
      return 'Other'
    }

    // Fallback to `navigator.userAgent`
    const userAgent = navigator.userAgent.toLowerCase()
    if (userAgent.includes('mac')) return 'Mac'
    if (userAgent.includes('win')) return 'Windows'
    return 'Other'
  }

  const openHotKeysDialog = (): void => {
    const os = getOperatingSystem()
    const isMac = os === 'Mac'

    uc_setUserInterface_CustomDialogDisplay({
      display: true,
      dialog: {
        dialog_jsx: (
          <Dialog
            className="etw_hotkeys_dialog"
            keepMounted
            onClose={() => {
              uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
            }}
            open={true}
            fullWidth
            maxWidth="xl"
            PaperProps={{
              sx: {
                padding: '20px',
                maxWidth: '30%',
                width: '90%',
                borderRadius: '12px',
                margin: 'auto',
              },
            }}
          >
            <Box>
              <Typography
                variant="h5"
                component="div"
                className="tw-font-bold"
                sx={{
                  textAlign: 'center',
                  marginBottom: '20px',
                  color: 'white',
                }}
              >
                Hotkeys
              </Typography>

              <Card
                sx={{
                  padding: '20px',
                  marginBottom: '24px',
                }}
              >
                {/* Navigation Section */}
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      marginBottom: '12px',
                      color: 'white',
                    }}
                  >
                    Navigation
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: 'left',
                      marginBottom: '24px',
                      color: 'white',
                    }}
                  >
                    <span style={{ color: themeVariables.primary_main }}>{isMac ? 'Cmd + Shift + key' : 'Ctrl + Shift + key'}</span>
                    : Navigate to the page.
                    <br />
                    <span style={{ color: themeVariables.primary_main }}>{isMac ? 'Cmd + Option + key' : 'Ctrl + Alt + key'}</span>: Open the page in a new tab.
                  </Typography>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '12px',
                    }}
                  >
                    {[
                      { name: 'Open Projects', key: 'P' },
                      { name: 'Open Scheduler', key: 'C' },
                    ].map(({ name, key }) => (
                      <Box
                        key={key}
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'space-between',
                          padding: '8px 12px',
                          border: '1px solid white',
                          borderRadius: '8px',
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 'bold' }}
                        >
                          {name}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            fontStyle: 'italic',
                            color: themeVariables.primary_main,
                          }}
                        >
                          {key}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Card>

              <Card
                sx={{
                  padding: '20px',
                }}
              >
                {/* Actions Section */}
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      marginBottom: '12px',
                      color: 'white',
                    }}
                  >
                    Actions
                  </Typography>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '12px',
                    }}
                  >
                    {[
                      { name: 'Project Search', hotkey: isMac ? 'Cmd + Shift + S' : 'Ctrl + Shift + S' },
                      { name: 'Navigation Search', hotkey: isMac ? 'Cmd + Shift + K' : 'Ctrl + Shift + K' },
                      { name: 'Open Hotkeys', hotkey: isMac ? 'Cmd + Shift + H' : 'Ctrl + Shift + H' },
                      { name: 'Toggle Side Menu', hotkey: isMac ? 'Cmd + B' : 'Ctrl + B' }, // Side menu action
                    ].map(({ name, hotkey }) => (
                      <Box
                        key={name}
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'space-between',
                          padding: '8px 12px',
                          border: '1px solid white',
                          borderRadius: '8px',
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 'bold' }}
                        >
                          {name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: themeVariables.primary_main,
                          }}
                        >
                          {hotkey}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Card>
            </Box>
          </Dialog>
        ),
        settings: {
          max_width: 'xl',
        },
      },
    })
  }

  const getInitialExpandedAccordions = (): Set<string> => {
    const saved = localStorage.getItem('expandedAccordions')
    return saved ? new Set(JSON.parse(saved)) : new Set()
  }

  const [expandedAccordions, setExpandedAccordions] = useState<Set<string>>(getInitialExpandedAccordions)

  useEffect(() => {
    localStorage.setItem('expandedAccordions', JSON.stringify(Array.from(expandedAccordions)))
  }, [expandedAccordions])

  const toggleAccordion = (sectionKey: string) => {
    setExpandedAccordions((prev) => {
      const newExpanded = new Set(prev)
      if (newExpanded.has(sectionKey)) {
        newExpanded.delete(sectionKey)
      } else {
        newExpanded.add(sectionKey)
      }

      return newExpanded
    })
  }

  const isExpanded = (sectionKey: string): boolean => {
    return expandedAccordions.has(sectionKey)
  }

  const rJSX_NavBar = (): JSX.Element => {
    const isSectionActive = (sectionKey: string): boolean => {
      const section = sideBarNavObject[sectionKey]
      return Object.keys(section.links).some((linkKey) => checkIfNavSectionIsActive(sectionKey, linkKey))
    }

    let navBarJSX = <></>

    if (us_navType === 'hamburger') {
      navBarJSX = (
        <Box>
          <Drawer
            sx={{
              'width': drawerWidth,
              'flexShrink': 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant="persistent"
            anchor="left"
            open={uc_UserInterface_NavBarDisplay}
          >
            <Box
              sx={{
                background: 'rgb(39, 43, 51)',
                height: '100vh',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
              }}
              id="etw_side_nav"
            >
              <DrawerHeader sx={itemCategory}>
                <Box style={{ width: '100%', textAlign: 'left', height: '35px' }}>
                  <Box
                    className="tw-m-0"
                    component="img"
                    sx={{ width: 126, height: 36 }}
                    alt="logo"
                    src={logoSrc}
                  />
                </Box>
              </DrawerHeader>

              <List
                disablePadding
                sx={{ flexGrow: 1 }}
              >
                {Object.keys(sideBarNavObject).map((sectionKey) => {
                  const section = sideBarNavObject[sectionKey]
                  const sectionName = ApplicationNavSections[sectionKey]?.name || 'Unnamed Section'

                  return (
                    <Accordion
                      key={sectionKey}
                      expanded={isExpanded(sectionKey)}
                      disableGutters
                      sx={{
                        flexGrow: 1,
                      }}
                      onChange={() => toggleAccordion(sectionKey)}
                    >
                      <AccordionSummary
                        sx={{
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)', // Subtle hover effect
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            color: isSectionActive(sectionKey) ? selectedNavColor : 'rgba(255, 255, 255, 0.7)', // Blue for active, grey for inactive
                            fontWeight: 'bold', // Make text bold for all main sections
                          }}
                        >
                          {sectionName}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          padding: 0, // Remove all padding from the container
                          margin: 0, // Remove any unintended margin
                        }}
                      >
                        {Object.keys(section.links).map((navLinkKey) => {
                          const link = section.links[navLinkKey]

                          return (
                            <Link
                              to={link.url}
                              key={link.key}
                              style={{ textDecoration: 'none', width: '100%' }} // Ensure the link spans the full width
                            >
                              <ListItem
                                disablePadding
                                sx={{
                                  width: '100%',
                                  padding: 0, // Remove all padding from the ListItem
                                }}
                              >
                                <ListItemButton
                                  sx={{
                                    width: '100%', // Ensure the button spans the full width
                                    padding: 0, // Remove padding from the ListItemButton
                                    ...returnNavLinkStyle(checkIfNavSectionIsActive(sectionKey, navLinkKey)),
                                  }}
                                >
                                  <ListItemIcon
                                    sx={{
                                      ...returnNavIconStyle(checkIfNavSectionIsActive(sectionKey, navLinkKey)),
                                    }}
                                  >
                                    {React.isValidElement(link.icon) ? link.icon : <span>?</span>}
                                  </ListItemIcon>
                                  <ListItemText>{typeof link.name === 'string' || React.isValidElement(link.name) ? link.name : 'Invalid Link'}</ListItemText>
                                </ListItemButton>
                              </ListItem>
                            </Link>
                          )
                        })}
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </List>
            </Box>
          </Drawer>
        </Box>
      )
    }

    return navBarJSX
  }

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //

  const rJSX_NavButton = (open: boolean): JSX.Element => {
    let navButtonJSX = <></>
    // @ts-ignore
    if (us_navType === 'hamburger') {
      if (open === true) {
        navButtonJSX = (
          <Box className="tw-inline-block tw-float-left">
            <IconButton
              sx={{ marginLeft: '-12px', marginTop: '-4px', height: '40px', width: '40px', ...(uc_UserInterface_NavBarDisplay && { display: 'none' }) }}
              className="tw-inline-block tw-mr-1"
              onClick={() => {
                uc_setUserInterface_NavBarDisplay(false)
              }}
            >
              <Icon
                icon="chevron-left"
                className="tw-align-top"
              />
            </IconButton>
          </Box>
        )
      } else {
        navButtonJSX = (
          <Box
            className="tw-inline-block tw-float-left"
            // sx={{ marginTop: '-9px' }}
          >
            <IconButton
              sx={{ marginLeft: '-12px', marginTop: '-4px', height: '40px', width: '40px', ...(!uc_UserInterface_NavBarDisplay && { display: 'none' }) }}
              className="tw-inline-block tw-mr-1"
              onClick={() => {
                uc_setUserInterface_NavBarDisplay(true)
              }}
            >
              <Icon
                icon="bars"
                className="tw-align-top"
              />
            </IconButton>
          </Box>
        )
      }
    }
    return navButtonJSX
  }

  const stopMirroringUser = (uc_RootData_GlobalUser: TsInterface_RootData_GlobalUser) => {
    if (uc_RootData_GlobalUser != null && uc_RootData_GlobalUser.original_user_key != null) {
      const clientKey = uc_RootData_GlobalUser.client_key || 'default_client_key' // Ensure client_key is valid

      // Use the cloud function to stop mirroring
      cloudFunctionManageRequest('manageUser', {
        function: 'stopMirroringUser',
        original_user_key: uc_RootData_GlobalUser.original_user_key, // Pass the original user key
        client_key: clientKey, // Pass client key if needed
      })
        .then(() => {
          // The app will re-render based on real-time database listeners
        })
        .catch((error) => {
          console.error('Error stopping mirroring user:', error)
        })
    } else {
      console.error('Original user key or client key is missing or invalid.')
    }
  }

  const rJSX_MirroringBanner = (uc_RootData_GlobalUser: TsInterface_RootData_GlobalUser): JSX.Element => {
    let bannerJSX = <></>

    // Check if the user is mirroring someone
    if (uc_RootData_GlobalUser.isMirroring === true && uc_RootData_GlobalUser.currently_mirroring_name) {
      bannerJSX = (
        <Box
          sx={{
            background: themeVariables.info_main,
            position: 'fixed',
            top: '0px',
            left: '50%',
            transform: 'translateX(-50%)', // Center the box horizontally
            width: 'auto', // Dynamic width based on content
            padding: '0 16px', // Add padding for breathing room around the content
            height: '25px',
            textAlign: 'center',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            display: 'flex',
            alignItems: 'center', // Vertically center content
            justifyContent: 'center', // Horizontally center content
            zIndex: 9999,
          }}
        >
          <Icon
            icon="user-secret"
            className="tw-mr-2"
          />
          Currently Mirroring: {uc_RootData_GlobalUser.currently_mirroring_name}
          <Button
            variant="contained"
            color="secondary"
            sx={{
              ml: 2, // Margin-left for spacing
              padding: '2px 6px', // Shrink padding
              fontSize: '0.68rem', // Shrink text size
              minWidth: '50px', // Adjust width to make it smaller
            }}
            onClick={() => {
              // Call stopMirroringUser to stop mirroring the user
              stopMirroringUser(uc_RootData_GlobalUser)
            }}
          >
            Stop
          </Button>
        </Box>
      )
    }

    return bannerJSX
  }

  const rJSX_Component = (): JSX.Element => {
    let authContentJSX = <Box></Box>
    if (uc_RootData_AuthenticatedUser == null || uc_RootData_AuthenticatedUser.loggedIn == null) {
      authContentJSX = (
        <Box className="tw-text-center tw-p-4">
          <CircularProgress />
        </Box>
      )
    } else if (uc_RootData_AuthenticatedUser != null && uc_RootData_AuthenticatedUser.loggedIn === false) {
      authContentJSX = (
        <Navigate
          to={ApplicationPages.UnauthenticatedLoginPage.url()}
          replace
        />
      )
    } else if (!authorizedToViewPage) {
      authContentJSX = (
        <Navigate
          to={ApplicationPages.HomePage.url()}
          replace
        />
      )
    } else {
      authContentJSX = (
        <Box
          component="div"
          className="tw-flex"
        >
          {/* @ts-ignore */}
          <AppBar
            position="fixed"
            // @ts-ignore
            open={uc_UserInterface_NavBarDisplay}
            sx={{ boxShadow: 'none' }}
          >
            {topLoadingBar}
            <Toolbar
              sx={{
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                backgroundColor: themeVariables.background_default,
                boxShadow: 'none',
                paddingTop: '20px',
              }}
            >
              <Box sx={{ maxWidth: 'calc(100% - 192px)' }}>
                {rJSX_NavButton(uc_UserInterface_NavBarDisplay)}
                <Typography
                  variant="h5"
                  component="div"
                  className="tw-font-bold tw-truncate"
                  // sx={{ maxWidth: 'calc(100% - 192px)' }}
                >
                  {pr_pageHeader}
                </Typography>
              </Box>
              <Box
                className="tw-inline-block tw-float-right"
                sx={{ width: '160px', maxWidth: '160px' }}
              >
                {rJSX_NotificationIconLink()}
                {/* {rJSX_RegionIconLink()} */}
                {rJSX_InfoIconLink()}
                {rJSX_UserSettingsIconLink()}
              </Box>
              {rJSX_StagingWarning()}
              {rJSX_MirroringBanner(uc_RootData_GlobalUser)}
            </Toolbar>
          </AppBar>
          {rJSX_NavBar()}
          {/* @ts-ignore */}
          <Main
            className={getMainSectionPadding(false)}
            // @ts-ignore
            open={uc_UserInterface_NavBarDisplay}
          >
            {rJSX_PageContent()}
            {/* @ts-ignore */}
            <style>{navCSS}</style>
            <GlobalKeypressListener
              openNav={() => {
                uc_setUserInterface_NavBarDisplay(true)
              }}
              closeNav={() => {
                uc_setUserInterface_NavBarDisplay(false)
              }}
              navOpen={uc_UserInterface_NavBarDisplay}
              openSearch={() => {
                openProjectSearchDialog()
              }}
              openHotKeysDialog={() => {
                openHotKeysDialog()
              }}
              openNavSearch={() => {
                openNavSearch()
              }}
            />
          </Main>
        </Box>
      )
    }
    return authContentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}
