//======================================//
//		  ooOOOO BOILERPLATE TEMPLATE   //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Page used to manage access to application pages on a client by client basis

		TODO:
			[ ] Typescript - 1 isntance of any
			[ ] Typescript - 5 isntances of TsInterface_UnspecifiedObject
			[ ] Bug - Warning: A component is changing an uncontrolled input to be controlled (on checklist)

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material/'
import { tableColumns_UserReadOnly } from 'app/models/users/user_table'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages, ClientTypes, generateAvailableClientLevelPermissions } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_ClientPermissions_Document,
  DatabaseRef_ClientUser_Document,
  DatabaseRef_GlobalUsersWithToggle_Collection,
  DatabaseRef_GlobalUser_Document,
  DatabaseRef_RootClient_Collection,
  DatabaseRef_RootClient_Document,
  DatabaseRef_Users_Collection,
} from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import { Json } from 'rfbp_core/components/code_display'
import {
  Form,
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
  TsType_FormOnChange,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientUser, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import {
  TsInterface_GenericPromiseReject,
  TsInterface_GenericPromiseResolve,
  TsInterface_UnspecifiedObject,
  TsType_VoidFunction,
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey = ApplicationPages['SuperClientPermissionsManagementPage']['key']

// Tables
const tableSettings_ClientUsers: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'name',
  sortable: true,
}

const tableSettings_ToggleUsers: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'name',
  sortable: true,
}

const rJSX_ClientToggleButton = (client: TsInterface_UnspecifiedObject, user: TsInterface_UnspecifiedObject): JSX.Element => {
  let buttonJSX = <></>
  if (user != null && user['authorized_clients'] != null && client != null && client.key != null && user['authorized_clients'][client.key] === true) {
    buttonJSX = (
      <Button
        color="success"
        onClick={() => {
          let updateObject: TsInterface_UnspecifiedObject = {}
          if (user['authorized_clients'] == null) {
            updateObject['authorized_clients'] = user['authorized_clients']
          } else {
            updateObject['authorized_clients'] = {}
          }
          updateObject['authorized_clients'][client.key] = false
          DatabaseSetMergeDocument(DatabaseRef_GlobalUser_Document(user.key), updateObject)
            .then((res_DSMD) => {
              // Nothing
            })
            .catch((rej_DSMD) => {
              console.error(rej_DSMD)
            })
        }}
        variant="contained"
      >
        {client.name}
      </Button>
    )
  } else {
    buttonJSX = (
      <Button
        color="error"
        onClick={() => {
          let updateObject: TsInterface_UnspecifiedObject = {}
          if (user['authorized_clients'] == null) {
            updateObject['authorized_clients'] = user['authorized_clients']
          } else {
            updateObject['authorized_clients'] = {}
          }
          updateObject['authorized_clients'][client.key] = true
          DatabaseSetMergeDocument(DatabaseRef_GlobalUser_Document(user.key), updateObject)
            .then((res_DSMD) => {
              // Nothing
            })
            .catch((rej_DSMD) => {
              console.error(rej_DSMD)
            })
        }}
        variant="outlined"
      >
        {client.name}
      </Button>
    )
  }
  return buttonJSX
}

const rJSX_AddToClientButton = (client: TsInterface_UnspecifiedObject, user: TsInterface_UnspecifiedObject): JSX.Element => {
  let buttonJSX = <></>
  buttonJSX = (
    <Button
      color="info"
      variant="outlined"
      onClick={() => {
        let updateObject: TsInterface_UnspecifiedObject = {
          email: getProp(user, 'name', null),
          key: getProp(user, 'name', null),
          name: getProp(user, 'name', null),
          status: 'active',
          user_role: 'admin',
        }
        DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(client.key, user.key), updateObject)
          .then((res_DSMD) => {
            // Nothing
          })
          .catch((rej_DSMD) => {
            console.error(rej_DSMD)
          })
      }}
    >
      {client.name}
    </Button>
  )
  return buttonJSX
}

const tableColumns_ToggleUsers: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('User Name'), 'name'),
  key: TableCellBasic('key', rLIB('Key'), 'key'),
  client_key: TableCellBasic('client_key', rLIB('Current Client Key'), 'client_key'),
  authorized_clients: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Authorized Clients')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (tableAdditionalData != null && tableAdditionalData['clientsList']) {
          cellJSX = (
            <Box>
              <Box>
                {objectToArray(tableAdditionalData['clientsList'])
                  .sort(dynamicSort('name', 'asc'))
                  .map((client, index) => (
                    <Box
                      key={index}
                      className="tw-p-1 tw-inline-block"
                    >
                      {rJSX_ClientToggleButton(client, rowData)}
                    </Box>
                  ))}
              </Box>
              <Box>
                {objectToArray(tableAdditionalData['clientsList'])
                  .sort(dynamicSort('name', 'asc'))
                  .map((client, index) => (
                    <Box
                      key={index}
                      className="tw-p-1 tw-inline-block"
                    >
                      {rJSX_AddToClientButton(client, rowData)}
                    </Box>
                  ))}
              </Box>
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_clientLevelPermissions, us_setClientLevelPermissions] = useState<TsInterface_UnspecifiedObject>({})
  const [us_clientUsers, us_setClientUsers] = useState<TsInterface_UnspecifiedObject>({})
  const [us_clientUsersLoaded, us_setClientUsersLoaded] = useState<boolean>(false)
  const [us_clientsList, us_setClientsList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formData_ClientType, us_setFormData_ClientType] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedClientKey, us_setSelectedClientKey] = useState<string>('')
  const [us_toggleUsers, us_setToggleUsers] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  // useEffect(() => {
  // 	document.title = rLIB("Client Permissions", false) as string
  // }, [ ])

  useEffect(() => {
    // Get Client Level Permissions
    us_setClientLevelPermissions(generateAvailableClientLevelPermissions(uc_RootData_ClientUser))
    return () => {}
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setClientsList(newData)
      ur_forceRerender()
    }
    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_RootClient_Collection(), updateLiveData)
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setFormData_ClientType(newData)
      ur_forceRerender()
    }
    if (us_selectedClientKey != null && us_selectedClientKey !== '') {
      unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ClientPermissions_Document(us_selectedClientKey), updateLiveData)
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender, us_selectedClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setToggleUsers(newData)
      ur_forceRerender()
    }
    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_GlobalUsersWithToggle_Collection(), updateLiveData)
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender])

  // Other Variables
  let formAdditionalData_ClientType: TsInterface_FormAdditionalData = {}

  let formInputs_ClientType: TsInterface_FormInputs = {
    client_type: {
      data_type: 'string',
      input_type: 'multiple_choice_radio',
      key: 'client_type',
      label: rLIB('Client Type'),
      options: [],
      required: true,
    },
  }

  let formSettings_ClientType: TsInterface_FormSettings = {
    highlight_missing: true,
    submit_button_alignment: 'left',
    submit_button_saving_icon: true,
    submit_button_text: rLIB('Update Client Type') as JSX.Element,
    submit_button_theme: 'primary',
  }

  let tableAdditionalData_ClientUsers: TsInterface_TableAdditionalData = {}
  let tableAdditionalData_ToggleUsers: TsInterface_TableAdditionalData = { clientsList: us_clientsList }

  // Functions
  const returnClientTypeFormOptions = (): TsInterface_UnspecifiedObject[] => {
    let options = []
    for (let clientTypeKey in ClientTypes) {
      options.push({ key: clientTypeKey, value: ClientTypes[clientTypeKey]['name'] })
    }
    // formInputs_ClientType["client_type"]["options"] = options
    return options
  }

  const selectClientKey = (event: SelectChangeEvent<any>): void => {
    us_setSelectedClientKey(event.target.value)
    us_setClientUsers({})
    us_setClientUsersLoaded(false)
    ur_forceRerender()
  }

  const togglePermission = (event: React.ChangeEvent<HTMLInputElement>, permissionKey: string, permissionValue: string | boolean): void => {
    let permissionUpdateObject: TsInterface_UnspecifiedObject = {}
    permissionUpdateObject[permissionKey] = permissionValue
    DatabaseSetMergeDocument(DatabaseRef_ClientPermissions_Document(us_selectedClientKey), permissionUpdateObject)
      .then((res_DSMD) => {
        DatabaseGetDocument(DatabaseRef_ClientPermissions_Document(us_selectedClientKey)).then((res_DGD) => {
          us_setFormData_ClientType(res_DGD.data)
        })
      })
      .catch((rej_DSMD) => {
        console.error(rej_DSMD)
      })
  }

  const formOnChange_ClientType: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings, formHooks) => {
    // Nothing
  }

  const formSubmission_ClientType = (
    submittedFormData: TsInterface_UnspecifiedObject,
    formAdditionalData: TsInterface_UnspecifiedObject,
  ): Promise<TsInterface_GenericPromiseReject | TsInterface_GenericPromiseResolve> => {
    return new Promise((resolve, reject) => {
      DatabaseSetMergeDocument(DatabaseRef_ClientPermissions_Document(us_selectedClientKey), submittedFormData)
        .then((res_DSMD) => {
          ur_forceRerender()
          resolve(res_DSMD)
        })
        .catch((rej_DSMD) => {
          console.error(rej_DSMD)
          reject(rej_DSMD)
        })
    })
  }

  const loadClientUsers = (clientKey: string): void => {
    if (clientKey != null) {
      DatabaseGetCollection(DatabaseRef_Users_Collection(clientKey))
        .then((res_DGC) => {
          us_setClientUsers(res_DGC.data)
          us_setClientUsersLoaded(true)
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
        })
    }
  }

  const createNewClient = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            key: {
              key: 'key',
              label: rLIB('Client Key'),
              input_type: 'text_basic',
              required: true,
              data_type: 'string',
            },
            name: {
              key: 'name',
              label: rLIB('Client Name'),
              input_type: 'text_basic',
              required: true,
              data_type: 'string',
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              DatabaseGetDocument(DatabaseRef_RootClient_Document(formSubmittedData.key))
                .then((res_DGD) => {
                  if (res_DGD != null && res_DGD.data != null && res_DGD.data.key != null) {
                    uc_setUserInterface_ErrorDialogDisplay({
                      display: true,
                      error: {
                        message: rLIB('Failed to create client'),
                        details: rLIB('Client key already used'),
                        code: 'ER-D-CPM-CNC-01',
                      },
                    })
                  } else {
                    DatabaseSetMergeDocument(DatabaseRef_RootClient_Document(formSubmittedData.key), formSubmittedData)
                      .then((res_DSMD) => {
                        // TODO - Snackbar
                        us_setSelectedClientKey(formSubmittedData.key)
                        us_setClientUsers({})
                        us_setClientUsersLoaded(false)
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        reject(rej_DSMD)
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  }
                })
                .catch((rej_DGD) => {
                  DatabaseSetMergeDocument(DatabaseRef_RootClient_Document(formSubmittedData.key), formSubmittedData)
                    .then((res_DSMD) => {
                      // TODO - Snackbar
                      us_setSelectedClientKey(formSubmittedData.key)
                      us_setClientUsers({})
                      us_setClientUsersLoaded(false)
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      reject(rej_DSMD)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Create new Client'),
          formDialogIcon: <Icon icon="pen-to-square" />,
        },
      },
    })
  }

  formInputs_ClientType['client_type']['options'] = returnClientTypeFormOptions()

  // JSX Generation
  const rJSX_ClientToggleDropdown = (): JSX.Element => {
    let clientToggleDropdownJSX = (
      <Box>
        <Stack
          spacing={1}
          direction="row"
        >
          <FormControl sx={{ minWidth: '200px', background: themeVariables.background_paper }}>
            <InputLabel id="select_client_dropdown">{rLIB('Select Client')}</InputLabel>
            <Select
              labelId="select_client_dropdown"
              value={us_selectedClientKey}
              label={rLIB('Select Client')}
              onChange={selectClientKey}
            >
              {objectToArray(us_clientsList).map((client) => (
                <MenuItem
                  value={client.key}
                  key={client.key}
                >
                  {client.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            color="success"
            onClick={() => {
              createNewClient()
            }}
            variant="contained"
          >
            <Icon
              icon="circle-plus"
              className="tw-pr-1"
            />
            {rLIB('Create new Client')}
          </Button>
        </Stack>
      </Box>
    )
    return clientToggleDropdownJSX
  }

  const rJSX_PermissionChecklist = (): JSX.Element => {
    let clientPermissionsJSX: JSX.Element = <Box></Box>
    if (us_selectedClientKey != null && us_selectedClientKey !== '') {
      if (
        us_formData_ClientType != null &&
        us_formData_ClientType.client_type != null &&
        us_clientLevelPermissions != null &&
        us_clientLevelPermissions[us_formData_ClientType.client_type] != null
      ) {
        clientPermissionsJSX = (
          <Box className="tw-text-left tw-p-4">
            {objectToArray(us_clientLevelPermissions[us_formData_ClientType.client_type]).map((section) => (
              <Box key={section.sectionKey}>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                >
                  {section.sectionName}
                </Typography>
                <FormGroup>
                  {objectToArray(section.permissions).map((permission) => (
                    <Box key={permission.key}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={us_formData_ClientType[permission.key] || false}
                            onChange={(event, value) => {
                              togglePermission(event, permission.key, value)
                            }}
                          />
                        }
                        key={permission.key}
                        label={permission.pageName}
                      />
                    </Box>
                  ))}
                </FormGroup>
              </Box>
            ))}
          </Box>
        )
      } else {
        clientPermissionsJSX = (
          <Box className="tw-text-center tw-p-4">
            <Typography
              variant="h5"
              noWrap
              component="div"
            >
              {rLIB('The selected client is missing a valid client type')}
            </Typography>
          </Box>
        )
      }
    } else {
      clientPermissionsJSX = (
        <Box className="tw-text-center tw-p-4">
          <Typography
            variant="h5"
            noWrap
            component="div"
          >
            {rLIB('Select a client to edit permissions')}
          </Typography>
        </Box>
      )
    }
    return clientPermissionsJSX
  }

  const rJSX_ClientTypeForm = (): JSX.Element => {
    let clientTypeJSX: JSX.Element = <Box></Box>
    // Client Type Selection
    if (us_selectedClientKey != null && us_selectedClientKey !== '') {
      clientTypeJSX = (
        <Card
          className="tw-text-left tw-p-4"
          variant="outlined"
        >
          <Form
            formAdditionalData={formAdditionalData_ClientType}
            formData={us_formData_ClientType}
            formInputs={formInputs_ClientType}
            formOnChange={formOnChange_ClientType}
            formSettings={formSettings_ClientType}
            formSubmission={formSubmission_ClientType}
          />
        </Card>
      )
    }
    return clientTypeJSX
  }

  const rJSX_UsersTab = (): JSX.Element => {
    let usersTabJSX = <></>
    if (us_selectedClientKey === null || us_selectedClientKey === '') {
      usersTabJSX = (
        <Card
          className="tw-text-center tw-p-4"
          variant="outlined"
        >
          <Typography
            variant="h5"
            noWrap
            component="div"
          >
            {rLIB('Select a client to view users')}
          </Typography>
        </Card>
      )
    } else if (us_clientUsersLoaded === false) {
      usersTabJSX = (
        <Box className="tw-py-1 tw-text-center">
          <Button
            color="info"
            onClick={() => {
              loadClientUsers(us_selectedClientKey)
            }}
            variant="contained"
          >
            {rLIB('Load users for client')}
          </Button>
        </Box>
      )
    } else {
      usersTabJSX = (
        <Box>
          <Card variant="outlined">
            <TableBasic
              tableAdditionalData={tableAdditionalData_ClientUsers}
              tableColumns={tableColumns_UserReadOnly}
              tableData={objectToArray(us_clientUsers)}
              tableSettings={tableSettings_ClientUsers}
            />
          </Card>
        </Box>
      )
    }
    return usersTabJSX
  }

  const rJSX_ToggleUsersTab = (): JSX.Element => {
    let tabJSX = (
      <Box>
        <Card variant="outlined">
          <TableBasic
            tableAdditionalData={tableAdditionalData_ToggleUsers}
            tableColumns={tableColumns_ToggleUsers}
            tableData={objectToArray(us_toggleUsers)}
            tableSettings={tableSettings_ToggleUsers}
          />
        </Card>
      </Box>
    )

    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    // Page JSX
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Client Permissions')}
        pageKey={pageKey}
        content={
          <Box>
            <Box className="tw-my-4">{rJSX_ClientToggleDropdown()}</Box>
            <TabsUrl
              tabsSettings={{
                baseUrl: ApplicationPages.SuperClientPermissionsManagementPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Client Permissions', false) as string,
              }}
              tabs={[
                {
                  tabUrlKey: 'Permissions',
                  tabHeader: rLIB('Permissions'),
                  tabContent: (
                    <Box>
                      <Card variant="outlined">
                        {rJSX_ClientTypeForm()}
                        {rJSX_PermissionChecklist()}
                      </Card>
                    </Box>
                  ),
                },
                {
                  tabUrlKey: 'Users',
                  tabHeader: rLIB('Users'),
                  tabContent: <Box>{rJSX_UsersTab()}</Box>,
                },
                {
                  tabUrlKey: 'Toggle_Users',
                  tabHeader: rLIB('Users with client toggle'),
                  tabContent: <Box>{rJSX_ToggleUsersTab()}</Box>,
                },
                {
                  tabUrlKey: 'JSON',
                  tabHeader: rLIB('JSON'),
                  tabContent: (
                    <Box>
                      <Json
                        data={us_formData_ClientType}
                        alphabetized={true}
                      />
                    </Box>
                  ),
                },
              ]}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
