///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_Vehicles_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'vehicles', 'main')
}

export const DatabaseRef_Vehicle_Document = (clientKey: string, vehicleKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'vehicles', 'main', vehicleKey)
}

export const DatabaseRef_ActiveVehicles_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'vehicles', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_Vehicles_Search = (clientKey: string, searchProperty: string, searchValue: string, limit: number): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: searchProperty, comparator: '>=', value: searchValue },
    { prop: searchProperty, comparator: '<=', value: searchValue + '~' },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'vehicles', 'main'), queryOperatorsArray, orderByArray, {}, limit)
}

export const DatabaseRef_VehicleFiles_Collection = (clientKey: string, vehicleKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'vehicles', 'main', vehicleKey, 'files')
}

export const DatabaseRef_VehicleFile_Document = (clientKey: string, vehicleKey: string, fileKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'vehicles', 'main', vehicleKey, 'files', fileKey)
}

// Forum
export const DatabaseRef_VehicleForumThreads_Collection = (clientKey: string, vehicleKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'vehicles', 'main', vehicleKey, 'forum')
}

export const DatabaseRef_VehicleForumThread_Document = (clientKey: string, vehicleKey: string, threadKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'vehicles', 'main', vehicleKey, 'forum', threadKey)
}

export const DatabaseRef_VehicleForumMessages_Collection = (clientKey: string, vehicleKey: string, threadKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'vehicles', 'main', vehicleKey, 'forum', threadKey, 'messages')
}

export const DatabaseRef_VehicleForumMessage_Document = (
  clientKey: string,
  vehicleKey: string,
  threadKey: string,
  messageKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'vehicles', 'main', vehicleKey, 'forum', threadKey, 'messages', messageKey)
}

// Vehicles for specific user
export const DatabaseRef_VehiclesForSpecificUser_Query = (clientKey: string, userKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_user_key', comparator: '==', value: userKey }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'vehicles', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

// Vehicles for specific garage
export const DatabaseRef_VehiclesFilterByGarage_Query = (clientKey: string, garageKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_garage_key', comparator: '==', value: garageKey }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'vehicles', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

// Vehicles that are parked
export const DatabaseRef_VehiclesFilterByParked_Query = (clientKey: string, parkStatus: boolean): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'field_status', comparator: '==', value: parkStatus }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'vehicles', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

// Vehicles for specific garage and parked
export const DatabaseRef_VehiclesFilterByParkedAndGarage_Query = (clientKey: string, garageKey: string, parkStatus: boolean): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_garage_key', comparator: '==', value: garageKey },
    { prop: 'field_status', comparator: '==', value: parkStatus },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'vehicles', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_GasPricesForMonth_Query = (clientKey: string, state: string, startDate: Date, endDate: Date): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_added', comparator: '>=', value: startDate },
    { prop: 'timestamp_added', comparator: '<=', value: endDate },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_gas_prices', state),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_Rentals_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'rentals', 'main')
}

export const DatabaseRef_RentalsByStatus_Query = (clientKey: string, statuses: string[]): Query<unknown> => {
  if (!statuses || statuses.length === 0) {
    throw new Error('Statuses array must contain at least one status.')
  }

  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: 'in', value: statuses }]
  let orderByArray: TsInterface_OrderByArray = []

  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'rentals', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_Rental_Document = (clientKey: string, rentalKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'rentals', 'main', rentalKey)
}
