import { Box, Button, Divider, Switch, Typography } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Trailer_Document } from 'rfbp_aux/services/database_endpoints/directory/trailers'
import { TsInterface_FormAdditionalData, TsInterface_FormHooksObject, TsInterface_FormSubmittedData } from 'rfbp_core/components/form'
import { TsInterface_InputHooksObject } from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import { rJSX_HighlightedSearchString } from 'rfbp_core/components/search'
import {
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseBatchUpdate, DatabaseSetMergeDocument, TsInterface_DatabaseBatchUpdatesArray } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import TrailerAssignmentHistoryDialog from '../dialogs/dialog_trailer_assignment_history'
import FieldStatusToggleTrailerAssignmentDialog from '../dialogs/dialog_trailer_field_status_toggle'
import TrailerAssignmentInTableDialog from '../dialogs/dialog_trailer_in_table_assign'

export const tableColumns_Trailers: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseTrailerViewPage.url(rowData.key as string))
        }
      },
    },
    delete: {
      icon: (
        <Icon
          icon="trash"
          type="solid"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData != null && rowData.key != null && tableHooks != null && tableHooks.uc_RootData_ClientKey != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete Trailer'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure you want to delete this trailer?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  let updateObject = {
                    status: 'deleted',
                  }
                  DatabaseSetMergeDocument(DatabaseRef_Trailer_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string), updateObject)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
              },
            },
          })
        }
      },
    },
    undelete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-undo"
        />
      ),
      label: <>{rLIB('Undelete')}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'info',
              header: rLIB('Undelete Trailer'),
              icon: (
                <Icon
                  icon="trash-undo"
                  type="solid"
                />
              ),
              submit_text: rLIB('Undelete'),
              text: rLIB('Are you sure that you want to undelete this trailer?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        status: 'active',
                      }
                      DatabaseSetMergeDocument(DatabaseRef_Trailer_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Trailer Name')
      },
      header_sort_by: 'name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseTrailerViewPage.url(rowData.key as string))
                }
              }}
            >
              {rowData.name}
            </Box>
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  license_plate: TableCellBasic('license_plate', rLIB('License Plate'), 'license_plate'),
  field_status: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Field Status')
      },
      header_sort_by: 'field_status',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const handleFieldStatusChange = (event: any) => {
          if (event != null && event.target != null && event.target.checked != null && rowData != null && rowData.key != null) {
            if (event.target.checked) {
              tableHooks.uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <FieldStatusToggleTrailerAssignmentDialog
                      entity={rowData}
                      tableHooks={tableHooks}
                      uc_setUserInterface_CustomDialogDisplay={tableHooks.uc_setUserInterface_CustomDialogDisplay}
                    />
                  ),
                  settings: {
                    max_width: 'lg',
                    header_color: 'success',
                    header_text: `Assign Driver for ${rowData.name}`,
                    icon: <Icon icon="pen-to-square" />,
                  },
                },
              })
            } else {
              const updateObject = {
                field_status: false,
                associated_user_key: '',
                associated_user_name: '',
              }

              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  const updates: TsInterface_DatabaseBatchUpdatesArray = [
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_Trailer_Document(res_GCK.clientKey, rowData.key as string),
                      data: updateObject,
                    },
                  ]

                  DatabaseBatchUpdate(updates)
                    .then((res_DBU) => {
                      tableHooks.ur_forceRerender()
                    })
                    .catch((rej_DBU) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                    })
                })
                .catch((rej_GCK) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            }
          }
        }

        let cellJSX = (
          <>
            <Switch
              checked={(rowData.field_status as boolean) || false}
              onChange={handleFieldStatusChange}
            />
            {rowData.field_status ? rLIB('Active') : rLIB('Inactive')}
          </>
        )

        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },

  associated_user_name: {
    header: {
      header_jsx: () => rLIB('Assigned To'),
      header_sort_by: 'associated_user_name',
    },
    cell: {
      cell_jsx: (rowData, tableAdditionalData, tableHooks) => {
        const manageUserIcon = (
          <Icon
            icon="pen-to-square"
            className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
            tooltip={'Edit'}
            tooltipPlacement="right"
            onClick={() => {
              tableHooks.uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <TrailerAssignmentInTableDialog
                      us_trailer={rowData}
                      tableHooks={tableHooks}
                      uc_setUserInterface_CustomDialogDisplay={tableHooks.uc_setUserInterface_CustomDialogDisplay}
                    />
                  ),
                  settings: {
                    max_width: 'lg',
                    header_color: 'success',
                    header_text: `Trailer Details for ${rowData.name}`,
                    icon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )

        const viewHistoryIcon = (
          <Icon
            icon="magnifying-glass"
            className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
            tooltip={rLIB('View History')}
            tooltipPlacement="right"
            onClick={() => {
              tableHooks.uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <TrailerAssignmentHistoryDialog
                      us_trailer={rowData}
                      tableHooks={tableHooks}
                      uc_setUserInterface_CustomDialogDisplay={tableHooks.uc_setUserInterface_CustomDialogDisplay}
                    />
                  ),
                  settings: {
                    max_width: 'lg',
                    header_color: 'info',
                    header_text: `Assignment History for ${rowData.name}`,
                    icon: (
                      <Icon
                        type="solid"
                        icon="magnifying-glass"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )

        const missingJSX = <Box className="tw-italic tw-opacity-30 tw-inline-block">{'Missing'}</Box>

        const cellJSX = (
          <>
            {rowData.associated_user_name || missingJSX} {manageUserIcon} {viewHistoryIcon}
          </>
        )

        return cellJSX
      },
      cell_css: () => '',
    },
  },

  associated_garage_name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Garage')
      },
      header_sort_by: 'associated_garage_name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const editGarageIcon = (
          <Icon
            icon="pen-to-square"
            className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              console.log('Active Garages List:', tableAdditionalData?.us_activeGaragesList)

              // Populate form options
              let formOptions: TsInterface_UnspecifiedObject[] = []
              if (tableAdditionalData && tableAdditionalData.us_activeGaragesList && Object.keys(tableAdditionalData.us_activeGaragesList).length > 0) {
                for (let loopGarageKey in tableAdditionalData.us_activeGaragesList) {
                  const loopGarage = tableAdditionalData.us_activeGaragesList[loopGarageKey]
                  if (loopGarage.name && loopGarage.key) {
                    formOptions.push({
                      value: loopGarage.name,
                      key: loopGarage.key,
                    })
                  }
                }
              } else {
                console.warn('No active garages found or list is empty.')
              }

              // Open form dialog
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {
                      taskKey: rowData.key,
                    },
                    formData: rowData,
                    formInputs: {
                      associated_garage_key: {
                        data_type: 'string',
                        input_type: 'multiple_choice_radio',
                        key: 'associated_garage_key',
                        label: rLIB('Garage'),
                        required: true,
                        options: formOptions,
                        submit_on_change: true,
                      },
                    },
                    formOnChange: () => {},
                    formSettings: {
                      submit_button_hide: true,
                    },
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        console.log('Form submitted data:', formSubmittedData)
                        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            const selectedGarage = tableAdditionalData.us_activeGaragesList[formSubmittedData.associated_garage_key]
                            if (!selectedGarage) {
                              console.error('Invalid garage key selected.')
                              return reject(new Error('Invalid garage key selected.'))
                            }

                            const updateObject = {
                              associated_garage_name: selectedGarage.name,
                              associated_garage_key: formSubmittedData.associated_garage_key,
                            }
                            DatabaseSetMergeDocument(DatabaseRef_Trailer_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                reject(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({
                              display: true,
                              error: rej_GCK.error,
                            })
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Select Garage for')} {rowData.name}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )

        const missingJSX = <Box className="tw-italic tw-opacity-30 tw-inline-block">{rLIB('Missing')}</Box>
        const cellJSX = (
          <>
            {getProp(rowData, 'associated_garage_name', missingJSX)} {editGarageIcon}
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
  },

  vin: TableCellBasic('vin', rLIB('Vin'), 'vin'),
  trailer_make: TableCellBasic('trailer_make', rLIB('Make'), 'trailer_make'),
  trailer_model: TableCellBasic('trailer_model', rLIB('Model'), 'trailer_model'),
  trailer_year: TableCellBasic('trailer_year', rLIB('Year'), 'trailer_year'),
  trailer_tag_expiration: TableCellBasic('trailer_tag_expiration', rLIB('Tag Expiration'), 'trailer_tag_expiration'),
  trailer_color: TableCellBasic('trailer_color', rLIB('Color'), 'trailer_color'),
}

export const tableSettings_Trailers: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  collapsible_columns: true,
  use_live_data: true,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
  searchable: true,
  // search_settings_database: {
  //   search_type: 'firebase',
  //   search_no_data_message: s_NO_TRAILERS_FOUND_BY_SEARCH,
  //   search_property_options: [{ key: 'license_plate', value: rLIB('License Plate') }],
  // },
  search_settings_database: {
    search_type: 'meilisearch',
    search_client_key: null,
    search_index_key: 'trailers',
    search_filters: [],
    search_result_renderer: (option: TsInterface_UnspecifiedObject, searchInputValue: string | null, inputHooks: TsInterface_InputHooksObject): JSX.Element => {
      let searchResultJSX = (
        <Box sx={{ marginLeft: '8px', marginRight: '8px' }}>
          <Typography>
            {rLIB('Trailer Name')}: {rJSX_HighlightedSearchString(searchInputValue, option.name)}{' '}
          </Typography>
          <Typography>
            {rLIB('Assigned Driver')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_user_name)}{' '}
          </Typography>
          <Typography>
            {rLIB('License Plate')}: {rJSX_HighlightedSearchString(searchInputValue, option.license_plate)}{' '}
          </Typography>
          <Typography>
            {rLIB('VIN')}: {rJSX_HighlightedSearchString(searchInputValue, option.vin)}{' '}
          </Typography>
          <Typography>
            {rLIB('Garage Location')}:{' '}
            <Box
              component="span"
              sx={{ opacity: 0.3 }}
            >
              {option.associated_garage_name}
            </Box>
          </Typography>
          <Typography>
            {rLIB('Field Status')}:{' '}
            <Box
              component="span"
              sx={{ opacity: 0.3 }}
            >
              {option.field_status ? rLIB('Active') : rLIB('Parked')}
            </Box>
          </Typography>
          <Button
            color={'info'}
            onClick={(event) => {
              if (option.id != null) {
                onClickAppNavigation(event, inputHooks.un_routerNavigation, ApplicationPages.AdminDatabaseTrailerViewPage.url(option.id as string))
              }
            }}
            variant="contained"
            sx={{ marginBottom: '5px' }}
          >
            <Icon icon="magnifying-glass" />
            {rLIB('View Trailer')}
          </Button>
          <Divider />
        </Box>
      )
      return searchResultJSX
    },
  },
}
