import { Box } from '@mui/material'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import GeoRasterLayer from 'georaster-layer-for-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { useEffect, useRef } from 'react'
// @ts-ignore
import parseGeoraster from 'georaster'

interface GeoTiffMapProps {
  tiffUrl: string
  latitude: number
  longitude: number
  zoomLevel: number
  panels: any
}

export const GeoTiffMap: React.FC<GeoTiffMapProps> = ({ tiffUrl, latitude, longitude, zoomLevel, panels }: GeoTiffMapProps) => {
  const mapRef = useRef<HTMLDivElement>(null)
  const mapInstanceRef = useRef<L.Map | null>(null)

  // Function to load and display GeoTIFF
  async function loadGeoTIFF() {
    try {
      //Get reference to the file in Firebase Storage
      const storageRef = ref(getStorage(), tiffUrl)

      // Get download URL
      const downloadURL = await getDownloadURL(storageRef)
      const response = await fetch(downloadURL)
      const arrayBuffer = await response.arrayBuffer()

      // Try parsing with the verified metadata
      const georaster = await parseGeoraster(arrayBuffer)

      // Create and add layer
      const layer = new GeoRasterLayer({
        georaster: georaster,
        opacity: 0.7,
        resolution: 256,
      })

      if (mapInstanceRef.current) {
        layer.addTo(mapInstanceRef.current)
        // mapInstanceRef.current.fitBounds(layer.getBounds(), { maxZoom: 50 })
        const bounds = layer.getBounds()
        mapInstanceRef.current.setView(bounds.getCenter(), 20)

        // Then add panels on top with correct coordinate ordering
        Object.values(panels).forEach((panel: any) => {
          // Ensure coordinates are in the correct format for Leaflet [lat, lng]
          const coordinates = Object.values(panel.row.coordinates).map((corner: any) => [
            corner[1], // latitude
            corner[0], // longitude
          ])

          if (mapInstanceRef.current) {
            const polygon = L.polygon(coordinates, {
              color: 'blue',
              fillColor: 'blue',
              fillOpacity: 0.3,
              weight: 1,
              interactive: true,
            }).addTo(mapInstanceRef.current)

            // Add hover effects and click handler
            polygon
              .on('mouseover', () => {
                polygon.setStyle({ fillOpacity: 0.5 })
              })
              .on('mouseout', () => {
                polygon.setStyle({ fillOpacity: 0.3 })
              })
              .on('click', () => {
                console.log('Panel clicked:', panel)
                //turn off panel
                //turn on panel
                //use map?
                //update system size and offset and production and price, based on how many panels are on
                // Add your click handler here
              })
          }
        })
      }
    } catch (error) {
      console.error('Error loading GeoTIFF:', error)
      if (error instanceof Error) {
        console.error('Error details:', {
          name: error.name,
          message: error.message,
          stack: error.stack,
        })
      }
    }
  }

  useEffect(() => {
    if (mapRef.current && !mapInstanceRef.current) {
      // Initialize the map
      const map = L.map(mapRef.current, {
        maxZoom: 22,
        minZoom: 1,
        attributionControl: false,
      }).setView([latitude, longitude], zoomLevel)
      mapInstanceRef.current = map

      // Add OpenStreetMap as base layer
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors',
      }).addTo(map)

      // Load the GeoTIFF when the map is initialized
      loadGeoTIFF()
    }

    // Cleanup function
    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.remove()
        mapInstanceRef.current = null
      }
    }
  }, [])

  return (
    <Box>
      <div
        ref={mapRef}
        style={{
          height: '500px',
          width: '85%',
          marginBottom: '16px',
          marginLeft: '30px',
        }}
      />
    </Box>
  )
}
