///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:
			1 @ts-expect-error - really annoying casting problem on permission overrides

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Typography } from '@mui/material/'
import { Trans } from 'react-i18next'
import { TsInterface_FormInputs, TsInterface_FormSettings, TsType_FormOnChange, TsType_FormSubmission } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_CITY: JSX.Element = <Trans>City</Trans>
const s_OTHER: JSX.Element = <Trans>Other</Trans>
const s_SIGNED_DATE: JSX.Element = <Trans>Signed Date</Trans>
const s_STATE: JSX.Element = <Trans>State</Trans>
const s_STREET_ADDRESS: JSX.Element = <Trans>Street Address</Trans>
const s_ZIP: JSX.Element = <Trans>Zip</Trans>
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const formOnChange_Project: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings, formHooks) => {
  // Nothing
}

export const formSubmission_ProjectCreate: TsType_FormSubmission = (formSubmittedData, formAdditionalData, formHooks) => {
  return new Promise((resolve, reject) => {
    resolve({})
  })
}

export const formSubmission_ProjectUpdate: TsType_FormSubmission = (formSubmittedData, formAdditionalData, formHooks) => {
  return new Promise((resolve, reject) => {
    resolve({})
  })
}

export const formSettings_Project: TsInterface_FormSettings = {
  // highlight_missing: true,
  // submit_button_alignment: "right",
  // submit_button_hide: false,
  // submit_button_icon: <SaveIcon/>,
  // submit_button_saving_icon: true,
  // submit_button_text: <>{s_SAVE}</>,
}

export const formInputs_ProjectNew: TsInterface_FormInputs = {
  display_address: {
    data_type: 'string',
    key: 'display_address',
    input_type: 'custom_form_input_jsx',
    label: rLIB('Address'),
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box sx={{ marginBottom: '8px' }}>
          <Typography variant="h6">{rLIB('Address')}</Typography>
        </Box>
      )
      return inputJSX
    },
  },
  location_address: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_address',
    label: s_STREET_ADDRESS,
    required: true,
  },
  location_city: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_city',
    label: s_CITY,
    required: true,
  },
  location_state: {
    data_type: 'string',
    input_type: 'multiple_choice_select_state_usa',
    key: 'location_state',
    label: s_STATE,
    required: true,
  },
  location_zip: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_zip',
    label: s_ZIP,
    required: true,
  },
  // display_system_details: {
  // 	data_type: "string",
  // 	key: "display_system_details",
  // 	input_type: "custom_form_input_jsx",
  // 	label: s_SYSTEM_DETAILS,
  // 	required: false,
  // 	renderCustomFormInput: ( formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData ) => {
  // 		let inputJSX =
  // 		<Box sx={{ marginTop: "16px", marginBottom: "8px" }}>
  // 			<Typography variant="h6">{ s_SYSTEM_DETAILS }</Typography>
  // 		</Box>
  // 		return inputJSX
  // 	}
  // },
  // system_size: {
  // 	data_type: "string",
  // 	input_type: "multiple_choice_select",
  // 	key: "system_size",
  // 	label: s_SYSTEM_SIZE,
  // 	required: true,
  // 	options: [] // Dynamically populated from database when form used
  // },
  // system_module: {
  // 	data_type: "string",
  // 	input_type: "multiple_choice_select",
  // 	key: "system_module",
  // 	label: s_MODULE,
  // 	required: true,
  // 	options: [] // Dynamically populated from database when form used
  // },
  // system_inverter: {
  // 	data_type: "string",
  // 	input_type: "multiple_choice_select",
  // 	key: "system_inverter",
  // 	label: s_INVERTER,
  // 	required: true,
  // 	options: [] // Dynamically populated from database when form used
  // },
  // system_battery: {
  // 	data_type: "string",
  // 	input_type: "multiple_choice_select",
  // 	key: "system_battery",
  // 	label: s_BATTERY,
  // 	required: true,
  // 	options: [] // Dynamically populated from database when form used
  // },
  // display_associations: {
  // 	data_type: "string",
  // 	key: "display_associations",
  // 	input_type: "custom_form_input_jsx",
  // 	label: s_PEOPLE,
  // 	required: false,
  // 	renderCustomFormInput: ( formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData ) => {
  // 		let inputJSX =
  // 		<Box sx={{ marginTop: "16px", marginBottom: "8px" }}>
  // 			<Typography variant="h6">{ s_PEOPLE }</Typography>
  // 		</Box>
  // 		return inputJSX
  // 	}
  // },
  // associated_css_rep: {
  // 	data_type: "string",
  // 	input_type: "association_autocomplete_combo_box",
  // 	key: "associated_css_rep",
  // 	label: s_CSS_REP,
  // 	required: true,
  // 	options: []
  // },
  // associated_sales_rep: {
  // 	data_type: "string",
  // 	input_type: "association_autocomplete_combo_box",
  // 	key: "associated_sales_rep",
  // 	label: s_SALES_REP,
  // 	required: true,
  // },
  // associated_customer: {
  // 	data_type: "string",
  // 	input_type: "association_autocomplete_combo_box",
  // 	key: "associated_customer",
  // 	label: s_CUSTOMER,
  // 	required: true,
  // },
  display_other: {
    data_type: 'string',
    key: 'display_other',
    input_type: 'custom_form_input_jsx',
    label: s_OTHER,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box sx={{ marginTop: '16px', marginBottom: '8px' }}>
          <Typography variant="h6">{s_OTHER}</Typography>
        </Box>
      )
      return inputJSX
    },
  },
  timestamp_customer_signed: {
    data_type: 'number',
    input_type: 'timestamp_date',
    key: 'timestamp_customer_signed',
    label: s_SIGNED_DATE,
    required: true,
  },
}

export const formInputs_ProjectEdit: TsInterface_FormInputs = {
  name: formInputs_ProjectNew['name'],
  // phone: formInputs_ProjectNew["phone"],
  // user_role: formInputs_ProjectNew["user_role"],
  // permission_overrides: formInputs_ProjectNew["permission_overrides"],
}
