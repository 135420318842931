///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_UsersWithUserRole_Query = (clientKey: string, userRole: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'user_role', comparator: '==', value: userRole }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_UsersWithTaskRole_Query = (clientKey: string, taskRole: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'task_roles.' + taskRole, comparator: '==', value: true }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_UsersWithSpecificEmail_Query = (clientKey: string, email: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'email', comparator: '==', value: email }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_UsersWithSpecificNameAbbreviation_Query = (clientKey: string, abbreviation: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'name_abbreviation', comparator: '==', value: abbreviation }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_InternalUsers_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_organization_type', comparator: '==', value: 'internal' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_UsersWithSpecificTaskRole_Query = (clientKey: string, roleKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'active' },
    { prop: 'task_roles.' + roleKey, comparator: '==', value: true },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_TimecardHourlyUsers_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'active' },
    { prop: 'hourly_or_salaried', comparator: '==', value: 'hourly' },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_Users_Search = (clientKey: string, searchProperty: string, searchValue: string, limit: number): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: searchProperty, comparator: '>=', value: searchValue },
    { prop: searchProperty, comparator: '<=', value: searchValue + '~' },
  ]

  let orderByArray: TsInterface_OrderByArray = []

  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main'),
    queryOperatorsArray,
    orderByArray,
    {}, // any additional options, if needed
    limit,
  )
}

export const DatabaseRef_UsersWithVehicleGarages_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_garage_key', comparator: '!=', value: false }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main'), queryOperatorsArray, [], {}, null)
}

// Files
export const DatabaseRef_UserFiles_Collection = (clientKey: string, userKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main', userKey, 'files')
}

export const DatabaseRef_UserFile_Document = (clientKey: string, userKey: string, fileKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main', userKey, 'files', fileKey)
}

// Forum
export const DatabaseRef_UserForumThreads_Collection = (clientKey: string, userKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main', userKey, 'forum')
}

export const DatabaseRef_UserForumThread_Document = (clientKey: string, userKey: string, threadKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main', userKey, 'forum', threadKey)
}

export const DatabaseRef_UserForumMessages_Collection = (clientKey: string, userKey: string, threadKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main', userKey, 'forum', threadKey, 'messages')
}

export const DatabaseRef_UserSettings_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'settings')
}

export const DatabaseRef_UserSettings_Document = (clientKey: string, userKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'users', 'settings', userKey)
}

export const DatabaseRef_Users_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main')
}

export const DatabaseRef_User_Document = (clientKey: string, userKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main', userKey)
}

export const DatabaseRef_UserForumMessage_Document = (
  clientKey: string,
  userKey: string,
  threadKey: string,
  messageKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main', userKey, 'forum', threadKey, 'messages', messageKey)
}

export const DatabaseRef_UsersBySalesPartner_Query = (clientKey: string, salesPartnerKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_sales_partner_key', comparator: '==', value: salesPartnerKey }]

  let orderByArray: TsInterface_OrderByArray = []

  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_CachedTransactions_Document = (clientKey: string, monthYear: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_transactions', 'main', monthYear)
}

export const DatabaseRef_ActiveUsers_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main'), queryOperatorsArray, orderByArray, {}, null)
}
