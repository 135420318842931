///////////////////////////////
// Description
///////////////////////////////

import { AppBar, Box, Button, Dialog, DialogContent, IconButton, Stack, Toolbar, Typography } from '@mui/material'
import { useContext, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { downloadBasePricingPDF } from '../services/invoice_pdf_templates'
import { sendProjectBaseInvoiceEmail } from '../services/invoice_progress_functions'
import { invoiceBillToOptions } from '../tables/task_invoice_mapping'

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_BillBaseInvoiceDialog {
  clientKey: string
  taskKey: string
  invoiceKey: string
  projectKey: string
  project: TsInterface_UnspecifiedObject
  emailAndBillingContactInfo: TsInterface_UnspecifiedObject
  invoice: TsInterface_UnspecifiedObject
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

/* eslint-disable react/prop-types */
export const BillBaseInvoiceDialog: React.FC<TsInterface_BillBaseInvoiceDialog> = ({
  clientKey,
  taskKey,
  projectKey,
  invoiceKey,
  project,
  emailAndBillingContactInfo,
  invoice,
}): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_billingInvoice, us_setBillingInvoice] = useState<boolean>(false)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useEffect

  // Functions

  // JSX Generation
  const rJSX_FinanceLineItem = (
    us_rootProject: TsInterface_UnspecifiedObject,
    icon: string,
    label: string | JSX.Element,
    color: string,
    propKey: string,
    uc_setUserInterface_ErrorDialogDisplay: any,
  ): JSX.Element => {
    let propValueJSX = <></>
    let propValue = getProp(us_rootProject, propKey, null)
    if (propValue == null) {
      propValueJSX = (
        <Typography
          variant="body1"
          sx={{ background: themeVariables.warning_main }}
          className="tw-rounded tw-px-2 tw-py-0"
        >
          {rLIB('Missing')}
        </Typography>
      )
    } else {
      propValueJSX = <Typography variant="body1">{propValue}</Typography>
    }
    let lineItemJSX = (
      <Box className="tw-mb-1">
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Typography
            variant="body1"
            sx={{ color: color }}
          >
            <Icon
              icon={icon}
              className="tw-mr-2"
            />
            {label}:
          </Typography>
          {propValueJSX}
        </Stack>
      </Box>
    )
    return lineItemJSX
  }

  const rJSX_FinanceLineItemArray = (
    us_rootProject: TsInterface_UnspecifiedObject,
    icon: string,
    label: string | JSX.Element,
    color: string,
    propKey: string,
    uc_setUserInterface_ErrorDialogDisplay: any,
  ): JSX.Element => {
    let propValueJSX = <></>
    let propValue = getProp(us_rootProject, propKey, null)
    if (propValue == null) {
      propValueJSX = (
        <Typography
          variant="body1"
          sx={{ background: themeVariables.warning_main }}
          className="tw-rounded tw-px-2 tw-py-0"
        >
          {rLIB('Missing')}
        </Typography>
      )
    } else {
      propValueJSX = <Typography variant="body1">{propValue.join(', ')}</Typography>
    }
    let lineItemJSX = (
      <Box className="tw-mb-1">
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Typography
            variant="body1"
            sx={{ color: color }}
          >
            <Icon
              icon={icon}
              className="tw-mr-2"
            />
            {label}:
          </Typography>
          {propValueJSX}
        </Stack>
      </Box>
    )
    return lineItemJSX
  }

  const rJSX_CustomFinanceLineItem = (
    us_rootProject: TsInterface_UnspecifiedObject,
    icon: string,
    label: string | JSX.Element,
    color: string,
    propKey: string,
    uc_setUserInterface_ErrorDialogDisplay: any,
    rJSX_customFormatFunction: (propValue: any) => JSX.Element,
  ): JSX.Element => {
    let propValueJSX = <></>
    let propValue = getProp(us_rootProject, propKey, null)
    if (propValue == null) {
      propValueJSX = (
        <Typography
          variant="body1"
          sx={{ background: themeVariables.warning_main }}
          className="tw-rounded tw-px-2 tw-py-0"
        >
          {rLIB('Missing')}
        </Typography>
      )
    } else {
      // propValueJSX = <Typography variant="body1">{propValue}</Typography>
      propValueJSX = rJSX_customFormatFunction(propValue)
    }
    let lineItemJSX = (
      <Box className="tw-mb-1">
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Typography
            variant="body1"
            sx={{ color: color }}
          >
            <Icon
              icon={icon}
              className="tw-mr-2"
            />
            {label}:
          </Typography>
          {propValueJSX}
        </Stack>
      </Box>
    )
    return lineItemJSX
  }

  const rJSX_SingleTaskBasePriceCard = (
    us_rootProject: TsInterface_UnspecifiedObject,
    contactInfo: TsInterface_UnspecifiedObject,
    uc_RootData_ClientKey: any,
    pr_projectKey: string,
    uc_setRootData_ClientKey: any,
    uc_setUserInterface_ErrorDialogDisplay: any,
    selectedInvoice: TsInterface_UnspecifiedObject,
  ) => {
    return (
      <Box>
        {rJSX_CustomFinanceLineItem(
          us_rootProject,
          'file-invoice',
          rLIB('Bill To'),
          themeVariables.primary_main,
          'invoice_bill_to',
          uc_setUserInterface_ErrorDialogDisplay,
          (propValue: any) => {
            let mappedPropValue = propValue
            if (invoiceBillToOptions != null && invoiceBillToOptions[propValue] != null && invoiceBillToOptions[propValue].name != null) {
              mappedPropValue = invoiceBillToOptions[propValue].name
            }
            return <Typography variant="body1">{mappedPropValue}</Typography>
          },
        )}
        {rJSX_FinanceLineItemArray(
          contactInfo,
          'paper-plane',
          rLIB('Billing Email'),
          themeVariables.primary_main,
          'email',
          uc_setUserInterface_ErrorDialogDisplay,
        )}
        {rJSX_FinanceLineItem(
          contactInfo,
          'location-dot',
          rLIB('Address'),
          themeVariables.primary_main,
          'location_address',
          uc_setUserInterface_ErrorDialogDisplay,
        )}
        {rJSX_FinanceLineItem(contactInfo, 'location-dot', rLIB('City'), themeVariables.primary_main, 'location_city', uc_setUserInterface_ErrorDialogDisplay)}
        {rJSX_FinanceLineItem(
          contactInfo,
          'location-dot',
          rLIB('State'),
          themeVariables.primary_main,
          'location_state',
          uc_setUserInterface_ErrorDialogDisplay,
        )}
        {rJSX_FinanceLineItem(contactInfo, 'location-dot', rLIB('Zip'), themeVariables.primary_main, 'location_zip', uc_setUserInterface_ErrorDialogDisplay)}
        <Box>
          <Typography
            variant="body1"
            sx={{ color: themeVariables.primary_main }}
          >
            <Icon
              icon="file-invoice-dollar"
              className="tw-mr-2"
            />
            {rLIB('Invoice PDF')}
          </Typography>
          <Box>
            <Stack
              direction="row"
              alignItems="center"
            >
              <Typography
                className="tw-ml-4"
                variant="body1"
              >
                {selectedInvoice.invoice_id_number} - {selectedInvoice.associated_task_name}
              </Typography>
              <Icon
                icon="file-pdf"
                className="tw-ml-2 tw-cursor-pointer"
                sx={{ color: themeVariables.error_main }}
                onClick={() => {
                  // TODO: Missing Data
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      downloadBasePricingPDF(res_GCK.clientKey, us_rootProject.key, selectedInvoice.associated_task_key, 'download', 'invoice', {})
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                }}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    )
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Dialog
        className="bp_dialog_md_width"
        keepMounted
        onClose={() => {
          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        }}
        open={true}
      >
        <AppBar
          position="static"
          color="inherit"
        >
          <Toolbar>
            <IconButton
              aria-label="menu"
              color="inherit"
              disabled
              edge="start"
              size="large"
              sx={{ mr: 1, color: '#fff !important' }}
            >
              <Icon icon="paper-plane" />
            </IconButton>
            <Typography
              component={'span'}
              variant={'h6'}
              sx={{ flexGrow: 1 }}
            >
              <Box className="tw-inline-block">{rLIB('Email Invoice')}</Box>
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ padding: '0px' }}>
          <Box className="tw-p-4">
            {rJSX_SingleTaskBasePriceCard(
              project,
              emailAndBillingContactInfo,
              uc_RootData_ClientKey,
              projectKey,
              uc_setRootData_ClientKey,
              uc_setUserInterface_ErrorDialogDisplay,
              invoice,
            )}
          </Box>
          <Box className="tw-p-4 tw-text-center">
            <Button
              variant="contained"
              color="success"
              startIcon={
                us_billingInvoice === true ? (
                  <Icon
                    icon="arrows-rotate"
                    className="bp_spin"
                  />
                ) : (
                  <Icon icon="paper-plane" />
                )
              }
              disabled={us_billingInvoice === true}
              onClick={() => {
                us_setBillingInvoice(true)
                sendProjectBaseInvoiceEmail(clientKey, projectKey, taskKey, invoiceKey)
                  .then((res_SPBIE) => {
                    us_setBillingInvoice(false)
                    uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                  })
                  .catch((rej_SPBIE) => {
                    us_setBillingInvoice(false)
                    uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                    uc_setUserInterface_ErrorDialogDisplay(rej_SPBIE)
                  })
              }}
            >
              {rLIB('Send Email')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}
