///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_Client_InboundDataProcedure_Email_Query,
  DatabaseRef_Client_InboundDataProcedure_Query,
  DatabaseRef_InboundDataProcedure_Collection,
} from 'rfbp_aux/services/database_endpoints/global/inbound_data_procedures'
import { DatabaseRef_ClientIntegrations_GHL_AccessTokens_Document } from 'rfbp_aux/services/database_endpoints/integrations/ghl'
import { DatabaseRef_IntegrationTestData_Document } from 'rfbp_aux/services/database_endpoints/integrations/test_data'
import { Json } from 'rfbp_core/components/code_display'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TableBasic } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import { DatabaseAddDocument, DatabaseGetCollection, DatabaseGetLiveCollection, DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { dynamicSort, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { convertToPacificTime, formInputs_GoogleSheetScraper } from './forms/google_sheet_scraper'
import { formInputs_InboundEmailParser } from './forms/inbound_email_parser'
import { tableColumns_GoogleSheets, tableSettings_GoogleSheets } from './tables/google_sheets'
import { tableColumns_InboundEmails, tableSettings_InboundEmails } from './tables/inbound_email'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AutomaticDataImportPages']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_inboundDataProcedures, us_setInboundDataProcedures] = useState<TsInterface_UnspecifiedObject>({})
  const [us_testInboundEmailTestData, us_setTestInboundEmailTestData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_inboundEmails, us_setInboundEmails] = useState<TsInterface_UnspecifiedObject>({})
  const [us_googleSheets, us_setGoogleSheets] = useState<TsInterface_UnspecifiedObject>({})
  const [us_ghlAccessTokens, us_setGHLAccessTokens] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Automatic Data Imports', false) as string
  }, [])

  useEffect(() => {
    DatabaseGetCollection(DatabaseRef_Client_InboundDataProcedure_Email_Query('motorq@msg.etwenergy.com')).then((res) => {
      console.log(res)
    })
  }, [])

  useEffect(() => {
    let inboundEmails: TsInterface_UnspecifiedObject = {}
    let googleSheets: TsInterface_UnspecifiedObject = {}
    for (let loopProcedureKey in us_inboundDataProcedures) {
      let loopProcedure = us_inboundDataProcedures[loopProcedureKey]
      if (loopProcedure.status !== 'deleted') {
        if (loopProcedure.inbound_data_type === 'inbound_email_parser') {
          inboundEmails[loopProcedureKey] = loopProcedure
        }
        if (loopProcedure.inbound_data_type === 'google_sheet_scraper') {
          googleSheets[loopProcedureKey] = loopProcedure
        }
      }
    }
    us_setInboundEmails(inboundEmails)
    us_setGoogleSheets(googleSheets)
  }, [us_inboundDataProcedures])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setInboundDataProcedures(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Client_InboundDataProcedure_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTestInboundEmailTestData(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_IntegrationTestData_Document(res_GCK.clientKey, 'inbound_email_parse_test'), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setGHLAccessTokens(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ClientIntegrations_GHL_AccessTokens_Document(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions

  // JSX Generation
  const rJSX_AddInboundEmailButton = (disabled: boolean): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        variant="contained"
        color="success"
        startIcon={<Icon icon="circle-plus" />}
        disabled={disabled}
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: formInputs_InboundEmailParser,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        // TODO: Make sure that emails can't be added twice
                        let updateObject: TsInterface_UnspecifiedObject = {
                          email_address: formSubmittedData.email_address,
                          parser_procedure: formSubmittedData.parser_procedure,
                          status: 'paused',
                          client_key: res_GCK.clientKey,
                          inbound_data_type: 'inbound_email_parser',
                        }
                        DatabaseAddDocument(DatabaseRef_InboundDataProcedure_Collection(), updateObject, true)
                          .then((res_DSMD) => {
                            resolve({ success: true })
                          })
                          .catch((rej_DSMD) => {
                            reject(rej_DSMD)
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          })
                      })
                      .catch((rej_GCK) => {
                        reject(rej_GCK)
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: <>{rLIB('New Inbound Email Parser')}</>,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      >
        {rLIB('Add Inbound Email Parser')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_GoogleSheetButton = (disabled: boolean): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        variant="contained"
        color="success"
        startIcon={<Icon icon="circle-plus" />}
        disabled={disabled}
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: formInputs_GoogleSheetScraper,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          name: formSubmittedData.name,
                          google_sheet_id: formSubmittedData.google_sheet_id,
                          google_sheet_range: formSubmittedData.google_sheet_range,
                          parser_procedure: formSubmittedData.parser_procedure,
                          status: 'paused',
                          client_key: res_GCK.clientKey,
                          inbound_data_type: 'google_sheet_scraper',
                          timestamp_email_scheduled: formSubmittedData.timestamp_email_scheduled,
                          timestamp_email_scheduled_timezone: formSubmittedData.timestamp_email_scheduled_timezone,
                          timestamp_email_scheduled_pacific: convertToPacificTime(
                            formSubmittedData.timestamp_email_scheduled,
                            formSubmittedData.timestamp_email_scheduled_timezone,
                          ),
                        }
                        DatabaseAddDocument(DatabaseRef_InboundDataProcedure_Collection(), updateObject, true)
                          .then((res_DSMD) => {
                            resolve({ success: true })
                          })
                          .catch((rej_DSMD) => {
                            reject(rej_DSMD)
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          })
                      })
                      .catch((rej_GCK) => {
                        reject(rej_GCK)
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: <>{rLIB('New Google Sheet Scraper')}</>,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      >
        {rLIB('Add Google Sheet Scraper')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_InboundEmailsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card>
        <TableBasic
          tableAdditionalData={{}}
          tableColumns={tableColumns_InboundEmails}
          tableData={objectToArray(us_inboundEmails).sort(dynamicSort('email_address', 'asc'))}
          tableSettings={tableSettings_InboundEmails}
        />
      </Card>
    )
    return tabJSX
  }

  const rJSX_GoogleSheetsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card>
        <TableBasic
          tableAdditionalData={{}}
          tableColumns={tableColumns_GoogleSheets}
          tableData={objectToArray(us_googleSheets).sort(dynamicSort('name', 'asc'))}
          tableSettings={tableSettings_GoogleSheets}
        />
      </Card>
    )
    return tabJSX
  }

  const rJSX_InboundEmailsTestDataTab = (): JSX.Element => {
    let tabJSX = (
      <Box>
        <Json
          data={us_testInboundEmailTestData}
          alphabetized={true}
        />
      </Box>
    )
    return tabJSX
  }

  const rJSX_GHL_AccessTokensTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <Json
          data={us_ghlAccessTokens}
          alphabetized={true}
        />
      </Box>
    )
    return tabJSX
  }

  const rJSX_RefreshGHLAccessTokensButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        variant="contained"
        color="success"
        startIcon={<Icon icon="arrows-retweet" />}
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: formInputs_InboundEmailParser,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {})
                      .catch((rej_GCK) => {
                        reject(rej_GCK)
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: <>{rLIB('New Inbound Email Parser')}</>,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      >
        {rLIB('Add Inbound Email Parser')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Automatic Data Imports')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'inbound_emails',
                  tabHeader: rLIB('Inbound Emails'),
                  tabButtons: [{ fullJSX: rJSX_AddInboundEmailButton(false), minJSX: rJSX_AddInboundEmailButton(false), sizeCutoff: 0 }],
                  tabContent: rJSX_InboundEmailsTab(),
                },
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'google_sheets',
                  tabHeader: rLIB('Google Sheets'),
                  tabButtons: [{ fullJSX: rJSX_GoogleSheetButton(false), minJSX: rJSX_GoogleSheetButton(false), sizeCutoff: 0 }],
                  tabContent: rJSX_GoogleSheetsTab(),
                },
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'inbound_emails_test_data',
                  tabHeader: rLIB('Inbound Emails Test Data'),
                  tabButtons: [{ fullJSX: rJSX_AddInboundEmailButton(true), minJSX: rJSX_AddInboundEmailButton(true), sizeCutoff: 0 }],
                  tabContent: rJSX_InboundEmailsTestDataTab(),
                },
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'ghl_access_tokens',
                  tabHeader: rLIB('GHL Access Tokens'),
                  tabButtons: [
                    { fullJSX: rJSX_AddInboundEmailButton(true), minJSX: rJSX_AddInboundEmailButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_RefreshGHLAccessTokensButton(), minJSX: rJSX_RefreshGHLAccessTokensButton(), sizeCutoff: 0 },
                  ],
                  tabContent: rJSX_GHL_AccessTokensTab(),
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.AutomaticDataImportPages.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Automatic Data Imports', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
