//======================================//
//		  ooOOOO BOILERPLATE TEMPLATE   //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Generic unbranded login page

		TODO:
			[ ] Feature - Get Login email working

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Divider, Stack } from '@mui/material/'
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'
import { useContext, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { rJSX_StagingWarning } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Form, TsInterface_FormInputs, TsInterface_FormSettings, TsType_FormOnChange, TsType_FormSubmission } from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_UserInterface_AlertDialog, Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { authWithGoogle, authWithPassword, sendAuthLinkToEmail, sendPasswordResetToEmail } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

// @ts-expect-error - TODO: reason for error
import googleLogoSrc from '../../../rfbp_core/services/user_authentication/images/google.png'
// @ts-expect-error - TODO: reason for error
import logoSrc2 from '../../images/logos/logo_temp.png'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
// const s_POWERWALL_INSTALLATIONS: JSX.Element = 								<Trans>Powerwall Installations</Trans>
const s_ALL_RIGHTS_RESERVED: JSX.Element = <Trans>All right reserved</Trans>
const s_BACK: JSX.Element = <Trans>Back</Trans>
const s_BACK_TO_LOGIN: JSX.Element = <Trans>Back to Log In</Trans>
const s_CHECK_YOUR_EMAIL_FOR_A_LOGIN_LINK: JSX.Element = <Trans>Check your email for a login link</Trans>
const s_COPYRIGHT: JSX.Element = <Trans>Copyright</Trans>
const s_EMAIL: JSX.Element = <Trans>Email</Trans>
const s_EMAIL_SENT_SUCCESSFULLY: JSX.Element = <Trans>Email Sent Successfully</Trans>
const s_ETW_ENERGY_LLC: JSX.Element = <Trans>ETW Energy, LLC</Trans>
const s_GOOGLE_AUTHENTICATION_IS_LIMITED_TO_EMPLOYEES_ONLY: JSX.Element = <Trans>Google authentication is limited to employees only</Trans>
const s_LOG_IN: JSX.Element = <Trans>Log In</Trans>
const s_LOG_IN_WITH_PASSWORD: JSX.Element = <Trans>Log in with password</Trans>
const s_PRIVACY_POLICY: JSX.Element = <Trans>Privacy Policy</Trans>
const s_RESET_PASSWORD: JSX.Element = <Trans>Reset Password</Trans>
const s_SEND_LOGIN_EMAIL: JSX.Element = <Trans>Send Login Email</Trans>
const s_SEND_PASSWORD_RESET_EMAIL: JSX.Element = <Trans>Send Password Reset Email</Trans>
const s_SIGN_IN_WITH_GOOGLE: JSX.Element = <Trans>Sign in with Google</Trans>
const s_TERMS_OF_SERVICE: JSX.Element = <Trans>Terms of Service</Trans>
const s_YOUR_PASSWORD_RESET_EMAIL_SHOULD_ARRIVE_MOMENTARILY: JSX.Element = <Trans>Your password reset email should arrive momentarily</Trans>
const se_ETW_ENERGY_LOGIN = 'ETW Energy - Login'
// { sort-end } - displayed text

// CSS
let pageCSS: string = `
		body {
			background: #000000;
		}
	`

// Passwordless Auth
const auth = getAuth()

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  // url: 'https://etw.app/login',
  url: getDomainWithProtocol(window.location.href) + '/login',
  // url: 'https://etw.page.link/sign_in',
  // This must be true.
  handleCodeInApp: true,
  iOS: {
    bundleId: 'com.etwenergy',
  },
  android: {
    packageName: 'com.etwenergy',
    installApp: true,
    minimumVersion: '12',
  },
  dynamicLinkDomain: undefined,
  // dynamicLinkDomain: 'example.page.link'
}

// Form Properties
const formInputs_Login: TsInterface_FormInputs = {
  email: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email',
    label: s_EMAIL,
    required: true,
    startAdornment: (
      <Icon
        icon="user"
        type="light"
      />
    ),
  },
  password: {
    data_type: 'string',
    input_type: 'text_password',
    key: 'password',
    label: rLIB('Password'),
    required: true,
    startAdornment: (
      <Icon
        icon="lock-keyhole"
        type="light"
      />
    ),
  },
}

const formSettings_Login: TsInterface_FormSettings = {
  submit_button_theme: 'primary',
  submit_button_alignment: 'center',
  submit_button_hide: false,
  submit_button_icon: <Icon icon="right-to-bracket" />,
  submit_button_saving_icon: true,
  submit_button_text: <>{s_LOG_IN}</>,
}

const formInputs_ForgotEmail: TsInterface_FormInputs = {
  email: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email',
    label: s_EMAIL,
    required: true,
    startAdornment: (
      <Icon
        icon="user"
        type="light"
      />
    ),
  },
}

const FormSettings_ForgotEmail: TsInterface_FormSettings = {
  submit_button_theme: 'primary',
  submit_button_alignment: 'center',
  submit_button_hide: false,
  submit_button_icon: <Icon icon="paper-plane" />,
  submit_button_saving_icon: true,
  submit_button_text: <>{s_SEND_PASSWORD_RESET_EMAIL}</>,
}

const formInputs_PasswordlessLogin: TsInterface_FormInputs = {
  email: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email',
    label: s_EMAIL,
    required: true,
    startAdornment: (
      <Icon
        icon="user"
        type="light"
      />
    ),
  },
}

const formSettings_PasswordlessLogin: TsInterface_FormSettings = {
  submit_button_theme: 'primary',
  submit_button_alignment: 'center',
  submit_button_hide: false,
  submit_button_icon: <Icon icon="paper-plane" />,
  submit_button_saving_icon: true,
  submit_button_text: <>{s_SEND_LOGIN_EMAIL}</>,
}

///////////////////////////////
// Functions
///////////////////////////////

function getDomainWithProtocol(url: string) {
  const protocol = url.match(/^(https?:\/\/)/i)
  if (protocol) {
    return protocol[0] + url.substr(protocol[0].length).split('/')[0]
  }
  return url
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_formAdditionalData_ForgotEmail, us_setFormAdditionalData_ForgotEmail] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formAdditionalData_Login, us_setFormAdditionalData_Login] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formAdditionalData_PasswordlessLogin, us_setFormAdditionalData_PasswordlessLogin] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formData_ForgotEmail, us_setFormData_ForgotEmail] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formData_Login, us_setFormData_Login] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formData_PasswordlessLogin, us_setFormData_PasswordlessLogin] = useState<TsInterface_UnspecifiedObject>({})
  const [us_pageType, us_setPageType] = useState<string>('select')
  const un_routerNavigation = useNavigate()
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_ETW_ENERGY_LOGIN
  }, [])

  useEffect(() => {
    us_setFormData_Login({})
    us_setFormAdditionalData_Login({})
    us_setFormData_ForgotEmail({})
    us_setFormAdditionalData_ForgotEmail({})
    // setFormData_PasswordlessLogin({})
    us_setFormAdditionalData_PasswordlessLogin({})
    let email = window.localStorage.getItem('emailForSignIn')
    if (email != null) {
      us_setFormData_PasswordlessLogin({ email: email })
    } else {
      us_setFormData_PasswordlessLogin({})
    }
    return () => {}
  }, [])

  useEffect(() => {
    // console.log("V0.6")
    // console.log("1) sign in")
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn')
      // console.log("2) email: ")
      // console.log( email )
      if (email != null) {
        // console.log("3) passed")
        signInWithEmailLink(auth, email, window.location.href)
          .then((res_SIWEL) => {
            // console.log("4) passed")
            // User signed in successfully
            window.localStorage.removeItem('emailForSignIn')
            directAppNavigation(un_routerNavigation, ApplicationPages.HomePage.url())
          })
          .catch((rej_SIWEL) => {
            // Handle errors here
            console.error(rej_SIWEL)
          })
      } else {
        // console.log("5) No Email"  )
      }
    } else {
      // console.log("6) isSignInWithEmailLink: false"  )
    }
  }, [un_routerNavigation])

  // Functions
  const formOnChange_Login: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings, formHooks) => {
    // Nothing
  }

  const formSubmission_Login: TsType_FormSubmission = (formSubmittedData, formAdditionalData) => {
    return new Promise((resolve, reject) => {
      authWithPassword(formSubmittedData.email, formSubmittedData.password)
        .then((res_AWP) => {
          directAppNavigation(un_routerNavigation, ApplicationPages.HomePage.url())
          resolve(res_AWP)
        })
        .catch((rej_AWP) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_AWP.error })
          reject(rej_AWP)
        })
    })
  }

  const formOnChange_PasswordlessLogin: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings, formHooks) => {
    // Nothing
  }

  const formSubmission_PasswordlessLogin: TsType_FormSubmission = (formSubmittedData, formAdditionalData) => {
    return new Promise((resolve, reject) => {
      console.log('7) ')
      console.log(actionCodeSettings)
      sendAuthLinkToEmail(formSubmittedData.email, actionCodeSettings)
        .then((res_SALTE) => {
          uc_setUserInterface_AlertDialogDisplay({
            display: true,
            alert: {
              color: 'info',
              header: s_EMAIL_SENT_SUCCESSFULLY,
              icon: <Icon icon="paper-plane" />,
              text: s_CHECK_YOUR_EMAIL_FOR_A_LOGIN_LINK,
            },
          })
          resolve(res_SALTE)
        })
        .catch((rej_SALTE) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_SALTE.error })
          reject(rej_SALTE)
        })
    })
  }

  const formOnChange_ForgotEmail: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings, formHooks) => {
    // Nothing
  }

  const formSubmission_ForgotEmail: TsType_FormSubmission = (formSubmittedData, formAdditionalData) => {
    return new Promise((resolve, reject) => {
      sendPasswordResetToEmail(formSubmittedData.email)
        .then((res_SPRTE) => {
          uc_setUserInterface_AlertDialogDisplay({
            display: true,
            alert: {
              color: 'info',
              header: s_EMAIL_SENT_SUCCESSFULLY,
              icon: <Icon icon="paper-plane" />,
              text: s_YOUR_PASSWORD_RESET_EMAIL_SHOULD_ARRIVE_MOMENTARILY,
            },
          })
          us_setPageType('log_in')
          resolve(res_SPRTE)
        })
        .catch((rej_SPRTE) => {
          reject(rej_SPRTE)
        })
    })
  }

  // JSX Generation
  const rJSX_Header = (): JSX.Element => {
    let headerJSX = (
      <Stack
        direction="row"
        className="tw-justify-between"
      >
        <Box
          className="tw-m-2 tw-cursor-pointer"
          component="img"
          sx={{ width: 126, height: 36 }}
          alt="logo"
          src={logoSrc2}
          onClick={(event) => {
            onClickAppNavigation(event, un_routerNavigation, ApplicationPages.UnauthenticatedLandingPage.url())
          }}
        />
        <Box></Box>
      </Stack>
    )
    return headerJSX
  }

  const rJSX_Footer = (): JSX.Element => {
    let footerJSX = <></>
    footerJSX = (
      <Box sx={{ marginTop: 'auto' }}>
        <Divider />
        <Stack
          direction="row"
          className="tw-justify-between"
        >
          <Box className="tw-text-center">
            <Button
              size="small"
              color="inherit"
              className="tw-opacity-30 tw-ml-1 tw-cursor-pointer"
              onClick={(event) => {
                onClickAppNavigation(event, un_routerNavigation, ApplicationPages.PrivacyPolicyPage.url())
              }}
            >
              {s_PRIVACY_POLICY}
            </Button>
            <Button
              size="small"
              color="inherit"
              className="tw-opacity-30 tw-ml-1 tw-cursor-pointer"
              onClick={(event) => {
                onClickAppNavigation(event, un_routerNavigation, ApplicationPages.TermsOfServicePage.url())
              }}
            >
              {s_TERMS_OF_SERVICE}
            </Button>
          </Box>
          <Box
            className="tw-mt-1 tw-mr-1 tw-opacity-30 tw-text-center"
            sx={{ fontSize: '13px' }}
          >
            {s_COPYRIGHT} © {new Date().getFullYear()} {s_ETW_ENERGY_LLC}. {s_ALL_RIGHTS_RESERVED}.
          </Box>
        </Stack>
      </Box>
    )
    return footerJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let loginFormJSX: JSX.Element = <></>
    if (us_pageType === 'select') {
      loginFormJSX = (
        <Box sx={{ width: '100%' }}>
          <Box
            className="tw-p-4"
            sx={{ width: '460px' }}
          >
            <Box className="tw-mb-1">
              <Button
                variant="outlined"
                color="inherit"
                className="tw-m-1"
                onClick={() => {
                  us_setPageType('passwordless_log_in')
                }}
              >
                <Icon
                  icon="paper-plane"
                  className="tw-mr-2"
                  type="solid"
                />
                {s_SEND_LOGIN_EMAIL}
              </Button>
            </Box>
            <Box className="tw-mb-1">
              <Button
                variant="outlined"
                color="inherit"
                className="tw-m-1"
                onClick={() => {
                  us_setPageType('log_in')
                }}
              >
                <Icon
                  icon="key"
                  className="tw-mr-2"
                  type="solid"
                />
                {s_LOG_IN_WITH_PASSWORD}
              </Button>
            </Box>
            <Box className="tw-mb-1">
              <Button
                variant="outlined"
                color="info"
                className="tw-m-1"
                onClick={() => {
                  // setPageType("log_in")
                  authWithGoogle({ 'etwenergy.com': true }, s_GOOGLE_AUTHENTICATION_IS_LIMITED_TO_EMPLOYEES_ONLY)
                    .then((res_AWG) => {
                      directAppNavigation(un_routerNavigation, ApplicationPages.HomePage.url())
                    })
                    .catch((rej_AWG) => {
                      // Handle errors here
                      console.error(rej_AWG)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_AWG.error })
                    })
                }}
              >
                <img
                  src={googleLogoSrc}
                  alt=""
                  loading="lazy"
                  width={'20px'}
                  className="tw-mr-1"
                />
                {s_SIGN_IN_WITH_GOOGLE}
              </Button>
            </Box>
          </Box>
        </Box>
      )
    } else if (us_pageType === 'passwordless_log_in') {
      loginFormJSX = (
        <Box sx={{ width: '100%' }}>
          <Box
            className="tw-p-4"
            sx={{ width: '360px' }}
          >
            <Form
              formAdditionalData={us_formAdditionalData_PasswordlessLogin}
              formData={us_formData_PasswordlessLogin}
              formInputs={formInputs_PasswordlessLogin}
              formOnChange={formOnChange_PasswordlessLogin}
              formSettings={formSettings_PasswordlessLogin}
              formSubmission={formSubmission_PasswordlessLogin}
            />
          </Box>
          <Box className="tw-mb-16 tw-mt-1">
            <Button
              variant="text"
              color="inherit"
              className="tw-opacity-30 tw-m-1"
              onClick={() => {
                us_setPageType('select')
              }}
            >
              {s_BACK}
            </Button>
          </Box>
        </Box>
      )
    } else if (us_pageType === 'log_in') {
      loginFormJSX = (
        <Box sx={{ width: '100%' }}>
          <Box
            className="tw-p-4"
            sx={{ width: '360px' }}
          >
            <Form
              formAdditionalData={us_formAdditionalData_Login}
              formData={us_formData_Login}
              formInputs={formInputs_Login}
              formOnChange={formOnChange_Login}
              formSettings={formSettings_Login}
              formSubmission={formSubmission_Login}
            />
          </Box>
          <Box className="tw-mb-16 tw-mt-1">
            <Button
              variant="text"
              color="inherit"
              className="tw-opacity-30 tw-m-1"
              onClick={() => {
                us_setPageType('select')
              }}
            >
              {s_BACK}
            </Button>
            <Button
              variant="text"
              color="inherit"
              className="tw-opacity-30 tw-m-1"
              onClick={() => {
                us_setPageType('forgot_password')
              }}
            >
              {s_RESET_PASSWORD}
            </Button>
          </Box>
        </Box>
      )
    } else if (us_pageType === 'forgot_password') {
      loginFormJSX = (
        <Box sx={{ width: '100%' }}>
          <Box
            className="tw-p-4"
            sx={{ width: '360px' }}
          >
            <Form
              formAdditionalData={us_formAdditionalData_ForgotEmail}
              formData={us_formData_ForgotEmail}
              formInputs={formInputs_ForgotEmail}
              formOnChange={formOnChange_ForgotEmail}
              formSettings={FormSettings_ForgotEmail}
              formSubmission={formSubmission_ForgotEmail}
            />
          </Box>
          <Box className="tw-mb-16 tw-mt-1">
            <Button
              variant="text"
              color="inherit"
              className="tw-opacity-30 tw-m-1"
              onClick={() => {
                us_setPageType('log_in')
              }}
            >
              {s_BACK_TO_LOGIN}
            </Button>
          </Box>
        </Box>
      )
    }
    let pageJSX = (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        {rJSX_StagingWarning()}
        {rJSX_Header()}
        <Divider />
        <Box className="tw-text-center tw-m-auto">{loginFormJSX}</Box>
        {rJSX_Footer()}
        <style>{pageCSS}</style>
      </Box>
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
