import { TsInterface_CashDisclosureForm, TsInterface_LeaseDisclosureForm } from '../interfaces/i_sales_tools'

export const leaseDisclosureForm: TsInterface_LeaseDisclosureForm = {
  vendor_id: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Must match an AV ID that is associated with your user account',
    options: [],
    label: 'Vendor ID',
    validation_check: (value: number) => {
      return value.toString().length > 0
    },
  },
  reference_number: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'AV Reference Number',
    validation_check: (value: string) => {
      return value.length > 0
    },
  },
  form_name: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Name must be unique, it cannot already exist in your list of DFs',
    options: [],
    label: 'Disclosure Form Name',
    validation_check: (value: string) => {
      return value.length > 0
    },
  },
  form_type: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: 'lease_form',
    instructions: '',
    options: [],
    label: 'Form Type',
    validation_check: (value: string) => {
      return value === 'lease_form' || value === 'cash_form'
    },
  },
  seller_legal_name: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: 'This will also be used as the preset name if you choose to save this form as a preset',
    options: [],
    label: 'Project Lessor Legal Name',
    validation_check: (value: string) => {
      return value.length > 0
    },
  },
  seller_marketing_name: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Project Lessor Name Used for Marketing',
  },
  seller_address_1: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: '',
    options: [],
    label: 'Project Lessor Street',
    validation_check: (value: string) => {
      return value.length > 0
    },
  },
  seller_address_2: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Project Lessor Suite',
  },
  seller_address_city: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: '',
    options: [],
    label: 'Project Lessor City',
    validation_check: (value: string) => {
      return value.length > 0
    },
  },
  seller_address_state: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: 'Please use a two-letter state abbreviation',
    options: [],
    label: 'Project Lessor State',
    validation_check: (value: string) => {
      return value.length === 2
    },
  },
  seller_address_zip: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: '',
    options: [],
    label: 'Project Lessor Zip Code',
    validation_check: (value: string) => {
      return value.length === 5
    },
  },
  seller_address_phone: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: '10 digits, do not include dashes or parenthesis',
    options: [],
    label: 'Project Lessor Phone',
    validation_check: (value: string) => {
      const phoneRegex = /^\d{10}$/
      return value.length === 10 && phoneRegex.test(value)
    },
  },
  seller_address_email: {
    is_required: true,
    input_type: 'text',
    value: '',
    conditional_dependencies: {},
    instructions: '',
    options: [],
    label: 'Project Lessor Email',
    validation_check: (value: string) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return value.length > 0 && emailRegex.test(value)
    },
  },
  customer_type: {
    is_required: true,
    input_type: 'dropdown',
    options: ['Residential/Small Commercial', 'Large Commercial/Industrial', 'Public School'],
    value: 'Residential/Small Commercial',
    conditional_dependencies: {},
    instructions: '',
    label: 'Project Type',
  },
  customer_name: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Name',
    validation_check: (value: string) => {
      return value.length > 0
    },
  },
  customer_address_1: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Street',
    validation_check: (value: string) => {
      return value.length > 0
    },
  },
  customer_address_2: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Apartment or Suite',
  },
  customer_address_city: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer City',
    validation_check: (value: string) => {
      return value.length > 0
    },
  },
  customer_address_state: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: 'IL',
    instructions: 'Please use a two-letter state abbreviation',
    options: [],
    label: 'Customer State',
    validation_check: (value: string) => {
      return value.length === 2
    },
  },
  customer_address_zip: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Zip Code',
    validation_check: (value: string) => {
      return value.length === 5
    },
  },
  customer_address_phone: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '10 digits, do not include dashes or parentheses',
    options: [],
    label: 'Customer Phone',
    validation_check: (value: string) => {
      const phoneRegex = /^\d{10}$/
      return value.length === 10 && phoneRegex.test(value)
    },
  },
  customer_address_email: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Email',
    validation_check: (value: string) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return value.length > 0 && emailRegex.test(value)
    },
  },
  electric_utility: {
    is_required: true,
    input_type: 'dropdown',
    value: 'ComEd',
    conditional_dependencies: {},
    instructions: '',
    options: ['AmerenIllinois', 'ComEd', 'MidAmerican', 'Mt. Carmel', 'Municipal Utility', 'Rural Electric Cooperative'],
    label: 'Electric Utility',
  },
  muni_coop_name: {
    input_type: 'text',
    value: '',
    is_required: false,
    conditional_dependencies: { electric_utility: ['Municipal Utility', 'Rural Electric Cooperative'] },
    instructions: '',
    options: [],
    label: 'Electric Utility Name',
  },
  installer_known: {
    input_type: 'dropdown',
    value: 'No',
    is_required: true,
    conditional_dependencies: {},
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is the Project Installer Known at This Time?',
  },
  installer_address_2: {
    input_type: 'text',
    value: '',
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    instructions: '',
    options: [],
    label: 'Installer Apartment or Suite',
  },
  installer_legal_name: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { installer_known: ['Yes'] },
    instructions: '',
    options: [],
    label: 'Installer Legal Name',
  },
  installer_marketing_name: {
    is_required: false,
    conditional_dependencies: { installer_legal_name: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Installer Name Used for Marketing',
  },
  installer_address_1: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { installer_known: ['Yes'] },
    instructions: '',
    options: [],
    label: 'Installer Street',
  },
  installer_address_city: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { installer_known: ['Yes'] },
    instructions: '',
    options: [],
    label: 'Installer City',
  },
  installer_address_state: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { installer_known: ['Yes'] },
    instructions: 'Use a two-letter state abbreviation',
    options: [],
    label: 'Installer State',
  },
  installer_address_zip: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { installer_known: ['Yes'] },
    instructions: '',
    options: [],
    label: 'Installer Zip Code',
  },
  installer_address_phone: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { installer_known: ['Yes'] },
    instructions: '10 digits, do not include dashes or parenthesis',
    options: [],
    label: 'Installer Phone',
  },
  installer_address_email: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { installer_known: ['Yes'] },
    instructions: '',
    options: [],
    label: 'Installer Email',
  },
  designee_email: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: {},
    instructions: '',
    options: [],
    label: 'Designee Email',
  },
  installer_name_1: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: {},
    instructions: '',
    options: [],
    label: 'Installer Legal Name 1',
  },
  installer_name_2: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: {},
    instructions: '',
    options: [],
    label: 'Installer Legal Name 2',
  },
  installer_name_3: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: {},
    instructions: '',
    options: [],
    label: 'Installer Legal Name 3',
  },
  initial_deposit_owed: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Deposit, Down Payment, or Initial Upfront Amount',
    validation_check: (value: string) => {
      return value.length > 0
    },
  },
  initial_amount_due: {
    input_type: 'number',
    value: 0,
    is_required: true,
    conditional_dependencies: {},
    instructions: 'Omit $ sign and commas, can be left blank or greater than/equal to 0',
    options: [],
    label: 'First Lease Payment Amount',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  final_amount_due: {
    input_type: 'number',
    value: 0,
    is_required: true,
    conditional_dependencies: {},
    instructions: 'Omit $ sign and commas, can be left blank or greater than/equal to 0',
    options: [],
    label: 'Final Lease Payment Amount',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  total_payments_amount: {
    input_type: 'number',
    value: 0,
    is_required: true,
    conditional_dependencies: {},
    instructions: 'Omit $ sign and commas. Do not include any down payment or other fees, can be left blank or greater than/equal to 0',
    options: [],
    label: 'Total Amount of All Lease Payments',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  frequency_payments: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Free text: enter a description of the frequency of the lease payments',
    options: [],
    label: 'Frequency of Lease Payments',
  },
  first_payment_due: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Free text: enter a description or a date (mm/dd/yyyy format)',
    options: [],
    label: 'When the First Payment is Due',
  },
  final_payment_due: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Free text: enter a description or a date (mm/dd/yyyy format)',
    options: [],
    label: 'When the Final Payment is Due',
  },
  total_payments: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Can be left blank or greater than/equal to 0',
    options: [],
    label: 'Total Number of Payments',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  term_months: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Enter a number from 0-11',
    options: [],
    label: 'Lease Term Portion (Months)',
    validation_check: (value: number) => {
      return value >= 0 && value <= 11
    },
  },
  term_years: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Enter a number from 0-25',
    options: [],
    label: 'Lease Term Portion (Years)',
    validation_check: (value: number) => {
      return value >= 0 && value <= 25
    },
  },
  escalation_rate: {
    input_type: 'number',
    value: 0,
    is_required: true,
    conditional_dependencies: {},
    instructions: 'Omit % symbol, can be left blank or greater than/equal to 0',
    options: [],
    label: 'Annual Escalation Rate',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  expected_rec_value: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas, can be left blank or greater than/equal to 0',
    options: [],
    label: 'Expected Value Received by Approved Vendor for REC Incentive Payments',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  abp_contingent: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is the Lease Contingent Upon Selection for the Adjustable Block Program Incentive?',
  },
  will_terms_change: {
    is_required: false,
    conditional_dependencies: { abp_contingent: ['No'] },
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will the Pricing Terms Change if the Project is Not Selected for the Adjustable Block Program Incentive?',
  },
  mechanic_waiver_date: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: 'Free text: enter a description or a date (mm/dd/yyyy format)',
    options: [],
    label: 'Estimated Date for Mechanics Lien Waiver',
  },
  interconnection_party: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'Lessor',
    instructions: '',
    options: ['Lessor', 'Approved Vendor', 'Installer', 'Customer'],
    label: 'Party Responsible for Submitting a Project Interconnection Application to the Utility',
  },
  file_uniform_statement: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Does the Lessor Intend to File a Uniform Commercial Code 1 Filing Statement?',
  },
  project_size_kw_dc: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 3,
    instructions: 'Enter a number from 3-25',
    options: [],
    label: 'Project Size DC kW',
    validation_check: (value: number) => {
      return value >= 3 && value <= 25
    },
  },
  project_size_kw_ac: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Enter a number greater than/equal to 0',
    options: [],
    label: 'Project Size AC kW',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  expected_project_life: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Enter a number greater than/equal to 0',
    options: [],
    label: 'Expected Life of the Project (Years)',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  mounting_location: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'Roof-mounted',
    instructions: '',
    options: ['Ground-mounted', 'Roof-mounted'],
    label: 'Mounting Location',
  },
  gross_electric_production: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 3000,
    instructions: 'Omit kWh, enter a number from 3000-30000',
    options: [],
    label: 'Estimated Gross Annual Electricity Production',
    validation_check: (value: number) => {
      return value >= 3000 && value <= 30000
    },
  },
  smart_inverter_rebate_submission: {
    input_type: 'dropdown',
    value: 'No',
    is_required: true,
    conditional_dependencies: { electric_utility: ['AmerenIllinois', 'ComEd'] },
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will an Application be Submitted for the Smart Inverter DG Rebate?',
  },
  rebate_amount: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { smart_inverter_rebate_submission: ['Yes'] },
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Rebate Amount',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  who_keeps_rebate: {
    is_required: false,
    input_type: 'dropdown',
    value: 'Customer',
    conditional_dependencies: { smart_inverter_rebate_submission: ['Yes'] },
    instructions: '',
    options: ['Customer', 'Lessor', 'Approved Vendor', 'Installer'],
    label: 'Who Keeps the Rebate Payment?',
  },
  maintenance_included: {
    input_type: 'dropdown',
    value: 'No',
    is_required: true,
    conditional_dependencies: {},
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is Project Maintenance Operation Upkeep Included as Part of the Lease?',
  },
  maintenance_party: {
    is_required: false,
    input_type: 'dropdown',
    value: 'Lessor',
    conditional_dependencies: { maintenance_included: ['Yes'] },
    instructions: '',
    options: ['Lessor', 'Approved Vendor', 'Installer'],
    label: 'Who is Responsible for Project Maintenance?',
  },
  maintenance_years: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { maintenance_included: ['Yes'] },
    instructions: '',
    options: [],
    label: 'For How Many Years is Maintenance Included?',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  repairs_included: {
    input_type: 'dropdown',
    value: 'No',
    is_required: true,
    conditional_dependencies: {},
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Are Project Repairs Required to Fix a Malfunctioning System Included as Part of the Lease?',
  },
  repairs_party: {
    is_required: false,
    input_type: 'dropdown',
    value: 'Installer',
    conditional_dependencies: { repairs_included: ['Yes'] },
    instructions: '',
    options: ['Lessor', 'Approved Vendor', 'Installer'],
    label: 'Who is Responsible for Repairs?',
  },
  repairs_years: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { repairs_included: ['Yes'] },
    instructions: 'If included, enter a number greater than 0',
    options: [],
    label: 'For How Many Years Are Project Repairs Included?',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  install_warranty_included: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is the Project Warrantied Against Issues Related to Improper Installation?',
  },
  install_warranty_party: {
    is_required: false,
    input_type: 'dropdown',
    value: 'Lessor',
    conditional_dependencies: { install_warranty_included: ['Yes'] },
    instructions: '',
    options: ['Lessor', 'Approved Vendor', 'Installer'],
    label: 'Who is Responsible for Warranties Related to Improper Installation?',
  },
  install_warranty_years: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { install_warranty_included: ['Yes'] },
    instructions: '',
    options: [],
    label: 'For How Many Years is the Install Warranty Included?',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  roof_warranty_included: {
    is_required: false,
    input_type: 'dropdown',
    value: 'No',
    conditional_dependencies: { mounting_location: ['Roof-mounted'] },
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is the Roof Warrantied Against Leaks from the Project Installation?',
  },
  roof_warranty_party: {
    is_required: false,
    input_type: 'dropdown',
    value: 'Lessor',
    conditional_dependencies: { roof_warranty_included: ['Yes'] },
    instructions: '',
    options: ['Lessor', 'Approved Vendor', 'Installer'],
    label: 'Who is Responsible for the Warranty Against Roof Leaks Due to Improper Installation?',
  },
  roof_warranty_years: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { roof_warranty_included: ['Yes'] },
    instructions: '',
    options: [],
    label: 'For How Many Years Is the Warranty Against Roof Leaks Included',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  panel_warranty_included: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Do the Solar Panels Come with a Manufacturers Warranty',
  },
  panel_warranty_years: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { panel_warranty_included: ['Yes'] },
    instructions: '',
    options: [],
    label: "For How Many Years Is the Panel Manufacturer's Warranty Included?",
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  inverter_warranty: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: "Does the Inverter Come with a Manufacturer's Warranty?",
  },
  inverter_warranty_years: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { inverter_warranty: ['Yes'] },
    instructions: '',
    options: [],
    label: "For How Many Years Is the Inverter Manufacturer's Warranty Included?",
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  loss_damage_included: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will the Lessor Insure for Loss or Damage to the Project?',
  },
  loss_damage_exceptions: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { loss_damage_included: ['Yes'] },
    instructions: '',
    options: [],
    label: 'Provide Details of Insurance Coverage, Including Limitations of Coverage',
  },
  termination_penalty: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will the Lessor Impose a Fee or Penalty for Early Termination of the Lease by the Lessee Prior to the Conclusion of the Contract Term?',
  },
  termination_fee: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: {
      termination_penalty: ['Yes'],
    },
    instructions: 'Leave blank if description will be provided',
    options: [],
    label: 'Amount of Fee Penalty',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  termination_calculation: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: {
      termination_fee: ['Yes'],
    },
    instructions: 'Free text: enter a description',
    options: [],
    label: 'Or Will be Calculated as Follows',
  },
  remove_system: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will the Lessor Remove the Project at the Conclusion of the Term of the System Lease?',
  },
  return_site_original: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will the Lessor Return the Site to Its Original Condition Upon the Removal of the System?',
  },
  own_option: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will the Lessor Offer the Customer the Option to Own and Retain the System at the Conclusion of the Term of the System Lease?',
  },
  renew_option: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will the Lessor Offer the Customer the Option to Renew After the Initial Term of the System Lease?',
  },
  transfer_option: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label:
      'If the Home or Business Where the Solar Project is Located is Sold, Can the Customer Transfer the System Lease Obligations to the Purchasers of the Home or Business?',
  },
  transfer_requirements: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: {
      transfer_option: ['Yes'],
    },
    instructions: 'Free text: enter a description',
    options: [],
    label: 'Requirements for Transferring Lease Obligations',
  },
  purchase_required: {
    is_required: false,
    input_type: 'dropdown',
    value: 'No',
    conditional_dependencies: {
      transfer_option: ['No'],
    },
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is the Customer Required to Purchase the Solar Project Before Selling the Property Where it is Located?',
  },
  fee_name_1: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'List up to 5 fees that will apply to the project. If there are no fees, leave blank',
    options: [],
    label: 'Fee Name 1',
  },
  fee_amount_1: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_1: ['any'] },
    instructions: '',
    options: [],
    label: 'Amount 1',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  fee_due_1: {
    input_type: 'text',
    value: '',
    is_required: false,
    conditional_dependencies: { fee_name_1: ['any'] },
    instructions: '',
    options: [],
    label: 'When its Due 1',
  },
  fee_payment_1: {
    input_type: 'number',
    value: 0,
    is_required: false,
    conditional_dependencies: { fee_name_1: ['any'] },
    instructions: '',
    options: [],
    label: 'Number of Payments 1',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  fee_name_2: {
    is_required: false,
    conditional_dependencies: { fee_name_1: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Fee Name 2',
  },
  fee_amount_2: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { fee_name_2: ['any'] },
    instructions: '',
    options: [],
    label: 'Amount 2',
  },
  fee_due_2: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { fee_name_2: ['any'] },
    instructions: '',
    options: [],
    label: 'When its Due 2',
  },
  fee_payment_2: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_2: ['any'] },
    instructions: '',
    options: [],
    label: 'Number of Payments 2',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  fee_name_3: {
    is_required: false,
    conditional_dependencies: { fee_name_2: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Fee Name 3',
  },
  fee_amount_3: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_3: ['any'] },
    instructions: '',
    options: [],
    label: 'Amount 3',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  fee_due_3: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { fee_name_3: ['any'] },
    instructions: '',
    options: [],
    label: 'When its Due 3',
  },
  fee_payment_3: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_3: ['any'] },
    instructions: '',
    options: [],
    label: 'Number of Payments 3',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  fee_name_4: {
    is_required: false,
    conditional_dependencies: { fee_name_3: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Fee Name 4',
  },
  fee_amount_4: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_4: ['any'] },
    instructions: '',
    options: [],
    label: 'Amount 4',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  fee_due_4: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { fee_name_4: ['any'] },
    instructions: '',
    options: [],
    label: 'When its Due 4',
  },
  fee_payment_4: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_4: ['any'] },
    instructions: '',
    options: [],
    label: 'Number of Payments 4',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  fee_name_5: {
    is_required: false,
    conditional_dependencies: { fee_name_4: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Fee Name 5',
  },
  fee_amount_5: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_5: ['any'] },
    instructions: '',
    options: [],
    label: 'Amount 5',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  fee_due_5: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { fee_name_5: ['any'] },
    instructions: '',
    options: [],
    label: 'When its Due 5',
  },
  fee_payment_5: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_5: ['any'] },
    instructions: '',
    options: [],
    label: 'Number of Payments 5',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  other_fee_name_1: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'List up to 5 other fees that will apply to the project. If there are no other fees, leave blank',
    options: [],
    label: 'Other Fee Name 1',
  },
  other_fee_amount_1: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_1: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee Amount 1',
  },
  other_fee_applicable_1: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_1: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee When Applicable 1',
  },
  other_fee_name_2: {
    is_required: false,
    conditional_dependencies: { other_fee_name_1: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Other Fee Name 2',
  },
  other_fee_amount_2: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_2: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee Amount 2',
  },
  other_fee_applicable_2: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_2: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee When Applicable 2',
  },
  other_fee_name_3: {
    is_required: false,
    conditional_dependencies: { other_fee_name_2: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Other Fee Name 3',
  },
  other_fee_amount_3: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_3: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee Amount 3',
  },
  other_fee_applicable_3: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_3: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee When Applicable 3',
  },
  other_fee_name_4: {
    is_required: false,
    conditional_dependencies: { other_fee_name_3: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Other Fee Name 4',
  },
  other_fee_amount_4: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_4: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee Amount 4',
  },
  other_fee_applicable_4: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_4: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee When Applicable 4',
  },
  other_fee_name_5: {
    is_required: false,
    conditional_dependencies: { other_fee_name_4: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Other Fee Name 5',
  },
  other_fee_amount_5: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_5: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee Amount 5',
  },
  other_fee_applicable_5: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: { other_fee_name_5: ['any'] },
    instructions: '',
    options: [],
    label: 'Other Fee When Applicable 5',
  },
  install_start_date_days: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Leave blank if unknown',
    options: [],
    label: 'The Estimated Start Date of Project Installation in Days from the Date the Contract is Signed',
  },
  install_start_date: {
    is_required: true,
    input_type: 'text',
    value: '',
    conditional_dependencies: {},
    instructions: 'yyyy-mm-dd format, ignore if the start days have been provided above.',
    options: [],
    label: 'Alternatively, the Estimated Start Date Will Be',
  },
  install_completion_date_days: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Leave blank if unknown',
    options: [],
    label: 'The Estimated Completion Date of Project Installation in Days from the Date the Contract is Signed',
  },
  install_completion_date: {
    is_required: true,
    input_type: 'text',
    value: '',
    conditional_dependencies: {},
    instructions: 'yyyy-mm-dd format, ignore if the completion days have been provided above.',
    options: [],
    label: 'Alternatively, the Estimated Completion Date Will Be',
  },
  expected_annual_electricity_usage: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 3000,
    instructions: 'Enter a number from 3000-30000',
    options: [],
    label: "Customer's Expected Annual Electricity Usage in kWh",
    validation_check: (value: number) => {
      return value >= 3000 && value <= 30000
    },
  },
  electric_service_billing_type: {
    is_required: false,
    conditional_dependencies: {
      customer_type: ['Residential/Small Commercial'],
      electric_utility: ['Mt. Carmel', 'Municipal Utility', 'Rural Electric Cooperative'],
    },
    input_type: 'dropdown',
    value: 'basic utility service',
    instructions:
      'Will the customer take utility basic electric service, utility hourly/dynamic/time of use service, or will the customer take supply from an ARES?',
    options: ['basic utility service', 'hourly/dynamic/time of use', 'ARES'],
    label: 'Electric Service Billing Type',
  },
  include_battery: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Does This Project Include a Battery for Energy Storage?',
  },
  battery_size: {
    is_required: false,
    conditional_dependencies: { include_battery: ['Yes'] },
    input_type: 'number',
    value: 0.2,
    instructions: 'What is the size of battery in kWh? Must be greater than 0.1',
    options: [],
    label: 'Battery Size',
    validation_check: (value: number) => {
      return value > 0.1
    },
  },
  energy_storage_rebate: {
    is_required: false,
    conditional_dependencies: { include_battery: ['Yes'], electric_utility: ['ComEd', 'AmerenIllinois'] },
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will an Application be Submitted for the Utility Energy Storage Rebate?',
  },
  energy_storage_rebate_recipient: {
    is_required: false,
    conditional_dependencies: { energy_storage_rebate: ['Yes'] },
    input_type: 'dropdown',
    value: 'customer',
    instructions: '',
    options: ['customer', 'Vendor/designee'],
    label: 'Energy Storage Rebate Recipient',
  },
  rebate_rate: {
    is_required: false,
    conditional_dependencies: {
      or: ['true'], // TODO: hacky way to make this work but this is the only OR of all the fields, can make it more general later
      energy_storage_rebate: ['Yes'],
      smart_inverter_rebate_submission: ['Yes'],
    },
    input_type: 'dropdown',
    value: 250,
    instructions: 'Is the customer eligible for rebates calculated based on the $300 rate, or the $250 rate?',
    options: [250, 300],
    label: 'Rebate Rate',
  },
  netmetering_excess_generation_credit: {
    is_required: false,
    conditional_dependencies: { electric_utility: ['Mt. Carmel', 'Municipal Utility', 'Rural Electric Cooperative'] },
    input_type: 'text',
    value: '',
    instructions:
      "Provide a complete explanation of how the customer's electric utility will credit the customer for excess electricity that is sent back to the grid, including the current crediting rate (cents/kWh) and any relevant limitations or restrictions on the crediting. Please use terminology that will be understandable to the customer.",
    options: [],
    label: 'Net Metering Excess Generation Credit',
  },
  project_performance_explanation: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Project Performance Explanation',
  },
  project_guarantee: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Free text: enter a description',
    options: [],
    label: 'Is the Lessor Providing a Project Guarantee? If so, please provide details below',
  },
  explanatory_information: {
    is_required: false,
    input_type: 'text',
    value: '',
    conditional_dependencies: {},
    instructions: 'Free text: can refer to any item in the disclosure form',
    options: [],
    label: 'Additional Details or Explanatory Information',
  },
  is_spanish: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Generate Form PDF in Spanish?',
  },
  auto_esign_email_option: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: 'Select yes if you would like to receive an email with the form attached',
    options: ['Yes', 'No'],
    label: 'Auto E-Sign Email Option',
  },
  substitute_auto_esign_emailaddr: {
    is_required: false,
    conditional_dependencies: { auto_esign_email_option: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Substitute Auto E-Sign Email Address',
  },
}

interface TsInterface_UtilityEntry {
  mappedUtility: string
  group: 'A' | 'B' | ''
}

interface TsInterface_UtilityGroups {
  [key: string]: TsInterface_UtilityEntry
}

const IL_utilityGroups: TsInterface_UtilityGroups = {
  'Ameren': { mappedUtility: 'Ameren Illinois', group: 'A' },
  'ComEd': { mappedUtility: 'ComEd', group: 'B' },
  'Tri-County Electric Coop, Inc (TCEC)- IL': { mappedUtility: 'Rural Electric Cooperative', group: 'A' },
  'Village of Chatham': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City Water Light and Power (CWLP)': { mappedUtility: 'Municipal Utility', group: 'A' },
  'Rural Electric Conven Coop (RECC)': { mappedUtility: 'Rural Electric Cooperative', group: 'A' },
  'City of Highland (COH)': { mappedUtility: 'Municipal Utility', group: 'A' },
  'Menard Electric Coop (MEC)': { mappedUtility: 'Rural Electric Cooperative', group: 'A' },
  'Wayne-White Counties Elec Coop (WWCEC)': { mappedUtility: 'Rural Electric Cooperative', group: 'A' },
  'Corn Belt': { mappedUtility: 'Rural Electric Cooperative', group: 'A' },
  'Southwestern Electric Coop Inc (SEC)': { mappedUtility: 'Rural Electric Cooperative', group: 'A' },
  'Southern Illinois Elec Coop (SIEC)': { mappedUtility: 'Rural Electric Cooperative', group: 'A' },
  'Rock Energy Cooperative (REC)': { mappedUtility: 'Rural Electric Cooperative', group: 'B' },
  'Shelby Electric Coop, Inc (SEC)': { mappedUtility: 'Rural Electric Cooperative', group: 'A' },
  'City of Batavia (COB)': { mappedUtility: 'Municipal Utility', group: 'B' },
  'Cairo Public Utility Company': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Allendale': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Altamont': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Breese': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Bushnell': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Carlyle': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Carmi': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Casey': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Fairfield': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Farmer City': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Flora': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Geneseo': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Geneva': { mappedUtility: 'Municipal Utility', group: 'B' },
  'City of Highland': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Marshall': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Mascoutah': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Metropolis': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Naperville': { mappedUtility: 'Municipal Utility', group: 'B' },
  'City of Oglesby': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Peru': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Princeton': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Red Bud': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Rock Falls': { mappedUtility: 'Municipal Utility', group: 'B' },
  'City of Roodhouse': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Springfield': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of St Charles': { mappedUtility: 'Municipal Utility', group: 'B' },
  'City of Sullivan': { mappedUtility: 'Municipal Utility', group: 'A' },
  'City of Waterloo': { mappedUtility: 'Municipal Utility', group: 'A' },
  'Clay Electric Coop Inc': { mappedUtility: 'Rural Electric Cooperative', group: 'A' },
  'Village of Bethany': { mappedUtility: 'Municipal Utility', group: 'A' },
  'Village of Freeburg': { mappedUtility: 'Municipal Utility', group: 'A' },
  'Village of Ladd': { mappedUtility: 'Municipal Utility', group: 'A' },
  'Village of Rantoul': { mappedUtility: 'Municipal Utility', group: '' },
  'Village of Riverton': { mappedUtility: 'Municipal Utility', group: '' },
  'Village of Winnetka': { mappedUtility: 'Municipal Utility', group: '' },
}

export const cashDisclosureForm: TsInterface_CashDisclosureForm = {
  vendor_id: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Must match an AV ID that is associated with your user account',
    options: [],
    label: 'Vendor ID',
    validation_check: (value: number) => {
      return value.toString().length > 0
    },
  },
  form_type: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: 'purchase_form',
    instructions: '',
    options: [],
    label: 'Form Type',
    validation_check: (value: string) => {
      return value === 'purchase_form'
    },
  },
  reference_number: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'AV Reference Number',
    validation_check: (value: string) => {
      return value.length > 0
    },
  },
  form_name: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Name must be unique, it cannot already exist in your list of DFs',
    options: [],
    label: 'Disclosure Form Name',
    validation_check: (value: string) => {
      return value.length > 0
    },
  },
  seller_legal_name: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: 'This will also be used as the preset name if you choose to save this form as a preset',
    options: [],
    label: 'Seller Legal Name',
    validation_check: (value: string) => {
      return value.length > 0
    },
  },
  seller_address_1: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: '',
    options: [],
    label: 'Seller Street',
    validation_check: (value: string) => {
      return value.length > 0
    },
  },
  seller_address_2: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Seller Suite',
  },
  seller_address_city: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Seller City',
    validation_check: (value: string) => {
      return value.length > 0
    },
  },
  seller_address_state: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Seller State',
    validation_check: (value: string) => {
      return value.length === 2
    },
  },
  seller_address_phone: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Seller Phone',
    validation_check: (value: string) => {
      const phoneRegex = /^\d{10}$/
      return phoneRegex.test(value)
    },
  },
  seller_address_email: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Seller Email',
    validation_check: (value: string) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(value)
    },
  },
  seller_address_zip: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Seller Zip Code',
    validation_check: (value: string) => {
      const zipRegex = /^\d{5}$/
      return zipRegex.test(value)
    },
  },
  customer_type: {
    is_required: true,
    input_type: 'dropdown',
    options: ['Residential/Small Commercial', 'Large Commercial/Industrial', 'Public School'],
    value: 'Residential/Small Commercial',
    conditional_dependencies: {},
    instructions: '',
    label: 'Project Type',
  },
  customer_name: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Name',
    validation_check: (value: string) => {
      return value.length > 0
    },
  },
  customer_address_1: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Street',
    validation_check: (value: string) => {
      return value.length > 0
    },
  },
  customer_address_2: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Apartment Or Suite',
  },
  customer_address_city: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer City',
    validation_check: (value: string) => {
      return value.length > 0
    },
  },
  customer_address_state: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: 'IL',
    instructions: '',
    options: [],
    label: 'Customer State',
    validation_check: (value: string) => {
      return value.length === 2
    },
  },
  customer_address_zip: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Zip Code',
    validation_check: (value: string) => {
      const zipRegex = /^\d{5}$/
      return zipRegex.test(value)
    },
  },
  customer_address_phone: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Phone',
    validation_check: (value: string) => {
      const phoneRegex = /^\d{10}$/
      return phoneRegex.test(value)
    },
  },
  customer_address_email: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Customer Email',
    validation_check: (value: string) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(value)
    },
  },
  electric_utility: {
    is_required: true,
    input_type: 'dropdown',
    value: 'ComEd',
    conditional_dependencies: {},
    instructions: '',
    options: ['AmerenIllinois', 'ComEd', 'MidAmerican', 'Mt. Carmel', 'Municipal Utility', 'Rural Electric Cooperative'],
    label: 'Electric Utility',
  },
  muni_coop_name: {
    input_type: 'text',
    value: '',
    is_required: false,
    conditional_dependencies: { electric_utility: ['Municipal Utility', 'Rural Electric Cooperative'] },
    instructions: '',
    options: [],
    label: 'Electric Utility Name',
  },
  seller_marketing_name: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Seller Name Used for Marketing',
  },
  installer_known: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is the Project Installer Known at This Time?',
  },
  installer_legal_name: {
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Installer Legal Name',
  },
  installer_marketing_name: {
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: 'Enter if different from legal name',
    options: [],
    label: 'Installer Marketing Name',
  },
  installer_address_1: {
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Installer Street',
  },
  installer_address_2: {
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Installer Suite',
  },
  installer_address_city: {
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Installer City',
  },
  installer_address_state: {
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Installer State',
  },
  installer_address_zip: {
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Installer Zip Code',
  },
  installer_address_phone: {
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Installer Phone',
  },
  installer_address_email: {
    is_required: false,
    conditional_dependencies: { installer_known: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Installer Email',
  },
  installer_name_1: {
    is_required: false,
    conditional_dependencies: { installer_known: ['No'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Potential Installer Name 1',
  },
  installer_legal_name_1: {
    is_required: false,
    conditional_dependencies: { installer_known: ['No'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Potential Installer Legal Name 1',
  },
  installer_name_2: {
    is_required: false,
    conditional_dependencies: { installer_known: ['No'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Potential Installer Name 2',
  },
  installer_legal_name_2: {
    is_required: false,
    conditional_dependencies: { installer_known: ['No'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Potential Installer Legal Name 2',
  },
  installer_name_3: {
    is_required: false,
    conditional_dependencies: { installer_known: ['No'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Potential Installer Name 3',
  },
  installer_legal_name_3: {
    is_required: false,
    conditional_dependencies: { installer_known: ['No'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Potential Installer Legal Name 3',
  },
  deposit_owed: {
    // ignore this field, value is set to the value of initial deposit owed and sent to API as a string
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Deposit Owed',
  },
  initial_deposit_owed: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 500, // this value is semi-fixed
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Deposit, Down Payment, or Initial Upfront Amount',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  installation_owed: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 4000, // this value is not pre-calculated
    instructions: 'Omit $ sign and commas, enter a number from 4000-150000',
    options: [],
    label: 'Installation Owed',
    validation_check: (value: number) => {
      return value >= 4000 && value <= 150000
    },
  },
  final_payment_due: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: 'N/A', // fixed
    instructions: 'Free text: give a date or a description',
    options: [],
    label: 'When is the Final Payment Due?',
  },
  final_amount_owed: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Final Amount Owed',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  intermediary_due: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'When is the Intermediary Due?',
  },
  intermediary_payments: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Intermediary Payments Amount',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  fee_name_1: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: 'Application Fee',
    instructions: 'List up to 5 fees that will apply to the project. If there are no fees, leave blank',
    options: [],
    label: 'Fee Name 1',
  },
  fee_amount_1: {
    is_required: false,
    input_type: 'number',
    value: 228,
    conditional_dependencies: { fee_name_1: ['any'] },
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Fee Amount 1',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  fee_due_1: {
    is_required: false,
    conditional_dependencies: { fee_name_1: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Fee Due 1',
  },
  fee_payment_1: {
    is_required: false,
    conditional_dependencies: { fee_name_1: ['any'] },
    input_type: 'number',
    value: 1,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Number of Fee 1 Payments',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  fee_name_2: {
    is_required: false,
    conditional_dependencies: { fee_name_1: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Fee Name 2',
  },
  fee_amount_2: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_2: ['any'] },
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Fee Amount 2',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  fee_due_2: {
    is_required: false,
    conditional_dependencies: { fee_name_2: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Fee Due 2',
  },
  fee_payment_2: {
    is_required: false,
    conditional_dependencies: { fee_name_2: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Number of Fee 2 Payments',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  fee_name_3: {
    is_required: false,
    conditional_dependencies: { fee_name_2: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Fee Name 3',
  },
  fee_amount_3: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_3: ['any'] },
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Fee Amount 3',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  fee_due_3: {
    is_required: false,
    conditional_dependencies: { fee_name_3: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Fee Due 3',
  },
  fee_payment_3: {
    is_required: false,
    conditional_dependencies: { fee_name_3: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Number of Fee 3 Payments',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  fee_name_4: {
    is_required: false,
    conditional_dependencies: { fee_name_3: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Fee Name 4',
  },
  fee_amount_4: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_4: ['any'] },
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Fee Amount 4',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  fee_due_4: {
    is_required: false,
    conditional_dependencies: { fee_name_4: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Fee Due 4',
  },
  fee_payment_4: {
    is_required: false,
    conditional_dependencies: { fee_name_4: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Number of Fee 4 Payments',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  fee_name_5: {
    is_required: false,
    conditional_dependencies: { fee_name_4: ['any'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Fee Name 5',
  },
  fee_amount_5: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { fee_name_5: ['any'] },
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Fee Amount 5',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  fee_due_5: {
    is_required: false,
    conditional_dependencies: { fee_name_5: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Fee Due 5',
  },
  fee_payment_5: {
    is_required: false,
    conditional_dependencies: { fee_name_5: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Number of Fee 5 Payments',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  other_fee_name_1: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'List up to 5 other fees that will apply to the project. If there are no other fees, leave blank',
    options: [],
    label: 'Other Fee Name 1',
  },
  other_fee_amount_1: {
    is_required: false,
    conditional_dependencies: { other_fee_name_1: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Other Fee Amount 1',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  other_fee_applicable_1: {
    is_required: false,
    conditional_dependencies: { other_fee_name_1: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Number of Other Fee 1 Payments',
  },
  other_fee_name_2: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'List up to 5 other fees that will apply to the project. If there are no other fees, leave blank',
    options: [],
    label: 'Other Fee Name 2',
  },
  other_fee_amount_2: {
    is_required: false,
    conditional_dependencies: { other_fee_name_2: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Other Fee Amount 2',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  other_fee_applicable_2: {
    is_required: false,
    conditional_dependencies: { other_fee_name_2: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Number of Other Fee 2 Payments',
  },
  other_fee_name_3: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'List up to 5 other fees that will apply to the project. If there are no other fees, leave blank',
    options: [],
    label: 'Other Fee Name 3',
  },
  other_fee_amount_3: {
    is_required: false,
    conditional_dependencies: { other_fee_name_3: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Other Fee Amount 3',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  other_fee_applicable_3: {
    is_required: false,
    conditional_dependencies: { other_fee_name_3: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Number of Other Fee 3 Payments',
  },
  other_fee_name_4: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'List up to 5 other fees that will apply to the project. If there are no other fees, leave blank',
    options: [],
    label: 'Other Fee Name 4',
  },
  other_fee_amount_4: {
    is_required: false,
    conditional_dependencies: { other_fee_name_4: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Other Fee Amount 4',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  other_fee_applicable_4: {
    is_required: false,
    conditional_dependencies: { other_fee_name_4: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Number of Other Fee 4 Payments',
  },
  other_fee_name_5: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'List up to 5 other fees that will apply to the project. If there are no other fees, leave blank',
    options: [],
    label: 'Other Fee Name 5',
  },
  other_fee_amount_5: {
    is_required: false,
    conditional_dependencies: { other_fee_name_5: ['any'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Other Fee Amount 5',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  other_fee_applicable_5: {
    is_required: false,
    conditional_dependencies: { other_fee_name_5: ['any'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Number of Other Fee 5 Payments',
  },
  collateral_fee_required: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is a Collateral Fee Required?',
  },
  collateral_fee_type: {
    is_required: false,
    conditional_dependencies: { collateral_fee_required: ['Yes'] },
    input_type: 'dropdown',
    value: '',
    instructions: '',
    options: ['Charge a fee', 'Withhold part of the REC payment'],
    label: 'Collateral Fee Type',
  },
  collateral_fee_amount: {
    is_required: false,
    conditional_dependencies: { collateral_fee_required: ['Yes'] },
    input_type: 'number',
    value: 0,
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Collateral Fee Amount',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  collateral_fee_return_date: {
    is_required: false,
    conditional_dependencies: { collateral_fee_required: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: enter a description or a date',
    options: [],
    label: 'Collateral Fee Return Date',
  },
  collateral_fee_conditions: {
    is_required: false,
    conditional_dependencies: { collateral_fee_required: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Collateral Fee Conditions',
  },
  expected_rec_value: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0, // precalculated
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Expected Value Of REC Incentive Payments Received By Approved Vendor If Accepted Into Illinois Shines Program',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  abp_contingent: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is the Installation Contract Contingent Upon Selection for the Adjustable Block Program Incentive?',
  },
  will_terms_change: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will the Terms of the Installation Contract Change if the Project is Not Selected for the Adjustable Block Program Incentive?',
  },
  rec_customer_payment: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0, // precalculated
    instructions: '',
    options: [],
    label: 'Amount of REC Payment Customer Will Receive From Approved Vendor',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  project_submit_date: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Free text: enter a description or a date (yyyy-mm-dd)',
    options: [],
    label: 'When Will the Approved Vendor Submit an Application For the Project to Illinois Shines?',
  },
  install_start_date_days: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Leave blank if unknown',
    options: [],
    label: 'The Estimated Start Date of Project Installation in Days from the Date the Contract is Signed',
  },
  install_start_date: {
    is_required: true,
    input_type: 'text',
    value: '',
    conditional_dependencies: {},
    instructions: 'yyyy-mm-dd format, ignore if the start days have been provided above.',
    options: [],
    label: 'Alternatively, the Estimated Start Date Will Be',
  },
  install_completion_date_days: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Leave blank if unknown',
    options: [],
    label: 'The Estimated Completion Date of Project Installation in Days from the Date the Contract is Signed',
  },
  install_completion_date: {
    is_required: true,
    input_type: 'text',
    value: '',
    conditional_dependencies: {},
    instructions: 'yyyy-mm-dd format, ignore if the completion days have been provided above.',
    options: [],
    label: 'Alternatively, the Estimated Completion Date Will Be',
  },
  mechanic_waiver_date: {
    input_type: 'text',
    value: '',
    is_required: true,
    conditional_dependencies: {},
    instructions: 'Free text: enter a description or a date (mm/dd/yyyy format)',
    options: [],
    label: 'Estimated Date for Mechanics Lien Waiver',
  },
  interconnection_party: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'Seller',
    instructions: '',
    options: ['Seller', 'Approved Vendor', 'Installer', 'Customer'],
    label: 'Party Responsible for Submitting a Project Interconnection Application to the Utility',
  },
  project_size_kw_ac: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Omit kW, must be greater than 0',
    options: [],
    label: 'Project Size AC kW',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  project_size_kw_dc: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 3,
    instructions: 'Omit kW, enter a number from 3-25',
    options: [],
    label: 'Project Size DC kW',
    validation_check: (value: number) => {
      return value >= 3 && value <= 25
    },
  },
  expected_project_life: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 0,
    instructions: 'Enter a number greater than or equal to 0',
    options: [],
    label: 'Expected Life of the Project (Years)',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  mounting_location: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'Roof-mounted',
    instructions: '',
    options: ['Ground-mounted', 'Roof-mounted'],
    label: 'Mounting Location',
  },
  gross_electric_production: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 3000,
    instructions: 'Omit kWh, enter a number from 3000-30000',
    options: [],
    label: 'Estimated Gross Annual Electricity Production',
    validation_check: (value: number) => {
      return value >= 3000 && value <= 30000
    },
  },
  project_performance_explanation: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Project Performance Explanation',
  },
  smart_inverter_rebate_submission: {
    input_type: 'dropdown',
    value: 'No',
    is_required: true,
    conditional_dependencies: { electric_utility: ['AmerenIllinois', 'ComEd'] },
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will an Application be Submitted for the Smart Inverter DG Rebate?',
  },
  rebate_amount: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { smart_inverter_rebate_submission: ['Yes'] },
    instructions: 'Omit $ sign and commas',
    options: [],
    label: 'Rebate Amount',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  who_keeps_rebate: {
    is_required: false,
    conditional_dependencies: { smart_inverter_rebate_submission: ['Yes'] },
    input_type: 'dropdown',
    value: 'Seller',
    instructions: '',
    options: ['Seller', 'Approved Vendor', 'Installer', 'Customer'],
    label: 'Who Keeps the Rebate?',
  },
  maintenance_included: {
    input_type: 'dropdown',
    value: 'No',
    is_required: true,
    conditional_dependencies: {},
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is Project Maintenance Operation Upkeep Included as Part of the Contract?',
  },
  maintenance_party: {
    is_required: false,
    input_type: 'dropdown',
    value: 'Seller',
    conditional_dependencies: { maintenance_included: ['Yes'] },
    instructions: '',
    options: ['Seller', 'Approved Vendor', 'Installer'],
    label: 'Who is Responsible for Project Maintenance?',
  },
  maintenance_years: {
    is_required: false,
    conditional_dependencies: { maintenance_included: ['Yes'] },
    input_type: 'number',
    value: 0,
    instructions: '',
    options: [],
    label: 'For How Many Years is Maintenance Included?',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  repairs_included: {
    input_type: 'dropdown',
    value: 'No',
    is_required: true,
    conditional_dependencies: {},
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Are Project Repairs Required to Fix a Malfunctioning System Included as Part of the Lease?',
  },
  repairs_party: {
    is_required: false,
    conditional_dependencies: { repairs_included: ['Yes'] },
    input_type: 'dropdown',
    value: 'Seller',
    instructions: '',
    options: ['Seller', 'Approved Vendor', 'Installer'],
    label: 'Who is Responsible for Project Repairs?',
  },
  repairs_years: {
    is_required: false,
    input_type: 'number',
    value: 0,
    conditional_dependencies: { repairs_included: ['Yes'] },
    instructions: 'If included, enter a number greater than 0',
    options: [],
    label: 'For How Many Years Are Project Repairs Included?',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  install_warranty_included: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is Project Installation Warranty Included as Part of the Contract?',
  },
  install_warranty_party: {
    is_required: false,
    conditional_dependencies: { install_warranty_included: ['Yes'] },
    input_type: 'dropdown',
    value: 'Seller',
    instructions: '',
    options: ['Seller', 'Approved Vendor', 'Installer'],
    label: 'Who is Responsible for Project Installation Warranty?',
  },
  install_warranty_years: {
    is_required: false,
    conditional_dependencies: { install_warranty_included: ['Yes'] },
    input_type: 'number',
    value: 0,
    instructions: 'Enter a number greater than or equal to 0',
    options: [],
    label: 'For How Many Years is Project Installation Warranty Included?',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  roof_warranty_included: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is Project Roof Warranty Included as Part of the Contract?',
  },
  roof_warranty_party: {
    is_required: false,
    conditional_dependencies: { roof_warranty_included: ['Yes'] },
    input_type: 'dropdown',
    value: 'Seller',
    instructions: '',
    options: ['Seller', 'Approved Vendor', 'Installer'],
    label: 'Who is Responsible for Project Roof Warranty?',
  },
  roof_warranty_years: {
    is_required: false,
    conditional_dependencies: { roof_warranty_included: ['Yes'] },
    input_type: 'number',
    value: 0,
    instructions: 'Enter a number greater than or equal to 0',
    options: [],
    label: 'For How Many Years is Project Roof Warranty Included?',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  loss_damage_included: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will the Seller Insure for Loss or Damage to the Project?',
  },
  loss_damage_exceptions: {
    is_required: false,
    conditional_dependencies: { loss_damage_included: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Provide Details of Insurance Coverage, Including Limitations of Coverage',
  },
  panel_warranty_included: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is Project Panel Warranty Included as Part of the Contract?',
  },
  panel_warranty_years: {
    is_required: false,
    conditional_dependencies: { panel_warranty_included: ['Yes'] },
    input_type: 'number',
    value: 0,
    instructions: 'Enter a number greater than or equal to 0',
    options: [],
    label: 'For How Many Years is Project Panel Warranty Included?',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  inverter_warranty: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Is Project Inverter Warranty Included as Part of the Contract?',
  },
  inverter_warranty_years: {
    is_required: false,
    conditional_dependencies: { inverter_warranty: ['Yes'] },
    input_type: 'number',
    value: 0,
    instructions: 'Enter a number greater than or equal to 0',
    options: [],
    label: 'For How Many Years is Project Inverter Warranty Included?',
    validation_check: (value: number) => {
      return value >= 0
    },
  },
  expected_annual_electricity_usage: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'number',
    value: 3000,
    instructions: 'Enter a number from 3000-30000',
    options: [],
    label: "Customer's Expected Annual Electricity Usage in kWh",
    validation_check: (value: number) => {
      return value >= 3000 && value <= 30000
    },
  },
  electric_service_billing_type: {
    is_required: false,
    conditional_dependencies: {
      customer_type: ['Residential/Small Commercial'],
      electric_utility: ['Mt. Carmel', 'Municipal Utility', 'Rural Electric Cooperative'],
    },
    input_type: 'dropdown',
    value: 'basic utility service',
    instructions:
      'Will the customer take utility basic electric service, utility hourly/dynamic/time of use service, or will the customer take supply from an ARES?',
    options: ['basic utility service', 'hourly/dynamic/time of use', 'ARES'],
    label: 'Electric Service Billing Type',
  },
  include_battery: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Does This Project include a battery for energy storage?',
  },
  battery_size: {
    is_required: false,
    conditional_dependencies: { include_battery: ['Yes'] },
    input_type: 'number',
    value: 0.2,
    instructions: 'What is the size of battery in kWh? Must be greater than 0.1',
    options: [],
    label: 'Battery Size',
    validation_check: (value: number) => {
      return value > 0.1
    },
  },
  energy_storage_rebate: {
    is_required: false,
    conditional_dependencies: { include_battery: ['Yes'], electric_utility: ['ComEd', 'AmerenIllinois'] },
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Will an Application be Submitted for the Utility Energy Storage Rebate?',
  },
  energy_storage_rebate_recipient: {
    is_required: false,
    conditional_dependencies: { energy_storage_rebate: ['Yes'] },
    input_type: 'dropdown',
    value: 'customer',
    instructions: '',
    options: ['customer', 'Vendor/designee'],
    label: 'Energy Storage Rebate Recipient',
  },
  rebate_rate: {
    is_required: false,
    conditional_dependencies: {
      or: ['true'], // TODO: hacky way to make this work but this is the only OR of all the fields, can make it more general later
      energy_storage_rebate: ['Yes'],
      smart_inverter_rebate_submission: ['Yes'],
    },
    input_type: 'dropdown',
    value: 250,
    instructions: 'Is the customer eligible for rebates calculated based on the $300 rate, or the $250 rate?',
    options: [250, 300],
    label: 'Rebate Rate',
  },
  netmetering_excess_generation_credit: {
    is_required: false,
    conditional_dependencies: { electric_utility: ['Mt. Carmel', 'Municipal Utility', 'Rural Electric Cooperative'] },
    input_type: 'text',
    value: '',
    instructions:
      "Provide a complete explanation of how the customer's electric utility will credit the customer for excess electricity that is sent back to the grid, including the current crediting rate (cents/kWh) and any relevant limitations or restrictions on the crediting. Please use terminology that will be understandable to the customer.",
    options: [],
    label: 'Net Metering Excess Generation Credit',
  },
  project_guarantee: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Seller Project Guarantee',
  },
  explanatory_information: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: 'Free text: give a description',
    options: [],
    label: 'Additional Explanatory Information',
  },
  is_spanish: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: '',
    options: ['Yes', 'No'],
    label: 'Generate Form PDF in Spanish?',
  },
  designee_email: {
    is_required: false,
    conditional_dependencies: {},
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: '',
  },
  auto_esign_email_option: {
    is_required: true,
    conditional_dependencies: {},
    input_type: 'dropdown',
    value: 'No',
    instructions: 'Select yes if you would like to receive an email with the form attached',
    options: ['Yes', 'No'],
    label: 'Auto E-Sign Email Option',
  },
  substitute_auto_esign_emailaddr: {
    is_required: false,
    conditional_dependencies: { auto_esign_email_option: ['Yes'] },
    input_type: 'text',
    value: '',
    instructions: '',
    options: [],
    label: 'Substitute Auto E-Sign Email Address',
  },
}

interface TsInterface_StateUtilityMap {
  [state: string]: {
    [utilityName: string]: {
      mappedUtility: string
      group: string
    }
  }
}

export const stateUtilityMap: TsInterface_StateUtilityMap = {
  IL: IL_utilityGroups,
}

const formatFormName = (form: TsInterface_CashDisclosureForm | TsInterface_LeaseDisclosureForm, companyLetterId: string) => {
  const areaCode = String(form['customer_address_phone'].value).slice(0, 3)
  const letter = companyLetterId
  const houseNum = String(form['customer_address_1'].value).split(' ')[0]
  const customerName = String(form['customer_name'].value)
  if (customerName.length > 1) {
    const customerNameFourLetters = customerName.split(' ')[0].slice(0, 4)
    return `${areaCode}${letter}-${houseNum}${customerNameFourLetters}`
  } else {
    const customerNameFourLetters = customerName.slice(0, 4)
    return `${areaCode}${letter}-${houseNum}${customerNameFourLetters}`
  }
}

export const generateFormName = (companyName: string, form: TsInterface_CashDisclosureForm | TsInterface_LeaseDisclosureForm): string => {
  const companyLetterIdMap = {
    sunrun: 'R',
    tesla: 'T',
    sunergy: 'U',
    sunnova: 'N',
    palmetto: 'P',
    sunder: 'S',
    lunar: 'L',
    powerise: 'W',
    etw: 'E',
    etw_admin_lease: 'E',
    etw_admin_purchase: 'E',
    carbon_solutions: 'C',
  }

  if (Object.keys(companyLetterIdMap).includes(companyName)) {
    return formatFormName(form, companyLetterIdMap[companyName as keyof typeof companyLetterIdMap])
  }
  console.error('Company name not found')
  return ''
}
